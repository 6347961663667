<div class="flex items-start gap-x-2" [ngClass]="{ 'flex-row-reverse': isMessageFromCurrentUser() }">
    <img
        class="rounded-full"
        width="34"
        height="34"
        [ngSrc]="message().author.profilePictureUrl ?? (Illustration.Karl | illustrationPathResolver)" />

    <div
        class="feedback-message flex grow flex-col gap-3 rounded-[10px] border border-malou-color-border-primary p-3"
        [ngClass]="{
            'bg-white': !isMessageFromCurrentUser(),
            'bg-malou-color-background-dark': isMessageFromCurrentUser(),
        }">
        <div class="flex justify-between">
            <div>
                <div class="flex gap-x-[2px] text-malou-color-text-1">
                    <span class="malou-text-10--medium">{{ authorFullName() }}</span>
                    @if (isMessageFromCurrentUser()) {
                        <span class="malou-text-10--regular italic">{{ 'feedbacks.you' | translate }}</span>
                    }
                </div>
                <div class="malou-text-10--regular italic text-malou-color-text-2">
                    {{ messageDate() }}
                    @if (messageIsUpdated()) {
                        - {{ 'feedbacks.updated' | translate }}
                    }
                </div>
                @if (message().visibility === FeedbackMessageVisibility.ADMIN) {
                    <div class="malou-text-10--regular italic text-malou-color-text-2">
                        {{ 'feedbacks.admin_visibility_v2' | translate }}
                    </div>
                }
            </div>

            <div class="invisible">
                @if (!feedbacksAreClosed() && isMessageFromCurrentUser() && message().type === FeedbackMessageType.TEXT) {
                    <div class="flex gap-x-2">
                        @if (!editingMessageId()) {
                            <mat-icon
                                class="malou-color-chart-pink--accent !h-4 !w-4 cursor-pointer"
                                [svgIcon]="SvgIcon.TRASH"
                                (click)="onDeleteMessage()"></mat-icon>

                            <mat-icon
                                class="malou-color-primary !h-4 !w-4 cursor-pointer"
                                [svgIcon]="SvgIcon.EDIT"
                                (click)="startEditingMessage()"></mat-icon>
                        }
                    </div>
                }
            </div>
        </div>

        <div>
            @switch (message().type) {
                @case (FeedbackMessageType.TEXT) {
                    <div class="flex flex-col gap-1">
                        @if (editingMessageId() !== message().id) {
                            <p class="malou-color-text-2" [innerHtml]="messageTextHtml()"></p>
                        } @else {
                            <mat-checkbox
                                color="primary"
                                appShowIfAdmin
                                [checked]="message().visibility === FeedbackMessageVisibility.ADMIN"
                                (change)="onChangeMessageVisibility()">
                                <div class="malou-text-10--regular -mt-[3px] text-malou-color-text-2">
                                    {{ 'feedbacks.admin_visibility_v2' | translate }}
                                </div>
                            </mat-checkbox>
                            <div class="flex">
                                <div class="grow">
                                    <form [formGroup]="messagesForm">
                                        <app-text-area
                                            formControlName="editingMessage"
                                            [textAreaId]="'feedback-edit-input'"
                                            [rows]="1"
                                            [mentionConfiguration]="mentionConfig()"></app-text-area>
                                    </form>
                                </div>
                            </div>
                            <div class="flex justify-end">
                                <div class="flex gap-x-3">
                                    @if (editingMessageId() === message().id) {
                                        <button class="malou-btn-flat btn-sm" (click)="cancelEditingMessage()">
                                            <span class="malou-text-10--semibold text-malou-color-text-2">{{
                                                'common.cancel' | translate
                                            }}</span>
                                        </button>
                                    }
                                    <button class="malou-btn-flat btn-sm" [disabled]="!editingMessage.length" (click)="onUpdateMessage()">
                                        <span class="malou-text-10--semibold">{{ 'common.save' | translate }}</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }
                @case (FeedbackMessageType.REOPEN) {
                    <div>
                        <p class="malou-color-dark-lighter">
                            <strong>{{ 'feedbacks.opened_feedback' | translate }}</strong>
                        </p>
                    </div>
                }
                @case (FeedbackMessageType.CLOSE) {
                    <div>
                        <p class="malou-color-dark-lighter">
                            <strong>{{ 'feedbacks.closed_feedback' | translate }}</strong>
                        </p>
                    </div>
                }
            }
        </div>
    </div>
</div>
