<div class="malou-color-background-light flex justify-between rounded-[10px] p-3">
    <div class="gat-y-2 flex flex-col">
        <span class="malou-text-13--bold text-malou-color-text-1">
            {{ notificationReminderTile() | translate }}
        </span>
        <span class="malou-text-10--regular italic text-malou-color-text-2">
            {{ notificationReminderDescription() | translate }}
        </span>
    </div>
    <div>
        <app-slide-toggle [checked]="checked()" (onToggle)="toggleReminder($event)"></app-slide-toggle>
    </div>
</div>
