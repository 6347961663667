<div
    class="flex w-full flex-col"
    [ngClass]="{ 'rounded-[3px] border border-malou-color-border-primary': theme() === InputTextTheme.INLINE_CARD }">
    @if (title() || subtitle() || (required() && !hideRequiredStar()) || showRequiredStar()) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title(); as title) {
                <span>{{ title }}</span>
            }
            @if (subtitle(); as subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
            @if ((required() && !hideRequiredStar()) || showRequiredStar()) {
                <span>*</span>
            }
            <ng-content select="[afterTitleContent]"></ng-content>
        </div>
    }

    <div
        class="flex items-center self-stretch"
        [ngClass]="{
            'opacity-50': control.disabled,
            'malou-border-secondary': !errorMessage() && isFocused() && theme() === InputTextTheme.FILL,
            'malou-border-error': !!errorMessage() || (control && control.invalid && (control.dirty || control.touched)),
            'malou-border-primary rounded-lg bg-white': theme() === InputTextTheme.FILL,
            'rounded-[3px] bg-malou-color-background-light': theme() === InputTextTheme.INLINE_CARD,
        }">
        @if (svgImg(); as svgImg) {
            <div class="flex items-center pl-5">
                <img class="!h-4 !w-4 !max-w-none" [src]="svgImg" />
            </div>
        }

        @if (shouldShowSvgIcon() && theme() === InputTextTheme.INLINE_CARD) {
            <div
                class="round flex h-7 items-center rounded-l-[3px] bg-malou-color-background-light px-2"
                [ngClass]="{ 'cursor-pointer': cursorPointerOnIcon() }"
                (click)="iconClick.emit()">
                @if (svgIcon(); as svgIcon) {
                    <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="svgIcon"></mat-icon>
                }
            </div>
        }

        <div
            class="w-full"
            [ngClass]="{
                'h-12': theme() === InputTextTheme.FILL,
                'h-8': theme() === InputTextTheme.UNDERLINE,
                'h-7 rounded-[3px] bg-malou-color-background-light': theme() === InputTextTheme.INLINE_CARD,
            }">
            <!-- name="prevent-chrome-autocomplete" to force chrome to listen to autocomplete="off" -->
            <!-- https://developer.mozilla.org/en-US/docs/Web/Security/Practical_implementation_guides/Turning_off_form_autocompletion#disabling_autocompletion -->
            <input
                class="box-border h-full w-full border-0 px-5 !text-malou-color-text-1 outline-none placeholder:italic"
                name="prevent-chrome-autocomplete"
                [ngClass]="{
                    'malou-text-12--regular': isEmptyValue(),
                    'malou-text-12--medium': !isEmptyValue(),
                    'rounded-lg bg-white': theme() === InputTextTheme.FILL,
                    'border-b border-malou-color-primary/50 bg-transparent !px-1': theme() === InputTextTheme.UNDERLINE,
                    '!malou-text-10 rounded-[2px] bg-malou-color-background-light !pl-0 !pr-2 !font-normal':
                        theme() === InputTextTheme.INLINE_CARD,
                    'cursor-not-allowed !bg-malou-color-background-light': readOnly(),
                }"
                [type]="inputType()"
                [id]="inputId()"
                [attr.data-testid]="testId()"
                [placeholder]="placeholder()"
                [formControl]="control"
                [autocapitalize]="autocapitalize()"
                [autocomplete]="autocomplete()"
                [defaultValue]="defaultValue()"
                [min]="min()"
                [max]="max()"
                [maxlength]="maxLength()"
                [disabled]="control.disabled || disabled()"
                [readOnly]="readOnly()"
                (focus)="isFocused.set(true)"
                (blur)="isFocused.set(false)" />
        </div>

        @if (showMaxLength()) {
            <div class="flex h-10 items-center rounded border-l border-malou-color-border-primary px-1">
                <div class="malou-color-text-2 malou-text-12--regular whitespace-nowrap pr-1 italic">
                    {{ control.value?.length || 0 }}/{{ maxLength() }}
                </div>
            </div>
        }
        @if (isEmojiPickerEnabled()) {
            <div class="malou-color-text-2 pr-2 sm:hidden">
                <app-emoji-picker
                    [buttonColorClass]="emojiButtonColor()"
                    [disabled]="maxLength() && control.value ? maxLength() === control.value.length : false"
                    [closeOnSelect]="true"
                    (emitEmoji)="emojiSelected($event)"></app-emoji-picker>
            </div>
        }

        @if (shouldShowSvgIcon() && theme() !== InputTextTheme.INLINE_CARD) {
            <div
                class="flex items-center px-3"
                [ngClass]="{
                    'h-12': theme() === InputTextTheme.FILL,
                    'h-8': theme() === InputTextTheme.UNDERLINE,
                    'cursor-pointer': cursorPointerOnIcon(),
                }"
                (click)="iconClick.emit()">
                @if (svgIcon(); as svgIcon) {
                    <mat-icon class="malou-color-primary !h-4 !w-4" [svgIcon]="svgIcon"></mat-icon>
                }
            </div>
        }

        @if (showEyeIcon()) {
            <div class="flex items-center px-3">
                <mat-icon color="primary" [svgIcon]="password() ? 'eye' : 'eye-closed'" (click)="toggleEyeIcon()"></mat-icon>
            </div>
        }
        @if (showClearIcon() && control.value?.length > 0) {
            <div class="flex items-center pr-5">
                <mat-icon class="!h-3 !w-3" matRipple color="primary" [svgIcon]="SvgIcon.CROSS" (click)="clearControl()"></mat-icon>
            </div>
        }

        @if (customActionBtn(); as customActionBtn) {
            <div>
                <span class="malou-color-primary malou-text-10--bold cursor-pointer pr-4" (click)="emitCustomAction()">
                    {{ customActionBtn }}
                </span>
            </div>
        }

        @if (showConfirmIcon() && control.value?.length > 0) {
            <div class="flex cursor-pointer items-center pr-5">
                <mat-icon
                    class="!h-3 !w-3"
                    color="primary"
                    [svgIcon]="SvgIcon.ARROW_RIGHT"
                    [id]="confirmationIconId()"
                    (click)="emitControlValue()"></mat-icon>
            </div>
        }
    </div>

    @if (showMaxLengthHelper() && maxLength() !== null) {
        <div class="malou-text-8--regular malou-color-text-2 w-full pt-1 text-right italic">
            {{ 'common.max_length_helper' | translate: { count: maxLength() } }}
        </div>
    }

    @if (errorMessage()) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage() }}</div>
    }
    @if (!errorMessage() && control && control.invalid && (control.dirty || control.touched)) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">
            {{ 'common.invalid_field' | translate }}
        </div>
    }
</div>
