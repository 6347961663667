<div class="malou-dialog">
    <div class="malou-dialog__header">
        <span></span>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"> </mat-icon>
    </div>

    <div class="malou-dialog__body !overflow-hidden">
        <div class="flex justify-center">
            <span class="malou-color-text-1 malou-text-18--bold text-center">
                {{
                    'notification-center.popins.post_suggestion.event_name'
                        | translate
                            : {
                                  eventName: data.notification.getNotificationName | applyPure,
                                  eventDate: data.notification.getFormattedEventDate | applyPure,
                              }
                }}
            </span>
        </div>
        <div class="flex justify-center">
            <span class="malou-text-100">
                {{ data.notification.getNotificationEmoji | applyPure }}
            </span>
        </div>
        <div class="malou-color-text-2 malou-text-13--semibold my-2 flex justify-center">
            {{ 'notification-center.popins.post_suggestion.text' | translate }}
        </div>
        <div class="malou-color-text-2 malou-text-12 my-2 flex justify-center italic">
            {{ 'notification-center.popins.post_suggestion.subtext' | translate }}
        </div>
    </div>

    <div class="malou-dialog__footer flex-col">
        @if (!data.restaurant.isBrandBusiness()) {
            <button class="malou-btn-raised--secondary !py-[23px]" mat-raised-button (click)="navigateToPosts(PostSource.SEO)">
                {{ 'notification-center.popins.post_suggestion.create_seo_post' | translate }}
            </button>
        }
        <button class="malou-btn-raised--secondary !py-[23px]" mat-raised-button (click)="navigateToPosts(PostSource.SOCIAL)">
            {{ 'notification-center.popins.post_suggestion.create_social_post' | translate }}
        </button>
    </div>
</div>
