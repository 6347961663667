import { z } from 'zod';

import { FeedbackMessageType, FeedbackMessageVisibility, Role } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils';

export const addFeedbackMessageParamsValidator = z
    .object({
        feedback_id: objectIdValidator,
    })
    .transform((data) => ({
        feedbackId: data.feedback_id,
    }));
export type AddFeedbackMessageParamsDto = z.infer<typeof addFeedbackMessageParamsValidator>;

export const addFeedbackMessageQueryValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type AddFeedbackMessageQueryDto = z.infer<typeof addFeedbackMessageQueryValidator>;

export const addFeedbackMessageBodyValidator = z.object({
    feedbackMessage: z.object({
        text: z.string().nullish(),
        type: z.nativeEnum(FeedbackMessageType),
        visibility: z.nativeEnum(FeedbackMessageVisibility),
    }),
    participants: z.array(
        z.object({
            id: objectIdValidator,
            name: z.string(),
            lastname: z.string().nullish(),
            email: z.string().email(),
            role: z.nativeEnum(Role).nullish(),
            userId: objectIdValidator.nullish(),
        })
    ),
});
export type AddFeedbackMessageBodyDto = z.infer<typeof addFeedbackMessageBodyValidator>;
