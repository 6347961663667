import { createAction, props } from '@ngrx/store';

import { PlatformKey } from '@malou-io/package-utils';

import {
    AggregatedBoostersStatisticsData,
    AggregatedBoostersStatisticsDataV2,
} from ':modules/aggregated-statistics/boosters/booster.interface';
import { ReviewsRatingsAverageData } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-ratings-average/reviews-ratings-average.component';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import { ChartReviewsStats, DatesAndPeriod, InsightsByPlatformByRestaurant, MalouTimeScalePeriod, Restaurant } from ':shared/models';
import { AggregatedUserFilters } from ':shared/models/user-filters.model';

export const initializeState = createAction(
    '[Aggregated Statistics] Initialize_State',
    props<{ data: AggregatedUserFilters['aggregatedStatisticsFilters'] }>()
);

export const editDates = createAction('[Aggregated Statistics] Edit_Dates', props<{ dates: DatesAndPeriod }>());

export const editPlatforms = createAction(
    '[Aggregated Statistics] Edit_Platforms',
    props<{ page: PlatformFilterPage | undefined; platforms: PlatformKey[] }>()
);

export const editRestaurants = createAction('[Aggregated Statistics] Edit_Restaurants', props<{ restaurants: Restaurant[] }>());

export const editRoiRestaurants = createAction('[Aggregated Statistics] Edit_Roi_Restaurants', props<{ roiRestaurants: Restaurant[] }>());

export const editTotems = createAction('[Aggregated Statistics] Edit_Totems', props<{ totemIds: string[] }>());

export const resetState = createAction('[Aggregated Statistics] Reset_State');

export const editPlatformsRatingsByRestaurantData = createAction(
    '[Statistics] Edit_Platforms_Ratings_By_Restaurant_Data',
    props<{ data: InsightsByPlatformByRestaurant }>()
);

export const editReviewCountsData = createAction('[Statistics] Edit_Review_Counts_Data', props<{ data: ChartReviewsStats[] }>());

export const editAverageReviewsRatingsData = createAction(
    '[Statistics] Edit_Average_Reviews_Ratings_Data',
    props<{ data: ReviewsRatingsAverageData[] }>()
);

export const editBoosterStatsData = createAction(
    '[Statistics] Edit_Booster_Stats_Data',
    props<{ data: AggregatedBoostersStatisticsData }>()
);

export const editBoosterStatsDataV2 = createAction(
    '[Statistics] Edit_Booster_Stats_Data_V2',
    props<{ data: AggregatedBoostersStatisticsDataV2 }>()
);
export const editTimeScale = createAction('[Aggregated_Statistics] Edit_Time_Scale', props<{ data: MalouTimeScalePeriod }>());

export const editSelectedPage = createAction('[Aggregated_Statistics] Edit_Selected_Page', props<{ page: PlatformFilterPage }>());
