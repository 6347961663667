<div
    class="rounded-md border border-malou-color-background-dark bg-malou-color-background-light p-3"
    [ngClass]="{ 'border-dotted border-malou-color-primary': isDragging() }">
    @if (isDragging()) {
        <ng-container [ngTemplateOutlet]="dragOverTemplate"></ng-container>
    } @else if (medias().length === 0 && uploadingMediaCount() === 0) {
        <ng-container [ngTemplateOutlet]="addFirstMediaTemplate"></ng-container>
    } @else {
        <ng-container [ngTemplateOutlet]="mediaListTemplate"></ng-container>
    }
</div>

<ng-template #addFirstMediaTemplate>
    <div class="flex h-[75px] items-center gap-x-5 rounded-md bg-white p-4">
        <mat-icon class="!h-14 !w-14 !fill-malou-color-background-dark" [svgIcon]="SvgIcon.IMAGES"></mat-icon>
        <div class="flex flex-col gap-y-1">
            <div class="malou-text-11--semibold text-malou-color-text-1">
                <span class="cursor-pointer text-malou-color-primary" [matMenuTriggerFor]="uploadMenu">{{
                    'upload_and_edit_medias.add_media' | translate
                }}</span>
                {{ 'upload_and_edit_medias.or_drag_and_drop' | translate }}
            </div>
            <div class="malou-text-10--regular italic text-malou-color-text-2">
                {{ mediaRequirementsText() }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #mediaListTemplate>
    <div class="flex gap-x-1.5">
        <div class="min-w-0">
            <app-media-thumbnail-list
                [medias]="mediasForThumbnailList()"
                [uploadingMediaCount]="uploadingMediaCount()"
                [showEditMediaButton]="showEditMediaButton()"
                (editMedia)="onEditMedia($event)"
                (removeMedia)="onRemoveMedia($event)"
                (dropMedia)="onDropMedia($event)"
                (mediaClicked)="mediaClicked.emit($event)"></app-media-thumbnail-list>
        </div>
        <div
            class="flex h-[75px] w-[75px] shrink-0 cursor-pointer items-center justify-center rounded-md bg-white"
            (click)="fileInput.click()">
            <mat-icon class="!h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
        </div>
    </div>
</ng-template>

<ng-template #dragOverTemplate>
    <div class="flex h-[75px] items-center gap-x-5 rounded-md bg-white p-4">
        <mat-icon class="!h-14 !w-14 !fill-malou-color-background-dark" [svgIcon]="SvgIcon.IMAGES"></mat-icon>
        <div class="malou-text-11--semibold text-malou-color-text-1">
            {{ 'upload_and_edit_medias.drag_and_drop_here' | translate }}
        </div>
    </div>
</ng-template>

<input type="file" hidden multiple="true" [accept]="ACCEPT" (change)="onFileInputChange($event)" #fileInput />

<mat-menu class="malou-mat-menu malou-box-shadow rounded-md" #uploadMenu="matMenu">
    <button mat-menu-item (click)="fileInput.click()">
        <span class="malou-text-12--regular text-malou-color-text-2">
            {{ 'common.upload_from_computer' | translate }}
        </span>
    </button>
    <button mat-menu-item (click)="importMediaFromGallery.emit()">
        <span class="malou-text-12--regular text-malou-color-text-2">{{ 'common.upload_from_gallery' | translate }}</span>
    </button>
</mat-menu>
