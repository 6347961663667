import { CaslAction, CaslSubject } from '../casl.enum';
import { CaslPermission } from '../casl.type';
import { UserRestaurantPermission } from './permission.enum';

export type UserRestaurantPermissionArgs = { restaurantId: string; restaurant: { organizationId?: string } };

export const userRestaurantCaslPermissions = (permission: UserRestaurantPermission, ur: UserRestaurantPermissionArgs): CaslPermission => {
    const caslPermissionsByUserRestaurantPermission: Record<UserRestaurantPermission, CaslPermission> = {
        [UserRestaurantPermission.MODIFY_INFORMATION]: [CaslAction.UPDATE, CaslSubject.RESTAURANT, { _id: ur.restaurantId }],
        [UserRestaurantPermission.DISABLE_VENUE]: [CaslAction.UPDATE, CaslSubject.RESTAURANT, ['active'], { _id: ur.restaurantId }],
        [UserRestaurantPermission.SAVE_INSPIRATIONS]: [
            CaslAction.UPDATE,
            CaslSubject.RESTAURANT,
            ['bookmarkedPosts'],
            { _id: ur.restaurantId },
        ],
        [UserRestaurantPermission.UPDATE_BRICKS]: [CaslAction.UPDATE, CaslSubject.RESTAURANT, ['bricks'], { _id: ur.restaurantId }],

        [UserRestaurantPermission.DELETE_PLATFORM]: [CaslAction.DELETE, CaslSubject.PLATFORM, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.MANAGE_PLATFORM]: [CaslAction.MANAGE, CaslSubject.PLATFORM, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.UPDATE_PLATFORM]: [CaslAction.UPDATE, CaslSubject.PLATFORM, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.UPDATE_INSPIRATIONS]: [
            CaslAction.UPDATE,
            CaslSubject.PLATFORM,
            ['watchedAccounts'],
            { restaurantId: ur.restaurantId },
        ],

        [UserRestaurantPermission.UPDATE_SUBSCRIPTION]: [CaslAction.UPDATE, CaslSubject.SUBSCRIPTION, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.UPLOAD_MEDIA]: [CaslAction.MANAGE, CaslSubject.MEDIA, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_KEYWORDS]: [CaslAction.MANAGE, CaslSubject.KEYWORD, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_REVIEWS]: [CaslAction.MANAGE, CaslSubject.REVIEW, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_SEO_POSTS]: [CaslAction.MANAGE, CaslSubject.POST, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.MANAGE_SOCIAL_POSTS]: [CaslAction.MANAGE, CaslSubject.POST, { restaurantId: ur.restaurantId }], // TODO: add condition on Post.source
        [UserRestaurantPermission.PUBLISH_SEO_POSTS]: [CaslAction.PUBLISH, CaslSubject.SEO_POST, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.PUBLISH_SOCIAL_POSTS]: [CaslAction.PUBLISH, CaslSubject.SOCIAL_POST, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_TEMPLATES]: [CaslAction.MANAGE, CaslSubject.TEMPLATE, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_HASHTAGS]: [CaslAction.MANAGE, CaslSubject.RESTAURANT_HASHTAG, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_COMMENTS]: [CaslAction.MANAGE, CaslSubject.COMMENT, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.READ_USER_RESTAURANTS]: [CaslAction.READ, CaslSubject.USER_RESTAURANT, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.DELETE_USER_RESTAURANTS]: [
            CaslAction.DELETE,
            CaslSubject.USER_RESTAURANT,
            { restaurantId: ur.restaurantId },
        ],
        [UserRestaurantPermission.UPDATE_USER_RESTAURANTS]: [
            CaslAction.UPDATE,
            CaslSubject.USER_RESTAURANT,
            { restaurantId: ur.restaurantId },
        ],

        [UserRestaurantPermission.MANAGE_LABELS]: [CaslAction.MANAGE, CaslSubject.LABEL, { organizationIds: ur.restaurant.organizationId }],

        [UserRestaurantPermission.CREATE_USER]: [CaslAction.CREATE, CaslSubject.USER, { organizationIds: ur.restaurant.organizationId }],
        [UserRestaurantPermission.UPDATE_USER_ORGANIZATIONS]: [CaslAction.UPDATE, CaslSubject.USER, ['organizationIds']],

        [UserRestaurantPermission.MANAGE_CLIENTS]: [CaslAction.MANAGE, CaslSubject.CLIENT, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_CAMPAIGNS]: [CaslAction.MANAGE, CaslSubject.CAMPAIGN, { restaurantId: ur.restaurantId }],

        [UserRestaurantPermission.MANAGE_FEEDBACKS]: [CaslAction.MANAGE, CaslSubject.FEEDBACK, { restaurantId: ur.restaurantId }],
        [UserRestaurantPermission.ANSWER_FEEDBACK]: [CaslAction.ANSWER, CaslSubject.FEEDBACK, { restaurantId: ur.restaurantId }],
    };
    return caslPermissionsByUserRestaurantPermission[permission];
};
