@if (!isLoading()) {
    @if (insights().value?.length === 0) {
        <ng-container [ngTemplateOutlet]="errorTemplate" [ngTemplateOutletContext]="{ errorMessage: insights().error }"></ng-container>
    }
    @if ((insights().value?.length ?? 0) > 0) {
        <div class="flex flex-col gap-y-6">
            <ng-container [ngTemplateOutlet]="postsTableTemplate"></ng-container>
        </div>
    }
} @else {
    <app-skeleton skeletonClass="!h-12 secondary-bg" [count]="10"></app-skeleton>
}

<ng-template let-errorMessage="errorMessage" #errorTemplate>
    <ng-container
        [ngTemplateOutlet]="errorMessage ? defaultErrorTemplate : noFoundData"
        [ngTemplateOutletContext]="{ errorMessage: errorMessage }"></ng-container>
</ng-template>

<ng-template #postsTableTemplate>
    <div class="hidden w-full md:flex">
        <app-sort-by-filters
            class="w-full"
            [sortOptions]="SORT_OPTIONS"
            [sortOrder]="1"
            [sortBy]="'restaurantName'"
            (changeSortBy)="onSortByChange($event)"
            (toggleSortOrder)="onSortOrderChange()">
        </app-sort-by-filters>
    </div>
    <mat-table class="malou-mat-table" matSort [dataSource]="dataSource" #table="matTable">
        <ng-container matColumnDef="restaurantName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.social_networks.business' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let restaurantInsights; table: table">
                <div class="flex w-full items-center gap-4">
                    @if (restaurantInsights | applySelfPure: 'isBrandBusiness') {
                        <div class="restaurant-type-logo--brand">
                            <mat-icon [svgIcon]="SvgIcon.HEART"></mat-icon>
                        </div>
                    } @else {
                        <div class="restaurant-type-logo--local-business">
                            <mat-icon [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
                        </div>
                    }
                    <span class="malou-text-13--semibold malou-color-text-1">
                        {{ restaurantInsights.restaurantName }}
                    </span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="followers">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.social_networks.followers' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurantInsights; table: table">
                @if (!(restaurantInsights | applySelfPure: 'hasErrors')) {
                    <div class="flex w-full items-center gap-4 md:ml-2">
                        <span class="malou-text-12--medium malou-color-text-2 hidden md:block md:w-2/4">
                            {{ 'aggregated_statistics.social_networks.followers' | translate }}
                        </span>
                        <span class="malou-color-text-1 malou-text-13--medium md:w-1/4">
                            {{ restaurantInsights.current.followers | shortNumber: { fixNumber: 1 } }}
                        </span>
                        <span
                            class="malou-text-10--semibold"
                            [ngClass]="{
                                'malou-color-state-success': (restaurantInsights.difference?.followers ?? 0) > 0,
                                'malou-color-state-error': (restaurantInsights.difference?.followers ?? 0) < 0,
                            }">
                            <span>{{ (restaurantInsights.difference?.followers ?? 0) > 0 ? '+' : '' }}</span>
                            <span>{{ restaurantInsights.difference?.followers | shortNumber: { fixNumber: 1 } }}</span>
                        </span>
                    </div>
                }
                @if (restaurantInsights | applySelfPure: 'hasErrors') {
                    <span class="malou-color-text-2 malou-text-12--regular absolute mr-2.5 md:relative">
                        {{ restaurantInsights.firstPlatformTranslatedError }}
                        <a
                            class="malou-color-primary malou-text-12--semibold"
                            [routerLink]="'/restaurants/' + restaurantInsights.restaurantId + '/settings/platforms/connection'">
                            {{ 'aggregated_statistics.social_networks.modify' | translate }}
                        </a>
                    </span>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="impressions">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.social_networks.impressions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurantInsights; table: table">
                @if (!(restaurantInsights | applySelfPure: 'hasErrors')) {
                    <div class="flex w-full items-center gap-4 md:ml-2">
                        <span class="malou-text-12--medium malou-color-text-2 hidden md:block md:w-2/4">
                            {{ 'aggregated_statistics.social_networks.impressions' | translate }}
                        </span>
                        <span class="malou-color-text-1 malou-text-13--medium md:w-1/4">
                            {{ restaurantInsights.current.impressions | shortNumber: { fixNumber: 1 } }}
                        </span>
                        <span
                            class="malou-text-10--semibold"
                            [ngClass]="{
                                'malou-color-state-success': (restaurantInsights.difference?.impressions ?? 0) > 0,
                                'malou-color-state-error': (restaurantInsights.difference?.impressions ?? 0) < 0,
                            }">
                            <span>{{ (restaurantInsights.difference?.impressions ?? 0) > 0 ? '+' : '' }}</span>
                            <span>{{ restaurantInsights.difference?.impressions | shortNumber: { fixNumber: 1 } }}</span>
                        </span>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="engagements">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.social_networks.engagement_rate' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurantInsights; table: table">
                @if (!(restaurantInsights | applySelfPure: 'hasErrors')) {
                    <div class="flex w-full items-center gap-4 md:ml-2">
                        <span class="malou-text-12--medium malou-color-text-2 hidden md:block md:w-2/4">
                            {{ 'aggregated_statistics.social_networks.engagement_rate' | translate }}
                        </span>
                        <span class="malou-color-text-1 malou-text-13--medium md:w-1/4">
                            {{ restaurantInsights.current.engagementRate | shortNumber: { content: '%' } }}
                        </span>
                        <span
                            class="malou-text-10--semibold"
                            [ngClass]="{
                                'malou-color-state-success': (restaurantInsights.difference?.engagementRate ?? 0) > 0,
                                'malou-color-state-error': (restaurantInsights.difference?.engagementRate ?? 0) < 0,
                            }">
                            <span>{{ (restaurantInsights.difference?.engagementRate ?? 0) > 0 ? '+' : '' }}</span>
                            <span>{{ restaurantInsights.difference?.engagementRate | shortNumber: { fixNumber: 1, content: 'pts' } }}</span>
                        </span>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="posts">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'aggregated_statistics.social_networks.posts' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurantInsights; table: table">
                @if (!(restaurantInsights | applySelfPure: 'hasErrors')) {
                    <div class="flex w-full items-center gap-4 md:ml-2">
                        <span class="malou-text-12--medium malou-color-text-2 hidden md:block md:w-2/4">
                            {{ 'aggregated_statistics.social_networks.posts' | translate }}
                        </span>
                        <span class="malou-color-text-1 malou-text-13--medium md:w-1/4">
                            {{ restaurantInsights.current.posts | shortNumber: { fixNumber: 1 } }}
                        </span>
                        <span
                            class="malou-text-10--semibold"
                            [ngClass]="{
                                'malou-color-state-success': (restaurantInsights.difference?.posts ?? 0) > 0,
                                'malou-color-state-error': (restaurantInsights.difference?.posts ?? 0) < 0,
                            }">
                            <span>{{ (restaurantInsights.difference?.posts ?? 0) > 0 ? '+' : '' }}</span>
                            <span>{{ restaurantInsights.difference?.posts | shortNumber: { fixNumber: 1 } }}</span>
                        </span>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
        <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table" class="!relative"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noFoundData>
    <div class="flex h-[50vh] flex-col items-center justify-center">
        <img class="h-32" loading="lazy" [src]="'Goggles' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">
            {{ 'aggregated_statistics.social_networks.wrong_calculations' | translate }}
        </p>
        <p class="malou-text-10--regular my-4.5 text-center">
            {{ 'aggregated_statistics.social_networks.edit_filters' | translate }}
        </p>
    </div>
</ng-template>

<ng-template let-errorMessage="errorMessage" #defaultErrorTemplate>
    <div class="flex h-[50vh] flex-col items-center justify-center">
        <img class="h-32" loading="lazy" [src]="'Cutlery' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">
            {{ 'aggregated_statistics.social_networks.default_error_title' | translate }}
        </p>
        <p class="malou-text-10--regular my-4.5 text-center">
            {{ 'aggregated_statistics.social_networks.default_error_text' | translate }}
        </p>
        <p>{{ errorMessage }}</p>
    </div>
</ng-template>
