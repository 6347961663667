import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { NotificationItemComponent } from ':core/components/notification-center/components/notification-item/notification-item.component';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

interface MentionsByRestaurant {
    restaurantId: string;
    restaurantName: string;
}

@Component({
    selector: 'app-mention-notification-item',
    standalone: true,
    imports: [TranslateModule, DatePipe, MatButtonModule, MatIconModule, NgTemplateOutlet, ShortTextPipe],
    templateUrl: './mention-notification-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionNotificationItemComponent extends NotificationItemComponent {
    readonly SHORT_TEXT_LENGTH = 60;

    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _router = inject(Router);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);

    readonly notification = computed(() => this.initialNotification());
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.mentions?.[0].restaurantId)
    );

    readonly firstMention = computed(() => this.notification().data.mentions[0]);

    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);
    readonly isListVisible: WritableSignal<boolean> = signal(false);

    readonly mentionsByRestaurants: Signal<MentionsByRestaurant[]> = computed(() =>
        this.notification().data.restaurantIds.map((restaurantId: string) => {
            const restaurant = this._restaurantService.restaurants().find((r: Restaurant) => r._id === restaurantId);
            return {
                restaurantId,
                restaurantName: restaurant?.name,
            };
        })
    );

    onNotificationClick(restaurantId?: string): void {
        this.markAsRead();
        const restId = restaurantId ?? this.firstMention().restaurantId;
        this._notificationsCenterContext.trackNotification({
            heapEventName: HeapEventName.NOTIFICATION_MENTION_TRACKING_WEB_REDIRECT,
            notificationId: this.notification().id,
            properties: {
                restaurantId: restId,
                notificationType: this.notification().type,
            },
        });
        this._navigateToInteractions(restId);
        this._notificationsCenterContext.close();
    }

    toggleList(): void {
        this.isListVisible.set(!this.isListVisible());
    }

    private _navigateToInteractions(restaurantId: string): void {
        this._router.navigate(['restaurants', restaurantId, 'interactions', 'comments'], {
            queryParams: {
                nid: this.notification().id,
            },
        });
    }
}
