import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNumber, keyBy } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, combineLatest, debounceTime, forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { PostInsights, PostInsightsDto } from '@malou-io/package-dto';
import {
    InsightsChart,
    isNotNil,
    MalouMetric,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    PostSource,
} from '@malou-io/package-utils';

import { PostsInsightsService } from ':core/services/post-insights.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Pagination, Restaurant, Story } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const DEFAULT_PAGINATION = { pageSize: 10, pageNumber: 0, total: 0 };

interface StoryInsight {
    key: PlatformKey;
    hasData: boolean;
    socialId: string;
    socialLink?: string;
    mediaUrl?: string;
    thumbnailUrl?: string;
    postType?: string;
    createdAt: Date;
    impressions: number | null;
    reach: number | null;
    tapsExits: number | null;
    tapsForward: number | null;
    tapsBack: number | null;
}

export interface StoriesAndInsights {
    stories: Story[];
    insights: PostInsights[];
}

@Component({
    selector: 'app-social-network-stories',
    standalone: true,
    imports: [
        SkeletonComponent,
        SocialPostMediaComponent,
        SortByFiltersComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        CommonModule,
        LazyLoadImageModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        FormatDatePipe,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        StatisticsHttpErrorPipe,
        PlatformLogoComponent,
        PaginatorComponent,
    ],
    providers: [ShortNumberPipe],
    templateUrl: './stories.component.html',
    styleUrls: ['./stories.component.scss'],
})
export class StoriesComponent implements OnInit {
    @Input() shouldDisplayElementsPaginated = true;
    @Input() shouldHideIfNoData = false;
    @Input() shouldLazyLoadMedia = true;
    @Input() tableSortOptions: Sort | undefined = undefined;
    @Output() tableSortOptionsChange = new EventEmitter<{ chart: InsightsChart; value: Sort }>();
    @Output() hasNoData: EventEmitter<boolean> = new EventEmitter();

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly PAGE_SIZE_OPTIONS = [5, 10, 20];
    pagination: Pagination = DEFAULT_PAGINATION;

    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);
    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly DISPLAYED_COLUMNS: string[] = [
        'media',
        'platform',
        'createdAt',
        'impressions',
        'reach',
        'tapsExits',
        'tapsForward',
        'tapsBack',
    ];
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'platform', label: this.translate.instant('statistics.social_networks.stories.platform') },
        { key: 'createdAt', label: this.translate.instant('statistics.social_networks.posts.created_at') },
        { key: 'impressions', label: this.translate.instant('statistics.social_networks.posts.impressions') },
        { key: 'reach', label: this.translate.instant('statistics.social_networks.posts.reach') },
    ];
    dataSource = new MatTableDataSource<StoryInsight>([]);
    defaultSort: Sort = { active: 'createdAt', direction: 'desc' };
    isLoading = signal(false);
    pagination$: BehaviorSubject<Pagination> = new BehaviorSubject(DEFAULT_PAGINATION);
    totalStories = 0;
    restaurantId: string;
    httpError?: any;

    readonly shouldReload$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(
        private readonly _postsService: PostsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _postsInsightsService: PostsInsightsService,
        public readonly translate: TranslateService,
        private readonly _shortNumberPipe: ShortNumberPipe
    ) {}

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string => {
                const { active, direction } = sort;
                this.tableSortOptionsChange.emit({ chart: InsightsChart.STORY_INSIGHTS, value: { active, direction } });
                return item[property];
            };
            this.dataSource.sort = sort;
        }
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant) => this.synchronizeStoriesAndFetchInsights$(restaurant._id))
            )
            .subscribe({
                next: () => this.shouldReload$.next(true),
            });

        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.pagination$, this.shouldReload$])
            .pipe(
                filter(([restaurant, dates]) => isNotNil(restaurant) && isDateSetOrGenericPeriod(dates)),
                tap(() => this._reset()),
                debounceTime(500),
                switchMap(([restaurant, dates, pagination, _]: [Restaurant, DatesAndPeriod, Pagination, boolean]) => {
                    this.isLoading.set(true);
                    const restaurantId = restaurant._id;
                    const { startDate, endDate } = dates;
                    return this.getStoriesAndInsights$(restaurantId, pagination, startDate, endDate);
                }),
                catchError((err) => {
                    this.httpError = err;
                    this.isLoading.set(false);
                    return of(null);
                })
            )
            .subscribe({
                next: (storiesAndInsights) => {
                    if (storiesAndInsights) {
                        const { stories, insights } = storiesAndInsights;
                        this.isLoading.set(false);
                        this.dataSource.data = this._buildStoryInsights({ stories, insights });
                        this.hasNoData.emit(this.dataSource.data.length === 0);
                        if (this.tableSortOptions) {
                            this.defaultSort = this.tableSortOptions;
                        }
                    }
                },
            });
    }

    onPageChange({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }): void {
        this.pagination$.next({ pageNumber: pageIndex, pageSize, total: 0 });
    }

    synchronizeStoriesAndFetchInsights$(restaurantId: string): Observable<{ stories: Story[]; insights: PostInsightsDto[] }> {
        return this._postsInsightsService.synchronizeStoriesAndFetchInsights$(restaurantId);
    }

    getStoriesAndInsights$(
        restaurantId: string,
        pagination: Pagination,
        startDate: Date | null,
        endDate: Date | null
    ): Observable<{ stories: Story[]; insights: PostInsightsDto[] }> {
        return this._postsService
            .getRestaurantPostsPaginated(restaurantId, pagination, {
                startDate,
                endDate,
                category: PostSource.SOCIAL,
                source: PostSource.SOCIAL,
                isStory: true,
                publicationStatus: [PostPublicationStatus.PUBLISHED],
                platforms: PlatformDefinitions.getPlatformKeysWithStories(),
                sortOrder: -1,
            })
            .pipe(
                map((res) => {
                    this.totalStories = res.data.pagination.total;
                    return res.data.posts.map((story) => new Story(story));
                }),
                switchMap((stories) => {
                    // stories insights only exist for Instagram and not Facebook for now
                    const igStoriesSocialIds = stories
                        .filter((story) => story.key === PlatformKey.INSTAGRAM)
                        .map((story) => story.socialId);
                    return forkJoin({
                        stories: of(stories),
                        insights: igStoriesSocialIds.length
                            ? this._postsInsightsService.getStoriesInsights({
                                  restaurantId,
                                  platformKey: PlatformKey.INSTAGRAM,
                                  storiesSocialIds: igStoriesSocialIds,
                              })
                            : of([]),
                    });
                })
            );
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || 'asc', disableClear: true });
    }

    onSortOrderChange(): void {
        const newDirection = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active, start: newDirection, disableClear: true });
    }

    openSocialLink(storyInsights: StoryInsight): void {
        if (storyInsights.socialLink) {
            window.open(storyInsights.socialLink, '_blank');
        }
    }

    getTooltipForValue = (metric: keyof StoryInsight, value: number | null): string => {
        if (isNumber(value) && value < 0) {
            const metricText = this.translate.instant(`statistics.social_networks.stories.${metric}`);
            const valueText = this.translate.instant('statistics.social_networks.stories.less_than_five');
            return `${valueText} ${metricText.toLowerCase()}`;
        } else if (isNumber(value)) {
            return '';
        }
        return this.translate.instant('statistics.social_networks.stories.insights_not_available');
    };

    getValueDisplay = (value: number | null): string => {
        if (!isNumber(value)) {
            return 'N/A';
        }
        return value < 0 ? '< 5' : this._shortNumberPipe.transform(value);
    };

    private _buildStoryInsights({ stories, insights }: { stories: Story[]; insights: PostInsightsDto[] }): StoryInsight[] {
        const insightsMap = this._buildInsightsMapBySocialId(insights);
        return stories.map((story) => {
            const storyInsights = insightsMap[story.socialId];
            return {
                key: story.key,
                hasData: story.key === PlatformKey.INSTAGRAM,
                postType: story.postType,
                socialId: story.socialId,
                socialLink: story.socialLink,
                mediaUrl: story.attachments?.[0]?.urls?.small,
                thumbnailUrl: story.attachments?.[0]?.thumbnail,
                createdAt: story.socialCreatedAt,
                impressions: storyInsights?.data?.find((el) => el.metric === MalouMetric.IMPRESSIONS)?.value ?? null,
                reach: storyInsights?.data?.find((el) => el.metric === MalouMetric.REACH)?.value ?? null,
                tapsExits: storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_EXITS)?.value ?? null,
                tapsForward: storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_FORWARD)?.value ?? null,
                tapsBack: storyInsights?.data?.find((el) => el.metric === MalouMetric.TAPS_BACK)?.value ?? null,
            };
        });
    }

    private _buildInsightsMapBySocialId(insights: PostInsightsDto[]): { [socialId: string]: PostInsightsDto } {
        return keyBy(insights, 'socialId');
    }

    private _reset(): void {
        this.httpError = undefined;
    }
}
