import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, OnInit, output, Signal, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { orderBy, round } from 'lodash';

import { GIFT_PERCENTAGE_DECIMALS } from ':core/constants';
import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

enum WheelOfFortuneGiftsDistributionTableFieldName {
    GIFT = 'gift',
    WINNING = 'winning',
    RETRIEVED = 'retrieved',
    PERCENTAGE = 'percentage',
}

interface WheelOfFortuneGiftsDistributionTableData {
    giftName: string;
    giftDrawCount: number;
    giftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedGiftDrawCount: number;
    retrievedGiftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedPercentage: number;
    isFirst: boolean;
    isSecond: boolean;
    isThird: boolean;
}

@Component({
    selector: 'app-wheel-of-fortune-gifts-distribution-v2',
    templateUrl: './wheel-of-fortune-gifts-distribution.component.html',
    styleUrls: ['./wheel-of-fortune-gifts-distribution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTableModule,
        MatSortModule,
        TranslateModule,
        NumberEvolutionComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        NumberEvolutionComponent,
        SkeletonComponent,
        ImagePathResolverPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneGiftsDistributionV2Component implements OnInit {
    readonly tableSortBy = input<Sort>();
    readonly data = input<WheelOfFortuneGiftsStatisticsData | null>();
    readonly isParentLoading = input<boolean>();
    readonly isParentError = input<boolean>();
    readonly hasDataChange = output<boolean>();
    readonly tableSortByChange = output<Sort>();

    readonly Illustration = Illustration;
    readonly TableFieldName = WheelOfFortuneGiftsDistributionTableFieldName;
    readonly displayedColumns = Object.values(WheelOfFortuneGiftsDistributionTableFieldName);

    dataSource = new MatTableDataSource<WheelOfFortuneGiftsDistributionTableData>([]);
    defaultSort: Sort = {
        active: WheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED,
        direction: ChartSortBy.DESC,
    };

    readonly hasData: Signal<boolean> = computed(() => {
        const data = this.data();
        if (!data) {
            return false;
        }
        const { giftsInsightsPerGift } = data;
        this.hasDataChange.emit(giftsInsightsPerGift.length > 0);
        return giftsInsightsPerGift.length > 0;
    });

    readonly matTableData = computed(() => {
        const data = this.data();
        if (!data) {
            return [];
        }
        const { giftsInsightsPerGift } = data;
        const sortedGiftsInsights = orderBy(giftsInsightsPerGift, 'retrievedGiftDrawCount', ChartSortBy.DESC);
        return sortedGiftsInsights.map((giftInsight, index) => ({
            giftName: giftInsight.gift,
            giftDrawCount: giftInsight.giftDrawCount ?? 0,
            giftDrawCountDifferenceWithPreviousPeriod: (giftInsight.giftDrawCount ?? 0) - (giftInsight.previousGiftDrawCount ?? 0),
            retrievedGiftDrawCount: giftInsight.retrievedGiftDrawCount ?? 0,
            retrievedGiftDrawCountDifferenceWithPreviousPeriod:
                (giftInsight.retrievedGiftDrawCount ?? 0) - (giftInsight.previousRetrievedGiftDrawCount ?? 0),
            retrievedPercentage: giftInsight.giftDrawCount
                ? round((giftInsight.retrievedGiftDrawCount * 100) / giftInsight.giftDrawCount, GIFT_PERCENTAGE_DECIMALS)
                : 0,
            isFirst: index === 0 && giftInsight.retrievedGiftDrawCount !== 0,
            isSecond: index === 1 && giftInsight.retrievedGiftDrawCount !== 0,
            isThird: index === 2 && giftInsight.retrievedGiftDrawCount !== 0,
        }));
    });

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    default:
                    case WheelOfFortuneGiftsDistributionTableFieldName.GIFT:
                        return item.giftName;
                    case WheelOfFortuneGiftsDistributionTableFieldName.WINNING:
                        return item.giftDrawCount ?? -1;
                    case WheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED:
                        return item.retrievedGiftDrawCount ?? -1;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    constructor() {
        effect(() => {
            this.dataSource.data = this.matTableData();
        });
    }

    ngOnInit(): void {
        const tableSortBy = this.tableSortBy();
        if (tableSortBy) {
            this.defaultSort = tableSortBy;
        }
    }
}
