import { Pipe, PipeTransform } from '@angular/core';

import { PlatformKey, PostedStatus } from '@malou-io/package-utils';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

export enum ReviewBadgeStatus {
    PENDING = 'pending',
    REJECTED = 'rejected',
    ANSWERED = 'answered',
    NOT_ANSWERED = 'not_answered',
}

@Pipe({
    name: 'computeBadgeStatus',
    standalone: true,
})
export class ComputeBadgeStatusPipe implements PipeTransform {
    transform(review: Review | PrivateReview): ReviewBadgeStatus | null {
        if (!review.hasReply()) {
            return review.canBeReplied() ? ReviewBadgeStatus.NOT_ANSWERED : null;
        }
        if (
            review.hasOnlyPendingComment() ||
            (review.key === PlatformKey.UBEREATS &&
                [PostedStatus.PENDING, PostedStatus.RETRY].includes((review as Review).comments[review.comments.length - 1]?.posted))
        ) {
            return ReviewBadgeStatus.PENDING;
        }

        if (review.hasRejectedComment()) {
            return ReviewBadgeStatus.REJECTED;
        }

        return ReviewBadgeStatus.ANSWERED;
    }
}
