import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy, isNil } from 'lodash';

import { isNotNil, PlatformKey, WheelOfFortuneRedirectionPlatformKey } from '@malou-io/package-utils';

import { BoostersStatisticsDataV2 } from ':modules/statistics/boosters/boosters.interface';
import {
    ReviewCountData,
    ReviewsCountData,
    TotemsEstimatedReviewCountChartComponent,
} from ':modules/statistics/boosters/totems-estimated-review-count/totems-estimated-review-count-chart/totems-estimated-review-count-chart.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ScanForRestaurantInsights } from ':shared/models/scan';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-statistics-totems-estimated-review-count-v2',
    templateUrl: './totems-estimated-review-count.component.html',
    styleUrls: ['./totems-estimated-review-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        NumberEvolutionComponent,
        SkeletonComponent,
        TotemsEstimatedReviewCountChartComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotemsEstimatedReviewCountV2Component {
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly estimatedReviewCountData = input.required<{
        totemsData: BoostersStatisticsDataV2 | null;
        wheelOfFortuneData: BoostersStatisticsDataV2 | null;
    } | null>();
    readonly isParentLoading = input<boolean>(true);
    readonly isParentError = input<boolean>(false);
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();

    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly STAR_CLICKED_BY_TRANSLATION = {
        [this._translateService.instant('statistics.totems.unknown_star')]: undefined,
        [this._translateService.instant('statistics.totems.one_star')]: 1,
        [this._translateService.instant('statistics.totems.two_stars')]: 2,
        [this._translateService.instant('statistics.totems.three_stars')]: 3,
        [this._translateService.instant('statistics.totems.four_stars')]: 4,
        [this._translateService.instant('statistics.totems.five_stars')]: 5,
    };

    readonly hasData: Signal<boolean> = computed(() => {
        const estimatedReviewCountOnPeriod = this.computedData().estimatedReviewCountOnPeriod;
        const estimatedWofReviewCountOnPeriod = this.computedData().estimatedWofReviewCountOnPeriod;
        const hadData =
            isNotNil(estimatedReviewCountOnPeriod) && isNotNil(estimatedWofReviewCountOnPeriod)
                ? estimatedReviewCountOnPeriod > 0 || estimatedWofReviewCountOnPeriod > 0
                : false;
        this.hasDataChange.emit(hadData);
        return hadData;
    });

    readonly reviewsCountData: Signal<ReviewsCountData> = computed(() => {
        const totemData = this.estimatedReviewCountData()?.totemsData;
        const wheelOfFortuneData = this.estimatedReviewCountData()?.wheelOfFortuneData;
        if (isNil(totemData) || isNil(wheelOfFortuneData)) {
            return [];
        }
        const { scans } = totemData;
        const { scans: wofScans } = wheelOfFortuneData;
        return [
            {
                name: this._translateService.instant('statistics.totems.totems'),
                data: this._getChartDataForScans(scans),
            },
            {
                name: this._translateService.instant('statistics.totems.wheel_of_fortune'),
                data: this._getChartDataForScans(wofScans, true),
            },
        ];
    });

    readonly computedData = computed(() => {
        const totemData = this.estimatedReviewCountData()?.totemsData;
        const wheelOfFortuneData = this.estimatedReviewCountData()?.wheelOfFortuneData;
        if (isNil(totemData) || isNil(wheelOfFortuneData)) {
            return {
                estimatedReviewCountOnPeriod: null,
                estimatedReviewCountDifferenceWithPreviousPeriod: null,
                estimatedUnknownStarsReviewCountOnPeriod: 0,
                estimatedWofReviewCountOnPeriod: null,
                estimatedWofReviewCountDifferenceWithPreviousPeriod: null,
                estimatedWofUnknownStarsReviewCountOnPeriod: 0,
            };
        }
        const { scans, previousScans } = totemData;
        const { scans: wofScans, previousScans: previousWofScans } = wheelOfFortuneData;
        const estimatedReviewCountOnPeriod = scans.filter((scan) => scan.matchedReview?.id).length;
        const estimatedWofReviewCountOnPeriod = wofScans.filter((scan) => scan.matchedReview?.id).length;
        const estimatedReviewCountDifferenceWithPreviousPeriod = this._computeEstimatedReviewCountDifferenceWithPreviousPeriod(
            estimatedReviewCountOnPeriod,
            previousScans
        );
        const estimatedWofReviewCountDifferenceWithPreviousPeriod = this._computeEstimatedReviewCountDifferenceWithPreviousPeriod(
            estimatedWofReviewCountOnPeriod,
            previousWofScans
        );
        const estimatedUnknownStarsReviewCountOnPeriod = scans.filter((scan) => scan.matchedReview?.id && !scan.starClicked).length;
        const estimatedWofUnknownStarsReviewCountOnPeriod = wofScans.filter((scan) => scan.matchedReview?.id && !scan.starClicked).length;
        return {
            estimatedReviewCountOnPeriod,
            estimatedReviewCountDifferenceWithPreviousPeriod,
            estimatedUnknownStarsReviewCountOnPeriod,
            estimatedWofReviewCountOnPeriod,
            estimatedWofReviewCountDifferenceWithPreviousPeriod,
            estimatedWofUnknownStarsReviewCountOnPeriod,
        };
    });

    private _getChartDataForScans(nfcScans: ScanForRestaurantInsights[], isWof = false): ReviewCountData {
        return Object.entries(this.STAR_CLICKED_BY_TRANSLATION).map(([starName, starValue]) => {
            const scansFiltered = nfcScans.filter((scan) => {
                const condition = isWof ? scan.matchedReview?.rating === starValue : scan.starClicked === starValue;
                return (
                    scan.matchedReview?.id &&
                    condition &&
                    scan.nfcSnapshot?.platformKey !== WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION
                );
            });
            const scansByNfcName = groupBy(scansFiltered, (value) => value.nfcSnapshot?.chipName ?? value.nfcSnapshot?.name);

            const scansCountByNfcName: ReviewCountData[0]['count'] = [];
            for (const scanByNfcName of Object.entries(scansByNfcName)) {
                const [nfcName, allNfcScans] = scanByNfcName;
                const scansByPlatformName = groupBy(allNfcScans, (value) => value.nfcSnapshot?.platformKey);
                const scansCountByNfcPlatform: Partial<Record<PlatformKey, number>> = Object.entries(scansByPlatformName).reduce(
                    (acc, [platformKey, scans]) => {
                        if (platformKey) {
                            acc[platformKey as PlatformKey] = scans.length;
                        }
                        return acc;
                    },
                    {}
                );
                scansCountByNfcName.push({
                    chipName: nfcName,
                    total: allNfcScans.length,
                    countPerPlatform: scansCountByNfcPlatform,
                });
            }

            return {
                star: starName,
                starValue: starValue ?? -1,
                total: scansFiltered.length,
                count: scansCountByNfcName,
            };
        });
    }

    private _computeEstimatedReviewCountDifferenceWithPreviousPeriod(
        currentPeriodCount: number,
        previousPeriodScans: ScanForRestaurantInsights[]
    ): number | null {
        if (!currentPeriodCount) {
            return null;
        }
        return currentPeriodCount - previousPeriodScans.filter((scan) => scan.matchedReview?.id).length;
    }
}
