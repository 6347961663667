import { NgClass, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of, Subject, switchMap, tap } from 'rxjs';

import { errorReplacer } from '@malou-io/package-utils';

import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import {
    CloseWithoutSavingAction,
    CloseWithoutSavingModalComponent,
} from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { Step } from '../../interfaces/step.interface';

type StepperModalInitialData = unknown;
type StepSharedData = unknown;
type StepInputData = unknown;
type StepOutputData = unknown;
interface StepperModalData {
    validateButtonId?: string;
}

@Component({
    selector: 'app-stepper',
    standalone: true,
    templateUrl: './stepper-modal.component.html',
    styleUrls: ['./stepper-modal.component.scss'],
    imports: [
        NgClass,
        CloseWithoutSavingModalComponent,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        DynamicComponentDirective,
        ApplyPurePipe,
        NgStyle,
    ],
})
export class StepperModalComponent implements AfterViewInit {
    readonly SvgIcon = SvgIcon;
    @ViewChild(DynamicComponentDirective, { static: false }) dynamicComponent: DynamicComponentDirective;

    stepperModalData?: StepperModalData;
    steps: Step<StepInputData, StepSharedData>[];
    initialData: StepperModalInitialData;
    sharedData: StepSharedData;
    title: string;

    currentStepIndex = 0;
    currentStep: Step<StepInputData, StepSharedData>;
    stepOutputs: unknown[] = [];
    stepInputs: unknown[] = [];
    askSubmitSubject$: Subject<void> = new Subject<void>();

    isNextButtonDisabled = false;
    showButtons = true;

    displayCloseModal = false;

    shouldDisplayConfirmationCloseModalAfterClosed = false;
    malouDialogBodyCustomStyle: Record<string, any> = {};
    openWithoutSavingCheckAsModal: boolean;

    isSubmitting = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            stepperModalData?: StepperModalData;
            steps: Step<StepInputData, StepSharedData>[];
            title: string;
            initialData?: StepperModalInitialData;
            sharedData?: StepSharedData;
            onSuccess?: (result: unknown) => unknown;
            onError?: (error: unknown) => void;
            shouldDisplayConfirmationCloseModalAfterClosed?: boolean;
            malouDialogBodyCustomStyle?: Record<string, any>;
            fullScreenStepIndexes?: number[];
            openWithoutSavingCheckAsModal?: boolean;
        },
        private readonly _dialogRef: MatDialogRef<StepperModalComponent>,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _customDialogService: CustomDialogService
    ) {
        this.stepperModalData = data.stepperModalData;
        this.steps = data.steps;
        this.initialData = data.initialData;
        this.sharedData = data.sharedData;
        this.title = data.title;
        this.shouldDisplayConfirmationCloseModalAfterClosed = data.shouldDisplayConfirmationCloseModalAfterClosed ?? false;
        this.currentStep = this.steps[this.currentStepIndex];
        this.malouDialogBodyCustomStyle = data.malouDialogBodyCustomStyle ?? {};
        this.openWithoutSavingCheckAsModal = data.openWithoutSavingCheckAsModal ?? false;
        if (data.onSuccess) {
            this.onSuccess = data.onSuccess;
        }
        if (data.onError) {
            this.onError = data.onError;
        }
    }

    ngAfterViewInit(): void {
        this.loadComponent(this.currentStep, this.initialData);
        this._changeDetectorRef.detectChanges();
    }

    onSuccess: (result: unknown) => unknown = () => this.close();
    onError: (error: unknown) => void = (error) => {
        const errorMessage = JSON.stringify(error, errorReplacer);
        console.error(error);
        this._toastService.openErrorToast(errorMessage);
    };

    close({ data }: { data?: unknown } = {}): void {
        if (this.shouldDisplayConfirmationCloseModalAfterClosed) {
            this._customDialogService
                .open(CloseWithoutSavingModalComponent, {
                    height: 'unset',
                    width: 'unset',
                    data: {
                        isOpenedAsModal: true,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.action === CloseWithoutSavingAction.CONFIRMED) {
                        this.confirmClose({ data });
                    } else if (result.action === CloseWithoutSavingAction.CANCELLED) {
                        this.displayCloseModal = false;
                    }
                });
        } else {
            this.confirmClose({ data });
        }
    }

    confirmClose({ data }: { data?: unknown } = {}): void {
        this._dialogRef.close({ data });
    }

    loadComponent(step: Step<StepInputData, StepSharedData>, inputData: StepInputData): void {
        const viewContainerRef = this.dynamicComponent.viewContainerRef;
        viewContainerRef.clear();

        // add the component to the view
        const componentRef = viewContainerRef.createComponent(step.component);
        componentRef.setInput('inputData', inputData);
        componentRef.setInput('sharedData', this.sharedData);
        componentRef.setInput('askEmitSubmit$', this.askSubmitSubject$.asObservable());

        componentRef.instance.submit
            .pipe(
                tap(() => (this.isSubmitting = true)),
                switchMap((data: StepInputData) => {
                    this.stepOutputs[this.currentStepIndex] = data;
                    return step.nextFunction$ ? step.nextFunction$(data) : of(data);
                })
            )
            .subscribe({
                next: (result: StepOutputData) => {
                    this.isSubmitting = false;
                    const shouldPreventNextStep = this._getShouldPreventNextStep(result);
                    if (shouldPreventNextStep) {
                        return;
                    }
                    if (this._hasNextStep()) {
                        this.stepInputs[this.currentStepIndex] = result;
                        this._nextStep();
                        return;
                    }
                    this.onSuccess(result);
                },
                error: (error: unknown): void => {
                    this.isSubmitting = false;
                    this.onError(error);
                },
            });
        componentRef.instance.valid.subscribe((isValid: boolean) => setTimeout(() => (this.isNextButtonDisabled = !isValid)));
        componentRef.instance.showButtons.subscribe((showButtons: boolean) => setTimeout(() => (this.showButtons = showButtons)));
    }

    previousStep(): void {
        if (!this._hasPreviousStep()) {
            this.close();
            return;
        }
        this._previousStep();
    }

    nextStep(): void {
        this.askSubmitSubject$.next();
    }

    getSecondaryButtonText = (): string => {
        if (this.currentStep.secondaryButtonText) {
            return this.currentStep.secondaryButtonText;
        }
        return this._hasPreviousStep() ? this._translateService.instant('common.back') : this._translateService.instant('common.cancel');
    };

    getPrimaryButtonText = (): string => {
        if (this.currentStep.primaryButtonText) {
            return this.currentStep.primaryButtonText;
        }
        return this._hasNextStep() ? this._translateService.instant('common.next') : this._translateService.instant('common.save');
    };

    isStepWithFullScreen(): boolean {
        return this.data.fullScreenStepIndexes?.includes(this.currentStepIndex) ?? false;
    }

    private _hasPreviousStep(): boolean {
        return this.currentStepIndex > 0;
    }

    private _hasNextStep(): boolean {
        return this.currentStepIndex < this.steps.length - 1;
    }

    private _previousStep(): void {
        this.currentStepIndex--;
        this.currentStep = this.steps[this.currentStepIndex];
        this.loadComponent(this.currentStep, this.stepOutputs[this.currentStepIndex]);
    }

    private _nextStep(): void {
        const inputData = this.stepInputs[this.currentStepIndex];
        this.currentStepIndex++;
        this.currentStep = this.steps[this.currentStepIndex];
        this.loadComponent(this.currentStep, inputData);
    }

    private _getShouldPreventNextStep(result?: any): boolean {
        return result && 'shouldPreventNextStep' in result && !!result.shouldPreventNextStep;
    }
}
