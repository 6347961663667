<div class="flex flex-col px-8">
    <div class="flex items-center gap-x-2">
        <div class="flex gap-x-1">
            <span class="malou-text-18--bold">{{ 'admin.restaurants.businesses' | translate }}</span>
            <span class="malou-text-18--regular">({{ dataSource.filteredData.length }})</span>
        </div>
        <div class="grow">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
        </div>
    </div>

    <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
</div>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [trackBy]="trackByIdFn"
        [dataSource]="dataSource"
        [matSortActive]="defaultSort.active"
        [matSortDirection]="defaultSort.direction"
        #table="matTable">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.restaurants.name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table">
                <a class="malou-color-primary malou-text-12--semibold" [routerLink]="'/restaurants/' + restaurant._id">
                    {{ restaurant.name }}
                </a>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="organization">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.restaurants.organization' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table">
                {{ restaurant.organization?.name }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.restaurants.address' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table">
                @if (!restaurant.isBrandBusiness()) {
                    {{ restaurant.address?.formattedAddress }}
                    {{ restaurant.address?.postalCode }}
                    {{ restaurant.address?.locality }}
                } @else {
                    {{ 'admin.restaurants.brand_account' | translate }}
                }
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="managers">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.restaurants.managers' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table">
                <div class="flex flex-col">
                    @for (manager of restaurant.managers | slice: 0 : 3; track trackByIdFn($index, manager); let last = $last) {
                        <div>
                            {{ manager.user.email }}
                            @if (last && restaurant.managers.length > 3) {
                                <span
                                    class="malou-text-13--semibold malou-color-text-primary"
                                    (click)="displayManagersListModal(restaurant.managers)"
                                    >+{{ restaurant.managers.length - 3 }}</span
                                >
                            }
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="functionalities">
            <mat-header-cell *matHeaderCellDef class="mr-6">
                {{ 'admin.restaurants.functionalities' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table" class="mr-6">
                <div class="flex flex-col gap-y-1 py-1">
                    <div class="flex items-center justify-between gap-x-4">
                        <div>{{ 'admin.restaurants.booster_pack' | translate }}</div>
                        <div>
                            <app-slide-toggle
                                [checked]="restaurant.boosterPack ? restaurant.boosterPack.activated : false"
                                (onToggle)="updateIsBoosterFeatureActivated(!$event, restaurant._id)">
                            </app-slide-toggle>
                        </div>
                    </div>
                    <div class="flex items-center justify-between gap-x-4">
                        <div>{{ 'admin.restaurants.roi' | translate }}</div>
                        <div>
                            <app-slide-toggle
                                [checked]="restaurant.roiActivated"
                                (onToggle)="updateIsRoiActivated(!$event, restaurant._id)">
                            </app-slide-toggle>
                        </div>
                    </div>
                    @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
                        <div class="flex items-center justify-between gap-x-4">
                            <div>{{ 'admin.restaurants.yext.feature' | translate }}</div>
                            <div>
                                <app-slide-toggle [checked]="restaurant.isYextActivated" (onToggle)="openYextConfirmModal(restaurant)">
                                </app-slide-toggle>
                            </div>
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                {{ 'admin.restaurants.active' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table" class="malou-mat-table-cell-xsmall">
                <app-slide-toggle [checked]="restaurant.active" (onToggle)="proceedUpdateActive(restaurant, !$event)"> </app-slide-toggle>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isManagedByLoggedUser">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                {{ 'admin.restaurants.management' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table" class="malou-mat-table-cell-xsmall">
                <app-slide-toggle
                    [checked]="restaurant.isManagedByLoggedUser ?? false"
                    (onToggle)="updateIsManagedByLoggedUser(restaurant._id, !restaurant.isManagedByLoggedUser)">
                </app-slide-toggle>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-fit-content"></mat-header-cell>
            <mat-cell *matCellDef="let restaurant; table: table" class="malou-mat-table-cell-fit-content">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS" [matMenuTriggerFor]="actionsMenu"></mat-icon>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="updateRestaurant(restaurant)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
                    </button>
                </mat-menu>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let restaurant; columns: displayedColumns; table: table; let i = index"></mat-row>
    </mat-table>
    <app-paginator [shouldBeHidden]="dataSource.filteredData.length < 5" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"> </app-paginator>
</ng-template>
