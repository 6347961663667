import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, OnInit, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { InsightsChart, InsightsTab } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT } from ':modules/aggregated-statistics/aggregated-statistics.component';
import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { GmbActionsComponent } from ':modules/aggregated-statistics/seo/gmb-actions/gmb-actions.component';
import { GmbImpressionsComponent } from ':modules/aggregated-statistics/seo/gmb-impressions/gmb-impressions.component';
import { KeywordsV3Component } from ':modules/aggregated-statistics/seo/keywords/keywords-v3.component';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as StatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { GmbInsightsContext } from './context/gmb-insights.context';
import { GmbActionsV2Component } from './gmb-actions-v2/gmb-actions-v2.component';
import { GmbImpressionsV2Component } from './gmb-impressions-v2/gmb-impressions-v2.component';

@Component({
    selector: 'app-seo',
    templateUrl: './seo.component.html',
    styleUrls: ['./seo.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        KeywordsV3Component,
        GmbActionsComponent,
        GmbImpressionsComponent,
        FiltersComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatButtonModule,
        GmbActionsV2Component,
        GmbImpressionsV2Component,
    ],
})
export class SeoComponent implements OnInit, KillSubscriptions {
    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly PlatformFilterPage = PlatformFilterPage;

    readonly gmbInsightsContext = inject(GmbInsightsContext);

    readonly isAggregatedGmbInsightsChartsV2Enabled: Signal<boolean> = toSignal(
        this.experimentationService.isFeatureEnabled$('release-aggregated--gmb-insights-charts-v2'),
        {
            initialValue: false,
        }
    );

    restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    isRestaurantsCountUiLimitExceeded$: Observable<boolean> = this.restaurants$.pipe(
        map((restaurants) => restaurants.length > AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT)
    );
    readonly InsightsChart = InsightsChart;
    chartOptions: ChartOptions = {
        [InsightsChart.AGGREGATED_APPARITIONS]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.AGGREGATED_ACTIONS]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.AGGREGATED_RANKINGS]: {
            tableSortOptions: undefined,
        },
    };

    isKeywordsLoading = signal(true);
    isImpressionsLoading = signal(true);
    isActionsLoading = signal(true);

    isLoading = computed(
        () =>
            this.isKeywordsLoading() ||
            (this.isAggregatedGmbInsightsChartsV2Enabled()
                ? this.gmbInsightsContext.isLoading()
                : this.isImpressionsLoading() || this.isActionsLoading())
    );

    constructor(
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly experimentationService: ExperimentationService,
        public readonly screenSizeService: ScreenSizeService
    ) {}

    ngOnInit(): void {
        this._store.dispatch(AggregatedStatisticsActions.editSelectedPage({ page: PlatformFilterPage.SEO }));

        this.experimentationService
            .isFeatureEnabled$('release-aggregated--gmb-insights-charts-v2')
            .pipe(
                filter((isEnabled) => isEnabled),
                switchMap(() => this.gmbInsightsContext.gmbAggregatedInsights$),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe();
    }

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(StatisticsSelectors.selectDatesFilter),
            this._store.select(StatisticsSelectors.selectRestaurantIdsFilter),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, restaurantIds]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.AGGREGATED_SEO,
                                filters: {
                                    dates: { startDate, endDate },
                                    restaurantIds,
                                },
                                chartOptions: this.chartOptions,
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                chartSortBy: value,
            },
        };
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                hiddenDatasetIndexes: value,
            },
        };
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions = {
            ...this.chartOptions,
            [chart]: {
                ...this.chartOptions[chart],
                tableSortOptions: value,
            },
        };
    }
}
