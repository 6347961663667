import { AggregatedPlatformInsightsDto, StoredInsightsAggregatedResponseDto, StoredInsightsResponseDto } from '@malou-io/package-dto';
import { MalouMetric } from '@malou-io/package-utils';

interface MetricAndKey {
    metric: MalouMetric;
    key: string;
}

export type GmbInsights = StoredInsightsResponseDto;

export type GmbAggregatedInsights = StoredInsightsAggregatedResponseDto;
export type AggregatedPlatformInsights = AggregatedPlatformInsightsDto;

export const GMB_METRICS: MetricAndKey[] = [
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
        key: 'impressionsDesktopMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
        key: 'impressionsDesktopSearch',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
        key: 'impressionsMobileMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
        key: 'impressionsMobileSearch',
    },
    {
        metric: MalouMetric.ACTIONS_WEBSITE,
        key: 'websiteClicks',
    },
    {
        metric: MalouMetric.ACTIONS_PHONE,
        key: 'phoneClicks',
    },
    {
        metric: MalouMetric.ACTIONS_DRIVING_DIRECTIONS,
        key: 'drivingClicks',
    },
    {
        metric: MalouMetric.ACTIONS_MENU_CLICK,
        key: 'menuClicks',
    },
    {
        metric: MalouMetric.ACTIONS_BOOKING_CLICK,
        key: 'bookingClicks',
    },
    {
        metric: MalouMetric.BUSINESS_FOOD_ORDERS,
        key: 'foodOrderClicks',
    },
];
