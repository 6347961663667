import { Injectable } from '@angular/core';
import { FeatureDefinition, GrowthBook } from '@growthbook/growthbook';
import { BehaviorSubject, catchError, from, map, Observable, tap } from 'rxjs';

import { AppFeatureName } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { User } from ':modules/user/user';

@Injectable({ providedIn: 'root' })
export class ExperimentationService {
    _growthbook = new GrowthBook({
        apiHost: environment.experimentation_app_proxy_url,
        clientKey: environment.experimentation_app_client_key,
        enableDevMode: true,
        subscribeToChanges: true,
        realtimeInterval: 5,
        enabled: true,
        log: (context): void => {
            if (environment.environment !== 'production') {
                console.info('GrowthBook log', context);
            }
        },
        streamingHost: environment.experimentation_app_proxy_url,
        backgroundSync: true,
        trackingCallback: (experiment, result): void => {
            if (environment.environment !== 'production') {
                console.info('Viewed Experiment', {
                    experimentId: experiment.key,
                    variationId: result.key,
                });
            }
        },
    });

    public isLoaded$ = new BehaviorSubject<boolean>(false);
    private _features$: BehaviorSubject<Record<string, FeatureDefinition>> = new BehaviorSubject({});

    constructor() {}

    load(user: User, userRestaurantIds: string[]): Observable<unknown> {
        try {
            this._growthbook.setAttributes({
                ...user,
                restaurantIds: userRestaurantIds,
            });
        } catch (error) {
            this.isLoaded$.next(true);
        }
        return from(this._growthbook.loadFeatures({})).pipe(
            tap(() => {
                if (environment.environment !== 'production') {
                    console.info('Growthbook loaded with features :', this._growthbook.getFeatures());
                }
                this._features$.next(this._growthbook.getFeatures()); // subscribe seems not to work locally so we need to manually update the features once
                this.isLoaded$.next(true);
                this._growthbook.subscribe(() => {
                    this._features$.next(this._growthbook.getFeatures());
                });
            }),
            catchError((error) => {
                console.error('Error loading Growthbook', error);
                this.isLoaded$.next(true);
                return error;
            })
        );
    }

    isFeatureEnabled(featureKey: AppFeatureName): boolean {
        return this._growthbook.isOn(featureKey);
    }

    isFeatureEnabled$(featureKey: AppFeatureName): Observable<boolean> {
        return this._features$.pipe(map(() => this._growthbook.isOn(featureKey)));
    }

    updateCurrentRestaurantId(restaurantId?: string): void {
        this._growthbook.setAttributes({
            ...this._growthbook.getAttributes(),
            restaurantId,
        });
        this._features$.next(this._growthbook.getFeatures());
    }
}
