import { z } from 'zod';

import { restaurantIdParamsTransformValidator } from '../common';

export interface GetSocialPostsCountsDto {
    total: number;
    error: number;
    draft: number;
    feedbacks: number;
}

export const getSocialPostsCountsParamsValidator = restaurantIdParamsTransformValidator;
export type GetSocialPostsCountsParamsDto = z.infer<typeof getSocialPostsCountsParamsValidator>;
