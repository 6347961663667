import { z } from 'zod';

import { MediaType, PlatformKey, PostPublicationStatus, PostType, SocialPostsListFilter } from '@malou-io/package-utils';

import { restaurantIdParamsTransformValidator } from '../common';
import { PostHashtagsDto } from './post-hashtags.dto';

export interface SocialPostItemDto {
    id: string;
    text: string;
    platformKeys: PlatformKey[];
    published: PostPublicationStatus;
    postType: PostType;
    feedbackMessageCount: number;
    plannedPublicationDate: Date | null;
    thumbnail: SocialPostMediaDto | null;
    hashtags: PostHashtagsDto;
    error: {
        code: string;
        rawData: string;
    } | null;
    socialLink?: string;
    socialCreatedAt?: Date;
    sortDate?: Date;
}

export interface SocialPostMediaDto {
    url: string;
    type: MediaType;
    thumbnailUrl?: string;
}

export const getSocialPostsQueryValidator = z
    .object({
        cursor: z.string().datetime().nullish(),
        limit: z.coerce.number().int().positive().nullish(),
        filter: z.nativeEnum(SocialPostsListFilter).nullish(),
    })
    .transform((data) => ({
        cursor: data.cursor ? new Date(data.cursor) : null,
        limit: data.limit ?? null,
        filter: data.filter ?? SocialPostsListFilter.ALL,
    }));

export type GetSocialPostsQueryDto = z.infer<typeof getSocialPostsQueryValidator>;

export const getSocialPostsParamsValidator = restaurantIdParamsTransformValidator;
export type GetSocialPostsParamsDto = z.infer<typeof getSocialPostsParamsValidator>;
