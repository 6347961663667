import { GetReviewAnalysesChartDataByRestaurantIdResponseDto } from '@malou-io/package-dto';
import { ReviewAnalysisChartDataSentiment, ReviewAnalysisChartDataTag } from '@malou-io/package-utils';

type ScoreSentiment = Record<ReviewAnalysisChartDataSentiment, number>;

export class ReviewAnalysesChartDataByRestaurantId extends Map<string, Record<ReviewAnalysisChartDataTag, ScoreSentiment>> {
    constructor(data: Map<string, Record<ReviewAnalysisChartDataTag, ScoreSentiment>>) {
        super(data);
    }

    static fromDto(data: GetReviewAnalysesChartDataByRestaurantIdResponseDto): ReviewAnalysesChartDataByRestaurantId {
        const newMap = new Map();
        Object.keys(data).forEach((restaurantId) => {
            newMap.set(restaurantId, data[restaurantId]);
        });
        return new ReviewAnalysesChartDataByRestaurantId(newMap);
    }

    merge(data: ReviewAnalysesChartDataByRestaurantId): ReviewAnalysesChartDataByRestaurantId {
        const restaurantIds = [...data.keys()];
        restaurantIds.forEach((restaurantId) => {
            const value = data.get(restaurantId);
            if (value) {
                this.set(restaurantId, value);
            }
        });
        return new ReviewAnalysesChartDataByRestaurantId(this);
    }

    deleteMany(restaurantIds: string[]): ReviewAnalysesChartDataByRestaurantId {
        restaurantIds.forEach((restaurantId) => {
            this.delete(restaurantId);
        });
        return new ReviewAnalysesChartDataByRestaurantId(this);
    }

    getCount(tag: ReviewAnalysisChartDataTag, sentiment: ReviewAnalysisChartDataSentiment): number {
        return [...this.values()].filter(Boolean).reduce((acc, value) => acc + value[tag][sentiment], 0);
    }

    hasData(tag: ReviewAnalysisChartDataTag): boolean {
        return this.size > 0 && this.getCount(tag, ReviewAnalysisChartDataSentiment.TOTAL) > 0;
    }

    getSentimentPercentage(tag: ReviewAnalysisChartDataTag): {
        positiveSentimentsPercentage: number;
        negativeSentimentsPercentage: number;
    } {
        if (!this.hasData(tag)) {
            return {
                positiveSentimentsPercentage: 0,
                negativeSentimentsPercentage: 0,
            };
        }

        const total = this.getCount(tag, ReviewAnalysisChartDataSentiment.TOTAL);
        const positive = this.getCount(tag, ReviewAnalysisChartDataSentiment.POSITIVE);
        const negative = this.getCount(tag, ReviewAnalysisChartDataSentiment.NEGATIVE);

        // Ensure percentages add up to 100%
        const rawPositive = total ? (positive * 100) / total : 0;
        const rawNegative = total ? (negative * 100) / total : 0;
        const sum = Math.round(rawPositive) + Math.round(rawNegative);
        const adjustment = sum > 100 ? sum - 100 : 0;

        return {
            positiveSentimentsPercentage: Math.round(rawPositive) - Math.floor(adjustment / 2),
            negativeSentimentsPercentage: Math.round(rawNegative) - Math.ceil(adjustment / 2),
        };
    }
}
