<div class="malou-dialog-container" [class.border-error]="data.variant === 'error'">
    <div class="malou-dialog">
        <!-- justify end close button cross icon -->
        <div class="malou-dialog__header !justify-end">
            <button class="malou-btn-icon" mat-icon-button (click)="onCloseButtonClick()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
        <!-- dialog content -->
        <div class="malou-dialog__body !overflow-hidden text-center">
            <div class="mb-6 grid w-full place-items-center">
                @switch (data.variant) {
                    @case ('error') {
                        <img
                            class="w-[90px]"
                            alt="{{ data.illustration ?? Illustration.Cape }} illustration"
                            [src]="data.illustration ?? Illustration.Cape | illustrationPathResolver" />
                    }
                    @default {
                        <img
                            class="w-[90px]"
                            alt="{{ data.illustration ?? Illustration.Stove }} illustration"
                            [ngStyle]="data.customIllustrationWidth ? { width: data.customIllustrationWidth } : {}"
                            [src]="data.illustration ?? Illustration.Stove | illustrationPathResolver" />
                    }
                }
            </div>
            <div class="malou-text-10--regular break-all text-malou-color-text-2">
                <span>{{ readableDateWithTime }}</span>
            </div>
            <div class="malou-text-18--bold mb-4 break-all text-malou-color-text-1">
                <span [outerHTML]="data.title"></span>
            </div>

            <div class="malou-text-13--regular break-all text-malou-color-text-2">
                <span [outerHTML]="data.message"></span>
            </div>
        </div>

        <!-- dialog footer -->
        <div class="malou-dialog__footer !justify-center gap-x-2">
            @if (data.secondaryButton) {
                <button
                    class="malou-btn-raised--secondary !h-11"
                    data-testid="malou-dialog-secondary-btn"
                    mat-raised-button
                    (click)="onSecondaryButtonClick()">
                    {{ data.secondaryButton.label }}
                </button>
            }
            <button
                class="malou-btn-raised--primary !h-11"
                data-testid="malou-dialog-primary-btn"
                mat-raised-button
                (click)="onPrimaryButtonClick()">
                {{ data.primaryButton.label }}
            </button>
        </div>
    </div>
</div>
