import { z } from 'zod';

import { HeapEventName, NotificationChannel, NotificationType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export interface NotificationDTO {
    id: string;
    userId: string;
    restaurantId?: string;
    data: Record<string, any>;
    type: NotificationType;
    readAt?: Date | null;
    error?: string;
    channel: NotificationChannel;
    completedAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export const notificationDTOValidator = z.object({
    id: objectIdValidator,
    userId: objectIdValidator,
    restaurantId: objectIdValidator.optional(),
    data: z.record(z.any()),
    type: z.nativeEnum(NotificationType),
    readAt: z
        .string()
        .nullish()
        .transform((value) => (value ? new Date(value) : null))
        .optional(),
    error: z.string().optional(),
    channel: z.nativeEnum(NotificationChannel),
    completedAt: z
        .string()
        .nullish()
        .transform((value) => (value ? new Date(value) : null))
        .optional(),
    createdAt: z.date(),
    updatedAt: z.date(),
});

// ------------------------------------------------------------
export const trackNotificationActionBodyValidator = z.object({
    heapEventName: z.nativeEnum(HeapEventName),
    properties: z
        .object({
            eventName: z.string().optional(),
            restaurantId: objectIdValidator.optional(),
            notificationType: z.nativeEnum(NotificationType).optional(),
            channel: z.nativeEnum(NotificationChannel).optional(),
        })
        .optional(),
    notificationId: objectIdValidator,
});

export type TrackNotificationActionBodyDto = z.infer<typeof trackNotificationActionBodyValidator>;
