import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { round } from 'lodash';
import { combineLatest, Observable, tap } from 'rxjs';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedWheelOfFortuneGiftsStatisticsData } from '../booster.interface';

const GIFT_PERCENTAGE_DECIMALS = 1;

enum AggregatedWheelOfFortuneGiftsDistributionTableFieldName {
    BUSINESS = 'business',
    WINNING = 'winning',
    RETRIEVED = 'retrieved',
    PERCENTAGE = 'percentage',
}

interface AggregatedWheelOfFortuneGiftsDistributionTableData {
    restaurantId: string;
    restaurantName: string;
    giftDrawCount: number;
    giftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedGiftDrawCount: number;
    retrievedGiftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedPercentage: number;
}

@Component({
    selector: 'app-aggregated-wheel-of-fortune-gifts-distribution',
    templateUrl: './aggregated-wheel-of-fortune-gifts-distribution.component.html',
    styleUrls: ['./aggregated-wheel-of-fortune-gifts-distribution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTableModule,
        MatSortModule,
        TranslateModule,
        NumberEvolutionComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedWheelOfFortuneGiftsDistributionComponent implements OnInit {
    @Input() tableSort?: Sort;
    @Input() data$: Observable<AggregatedWheelOfFortuneGiftsStatisticsData>;
    @Input() restaurants$: Observable<Restaurant[]>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    readonly TableFieldName = AggregatedWheelOfFortuneGiftsDistributionTableFieldName;

    readonly displayedColumns = Object.values(AggregatedWheelOfFortuneGiftsDistributionTableFieldName);
    readonly trackByIdFn = TrackByFunctionFactory.get('restaurantId');
    readonly dataSource: MatTableDataSource<AggregatedWheelOfFortuneGiftsDistributionTableData> =
        new MatTableDataSource<AggregatedWheelOfFortuneGiftsDistributionTableData>([]);

    isLoading: WritableSignal<boolean> = signal(false);
    isError: WritableSignal<boolean> = signal(false);

    defaultSort: Sort = {
        active: this.tableSort?.active ?? AggregatedWheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED,
        direction: this.tableSort?.direction ?? ChartSortBy.DESC,
    };

    hasData: WritableSignal<boolean> = signal(true);

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    default:
                    case AggregatedWheelOfFortuneGiftsDistributionTableFieldName.BUSINESS:
                        return item.restaurantName;
                    case AggregatedWheelOfFortuneGiftsDistributionTableFieldName.WINNING:
                        return item.giftDrawCount ?? -1;
                    case AggregatedWheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED:
                        return item.retrievedGiftDrawCount ?? -1;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    ngOnInit(): void {
        if (this.tableSort) {
            this.defaultSort = this.tableSort;
        }
        combineLatest([this.data$, this.restaurants$])
            .pipe(
                tap(() => {
                    this.isLoading.set(true);
                    this.isError.set(false);
                })
            )
            .subscribe({
                next: ([{ giftsInsightsPerRestaurant }, restaurants]) => {
                    this.hasData.set(giftsInsightsPerRestaurant.length > 0);
                    this.hasDataChange.emit(giftsInsightsPerRestaurant.length > 0);
                    this.dataSource.data = restaurants.map((restaurant) => {
                        const giftsInsights = giftsInsightsPerRestaurant.find(
                            (giftInsights) => giftInsights.restaurant === restaurant.name
                        );
                        if (!giftsInsights) {
                            return {
                                restaurantId: restaurant?._id ?? '',
                                restaurantName: restaurant?.getDisplayedValue() ?? '',
                                giftDrawCount: 0,
                                giftDrawCountDifferenceWithPreviousPeriod: null,
                                retrievedGiftDrawCount: 0,
                                retrievedGiftDrawCountDifferenceWithPreviousPeriod: null,
                                retrievedPercentage: 0,
                            };
                        }
                        return {
                            restaurantId: restaurant?._id ?? '',
                            restaurantName: restaurant?.getDisplayedValue() ?? '',
                            giftDrawCount: giftsInsights.giftDrawCount ?? 0,
                            giftDrawCountDifferenceWithPreviousPeriod:
                                (giftsInsights.giftDrawCount ?? 0) - (giftsInsights.previousGiftDrawCount ?? 0),
                            retrievedGiftDrawCount: giftsInsights.retrievedGiftDrawCount ?? 0,
                            retrievedGiftDrawCountDifferenceWithPreviousPeriod:
                                (giftsInsights.retrievedGiftDrawCount ?? 0) - (giftsInsights.previousRetrievedGiftDrawCount ?? 0),
                            retrievedPercentage: giftsInsights.giftDrawCount
                                ? round(
                                      (giftsInsights.retrievedGiftDrawCount * 100) / giftsInsights.giftDrawCount,
                                      GIFT_PERCENTAGE_DECIMALS
                                  )
                                : 0,
                        };
                    });
                    this.isLoading.set(false);
                    this.isError.set(false);
                },
                error: (error) => {
                    console.warn(error);
                    this.isError.set(false);
                    this.isLoading.set(false);
                },
            });
    }
}
