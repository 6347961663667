import { Component, computed, input, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Tick } from 'chart.js/dist/types';
import { NgChartsModule } from 'ng2-charts';

import { isNotNil, ReviewAnalysisChartDataTag, SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ChartDataArray, ChartDataElement, malouChartColorGreen, malouChartColorRed } from ':shared/helpers';
import { Restaurant } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

const RESTAURANT_NAME_MAX_LENGTH = 20;

type BarChartType = Extract<ChartType, 'bar'>;

export interface TagEvolutionData {
    [SemanticAnalysisSentiment.POSITIVE]: ChartDataArray;
    [SemanticAnalysisSentiment.NEGATIVE]: ChartDataArray;
}

@Component({
    selector: 'app-tags-evolution-chart',
    templateUrl: './tags-evolution-chart.component.html',
    styleUrls: ['./tags-evolution-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
})
export class TagsEvolutionChartComponent {
    chartData = input.required<ReviewAnalysesChartDataByRestaurantId>();
    currentTag = input.required<ReviewAnalysisChartDataTag>();
    restaurants = input.required<Restaurant[]>();
    sortBy = input.required<ChartSortBy>();

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: Signal<ChartDataset<BarChartType, ChartDataArray>[]> = computed(() => {
        const { evolutionData } = this._computeSortedData(this.chartData(), this.currentTag(), this.restaurants(), this.sortBy());
        return this._computeChartData(evolutionData);
    });
    chartLabels: Signal<string[]> = computed(() => {
        const { labels } = this._computeSortedData(this.chartData(), this.currentTag(), this.restaurants(), this.sortBy());
        return this._computeChartLabels(labels);
    });
    chartOptions: ChartOptions<BarChartType>;

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _translateService: TranslateService,
        private readonly _shortTextPipe: ShortTextPipe
    ) {}

    private _computeChartData(tagEvolutionData: TagEvolutionData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this._translateService.instant('aggregated_statistics.e_reputation.reviews_analysis.negative_sentiments'),
                borderColor: malouChartColorRed,
                backgroundColor: malouChartColorRed,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: tagEvolutionData[SemanticAnalysisSentiment.NEGATIVE],
            },
            {
                label: this._translateService.instant('aggregated_statistics.e_reputation.reviews_analysis.positive_sentiments'),
                borderColor: malouChartColorGreen,
                backgroundColor: malouChartColorGreen,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: tagEvolutionData[SemanticAnalysisSentiment.POSITIVE],
            },
        ];
    }

    private _computeSortedData(
        chartData: ReviewAnalysesChartDataByRestaurantId,
        currentTag: ReviewAnalysisChartDataTag,
        restaurants: Restaurant[],
        sortBy: ChartSortBy
    ): { evolutionData: TagEvolutionData; labels: string[] } {
        const data = restaurants
            .map((restaurant) => {
                const isPresent = chartData.has(restaurant.id);
                if (!isPresent) {
                    return;
                }
                const restaurantData = chartData.get(restaurant.id);
                if (!restaurantData) {
                    return;
                }

                return {
                    label: restaurant.internalName,
                    positive: restaurantData[currentTag].positive,
                    negative: restaurantData[currentTag].negative,
                    total: restaurantData[currentTag].total,
                };
            })
            .filter(isNotNil);
        switch (sortBy) {
            case ChartSortBy.ASC:
                const sortedDataAsc = data.sort((a, b) => a.total - b.total);
                return this._reduceSortedData(sortedDataAsc);
            case ChartSortBy.DESC:
                const sortedDataDesc = data.sort((a, b) => b.total - a.total);
                return this._reduceSortedData(sortedDataDesc);
            default:
            case ChartSortBy.ALPHABETICAL:
                const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));
                return this._reduceSortedData(sortedData);
        }
    }

    private _reduceSortedData(data: { label: string; positive: ChartDataElement; negative: ChartDataElement; total: number }[]): {
        evolutionData: TagEvolutionData;
        labels: string[];
    } {
        return data.reduce(
            (acc, { label, positive, negative }) => ({
                evolutionData: {
                    [SemanticAnalysisSentiment.POSITIVE]: [...acc.evolutionData[SemanticAnalysisSentiment.POSITIVE], positive],
                    [SemanticAnalysisSentiment.NEGATIVE]: [...acc.evolutionData[SemanticAnalysisSentiment.NEGATIVE], negative],
                },
                labels: [...acc.labels, label],
            }),
            { evolutionData: { [SemanticAnalysisSentiment.POSITIVE]: [], [SemanticAnalysisSentiment.NEGATIVE]: [] }, labels: [] }
        );
    }

    private _computeChartLabels(labels: string[]): string[] {
        return labels;
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    stacked: true,
                    ticks: {
                        callback: (tickValue: number, index: number, _ticks: Tick[]): string => {
                            const label = this.chartLabels()[index];
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    stacked: true,
                    ticks: {
                        callback: (tickValue: number | string, _index: number, _ticks: Tick[]) =>
                            this._shortNumberPipe.transform(tickValue as number),
                    },
                },
            },
        };
    }
}
