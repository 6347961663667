import { z } from 'zod';

import {
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    AiPostGenerationEmojiStatus,
    AiPostSettingsLength,
    AiPostSettingsTone,
    AiTextToOptimizeType,
    ApplicationLanguage,
    CustomerNaming,
    FrenchTutoiementVouvoiement,
    PostSource,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const generateReviewReplyBodyValidator = z.object({
    reviewId: objectIdValidator,
});
export type GenerateReviewReplyBodyDto = z.infer<typeof generateReviewReplyBodyValidator>;

export const generateSeoPostTextSettingsBodyValidator = z.object({
    postId: objectIdValidator,
    description: z.string(),
    lang: z.string(),
    restaurantId: objectIdValidator,
    shouldUseImageAnalysis: z.boolean(),
});
export type GenerateSeoPostTextSettingsBodyDto = z.infer<typeof generateSeoPostTextSettingsBodyValidator>;

export const generatePostTextSettingsPreviewBodyValidator = z.object({
    description: z.string(),
    lang: z.string(),
    restaurantId: objectIdValidator,
    postSource: z.nativeEnum(PostSource),
    postSettings: z.object({
        prompt: z.string().nullish(),
        denomination: z.nativeEnum(FrenchTutoiementVouvoiement),
        tone: z.array(z.nativeEnum(AiPostSettingsTone)),
        emoji: z.nativeEnum(AiPostGenerationEmojiStatus),
        length: z.nativeEnum(AiPostSettingsLength),
    }),
});
export type GeneratePostTextSettingsPreviewBodyDto = z.infer<typeof generatePostTextSettingsPreviewBodyValidator>;

export const generateSocialNetworkPostTextSettingsBodyValidator = z.object({
    postId: objectIdValidator,
    description: z.string(),
    restaurantId: objectIdValidator,
    lang: z.string(),
    shouldUseImageAnalysis: z.boolean(),
});
export type GenerateSocialNetworkPostTextSettingsBodyDto = z.infer<typeof generateSocialNetworkPostTextSettingsBodyValidator>;

export const choosePostHashtagsBodyValidator = z.object({
    postId: objectIdValidator,
    restaurantId: objectIdValidator,
    postText: z.string(),
    hashtags: z.array(z.string()),
});
export type ChoosePostHashtagsBodyDto = z.infer<typeof choosePostHashtagsBodyValidator>;

export const optimizeTextBodyValidator = z.object({
    relatedEntityId: objectIdValidator,
    textToOptimize: z.string(),
    restaurantId: objectIdValidator,
    textToOptimizeType: z.nativeEnum(AiTextToOptimizeType),
    lang: z.string(),
});
export type OptimizeTextBodyDto = z.infer<typeof optimizeTextBodyValidator>;

export const textTranslationBodyValidator = z.object({
    relatedEntityId: objectIdValidator,
    relatedEntityCollection: z.nativeEnum(AiInteractionRelatedEntityCollection),
    type: z.nativeEnum(AiInteractionType),
    text: z.string(),
    lang: z.string().nullish(),
    restaurantId: objectIdValidator,
});
export type TextTranslationBodyDto = z.infer<typeof textTranslationBodyValidator>;

// ------------------------------------------------------------------------------------------------

export const answerReviewPreviewBodyValidator = z.object({
    restaurantAiSettings: z.object({
        restaurantId: z.string(),
        restaurantName: z.string(),
        defaultLanguageResponse: z.nativeEnum(ApplicationLanguage).optional(),
        postSettings: z.object({
            social: z.object({
                denomination: z.nativeEnum(FrenchTutoiementVouvoiement),
                tone: z.array(z.nativeEnum(AiPostSettingsTone)),
                length: z.nativeEnum(AiPostSettingsLength),
                emoji: z.nativeEnum(AiPostGenerationEmojiStatus),
                prompt: z.string().nullish(),
            }),
            seo: z.object({
                denomination: z.nativeEnum(FrenchTutoiementVouvoiement),
                tone: z.array(z.nativeEnum(AiPostSettingsTone)),
                length: z.nativeEnum(AiPostSettingsLength),
                emoji: z.nativeEnum(AiPostGenerationEmojiStatus),
                prompt: z.string().nullish(),
            }),
        }),
        reviewSettings: z.object({
            replyTone: z.nativeEnum(FrenchTutoiementVouvoiement),
            customerNaming: z.nativeEnum(CustomerNaming),
            restaurantKeywordIds: z.array(z.string()),
            forbiddenWords: z.array(z.string()),
            catchphrase: z.string().nullish(),
            shouldTranslateCatchphrase: z.boolean(),
            signatures: z.array(z.string()).nullish(),
            shouldTranslateSignature: z.boolean(),
        }),
    }),
    text: z.string(),
    reviewerName: z.string(),
    lang: z.nativeEnum(ApplicationLanguage),
    sourceLanguage: z.nativeEnum(ApplicationLanguage),
    restaurantId: objectIdValidator,
});
export type AnswerReviewPreviewBodyDto = z.infer<typeof answerReviewPreviewBodyValidator>;
