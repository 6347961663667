import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy, isEqual } from 'lodash';
import { catchError, combineLatest, debounceTime, filter, interval, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { isNotNil, PostPublicationStatus } from '@malou-io/package-utils';

import { BindingIdKey } from ':core/constants';
import { PostsService } from ':core/services/posts.service';
import { resetPostsBindingId, setCurrentlyPublishingPosts } from ':modules/posts/posts.actions';
import { selectPostsBindingId, selectPostsBindingIdKey } from ':modules/posts/posts.selectors';
import { SynchronizationStatus } from ':modules/reviews/store/reviews.interface';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { KillSubscriptions } from ':shared/interfaces';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

import { AutoUnsubscribeOnDestroy } from '../../decorators/auto-unsubscribe-on-destroy.decorator';
import { PlatformComparisonWithStatus, Post } from '../../models';

enum StepStatus {
    PENDING,
    SUCCESS,
    ERROR,
}
export interface PostingStatus {
    postId: string;
    platformKey: string;
    step1: StepStatus;
    step1Error: string;
}

@Component({
    selector: 'app-post-publication-status-footer',
    templateUrl: './post-publication-status-footer.component.html',
    styleUrls: ['./post-publication-status-footer.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
        NgTemplateOutlet,
        NgClass,
        PlatformLogoComponent,
        HttpErrorPipe,
        IncludesPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class PostPublicationStatusFooterComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly SynchronizationStatus = SynchronizationStatus;
    readonly StepStatus = StepStatus;

    readonly postingStatus = signal<PostingStatus[]>([]);
    readonly displayedPostingStatus = computed<PostingStatus[]>(() => {
        const groupedPostingStatus = groupBy(this.postingStatus(), 'platformKey');
        return Object.keys(groupedPostingStatus).map((key) => ({
            platformKey: key,
            postId: groupedPostingStatus[key]?.[0]?.postId,
            step1: this._getStatusFromListOfPostingStatus(groupedPostingStatus[key]),
            step1Error: groupedPostingStatus[key]?.find((post) => post.step1 === StepStatus.ERROR)?.step1Error ?? '',
        }));
    });
    platformsWithStatus: PlatformComparisonWithStatus[];
    bindingId: string | null = null;

    constructor(
        private readonly _postsService: PostsService,
        private readonly _store: Store
    ) {}

    get status(): SynchronizationStatus {
        if (!this.bindingId) {
            return SynchronizationStatus.NOT_STARTED;
        }
        if (this.postingStatus().some((status) => status.step1 === StepStatus.PENDING)) {
            return SynchronizationStatus.LOADING;
        }
        if (this.postingStatus().some((status) => status.step1 === StepStatus.ERROR)) {
            return SynchronizationStatus.ERROR;
        }
        return SynchronizationStatus.LOADED;
    }

    ngOnInit(): void {
        // this subscription is always running , TODO: check why
        interval(2000)
            .pipe(
                switchMap(() =>
                    combineLatest([this._store.select(selectPostsBindingId), this._store.select(selectPostsBindingIdKey)]).pipe(
                        debounceTime(50)
                    )
                ),
                tap(([bindingId]) => {
                    this.bindingId = bindingId;
                }),
                filter(([bindingId]) => isNotNil(bindingId)),
                switchMap(([bindingId, bindingIdKey]: [string, BindingIdKey]) =>
                    // We dont want hashtags so the posts are created with the original text (and not the text minus hashtags)
                    // not very clean, need refacto
                    this._postsService.getPostsWithoutHashtagsByBindingId(bindingId, bindingIdKey).pipe(
                        map((res) => res.data),
                        catchError(() => of(null))
                    )
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (posts) => {
                    if (posts) {
                        this._store.dispatch({ type: setCurrentlyPublishingPosts.type, posts: Object.freeze(posts) });
                        const status = this._computeStatus(posts);
                        if (!isEqual(this.postingStatus, status)) {
                            this.postingStatus.set(status);
                        }
                        if (this._areAllPostsProcessed(this.postingStatus())) {
                            this._store.dispatch(resetPostsBindingId());
                        }
                    }
                },
            });
    }

    close(): void {
        document.getElementById('post-publication-status')?.classList.add('close-animation');
    }

    private _computeStatus(posts: Post[]): PostingStatus[] {
        const postingStatus: PostingStatus[] = [];
        for (const post of posts) {
            const keys = post.key ? [post.key] : post.keys;
            const statuses: PostingStatus[] = keys.map((key) => ({
                postId: post._id,
                platformKey: key,
                step1: this._getStepStatus(post.published),
                step1Error: post.errorData,
            }));
            postingStatus.push(...statuses);
        }
        return postingStatus;
    }

    private _getStepStatus(status: PostPublicationStatus): StepStatus {
        if (status === PostPublicationStatus.PUBLISHED) {
            return StepStatus.SUCCESS;
        }
        if (status === PostPublicationStatus.ERROR) {
            return StepStatus.ERROR;
        }
        return StepStatus.PENDING;
    }

    private _areAllPostsProcessed(allStatus: PostingStatus[]): boolean {
        for (const status of allStatus) {
            if (status.step1 === StepStatus.PENDING) {
                return false;
            }
        }
        return true;
    }

    private _getStatusFromListOfPostingStatus(statusList: PostingStatus[]): StepStatus {
        const isAllPublished = !!statusList?.every((post) => post.step1 === StepStatus.SUCCESS);
        const postInError = statusList?.find((post) => post.step1 === StepStatus.ERROR);
        if (isAllPublished) {
            return StepStatus.SUCCESS;
        } else if (!!postInError) {
            return StepStatus.ERROR;
        }
        return StepStatus.PENDING;
    }
}
