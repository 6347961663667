<div class="flex h-full flex-1 flex-col p-6.5 pb-0">
    <div class="malou-text-18--bold text-malou-color-text-1 md:hidden">
        {{ 'validation.modal.title_left' | translate }}
    </div>

    <div class="mt-5 flex items-start gap-x-3 md:mt-0 md:flex-row-reverse">
        <div class="grow md:hidden">
            <app-search
                [testId]="'choose-keywords-search-keyword-input'"
                [shouldBuildValueIfNone]="
                    canCreateNewKeyword() | applyPure: dataSource().data : selectedKeywordsDataSource().data : dataSource().filter
                "
                [debounceTime]="0"
                [placeholder]="'common.search' | translate"
                [errorMessage]="createKeywordErrorMessage()"
                (valueBuilt)="createNewKeyword($event)"
                (searchChange)="applyFilter($event)"></app-search>
        </div>
        <div class="md:grow">
            <app-select-language
                [defaultSelectedLangs]="selectedLangs()"
                [langs]="unselectedKeywordLangs()"
                [prefixText]="'keywords.validation.keywords_language' | translate"
                (selectLanguageChange)="applyLangFilter($event)"></app-select-language>
        </div>
    </div>
    <div class="ml-1 hidden w-full md:!block">
        <button class="malou-btn-flat flex items-center py-3" [disabled]="isSaving()" (click)="toggleShowMoreOptions()">
            <span>
                {{ 'common.more_filters' | translate }}
            </span>
            <span>
                <mat-icon
                    class="ml-2 !h-3.5 !w-3.5 transition-all"
                    color="primary"
                    [svgIcon]="SvgIcon.CHEVRON_DOWN"
                    [class.rotate-180]="showMoreOptions()"></mat-icon>
            </span>
        </button>

        <div class="hidden w-full" [ngClass]="{ '!block': showMoreOptions() }">
            <app-search
                [shouldBuildValueIfNone]="!dataSource().filteredData.length"
                [debounceTime]="0"
                [placeholder]="'common.search' | translate"
                (valueBuilt)="createNewKeyword($event)"
                (searchChange)="applyFilter($event)"></app-search>
        </div>
    </div>
    @if (!loading()) {
        <div class="mt-5 max-h-full grow overflow-y-auto pb-6.5">
            <mat-table class="malou-mat-table" matSort [dataSource]="dataSource()" #table="matTable">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <app-noop-mat-checkbox
                            color="primary"
                            [checked]="isAllSelected()"
                            [indeterminate]="!isAllSelected()"
                            [disabled]="isSaving()"
                            (click)="$event ? toggleAllFiltered() : null">
                        </app-noop-mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table; let i = index">
                        <app-noop-mat-checkbox
                            color="primary"
                            [checked]="selection().isSelected(row)"
                            [disabled]="isSaving()"
                            (click)="$event.stopPropagation(); toggleSelected(row); selectMultiple($event, i)">
                        </app-noop-mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="text">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xlarge" mat-sort-header>
                        {{ 'keywords.keywords' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xlarge">
                        <div class="malou-text-13--semibold truncate pr-2 text-malou-color-text-1">
                            {{ row.text }}
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="volume">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                        {{ 'keywords.validation.popularity' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xsmall">
                        <app-keywords-popularity
                            [popularity]="row | applySelfPure: 'getPopularity' : allKeywords()"></app-keywords-popularity>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="language">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall" mat-sort-header>
                        {{ 'keywords.validation.lang' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let row; table: table"
                        class="malou-mat-table-cell-xsmall"
                        matTooltip="{{ getPrettyLang | applyPure: row.language }}"
                        (click)="$event.stopPropagation()">
                        <div class="flex">
                            <img
                                class="h-4 w-4"
                                [lazyLoad]="row.language | flagPathResolver"
                                [alt]="getPrettyLang | applyPure: row.language" />
                            <ng-container [ngTemplateOutlet]="updateKeywordLangTemplate" [ngTemplateOutletContext]="{ keyword: row }">
                            </ng-container>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="move">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall">
                        <div
                            [matTooltip]="
                                !selectionCount()
                                    ? ('keywords.validation.select_to_add' | translate)
                                    : (maxSelectedKeywordsReached | applyPure: selectedKeywordsDataSource().data.length)
                                      ? ('keywords.validation.already_ten_keywords' | translate)
                                      : ''
                            "
                            #selectToAddButton>
                            <button
                                class="malou-btn-flat"
                                data-testid="choose-keywords-add-selected-keywords-btn"
                                [disabled]="
                                    !selectionCount() ||
                                    isSaving() ||
                                    (maxSelectedKeywordsReached | applyPure: selectedKeywordsDataSource().data.length)
                                "
                                (click)="chooseSelectedKeywords()">
                                {{
                                    selectionCount() === 0
                                        ? ('hashtags.validation.selection' | translate)
                                        : ('keywords.validation.add_selection' | translate: { selectedLength: selectionCount() })
                                }}
                            </button>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; table: table" class="malou-mat-table-cell-xsmall justify-center">
                        <div
                            [matTooltip]="
                                (maxSelectedKeywordsReached | applyPure: selectedKeywordsDataSource().data.length)
                                    ? ('keywords.validation.already_ten_keywords' | translate)
                                    : ''
                            ">
                            <button
                                class="malou-btn-flat"
                                [disabled]="
                                    (maxSelectedKeywordsReached | applyPure: selectedKeywordsDataSource().data.length) ||
                                    row.isLoadingVolume ||
                                    isSaving()
                                "
                                (click)="$event.stopPropagation(); chooseKeyword(row)">
                                {{ 'keywords.validation.add' | translate }}
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table; let i = index"
                    (click)="$event.stopPropagation(); selection().toggle(row); selectMultiple($event, i)"></mat-row>
            </mat-table>
        </div>
    } @else {
        <div class="mt-5 max-h-full w-full grow overflow-y-auto">
            <app-skeleton skeletonClass="h-[50px] w-full" [count]="7"></app-skeleton>
        </div>
    }
</div>

<ng-template let-keyword="keyword" #updateKeywordLangTemplate>
    <app-update-keyword-lang
        [keyword]="keyword"
        [disabled]="isKeywordLangUpdating()(keyword)"
        (updateKeywordLang)="updateKeywordLang($event)"></app-update-keyword-lang>
</ng-template>
