import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { groupBy, uniq } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PlatformKey } from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { ToastService } from ':core/services/toast.service';

@Injectable({
    providedIn: 'root',
})
export class PlatformCacheService {
    readonly cachedResults: Record<string, PlatformKey[]> = {};

    private readonly _platformsService = inject(PlatformsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    getPlatformKeysForRestaurants(restaurantIds: string[]): Observable<PlatformKey[]> {
        if (restaurantIds.length === 0) {
            return of([]);
        }

        const restaurantsWithoutCachedResults = restaurantIds.filter((restaurantId) => !this.cachedResults[restaurantId]);

        return this.getMissingPlatformKeysForRestaurants(restaurantsWithoutCachedResults).pipe(
            map((missingResults) => uniq([...missingResults, ...restaurantIds.flatMap((restaurantId) => this.cachedResults[restaurantId])]))
        );
    }

    getMissingPlatformKeysForRestaurants(restaurantIds: string[]): Observable<PlatformKey[]> {
        return this._platformsService
            .getPlatformsForMultipleRestaurants({
                restaurantIds,
            })
            .pipe(
                map((res) => {
                    const platformsByRestaurant = groupBy(res.data, 'restaurantId');
                    const dataToCache = Object.keys(platformsByRestaurant).map((restaurantId) => {
                        const restaurantPlatforms = platformsByRestaurant[restaurantId].map((platform) => platform.key);
                        this.cachedResults[restaurantId] = restaurantPlatforms;
                        return {
                            restaurantId,
                            platformKeys: restaurantPlatforms,
                        };
                    });

                    return uniq(dataToCache.flatMap((result) => result.platformKeys));
                }),
                catchError(() => {
                    this._toastService.openErrorToast(
                        this._translateService.instant('aggregated_statistics.filters.fetch_platforms_error')
                    );
                    return of([]);
                })
            );
    }
}
