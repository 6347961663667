import { FeedItemDto } from '@malou-io/package-dto';
import { PostPublicationStatus, PostType, RemoveMethodsFromClass } from '@malou-io/package-utils';

import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export type IFeedItem = RemoveMethodsFromClass<FeedItem> & { updatedAt: Date };

export class FeedItem {
    postId: string;
    thumbnail: SocialPostMedia | null;
    published: PostPublicationStatus;
    postType: PostType;
    plannedPublicationDate: Date | null;
    socialCreatedAt?: Date;
    updatedAt: Date;
    sortDate?: Date;

    constructor(data: IFeedItem) {
        this.postId = data.postId;
        this.thumbnail = data.thumbnail;
        this.published = data.published;
        this.postType = data.postType;
        this.plannedPublicationDate = data.plannedPublicationDate;
        this.socialCreatedAt = data.socialCreatedAt;
        this.updatedAt = data.updatedAt;
        this.sortDate = data.sortDate;
    }

    copyWith(data: Partial<IFeedItem>): FeedItem {
        return new FeedItem({ ...this, ...data });
    }

    getPostDate(): Date {
        return new Date(this.sortDate ?? this.socialCreatedAt ?? this.plannedPublicationDate ?? this.updatedAt);
    }

    getPostTypeIcon(): SvgIcon | undefined {
        switch (this.postType) {
            case PostType.VIDEO:
            case PostType.REEL:
                return SvgIcon.VIDEO;
            case PostType.CAROUSEL:
                return SvgIcon.IMAGES;
            default:
                return undefined;
        }
    }

    static fromDto(dto: FeedItemDto): FeedItem {
        return new FeedItem({
            postId: dto.postId,
            thumbnail: dto.thumbnail ? new SocialPostMedia(dto.thumbnail) : null,
            published: dto.published,
            postType: dto.postType,
            plannedPublicationDate: dto.plannedPublicationDate ? new Date(dto.plannedPublicationDate) : null,
            socialCreatedAt: dto.socialCreatedAt ? new Date(dto.socialCreatedAt) : undefined,
            updatedAt: new Date(dto.updatedAt),
            sortDate: dto.sortDate ? new Date(dto.sortDate) : undefined,
        });
    }

    static fromSocialPostItem(socialPost: SocialPostItem): FeedItem {
        return new FeedItem({
            postId: socialPost.id,
            thumbnail: socialPost.thumbnail,
            published: socialPost.published,
            postType: socialPost.postType,
            plannedPublicationDate: socialPost.plannedPublicationDate,
            socialCreatedAt: socialPost.socialCreatedAt,
            updatedAt: new Date(),
            sortDate: socialPost.sortDate,
        });
    }
}
