import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { PostPublicationStatus } from '@malou-io/package-utils';

import { PostDatePickerComponent } from ':modules/posts-v2/post-date-picker/post-date-picker.component';
import { SocialPostMediaItemComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-post-item/social-post-media-item/social-post-media-item.component';
import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

@Component({
    selector: 'app-social-post-item',
    templateUrl: './social-post-item.component.html',
    styleUrls: ['./social-post-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        SocialPostMediaItemComponent,
        PlatformLogoComponent,
        PostDatePickerComponent,
        ApplySelfPurePipe,
        EnumTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostItemComponent {
    readonly post = input.required<SocialPostItem>();
    readonly updatePost = output<{ postId: string; shouldOpenFeedbacks?: boolean }>();
    readonly deletePost = output<{ postId: string }>();

    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);

    readonly textAndHashtags = computed((): string => {
        const post = this.post();
        return `${post.text} ${post.getHashtagsText()}`;
    });

    readonly feedbackCountTag = computed((): string | undefined => {
        const feedbackCount = this.post().feedbackMessageCount;
        return feedbackCount ? this._pluralTranslatePipe.transform('social_post.feedback_count_tag', feedbackCount) : undefined;
    });

    readonly SvgIcon = SvgIcon;
    readonly PostPublicationStatus = PostPublicationStatus;

    onOpenSocialLink(): void {
        const link = this.post().socialLink;
        if (link) {
            window.open(link, '_blank');
        }
    }

    onUpdatePost(): void {
        this.updatePost.emit({ postId: this.post().id });
    }

    onDeletePost(): void {
        this.deletePost.emit({ postId: this.post().id });
    }

    onRefreshPost(): void {
        console.log('Refresh post is not implemented yet');
    }

    onFeedbackCountTagClick(): void {
        this.updatePost.emit({ postId: this.post().id, shouldOpenFeedbacks: true });
    }
}
