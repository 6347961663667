/* eslint-disable @typescript-eslint/member-ordering */
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, OnDestroy, output, Renderer2, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateService } from '@ngx-translate/core';

import { ClickOutsideDirective } from ':shared/directives/click-outside.directive';
import { KeepAbsoluteDivInWindowDirective } from ':shared/directives/keep-element-in-window.directive';
import { Color } from ':shared/enums/colors';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

@Component({
    selector: 'app-emoji-picker',
    templateUrl: './emoji-picker.component.html',
    styleUrls: ['./emoji-picker.component.scss'],
    standalone: true,
    imports: [NgClass, ClickOutsideDirective, PickerComponent, MatIconModule, KeepAbsoluteDivInWindowDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerComponent implements OnDestroy {
    private readonly _renderer2 = inject(Renderer2);
    private readonly _translateService = inject(TranslateService);

    /**
     * Button element that replace the icon button in this component.
     * Useful if you want to trigger the emoji picker from another button. (see create-calendar-event.component.ts)
     */
    readonly buttonElement = input<HTMLElement | undefined>();
    readonly toggleEmojiPicker = input<boolean>(false);
    readonly closeOnSelect = input<boolean>(false);
    readonly buttonColorClass = input<string>('malou-color-primary');
    readonly disabled = input<boolean>(false);
    readonly emitEmoji = output<EmojiEvent>();

    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_COLOR = Color.PRIMARY;

    readonly isEmojiPickerDisplayed = signal(false);
    readonly i18n = signal<string | null>(null);

    unlisten: () => void;

    constructor() {
        effect(
            () => {
                const value = this.toggleEmojiPicker();
                this.isEmojiPickerDisplayed.set(value);
            },
            { allowSignalWrites: true }
        );

        effect(() => {
            this.unlisten?.();
            const buttonElement = this.buttonElement();
            if (buttonElement) {
                this.unlisten = this._renderer2.listen(buttonElement, 'click', () => {
                    this.toggle();
                });
            }
        });

        this.i18n.set(this._translateService.instant('emoji'));
    }

    ngOnDestroy(): void {
        this.unlisten?.();
    }

    addEmoji(selectedEmoji: EmojiEvent): void {
        this.emitEmoji.emit(selectedEmoji);
        if (this.closeOnSelect()) {
            this.isEmojiPickerDisplayed.set(false);
        }
    }

    toggle(): void {
        if (!this.disabled()) {
            this.isEmojiPickerDisplayed.update((displayEmojiPicker) => !displayEmojiPicker);
        }
    }

    handleClickOutside(): void {
        this.isEmojiPickerDisplayed.set(false);
    }
}
