import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { first, isNumber, last } from 'lodash';

import { isNotNil } from '@malou-io/package-utils';

import { CommunityData } from ':modules/statistics/social-networks/community-v2/community.interface';
import { CurrentAndDiffInsights } from ':modules/statistics/social-networks/engagement/engagement.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { ChartDataArray } from ':shared/helpers';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-community-main-infos-v2',
    templateUrl: './community-main-infos.html',
    styleUrls: ['./community-main-infos.scss'],
    standalone: true,
    imports: [ShortNumberPipe, NumberEvolutionComponent, TranslateModule, NgTemplateOutlet],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityMainInfosV2Component {
    communityData = input.required<CommunityData>();

    readonly followersPerPlatform = computed(() => {
        if (!this._hasOnePlatform(this.communityData().instagramFollowers, this.communityData().facebookFollowers)) {
            const facebookLastFollowersCurrentPeriod = last(this.communityData().facebookFollowers.filter(isNotNil));
            const facebookFirstFollowersCurrentPeriod = first(this.communityData().facebookFollowers.filter(isNotNil));

            const instagramLastFollowersCurrentPeriod = last(this.communityData().instagramFollowers.filter(isNotNil));
            const instagramFirstFollowersCurrentPeriod = first(this.communityData().instagramFollowers.filter(isNotNil));

            const facebookLastFollowersPreviousPeriod = last(this.communityData().previousFacebookFollowers.filter(isNotNil));
            const facebookFirstFollowersPreviousPeriod = first(this.communityData().previousFacebookFollowers.filter(isNotNil));

            const instagramLastFollowersPreviousPeriod = last(this.communityData().previousInstagramFollowers.filter(isNotNil));
            const instagramFirstFollowersPreviousPeriod = first(this.communityData().previousInstagramFollowers.filter(isNotNil));

            return {
                current: {
                    instagram: {
                        lastFollowers: instagramLastFollowersCurrentPeriod ?? null,
                        firstFollowers: instagramFirstFollowersCurrentPeriod ?? null,
                    },
                    facebook: {
                        lastFollowers: facebookLastFollowersCurrentPeriod ?? null,
                        firstFollowers: facebookFirstFollowersCurrentPeriod ?? null,
                    },
                },
                previous: {
                    instagram: {
                        lastFollowers: instagramLastFollowersPreviousPeriod ?? null,
                        firstFollowers: instagramFirstFollowersPreviousPeriod ?? null,
                    },
                    facebook: {
                        lastFollowers: facebookLastFollowersPreviousPeriod ?? null,
                        firstFollowers: facebookFirstFollowersPreviousPeriod ?? null,
                    },
                },
            };
        }
        return null;
    });

    readonly totalFollowers: Signal<CurrentAndDiffInsights> = computed(() => {
        if (this._hasOnePlatform(this.communityData().instagramFollowers, this.communityData().facebookFollowers)) {
            const currentTotalFollowers = last(this.communityData().totalFollowers.filter(isNotNil));
            const previousTotalFollowers = first(this.communityData().totalFollowers.filter(isNotNil));
            return {
                current: currentTotalFollowers ?? null,
                diff:
                    isNumber(previousTotalFollowers) && isNumber(currentTotalFollowers)
                        ? currentTotalFollowers - previousTotalFollowers
                        : null,
            };
        }

        const followersPerPlatform = this.followersPerPlatform();
        if (!followersPerPlatform) {
            return {
                current: null,
                diff: null,
            };
        }

        const {
            current: {
                instagram: { firstFollowers: instagramFirstFollowersCurrentPeriod, lastFollowers: instagramLastFollowersCurrentPeriod },
                facebook: { firstFollowers: facebookFirstFollowersCurrentPeriod, lastFollowers: facebookLastFollowersCurrentPeriod },
            },
        } = followersPerPlatform;

        const currentLastTotalFollowers =
            isNumber(facebookLastFollowersCurrentPeriod) && isNumber(instagramLastFollowersCurrentPeriod)
                ? facebookLastFollowersCurrentPeriod + instagramLastFollowersCurrentPeriod
                : null;

        const currentFirstTotalFollowers =
            isNumber(facebookFirstFollowersCurrentPeriod) && isNumber(instagramFirstFollowersCurrentPeriod)
                ? facebookFirstFollowersCurrentPeriod + instagramFirstFollowersCurrentPeriod
                : null;

        const res = {
            current: currentLastTotalFollowers ?? null,
            diff:
                isNumber(currentLastTotalFollowers) && isNumber(currentFirstTotalFollowers)
                    ? currentLastTotalFollowers - currentFirstTotalFollowers
                    : null,
        };
        return res;
    });

    readonly totalNewFollowers: Signal<CurrentAndDiffInsights> = computed(() => {
        if (this._hasOnePlatform(this.communityData().instagramFollowers, this.communityData().facebookFollowers)) {
            const existingValuesTotalFollowers = this.communityData().totalFollowers.filter(isNotNil);
            const firstFollowersValue = first(existingValuesTotalFollowers);
            const lastFollowersValue = last(existingValuesTotalFollowers);
            const currentTotalNewFollowers =
                isNumber(lastFollowersValue) && isNumber(firstFollowersValue) ? lastFollowersValue - firstFollowersValue : null;

            const existingValuesPreviousTotalFollowers = this.communityData().previousTotalFollowers.filter(isNotNil);
            const previousFirstFollowersValue = first(existingValuesPreviousTotalFollowers);
            const previousLastFollowersValue = last(existingValuesPreviousTotalFollowers);
            const previousTotalNewFollowers =
                isNumber(previousLastFollowersValue) && isNumber(previousFirstFollowersValue)
                    ? previousLastFollowersValue - previousFirstFollowersValue
                    : null;
            return {
                current: currentTotalNewFollowers ?? null,
                diff:
                    isNumber(previousTotalNewFollowers) && isNumber(currentTotalNewFollowers)
                        ? currentTotalNewFollowers - previousTotalNewFollowers
                        : null,
            };
        }

        const followersPerPlatform = this.followersPerPlatform();
        if (!followersPerPlatform) {
            return {
                current: null,
                diff: null,
            };
        }

        const {
            current: {
                instagram: { firstFollowers: instagramFirstFollowersCurrentPeriod, lastFollowers: instagramLastFollowersCurrentPeriod },
                facebook: { firstFollowers: facebookFirstFollowersCurrentPeriod, lastFollowers: facebookLastFollowersCurrentPeriod },
            },
            previous: {
                instagram: { firstFollowers: instagramFirstFollowersPreviousPeriod, lastFollowers: instagramLastFollowersPreviousPeriod },
                facebook: { firstFollowers: facebookFirstFollowersPreviousPeriod, lastFollowers: facebookLastFollowersPreviousPeriod },
            },
        } = followersPerPlatform;

        const currentLastTotalFollowers =
            isNumber(facebookLastFollowersCurrentPeriod) && isNumber(instagramLastFollowersCurrentPeriod)
                ? facebookLastFollowersCurrentPeriod + instagramLastFollowersCurrentPeriod
                : null;

        const currentFirstTotalFollowers =
            isNumber(facebookFirstFollowersCurrentPeriod) && isNumber(instagramFirstFollowersCurrentPeriod)
                ? facebookFirstFollowersCurrentPeriod + instagramFirstFollowersCurrentPeriod
                : null;

        const currentPeriodFollowersEvolution =
            isNumber(currentLastTotalFollowers) && isNumber(currentFirstTotalFollowers)
                ? currentLastTotalFollowers - currentFirstTotalFollowers
                : null;

        const previousLastTotalFollowers =
            isNumber(facebookLastFollowersPreviousPeriod) && isNumber(instagramLastFollowersPreviousPeriod)
                ? facebookLastFollowersPreviousPeriod + instagramLastFollowersPreviousPeriod
                : null;

        const previousFirstTotalFollowers =
            isNumber(facebookFirstFollowersPreviousPeriod) && isNumber(instagramFirstFollowersPreviousPeriod)
                ? facebookFirstFollowersPreviousPeriod + instagramFirstFollowersPreviousPeriod
                : null;

        const previousPeriodFollowersEvolution =
            isNumber(previousLastTotalFollowers) && isNumber(previousFirstTotalFollowers)
                ? previousLastTotalFollowers - previousFirstTotalFollowers
                : null;

        if (!isNumber(previousPeriodFollowersEvolution)) {
            return {
                current: currentPeriodFollowersEvolution ?? null,
                diff: currentPeriodFollowersEvolution ?? null,
            };
        }
        if (!isNumber(currentPeriodFollowersEvolution)) {
            return {
                current: currentPeriodFollowersEvolution ?? null,
                diff: -previousPeriodFollowersEvolution,
            };
        }
        return {
            current: currentPeriodFollowersEvolution,
            diff: currentPeriodFollowersEvolution - previousPeriodFollowersEvolution,
        };
    });

    private _hasOnePlatform(instagramFollowers: ChartDataArray, facebookFollowers: ChartDataArray): boolean {
        return instagramFollowers.length === 0 || facebookFollowers.length === 0;
    }
}
