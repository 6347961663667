import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ListingDto } from '@malou-io/package-dto';
import {
    BusinessCategory,
    failedAccessStatus,
    InvalidPlatformReason,
    PlatformAccessStatus,
    PlatformAccessType,
    PlatformCategory,
    PlatformDefinition,
    PlatformDefinitions,
    PlatformGroup,
    PlatformKey,
    YextPublisherId,
    yextPublishers,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import * as permissionsActions from ':core/credentials/store/permissions.actions';
import { Permissions, PermissionsState } from ':core/credentials/store/permissions.interface';
import { selectPermissionsState } from ':core/credentials/store/permissions.reducer';
import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PlatformsService } from ':core/services/platforms.service';
import { PublishersService } from ':core/services/publishers.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { AutomaticallyConnectedPlatformsModalComponent } from ':modules/platforms/automatically-connected-platforms-modal/automatically-connected-platforms-modal.component';
import { PlatformsConnectionHelpModalComponent } from ':modules/platforms/platforms-connection-help-modal/platforms-connection-help-modal.component';
import {
    AccountManagedConnectionModalService,
    AuthorizedAccountManagedPlatformKeys,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-account-managed-modal/account-managed-connection-modal.service';
import { DeliverooConnectionModalService } from ':modules/platforms/platforms-connection-modals/platforms-connection-deliveroo-modal/deliveroo-connection-modal.service';
import { FacebookInstagramConnectionModalService } from ':modules/platforms/platforms-connection-modals/platforms-connection-fb-insta-modal/facebook-instagram-connection-modal.service';
import { GoogleConnectionModalService } from ':modules/platforms/platforms-connection-modals/platforms-connection-google-modal/google-connection-modal.service';
import { ConnectMapstrBasicService } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/connect-mapstr-basic.service';
import { MapstrConnectionModalService } from ':modules/platforms/platforms-connection-modals/platforms-connection-mapstr-modal/mapstr-connection-modal.service';
import {
    AuthorizedPasswordManagedPlatformKeys,
    PasswordManagedConnectionModalService,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-password-managed-modal/password-managed-connection-modal.service';
import {
    AuthorizedSimpleAccountManagedPlatformKeys,
    SimpleAccountManagedConnectionModalService,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-simple-account-managed-modal/simple-account-managed-connection-modal.service';
import { TikTokConnectionModalService } from ':modules/platforms/platforms-connection-modals/platforms-connection-tiktok-modal/tiktok-connection-modal.service';
import { ZenchefConnectionModalService } from ':modules/platforms/platforms-connection-modals/platforms-connection-zenchef-modal/zenchef-connection-modal.service';
import { PlatformsFiltersComponent, ReconnectFilterOption } from ':modules/platforms/platforms-filters/platforms-filters.component';
import { selectCurrentPlatform, selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import * as UserActions from ':modules/user/store/user.actions';
import * as userActions from ':modules/user/store/user.actions';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { YextPlatformLogoComponent } from ':shared/components/yext-platform-logo/yext-platform-logo.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { MappedPlatform, MappedPlatforms, Platform, PlatformState, Restaurant, SimpleMappedPlatform } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { SlicePipe } from ':shared/pipes/slice.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

type PlatformsPermissionsStatus = Partial<Record<PlatformKey, boolean>>;

export interface PublisherIdWithUrl {
    id: YextPublisherId;
    url?: string;
}

const differences = {
    EMPTY_PLATFORM: 'empty_platform',
    EMPTY_FIELD: 'empty_field',
    DIFFERENT: 'different',
    COHERENT: 'coherent',
};

const platformSortByGroup: Record<PlatformGroup, PlatformKey[]> = {
    [PlatformGroup.MUST_HAVE]: [PlatformKey.GMB, PlatformKey.TRIPADVISOR, PlatformKey.INSTAGRAM],
    [PlatformGroup.SOCIAL]: [PlatformKey.FACEBOOK, PlatformKey.MAPSTR, PlatformKey.TIKTOK],
    [PlatformGroup.SEO]: [PlatformKey.LAFOURCHETTE, PlatformKey.ABC, PlatformKey.FOURSQUARE, PlatformKey.YELP, PlatformKey.PAGESJAUNES],
    [PlatformGroup.E_REPUTATION]: [
        PlatformKey.ZENCHEF,
        PlatformKey.UBEREATS,
        PlatformKey.DELIVEROO,
        PlatformKey.OPENTABLE,
        PlatformKey.RESY,
    ],
    [PlatformGroup.PRIVATE]: [],
};

@Component({
    selector: 'app-platforms',
    templateUrl: './platforms.component.html',
    styleUrls: ['./platforms.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        MalouSpinnerComponent,
        PlatformsFiltersComponent,
        PlatformLogoComponent,
        YextPlatformLogoComponent,
        ApplyPurePipe,
        AsyncPipe,
        EnumTranslatePipe,
        IncludesPipe,
        SlicePipe,
        PluralTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;
    readonly PlatformState = PlatformState;
    readonly PlatformAccessStatus = PlatformAccessStatus;
    readonly PlatformGroup = PlatformGroup;
    readonly yextPublishers = yextPublishers;
    readonly isConnectionModalOpen = signal(false);

    readonly trackByKeyFn = TrackByFunctionFactory.get('key');
    readonly trackByIdFn = TrackByFunctionFactory.get('id');
    readonly MAX_DISPLAYED_AUTOMATICALLY_PLATFORMS = 15;

    readonly platformGroups = Object.keys(PlatformGroup);
    sortedPlatforms = Object.keys(PlatformGroup).sort((a, b) => this._sortPlatforms(a, b));
    platformsWithSocialLink: Platform[] = [];

    readonly restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    readonly searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    readonly searchText = toSignal(this.searchText$, { initialValue: '' });
    readonly reconnectFilter$: BehaviorSubject<ReconnectFilterOption> = new BehaviorSubject<ReconnectFilterOption>(
        ReconnectFilterOption.ALL
    );
    readonly reconnectFilter = toSignal(this.reconnectFilter$, { initialValue: ReconnectFilterOption.ALL });

    restaurant: Restaurant;
    isYextEnabled: boolean;

    mappedPlatformsByCategories$: Observable<MappedPlatforms<PlatformCategory> | null>;
    mappedPlatformsByGroups$: Observable<MappedPlatforms<PlatformGroup> | null>;
    mappedPlatforms$: Observable<MappedPlatforms<PlatformCategory | PlatformGroup> | null>;
    platformsCategoryDisplayName: Record<string, { name: string; countText: string }>;
    readonly connectedPublishers: WritableSignal<PublisherIdWithUrl[]> = signal([]);
    readonly filteredConnectedPublishers = computed(() => {
        if (this.reconnectFilter() !== ReconnectFilterOption.ALL) {
            return [];
        }
        if (!this.searchText()) {
            return this.connectedPublishers();
        }
        return this.connectedPublishers().filter((publisher) => publisher.id.toLowerCase().includes(this.searchText().toLowerCase()));
    });
    readonly platformsPermissions$: Observable<PermissionsState> = this._store.select(selectPermissionsState);
    private readonly _ACCESS_TYPES = Object.values(PlatformAccessType);

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _platformsService: PlatformsService,
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _spinnerService: SpinnerService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _toastService: ToastService,
        private readonly _experimentationService: ExperimentationService,
        private readonly _publishersService: PublishersService,
        private readonly _googleConnectionModalService: GoogleConnectionModalService,
        private readonly _facebookInstagramConnectionModalService: FacebookInstagramConnectionModalService,
        private readonly _tiktokConnectionModalService: TikTokConnectionModalService,
        private readonly _zenchefConnectionModalService: ZenchefConnectionModalService,
        private readonly _deliverooConnectionModalService: DeliverooConnectionModalService,
        private readonly _accountManagedConnectionModalService: AccountManagedConnectionModalService,
        private readonly _passwordManagedConnectionModalService: PasswordManagedConnectionModalService,
        private readonly _mapstrConnectionModalService: MapstrConnectionModalService,
        private readonly _connectMapstrBasicService: ConnectMapstrBasicService,
        private readonly _simpleAccountManagedConnectionModalService: SimpleAccountManagedConnectionModalService
    ) {}

    ngOnInit(): void {
        this._cleanLocalStorage();
        this._store.dispatch({ type: UserActions.loadUser.type });
        this.restaurant$.pipe(filter(Boolean)).subscribe((rest) => {
            this.restaurant = rest;
            this.isYextEnabled = this.restaurant.isYextActivated;
            const yextPublishersAlreadyManagedByMalou = PlatformDefinitions.getPlatformKeysWithYextPublisherIds();
            this.reconnectFilter$.next(ReconnectFilterOption.ALL);

            if (this.isYextEnabled) {
                this._publishersService
                    .getYextListingsForRestaurant$(this.restaurant._id)
                    .pipe(
                        catchError(async () => ({
                            data: [] as ListingDto[],
                        }))
                    )
                    .pipe(map((res) => res.data))
                    .subscribe({
                        next: (listings) => {
                            const availablePublishers = listings.filter(
                                (listing) => !yextPublishersAlreadyManagedByMalou.includes(listing.id as YextPublisherId)
                            );
                            const connectedPublishers = availablePublishers
                                .map((listing) => {
                                    const yextPublisherConfig = yextPublishers[listing.id as YextPublisherId];
                                    return {
                                        id: listing.id as YextPublisherId,
                                        url: listing.url,
                                        displayPriority: yextPublisherConfig.displayPriority,
                                    };
                                })
                                .sort((a, b) => {
                                    const aValue = a.displayPriority;
                                    const bValue = b.displayPriority;
                                    return bValue - aValue;
                                })
                                .map((listing) => ({
                                    id: listing.id as YextPublisherId,
                                    url: listing.url,
                                }));

                            this.connectedPublishers.set(connectedPublishers);
                        },
                        error: () => {
                            this.connectedPublishers.set([]);
                        },
                    });
            } else {
                this.connectedPublishers.set([]);
            }
        });

        this.mappedPlatformsByCategories$ = this._getMappedPlatformsByCategories$();
        this.mappedPlatformsByGroups$ = this._getMappedPlatformsByGroups$();
        this.mappedPlatforms$ = this._getMappedPlatforms$();

        this._route.queryParams.pipe(filter((params) => params.new_restaurant || params.open_platform || params.open_modal)).subscribe({
            next: (params) => {
                if (params.open_platform) {
                    const platformKey = params.open_platform;
                    const step = 1;
                    this.openConnectPlatform({
                        platformKey,
                        step,
                        selectedAuth: params.auth_id,
                    });
                }
                this._router.navigate(['.'], { relativeTo: this._route });
            },
            error: console.warn,
        });

        this._route.queryParams.subscribe((params) => {
            if (params['missingPlatformPermissions']) {
                this._router.navigate(['.'], { relativeTo: this._route });
                this.openConnectPlatform({ platformKey: params['missingPlatformPermissions'], step: 0 });
            }

            if (params['reconnectPlatform']) {
                this._router.navigate(['.'], { relativeTo: this._route });
                this.openConnectPlatform({ platformKey: params['reconnectPlatform'], step: 0 });
            }
        });
    }

    showDeletePlatformButton(platform: MappedPlatform<any>): boolean {
        if (platform.key === PlatformKey.MAPSTR) {
            return !!platform.platformData?.credentials?.[0];
        }
        return true;
    }

    getDeletePlatformTranslationKey(platform: Platform): string {
        if (platform.key === PlatformKey.MAPSTR) {
            return 'platforms.connection.delete_connection_mapstr';
        }
        return 'platforms.connection.delete_connection';
    }

    onDeletePlatform(platform: SimpleMappedPlatform): void {
        const modalTitleParam = this._getDeletePlatformModalTitleParam(platform);
        const modalMessageKey = this._getDeletePlatformModalMessageKey(platform);
        this._dialogService.open({
            variant: DialogVariant.ALERT,
            title: this._translate.instant('platforms.connection.warning_delete_platform_title', { platform: modalTitleParam }),
            message: this._translate.instant(modalMessageKey, { platform: platform.fullName }),
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this._translate.instant('common.delete'),
                action: () => {
                    this._deletePlatform(platform);
                },
            },
        });
    }

    openInformationModal(platformKey: PlatformKey, state: PlatformState): void {
        this._customDialogService
            .open(PlatformsConnectionHelpModalComponent, {
                maxHeight: '546px',
                height: 'unset',
                data: {
                    platformKey,
                    platformState: state,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (!result) {
                        return;
                    }
                    this.openConnectPlatform({ platformKey, step: 0 });
                },
            });
    }

    hasSocialLink = (platformKey: string): boolean => this.platformsWithSocialLink.some((p) => p.key === platformKey);

    openPlatformInNewPage(platformKey: string): void {
        const platform = this.platformsWithSocialLink.find((p) => p.key === platformKey);
        if (platform) {
            window.open(platform.socialLink, '_blank');
        }
    }

    openHelpModal(platformKey: PlatformKey, state?: PlatformState): void {
        this._customDialogService
            .open(PlatformsConnectionHelpModalComponent, {
                maxHeight: '546px',
                height: 'unset',
                data: {
                    platformKey,
                    platformState: state ?? PlatformState.EMPTY,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (!result) {
                        return;
                    }
                    this.openConnectPlatform({ platformKey, step: 0 });
                },
            });
    }

    getMissingPermissionsButtonText = (permissionsState: PermissionsState | null, platformKey: PlatformKey): string => {
        const platformPermissions: Permissions | undefined = (permissionsState?.data ?? [])?.find(
            (platformData) => platformData.key === platformKey
        );
        if (!platformPermissions || platformPermissions.isValid) {
            return '';
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.TRIPADVISOR_NOT_MANAGER) {
            return this._translate.instant('platforms.connection.bad_access_admin');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.TRIPADVISOR_UNCLAIMED_PAGE) {
            return this._translate.instant('platforms.connection.unclaimed_page');
        }
        return this._translate.instant('platforms.connection.missing_permissions');
    };
    getMissingPermissionsTooltip = (
        permissionsState: PermissionsState | null,
        platformKey: PlatformKey,
        platformFullName: string
    ): string => {
        const platformPermissions: Permissions | undefined = (permissionsState?.data ?? [])?.find(
            (platformData) => platformData.key === platformKey
        );
        if (!platformPermissions || platformPermissions.isValid) {
            return '';
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.IG_NOT_FOUND) {
            return this._translate.instant('platforms.connection.missing_permissions_ig_not_found');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.GMB_DISABLED) {
            return this._translate.instant('permissions.gmb_disabled');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.GMB_SUSPENDED) {
            return this._translate.instant('permissions.gmb_suspended');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.GMB_NOT_VERIFIED) {
            return this._translate.instant('permissions.gmb_not_verified');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.GMB_PENDING_VERIFICATION) {
            return this._translate.instant('permissions.gmb_pending_verification');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.TRIPADVISOR_NOT_MANAGER) {
            return this._translate.instant('permissions.tripadvisor_not_manager');
        }
        if (platformPermissions.miscellaneous === InvalidPlatformReason.TRIPADVISOR_UNCLAIMED_PAGE) {
            return this._translate.instant('permissions.tripadvisor_unclaimed_page');
        }
        return this._translate.instant('platforms.connection.missing_permissions_reconnect_platform', {
            platform: platformFullName,
            missingPermissions: platformPermissions.missing?.length ? `(${platformPermissions.missing.join(', ')})` : '',
        });
    };

    openAllAutomaticallyConnectedPlatformsModal(connectedPublishers: PublisherIdWithUrl[]): void {
        this._customDialogService.open(AutomaticallyConnectedPlatformsModalComponent, {
            panelClass: ['malou-dialog-panel'],
            width: '750px',
            data: {
                publishers: connectedPublishers,
            },
        });
    }

    openConnectPlatform(data: { platformKey: PlatformKey; step: number; selectedAuth?: string | null }): void {
        if (this.isConnectionModalOpen()) {
            return;
        }
        const afterClosedReloadFn = (): void => {
            this._restaurantsService.reloadSelectedRestaurant();
            this._store.dispatch({
                type: userActions.loadUserPlatformPermissions.type,
                key: data.platformKey,
            });
            this._store.dispatch({
                type: permissionsActions.loadPlatformPermissions.type,
                restaurantId: this.restaurant._id,
                platformKey: data.platformKey,
            });
        };

        const afterClosedBaseFn = (res: { hasDataChanged?: boolean } | undefined): void => {
            this.isConnectionModalOpen.set(false);
            if (res?.hasDataChanged) {
                afterClosedReloadFn();
            }
        };

        this.isConnectionModalOpen.set(true);
        const platformKey = data.platformKey;
        if (platformKey === PlatformKey.GMB) {
            this._googleConnectionModalService.open(data.selectedAuth ?? undefined).subscribe(afterClosedBaseFn);
            return;
        }
        if (platformKey === PlatformKey.FACEBOOK || platformKey === PlatformKey.INSTAGRAM) {
            this._facebookInstagramConnectionModalService.open(platformKey, data.selectedAuth ?? undefined).subscribe(afterClosedBaseFn);
            return;
        }
        if (platformKey === PlatformKey.ZENCHEF) {
            this._zenchefConnectionModalService.open().subscribe(afterClosedBaseFn);
            return;
        }
        if (platformKey === PlatformKey.DELIVEROO) {
            this._deliverooConnectionModalService.open().subscribe(afterClosedBaseFn);
            return;
        }
        if (platformKey === PlatformKey.TIKTOK) {
            this._tiktokConnectionModalService.open().subscribe(afterClosedBaseFn);
            return;
        }
        if (this._isAccountManagedPlatform(platformKey)) {
            this._accountManagedConnectionModalService.open(platformKey).subscribe((res) => {
                afterClosedBaseFn(res);
                if (res?.openHelpModal) {
                    this.openHelpModal(platformKey);
                }
            });
            return;
        }
        if (this._isPasswordManagedPlatform(platformKey)) {
            this._passwordManagedConnectionModalService.open(platformKey).subscribe((res) => {
                afterClosedBaseFn(res);
                if (res?.openHelpModal) {
                    this.openHelpModal(platformKey);
                }
            });
            return;
        }
        if (platformKey === PlatformKey.MAPSTR) {
            this._mapstrConnectionModalService.open().subscribe(afterClosedBaseFn);
            return;
        }
        if (this._isSimpleAccountManagedPlatform(platformKey)) {
            this._simpleAccountManagedConnectionModalService.open(platformKey).subscribe(afterClosedBaseFn);
            return;
        }
        this.isConnectionModalOpen.set(false);
    }

    onSearchChange(search: string): void {
        this.searchText$.next(search);
    }

    onReconnectFilterChange(reconnectFilterOption: ReconnectFilterOption): void {
        this.reconnectFilter$.next(reconnectFilterOption);
    }

    /**
     * Used to log and hotfix a potential bug where the RESTAURANT_CREATION_FORM_IN_PROGRESS key is in the localstorage
     * but the key is supposed to be deleted earlier
     *
     * @cyril @baptiste
     */
    private _cleanLocalStorage(): void {
        if (LocalStorage.getItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS)) {
            console.warn('RESTAURANT_CREATION_FORM_IN_PROGRESS key is present in local storage');
        }
        LocalStorage.removeItem(LocalStorageKey.RESTAURANT_CREATION_FORM_IN_PROGRESS);
    }

    private _getRestaurantAccess(restaurant: Restaurant): Record<string, string> {
        const temp = {};
        restaurant?.access?.filter(Boolean)?.forEach((access) => {
            if (access.active) {
                temp[access.platformKey] = access.accessType;
            }
        });
        return temp;
    }

    private _getPlatformState(platformKey: string, platformStatus: string, access: Record<string, string>): PlatformState | undefined {
        if (platformKey === PlatformKey.MAPSTR) {
            return platformStatus === differences.EMPTY_PLATFORM ? PlatformState.EMPTY : PlatformState.CONNECTED;
        }
        if (platformStatus === 'empty_platform') {
            return PlatformState.EMPTY;
        } else if (platformStatus === 'empty_platform') {
            return PlatformState.WAITING;
        } else if (platformStatus !== 'empty_platform' && !(this._ACCESS_TYPES as string[]).includes(access[platformKey])) {
            return PlatformState.FOUND;
        } else if (platformStatus !== 'empty_platform' && (this._ACCESS_TYPES as string[]).includes(access[platformKey])) {
            return PlatformState.CONNECTED;
        }
        return undefined;
    }

    private _getPlatformAccessStatus(
        platformKey: PlatformKey,
        restaurant: Restaurant,
        platformsPermissionsStatus: PlatformsPermissionsStatus
    ): PlatformAccessStatus | undefined {
        if (platformKey === PlatformKey.MAPSTR) {
            return PlatformAccessStatus.VERIFIED;
        }
        const platformAccesses = restaurant.access.filter(
            (pl) => pl.platformKey === platformKey && pl.accessType !== PlatformAccessType.AUTO
        );

        const platformAccess = platformAccesses.find((pl) => pl.active);

        if (PlatformDefinitions.getPlatformDefinition(platformKey)?.shouldFetchPermissions) {
            if (typeof platformsPermissionsStatus[platformKey] === 'undefined') {
                return undefined;
            }
            switch (platformKey) {
                case PlatformKey.TRIPADVISOR: // in case of tripadvisor, access can be under verification (permissions will be valid) so we check the access.status
                    return platformsPermissionsStatus[platformKey] === false
                        ? PlatformAccessStatus.MISSING_PERMISSIONS
                        : platformAccess?.status;
                default:
                    return platformsPermissionsStatus[platformKey]
                        ? PlatformAccessStatus.VERIFIED
                        : PlatformAccessStatus.MISSING_PERMISSIONS;
            }
        }
        if (platformAccesses.length && !platformAccesses.find((pl) => pl.active)) {
            return platformAccesses.pop()?.status;
        }
        return platformAccess?.status || PlatformAccessStatus.UNVERIFIED;
    }

    private _getMappedPlatformsByCategories$(): Observable<MappedPlatforms<PlatformCategory> | null> {
        return combineLatest([
            this.restaurant$,
            this._store.select(selectCurrentPlatforms),
            this._getSocialPlatformsPermissionsStatus$(),
            this.searchText$,
        ]).pipe(
            filter(([restaurant]) => !!restaurant),
            map(
                ([restaurant, platformsResult, socialPlatformsPermissions, searchText]: [
                    Restaurant,
                    Platform[],
                    PlatformsPermissionsStatus,
                    string,
                ]) => {
                    this.platformsWithSocialLink = platformsResult.filter((p) => !!p.socialLink);
                    const featureFlaggedPlatforms = PlatformDefinitions.getFeatureFlaggedPlatforms();
                    const featureFlaggedPlatformKeysToHide = featureFlaggedPlatforms
                        .filter((p) => !this._experimentationService.isFeatureEnabled(p.featureFlagKey!))
                        .map((p) => p.key);
                    const nonPrivatePlatforms = PlatformDefinitions.getNonPrivatePlatforms().filter(
                        (p) => !featureFlaggedPlatformKeysToHide.includes(p.key)
                    );

                    const platformsKeysForRestaurant = restaurant.isBrandBusiness()
                        ? nonPrivatePlatforms.filter((p) => PlatformDefinitions.getSocialPlatformKeys().includes(p.key))
                        : nonPrivatePlatforms;
                    const filteredPlatforms = platformsKeysForRestaurant.filter((platform) =>
                        platform.fullName.toLowerCase().includes(searchText.toLowerCase())
                    );
                    this._spinnerService.hide();
                    return this._buildMappedPlatformsByCategories(
                        filteredPlatforms,
                        platformsResult,
                        socialPlatformsPermissions,
                        restaurant
                    );
                }
            ),
            catchError((error) => {
                console.error('Unknown error: ', error);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._translate.instant('platforms.connection.unknown_error'));
                return [];
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _getMappedPlatforms$(): Observable<MappedPlatforms<PlatformCategory | PlatformGroup> | null> {
        return combineLatest([this.restaurant$, this.mappedPlatformsByCategories$, this.mappedPlatformsByGroups$]).pipe(
            map(([restaurant, platformCategories, platformGroups]) => {
                if (!restaurant) {
                    return null;
                }
                if (restaurant.isBrandBusiness()) {
                    return platformCategories;
                }
                return platformGroups;
            }),
            tap(
                (mappedPlatform: MappedPlatforms<PlatformCategory> | MappedPlatforms<PlatformGroup>) =>
                    (this.sortedPlatforms = Object.keys(mappedPlatform).sort((a, b) => this._sortPlatforms(a, b)))
            )
        );
    }

    private _getMappedPlatformsByGroups$(): Observable<MappedPlatforms<PlatformGroup> | null> {
        return combineLatest([
            this.restaurant$,
            this._store.select(selectCurrentPlatforms),
            this._getSocialPlatformsPermissionsStatus$(),
            this.searchText$,
            this.reconnectFilter$,
        ]).pipe(
            filter(([restaurant]) => !!restaurant),
            map(
                ([restaurant, platformsResult, socialPlatformsPermissions, searchText, reconnectFilterOption]: [
                    Restaurant,
                    Platform[],
                    PlatformsPermissionsStatus,
                    string,
                    ReconnectFilterOption,
                ]) => {
                    this.platformsWithSocialLink = platformsResult.filter((p) => !!p.socialLink);
                    const featureFlaggedPlatforms = PlatformDefinitions.getFeatureFlaggedPlatforms();
                    const featureFlaggedPlatformKeysToHide = featureFlaggedPlatforms
                        .filter((p) => !this._experimentationService.isFeatureEnabled(p.featureFlagKey!))
                        .map((p) => p.key);
                    const nonPrivatePlatforms = PlatformDefinitions.getNonPrivatePlatforms().filter(
                        (p) => !featureFlaggedPlatformKeysToHide.includes(p.key)
                    );
                    const platformsKeysForRestaurant = restaurant.isBrandBusiness()
                        ? nonPrivatePlatforms.filter((p) => PlatformDefinitions.getSocialPlatformKeys().includes(p.key))
                        : nonPrivatePlatforms;
                    const filteredPlatforms = platformsKeysForRestaurant.filter((platform) =>
                        platform.fullName.toLowerCase().includes(searchText.toLowerCase())
                    );
                    this._spinnerService.hide();
                    return this._buildMappedPlatformsByGroups(
                        filteredPlatforms,
                        platformsResult,
                        socialPlatformsPermissions,
                        restaurant,
                        reconnectFilterOption
                    );
                }
            ),
            catchError((error) => {
                console.error('Unknown error: ', error);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._translate.instant('platforms.connection.unknown_error'));
                return [];
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }

    private _buildMappedPlatformsByCategories = (
        platformsList: PlatformDefinition[],
        restaurantPlatforms: Platform[],
        socialPlatformsPermissions: PlatformsPermissionsStatus,
        restaurant: Restaurant
    ): MappedPlatforms<PlatformCategory> | null => {
        const hiddenPlatforms = [PlatformKey.PRIVATE];
        if (!restaurant || !this._isReferenceComplete(restaurant)) {
            this._dialogService.open({
                variant: DialogVariant.ERROR,
                title: this._translate.instant('platforms.connection.error'),
                message: this._translate.instant('platforms.connection.reference_platform_incomplete'),
                primaryButton: {
                    label: this._translate.instant('common.delete'),
                    action: () => {
                        this._router.navigate(['../../../list'], { relativeTo: this._route.parent });
                    },
                },
            });
            return null;
        }
        const mappedPlatformsList = platformsList
            .filter((platform) => !hiddenPlatforms.includes(platform.key))
            .map((p) => {
                const restaurantPlatform = restaurantPlatforms.find((rp) => rp.key === p.key); // can be null
                const restaurantAccess = this._getRestaurantAccess(restaurant);
                const platformStatus = restaurantPlatform ? 'no_status' : differences.EMPTY_PLATFORM;
                return {
                    key: p.key,
                    fullName: p.fullName,
                    platformData: restaurantPlatform,
                    accessStatus: this._getPlatformAccessStatus(p.key, restaurant, socialPlatformsPermissions),
                    state: this._getPlatformState(p.key, platformStatus, restaurantAccess),
                    category: p.category,
                    displayedCategoryName: p.category,
                };
            });

        const mappedPlatformsGroupedByCategory = groupBy(mappedPlatformsList, 'category');
        this.platformsCategoryDisplayName = this._getPlatformsCategoryDisplayName(mappedPlatformsGroupedByCategory);
        const sortedPlatforms = this._sortPlatformsByState(mappedPlatformsGroupedByCategory);
        return sortedPlatforms;
    };

    private _buildMappedPlatformsByGroups = (
        platformsList: PlatformDefinition[],
        restaurantPlatforms: Platform[],
        socialPlatformsPermissions: PlatformsPermissionsStatus,
        restaurant: Restaurant,
        reconnectFilterOption: ReconnectFilterOption
    ): MappedPlatforms<PlatformGroup> | null => {
        const hiddenPlatforms = [PlatformKey.PRIVATE];
        if (!restaurant || !this._isReferenceComplete(restaurant)) {
            this._dialogService.open({
                variant: DialogVariant.ERROR,
                title: this._translate.instant('platforms.connection.error'),
                message: this._translate.instant('platforms.connection.reference_platform_incomplete'),
                primaryButton: {
                    label: this._translate.instant('common.delete'),
                    action: () => {
                        this._router.navigate(['../../../list'], { relativeTo: this._route.parent });
                    },
                },
            });
            return null;
        }
        const mappedPlatformsList = platformsList
            .filter((platform) => !hiddenPlatforms.includes(platform.key))
            .map((p) => {
                const restaurantPlatform = restaurantPlatforms.find((rp) => rp.key === p.key); // can be null
                const restaurantAccess = this._getRestaurantAccess(restaurant);
                const platformStatus = restaurantPlatform ? 'no_status' : differences.EMPTY_PLATFORM;
                return {
                    key: p.key,
                    fullName: p.fullName,
                    platformData: restaurantPlatform,
                    accessStatus: this._getPlatformAccessStatus(p.key, restaurant, socialPlatformsPermissions),
                    state: this._getPlatformState(p.key, platformStatus, restaurantAccess),
                    category: p.category,
                    displayedCategoryName: p.category,
                    group: p.group,
                };
            })
            .filter((platform) => this._filterByReconnectOption(platform, reconnectFilterOption));

        const mappedPlatformsGroupedByGroup = groupBy(mappedPlatformsList, 'group');
        const sortedPlatforms = this._sortPlatformsByStaticOrder(mappedPlatformsGroupedByGroup);
        return sortedPlatforms;
    };

    private _isReferenceComplete(referencePlatform: Restaurant): boolean {
        return referencePlatform?.type === BusinessCategory.LOCAL_BUSINESS
            ? !!(referencePlatform.name && referencePlatform.address?.formattedAddress)
            : !!referencePlatform?.name && !!referencePlatform?.socialId;
    }

    private _getSocialPlatformsPermissionsStatus$(): Observable<PlatformsPermissionsStatus> {
        return this._store.select(selectPermissionsState).pipe(
            map((platformsPermissions) => {
                const status = {
                    [PlatformKey.MAPSTR]: true,
                };
                platformsPermissions.data.forEach((p) => (status[p.key] = p.isValid));
                return status;
            })
        );
    }

    private _deletePlatform(simpleMappedPlatform: SimpleMappedPlatform): void {
        this._spinnerService.show();
        let deleteResponse$: Observable<any>;
        if (simpleMappedPlatform.key === PlatformKey.MAPSTR) {
            deleteResponse$ = this._store.select(selectCurrentPlatform({ platformKey: PlatformKey.MAPSTR })).pipe(
                take(1),
                // For mapstr a deconnection means a switch to the basic connexion (vs the premium)
                switchMap((platform) => this._connectMapstrBasicService.execute(platform))
            );
        } else {
            deleteResponse$ = this.restaurant$.pipe(
                filter(Boolean),
                take(1),
                switchMap((restaurant) => this._platformsService.deleteByRestaurantId(restaurant._id, simpleMappedPlatform.key))
            );
        }
        deleteResponse$.subscribe({
            next: () => {
                this._spinnerService.hide();
                switch (simpleMappedPlatform.key) {
                    case PlatformKey.FACEBOOK:
                        this._store.dispatch({ type: permissionsActions.removeFacebookPermissions.type });
                        break;
                    case PlatformKey.INSTAGRAM:
                        this._store.dispatch({ type: permissionsActions.removeInstagramPermissions.type });
                        break;
                    case PlatformKey.GMB:
                        this._store.dispatch({
                            type: userActions.removeSinglePlatformPermissions.type,
                            restaurantId: this.restaurant._id,
                            key: simpleMappedPlatform.key,
                        });
                        break;
                    default:
                        break;
                }
                this._restaurantsService.reloadSelectedRestaurant();
                this._spinnerService.hide();
            },
            error: (err) => {
                this._spinnerService.hide();
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(this._translate.instant('platforms.connection.unknown_error'));
            },
        });
    }

    private _getDeletePlatformModalTitleParam(platform: SimpleMappedPlatform): string {
        if (platform.key === PlatformKey.MAPSTR) {
            return platform.fullName + ' Premium';
        }
        return platform.fullName;
    }

    private _getDeletePlatformModalMessageKey(platform: SimpleMappedPlatform): string {
        if (platform.key === PlatformKey.MAPSTR) {
            return 'platforms.connection.warning_delete_platform_html_mapstr';
        }
        return 'platforms.connection.warning_delete_platform_html';
    }

    private _isAccountManagedPlatform(platformKey: PlatformKey): platformKey is AuthorizedAccountManagedPlatformKeys {
        return [PlatformKey.TRIPADVISOR, PlatformKey.FOURSQUARE, PlatformKey.YELP, PlatformKey.RESY].includes(platformKey);
    }

    private _isSimpleAccountManagedPlatform(platformKey: PlatformKey): platformKey is AuthorizedSimpleAccountManagedPlatformKeys {
        return [PlatformKey.OPENTABLE, PlatformKey.UBEREATS].includes(platformKey);
    }

    private _isPasswordManagedPlatform(platformKey: PlatformKey): platformKey is AuthorizedPasswordManagedPlatformKeys {
        return [PlatformKey.PAGESJAUNES, PlatformKey.LAFOURCHETTE].includes(platformKey);
    }

    private _getPlatformsCategoryDisplayName<T extends PlatformCategory | PlatformGroup>(
        mappedPlatforms: MappedPlatforms<T>
    ): {
        [key: string]: { name: string; countText: string };
    } {
        const platformsDisplayName = {};
        Object.keys(mappedPlatforms).forEach((key) => {
            const platforms = mappedPlatforms[key];
            const connectedPlatformsLength = platforms.filter(
                (p) => p.accessStatus === PlatformAccessStatus.VERIFIED && p.state === PlatformState.CONNECTED
            ).length;
            platformsDisplayName[key] = {
                name: this._translate.instant(`platforms.connection.${key}`),
                countText: `(${connectedPlatformsLength}/${platforms.length})`,
            };
        });
        return platformsDisplayName;
    }

    private _sortPlatformsByState(mappedPlatformsGrouped: MappedPlatforms<PlatformCategory>): MappedPlatforms<PlatformCategory> {
        const sortedPlatforms = {};
        Object.keys(mappedPlatformsGrouped).forEach((key) => {
            const platforms = mappedPlatformsGrouped[key];
            sortedPlatforms[key] = platforms.sort((a, b) => {
                if (a.state === b.state) {
                    return 0;
                }
                return a.state === PlatformState.CONNECTED ? -1 : 1;
            });
        });
        return sortedPlatforms;
    }

    private _sortPlatformsByStaticOrder(mappedPlatformsGrouped: MappedPlatforms<PlatformGroup>): MappedPlatforms<PlatformGroup> {
        const sortedPlatforms = {};
        Object.keys(mappedPlatformsGrouped).forEach((key) => {
            const platformKeysSorted = platformSortByGroup[key];
            const platforms = mappedPlatformsGrouped[key];
            sortedPlatforms[key] = platforms.sort((a, b) => platformKeysSorted.indexOf(a.key) - platformKeysSorted.indexOf(b.key));
        });
        return sortedPlatforms;
    }

    private _sortPlatforms(a: string, b: string): number {
        const aIndex = this.platformGroups.indexOf(a);
        const bIndex = this.platformGroups.indexOf(b);
        return aIndex - bIndex;
    }

    private _filterByReconnectOption(platform: MappedPlatform<PlatformCategory>, reconnectFilterOption: ReconnectFilterOption): boolean {
        if (reconnectFilterOption === ReconnectFilterOption.ALL) {
            return true;
        }
        if (reconnectFilterOption === ReconnectFilterOption.FAILED) {
            return !!platform.accessStatus && failedAccessStatus.includes(platform.accessStatus);
        }
        return false;
    }
}
