import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map, Observable, startWith } from 'rxjs';

import { Role, RoutePath } from '@malou-io/package-utils';

import { AuthService } from ':core/auth/auth.service';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { ChatbotService } from ':core/services/chatbot.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { LOGOUT_ACTION } from ':core/storage/storage.metareducer';
import { CampaignsContext } from ':modules/campaigns/campaigns.context';
import { ClientsContext } from ':modules/clients/clients.context';
import { PlatformsContext } from ':modules/platforms/platforms.context';
import { PostsContext } from ':modules/posts/context/posts.context';
import { selectEstimatedReviewCount } from ':modules/reviews/store/reviews.selectors';
import { RolesManagerContext } from ':modules/roles/roles-manager.context';
import { ROUTER_LINK_ACTIVE_OPTIONS } from ':modules/sidenav-router/sidenav-content/sidenav-content-route-group/sidenav-content-route/sidenav-content-route.component';
import { selectMessageTemplatesCount } from ':modules/templates/message-templates/store/message-templates.selectors';
import { selectReviewTemplatesCount } from ':modules/templates/review-templates/store/review-templates.selectors';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { SidenavToggleButtonComponent } from ':shared/components/sidenav-toggle-button/sidenav-toggle-button.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { NfcSelectors } from '../shared-nfc/store/nfc.selectors';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        RouterLink,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        SidenavToggleButtonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        MatBadgeModule,
    ],
})
export class HeaderComponent {
    @Input() showProfile = true;
    @Input() showSidenavToggle = false;

    readonly SvgIcon = SvgIcon;
    readonly Role = Role;

    extensionJobs = 0;
    readonly shouldShowLogo = signal(
        this._router.isActive('/users', ROUTER_LINK_ACTIVE_OPTIONS) || this._router.isActive('/admin', ROUTER_LINK_ACTIVE_OPTIONS)
    );
    readonly userInfos$: Observable<User | null> = this._store.select(selectUserInfos);

    readonly reviewTemplateCount = toSignal(this._store.select(selectReviewTemplatesCount));
    readonly messageTemplateCount = toSignal(this._store.select(selectMessageTemplatesCount));
    readonly nfcCount = toSignal(this._store.select(NfcSelectors.selectNfcCount));
    readonly reviewCount = toSignal(this._store.select(selectEstimatedReviewCount), { initialValue: undefined });

    readonly DISPLAYED_ROUTE_TEXTS: Record<RoutePath, string> = {
        [RoutePath.DASHBOARD]: this._translateService.instant('sidenav_content.calendar'),
        [RoutePath.SEO]: this._translateService.instant('sidenav_content.local_seo'),
        [RoutePath.BOOSTERS]: this._translateService.instant('sidenav_content.boosters'),
        [RoutePath.E_REPUTATION]: this._translateService.instant('sidenav_content.e_reputation'),
        [RoutePath.SOCIAL_NETWORKS]: this._translateService.instant('sidenav_content.social_network'),
        [RoutePath.INTERACTIONS]: this._translateService.instant('sidenav_content.interactions'),
        [RoutePath.STATISTICS]: this._translateService.instant('sidenav_content.statistics'),
        [RoutePath.RESOURCES]: this._translateService.instant('sidenav_content.resources'),
        [RoutePath.SETTINGS]: this._translateService.instant('sidenav_content.settings'),
        [RoutePath.INFORMATIONS]: this._translateService.instant('sidenav_content.information'),
        [RoutePath.SOCIAL_POSTS]: this._translateService.instant('sidenav_content.posts'),
        [RoutePath.POSTS]: this._translateService.instant('sidenav_content.posts'),
        [RoutePath.WHEEL_OF_FORTUNE]: this._translateService.instant('sidenav_content.wheel_of_fortune'),
        [RoutePath.TOTEMS]: this._translateService.instant('sidenav_content.totems'),
        [RoutePath.REVIEWS]: this._translateService.instant('sidenav_content.reviews'),
        [RoutePath.CAMPAIGNS]: this._translateService.instant('sidenav_content.campaigns'),
        [RoutePath.STORIES]: this._translateService.instant('sidenav_content.stories'),
        [RoutePath.INSPIRATIONS]: this._translateService.instant('sidenav_content.inspirations'),
        [RoutePath.MESSAGES]: this._translateService.instant('sidenav_content.messages'),
        [RoutePath.COMMENTS]: this._translateService.instant('sidenav_content.comments'),
        [RoutePath.EARNINGS]: this._translateService.instant('sidenav_content.roi'),
        [RoutePath.SEO_STATS]: this._translateService.instant('sidenav_content.seo'),
        [RoutePath.E_REPUTATION_STATS]: this._translateService.instant('sidenav_content.e_reputation'),
        [RoutePath.SOCIAL_NETWORKS_STATS]: this._translateService.instant('sidenav_content.social_network'),
        [RoutePath.BOOSTERS_STATS]: this._translateService.instant('sidenav_content.boosters'),
        [RoutePath.GALLERY]: this._translateService.instant('sidenav_content.gallery'),
        [RoutePath.REVIEW_TEMPLATES]: this._translateService.instant('sidenav_content.review_templates'),
        [RoutePath.MESSAGE_TEMPLATES]: this._translateService.instant('sidenav_content.message_templates'),
        [RoutePath.CUSTOMERS]: this._translateService.instant('sidenav_content.clients'),
        [RoutePath.KEYWORDS]: this._translateService.instant('sidenav_content.keywords'),
        [RoutePath.PLATFORMS]: this._translateService.instant('sidenav_content.platforms'),
        [RoutePath.AUTOMATIONS]: this._translateService.instant('sidenav_content.automations.name'),
        [RoutePath.USERS]: this._translateService.instant('sidenav_content.users'),
        [RoutePath.AI]: this._translateService.instant('sidenav_content.ai.name'),
    };

    readonly routeDetails = computed<Partial<Record<RoutePath, number | string>>>(() => ({
        [RoutePath.CUSTOMERS]: this._clientsContext.clientsCount() || 0,
        [RoutePath.POSTS]: this._postsContext.postCount() || 0,
        [RoutePath.TOTEMS]: this.nfcCount() || 0,
        [RoutePath.REVIEWS]: this.reviewCount() || 0,
        [RoutePath.CAMPAIGNS]: this._campaignsContext.campaignsCount() || 0,
        [RoutePath.REVIEW_TEMPLATES]: this.reviewTemplateCount() || 0,
        [RoutePath.MESSAGE_TEMPLATES]: this.messageTemplateCount() || 0,
        [RoutePath.PLATFORMS]: this._platformsContext.formattedConnectedPlatformsOnTotalRatio(),
        [RoutePath.USERS]: this._rolesManagerContext.userCount() || 0,
    }));

    private readonly _subRoutes$ = this._router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this._router.url),
        map(() => this._activatedRoute),
        map((route) => {
            const paths: RoutePath[] = [];
            while (route.firstChild) {
                route = route.firstChild;
                const path = route?.snapshot?.data?.['path'];
                if (!path) {
                    continue;
                }
                const routePath = Object.values(RoutePath).includes(path) ? path : null;
                if (routePath && paths[paths.length - 1] !== routePath) {
                    paths.push(routePath);
                }
            }
            return paths;
        })
    );

    readonly subRoutes = toSignal(this._subRoutes$, { initialValue: [] });

    readonly displayedSubRoutes = computed<{ key: RoutePath; text: string; detail: string | number }[]>(() =>
        this.subRoutes().map((route: RoutePath) => ({
            key: route,
            text: this.DISPLAYED_ROUTE_TEXTS[route] || '',
            detail: this.routeDetails()[route] || '',
        }))
    );

    constructor(
        private readonly _auth: AuthService,
        private readonly _store: Store,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _translateService: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _clientsContext: ClientsContext,
        private readonly _postsContext: PostsContext,
        private readonly _campaignsContext: CampaignsContext,
        private readonly _rolesManagerContext: RolesManagerContext,
        private readonly _platformsContext: PlatformsContext,
        private readonly _chatbotService: ChatbotService,
        public readonly experimentationService: ExperimentationService,
        public readonly notificationCenterContext: NotificationCenterContext
    ) {}

    logout(): void {
        this._auth.logout$().subscribe(() => {
            this._restaurantsService.setSelectedRestaurant(null);
            LocalStorage.removeItem(LocalStorageKey.JWT_TOKEN);
            this._chatbotService.shutdownAndClearSession();
            window.sessionStorage.clear();
            this._store.dispatch({ type: LOGOUT_ACTION });
            void this._router.navigate(['auth/login']);
        });
    }

    navigateTo(url: string): void {
        this._router.navigate([url]);
    }
}
