import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';

import { LoadNfcsFromSheetResponseDto } from '@malou-io/package-dto';
import { MalouErrorCode } from '@malou-io/package-utils';

import { SpinnerService } from ':core/services/malou-spinner.service';
import { NfcService } from ':core/services/nfc.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { IFormGroup } from ':shared/interfaces/form-control-record.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

interface LoadNfcsFrom {
    sheetLink: string;
    sheetNumber: number | null;
}

@Component({
    selector: 'app-load-nfcs',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatTableModule,
        MatInputModule,
        TranslateModule,
        InputTextComponent,
        InputNumberComponent,
        TranslateModule,
        EnumTranslatePipe,
    ],
    templateUrl: './load-nfcs.component.html',
    styleUrl: './load-nfcs.component.scss',
})
export class LoadNfcsComponent {
    loadNfcsForm: IFormGroup<LoadNfcsFrom>;
    displayCloseModal = false;
    readonly SvgIcon = SvgIcon;
    readonly shouldDisplayFailedImports: WritableSignal<boolean> = signal(false);
    readonly failedImports: WritableSignal<LoadNfcsFromSheetResponseDto> = signal([]);
    readonly dataSource = computed(() => new MatTableDataSource(this.failedImports()));

    readonly DISPLAYED_COLUMNS = ['restaurantName', 'restaurantId', 'nfcId', 'reason'];

    constructor(
        public dialogRef: MatDialogRef<LoadNfcsComponent>,
        public screenSizeService: ScreenSizeService,
        private readonly _fb: FormBuilder,
        private readonly _nfcService: NfcService,
        private readonly _toastService: ToastService,
        private readonly _spinnerService: SpinnerService,
        private readonly _translateService: TranslateService
    ) {
        this.loadNfcsForm = this._initForm();
    }

    get sheetNumber(): AbstractControl | null {
        return this.loadNfcsForm.get('sheetNumber');
    }

    get sheetLink(): AbstractControl | null {
        return this.loadNfcsForm.get('sheetLink');
    }

    submit(): void {
        const { sheetLink, sheetNumber } = this.loadNfcsForm.value;
        if (!sheetLink || isNil(sheetNumber)) {
            this._toastService.openErrorToast('Invalid sheet link or sheet number');
            return;
        }
        const sheetId = this._extractSheetId(sheetLink);
        if (!sheetId) {
            this._toastService.openErrorToast('Invalid sheet link');
            return;
        }
        const realSheetNumber = sheetNumber - 1;
        this._spinnerService.show();
        this._nfcService.loadNfcsFromSheet({ sheetId, sheetNumber: realSheetNumber }).subscribe({
            next: (failedImports) => {
                this._spinnerService.hide();
                this._toastService.openSuccessToast('NFCs loaded successfully');
                if (failedImports.length) {
                    this.failedImports.set(failedImports);
                    this.shouldDisplayFailedImports.set(true);
                } else {
                    this.dialogRef.close(true);
                }
            },
            error: (err) => {
                this._spinnerService.hide();
                const message = this._getErrorMessage(err);
                this._toastService.openErrorToast(message);
            },
        });
    }

    close({ shouldCheckChangesBeforeClose }: { shouldCheckChangesBeforeClose: boolean } = { shouldCheckChangesBeforeClose: false }): void {
        if (shouldCheckChangesBeforeClose && this.loadNfcsForm.dirty) {
            this.displayCloseModal = true;
            return;
        }
        this.dialogRef.close();
    }

    onSheetNumberChange(sheetNumber: number | null): void {
        this.loadNfcsForm.patchValue({ sheetNumber });
    }

    private _initForm(): IFormGroup<LoadNfcsFrom> {
        // @ts-ignore
        return this._fb.group({
            sheetLink: ['', Validators.required],
            sheetNumber: [1, [Validators.required, Validators.min(1)]],
        });
    }

    private _extractSheetId(sheetLink: string): string | null {
        const regex = /\/d\/([^/]+)/;
        const match = sheetLink.match(regex);
        return match ? match[1] : null;
    }

    private _getErrorMessage(error: any): string {
        if (error.error?.malouErrorCode === MalouErrorCode.NOT_FOUND) {
            return this._translateService.instant('admin.nfcs.load_from_sheet_modal.restaurants_not_found');
        }
        return error.error?.message ?? JSON.stringify(error);
    }
}
