<div class="flex flex-col px-8">
    <div class="flex items-center gap-x-2">
        <div class="flex gap-x-1">
            <span class="malou-text-18--bold">{{ 'admin.organizations.organizations' | translate }}</span>
            <span class="malou-text-18--regular">({{ dataSource.filteredData.length }})</span>
        </div>
        <div class="grow">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onSearchChange($event)"></app-search>
        </div>
        <div>
            <button class="malou-btn-raised--primary !h-12 md:!hidden" mat-raised-button (click)="upsert(UpsertKind.INSERT)">
                {{ 'common.add' | translate }}
            </button>
        </div>
    </div>

    <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
</div>

<ng-template #tableTemplate>
    <mat-table
        class="malou-mat-table"
        matSort
        matSortDisableClear
        [trackBy]="trackByIdFn"
        [dataSource]="dataSource"
        [matSortActive]="defaultSort.active"
        [matSortDirection]="defaultSort.direction"
        #table="matTable">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.organizations.name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let organization; table: table">
                {{ organization.name }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.organizations.created_at' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let organization; table: table">
                {{ organization.createdAt | formatDate: 'shortDate' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="users">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.organizations.users' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let organization; table: table">
                <div class="flex flex-col">
                    @for (user of organization.users | slice: 0 : MAX_USERS_PER_COLUMN; track trackByIdFn($index, user); let last = $last) {
                        <div>
                            {{ user.email }}
                            @if (last && organization.users.length > MAX_USERS_PER_COLUMN) {
                                <span
                                    class="malou-text-13--semibold malou-color-text-primary"
                                    (click)="displayUsersListModal(organization.users)"
                                    >+{{ organization.users.length - MAX_USERS_PER_COLUMN }}</span
                                >
                            }
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="restaurants">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.organizations.businesses' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let organization; table: table">
                <div class="flex flex-row flex-wrap">
                    @for (
                        restaurant of organization.restaurants | slice: 0 : MAX_RESTAURANTS_PER_COLUMN;
                        track trackByIdFn($index, restaurant);
                        let last = $last
                    ) {
                        <div>
                            <a class="malou-color-primary malou-text-12--semibold" [routerLink]="'/restaurants/' + restaurant._id">
                                {{ restaurant.name }}
                            </a>
                            @if (!last) {
                                ,&nbsp;
                            }
                            @if (last && organization.restaurants.length > MAX_RESTAURANTS_PER_COLUMN) {
                                <span
                                    class="malou-text-13--semibold malou-color-text-primary"
                                    (click)="displayRestaurantsListModal(organization)"
                                    >+{{ organization.restaurants.length - MAX_RESTAURANTS_PER_COLUMN }}</span
                                >
                            }
                        </div>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-fit-content"></mat-header-cell>
            <mat-cell *matCellDef="let organization; table: table" class="malou-mat-table-cell-fit-content">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS" [matMenuTriggerFor]="actionsMenu"></mat-icon>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="upsert(UpsertKind.UPDATE, organization)">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
                    </button>
                    <button class="flex gap-x-3" mat-menu-item (click)="delete(organization._id)">
                        <mat-icon class="!h-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'common.delete' | translate }}</span>
                    </button>
                </mat-menu>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let r; columns: displayedColumns; table: table; let i = index"></mat-row>
    </mat-table>
    <app-paginator [shouldBeHidden]="dataSource.filteredData.length < 5" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"></app-paginator>
</ng-template>
