import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { BoostersStatisticsData } from ':modules/statistics/boosters/boosters.interface';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { NfcToCsvMapper } from ':shared/helpers/map-nfc-name';

import { AbstractCsvService, CsvAsStringArrays } from '../csv-service.abstract';

type Data = BoostersStatisticsData | undefined;

@Injectable({ providedIn: 'root' })
export class BoostersScanCountCsvInsightService extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _nfcToCsvMapper: NfcToCsvMapper
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return this._store.select(StatisticsSelectors.selectBoosterStatsData).pipe(take(1));
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Booster', 'Scan count'];
    }

    protected override _getCsvDataRows(data: Exclude<Data, undefined>): CsvAsStringArrays {
        const groupedData = data.nfcs.reduce((acc: string[][], nfc) => {
            const scanCount = data.scans.filter((scan) => nfc.id === scan.nfcId).length;
            const nfcName = this._nfcToCsvMapper.mapNfcName(nfc);
            acc.push([nfcName, scanCount.toString()]);
            return acc;
        }, []);
        return groupedData;
    }
}
