import { TranslateService } from '@ngx-translate/core';
import { isNil, min, round } from 'lodash';

import { RoiPerformanceScoreDto } from '@malou-io/package-dto';
import { PerformanceCriteria, PlatformKey, TimeInMilliseconds } from '@malou-io/package-utils';

export interface RoiPerformanceScoreRow {
    title: string;
    subtitle?: string;
    value?: number;
    goal?: number;
    unit: string;
    goalUnit: string;
    emoji: RoiPerformanceScoreMetric;
}

export enum RoiPerformanceScoreMetric {
    RECEIVED_REVIEWS_COUNT = 'received_reviews_count',
    ANSWERED_REVIEWS_COUNT = 'answered_reviews_count',
    GMB_RATING = 'gmb_rating',
    POSITIVE_REVIEWS_RATIO = 'positive_reviews_ratio',
    AVERAGE_ANSWER_TIME = 'average_answer_time',
    KEYWORD_SCORE = 'keyword_score',
    KEYWORDS_IN_TOP_TEN = 'keywords_in_top_ten',
    GMB_POST_COUNT = 'gmb_post_count',
    FACEBOOK_POST_COUNT = 'facebook_post_count',
    INSTAGRAM_POST_COUNT = 'instagram_post_count',
    SOCIAL_IMPRESSIONS = 'social_impressions',
}

export class RoiPerformanceScoreDetails {
    private readonly _SCORE_INCREASE_FOR_GOAL = 0.2;
    private readonly _PERCENTAGE_INCREASE_FOR_GOAL = 20;
    private readonly _COUNT_INCREASE_FOR_GOAL = 2;
    private readonly _MAX_PERCENTAGE_GOAL = 100;
    private readonly _DEFAULT_REVIEWS_GOAL = 20;

    performanceScore: number;
    performanceScoreEvolution: number;

    receivedReviewsCount: PerformanceCriteria;
    answeredReviewsRatio: PerformanceCriteria;
    gmbRating: PerformanceCriteria;
    positiveReviewsRatio: PerformanceCriteria;
    averageAnswerTime: PerformanceCriteria;
    keywordScore: PerformanceCriteria;
    keywordsInTopTen: PerformanceCriteria;
    gmbPostCount: PerformanceCriteria;
    instagramPostCount: PerformanceCriteria;
    facebookPostCount: PerformanceCriteria;
    socialImpressionsCount: PerformanceCriteria;

    connectedPlatforms: PlatformKey[];

    constructor(init: RoiPerformanceScoreDto, connectedPlatforms?: PlatformKey[]) {
        this.performanceScore = init.performanceScore;
        this.performanceScoreEvolution = init.performanceScorePreviousPeriod
            ? init.performanceScore - init.performanceScorePreviousPeriod
            : 0;

        this.receivedReviewsCount = init.receivedReviewsCount;
        this.answeredReviewsRatio = init.answeredReviewsRatio;
        this.gmbRating = init.gmbRating;
        this.positiveReviewsRatio = init.positiveReviewsRatio;
        this.averageAnswerTime = init.averageAnswerTime;
        this.keywordScore = init.keywordScore;
        this.keywordsInTopTen = init.keywordsInTopTen;
        this.gmbPostCount = init.gmbPostCount;
        this.instagramPostCount = init.instagramPostCount;
        this.facebookPostCount = init.facebookPostCount;
        this.socialImpressionsCount = init.socialImpressionsCount;

        this.connectedPlatforms = connectedPlatforms ?? [];
    }

    buildRoiPerformanceScoreDetails(translate: TranslateService): RoiPerformanceScoreRow[] {
        const rows: RoiPerformanceScoreRow[] = [];
        for (const metric of this._getAvailableMetrics()) {
            const title = this._getTitleForMetric(metric, translate);
            const subtitle = this._getSubtitleForMetric(metric, translate);
            const unit = this._getUnitForMetric(metric);
            const goalUnit = this._getGoalUnitForMetric(metric);
            const valueAndGoal = this._getValueAndGoalForMetric(metric);
            rows.push({
                title,
                subtitle,
                value: valueAndGoal?.value,
                goal: valueAndGoal?.goal,
                unit,
                goalUnit,
                emoji: metric,
            });
        }
        return rows;
    }

    private _getTitleForMetric(metric: RoiPerformanceScoreMetric, translate: TranslateService): string {
        switch (metric) {
            case RoiPerformanceScoreMetric.RECEIVED_REVIEWS_COUNT:
                return translate.instant('roi.performance_score.received_reviews');
            case RoiPerformanceScoreMetric.ANSWERED_REVIEWS_COUNT:
                return translate.instant('roi.performance_score.answered_reviews');
            case RoiPerformanceScoreMetric.GMB_RATING:
                return translate.instant('roi.performance_score.gmb_rating');
            case RoiPerformanceScoreMetric.POSITIVE_REVIEWS_RATIO:
                return translate.instant('roi.performance_score.positive_reviews');
            case RoiPerformanceScoreMetric.AVERAGE_ANSWER_TIME:
                return translate.instant('roi.performance_score.answer_time');
            case RoiPerformanceScoreMetric.KEYWORD_SCORE:
                return translate.instant('roi.performance_score.keyword_score');
            case RoiPerformanceScoreMetric.KEYWORDS_IN_TOP_TEN:
                return translate.instant('roi.performance_score.keywords_in_top_ten');
            case RoiPerformanceScoreMetric.GMB_POST_COUNT:
                return translate.instant('roi.performance_score.gmb_posts');
            case RoiPerformanceScoreMetric.FACEBOOK_POST_COUNT:
                return translate.instant('roi.performance_score.facebook_posts');
            case RoiPerformanceScoreMetric.INSTAGRAM_POST_COUNT:
                return translate.instant('roi.performance_score.instagram_posts');
            case RoiPerformanceScoreMetric.SOCIAL_IMPRESSIONS:
                return translate.instant('roi.performance_score.social_impressions');
            default:
                return '';
        }
    }

    private _getValueAndGoalForMetric(metric: RoiPerformanceScoreMetric): PerformanceCriteria | null {
        switch (metric) {
            case RoiPerformanceScoreMetric.RECEIVED_REVIEWS_COUNT:
                const receivedReviewsRoundedValues = this._roundGoal(this.receivedReviewsCount);
                return {
                    ...receivedReviewsRoundedValues,
                    goal: !this.receivedReviewsCount.goal ? this._DEFAULT_REVIEWS_GOAL : receivedReviewsRoundedValues.goal,
                };
            case RoiPerformanceScoreMetric.ANSWERED_REVIEWS_COUNT:
                const answeredReviewsRoundedValues = this._roundGoal(this.answeredReviewsRatio);
                return {
                    ...answeredReviewsRoundedValues,
                    goal: isNil(this.answeredReviewsRatio.value) ? this._MAX_PERCENTAGE_GOAL : answeredReviewsRoundedValues.goal,
                };
            case RoiPerformanceScoreMetric.GMB_RATING:
                return {
                    value: this.gmbRating.value,
                    goal: round(min([this.gmbRating.goal, this.gmbRating.value + this._SCORE_INCREASE_FOR_GOAL]) ?? this.gmbRating.goal, 2),
                };
            case RoiPerformanceScoreMetric.POSITIVE_REVIEWS_RATIO:
                return {
                    value: this.positiveReviewsRatio.value,
                    goal: isNil(this.positiveReviewsRatio.value)
                        ? this._MAX_PERCENTAGE_GOAL
                        : round(
                              min([this.positiveReviewsRatio.goal, this.positiveReviewsRatio.value + this._PERCENTAGE_INCREASE_FOR_GOAL]) ??
                                  this.positiveReviewsRatio.goal,
                              0
                          ),
                };
            case RoiPerformanceScoreMetric.AVERAGE_ANSWER_TIME:
                return {
                    value: round(this.averageAnswerTime.value / TimeInMilliseconds.HOUR, 0),
                    goal: round(this.averageAnswerTime.goal / TimeInMilliseconds.HOUR, 0),
                };
            case RoiPerformanceScoreMetric.KEYWORD_SCORE:
                return {
                    value: this.keywordScore.value,
                    goal: round(
                        min([this.keywordScore.goal, this.keywordScore.value + this._SCORE_INCREASE_FOR_GOAL]) ?? this.keywordScore.goal,
                        2
                    ),
                };
            case RoiPerformanceScoreMetric.KEYWORDS_IN_TOP_TEN:
                const value = round(this.keywordsInTopTen.value, 0);
                return {
                    value,
                    goal: min([this.keywordsInTopTen.goal, value + this._COUNT_INCREASE_FOR_GOAL]) ?? this.keywordsInTopTen.goal,
                };
            case RoiPerformanceScoreMetric.GMB_POST_COUNT:
                return this._roundGoal(this.gmbPostCount);
            case RoiPerformanceScoreMetric.FACEBOOK_POST_COUNT:
                return this._roundGoal(this.facebookPostCount);
            case RoiPerformanceScoreMetric.INSTAGRAM_POST_COUNT:
                return this._roundGoal(this.instagramPostCount);
            case RoiPerformanceScoreMetric.SOCIAL_IMPRESSIONS:
                return this._roundGoal(this.socialImpressionsCount);
            default:
                return null;
        }
    }

    private _getSubtitleForMetric(metric: RoiPerformanceScoreMetric, translate: TranslateService): string {
        if (metric === RoiPerformanceScoreMetric.POSITIVE_REVIEWS_RATIO) {
            return translate.instant('roi.performance_score.4_5_stars');
        } else if (metric === RoiPerformanceScoreMetric.AVERAGE_ANSWER_TIME) {
            return translate.instant('roi.performance_score.average');
        } else {
            return '';
        }
    }

    private _getUnitForMetric(metric: RoiPerformanceScoreMetric): string {
        switch (metric) {
            case RoiPerformanceScoreMetric.ANSWERED_REVIEWS_COUNT:
            case RoiPerformanceScoreMetric.POSITIVE_REVIEWS_RATIO:
                return '%';
            case RoiPerformanceScoreMetric.AVERAGE_ANSWER_TIME:
                return 'h';
            default:
                return '';
        }
    }

    private _getGoalUnitForMetric(metric: RoiPerformanceScoreMetric): string {
        return metric === RoiPerformanceScoreMetric.AVERAGE_ANSWER_TIME ? 'h max.' : this._getUnitForMetric(metric);
    }

    private _getAvailableMetrics(): RoiPerformanceScoreMetric[] {
        let roiPerformanceScoreMetrics: RoiPerformanceScoreMetric[] = [];
        if (this.connectedPlatforms.includes(PlatformKey.GMB)) {
            roiPerformanceScoreMetrics = roiPerformanceScoreMetrics.concat([
                RoiPerformanceScoreMetric.RECEIVED_REVIEWS_COUNT,
                RoiPerformanceScoreMetric.ANSWERED_REVIEWS_COUNT,
                RoiPerformanceScoreMetric.GMB_RATING,
                RoiPerformanceScoreMetric.POSITIVE_REVIEWS_RATIO,
                RoiPerformanceScoreMetric.AVERAGE_ANSWER_TIME,
                RoiPerformanceScoreMetric.KEYWORD_SCORE,
                RoiPerformanceScoreMetric.KEYWORDS_IN_TOP_TEN,
                RoiPerformanceScoreMetric.GMB_POST_COUNT,
            ]);
        }

        if (this.connectedPlatforms.includes(PlatformKey.FACEBOOK)) {
            roiPerformanceScoreMetrics.push(RoiPerformanceScoreMetric.FACEBOOK_POST_COUNT);
        }

        if (this.connectedPlatforms.includes(PlatformKey.INSTAGRAM)) {
            roiPerformanceScoreMetrics.push(RoiPerformanceScoreMetric.INSTAGRAM_POST_COUNT);
        }

        if (this.connectedPlatforms.includes(PlatformKey.FACEBOOK) || this.connectedPlatforms.includes(PlatformKey.INSTAGRAM)) {
            roiPerformanceScoreMetrics.push(RoiPerformanceScoreMetric.SOCIAL_IMPRESSIONS);
        }
        return roiPerformanceScoreMetrics;
    }

    private _roundGoal(performanceScoreMetric: PerformanceCriteria): PerformanceCriteria {
        return {
            ...performanceScoreMetric,
            goal: round(performanceScoreMetric.goal ?? 0, 0),
        };
    }
}
