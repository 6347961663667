import { AddressDto } from '@malou-io/package-dto';

import { Address } from ':shared/models/address';

type ChartReviewsStatsDto = Omit<ChartReviewsStats, 'restaurant'> & {
    restaurant: Omit<ChartReviewsStats['restaurant'], 'address'> & { address: AddressDto | null | undefined };
};

export class ChartReviewsStats {
    restaurant: {
        _id: string;
        name: string;
        address: Address | null;
        internalName?: string;
    };
    averageRating?: number;
    total?: number;
    reviews: {
        key: string;
        socialId: string;
        rating: number;
    }[];

    public constructor(init: ChartReviewsStatsDto) {
        Object.assign(this, init);
        this.restaurant.address = this.restaurant.address ? new Address(this.restaurant.address) : null;
        this.restaurant.internalName = init.restaurant.internalName ?? init.restaurant.name;
    }
}

export class ChartReviewsStatsByRestaurantId extends Map<string, ChartReviewsStats> {
    constructor(data: Map<string, ChartReviewsStats>) {
        super(data);
    }

    static fromDto(data: ChartReviewsStatsDto[]): ChartReviewsStatsByRestaurantId {
        const map = new Map<string, ChartReviewsStats>();
        data.forEach((item) => {
            map.set(item.restaurant._id, new ChartReviewsStats(item));
        });
        return new ChartReviewsStatsByRestaurantId(map);
    }

    merge(data: ChartReviewsStatsByRestaurantId): ChartReviewsStatsByRestaurantId {
        const restaurantIds = [...data.keys()];
        restaurantIds.forEach((restaurantId) => {
            const value = data.get(restaurantId);
            if (value) {
                this.set(restaurantId, value);
            }
        });
        return new ChartReviewsStatsByRestaurantId(this);
    }

    getPresentRestaurantIds(): string[] {
        return [...this.keys()];
    }

    getChartReviewStats(): ChartReviewsStats[] {
        return [...this.values()];
    }

    getReviewCount(): number {
        return this.getChartReviewStats().reduce((acc, item) => acc + (item.total ?? 0), 0);
    }

    deleteMany(restaurantIds: string[]): ChartReviewsStatsByRestaurantId {
        restaurantIds.forEach((restaurantId) => {
            this.delete(restaurantId);
        });
        return new ChartReviewsStatsByRestaurantId(this);
    }
}
