import { ChangeDetectionStrategy, Component, computed, inject, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PostDatePickerComponent } from ':modules/posts-v2/post-date-picker/post-date-picker.component';
import { PostDatePickerSize } from ':modules/posts-v2/post-date-picker/post-date-picker.interface';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { SubmitPublicationStatus } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { DropdownButtonComponent } from ':shared/components/dropdown-button/dropdown-button.component';
import { MenuButtonSize } from ':shared/components/menu-button-v2/menu-button-v2.interface';

@Component({
    selector: 'app-upsert-social-post-modal-footer',
    templateUrl: './upsert-social-post-modal-footer.component.html',
    styleUrls: ['./upsert-social-post-modal-footer.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatTooltipModule, TranslateModule, DropdownButtonComponent, PostDatePickerComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertSocialPostModalFooterComponent {
    readonly cancel = output<void>();
    readonly savePost = output<SubmitPublicationStatus>();

    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);
    private readonly _translateService = inject(TranslateService);

    readonly selectedDate = this._upsertSocialPostContext.upsertSocialPostState.post.plannedPublicationDate;

    readonly MenuButtonSize = MenuButtonSize;
    readonly PostDatePickerSize = PostDatePickerSize;

    readonly postErrors = this._upsertSocialPostContext.postErrors;
    readonly finalPostErrors = computed(() => (this.selectedOption() === SubmitPublicationStatus.DRAFT ? [] : this.postErrors()));
    readonly postErrorsMessage = computed(() => this.finalPostErrors().join('\n'));

    // TODO quid du cas où le post est déjà publié ? (ex: facebook)
    readonly DROPDOWN_OPTIONS: SubmitPublicationStatus[] = [
        SubmitPublicationStatus.NOW,
        SubmitPublicationStatus.SCHEDULE,
        SubmitPublicationStatus.DRAFT,
    ];
    readonly selectedOption = signal<SubmitPublicationStatus>(SubmitPublicationStatus.SCHEDULE);

    onCancel(): void {
        this.cancel.emit();
    }

    onDropdownButtonClick(submitPublicationStatus: SubmitPublicationStatus | null): void {
        if (submitPublicationStatus) {
            this.savePost.emit(submitPublicationStatus);
        }
    }

    onSelectedDateChange(date: Date | null): void {
        if (!date) {
            return;
        }
        this._upsertSocialPostContext.updatePlannedPublicationDate(date);
    }

    displayPublicationStatusOption = (status: SubmitPublicationStatus): string => {
        switch (status) {
            case SubmitPublicationStatus.NOW:
                return this._translateService.instant('social_posts.new_social_post.now');
            case SubmitPublicationStatus.SCHEDULE:
                return this._translateService.instant('social_posts.new_social_post.later');
            case SubmitPublicationStatus.DRAFT:
                return this._translateService.instant('social_posts.new_social_post.draft');
            default:
                return '';
        }
    };
}
