import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { SocialPostCaptionComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-caption/social-post-caption.component';
import { SocialPostCtaComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-cta/social-post-cta.component';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { SocialPostPlaceComponent } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-place/social-post-place.component';
import { UpsertSocialPostContext } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/contexts/upsert-social-post.context';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { FbLocation } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { SocialPostMediasComponent } from './social-post-medias/social-post-medias.component';

@Component({
    selector: 'app-social-post-content-form',
    templateUrl: './social-post-content-form.component.html',
    styleUrls: ['./social-post-content-form.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        SocialPostCaptionComponent,
        SocialPostCtaComponent,
        SocialPostPlaceComponent,
        InputTextComponent,
        SocialPostMediasComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostContentFormComponent {
    private readonly _upsertSocialPostContext = inject(UpsertSocialPostContext);

    readonly postTitle = this._upsertSocialPostContext.upsertSocialPostState.post.title;
    readonly postCaption = this._upsertSocialPostContext.upsertSocialPostState.post.text;
    readonly postLocation = this._upsertSocialPostContext.upsertSocialPostState.post.location;
    readonly attachments = this._upsertSocialPostContext.upsertSocialPostState.post.attachments;

    readonly isMapstrSelected = computed(() =>
        this._upsertSocialPostContext.upsertSocialPostState.post.platformKeys().includes(PlatformKey.MAPSTR)
    );
    readonly areFacebookOrInstagramSelected = computed(() =>
        this._upsertSocialPostContext.upsertSocialPostState.post
            .platformKeys()
            .some((platformKey) => [PlatformKey.FACEBOOK, PlatformKey.INSTAGRAM].includes(platformKey))
    );

    readonly facebookPlatformSocialId = computed((): string | null => {
        const connectedPlatforms = this._upsertSocialPostContext.upsertSocialPostState.connectedSocialPlatforms();
        const facebookPlatform = connectedPlatforms.find((platform) => platform.key === PlatformKey.FACEBOOK);
        return facebookPlatform?.socialId ?? null;
    });

    readonly isLoadingLocation = this._upsertSocialPostContext.upsertSocialPostState.isLoadingLocation;

    readonly SvgIcon = SvgIcon;

    onTitleChange(title: string): void {
        this._upsertSocialPostContext.updateTitle(title);
    }

    onCaptionChange(caption: string): void {
        this._upsertSocialPostContext.updateCaption(caption);
    }

    onLocationChange(location: FbLocation | null): void {
        this._upsertSocialPostContext.updateLocation(location);
    }

    onMediaChange(medias: EditionMedia[]): void {
        this._upsertSocialPostContext.updateMedias(medias);
    }
}
