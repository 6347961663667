<div class="expansion-header malou-expansion-panel w-full">
    <mat-accordion>
        <mat-expansion-panel disabled hideToggle [expanded]="isComparePeriodSelectorExpanded()">
            <mat-expansion-panel-header class="cursor-default !pl-4 !pr-0">
                <div class="flex w-full items-center justify-between">
                    <div class="malou-text-14--bold text-malou-color-text-1">
                        {{ 'date_filter.comparison_period.title' | translate }}
                    </div>
                    <div class="flex items-center">
                        <app-slide-toggle
                            [checked]="isComparePeriodSelectorExpanded()"
                            (click)="$event.stopPropagation()"
                            (onToggle)="toggleExpandComparePeriodSelector()">
                        </app-slide-toggle>
                    </div>
                </div>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <div class="flex flex-col gap-4 px-7 py-3">
                    @for (period of comparisonOptions(); track $index) {
                        <div class="flex flex-col" [class.cursor-not-allowed]="period.disabled" [class.opacity-50]="period.disabled">
                            <mat-checkbox
                                color="primary"
                                [checked]="selectedComparisonPeriod() === period.key"
                                [disabled]="period.disabled"
                                (click)="$event.stopPropagation()"
                                (change)="onComparePeriodChange(period.key)">
                                <span class="malou-text-14 text-malou-color-text-1">
                                    {{ 'date_filter.comparison_period.' + period.key | translate }}
                                </span>
                            </mat-checkbox>
                            <span class="malou-text-12 sm:malou-text-11 ml-5 italic text-malou-color-text-2">
                                @if (period.disabled) {
                                    {{ 'date_filter.comparison_period.unavailable' | translate }}
                                } @else {
                                    ({{ period.label }})
                                }
                            </span>
                        </div>
                    }
                </div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
