import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, model, output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MentionConfig } from 'angular-mentions';

import { FeedbackMessageType, FeedbackMessageVisibility, PostFeedbackMessage, PostFeedbacksParticipant } from '@malou-io/package-utils';

import {
    FAKE_ID,
    USER_TAGGED_TEMPLATE,
} from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/previews-feed-notes/components/notes/notes.interface';
import { User } from ':modules/user/user';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { formatDate } from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-feedback-message',
    templateUrl: './feedback-message.component.html',
    styleUrls: ['./feedback-message.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgOptimizedImage,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatIconModule,
        TranslateModule,
        TextAreaComponent,
        IllustrationPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackMessageComponent {
    readonly currentUser = input.required<User | null>();
    readonly message = input.required<PostFeedbackMessage>();
    readonly feedbacksAreClosed = input.required<boolean>();
    readonly mentionConfig = input.required<MentionConfig>();
    readonly editingMessageId = model.required<string | null>();

    readonly deleteMessage = output<PostFeedbackMessage>();
    readonly changeMessageVisibility = output<PostFeedbackMessage>();
    readonly updateMessage = output<{ message: PostFeedbackMessage; editingMessage: string }>();

    private readonly _fb = inject(FormBuilder);

    readonly isMessageFromCurrentUser = computed((): boolean => this.message()?.author?.id === this.currentUser()?._id);
    readonly authorFullName = computed(() => this.message().author.name + ' ' + this.message().author.lastname.toUpperCase());
    readonly messageDate = computed(() => formatDate(this.message().publishedAt ?? this.message().createdAt));
    readonly messageTextHtml = computed((): string => {
        const messageWords = this.message().text?.replace('\n', '<br/>').split(' ');
        const messageWordsHtml = messageWords?.map((word) =>
            word.match(new RegExp(USER_TAGGED_TEMPLATE)) ? `<strong>@${word.replace(USER_TAGGED_TEMPLATE, '')}</strong>` : word
        );
        return messageWordsHtml?.join(' ') ?? '';
    });
    readonly messageIsUpdated = computed(() => this.message().publishedAt?.getTime() !== this.message().lastUpdatedAt?.getTime());

    readonly FeedbackMessageType = FeedbackMessageType;
    readonly FeedbackMessageVisibility = FeedbackMessageVisibility;
    readonly Illustration = Illustration;
    readonly SvgIcon = SvgIcon;

    readonly messagesForm: FormGroup<{
        editingMessage: FormControl<string | null>;
    }> = this._fb.group({
        editingMessage: '',
    });

    private _taggedUsers: PostFeedbacksParticipant[] = [];

    get editingMessage(): string {
        return this.messagesForm.get('editingMessage')?.value ?? '';
    }

    startEditingMessage(): void {
        this._taggedUsers = [];
        this.messagesForm.patchValue({ editingMessage: this._formatHtmlTextToTextareaValue(this.message().text ?? '') });
        this.editingMessageId.set(this.message().id);
    }

    cancelEditingMessage(): void {
        this.editingMessageId.set(null);
        this.messagesForm.patchValue({ editingMessage: null });
    }

    onDeleteMessage(): void {
        this.deleteMessage.emit(this.message());
    }

    onChangeMessageVisibility(): void {
        this.changeMessageVisibility.emit(this.message());
    }

    onUpdateMessage(): void {
        this.updateMessage.emit({ message: this.message(), editingMessage: this.editingMessage });
        this.cancelEditingMessage();
    }

    private _formatHtmlTextToTextareaValue(text: string): string {
        return text
            ?.split(' ')
            ?.map((word) => {
                const cleanedName = word.replace(USER_TAGGED_TEMPLATE, '');
                const fakeUserWithName: PostFeedbacksParticipant = {
                    id: FAKE_ID,
                    name: cleanedName,
                    lastname: '',
                    email: '',
                };
                this._taggedUsers.push(fakeUserWithName);
                return word.match(new RegExp(USER_TAGGED_TEMPLATE)) ? `@${cleanedName}` : word;
            })
            ?.join(' ');
    }
}
