import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';

import {
    AiOptimizeTextDto,
    AiPostGenerationSettingsDto,
    AnswerReviewPreviewBodyDto,
    ChoosePostHashtagsBodyDto,
    ChoosePostHashtagsDto,
    CountAiInteractionsDto,
    GeneratePostTextSettingsPreviewBodyDto,
    GeneratePostTextSettingsPreviewResponseDto,
    OptimizeTextBodyDto,
    TextTranslationBodyDto,
} from '@malou-io/package-dto';
import { ApiResultV2 } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import * as JimoActions from ':modules/jimo/jimo.actions';

@Injectable({
    providedIn: 'root',
})
export class AiService {
    readonly API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/ai`;

    aiRemainingCredits$ = new BehaviorSubject<number>(0);

    constructor(
        private readonly _http: HttpClient,
        private readonly _store: Store
    ) {}

    answerReview(body: { reviewId: string; restaurantId: string }): Observable<ApiResultV2<string>> {
        return this._http.post<ApiResultV2<string>>(`${this.API_BASE_URL}/answer-review`, body);
    }

    answerReviewPreview(body: AnswerReviewPreviewBodyDto): Observable<ApiResultV2<string>> {
        return this._http.post<ApiResultV2<string>>(`${this.API_BASE_URL}/answer-review-preview`, body);
    }

    generateSeoPostUsingSettings(body: {
        postId: string;
        description: string;
        restaurantId: string;
        lang: string;
        shouldUseImageAnalysis: boolean;
    }): Observable<ApiResultV2<AiPostGenerationSettingsDto>> {
        return this._http.post<ApiResultV2<AiPostGenerationSettingsDto>>(`${this.API_BASE_URL}/generate-seo-post-text-settings`, body);
    }

    generatePostTextPreview(body: GeneratePostTextSettingsPreviewBodyDto): Promise<GeneratePostTextSettingsPreviewResponseDto> {
        return firstValueFrom(
            this._http
                .post<
                    ApiResultV2<GeneratePostTextSettingsPreviewResponseDto>
                >(`${this.API_BASE_URL}/generate-post-text-settings-preview`, body)
                .pipe(map((response) => response.data))
        );
    }

    generateSocialNetworkPostUsingSettings(body: {
        postId: string;
        description: string;
        restaurantId: string;
        lang: string;
        shouldUseImageAnalysis: boolean;
    }): Observable<ApiResultV2<AiPostGenerationSettingsDto>> {
        return this._http.post<ApiResultV2<AiPostGenerationSettingsDto>>(
            `${this.API_BASE_URL}/generate-social-network-post-text-settings`,
            body
        );
    }

    choosePostHashtags(body: ChoosePostHashtagsBodyDto): Observable<ApiResultV2<ChoosePostHashtagsDto>> {
        return this._http.post<ApiResultV2<ChoosePostHashtagsDto>>(`${this.API_BASE_URL}/choose-post-hashtags`, body);
    }

    translateText(body: TextTranslationBodyDto): Observable<ApiResultV2<string>> {
        return this._http.post<ApiResultV2<string>>(`${this.API_BASE_URL}/translate`, body);
    }

    optimizeText(body: OptimizeTextBodyDto): Observable<ApiResultV2<AiOptimizeTextDto>> {
        return this._http.post<ApiResultV2<AiOptimizeTextDto>>(`${this.API_BASE_URL}/optimize-text`, body);
    }

    setAiRemainingCredits(value: number): void {
        this.aiRemainingCredits$.next(value);
    }

    handleAiInteraction(creditCost = 1): void {
        this._decrementAiRemainingCredits(creditCost);
        this._store.dispatch({ type: JimoActions.incrementJimoUserAiInteractionsCount.type });
    }

    getUserAiInteractionCount(): Observable<ApiResultV2<CountAiInteractionsDto>> {
        return this._http.get<ApiResultV2<CountAiInteractionsDto>>(`${this.API_BASE_URL}/interactions/count`);
    }

    private _decrementAiRemainingCredits(decrement = 1): void {
        this.aiRemainingCredits$.next(Math.max(0, this.aiRemainingCredits$.value - decrement));
    }
}
