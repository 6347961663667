import { AsyncPipe, JsonPipe, LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    output,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Observable } from 'rxjs';

import {
    APP_DEFAULT_LANGUAGE,
    DEFAULT_LANG_UNKNOWN,
    getTimeDifferenceInHours,
    KeywordScoreTextType,
    MediaType,
    PlatformKey,
    PostedStatus,
} from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import { MediaPreviewModalComponent } from ':modules/gallery/modals/media-preview-modal/media-preview-modal.component';
import { CanBeEditedPipe } from ':modules/reviews/pipe/can-be-edited.pipe';
import { CanBeRepliedPipe } from ':modules/reviews/pipe/can-be-replied.pipe';
import { CanHaveMultipleRepliesPipe } from ':modules/reviews/pipe/can-have-multiple-replies.pipe';
import { DisplayMenuItemsPipe } from ':modules/reviews/pipe/display-menu-items.pipe';
import { GetEaterTotalOrdersPipe } from ':modules/reviews/pipe/get-eater-total-orders.pipe';
import { GetMenuItemReviewsPipe } from ':modules/reviews/pipe/get-menu-item-reviews.pipe';
import { GetNbDaysUntilCantBeAnsweredPipe } from ':modules/reviews/pipe/get-nb-days-until-cant-be-answered.pipe';
import { HasAttachmentsPipe } from ':modules/reviews/pipe/has-attachments.pipe';
import { HasReplyPipe } from ':modules/reviews/pipe/has-reply.pipe';
import { IsPrivatePipe } from ':modules/reviews/pipe/is-private.pipe';
import { ReviewAnswerTooltipPipe } from ':modules/reviews/pipe/review-answer-tooltip.pipe';
import { ReviewArchiveTooltipPipe } from ':modules/reviews/pipe/review-archive-tooltip.pipe';
import { ReviewDatePipe } from ':modules/reviews/pipe/review-date.pipe';
import { ReviewShortTextLengthPipe } from ':modules/reviews/pipe/review-short-text-length.pipe';
import { ReviewTextPipe } from ':modules/reviews/pipe/review-text.pipe';
import { ReviewerDisplayNamePipe } from ':modules/reviews/pipe/reviewer-display-name.pipe';
import { RestaurantHeaderForReviewPreviewComponent } from ':modules/reviews/review-preview/restaurant-header-for-review-preview/restaurant-header-for-review-preview.component';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { ReviewSemanticAnalysisComponent } from ':shared/components/review-preview/review-semantic-analysis/review-semantic-analysis.component';
import { ReviewTranslationsComponent } from ':shared/components/review-translations/review-translations.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { CheckPermissionsDirective } from ':shared/directives/permissions-check.directive';
import { ReviewAnalysisHighlighter } from ':shared/helpers/analysis-highlight';
import { Keyword, Media, Restaurant, Review, ReviewSocialAttachment, SegmentAnalyses } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

export type FoldType = 'text' | 'comment' | 'menuItems';

@Component({
    selector: 'app-review-preview',
    templateUrl: './review-preview.component.html',
    styleUrls: ['./review-preview.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        KeywordsScoreGaugeComponent,
        RestaurantHeaderForReviewPreviewComponent,
        ReviewSemanticAnalysisComponent,
        ReviewTranslationsComponent,
        StarGaugeComponent,
        CheckPermissionsDirective,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        AvatarPipe,
        CanBeEditedPipe,
        CanBeRepliedPipe,
        CanHaveMultipleRepliesPipe,
        DisplayMenuItemsPipe,
        GetEaterTotalOrdersPipe,
        GetMenuItemReviewsPipe,
        GetNbDaysUntilCantBeAnsweredPipe,
        HasAttachmentsPipe,
        HasReplyPipe,
        IllustrationPathResolverPipe,
        IsPrivatePipe,
        LowerCasePipe,
        PlatformLogoComponent,
        PluralTranslatePipe,
        ReviewerDisplayNamePipe,
        ReviewAnswerTooltipPipe,
        ReviewArchiveTooltipPipe,
        ReviewDatePipe,
        ReviewTextPipe,
        ReviewShortTextLengthPipe,
        ShortTextPipe,
        JsonPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewPreviewComponent {
    readonly keywordsScoreGauge = viewChild(KeywordsScoreGaugeComponent);

    readonly isSyncReviewsFinished = input<boolean>(true);
    readonly withReplyButton = input<boolean>(true);
    readonly withArchiveButton = input<boolean>(true);
    readonly withSeeMoreButton = input<boolean>(true);
    readonly isSelected = input<boolean>(false);
    readonly hasBorder = input<boolean>(false);
    readonly restaurant = input.required<Restaurant>();
    readonly isAggregatedView = input<boolean>(false);
    readonly restaurantKeywords$ = input.required<Observable<Keyword[]>>();
    readonly parentListIndex = input.required<number>();
    readonly review = input.required<Review | PrivateReview>();
    readonly restaurantAiSettings = input.required<RestaurantAiSettings | undefined>();

    readonly select = output<string>();
    readonly archived = output<number>();

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _dialog = inject(MatDialog);
    private readonly _store = inject(Store);
    private readonly _route = inject(ActivatedRoute);
    private readonly _router = inject(Router);
    private readonly _textHighlighter = inject(ReviewAnalysisHighlighter);
    private readonly _screenSizeService = inject(ScreenSizeService);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly isUbereatsPromotionOfferFeatureIsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-ubereats-promotion-offer')
    );

    readonly SvgIcon = SvgIcon;
    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly MAX_MEDIA_ATTACHMENTS_SHOWN = 2;
    readonly MAX_MENU_ITEM_SHOWN = 3;
    readonly isFold: WritableSignal<{ [Property in FoldType]: boolean }> = signal({
        text: true,
        comment: true,
        menuItems: true,
    });

    readonly restaurant$ = toObservable(this.restaurant);

    readonly commentText = computed(() => this.review().comments?.[0]?.text ?? null);
    readonly commentText$ = toObservable(this.commentText);

    readonly replyPostedStatus = computed(() => {
        const review = this.review();
        if (!review.isPrivate()) {
            return review.comments?.[0]?.posted;
        }
    });

    readonly gaugeTextType = computed(() =>
        this.review().rating <= 2 ? KeywordScoreTextType.LOW_RATE_REVIEW : KeywordScoreTextType.HIGH_RATE_REVIEW
    );
    readonly gaugeTextType$ = toObservable(this.gaugeTextType);

    readonly lang = computed(() => this.review().keywordsLang ?? this.review().lang ?? APP_DEFAULT_LANGUAGE);
    readonly lang$ = toObservable(this.lang);

    readonly reviewerName = computed(() => this.review().getDisplayName());
    readonly reviewerName$ = toObservable(this.reviewerName);

    readonly reviewUbereatsPromotionAmount = computed(() => {
        const hundredths = this.review().getUbereatsPromotionAmountInHundredths();
        return hundredths ? hundredths / 100 : undefined;
    });
    readonly reviewOrderCurrencyCode = computed(() => this.review().getOrderCurrencyCode());

    readonly shouldDisplaySemanticAnalysis = computed(() => this.review().hasText());

    readonly highlightedReviewTextHtml: WritableSignal<SafeHtml> = signal('');

    readonly temporaryHide: WritableSignal<boolean> = signal(false);

    readonly responseTime = computed(() =>
        Math.floor(getTimeDifferenceInHours(this.review().getFirstCommentDate() ?? new Date(), this.review().socialCreatedAt))
    );
    readonly responseTime$ = toObservable(this.responseTime);

    readonly PlatformKey = PlatformKey;
    readonly PostedStatus = PostedStatus;

    readonly showTranslation = signal(false);
    readonly shouldShowTranslationTemplate = computed(
        () =>
            this.review().lang !== DEFAULT_LANG_UNKNOWN &&
            this.review().lang !== this.currentLang &&
            this.review().hasText() &&
            !!this.review().lang
    );
    readonly showOriginalTextWarning = computed(() => this.showTranslation() && this.shouldShowTranslationTemplate());
    readonly currentLang = LocalStorage.getLang();

    readonly isReviewRevampFeatureEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-review-revamp'));
    readonly restaurantAiSettings$ = toObservable(this.restaurantAiSettings);

    constructor() {
        effect(
            () => {
                this.showTranslation.set(this.review().hasTranslations(this.currentLang));
            },
            { allowSignalWrites: true }
        );
    }

    toggleArchived(): void {
        this.archived.emit(this.parentListIndex());
    }

    onHoveredChip(segmentAnalyses: SegmentAnalyses): void {
        this.highlightedReviewTextHtml.set(this._textHighlighter.setReviewTextHtmlSegmentsHighlights(this.review(), segmentAnalyses));
    }

    highlightAllSegments(): void {
        // timeout prevent from ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => this.highlightedReviewTextHtml.set(this._textHighlighter.getHighlightedReviewTextHtml(this.review())));
    }

    openCarousel(attachments: ReviewSocialAttachment[], index: number): void {
        this._dialog
            .open(MediaPreviewModalComponent, {
                panelClass: 'malou-dialog-no-padding',
                data: {
                    mediaIndex: index,
                    medias: attachments.map(
                        (attachment) =>
                            new Media({
                                urls: attachment.urls,
                                type: ['photo', 'image'].includes(attachment.type) ? MediaType.PHOTO : MediaType.VIDEO,
                            })
                    ),
                    restaurant: this.restaurant(),
                    actions: [],
                },
            })
            .afterClosed()
            .subscribe();
    }

    toggleFold(foldKey: FoldType): void {
        this.isFold.update((currentIsFolder) => {
            currentIsFolder[foldKey] = !currentIsFolder[foldKey];
            return { ...currentIsFolder };
        });
    }

    openReplyModal(review: Review | PrivateReview): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { reviewId: review._id },
        });
    }

    onReviewClick(): void {
        this.select.emit(this.review()._id);
    }
}
