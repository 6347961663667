import { DateTime } from 'luxon';
import { z } from 'zod';

import { HashtagType, PlatformKey, PostPublicationStatus, PostType, SeoPostTopic } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const authorValidator = z.object({
    _id: objectIdValidator,
    name: z.string().optional(),
    picture: z.string().optional(),
});

export const hashtagValidator = z.object({
    id: objectIdValidator,
    text: z.string(),
    isMain: z.boolean().optional(),
    type: z.nativeEnum(HashtagType).optional(),
});

export const postHashtagsValidator = z.object({
    selected: z.array(hashtagValidator),
    suggested: z.array(hashtagValidator),
});

export const postValidator = z
    .object({
        platformId: objectIdValidator.optional(),
        keys: z.array(z.enum([PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK, PlatformKey.MAPSTR, PlatformKey.GMB])).optional(),
        key: z.nativeEnum(PlatformKey).optional(),
        language: z.string().optional(),
        text: z.string().optional(),
        published: z.nativeEnum(PostPublicationStatus).optional(),
        hashtags: postHashtagsValidator.optional(),
        plannedPublicationDate: z.string().datetime().optional(),
        createdAt: z.string().datetime().optional(),
        attachmentsName: z.string().nullable().optional(),
        attachments: z.array(objectIdValidator).optional(),
        thumbnail: objectIdValidator.optional(),
        thumbnailOffsetTimeInMs: z.number().optional(),
        postTopic: z.nativeEnum(SeoPostTopic).optional(),
        postType: z.nativeEnum(PostType).optional(),
        isReelDisplayedInFeed: z.boolean().optional(),
        hashtagsInFirstComment: z.boolean().optional(),
        duplicatedFromRestaurantId: objectIdValidator.optional(),
        restaurantId: objectIdValidator.optional(),
        author: authorValidator.optional(),
        location: z
            .object({
                id: z.string(),
                name: z.string(),
                link: z.string().optional(),
                location: z.object({
                    city: z.string(),
                    country: z.string(),
                    latitude: z.number(),
                    longitude: z.number(),
                    street: z.string().optional(),
                    zip: z.string().optional(),
                }),
            })
            .optional(),
        userTagsList: z
            .array(
                z.array(
                    z.object({
                        username: z.string(),
                        x: z.number().optional(),
                        y: z.number().optional(),
                    })
                )
            )
            .optional(),
        feedbackId: objectIdValidator.optional(),
        malouStoryId: z.string().uuid().optional(),
        title: z.string().optional(),
        isStory: z.boolean().optional(),
        event: z
            .object({
                title: z.string().optional(),
                startDate: z.string().datetime().optional(),
                endDate: z.string().datetime().optional(),
            })
            .optional(),
        offer: z
            .object({
                couponCode: z.string().optional(),
                onlineUrl: z.string().optional(),
                termsConditions: z.string().optional(),
            })
            .optional(),
        callToAction: z
            .object({
                actionType: z.string().nullish(),
                url: z.string().optional(),
            })
            .nullish(),
    })
    .transform((data) => ({
        ...data,
        plannedPublicationDate: data.plannedPublicationDate ? DateTime.fromISO(data.plannedPublicationDate).toJSDate() : null,
        createdAt: data.createdAt ? DateTime.fromISO(data.createdAt).toJSDate() : null,
        callToAction: data.callToAction
            ? {
                  actionType: data.callToAction?.actionType ?? null,
                  url: data.callToAction?.url ?? '',
              }
            : null,
        event: data.event
            ? {
                  title: data.event?.title ?? '',
                  startDate: data.event?.startDate
                      ? DateTime.fromISO(data.event?.startDate).toJSDate()
                      : DateTime.now().minus({ days: 1 }).toJSDate(),
                  endDate: data.event?.endDate
                      ? DateTime.fromISO(data.event?.endDate).toJSDate()
                      : DateTime.now().minus({ days: 0 }).toJSDate(),
              }
            : null,
        offer: data.offer
            ? {
                  couponCode: data.offer?.couponCode ?? '',
                  onlineUrl: data.offer?.onlineUrl ?? '',
                  termsConditions: data.offer?.termsConditions ?? '',
              }
            : null,
        location: data.location
            ? {
                  id: data.location?.id ?? '',
                  name: data.location?.name ?? '',
                  link: data.location?.link ?? '',
                  location: data.location?.location
                      ? {
                            city: data.location?.location?.city ?? '',
                            country: data.location?.location?.country ?? '',
                            latitude: data.location?.location?.latitude ?? 0,
                            longitude: data.location?.location?.longitude ?? 0,
                            street: data.location?.location?.street ?? '',
                            zip: data.location?.location?.zip ?? '',
                        }
                      : null,
              }
            : null,
        userTagsList: data.userTagsList
            ? data.userTagsList?.map((uts) =>
                  uts.map((ut) => ({
                      username: ut?.username ?? '',
                      x: ut?.x ?? 0,
                      y: ut?.y ?? 0,
                  }))
              )
            : null,
    }));
