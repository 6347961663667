@if (displayMode === NfcDisplayMode.BASIC) {
    @if (!isLoadingRestaurantHasTotems()) {
        @if (restaurantHasTotems()) {
            <div class="flex h-full flex-col px-8.5">
                <app-nfc-actions-header
                    [hasSelectedNfcs]="hasSelectedNfcs | applyPure: selection.selected.length"
                    [showActions]="false"
                    (searchChange)="onSearchChange($event)"
                    (delete)="deleteNfcs(selection.selected)"
                    (createNfc)="openUpsertNfcModal(undefined, $event)"
                    (loadNfcsFromSheet)="loadNfcsFromSheet()">
                </app-nfc-actions-header>
                <div class="min-h-0 grow">
                    @if (!isLoading()) {
                        <ng-container *ngTemplateOutlet="totalCount > 0 ? nfcTableTemplate : noNfcTemplate"></ng-container>
                    } @else {
                        <div class="flex h-full items-center justify-center">
                            <app-malou-spinner></app-malou-spinner>
                        </div>
                    }
                </div>
                <app-paginator
                    [shouldBeHidden]="totalCount < 5"
                    [pageSizeOptions]="PAGE_SIZE_OPTIONS"
                    [length]="totalCount"
                    [pageIndex]="((pagination$ | async)?.page ?? 2) - 1"
                    [pageSize]="(pagination$ | async)?.limit ?? PAGE_SIZE_OPTIONS[0]"
                    (pageEvent)="onPageEvent($event)">
                </app-paginator>
            </div>
        } @else {
            <div class="flex flex-col items-center justify-center" [ngClass]="{ 'mt-10': !isPhoneScreen(), 'h-full': isPhoneScreen() }">
                <img class="h-32" loading="lazy" [src]="'Cook' | illustrationPathResolver" />
                <p class="malou-text-14--bold mt-9 text-center">
                    {{ 'admin.nfcs.no_totem_title_basic' | translate }}
                </p>
                <p class="malou-text-10--regular my-4.5 text-center">
                    {{ 'admin.nfcs.no_totem_description_basic' | translate }}
                </p>
            </div>
        }
    } @else {
        <div class="flex h-full items-center justify-center">
            <app-malou-spinner></app-malou-spinner>
        </div>
    }
}

@if (displayMode === NfcDisplayMode.ADMIN) {
    <ng-container *ngTemplateOutlet="pageTemplate"></ng-container>
}

<ng-template #pageTemplate>
    <div class="flex h-full flex-col gap-y-1 px-8.5">
        @if (displayMode === NfcDisplayMode.ADMIN) {
            <div class="malou-card !mx-0 !mb-1 !mt-2 max-h-12 !w-full cursor-pointer !flex-row !items-center !gap-x-2 !p-3">
                <mat-checkbox
                    color="primary"
                    [checked]="isAllSelected | applyPure: selection.selected.length"
                    [indeterminate]="isAllSelectedIndeterminate | applyPure: selection.selected.length"
                    (change)="$event ? toggleAllRows() : null">
                </mat-checkbox>
                <span class="malou-text-12--bold"> {{ 'common.select_all' | translate }}</span>
            </div>
            <app-sort-by-filters
                [sortOptions]="SORT_OPTIONS"
                [sortBy]="sortBy()"
                [sortOrder]="sortOrder()"
                (changeSortBy)="onSortByChange($event)"
                (toggleSortOrder)="onSortOrderChange($event)">
            </app-sort-by-filters>
        }
        <app-nfc-actions-header
            [hasSelectedNfcs]="hasSelectedNfcs | applyPure: selection.selected.length"
            [showActions]="displayMode === NfcDisplayMode.ADMIN"
            (searchChange)="onSearchChange($event)"
            (delete)="deleteNfcs(selection.selected)"
            (createNfc)="openUpsertNfcModal(undefined, $event)"
            (loadNfcsFromSheet)="loadNfcsFromSheet()">
        </app-nfc-actions-header>

        @if (!isLoading()) {
            <ng-container *ngTemplateOutlet="totalCount > 0 ? nfcTableTemplate : noNfcTemplate"></ng-container>
        } @else {
            <div class="flex h-full items-center justify-center">
                <app-malou-spinner></app-malou-spinner>
            </div>
        }

        <app-paginator
            [shouldBeHidden]="totalCount < 5"
            [pageSizeOptions]="PAGE_SIZE_OPTIONS"
            [length]="totalCount"
            [pageIndex]="((pagination$ | async)?.page ?? 2) - 1"
            [pageSize]="(pagination$ | async)?.limit ?? PAGE_SIZE_OPTIONS[0]"
            (pageEvent)="onPageEvent($event)">
        </app-paginator>
    </div>
</ng-template>

<ng-template #nfcTableTemplate>
    @if (screenSizeService.isPhoneScreen) {
        <ng-container *ngTemplateOutlet="mobileNfcListTemplate"></ng-container>
    } @else {
        <ng-container *ngTemplateOutlet="desktopTableNfcTemplate"></ng-container>
    }
</ng-template>

<ng-template #mobileNfcListTemplate>
    <div class="my-5 flex h-fit flex-col gap-y-2">
        @for (nfc of dataSource.data; track nfc.id; let i = $index) {
            <div class="malou-card !m-0 !flex-none cursor-pointer gap-y-5 !p-5" [id]="nfc.id">
                <div class="flex justify-between">
                    <div class="flex gap-x-2">
                        <span class="malou-text-13--semibold">{{ 'admin.nfcs.type.title' | translate }}:</span>
                        <span class="malou-text-12--bold">
                            {{
                                (nfc | applySelfPure: 'isTotem')
                                    ? ('admin.nfcs.type.totem' | translate)
                                    : ('admin.nfcs.type.sticker' | translate)
                            }}</span
                        >
                    </div>
                    <div>
                        <div class="flex h-full w-full items-center justify-end" [matMenuTriggerFor]="actionsMenu">
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                        </div>
                        <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                            <button class="flex gap-x-3" mat-menu-item (click)="openUpsertNfcModal(nfc, nfc.isTotem())">
                                <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                                <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
                            </button>
                            @if (displayMode === NfcDisplayMode.ADMIN) {
                                <button class="flex gap-x-3" mat-menu-item (click)="deleteNfcs([nfc])">
                                    <mat-icon class="!h-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                    <span class="malou-text-14--regular">{{ 'common.delete' | translate }}</span>
                                </button>
                            }
                            @if (nfc | applySelfPure: 'isSticker') {
                                <button class="flex gap-x-3" mat-menu-item (click)="downloadSticker(nfc)">
                                    <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.STICKER"></mat-icon>
                                    <span class="malou-text-14--regular">{{ 'admin.nfcs.download_sticker' | translate }}</span>
                                </button>
                            }
                            @if (displayMode === NfcDisplayMode.BASIC) {
                                <button class="flex gap-x-3" mat-menu-item (click)="downloadQrCode(nfc)">
                                    <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
                                    <span class="malou-text-14--regular">{{ 'admin.nfcs.download_qr_code' | translate }}</span>
                                </button>
                            }
                        </mat-menu>
                        @if (displayedStickerId() === nfc.id) {
                            <div>
                                <div class="absolute right-0 top-0 z-[-1]">
                                    <!-- The component has to be displayed to be downloaded by the lib, so we display it in the back so the user dont see it with z-index -1 -->
                                    <app-sticker [sticker]="nfc"></app-sticker>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div class="flex gap-x-2 align-middle">
                    <mat-checkbox
                        color="primary"
                        [checked]="isSelected | applyPure: nfc : selection.selected.length"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(nfc) : null">
                    </mat-checkbox>
                    <span class="malou-text-12--bold"> {{ nfc.chipName || DEFAULT_VALUE_DISPLAY }}</span>
                </div>
                <div class="flex flex-col">
                    <span class="malou-text-12--bold">{{ nfc.restaurant?.name }}</span>
                    @if (shouldDisplayNfcAddress | applyPure: nfc) {
                        <span class="malou-text-11--regular">
                            {{ displayNfcAddress | applyPure: nfc }}
                        </span>
                    }
                </div>
                <div class="flex justify-between">
                    <div class="flex gap-x-2">
                        <span class="malou-text-13--semibold">{{ 'admin.nfcs.name' | translate }}</span>
                        <span class="malou-text-12--bold"> {{ nfc.chipName || DEFAULT_VALUE_DISPLAY }}</span>
                    </div>
                    <div class="flex gap-x-2 align-middle">
                        <span class="malou-text-13--semibold">{{ 'admin.nfcs.active' | translate }}</span>
                        <app-slide-toggle [checked]="nfc.active" (onToggle)="toggleActiveNfc(nfc, !$event)"> </app-slide-toggle>
                    </div>
                </div>

                <div class="flex justify-between">
                    <div class="flex gap-x-2">
                        @if (nfc.platformKey) {
                            @if (!(nfc | applySelfPure: 'isRedirectingToWheelOfFortune')) {
                                <app-platform-logo imgClasses="h-8 w-8" [logo]="nfc.platformKey"></app-platform-logo>
                                <div>{{ nfc.platformKey | enumTranslate: 'platform_key' }}</div>
                            } @else {
                                <app-platform-logo imgClasses="h-8 w-8" [logo]="WHEEL_OF_FORTUNE"></app-platform-logo>
                                <div>{{ WHEEL_OF_FORTUNE | enumTranslate: 'platform_key' }}</div>
                            }
                        }
                    </div>
                    <button class="flex w-fit gap-x-2" [matTooltip]="nfc | applySelfPure: 'getNfcUrl'" (click)="copyChipLink(nfc)">
                        <mat-icon class="!h-[22px] !w-[22px] !p-px" color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
                        <span class="malou-text-13--semibold malou-color-text-primary">{{ 'common.copy' | translate }}</span>
                    </button>
                </div>

                <div>
                    @if (!(nfc | applySelfPure: 'canBeRated')) {
                        {{ DEFAULT_VALUE_DISPLAY }}
                    }
                    @if (nfc | applySelfPure: 'canBeRated') {
                        <div class="flex gap-x-1">
                            @if (nfc.starsRedirected.length === NUMBER_OF_STARS_TO_SHOW_ALL) {
                                <app-star-with-text-chip [value]="'common.all_f' | translate" [widthMode]="'fit'"></app-star-with-text-chip>
                            }
                            @if (nfc.starsRedirected.length !== 5) {
                                @for (star of nfc.starsRedirected; track star) {
                                    <app-star-with-text-chip [value]="star"></app-star-with-text-chip>
                                }
                            }
                        </div>
                    }
                </div>
                <div class="flex gap-x-2">
                    <span class="malou-text-13--semibold"> {{ 'admin.nfcs.notes' | translate }}</span>
                    <span class="malou-text-12--semibold truncate">{{ nfc.notes || DEFAULT_VALUE_DISPLAY }}</span>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #desktopTableNfcTemplate>
    <mat-table
        class="malou-mat-table hide-scrollbar h-full overflow-y-auto"
        matSort
        matSortDisableClear
        matSortDirection="asc"
        matSortActive="name"
        aria-label="Templates table"
        [dataSource]="dataSource"
        #table="matTable">
        <ng-container [matColumnDef]="NfcTableFieldName.SELECT">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    color="primary"
                    [checked]="isAllSelected | applyPure: selection.selected.length"
                    [indeterminate]="isAllSelectedIndeterminate | applyPure: selection.selected.length"
                    (change)="$event ? toggleAllRows() : null">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; table: table">
                <mat-checkbox
                    color="primary"
                    [checked]="isSelected | applyPure: row : selection.selected.length"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.CHIP_NAME">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall">
                {{ 'admin.nfcs.chip_name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-text-12--bold malou-mat-table-cell-xsmall">
                {{ nfc.chipName || DEFAULT_VALUE_DISPLAY }}
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.RESTAURANT_NAME">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.nfcs.restaurant' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table">
                <div class="flex flex-col">
                    <span>{{ nfc.restaurant?.name }}</span>
                    @if (shouldDisplayNfcAddress | applyPure: nfc) {
                        <span class="malou-text-11--regular">
                            {{ displayNfcAddress | applyPure: nfc }}
                        </span>
                    }
                </div>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.ACTIVE">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xsmall">
                {{ 'admin.nfcs.active' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-mat-table-cell-xsmall">
                <app-slide-toggle [checked]="nfc.active" (onToggle)="toggleActiveNfc(nfc, !$event)"> </app-slide-toggle>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.NAME">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                {{ 'admin.nfcs.name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-text-11--bold malou-mat-table-cell-small">
                <span class="truncate">{{ nfc.name || DEFAULT_VALUE_DISPLAY }}</span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.REDIRECTION">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                {{ 'admin.nfcs.redirection' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-mat-table-cell-small gap-x-2">
                @if (nfc.platformKey) {
                    @if (!(nfc | applySelfPure: 'isRedirectingToWheelOfFortune')) {
                        <app-platform-logo imgClasses="h-8 w-8" [logo]="nfc.platformKey"></app-platform-logo>
                        <div>{{ nfc.platformKey | enumTranslate: 'platform_key' }}</div>
                    } @else {
                        <app-platform-logo imgClasses="h-8 w-8" [logo]="WHEEL_OF_FORTUNE"></app-platform-logo>
                        <div>{{ WHEEL_OF_FORTUNE | enumTranslate: 'platform_key' }}</div>
                    }
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.REDIRECTION_LINK">
            <mat-header-cell *matHeaderCellDef>
                {{ 'admin.nfcs.redirection_link' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table">
                <button class="flex w-fit gap-x-2" [matTooltip]="nfc | applySelfPure: 'getNfcUrl'" (click)="copyChipLink(nfc)">
                    <mat-icon class="!h-[22px] !w-[22px] !p-px" color="primary" [svgIcon]="SvgIcon.COPY"></mat-icon>
                    <span class="malou-text-13--semibold malou-color-text-primary">{{ 'common.copy' | translate }}</span>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.STARS_REDIRECTED">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.nfcs.stars_redirected' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table">
                @if (!(nfc | applySelfPure: 'canBeRated')) {
                    {{ DEFAULT_VALUE_DISPLAY }}
                }
                @if (nfc | applySelfPure: 'canBeRated') {
                    <div class="flex gap-x-1">
                        @if (nfc.starsRedirected.length === NUMBER_OF_STARS_TO_SHOW_ALL) {
                            <app-star-with-text-chip [value]="'common.all_f' | translate" [widthMode]="'fit'"></app-star-with-text-chip>
                        }
                        @if (nfc.starsRedirected.length !== 5) {
                            @for (star of nfc.starsRedirected; track star) {
                                <app-star-with-text-chip [value]="star"></app-star-with-text-chip>
                            }
                        }
                    </div>
                }
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.TYPE">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                {{ 'admin.nfcs.type.title' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-text-11--bold malou-mat-table-cell-small">
                {{ (nfc | applySelfPure: 'isTotem') ? ('admin.nfcs.type.totem' | translate) : ('admin.nfcs.type.sticker' | translate) }}
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.NOTES">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'admin.nfcs.notes' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-text-11--regular" [matTooltip]="nfc.notes ?? ''">
                <span class="truncate">{{ nfc.notes || DEFAULT_VALUE_DISPLAY }}</span>
            </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="NfcTableFieldName.ACTIONS">
            <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-fit-content">
                <div class="w-4"></div>
            </mat-header-cell>
            <mat-cell *matCellDef="let nfc; table: table" class="malou-mat-table-cell-fit-content">
                <div class="flex h-full w-full items-center justify-end" [matMenuTriggerFor]="actionsMenu">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                </div>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="openUpsertNfcModal(nfc, nfc.isTotem())">
                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
                    </button>
                    @if (displayMode === NfcDisplayMode.ADMIN) {
                        <button class="flex gap-x-3" mat-menu-item (click)="deleteNfcs([nfc])">
                            <mat-icon class="!h-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'common.delete' | translate }}</span>
                        </button>
                    }
                    @if (nfc | applySelfPure: 'isSticker') {
                        <button class="flex gap-x-3" mat-menu-item (click)="downloadSticker(nfc)">
                            <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.STICKER"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'admin.nfcs.download_sticker' | translate }}</span>
                        </button>
                    }
                    @if (displayMode === NfcDisplayMode.BASIC) {
                        <button class="flex gap-x-3" mat-menu-item (click)="downloadQrCode(nfc)">
                            <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.DOWNLOAD"></mat-icon>
                            <span class="malou-text-14--regular">{{ 'admin.nfcs.download_qr_code' | translate }}</span>
                        </button>
                    }
                </mat-menu>
                @if (displayedStickerId() === nfc.id) {
                    <div>
                        <div class="absolute right-0 top-0 z-[-1]">
                            <!-- The component has to be displayed to be downloaded by the lib, so we display it in the back so the user dont see it with z-index -1 -->
                            <app-sticker [sticker]="nfc"></app-sticker>
                        </div>
                    </div>
                }
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="bg-malou-color-background-light"></mat-header-row>
        <mat-row
            *matRowDef="let nfc; columns: displayedColumns; table: table"
            class="row"
            [class.selected]="isSelected | applyPure: nfc"></mat-row>
    </mat-table>
</ng-template>

<ng-template #noNfcTemplate>
    <div class="flex h-full flex-col items-center justify-center">
        <img class="h-32" loading="lazy" [src]="'Icecream' | illustrationPathResolver" />
        <p class="malou-text-14--bold mt-9 text-center">
            {{ 'admin.nfcs.no_totem_title_admin' | translate }}
        </p>
        <p class="malou-text-10--regular my-4.5 text-center">
            {{ 'admin.nfcs.no_totem_description_admin' | translate }}
        </p>
    </div>
</ng-template>
