import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    EventEmitter,
    inject,
    OnInit,
    Output,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, Observable, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { GetTop3PostsInsightsResponseDto } from '@malou-io/package-dto';
import { PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PostsService } from ':core/services/posts.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { TopPostCardSkeletonComponent } from ':shared/components/top-post-card-skeleton/top-post-card-skeleton.component';
import { TopPostCardComponent, TopPostCardInputData } from ':shared/components/top-post-card/top-post-card.component';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, Restaurant } from ':shared/models';

import { PlatformFilterPage } from '../../store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from '../../store/aggregated-statistics.selectors';

@Component({
    selector: 'app-aggregated-top-3-posts',
    templateUrl: './aggregated-top-3-posts.component.html',
    styleUrls: ['./aggregated-top-3-posts.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, TopPostCardComponent, TopPostCardSkeletonComponent],
})
export class AggregatedTop3PostsComponent implements OnInit {
    @Output() hasDataChange = new EventEmitter<boolean>(true);
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    private readonly _store = inject(Store);
    private readonly _postsService = inject(PostsService);
    private readonly _aggregatedStatisticsFiltersContext = inject(AggregatedStatisticsFiltersContext);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _experimentationService = inject(ExperimentationService);

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(AggregatedStatisticsSelectors.selectDatesFilter);
    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;

    readonly isLoading: WritableSignal<boolean> = signal(true);
    readonly top3PostCards: WritableSignal<TopPostCardInputData[]> = signal([]);

    constructor() {
        toObservable(this.isLoading)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((isLoading) => this.isLoadingEvent.emit(isLoading));
    }

    ngOnInit(): void {
        this._getTop3Posts();
    }

    private _getTop3Posts() {
        combineLatest([
            this.dates$,
            this.platformKeys$,
            this.selectedRestaurants$,
            this._experimentationService.isFeatureEnabled$('release-aggregated-social-media-performance-improvements'),
        ])
            .pipe(
                filter(([dates, platforms]) => isDateSetOrGenericPeriod(dates) && platforms.length > 0),
                filter(([dates]) => {
                    const { startDate, endDate } = dates;
                    return !!startDate && !!endDate;
                }),
                tap(() => this.isLoading.set(true)),
                switchMap(([dates, platformKeys, restaurants, isReleaseAggregatedSocialMediaPerformanceImprovementsEnabled]) =>
                    isReleaseAggregatedSocialMediaPerformanceImprovementsEnabled
                        ? this._postsService.getTop3PostsInsightsV2({
                              restaurantIds: restaurants.map((r) => r._id),
                              platformKeys: PlatformDefinitions.getPlatformKeysWithRSStats().filter((platformKey) =>
                                  platformKeys.includes(platformKey)
                              ) as (PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM)[],
                              startDate: (dates.startDate as Date).toISOString(),
                              endDate: (dates.endDate as Date).toISOString(),
                          })
                        : this._postsService.getTop3PostsInsights({
                              restaurantIds: restaurants.map((r) => r._id),
                              platformKeys: PlatformDefinitions.getPlatformKeysWithRSStats().filter((platformKey) =>
                                  platformKeys.includes(platformKey)
                              ) as (PlatformKey.FACEBOOK | PlatformKey.INSTAGRAM)[],
                              startDate: (dates.startDate as Date).toISOString(),
                              endDate: (dates.endDate as Date).toISOString(),
                          })
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (res) => {
                    this.top3PostCards.set(res.map(this._mapTop3PostsToTopPostCardInputs));
                    if (!res.length) {
                        this.hasDataChange.emit(false);
                    }
                    this.isLoading.set(false);
                },
            });
    }

    private _mapTop3PostsToTopPostCardInputs(top3Post: GetTop3PostsInsightsResponseDto): TopPostCardInputData {
        return {
            restaurantName: top3Post.restaurantName,
            restaurantAddress: top3Post.restaurantAddress,
            postType: top3Post.postType,
            platformKey: top3Post.platformKey,
            url: top3Post.url,
            thumbnailUrl: top3Post.thumbnailUrl,
            createdAt: top3Post.createdAt,
            likes: top3Post.likes ?? 0,
            comments: top3Post.comments ?? 0,
            shares: top3Post.shares ?? 0,
            saves: top3Post.saves ?? 0,
            impressions: top3Post.impressions ?? 0,
            engagementRate: top3Post.engagementRate ?? 0,
            uuid: uuidv4(),
        };
    }
}
