import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

interface TotalGiftsData {
    totalWinningGifts: number | null;
    totalRetrievedGifts: number | null;
    totalWinningGiftsDifferenceWithPreviousPeriod: number | null;
    totalRetrievedGiftsDifferenceWithPreviousPeriod: number | null;
}

@Component({
    selector: 'app-wheel-of-fortune-gifts-kpis-v2',
    templateUrl: './wheel-of-fortune-gifts-kpis.component.html',
    styleUrls: ['./wheel-of-fortune-gifts-kpis.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        SkeletonComponent,
        ShortNumberPipe,
        NumberEvolutionComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneGiftsKpisV2Component {
    readonly data = input<WheelOfFortuneGiftsStatisticsData | null>();
    readonly isParentLoading = input<boolean>();
    readonly isParentError = input<boolean>();
    readonly hasDataChange = output<boolean>();

    readonly Illustration = Illustration;

    readonly hasData: Signal<boolean> = computed(() => {
        const data = this.data();
        if (!data) {
            return false;
        }
        const { giftsInsightsPerGift } = data;
        this.hasDataChange.emit(giftsInsightsPerGift.length > 0);
        return giftsInsightsPerGift.length > 0;
    });

    readonly totalGiftsData: Signal<TotalGiftsData> = computed(() => {
        const data = this.data();
        if (!data) {
            return {
                totalWinningGifts: null,
                totalRetrievedGifts: null,
                totalWinningGiftsDifferenceWithPreviousPeriod: null,
                totalRetrievedGiftsDifferenceWithPreviousPeriod: null,
            };
        }
        const { giftsInsightsPerGift } = data;
        const totalWinningGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.giftDrawCount ?? 0), 0);
        const totalRetrievedGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.retrievedGiftDrawCount ?? 0), 0);
        const previousTotalWinningGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.previousGiftDrawCount ?? 0), 0);
        const previousTotalRetrievedGifts = giftsInsightsPerGift.reduce((acc, curr) => acc + (curr.previousRetrievedGiftDrawCount ?? 0), 0);
        return {
            totalWinningGifts,
            totalRetrievedGifts,
            totalWinningGiftsDifferenceWithPreviousPeriod: totalWinningGifts - previousTotalWinningGifts,
            totalRetrievedGiftsDifferenceWithPreviousPeriod: totalRetrievedGifts - previousTotalRetrievedGifts,
        };
    });
}
