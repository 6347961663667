import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const deleteFeedbackMessageParamsValidator = z
    .object({
        feedback_id: objectIdValidator,
        message_id: objectIdValidator,
    })
    .transform((data) => ({
        feedbackId: data.feedback_id,
        messageId: data.message_id,
    }));
export type DeleteFeedbackMessageParamsDto = z.infer<typeof deleteFeedbackMessageParamsValidator>;

export const deleteFeedbackMessageQueryValidator = z
    .object({
        restaurant_id: objectIdValidator,
        send_fake_opened_email: z.coerce.boolean().optional(),
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
        sendFakeOpenedEmail: data.send_fake_opened_email ?? false,
    }));
export type DeleteFeedbackMessageQueryDto = z.infer<typeof deleteFeedbackMessageQueryValidator>;
