import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { forkJoin, Observable, take } from 'rxjs';

import { AggregatedBoostersStatisticsDataV2 } from ':modules/aggregated-statistics/boosters/booster.interface';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { NfcToCsvMapper } from ':shared/helpers';
import { Restaurant } from ':shared/models';

import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from '../csv-service.abstract';

interface Data {
    chartsData: AggregatedBoostersStatisticsDataV2 | undefined;
    restaurants: Restaurant[];
}

interface AggregatedScanCountCsvInsight {
    location: string;
    locationInternalName: string;
    locationAddress: string;
    booster: string;
    scanCount: number;
}

@Injectable({ providedIn: 'root' })
export class AggregatedBoostersScanCountCsvInsightV2Service extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        private readonly _nfcToCsvMapper: NfcToCsvMapper
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin({
            restaurants: this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
            chartsData: this._store.select(AggregatedStatisticsSelectors.selectBoosterStatsDataV2).pipe(take(1)),
        });
    }

    protected override _isDataValid({ chartsData, restaurants }: Data): boolean {
        return !!chartsData && restaurants.length > 0;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Location', 'Location Internal Name', 'Location Address', 'Booster', 'Scan Count'];
    }

    protected override _getCsvDataRows(data: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const { chartsData, restaurants } = data;
        const groupedData: AggregatedScanCountCsvInsight[] = [];
        chartsData.nfcs.map((nfc) => {
            const restaurant = restaurants.find((r) => r.id === nfc.restaurantId);
            if (restaurant) {
                const nfcName = this._nfcToCsvMapper.mapNfcName(nfc);
                const scanCount = chartsData.scans.filter(
                    (scan) => nfc.id === scan.nfcId && scan.nfcSnapshot?.restaurantId === nfc.restaurantId
                ).length;
                groupedData.push({
                    location: restaurant.name,
                    locationInternalName: restaurant.internalName,
                    locationAddress: restaurant.address?.formattedAddress ?? restaurant.address?.toString() ?? '-',
                    booster: nfcName,
                    scanCount: scanCount,
                });
            }
        });

        return _.orderBy(groupedData, ['location'], ['asc']).map(
            ({ location, locationInternalName, locationAddress, booster, scanCount }) => [
                location,
                locationInternalName,
                locationAddress,
                booster,
                scanCount.toString(),
            ]
        );
    }
}
