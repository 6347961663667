/* eslint-disable max-len */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';

import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces/kill-subscriptions.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

/** Custom header component for datepicker. */
@Component({
    selector: 'app-custom-mat-calendar-header',
    templateUrl: './custom-mat-calendar-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule],
})
@AutoUnsubscribeOnDestroy()
export class CustomMatCalendarHeaderComponent<D> implements KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _calendar: MatCalendar<D>,
        private readonly _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private readonly _dateFormats: MatDateFormats,
        private readonly _cdr: ChangeDetectorRef
    ) {
        this._dateAdapter = _dateAdapter;
        _calendar.stateChanges.pipe(takeUntil(this.killSubscriptions$)).subscribe(() => this._cdr.markForCheck());
    }

    get periodLabel(): string {
        return this._dateAdapter.format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
    }

    previousEnabled(): boolean {
        const MAX_PAST_DATE = this._dateAdapter.createDate(1971, 1, 1);
        const beginningOfActiveMonth = this._dateAdapter.createDate(
            this._dateAdapter.getYear(this._calendar.activeDate),
            this._dateAdapter.getMonth(this._calendar.activeDate),
            1
        );
        return beginningOfActiveMonth > (this._calendar.minDate || MAX_PAST_DATE);
    }

    nextEnabled(): boolean {
        const MAX_FUTURE_DATE = this._dateAdapter.createDate(2050, 1, 1);
        const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
        const activeMonth = this._dateAdapter.getMonth(this._calendar.activeDate);

        const maxYear = this._dateAdapter.getYear(this._calendar.maxDate || MAX_FUTURE_DATE);
        const maxMonth = this._dateAdapter.getMonth(this._calendar.maxDate || MAX_FUTURE_DATE);

        return this._dateAdapter.createDate(activeYear, activeMonth, 1) < this._dateAdapter.createDate(maxYear, maxMonth, 1);
    }

    previousClicked(): void {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    }

    nextClicked(): void {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    }
}
