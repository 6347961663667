import { CaslAction, CaslSubject } from '../casl.enum';
import { CaslPermission } from '../casl.type';
import { UserPermission } from './permission.enum';

export type UserPermissionArgs = { organizations: { _id: string }[]; organizationIds: string[] };

export const userCaslPermissions = (permission: UserPermission, user: UserPermissionArgs): CaslPermission => {
    const caslPermissionsByUserPermission: Record<UserPermission, CaslPermission> = {
        [UserPermission.DELETE_ORGANIZATION_USER]: [CaslAction.DELETE, CaslSubject.USER, { organizations: { $in: user.organizations } }],
        [UserPermission.MANAGE_ORGANIZATION_USER_CASL_ROLE]: [
            CaslAction.MANAGE,
            CaslSubject.USER,
            ['caslRole'],
            { organizations: { $in: user.organizations } },
        ],
        [UserPermission.CREATE_ORGANIZATION_USER]: [CaslAction.CREATE, CaslSubject.USER, { organizations: { $in: user.organizations } }],

        [UserPermission.MANAGE_ORGANIZATION_USER_RESTAURANT]: [
            CaslAction.MANAGE,
            CaslSubject.USER_RESTAURANT,
            { 'restaurant.organization': { $in: user.organizations } },
        ],

        [UserPermission.CREATE_ORGANIZATION_RESTAURANT]: [
            CaslAction.CREATE,
            CaslSubject.RESTAURANT,
            { organizationId: { $in: user.organizationIds } },
        ],

        [UserPermission.MANAGE_ORGANIZATION_CREDENTIALS]: [
            CaslAction.MANAGE,
            CaslSubject.CREDENTIAL,
            { organization: { $in: user.organizations } },
        ],
    };
    return caslPermissionsByUserPermission[permission];
};
