import { AsyncPipe, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, switchMap, take } from 'rxjs';

import { ApplicationLanguage, CGU_LINK, isNotNil, mapLanguageStringToApplicationLanguage } from '@malou-io/package-utils';

import { JimoSupportedLanguages, JimoType } from ':core/constants';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { LocalStorage } from ':core/storage/local-storage';
import * as JimoActions from ':modules/jimo/jimo.actions';
import { EditUserModalComponent } from ':modules/user/edit-user-modal/edit-user-modal.component';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { SelectLanguagesComponent } from ':shared/components/select-languages/select-languages.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

interface AppState {
    user: any;
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        SelectLanguagesComponent,
        IllustrationPathResolverPipe,
        FlagPathResolverPipe,
        ShortTextPipe,
        TitleCasePipe,
        AsyncPipe,
        EnumTranslatePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
    private readonly _store: Store<AppState> = inject(Store);

    readonly userInfos$ = this._store.select(selectUserInfos);
    readonly user = toSignal<User | null>(this.userInfos$, { initialValue: null });

    readonly currentLang = mapLanguageStringToApplicationLanguage(LocalStorage.getLang());

    readonly CGU_LINK_DATA = CGU_LINK;
    readonly Illustration = Illustration;
    readonly SvgIcon = SvgIcon;

    readonly APP_LANGUAGES = Object.values(ApplicationLanguage);

    readonly isUpdatingLang = signal<boolean>(false);

    private readonly _usersService = inject(UsersService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _screenSizeService = inject(ScreenSizeService);

    constructor() {
        // need to load it once to trigger animations 'appearFromBottom' of modal
        this._screenSizeService.resize$.pipe(takeUntilDestroyed()).subscribe();
    }

    openEditUserModal(): void {
        this._customDialogService
            .open(EditUserModalComponent, {
                width: '650px',
                height: 'auto',
                disableClose: false,
                data: {
                    user: this.user(),
                },
            })
            .afterClosed();
    }

    changeLang(event: ApplicationLanguage | ApplicationLanguage[]): void {
        let lang: ApplicationLanguage;
        if (Array.isArray(event)) {
            lang = event[0];
        } else {
            lang = event;
        }
        this.isUpdatingLang.set(true);

        LocalStorage.setLang(lang);
        if (JimoSupportedLanguages.includes(lang)) {
            (window as JimoType).jimo?.push(['set', 'core:language', [lang]]);
        }
        this.userInfos$
            .pipe(
                take(1),
                filter(isNotNil),
                switchMap((userInfos) => this._usersService.updateUser$(userInfos._id, { defaultLanguage: lang }))
            )
            .subscribe({
                next: (res) => {
                    this._store.dispatch(UserActions.editUserInfos({ infos: new User(res.data) }));
                    location.reload();
                },
                error: (err) => {
                    console.warn('err updating user language :>>', err);
                    this.isUpdatingLang.set(false);
                },
            });
        this._store.dispatch(JimoActions.updateLanguage({ language: lang }));
    }
}
