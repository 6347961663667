import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const swapPlannedPublicationDatesBodyValidator = z.object({
    /** the list of plannedPublicationDates to reassign */
    reassign: z.array(
        z.object({
            /** plannedPublicationDates will be read from this post */
            sourcePostId: objectIdValidator,

            /** The post that will be updated with the publication date of sourcePostId. */
            destinationPostId: objectIdValidator,
        })
    ),
});

export type SwapPlannedPublicationDatesBodyDto = z.infer<typeof swapPlannedPublicationDatesBodyValidator>;
