import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { forkJoin, map, Observable, take } from 'rxjs';

import { ReviewResponseDto } from '@malou-io/package-dto';

import { STARS_RATING } from ':core/constants';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { NfcToCsvMapper } from ':shared/helpers';
import { LightNfc } from ':shared/models';
import { ScanForRestaurantInsights } from ':shared/models/scan';
import { AbstractCsvService, CsvAsStringArrays, DataWithNilExcluded } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    nfcs: LightNfc[] | undefined;
    scans: ScanForRestaurantInsights[] | undefined;
    privateReviews: ReviewResponseDto[] | undefined;
}

@Injectable({ providedIn: 'root' })
export class BoostersReviewsCountCsvInsightV2Service extends AbstractCsvService<Data> {
    constructor(
        private readonly _store: Store,
        private readonly _nfcToCsvMapper: NfcToCsvMapper
    ) {
        super();
    }

    protected _getData$(): Observable<Data> {
        return forkJoin({
            boostersData: this._store.select(StatisticsSelectors.selectBoosterStatsDataV2).pipe(take(1)),
            privateReviews: this._store.select(StatisticsSelectors.selectPrivateReviewsData).pipe(take(1)),
        }).pipe(map(({ boostersData, privateReviews }) => ({ nfcs: boostersData?.nfcs, scans: boostersData?.scans, privateReviews })));
    }

    protected override _isDataValid(data: Data): boolean {
        return !!data;
    }

    protected override _getCsvHeaderRow(): string[] {
        return ['Booster', 'Rating', 'Private Review Count', 'Collected Reviews Estimate'];
    }

    protected override _getCsvDataRows(data: DataWithNilExcluded<Data>): CsvAsStringArrays {
        const { nfcs, scans, privateReviews } = data;
        const scansById = groupBy(scans, (el) => el.id.toString());
        const groupedData = nfcs.reduce((acc: string[][], nfc) => {
            const nfcScans = scans.filter((scan) => nfc.id === scan.nfcId);
            const nfcPrivateReviewsScan: ReviewResponseDto[] = [];
            privateReviews.forEach((privateReview) => {
                if (privateReview.scanId) {
                    const scan = scansById[privateReview.scanId.toString()];
                    if (scan && scan[0].nfcId === nfc.id) {
                        nfcPrivateReviewsScan.push(privateReview);
                    }
                }
            });
            const nfcName = this._nfcToCsvMapper.mapNfcName(nfc);
            STARS_RATING.forEach((rating) => {
                const scansFiltered = nfcScans?.filter(
                    (scan) => scan.matchedReview?.id && (scan.starClicked === rating || scan.matchedReview?.rating === rating)
                );
                const privateReviewsFiltered = nfcPrivateReviewsScan.filter((privateReview) => privateReview.rating === rating);
                acc.push([nfcName, rating.toString(), privateReviewsFiltered.length.toString(), scansFiltered?.length.toString() ?? '0']);
            });
            return acc;
        }, []);
        return groupedData ?? [];
    }
}
