@if (!isParentError()) {
    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
        <div>
            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
        </div>

        @if (!isParentLoading()) {
            @if (boosterData().hasData) {
                <div>
                    <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                </div>
                <div>
                    <ng-container [ngTemplateOutlet]="cardsTemplate"></ng-container>
                </div>
            } @else {
                <div class="flex h-[442px] flex-col items-center justify-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                    <div class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</div>
                </div>
            }
        } @else {
            <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
        }
    </div>
} @else {
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <span class="malou-text-section-title malou-color-text-1">{{ 'aggregated_statistics.boosters.scans.scan_count' | translate }}</span>
        <div class="flex items-center gap-2">
            <span class="malou-text-14--medium md:hidden">{{ 'common.sort_by' | translate }}</span>
            <app-select
                [values]="SORT_BY_VALUES"
                [displayWith]="sortByDisplayWith"
                [formControl]="sortByControl"
                (selectChange)="onSortByChange($event)"></app-select>
        </div>
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-aggregated-boosters-scan-count-chart
        [boostersData]="boosterData().chartData"
        [restaurants]="boosterData().restaurantsToDisplay"></app-aggregated-boosters-scan-count-chart>
</ng-template>

<ng-template #cardsTemplate>
    <div class="flex">
        <div class="malou-simple-card--light mr-3 flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ boosterData().scanCountOnPeriod | shortNumber }}
                </div>
                @if (boosterData().scanCountOnPeriod) {
                    <app-number-evolution
                        [value]="boosterData().scanCountDifferenceWithPreviousPeriod"
                        [displayedValue]="boosterData().scanCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'aggregated_statistics.boosters.scans.direct_scans' | translate }}
            </div>
        </div>
        <div class="malou-simple-card--light ml-3 flex flex-1 flex-col items-center py-3">
            <div class="flex items-center">
                <div class="malou-text-30--bold malou-color-text-1">
                    {{ boosterData().wheelOfFortuneCountOnPeriod | shortNumber }}
                </div>
                @if (boosterData().wheelOfFortuneCountOnPeriod) {
                    <app-number-evolution
                        [value]="boosterData().wheelOfFortuneCountDifferenceWithPreviousPeriod"
                        [displayedValue]="
                            boosterData().wheelOfFortuneCountDifferenceWithPreviousPeriod | shortNumber
                        "></app-number-evolution>
                }
            </div>
            <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
                {{ 'aggregated_statistics.boosters.scans.wheel_of_fortune' | translate }}
            </div>
        </div>
    </div>
</ng-template>
