import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, input, OnInit, Output, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PlatformKey, PostPublicationStatus, PostType } from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { Story } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

import { MediaDurationComponent } from '../media-duration/media-duration.component';

@Component({
    selector: 'app-story',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        ApplyPurePipe,
        TranslateModule,
        MatIconModule,
        NgClass,
        NgStyle,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        SocialPostMediaComponent,
        EnumTranslatePipe,
        MediaDurationComponent,
        MatMenuModule,
        PlatformLogoComponent,
    ],
    templateUrl: './story.component.html',
    styleUrls: ['./story.component.scss'],
})
export class StoryComponent implements OnInit {
    readonly story = input.required<Story>();
    readonly isStoryChecked = input.required();
    @Output() storyChecked = new EventEmitter<boolean>();
    @Output() deleteStoryEvent = new EventEmitter<string>();
    @Output() editStoryEvent = new EventEmitter<string>();
    @Output() duplicate = new EventEmitter<{ destination: DuplicationDestination; story: Story }>();

    readonly SvgIcon = SvgIcon;
    clearError: Signal<string | null | undefined> = computed(() => {
        const story = this.story();
        return story.published === PostPublicationStatus.ERROR ? story.clarifyError?.(story.errorData, this._translateService) : null;
    });
    mediaDuration: number;

    PublicationStatus = PostPublicationStatus;
    DuplicationDestination = DuplicationDestination;

    readonly DEFAULT_PHOTO_DURATION = 5;

    readonly canOpenStorySocialLink: Signal<boolean> = computed(() => !!this.story().socialLink && this.story().isActive);

    constructor(
        public readonly screenSizeService: ScreenSizeService,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.story()?.postType === PostType.IMAGE) {
            this.mediaDuration = this.DEFAULT_PHOTO_DURATION;
        }
    }

    onLoadedVideoMetadata(event: Event): void {
        const duration = Math.floor((event.target as HTMLVideoElement).duration);
        this.mediaDuration = duration;
    }

    onPostCheckedChange(event: MatCheckboxChange): void {
        this.storyChecked.emit(event.checked);
    }

    deleteStory(): void {
        this.deleteStoryEvent.emit(this.story()._id);
    }

    editStory(): void {
        this.editStoryEvent.emit(this.story()._id);
    }

    openSocialLink(): void {
        if (this.story().socialLink) {
            window.open(this.story().socialLink, '_blank');
        }
    }

    isStoryNotPublishedYet(statusType: ExtendedPostPublicationStatus): boolean {
        return [ExtendedPostPublicationStatus.DRAFT, ExtendedPostPublicationStatus.PENDING, ExtendedPostPublicationStatus.ERROR].includes(
            statusType
        );
    }

    duplicateStory(destination: DuplicationDestination): void {
        this.duplicate.emit({ destination, story: this.story() });
    }

    platformKeysToShow(): PlatformKey[] {
        if (this.story().key) {
            return [this.story().key];
        }
        return this.story().keys;
    }
}
