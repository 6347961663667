import { Primitive, z } from 'zod';

export function isValidZodLiteralUnion<T extends z.ZodLiteral<unknown>>(literals: T[]): literals is [T, T, ...T[]] {
    return literals.length >= 2;
}

export function constructZodLiteralUnionType<T extends Primitive>(values: readonly T[]) {
    const literals = values.map((value) => z.literal(value));
    if (!isValidZodLiteralUnion(literals)) {
        throw new Error('Literals passed do not meet the criteria for constructing a union schema, the minimum length is 2');
    }

    return z.union(literals);
}
