import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { InformationUpdateErrorNotification } from ':core/components/notification-center/models/information-update-error-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-information-update-error-notification-item',
    templateUrl: './information-update-error-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, DatePipe, MatButtonModule, MatIconModule],
})
export class InformationUpdateErrorNotificationItemComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);
    readonly notification = computed<InformationUpdateErrorNotification>(() => this.initialNotification());
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.restaurantIds?.[0])
    );

    readonly SvgIcon = SvgIcon;

    onNotificationClick(): void {
        this._notificationsCenterContext.trackNotification({
            heapEventName: HeapEventName.INFORMATION_UPDATE_ERROR_REDIRECT,
            notificationId: this.notification()?.id,
            properties: {
                restaurantId: this.notification().data.restaurantIds[0],
                notificationType: this.notification()?.type,
            },
        });
        this.markAsRead();
        this._navigateToPlatform();
        this.notificationCenterContext.close();
    }

    private _navigateToPlatform(): void {
        this._router.navigate(['restaurants', this.notification().data.restaurantIds[0], 'seo', 'informations'], {
            queryParams: {
                nid: this.notification().data.informationUpdateId,
            },
        });
    }
}
