import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    input,
    model,
    OnInit,
    output,
    signal,
    viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { catchError, debounceTime, filter, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';

import {
    DEFAULT_MAX_IMAGE_SIZE,
    InputFileType,
    InputMediaType,
    MediaCategory,
    PictureSize,
    PostSource,
    PostType,
    SizeInBytes,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { MediaEditionState } from ':core/constants';
import { DialogService } from ':core/services/dialog.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { MediaDimensionsService } from ':core/services/media-dimensions.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { IGAccount } from ':modules/inspirations/inspirations/edit-inspiring-accounts-dialog/edit-inspiring-accounts-dialog.component';
import { MediaPickerModalComponent } from ':modules/media/media-picker-modal/media-picker-modal.component';
import { MediaPickerFilter } from ':modules/media/media-picker-modal/media-picker-modal.interface';
import { MediaService } from ':modules/media/media.service';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { MediaFileUploaderComponent } from ':shared/components/media-file-uploader/media-file-uploader.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { DndDirective } from ':shared/directives/dnd.directive';
import { CropOption } from ':shared/enums/crop-options';
import { PictureFormat } from ':shared/enums/picture-format';
import { parseAspectRatio } from ':shared/helpers/dimensions';
import { isSafari } from ':shared/helpers/is-safari';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Media, UserTag } from ':shared/models';
import { ImageProperties } from ':shared/models/image-properties';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { MediaViewComponent } from './components/media-view/media-view.component';
import { TagAccountComponent } from './components/tag-account/tag-account.component';
import { HorizontallyScrollableDirective } from './horizontally-scrollable.directive';
import { ResizeMetadataComputationService } from './services/resize-metadata-computation.service';

export const DEFAULT_MAX_MEDIAS = 20;

export const DEFAULT_ACCEPTED_MEDIA_TYPES = [InputMediaType.IMAGE, InputMediaType.VIDEO];

enum MediaResetErrorTooltipType {
    NOT_FIRST_MEDIA = 'NOT_FIRST_MEDIA',
    ALREADY_ORIGINAL = 'ALREADY_ORIGINAL',
}

@Component({
    selector: 'app-media-editor',
    templateUrl: './media-editor.component.html',
    styleUrls: ['./media-editor.component.scss'],
    standalone: true,
    imports: [
        CdkDropList,
        CdkDrag,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        ColorPickerModule,
        FormsModule,
        ImageCropperModule,
        LazyLoadImageModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatTooltipModule,
        MatOptionModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        TranslateModule,
        DndDirective,
        HorizontallyScrollableDirective,
        ApplyPurePipe,
        IllustrationPathResolverPipe,
        MalouSpinnerComponent,
        MediaFileUploaderComponent,
        ApplySelfPurePipe,
        SkeletonComponent,
        MatProgressBarModule,
        MediaViewComponent,
        TagAccountComponent,
    ],
    providers: [ColorPickerService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaEditorComponent implements OnInit {
    readonly maxImageSize = input<number>(DEFAULT_MAX_IMAGE_SIZE);
    readonly initialMedias = input<Media[]>([]);
    readonly attachmentsName = input<string | null>(null);
    readonly acceptedMediaTypes = input<InputMediaType[]>(DEFAULT_ACCEPTED_MEDIA_TYPES);
    readonly maxMedia = input<number>(DEFAULT_MAX_MEDIAS);
    readonly isTextEditable = input<boolean>(false);
    readonly igPlatformId = input<string | null>(null);
    readonly initialUserTagsList = model<UserTag[][] | null>(null);
    readonly disabled = input<boolean>(false);
    readonly isMediaDeletable = input<boolean>(true);
    readonly maxVideoSize = input<number>(0);
    readonly isTaggable = input(true);

    readonly mediasSelected = output<Media[]>();
    readonly fileChanged = output<Media[]>();
    readonly accountTagged = output<UserTag[][]>();
    readonly editing = output<MediaEditionState>();

    readonly medias = signal<Media[]>(this.initialMedias());
    readonly userTagsList = signal<UserTag[][] | null>(null);
    readonly editedImageContainer = signal<ElementRef | undefined>(undefined);
    readonly croppedImage = signal<string | null>('');
    readonly cropOption = signal(CropOption.PORTRAIT);
    readonly rotation = signal(0);
    readonly state = signal('crop');
    readonly format = signal<PictureFormat>(PictureFormat.JPEG);
    readonly tagButtonToggled = signal(false);
    readonly dimensionsButtonToggled = signal(false);
    readonly rotationButtonToggled = signal(false);
    readonly textEditionButtonToggled = signal(false);
    readonly backgroundEditionButtonToggled = signal(false);
    readonly isCropping = signal(false);
    readonly draggingOver = signal(false);
    readonly originalMediasById = signal<Record<string, Media>>({});
    readonly currentMediaIndex = signal(0);
    readonly restaurantId = signal<string | null>(null);
    readonly foundAccount = signal<IGAccount | null>(null);
    readonly searching = signal(false);
    readonly imageCropperMediaLoading = signal(true);
    readonly showTagContainer = signal(false);
    readonly carrouselContainerScrollX = signal({ left: false, right: false });
    readonly cropCommitLoading = signal(false);

    readonly videoPlayerElement = viewChild<HTMLVideoElement | undefined>('videoPlayer');
    readonly imageContainer = viewChild<HTMLImageElement | undefined>('imageContainer');
    readonly areMediasLoading = signal(false);
    readonly mediaLoadingPercentage = signal(0);
    readonly ratio = signal(4 / 5);

    readonly angle = computed(() => this.rotation() - this.initialAngle());
    readonly currentOriginalMedia = computed(() => this.originalMediasById()[this.currentMedia().id] ?? this.currentMedia());
    readonly initialAngle = computed<number>(() => this.rotation() + 0);
    readonly currentMedia = computed(() => this.medias()[this.currentMediaIndex()]);
    readonly currentMediaUrl = computed(() => this.medias()[this.currentMediaIndex()]?.getMediaUrl(PictureSize.IG_FIT));
    readonly isCurrentMediaVideo = computed(() => this.medias()[this.currentMediaIndex()]?.isVideo());
    readonly userTags = computed(() => this.userTagsList()?.[this.currentMediaIndex()] ?? []);
    readonly shouldResizeImgHeight = computed(() => !this.textEditionButtonToggled());
    readonly currentOriginalMediaUrl = computed(
        () =>
            this.originalMediasById()[this.medias[this.currentMediaIndex()]?.id]?.getMediaUrl(PictureSize.ORIGINAL) ??
            this.currentMedia()?.getMediaUrl(PictureSize.ORIGINAL)
    );
    readonly canChangeAspectRatio = computed(
        () =>
            // TODO: Remove the second part of the condition when the feature flag is removed
            this.currentMediaIndex() === 0 || !this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')
    );
    // we need to have the image fill full width if we want to add text. Html2canvas takes a screenshot of the entire imageContainer that cannot have any overflow.

    readonly SvgIcon = SvgIcon;
    readonly shouldShowSlider = !isSafari;
    readonly tagControl = new FormControl<string>('');
    readonly CropOption = CropOption;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');
    readonly MediaResetErrorTooltipType = MediaResetErrorTooltipType;

    currentTagXPosition = 0.5;
    currentTagYPosition = 0.5;

    constructor(
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _mediaService: MediaService,
        private readonly _spinnerService: SpinnerService,
        private readonly _dialogService: DialogService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _postsService: PostsService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _mediaDimensionsService: MediaDimensionsService,
        private readonly _resizeMetadataComputationService: ResizeMetadataComputationService,
        public readonly experimentationService: ExperimentationService
    ) {
        effect(
            () => {
                this.userTagsList.set(this.initialUserTagsList());
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                this.medias.set(this.initialMedias());
                this._initializeOriginalMedias(this.initialMedias());
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                this.tagButtonToggled.set(this.isTaggable() === false ? false : this.tagButtonToggled());
            },
            {
                allowSignalWrites: true,
            }
        );

        toObservable(this.medias)
            .pipe(
                filter((medias) => medias.length > 0),
                take(1)
            )
            .subscribe({
                next: (medias) => {
                    this.cropOption.set(this._getCorrespondingAspectRatio(medias[0]) ?? CropOption.PORTRAIT);
                    this.ratio.set(this._getCorrespondingAspectRatio(medias[0]) ?? CropOption.PORTRAIT);
                },
            });
    }

    ngOnInit(): void {
        if (this.medias[this.currentMediaIndex()]?.getMediaUrl('igFit')?.includes('.png')) {
            this.format.set(PictureFormat.PNG);
        }

        this.tagControl.valueChanges
            .pipe(
                filter((text) => {
                    this.searching.set(true);
                    this.foundAccount.set(null);
                    const isText = !!text && typeof text === 'string';
                    if (!isText) {
                        this.searching.set(false);
                        return false;
                    }
                    return true;
                }),
                debounceTime(400),
                switchMap((text) => {
                    if (!text || !this.igPlatformId()) {
                        return of(null);
                    }
                    return this._postsService.igSearch(text, this.igPlatformId()!).pipe(catchError(() => of({ data: null })));
                })
            )
            .subscribe({
                next: (result) => {
                    const account = result?.data?.business_discovery;
                    this.searching.set(false);
                    this.foundAccount.set(account);
                },
                error: () => {
                    this.searching.set(false);
                },
            });
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage.set(event.base64 ?? null);
    }

    imageCropperReady(): void {
        this.imageCropperMediaLoading.set(false);
    }

    getAcceptedInputFileTypes(acceptedMediaType: InputMediaType[]): string {
        const inputFileTypes: InputFileType[] = [];
        if (acceptedMediaType.includes(InputMediaType.IMAGE)) {
            inputFileTypes.push(InputFileType.PNG, InputFileType.JPEG, InputFileType.HEIF, InputFileType.HEIC);
        }
        if (acceptedMediaType.includes(InputMediaType.VIDEO)) {
            inputFileTypes.push(InputFileType.VIDEO, InputFileType.QUICKTIME);
        }
        return inputFileTypes.join(',');
    }

    changeAspectRatio(option: CropOption): void {
        if (this.canChangeAspectRatio()) {
            this.cropOption.set(option);
            this.ratio.set(option);
        }
    }

    commitCurrentEdition(): void {
        if (this.currentMedia().isVideo()) {
            this._updateMediasResizeMetadata(this.medias(), this.cropOption()).subscribe({
                next: (medias) => {
                    this.medias.set(medias);
                    this.fileChanged.emit(this.medias());
                    this.cropCommitLoading.set(false);
                    this.closeDimensionEditors();
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    this.cropCommitLoading.set(false);
                },
            });
            return;
        }
        if (!this.croppedImage()) {
            this.rotation.set(0);
            this.closeDimensionEditors();
            return;
        }
        this.saveCroppedMedia$(this.originalMediasById()[this.currentMedia().id] ?? this.currentMedia(), this.croppedImage()!)
            .pipe(
                switchMap((newMedia) =>
                    this._updateMediasResizeMetadata(
                        this.medias().map((media) => (media.urls.original === this.currentMedia().urls.original ? newMedia : media)),
                        this.cropOption()
                    )
                )
            )
            .subscribe({
                next: (newMedias) => {
                    this.originalMediasById.update((old) => {
                        const originalMedia = old[this.currentMedia().id] ?? this.currentMedia();
                        delete old[this.currentMedia().id];
                        return { ...old, [newMedias[this.currentMediaIndex()].id]: originalMedia };
                    });

                    this.medias.set(newMedias);
                    this.fileChanged.emit(this.medias());
                    this.ratio.set(newMedias[0].resizeMetadata?.aspectRatio);
                    this.croppedImage.set(null);
                    this.rotation.set(0);
                    this.closeDimensionEditors();
                    this.cropCommitLoading.set(false);
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    this.cropCommitLoading.set(false);
                },
            });
    }

    closeDimensionEditors(): void {
        this.isCropping.set(false);
        this.dimensionsButtonToggled.set(false);
        this.rotationButtonToggled.set(false);
        this.cropCommitLoading.set(false);
        this.cropOption.set(this.ratio());
        this.imageCropperMediaLoading.set(true);
        this.editing.emit(MediaEditionState.FINISHED_EDITING);
    }

    openRotationEditor(): void {
        this.editing.emit(MediaEditionState.EDITING);
        this.rotationButtonToggled.set(true);
        this.dimensionsButtonToggled.set(false);
        this.showTagContainer.set(false);
        this.tagButtonToggled.set(false);
        this.textEditionButtonToggled.set(false);
        this.isCropping.set(true);
    }

    openCropEditor(): void {
        this.editing.emit(MediaEditionState.EDITING);
        this.dimensionsButtonToggled.set(true);
        this.rotationButtonToggled.set(false);
        this.showTagContainer.set(false);
        this.tagButtonToggled.set(false);
        this.textEditionButtonToggled.set(false);
        this.isCropping.set(true);
    }

    toggleTag(): void {
        this.tagButtonToggled.set(!this.tagButtonToggled());
        this.rotationButtonToggled.set(false);
        this.dimensionsButtonToggled.set(false);
        this.isCropping.set(false);
        if (this.tagButtonToggled() && this.userTagsList()?.[this.currentMediaIndex()]?.length === 0) {
            this.showTagContainer.set(true);
        }
    }

    closeTagContainer(): void {
        this.showTagContainer.set(false);
    }

    getMediaUrl(media: Media, size: string = 'small'): string {
        return media.getMediaUrl(size);
    }

    getMediaTypeFilter(): MediaPickerFilter {
        if (this.acceptedMediaTypes().length === 1 && this.acceptedMediaTypes()[0] === InputMediaType.IMAGE) {
            return MediaPickerFilter.ONLY_IMAGE;
        }
        if (this.acceptedMediaTypes().length === 1 && this.acceptedMediaTypes()[0] === InputMediaType.VIDEO) {
            return MediaPickerFilter.ONLY_VIDEO;
        }
        return MediaPickerFilter.ALL;
    }

    openMediaPickerModal(): void {
        this._customDialogService
            .open(MediaPickerModalComponent, {
                width: '600px',
                data: {
                    restaurant: this._restaurantsService.currentRestaurant,
                    multi: this.maxMedia() - this.medias().length > 1,
                    filter: this.getMediaTypeFilter(),
                    maxVideoSize: this.maxVideoSize(),
                    maxMedia: this.maxMedia() - this.medias().length,
                    maxMediaReachedMessage: this._translate.instant('social_posts.max_number_of_media_reached', { max: this.maxMedia() }),
                },
            })
            .afterClosed()
            .pipe(
                filter((medias: Media[]) => !!medias && medias.length > 0),
                tap(() => this.areMediasLoading.set(true)),
                concatMap((medias) => {
                    const observables = medias.map((medium) => {
                        if (medium.dimensions) {
                            return of(medium);
                        }
                        return this._mediaDimensionsService.getMediaProperties$({ medium, isReel: false, fit: PictureSize.ORIGINAL }).pipe(
                            switchMap((mediaProperties) => {
                                const updatedMedium = new Media({
                                    ...medium,
                                    dimensions: {
                                        original: {
                                            width: mediaProperties.width,
                                            height: mediaProperties.height,
                                        },
                                    },
                                });
                                return this._mediaService.updateMediaById(updatedMedium.id, updatedMedium).pipe(map((res) => res.data));
                            }),
                            map((media) => {
                                const imageProperties = new ImageProperties({
                                    width: media.dimensions?.original.width,
                                    height: media.dimensions?.original.height,
                                    bytes: media.getBytesForSize(PictureSize.ORIGINAL),
                                });
                                const mediaErrors = this._getImageErrors(imageProperties);
                                media.setErrors(mediaErrors);
                                return media;
                            })
                        );
                    });
                    return forkJoin(observables);
                }),
                tap((medias: Media[]) => {
                    if (this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
                        this.onFileProcessed(medias);
                    } else {
                        this._resizeMediasWithErrors(medias);
                    }
                }),
                switchMap((medias: Media[]) => this._mediaService.fetchMediaDescription(medias?.map((media) => media.id)))
            )
            .subscribe();
    }

    onFileChanged(event: Event | FileList): void {
        if (!this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
            this._spinnerService.show();
        }
        this.draggingOver.set(false);
        const eventTarget = (event as Event).target as HTMLInputElement;
        const fileList = eventTarget?.files || (event as FileList) || [];
        if (!fileList?.[0]) {
            return;
        }

        let formatErrors = 0;
        let sizeErrors = 0;
        if (this.maxMedia() === 1 && fileList.length > 1) {
            this._openErrorDialog(
                this._translate.instant('social_posts.new_social_post.error_too_many_medias'),
                this._translate.instant('social_posts.new_social_post.only_one_media')
            );
            this._spinnerService.hide();
            return;
        } else if (this.medias().length + fileList.length > this.maxMedia()) {
            this._openErrorDialog(
                this._translate.instant('social_posts.new_social_post.error_too_many_medias'),
                this._translate.instant('social_posts.max_number_of_media_reached', { max: this.maxMedia() })
            );
            this._spinnerService.hide();
            return;
        }
        if (
            this.acceptedMediaTypes().length === 1 &&
            this.acceptedMediaTypes().includes(InputMediaType.VIDEO) &&
            !fileList[0]?.type.match(/video\/(quicktime|mp4)/)
        ) {
            this._openErrorDialog(
                this._translate.instant('social_posts.new_social_post.error_wrong_format'),
                this._translate.instant('social_posts.new_social_post.should_be_video')
            );
            this._spinnerService.hide();
            return;
        }
        Array.from(fileList).forEach((file) => {
            if (!this._isAcceptedImageType(file) && !this._isAcceptedVideoType(file)) {
                formatErrors++;
            }
            if (this._isFileTooBig(file)) {
                sizeErrors++;
            }
        });
        if (formatErrors > 0) {
            this._openFileWrongFormatError(sizeErrors);
            return;
        }

        if (sizeErrors > 0) {
            this._openFilesTooBigError(formatErrors);
        }

        this._mediaService
            .uploadAndCreateMedia(
                Array.from(fileList).map((file) => ({
                    data: file,
                    metadata: {
                        category: MediaCategory.ADDITIONAL,
                        restaurantId: this._restaurantsService.currentRestaurant._id,
                        title: this.attachmentsName() ?? '',
                        desiredAspectRatio: parseAspectRatio(this.cropOption()),
                    },
                }))
            )
            .pipe(
                map((res) =>
                    res.data.map((media) => {
                        const newMedia = new Media(media);
                        newMedia.setErrors(
                            this._getImageErrors(
                                new ImageProperties({
                                    width: newMedia.dimensions.original.width,
                                    height: newMedia.dimensions.original.height,
                                    bytes: newMedia.sizes.original,
                                })
                            )
                        );
                        return newMedia;
                    })
                )
            )
            .subscribe({
                next: (medias) => {
                    if (this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
                        this.onFileProcessed(medias);
                    } else {
                        this._resizeMediasWithErrors(medias);
                    }
                },
                error: (err) => {
                    console.warn('error', err);
                    this._spinnerService.hide();
                },
            });
    }

    removeMedia(media: Media): void {
        if (this.dimensionsButtonToggled()) {
            this.closeDimensionEditors();
        }
        this.originalMediasById.update((old) => {
            delete old[media.id];
            return { ...old };
        });
        if (this.currentMediaIndex() === this.medias().length - 1 && this.medias().length !== 1) {
            this.currentMediaIndex.update((old) => old - 1);
        }
        const idx = this.medias().findIndex((m) => m.id === media.id);
        this.userTagsList()?.splice(idx, 1);
        this.accountTagged.emit(this.userTagsList() ?? []);

        this.medias.set(this.medias().filter((m) => m.urls.original !== media.urls.original));
        this.fileChanged.emit(this.medias());
        if (this.medias().length === 0) {
            this.cropOption.set(CropOption.PORTRAIT);
            this.ratio.set(CropOption.PORTRAIT);
        }
    }

    dragOver(): void {
        if (this.canAddMoreMedia()) {
            this.draggingOver.set(true);
        }
    }

    dragLeave(): void {
        this.draggingOver.set(false);
    }

    canAddMoreMedia(): boolean {
        return !this.disabled() && this.medias().length < this.maxMedia();
    }

    changeCurrentSelectedMedia(mediaIndex: number): void {
        this.currentMediaIndex.set(mediaIndex);
        if (this.isCurrentMediaVideo()) {
            return;
        }
        if (this.medias[this.currentMediaIndex()]?.getMediaUrl('igFit')?.includes('.png')) {
            this.format.set(PictureFormat.PNG);
        } else {
            this.format.set(PictureFormat.JPEG);
        }
    }

    mediaDropped(event: CdkDragDrop<Media[]>): void {
        moveItemInArray(this.medias(), event.previousIndex, event.currentIndex);
        moveItemInArray(this.userTagsList() ?? [], event.previousIndex, event.currentIndex);
        this.fileChanged.emit(this.medias());
        this.accountTagged.emit(this.userTagsList() ?? []);
        this.currentMediaIndex.set(event.currentIndex);
    }

    playVideo(): void {
        if (!this.videoPlayerElement()) {
            return;
        }
        if (this.videoPlayerElement()?.paused) {
            this.videoPlayerElement()?.play();
        } else {
            this.videoPlayerElement()?.pause();
        }
    }

    muteUnmuteVideo(): void {
        if (!this.videoPlayerElement()) {
            return;
        }
        this.videoPlayerElement()!.muted = !this.videoPlayerElement()?.muted;
    }

    isVideoMuted(): boolean {
        return !!this.videoPlayerElement()?.muted;
    }

    isVideoPlaying(): boolean {
        return !this.videoPlayerElement()?.paused;
    }

    saveCroppedMedia$(oldMedia: Media, croppedImageBase64: string): Observable<Media> {
        return forkJoin([this._transformMedia(croppedImageBase64), this._store.select(selectUserInfos).pipe(take(1))]).pipe(
            switchMap(([file, user]) =>
                this._mediaService.uploadAndCreateMedia([
                    {
                        data: file,
                        metadata: {
                            restaurantId: oldMedia.restaurantId,
                            title: oldMedia.title,
                            userId: user?._id,
                            originalMediaId: this.originalMediasById()[oldMedia.id]?.id ?? oldMedia.id,
                            category: MediaCategory.ADDITIONAL,
                        },
                    },
                ])
            ),
            switchMap((res) => {
                const newMedia = new Media(res.data[0]);
                if (this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
                    const mediaErrors = this._getImageErrors(
                        new ImageProperties({
                            width: newMedia.dimensions.original.width,
                            height: newMedia.dimensions.original.height,
                            bytes: newMedia.sizes.original,
                        })
                    );
                    newMedia.setErrors(mediaErrors);
                    return of(newMedia);
                }

                // ! TODO: REMOVE THIS WHEN THE FEATURE FLAG IS REMOVED
                return newMedia.getProperties$().pipe(
                    map((properties) => {
                        if (!properties) {
                            return newMedia;
                        }
                        const mediaErrors = this._getImageErrors(properties);
                        newMedia.setErrors(mediaErrors);
                        newMedia.setMediaResizeMetadata({
                            ...properties,
                            type: this._getPostType(newMedia),
                        });
                        return newMedia;
                    })
                );
            })
        );
    }

    resetCurrentImage(): void {
        if (this.getResetCurrentImageErrorMessage(this.currentMedia(), this.originalMediasById(), this.cropOption())?.length) {
            return;
        }
        const oldMedia = this.originalMediasById()[this.currentMedia().id] ?? this.currentMedia();
        if (this.originalMediasById()[this.currentMedia()?.id]) {
            this.medias.update((old) =>
                old.map((media) => (media.id === this.currentMedia().id ? this.originalMediasById()[this.currentMedia().id] : media))
            );
            this.originalMediasById.update((old) => {
                delete old[this.currentMedia().id];
                return old;
            });

            this.fileChanged.emit(this.medias());
            this.croppedImage.set(null);
        }
        if (this.currentMediaIndex() === 0) {
            this.cropOption.set(this._getCorrespondingAspectRatio(oldMedia));
            this.ratio.set(this.cropOption());
            if (!this.originalMediasById()[this.currentMedia()?.id]) {
                this._updateMediasResizeMetadata(this.medias(), this.cropOption()).subscribe({
                    next: (medias) => {
                        this.medias.set(medias);
                        this.fileChanged.emit(this.medias());
                    },
                });
            }
        }
    }

    getResetCurrentImageErrorMessage = (
        currentMedia: Media | undefined,
        originalMedias: Record<string, Media>,
        cropOption: CropOption
    ): string | null => {
        if (!currentMedia) {
            return null;
        }
        const mediaAspectRatio = this._getCorrespondingAspectRatio(currentMedia);
        if (currentMedia?.isVideo()) {
            if (cropOption !== CropOption.PORTRAIT && this.currentMediaIndex() === 0) {
                return null;
            }
        }
        if (
            this.currentMediaIndex() !== 0 &&
            mediaAspectRatio !== parseAspectRatio(cropOption) &&
            (!originalMedias[currentMedia.id] || originalMedias[currentMedia.id]?.id !== currentMedia.id)
        ) {
            return MediaResetErrorTooltipType.NOT_FIRST_MEDIA;
        }

        if (
            (originalMedias[currentMedia.id]?.id === currentMedia.id && mediaAspectRatio === parseAspectRatio(cropOption)) ||
            (!originalMedias[currentMedia.id] && mediaAspectRatio === parseAspectRatio(cropOption)) ||
            (this.currentMediaIndex() !== 0 &&
                (originalMedias[currentMedia.id]?.id === currentMedia.id || !originalMedias[currentMedia.id]))
        ) {
            return MediaResetErrorTooltipType.ALREADY_ORIGINAL;
        }

        return null;
    };

    toggleShowTagContainer(event: { x: number; y: number }): void {
        this.showTagContainer.set(true);
        const { x, y } = event;
        setTimeout(() => {
            this.currentTagXPosition = x;
            this.currentTagYPosition = y;
        });
        this.tagControl.setValue('');
        document.addEventListener('keyup', (ev) => this._hideTagContainerOnEscape(ev));
    }

    addAccount(event: MatAutocompleteSelectedEvent): void {
        const username = event.option.value.username;
        const x = this.currentTagXPosition;
        const y = this.currentTagYPosition;

        if (
            this.userTagsList() &&
            username.length &&
            !this.userTagsList()?.[this.currentMediaIndex()]?.find((el) => el.username === username)
        ) {
            if (!this.userTagsList()?.[this.currentMediaIndex()]) {
                this.userTagsList.update((old) => {
                    if (!old) {
                        old = [];
                        return old;
                    }
                    old[this.currentMediaIndex()] = [];
                    return old;
                });
            }
            this.userTagsList()?.[this.currentMediaIndex()].push({
                username,
                x,
                y,
            });
        }
        this.tagControl.setValue('');
        this.accountTagged.emit(this.userTagsList() ?? []);
        this.showTagContainer.set(false);
        document.removeEventListener('keydown', (ev) => this._hideTagContainerOnEscape(ev));
    }

    // TODO: REMOVE THIS WHEN THE FEATURE FLAG IS REMOVED
    getCurrentTagPositionInMedia(x: number, y: number): { left: string; top: string } {
        const image = document.getElementById('imageContainer') as HTMLImageElement;
        const width = image.offsetWidth;
        const height = image.offsetHeight;

        return {
            left: x * width + 'px',
            top: y * height + 'px',
        };
    }

    removeAccount(account: string): void {
        if (this.userTagsList()) {
            this.userTagsList.update((old) => {
                if (!old) {
                    return [];
                }
                old[this.currentMediaIndex()] = old[this.currentMediaIndex()]?.filter((el) => el.username !== account);
                return [...old];
            });
        }
        this.accountTagged.emit(this.userTagsList() ?? []);
    }

    displayRotationWith(value: number): string {
        return `${value}°`;
    }

    scrollRight(): void {
        const carouselItemWidth = 100;
        document.getElementById('carrousel-container')?.scrollBy({
            behavior: 'smooth',
            left: carouselItemWidth,
        });
    }

    scrollLeft(): void {
        const carouselItemWidth = 100;
        document.getElementById('carrousel-container')?.scrollBy({
            behavior: 'smooth',
            left: -carouselItemWidth,
        });
    }

    onFileProcessed(createdMedias: Media[]): void {
        if (!this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
            this._spinnerService.hide();
        }
        // Initialize crop option on first media upload
        // Will ensure that we don't end up with bad aspect ratio
        if (this.medias().length === 0) {
            this.cropOption.set(this._getCorrespondingAspectRatio(createdMedias[0]) ?? CropOption.PORTRAIT);
        }

        if (createdMedias.length > 0) {
            if (this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
                this._updateMediasResizeMetadata(createdMedias, this.cropOption()).subscribe({
                    next: (updatedMedias) => {
                        this.medias.set([...this.medias().filter((media) => !!media.id), ...updatedMedias]);
                        this.mediasSelected.emit(this.medias());

                        this.changeCurrentSelectedMedia(this.currentMediaIndex());
                        this.userTagsList.set(this.medias().map((_m, idx) => this.userTagsList()?.[idx] ?? []) ?? []);
                        this.accountTagged.emit(this.userTagsList() ?? []);
                        this.areMediasLoading.set(false);
                        this.mediaLoadingPercentage.set(0);
                        this.fileChanged.emit(this.medias());
                    },
                    error: (err) => {
                        console.warn('err :>>', err);
                        this.areMediasLoading.set(false);
                        this.mediaLoadingPercentage.set(0);
                    },
                });
            } else {
                this._resizeMediasWithErrors(createdMedias);
            }
        }
    }

    startReadingFile(numberOfFiles: number): void {
        if (this.experimentationService.isFeatureEnabled('release-post-media-upload-performance')) {
            this.areMediasLoading.set(true);
            this.mediaLoadingPercentage.set(0);
            if (this.medias().length > 0 && numberOfFiles > 0) {
                this.medias.set([...this.medias(), ...Array(numberOfFiles).fill(new Media({}))]);
            }
        } else {
            this._spinnerService.show();
        }
    }

    onProgressChanged(progress: number): void {
        this.mediaLoadingPercentage.set(progress);
    }

    processError(error: Error | string): void {
        this._spinnerService.hide();
        const errorMessage = typeof error === 'string' ? error : (error.message ?? error.toString());
        this._toastService.openErrorToast(errorMessage);
    }

    displayWithKey = (elem: { key: string }): string => elem.key;

    isCropOptionSelected(cropOptions: CropOption[], currentCropOption: CropOption): boolean {
        return cropOptions.map((cropOption) => parseAspectRatio(currentCropOption) === parseAspectRatio(cropOption)).includes(true);
    }

    onSliderInput(event: any): void {
        this.rotation.set(event.target.value ?? 0);
    }

    private _getCorrespondingAspectRatio(media: Media): number {
        if (!media.dimensions?.original || this.disabled()) {
            return media.resizeMetadata?.aspectRatio ?? CropOption.PORTRAIT;
        }
        const mediaAspectRatio = media.dimensions.original.width / media.dimensions.original.height;
        return mediaAspectRatio > 1 ? CropOption.LANDSCAPE : mediaAspectRatio < 1 ? CropOption.PORTRAIT : CropOption.SQUARE;
    }

    // TODO: Remove on release post media upload performance
    private _resizeMediasWithErrors(createdMedias: Media[]): void {
        this._spinnerService.hide();
        this.areMediasLoading.set(true);
        forkJoin<Media[]>(
            createdMedias.map((media, index) =>
                this._mediaDimensionsService
                    .areMediaDimensionsValid$({
                        medium: media,
                        isReel: false,
                        mediaIndex: index,
                    })
                    .pipe(
                        map(({ properties }) => {
                            media.setMediaResizeMetadata(properties);
                            return media;
                        })
                    )
            )
        )
            .pipe(
                switchMap((medias) =>
                    this._postsService
                        .resizePostAttachments$(medias, PostSource.SOCIAL, {
                            shouldForceResizeToRecommendedSize: true,
                        })
                        .pipe(
                            map((res) =>
                                medias.map((m) => {
                                    const resizedMedia = res.find((r) => r.originalMediaId === m.id);
                                    if (resizedMedia) {
                                        return resizedMedia;
                                    }
                                    return m;
                                })
                            ),
                            map((res) => ({ newMedias: res, oldMedias: medias }))
                        )
                )
            )
            .subscribe({
                next: ({ newMedias, oldMedias }) => {
                    if (newMedias) {
                        oldMedias = [...this.medias(), ...oldMedias];
                        this.medias.set([...this.medias(), ...newMedias]);
                        this.mediasSelected.emit(this.medias());
                        this.originalMediasById.set(
                            this.medias().reduce((acc, media, index) => ({ ...acc, [media.id]: oldMedias[index] }), {})
                        );

                        this.changeCurrentSelectedMedia(this.currentMediaIndex());
                        this.userTagsList.set(this.medias().map((_m, idx) => this.userTagsList()?.[idx] ?? []) ?? []);
                        this.accountTagged.emit(this.userTagsList() ?? []);
                    }
                    this.areMediasLoading.set(false);
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this.areMediasLoading.set(false);
                },
            });
    }

    private _hideTagContainerOnEscape(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.showTagContainer.set(false);
        }
    }

    private _transformMedia(mediaBase64: string): Observable<File> {
        return from(
            fetch(mediaBase64)
                .then((res) => res.blob())
                .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
        );
    }

    private _isFileTooBig(file: File): boolean {
        const maxFileSize = this._isImage(file) ? this.maxImageSize() : this.maxVideoSize();
        return file.size > maxFileSize;
    }

    private _isAcceptedImageType(file: File): boolean {
        return !!file?.type.match(/image\/(jpeg|png|jpg)/);
    }

    private _isAcceptedVideoType(file: File): boolean {
        return !!file.type.match(/video\/(quicktime|mp4)/);
    }

    private _isImage(file: File): boolean {
        return !!file.type.match(/image/);
    }

    private _openErrorDialog(title: string, message: string): void {
        this._dialogService.open({
            title,
            message,
            variant: DialogVariant.ERROR,
            primaryButton: {
                label: this._translate.instant('common.understood'),
            },
        });
    }

    private _openFilesTooBigError(sizeErrors: number): void {
        const errors = `${this._translate.instant('social_posts.new_social_post.large_file')}\n ${sizeErrors} ${this._translate.instant(
            'social_posts.new_social_post.happened_x_times'
        )}`;

        this._openErrorDialog(this._translate.instant('social_posts.new_social_post.error_media_too_big'), errors);
        this._spinnerService.hide();
    }

    private _openFileWrongFormatError(formatErrors: number): void {
        let errorText = '';
        if (this.acceptedMediaTypes().includes(InputMediaType.IMAGE)) {
            errorText += this._translate.instant('social_posts.new_social_post.should_be_png') + ' ';
        }
        if (this.acceptedMediaTypes().includes(InputMediaType.VIDEO)) {
            errorText += this._translate.instant('social_posts.new_social_post.should_be_mp4');
        }
        const errors = `${errorText}\n ${formatErrors} ${this._translate.instant('social_posts.new_social_post.happened_x_times')}`;

        this._openErrorDialog(this._translate.instant('social_posts.new_social_post.error_wrong_format'), errors);
        this._spinnerService.hide();
    }

    private _getImageErrors(imgProperties: ImageProperties): string[] {
        const errors: string[] = [];

        const minimumSizeInBytes = 10 * SizeInBytes.KILO_BYTES;
        if (imgProperties.isTooSmallSizeImage(minimumSizeInBytes)) {
            const actualSizeInKiloBytes = imgProperties.bytes / SizeInBytes.KILO_BYTES;
            const minimumSizeInKiloBytes = minimumSizeInBytes / SizeInBytes.KILO_BYTES;
            errors.push(
                this._translate.instant('posts.new_post.too_small_size_image', {
                    actualSize: actualSizeInKiloBytes,
                    minimumSize: minimumSizeInKiloBytes,
                })
            );
        }
        const minimumWidth = 250;
        const minimumHeight = 250;
        if (imgProperties.isTooSmallDimensionsImage(minimumWidth, minimumHeight)) {
            const actualWidth = imgProperties.width;
            const actualHeight = imgProperties.height;
            const dimensionsErrorMessage = this._translate.instant('posts.new_post.too_small_dimensions_image', {
                minimumWidth,
                minimumHeight,
                actualWidth,
                actualHeight,
            });
            errors.push(dimensionsErrorMessage);
        }

        return errors;
    }
    private _getPostType(medium: Media): PostType {
        if (medium.isVideo()) {
            return PostType.VIDEO;
        } else {
            return PostType.IMAGE;
        }
    }

    private _initializeOriginalMedias(medias: Media[]): void {
        forkJoin(
            medias
                .filter((media) => !!media.originalMediaId)
                .map((media) =>
                    this._mediaService.getMediumById(media.originalMediaId!).pipe(
                        map((res) => (res.data.id ? new Media(res.data) : media)),
                        tap((originalMedia) => {
                            this.originalMediasById.update((old) => ({ ...old, [media.id]: originalMedia }));
                        })
                    )
                )
        ).subscribe();
    }

    private _updateMediasResizeMetadata(medias: Media[], desiredAspectRatio?: number): Observable<Media[]> {
        return forkJoin(
            medias.map((media) =>
                this._mediaService.updateMediaById(media.id, {
                    resizeMetadata: this._resizeMetadataComputationService.computeResizeMetadata({
                        mediaAspectRatio: media.getAspectRatio(),
                        desiredAspectRatio: parseAspectRatio(
                            desiredAspectRatio ?? media.resizeMetadata.aspectRatio ?? this._getCorrespondingAspectRatio(media)
                        ),
                        height: media.dimensions.original.height,
                        width: media.dimensions.original.width,
                    }),
                })
            )
        ).pipe(
            map((results) => results.map((res) => new Media(res.data))),
            tap((mediasWithResizeMetadata) => {
                this.cropOption.set(mediasWithResizeMetadata[0].resizeMetadata.aspectRatio);
                this.ratio.set(mediasWithResizeMetadata[0].resizeMetadata.aspectRatio);
            })
        );
    }
}
