import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, forkJoin, Observable, of, switchMap, take } from 'rxjs';

import { isNotNil, PlatformDefinitions, PlatformKey, ReviewReplyAutomationComment, TemplateType } from '@malou-io/package-utils';

import { AutomationsService } from ':core/services/automations.service';
import { DialogService } from ':core/services/dialog.service';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { TemplatesService } from ':core/services/templates.service';
import { AutomationCardComponent } from ':modules/automations/automation-card/automation-card.component';
import {
    EditReviewReplyAutomationsModalComponent,
    EditReviewReplyAutomationsModalInputData,
} from ':modules/automations/review-reply-automations/edit-review-reply-automations-modal/edit-review-reply-automations-modal.component';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Platform, Restaurant, Template } from ':shared/models';
import { ReviewReplyAutomation } from ':shared/models/automations';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-review-reply-automations',
    templateUrl: './review-reply-automations.component.html',
    standalone: true,
    imports: [NgTemplateOutlet, MatButtonModule, MatIconModule, TranslateModule, AutomationCardComponent, SkeletonComponent],
})
export class ReviewReplyAutomationsComponent {
    readonly isLoadingAutomations = signal(false);
    readonly isLoadingTemplates = signal(false);
    readonly isLoading = computed(() => this.isLoadingAutomations() && this.isLoadingTemplates());

    readonly automations: WritableSignal<ReviewReplyAutomation[]> = signal([]);
    templates: Template[];

    readonly restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    restaurant: Restaurant;

    availableAutoResponsePlatforms: PlatformKey[] = [];

    readonly reviewAutomationsWithComment = computed(() =>
        this.automations()?.filter((automation) => automation.withComment === ReviewReplyAutomationComment.WITH_COMMENT)
    );

    readonly reviewAutomationsWithoutComment = computed(() =>
        this.automations()?.filter((automation) => automation.withComment === ReviewReplyAutomationComment.WITHOUT_COMMENT)
    );

    constructor(
        private readonly _automationsService: AutomationsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _translateService: TranslateService,
        private readonly _templatesService: TemplatesService,
        private readonly _store: Store,
        private readonly _keywordsService: KeywordsService,
        private readonly _dialogService: DialogService,
        private readonly _router: Router
    ) {
        this._checkIfHasKeywords();
        combineLatest([this.restaurant$, this._store.select(selectCurrentPlatforms)])
            .pipe(
                filter(([restaurant]) => isNotNil(restaurant)),
                switchMap(([restaurant, currentPlatforms]: [Restaurant, Platform[]]) => {
                    this.availableAutoResponsePlatforms = this._getAutoResponsePlatforms(currentPlatforms);
                    this.restaurant = restaurant;
                    this.isLoadingTemplates.set(true);
                    this.isLoadingAutomations.set(true);
                    return forkJoin([
                        this._automationsService.getRestaurantReviewReplyAutomations(this.restaurant._id),
                        this._templatesService.getTemplatesByRestaurantId(this.restaurant._id, TemplateType.REVIEW),
                    ]);
                }),
                takeUntilDestroyed()
            )
            .subscribe({
                next: ([automations, templates]) => {
                    this.isLoadingTemplates.set(false);
                    this.isLoadingAutomations.set(false);
                    this.automations.set(automations);
                    this.templates = templates;
                },
                error: (err) => {
                    console.warn('Error while getting automations and templates', err);
                },
            });
    }

    openReviewsWithCommentAutomationsModal(): void {
        this._openModal(ReviewReplyAutomationComment.WITH_COMMENT);
    }

    openReviewsWithoutCommentAutomationsModal(): void {
        this._openModal(ReviewReplyAutomationComment.WITHOUT_COMMENT);
    }

    private _openModal(withComment: ReviewReplyAutomationComment): void {
        const title =
            withComment === ReviewReplyAutomationComment.WITH_COMMENT
                ? this._translateService.instant('automations.reviewReplies.automations_with_comment')
                : this._translateService.instant('automations.reviewReplies.automations_without_comment');

        const data: EditReviewReplyAutomationsModalInputData = {
            title,
            restaurant: this.restaurant,
            automations: this.automations(),
            templates: this.templates,
            withComment,
            availableAutoResponsePlatforms: this.availableAutoResponsePlatforms,
        };

        this._customDialogService
            .open(EditReviewReplyAutomationsModalComponent, {
                height: 'unset',
                width: '750px',
                data,
            })
            .afterClosed()
            .subscribe((automations: ReviewReplyAutomation[]) => {
                if (automations) {
                    this.automations.set(automations);
                }
            });
    }

    private _getAutoResponsePlatforms(platforms: Platform[]): PlatformKey[] {
        return PlatformDefinitions.getPlatformKeysForReviewReplyAutomation().filter((platform) =>
            platforms.some((p) => p.key === platform)
        );
    }

    private _checkIfHasKeywords(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(Boolean),
                switchMap((restaurant) => forkJoin([of(restaurant), this._keywordsService.getKeywordsCountForRestaurant(restaurant._id)])),
                take(1)
            )
            .subscribe({
                next: ([restaurant, res]) => {
                    if (res.data > 0) {
                        return;
                    }
                    if (!restaurant.isBrandBusiness()) {
                        this._dialogService.open({
                            variant: DialogVariant.ALERT,
                            title: this._translateService.instant('automations.reviewReplies.keywords.title'),
                            message: this._translateService.instant('automations.reviewReplies.keywords.text'),
                            primaryButton: {
                                label: this._translateService.instant('automations.reviewReplies.keywords.confirm'),
                                action: () => this._router.navigate(['/restaurants', restaurant._id, 'resources', 'keywords', 'list']),
                            },
                            secondaryButton: {
                                label: this._translateService.instant('common.cancel'),
                            },
                        });
                    }
                },
            });
    }
}
