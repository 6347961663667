<div class="malou-simple-card flex h-full flex-col gap-5 px-6 py-4">
    <div class="flex">
        <span class="malou-text-section-title malou-color-text-1">
            {{ 'statistics.e_reputation.reviews_kpis.ratings' | translate }}
        </span>
    </div>
    @if (isLoading()) {
        <app-skeleton skeletonClass="!h-[350px]"></app-skeleton>
    } @else {
        @if (!httpError()) {
            @if (hasData()) {
                <mat-table
                    class="malou-mat-table overflow-y-auto"
                    matSort
                    [dataSource]="dataSource()"
                    [matSortActive]="sort.active"
                    [matSortDirection]="sort.direction"
                    #table="matTable">
                    @for (column of displayedColumns(); track column) {
                        <ng-container [matColumnDef]="column">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ getTableColumnDisplayName | applyPure: column }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element; table: table">
                                <div class="hidden lg:flex">
                                    <span>
                                        {{ getTableColumnDisplayName | applyPure: column }}
                                    </span>
                                </div>
                                <ng-container
                                    [ngTemplateOutlet]="column === RESTAURANT_COLUMN ? restaurantCellTemplate : platformCellTemplate"
                                    [ngTemplateOutletContext]="{
                                        cellData: column === RESTAURANT_COLUMN ? element.restaurant : element.platformKpis[column],
                                    }">
                                </ng-container>
                            </mat-cell>
                        </ng-container>
                    }
                    <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns(); table: table"></mat-row>
                </mat-table>
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{ 'statistics.e_reputation.reviews_kpis.no_data' | translate }}</span>
                </div>
            }
        } @else {
            <div class="flex flex-col items-center py-6">
                <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
                <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
                <span class="malou-text-10--regular">{{ httpError() | statisticsHttpError }}</span>
            </div>
        }
    }
</div>

<ng-template let-cellData="cellData" #restaurantCellTemplate>
    <span class="malou-color-text-1 malou-text-13--semibold">{{ cellData.name }}</span>
</ng-template>

<ng-template let-cellData="cellData" #platformCellTemplate>
    <div class="flex items-center justify-center">
        <span class="malou-text-14--bold malou-color-text-1 mr-2 text-center leading-none">
            {{ cellData.hasData ? (cellData.currentRating | shortNumber) : '-' }}
        </span>
        <div
            [ngClass]="{
                'high-evolution': cellData.diffRating === highestEvolution && cellData.diffRating !== 0,
                'worst-regression': cellData.diffRating === worstRegression && cellData.diffRating !== 0,
            }">
            @if (cellData.hasData) {
                <app-number-evolution
                    [value]="cellData.diffRating"
                    [displayedValue]="cellData.diffRating | shortNumber: { shouldDisplayMinusSign: false }"
                    [size]="'small'"
                    [reverseArrow]="false">
                </app-number-evolution>
            }
        </div>
    </div>
</ng-template>
