import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, effect, input, OnInit, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { difference, isEqual, isEqualWith, partition } from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { GetReviewAnalysesChartDataByRestaurantIdResponseDto } from '@malou-io/package-dto';
import {
    isSameDay,
    PlatformDefinitions,
    PlatformKey,
    ReviewAnalysisChartDataSentiment,
    ReviewAnalysisChartDataTag,
} from '@malou-io/package-utils';

import { ReviewAnalysesService } from ':core/services/review-analyses.service';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ReviewAnalysesChartFilter } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { TagsBarChartComponent } from ':shared/components/review-analyses-v2/tags-bar-chart/tags-bar-chart.component';
import { TagsDoughnutChartComponent } from ':shared/components/review-analyses-v2/tags-doughnut-chart/tags-doughnut-chart.component';
import { TagsEvolutionComponent } from ':shared/components/review-analyses-v2/tags-evolution/tags-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

@Component({
    selector: 'app-review-analyses-v2',
    templateUrl: './review-analyses.component.html',
    styleUrls: ['./review-analyses.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        TagsBarChartComponent,
        TagsDoughnutChartComponent,
        TagsEvolutionComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class ReviewAnalysesV2Component implements OnInit, KillSubscriptions {
    shouldDetailTagsEvolutionCharts = input(false);
    shouldDisplayAnalysesTagCharts = input(true);
    shouldDisplayAnalysesTagEvolution = input(true);
    showSplitByRestaurant = input(true);
    showSeeMoreButton = input(false);
    tagsEvolutionSortBy = input<ChartSortBy | undefined>(undefined);
    tagsEvolutionSortByChange = output<ChartSortBy>();
    hasDataChange = output<boolean>();
    isLoadingEvent = output<boolean>();

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;

    readonly detailHref = environment.BASE_URL + '/groups/statistics/e-reputation';

    readonly isLoading = signal(false);
    readonly warningTooltip = signal<string | undefined>(undefined);
    readonly reviewAnalysesChartData = signal<ReviewAnalysesChartDataByRestaurantId>(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
    readonly hasData = computed(() => this.reviewAnalysesChartData().hasData(ReviewAnalysisChartDataTag.TOTAL));

    readonly reviewAnalysesFilter = signal<ReviewAnalysesChartFilter>({
        startDate: null,
        endDate: null,
        keys: [],
        restaurantIds: [],
    });

    readonly dates$: Observable<{ startDate: Date | null; endDate: Date | null }> = this._store
        .select(AggregatedStatisticsSelectors.selectDatesFilter)
        .pipe(
            distinctUntilChanged((prev, curr) => {
                const isSameStartDate = !!prev.startDate && !!curr.startDate && isSameDay(prev.startDate, curr.startDate);
                const isSameEndDate = !!prev.endDate && !!curr.endDate && isSameDay(prev.endDate, curr.endDate);
                return isSameStartDate && isSameEndDate;
            })
        );

    readonly platformKeys$: Observable<PlatformKey[]> = this._store
        .select(AggregatedStatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
        .pipe(
            distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqual(prev, curr)),
            map((platforms) => {
                if (platforms?.length) {
                    return platforms;
                }
                return Object.values(PlatformKey);
            })
        );

    readonly selectedRestaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
        distinctUntilChanged((prev, curr) => prev.length === curr.length && isEqualWith(prev, curr, (c, d) => c.id === d.id))
    );

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _store: Store,
        private readonly _reviewAnalysesService: ReviewAnalysesService,
        private readonly _translate: TranslateService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => {
            this.isLoadingEvent.emit(this.isLoading());
        });
    }

    ngOnInit(): void {
        combineLatest([
            this.dates$.pipe(tap(() => this._resetChartData())),
            this.platformKeys$.pipe(
                tap(() => this._resetChartData()),
                map((platforms) => platforms.filter((key) => !PlatformDefinitions.getDeliveryPlatformKeys().includes(key as PlatformKey)))
            ),
            this.selectedRestaurants$,
        ])
            .pipe(
                filter(
                    ([dates, platforms, restaurants]) =>
                        !!dates.startDate && !!dates.endDate && platforms.length > 0 && !!restaurants.length
                ),
                tap(() => {
                    this._reset();
                }),
                map(([dates, platforms, restaurants]) => {
                    const [businessRestaurant, nonBusinessRestaurants] = partition(restaurants, (r) => r.isBrandBusiness());
                    this.warningTooltip.set(this._computeWarningTooltip(businessRestaurant));
                    const restaurantIdsWithDataAlreadyFetched = [...this.reviewAnalysesChartData().keys()];
                    const restaurantIdsWeNeedToFetch = difference(
                        nonBusinessRestaurants.map((r) => r.id),
                        restaurantIdsWithDataAlreadyFetched
                    );
                    this._removeRestaurantIdsFromChartData(nonBusinessRestaurants, restaurantIdsWithDataAlreadyFetched);
                    this.reviewAnalysesFilter.set({
                        startDate: dates.startDate,
                        endDate: dates.endDate,
                        keys: platforms,
                        restaurantIds: nonBusinessRestaurants.map((r) => r.id),
                    });
                    return [dates, platforms, restaurantIdsWeNeedToFetch];
                }),
                filter(
                    ([_dates, _platforms, restaurantIds]: [{ startDate: Date | null; endDate: Date | null }, PlatformKey[], string[]]) => {
                        if (restaurantIds.length === 0) {
                            this.isLoading.set(false);
                            this.hasDataChange.emit(false);
                            return false;
                        }
                        return true;
                    }
                ),
                switchMap(([dates, platforms, restaurantIdsWeNeedToFetch]) => {
                    const { startDate, endDate } = dates;
                    const emptyData = this._buildEmptyChartData(restaurantIdsWeNeedToFetch);
                    return this._reviewAnalysesService
                        .getReviewAnalysesChartData({
                            startDate,
                            endDate,
                            keys: platforms,
                            restaurantIds: restaurantIdsWeNeedToFetch,
                        })
                        .pipe(map((res) => emptyData.merge(ReviewAnalysesChartDataByRestaurantId.fromDto(res.data))));
                }),
                filter((newReviewAnalysesChartData: ReviewAnalysesChartDataByRestaurantId) => {
                    if (newReviewAnalysesChartData.hasData(ReviewAnalysisChartDataTag.TOTAL)) {
                        return true;
                    }
                    this.isLoading.set(false);
                    this.hasDataChange.emit(false);
                    return false;
                }),
                catchError((error) => {
                    this.isLoading.set(false);
                    this.hasDataChange.emit(false);
                    console.error('Error while fetching review analyses chart data', error);
                    return of(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (newReviewAnalysesChartData: ReviewAnalysesChartDataByRestaurantId) => {
                    this.reviewAnalysesChartData.update((reviewAnalysesChartData) =>
                        reviewAnalysesChartData.merge(newReviewAnalysesChartData)
                    );
                    this.isLoading.set(false);
                },
            });
    }

    private _computeWarningTooltip(restaurants: Restaurant[]): string | undefined {
        if (!restaurants.length) {
            return;
        }
        const restaurantsLabel = restaurants.map((e) => e.name).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _reset(): void {
        this.isLoading.set(true);
        this.warningTooltip.set(undefined);
    }

    private _resetChartData(): void {
        this.reviewAnalysesChartData.set(ReviewAnalysesChartDataByRestaurantId.fromDto({}));
    }

    /**
     * Return empty data for all restaurants
     * Useful because if we don't retrieve result for a restaurant,
     * we will always do a call for this restaurant when changing the date or platformKeys
     */
    private _buildEmptyChartData(restaurantIds: string[]): ReviewAnalysesChartDataByRestaurantId {
        const defaultTagData = {
            [ReviewAnalysisChartDataSentiment.POSITIVE]: 0,
            [ReviewAnalysisChartDataSentiment.NEGATIVE]: 0,
            [ReviewAnalysisChartDataSentiment.TOTAL]: 0,
        };
        const defaultData = {
            [ReviewAnalysisChartDataTag.ATMOSPHERE]: { ...defaultTagData },
            [ReviewAnalysisChartDataTag.EXPEDITIOUSNESS]: { ...defaultTagData },
            [ReviewAnalysisChartDataTag.FOOD]: { ...defaultTagData },
            [ReviewAnalysisChartDataTag.HYGIENE]: { ...defaultTagData },
            [ReviewAnalysisChartDataTag.PRICE]: { ...defaultTagData },
            [ReviewAnalysisChartDataTag.SERVICE]: { ...defaultTagData },
            [ReviewAnalysisChartDataTag.TOTAL]: { ...defaultTagData },
        };
        return ReviewAnalysesChartDataByRestaurantId.fromDto(
            restaurantIds.reduce((acc, restaurantId) => {
                acc[restaurantId] = { ...defaultData };
                return acc;
            }, {} as GetReviewAnalysesChartDataByRestaurantIdResponseDto)
        );
    }

    private _removeRestaurantIdsFromChartData(nonBusinessRestaurants: Restaurant[], restaurantIdsWithDataAlreadyFetched: string[]): void {
        const restaurantIdsToDelete = difference(
            restaurantIdsWithDataAlreadyFetched,
            nonBusinessRestaurants.map((r) => r.id)
        );
        if (restaurantIdsToDelete.length) {
            this.reviewAnalysesChartData.update((reviewAnalysesChartData) => reviewAnalysesChartData.deleteMany(restaurantIdsToDelete));
            this.reviewAnalysesFilter.update((filters) => ({
                ...filters,
                restaurantIds: difference(filters.restaurantIds, restaurantIdsToDelete),
            }));
        }
    }
}
