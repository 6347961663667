<div class="mt-6 grid grid-cols-2 gap-6">
    <div
        class="max-h-[560px] w-full"
        [ngClass]="{ 'col-span-2': reviewsRatingKpisDisplayedColumnsCount() > 3 || (isRestaurantsCountUiLimitExceeded$ | async) }">
        <app-reviews-rating-kpis-v2
            (displayedColumnsCount)="reviewsRatingKpisDisplayedColumnsCount.set($event)"
            (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS, $event)"
            (isLoadingEvent)="isReviewsRatingsKpisLoading.set($event)">
        </app-reviews-rating-kpis-v2>
    </div>
    <div class="w-full" [ngClass]="{ 'col-span-2': isRestaurantsCountUiLimitExceeded$ | async }">
        <app-reviews-count-v2
            [chartReviewsDataWithPreviousPeriod$]="this.chartReviewsDataWithPreviousPeriod$"
            [isLoading]="isReviewsCountLoading"
            (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
            (tableSortByChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)"
            (viewModeChange)="onViewModeChange(InsightsChart.AGGREGATED_REVIEWS_COUNT, $event)">
        </app-reviews-count-v2>
    </div>
    <div
        class="w-full"
        [ngClass]="{ 'col-span-2': reviewsRatingKpisDisplayedColumnsCount() <= 3 || (isRestaurantsCountUiLimitExceeded$ | async) }">
        <app-reviews-ratings-average-v2
            [chartReviewsDataWithPreviousPeriod$]="this.chartReviewsDataWithPreviousPeriod$"
            [isLoading]="isReviewsRatingsAverageLoading"
            (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
            (tableSortByChange)="onTableSortOptionsChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)"
            (viewModeChange)="onViewModeChange(InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE, $event)">
        </app-reviews-ratings-average-v2>
    </div>
</div>
