import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { catchError, combineLatest, forkJoin, Observable, of, tap } from 'rxjs';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { GetMergedInformationUpdateResponseDto, MergedInformationUpdateDto, SaveInformationUpdateDataBodyDto } from '@malou-io/package-dto';
import {
    HeapEventName,
    InformationUpdatePlatformStateStatus,
    isNotNil,
    NotificationChannel,
    NotificationType,
    PlatformAccessType,
    PlatformDefinition,
    PlatformDefinitions,
    PlatformKey,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { DialogService } from ':core/services/dialog.service';
import { InformationUpdatesService } from ':core/services/information-update.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SuggestionsService } from ':core/services/suggestions.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { AttributesUpdateData } from ':modules/informations/attributes-modal/attributes-modal.component';
import { AttributesComponent } from ':modules/informations/attributes/attributes.component';
import { DescriptionUpdateData } from ':modules/informations/description-modal/description-modal.component';
import { DescriptionComponent } from ':modules/informations/description/description.component';
import {
    DisconnectedPlatformsModalComponent,
    DisconnectedPlatformsModalProps,
    DisconnectedPlatformsModalResult,
} from ':modules/informations/disconnected-platforms-modal/disconnected-platforms-modal.component';
import { HoursModalComponent, HoursModalTabs, HoursUpdateData } from ':modules/informations/hours-modal/hours-modal.component';
import { HoursComponent } from ':modules/informations/hours/hours.component';
import { InformationSuggestionModalComponent } from ':modules/informations/information-suggestions-modal/information-suggestions-modal.component';
import { Suggestion } from ':modules/informations/information-suggestions-modal/store/suggestions.interface';
import { selectCurrentSuggestion } from ':modules/informations/information-suggestions-modal/store/suggestions.reducer';
import { InformationsContext } from ':modules/informations/informations.context';
import {
    InformationUpdateData,
    InfosRestaurantModalComponent,
} from ':modules/informations/infos-restaurant-modal/infos-restaurant-modal.component';
import { InfosRestaurantComponent } from ':modules/informations/infos-restaurant/infos-restaurant.component';
import { PlatformsComparisonsModalComponent } from ':modules/informations/platforms-comparisons-modal/platforms-comparisons-modal.component';
import { platformsUpdateConfig } from ':modules/informations/platforms-update-config';
import * as InformationsActions from ':modules/informations/store/informations.actions';
import { InformationsUpdateStatus } from ':modules/informations/store/informations.interface';
import * as fromStore from ':modules/informations/store/informations.reducer';
import * as PlatformActions from ':modules/platforms/store/platforms.actions';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import * as RestaurantsActions from ':modules/restaurant-list/restaurant-list.actions';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { getPreviousDataBodyDtoFromRestaurant } from ':shared/helpers/information-updates.helper';
import { Step } from ':shared/interfaces/step.interface';
import { ComparisonKey, Platform, PlatformComparisonWithStatus, Restaurant } from ':shared/models';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';
import { HeapEmailEventsTrackerService } from ':shared/services/heap-email-events-tracker.service';

import { DetectedInconsistenciesComponent } from './detected-inconsistencies/detected-inconsistencies.component';
import { InformationSuggestionsComponent } from './information-suggestions/information-suggestions.component';
import { InformationsGaugeComponent } from './informations-gauge/informations-gauge.component';
import { InformationsUpdatesStateComponent } from './informations-updates-state/informations-updates-state.component';

enum UpdateState {
    DIFFERENT = 'different',
    WAITING = 'waiting',
    UPDATED = 'updated',
    ERROR = 'error',
}

interface DuplicationResponse {
    success: boolean;
    restaurant: Restaurant;
}

const APPROXIMATE_PRODUCTION_RELEASE_DATE = new Date('2023-12-18T14:00:00');

@Component({
    selector: 'app-general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AttributesComponent,
        DescriptionComponent,
        DetectedInconsistenciesComponent,
        HoursComponent,
        InfosRestaurantComponent,
        InformationsGaugeComponent,
        InformationSuggestionsComponent,
        InformationsUpdatesStateComponent,
        SkeletonComponent,
    ],
})
export class GeneralInformationComponent implements OnInit {
    private readonly _informationsContext = inject(InformationsContext);
    private readonly _platformsService = inject(PlatformsService);

    private readonly _restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    readonly restaurant = toSignal(this._restaurant$);

    readonly restaurantHasAccessToGmb = computed((): boolean => !!this.restaurant()?.access.some((a) => a.platformKey === PlatformKey.GMB));

    private readonly _platforms$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms).pipe(
        map((platforms) => {
            if (platforms?.length) {
                return platforms.map((platform) => new Platform(platform));
            }
            return platforms;
        })
    );
    private readonly _suggestions$ = this._store.select(selectCurrentSuggestion);

    readonly UpdateState = UpdateState;

    readonly updateState = signal<UpdateState | null>(null);
    readonly isLoading = signal(true);
    readonly restaurantInformationUpdateStatesIsLoading = this._informationsContext.restaurantInformationUpdateStatesIsLoading;
    readonly totalDifferencesCount = signal<number>(0);
    readonly showDifferencesButton = signal(false);
    private readonly _platformsComparisonsWithStatus = signal<PlatformComparisonWithStatus[]>([]);
    private readonly _isSuggestionModalOpen = signal(false);
    private readonly _informationTranslate = this._translateService.instant('information');
    private readonly _isInformationsDirty$: Observable<boolean | undefined> = this._store.select(fromStore.selectCurrentInformationIsDirty);

    private readonly _destroyRef = inject(DestroyRef);

    readonly suggestionsCount = toSignal(this._suggestions$.pipe(map((suggestion) => suggestion?.comparisons?.length ?? 0)), {
        initialValue: 0,
    });

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _spinnerService: SpinnerService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _translateService: TranslateService,
        private readonly _suggestionService: SuggestionsService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _toastService: ToastService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _notificationCenterContext: NotificationCenterContext,
        private readonly _heapEmailEventsTrackerService: HeapEmailEventsTrackerService
    ) {}

    ngOnInit(): void {
        this._informationsContext.refreshRestaurantInformationUpdateStates();
        this._heapEmailEventsTrackerService.trackEmailEvent();
        this._activatedRoute.queryParamMap.subscribe((queryParams) => {
            const utmSource = queryParams.get('utm_source');
            const type = queryParams.get('type');
            const innerType = queryParams.get('inner_type');
            if (utmSource === 'email' && type === NotificationType.SPECIAL_HOUR) {
                this._notificationCenterContext.trackNotification({
                    heapEventName: HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_EMAIL_BUTTON_CLICKED,
                    notificationId: queryParams.get('nid') ?? '',
                    properties: {
                        notificationType: NotificationType.SPECIAL_HOUR,
                    },
                });
            }
            if (utmSource === 'email' && type === NotificationType.SUMMARY && innerType === NotificationType.INFORMATION_UPDATE_ERROR) {
                this._notificationCenterContext.trackNotification({
                    heapEventName: HeapEventName.INFORMATION_UPDATE_ERROR_REDIRECT,
                    notificationId: queryParams.get('nid') ?? '',
                    properties: {
                        notificationType: NotificationType.INFORMATION_UPDATE_ERROR,
                        channel: NotificationChannel.EMAIL,
                    },
                });
            }
        });

        this._store
            .select(selectCurrentPlatforms)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((platforms) => {
                const referencePlatformKey = this._restaurantsService.currentRestaurant.isBrandBusiness()
                    ? PlatformKey.FACEBOOK
                    : PlatformKey.GMB;
                const referencePlatform = platforms.find((p) => p.key === referencePlatformKey);
                this._store.dispatch({
                    type: InformationsActions.editInformation.type,
                    information: {
                        platformPropertiesToUpdate: referencePlatform?.platformPropertiesToUpdate,
                    },
                });
            });

        this._restaurant$
            .pipe(
                filter(isNotNil),
                tap((restaurant) => {
                    this.showDifferencesButton.set(
                        !!restaurant.createdAt && new Date(restaurant.createdAt) > APPROXIMATE_PRODUCTION_RELEASE_DATE
                    );
                }),
                switchMap((restaurant) => forkJoin([of(restaurant), this._initializeUpdateState$(restaurant)])),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([restaurant, data]) => {
                const restaurantData = data.mergedInformationUpdateResponseDto.find((e) => e.restaurantId === restaurant._id);
                this._setUpdateState(restaurantData?.mergedInformationUpdatesByPlatform, data.hasRestaurantEverBeenUpdated);
            });

        combineLatest([this._restaurant$, this._platforms$])
            .pipe(
                filter(([restaurant, platforms]) => !!(restaurant && platforms.length)),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([restaurant, platforms]: [Restaurant, Platform[]]) => {
                    this._platformsComparisonsWithStatus.set(
                        this._buildPlatformsComparisonWithStatus(PlatformDefinitions.getNonPrivatePlatforms(), platforms, restaurant)
                    );
                    this.totalDifferencesCount.set(this._getTotalDiff());
                    this._store.dispatch({
                        type: InformationsActions.editInformation.type,
                        information: {
                            restaurantId: restaurant._id,
                        },
                    });
                    this._store.dispatch({
                        type: InformationsActions.selectInformation.type,
                        restaurantId: restaurant._id,
                    });
                    if (this._route.snapshot.queryParams.openSpecialHours) {
                        const date = this._route.snapshot.queryParams.date ? new Date(this._route.snapshot.queryParams.date) : null;
                        this._openSpecialHoursModal(date);
                    }
                    if (this._route.snapshot.queryParams.shouldOpenGeneralInformations === 'true') {
                        this._openInfoDialog(restaurant);
                    }
                    setTimeout(() => {
                        // needed to prevent conflict with route navigation from side navigation
                        this._router.navigate(['./'], { relativeTo: this._route });
                    }, 0);
                    this.isLoading.set(false);
                },
                error: (error) => {
                    console.error(error);
                    this.isLoading.set(false);
                },
            });

        this._restaurant$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant) => (this._canOpenPopin(restaurant) ? this._getListOfDisconnectedPlatforms$(restaurant) : of([]))),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((disconnectedPlatforms) => {
                if (disconnectedPlatforms.length > 0) {
                    this._showDisconnectedPlatformsPopin(disconnectedPlatforms);
                }
            });
    }

    updateRestaurantInformation(data: InformationUpdateData): void {
        this._updateRestaurant(data);
    }

    updateRestaurantDescription(data: DescriptionUpdateData): void {
        this._updateRestaurant(data as Partial<Restaurant>);
    }

    updateRestaurantHours(data: HoursUpdateData): void {
        this._updateRestaurant(data);
    }

    updateRestaurantAttributes(data: AttributesUpdateData): void {
        this._updateRestaurant(data);
    }

    openSuggestions({ restaurant, suggestion }: { restaurant: Restaurant; suggestion: Suggestion }): void {
        if (!this._isSuggestionModalOpen()) {
            this._isSuggestionModalOpen.set(true);
            this._customDialogService
                .open(InformationSuggestionModalComponent, {
                    width: '850px',
                    minHeight: '30vh',
                    height: 'auto',
                    disableClose: true,
                    data: {
                        suggestion,
                        restaurant,
                    },
                })
                .afterClosed()
                .pipe(
                    tap(() => {
                        this._isSuggestionModalOpen.set(false);
                    }),
                    filter(Boolean)
                )
                .subscribe({
                    next: (data: Record<string, any>) => this._handleSuggestionUpdated(data),
                    error: () => this._openSuggestionErrorModal(),
                });
            return;
        }
        const currentRestaurant = this.restaurant();
        if (currentRestaurant) {
            this._suggestionService.emitShouldFetchSuggestions(currentRestaurant, true);
        }
    }

    openPlatformsComparisons(): void {
        this._customDialogService
            .open(PlatformsComparisonsModalComponent, {
                height: undefined,
                width: '1000px',
                data: {
                    comparedPlatforms: this._platformsComparisonsWithStatus().filter((platform) =>
                        PlatformDefinitions.shouldCompareInformation(platform.key)
                    ),
                    totalDifferencesCount: this.totalDifferencesCount(),
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result?.updated) {
                        this._toastService.openSuccessToast(
                            this._translateService.instant('information.update_modal.locked_fields_updated')
                        );
                        const restaurant = this.restaurant();
                        if (restaurant) {
                            this._store.dispatch({
                                type: PlatformActions.loadPlatformsData.type,
                                restaurantId: restaurant._id,
                            });
                        }
                    }
                },
            });
    }

    onPrepareDescriptionsDuplication(descriptionSize: string): void {
        const titleKey = 'information.description.duplicate_descriptions';
        const customUpdateFn = (restaurant: Restaurant): Partial<Restaurant> => {
            const descriptionsToKeep = restaurant.descriptions.filter((description) => description.size !== descriptionSize);
            const descriptionsToDuplicate =
                this.restaurant()?.descriptions.filter((description) => description.size === descriptionSize) ?? [];

            return {
                descriptions: [...descriptionsToKeep, ...descriptionsToDuplicate],
            };
        };

        this._prepareDuplication({ titleKey, customUpdateFn });
    }

    onPrepareAttributesDuplication(): void {
        const titleKey = 'information.attributes.duplicate_attributes';
        const customUpdateFn = (_restaurant: Restaurant): Partial<Restaurant> => ({ attributeList: this.restaurant()?.attributeList });
        this._prepareDuplication({ titleKey, customUpdateFn });
    }

    onPrepareHoursDuplication(selectedTab: HoursModalTabs): void {
        const titleKey = this._getTitleHoursDuplicationModal(selectedTab);
        const restaurantKeyToDuplicate = this._getRestaurantHoursKeyToDuplicate(selectedTab);
        const toastSuccessMessage = this._getToastSuccessMessageForHoursDuplication(selectedTab);
        const customUpdateFn = (_restaurant: Restaurant): Partial<Restaurant> => ({
            [restaurantKeyToDuplicate]:
                restaurantKeyToDuplicate === 'specialHours'
                    ? this.restaurant()?.specialHours.filter((specialTimePeriod) => specialTimePeriod.isFutureDate())
                    : this.restaurant()?.[restaurantKeyToDuplicate],
        });
        this._prepareDuplication({
            titleKey,
            customUpdateFn,
            toastSuccessMessage,
            withoutBrandBusiness: true,
        });
    }

    private _updateRestaurant(data: Partial<Restaurant>, afterPublishValidationFn?: () => void): void {
        forkJoin([of(data), this._restaurant$.pipe(take(1))])
            .pipe(
                filter(([update, restaurant]) => !!update && !!restaurant),
                tap(() => this._spinnerService.show()),
                switchMap(([update, restaurant]: [Partial<Restaurant>, Restaurant]) =>
                    forkJoin([of(update), this._saveInformationUpdatesData(restaurant, update), of(restaurant)])
                ),
                switchMap(([update, _, restaurant]) => {
                    this._store.dispatch({
                        type: InformationsActions.editInformation.type,
                        information: {
                            platformPropertiesToUpdate: Object.keys(update),
                            isFooterVisible: true,
                            updateLoadingState: InformationsUpdateStatus.NOT_STARTED,
                        },
                    });
                    afterPublishValidationFn?.();
                    return this._restaurantsService.update(restaurant._id, update);
                }),
                switchMap((res) => {
                    this._restaurantsService.setSelectedRestaurant(res.data);
                    return combineLatest([this._isInformationsDirty$, this._restaurant$]).pipe(take(1));
                }),
                finalize(() => {
                    this._spinnerService.hide();
                })
            )
            .subscribe({
                next: () => {
                    this._spinnerService.hide();
                    this._toastService.openSuccessToast(this._translateService.instant('informations.update.sent'));
                },
                error: (error) => {
                    console.warn(error);
                    this._spinnerService.hide();
                    if (error?.status === 403 && !!error.error?.casl) {
                        return combineLatest([this._isInformationsDirty$, this._restaurant$]).pipe(take(1));
                    } else {
                        this._toastService.openErrorToast(this._clarifyUpdateError(error));
                    }
                    return combineLatest([this._isInformationsDirty$, this._restaurant$]).pipe(take(1));
                },
            });
    }

    private _clarifyUpdateError(error: any): string {
        if (error?.error?.status === 400) {
            return this._informationTranslate.detected_error + error?.error?.message;
        } else if (error?.error?.message?.match(/not_found/)) {
            return this._informationTranslate.user_not_authorized;
        } else if (
            error?.error?.message?.includes('not valid for this location') ||
            error?.error?.message?.includes('Invalid attribute_id provided')
        ) {
            return this._informationTranslate.user_not_authorized;
        } else {
            return this._httpErrorPipe.transform(error);
        }
    }

    private _openInfoDialog(restaurant: Restaurant): void {
        this._customDialogService
            .open<InfosRestaurantModalComponent, any, InformationUpdateData>(InfosRestaurantModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                data: { restaurant },
            })
            .afterClosed()
            .subscribe((data) => {
                if (data && Object.keys(data).length > 0) {
                    this._updateRestaurant(data);
                }
            });
    }

    private _openSpecialHoursModal(date: Date | null = null): void {
        this._customDialogService
            .open(HoursModalComponent, {
                panelClass: ['malou-dialog-panel'],
                height: undefined,
                width: '750px',
                data: {
                    restaurant: this.restaurant(),
                    prefilledStartDate: date,
                    selectedTab: HoursModalTabs.SPECIAL_HOURS,
                },
            })
            .afterClosed()
            .subscribe((data: Partial<Restaurant>) => {
                this._updateRestaurant(data);
            });
    }

    private _saveInformationUpdatesData(restaurant: Restaurant, update: Partial<Restaurant>): Observable<void> {
        const body: SaveInformationUpdateDataBodyDto = {
            restaurantId: restaurant._id,
            data: getPreviousDataBodyDtoFromRestaurant(update),
            previousData: getPreviousDataBodyDtoFromRestaurant(restaurant),
        };

        return this._informationUpdatesService.saveInformationUpdateData(body);
    }

    private _getRestaurantHoursKeyToDuplicate(selectedTab: HoursModalTabs): 'regularHours' | 'specialHours' | 'otherHours' {
        switch (selectedTab) {
            case HoursModalTabs.REGULAR_HOURS:
                return 'regularHours';
            case HoursModalTabs.SPECIAL_HOURS:
                return 'specialHours';
            default:
                return 'otherHours';
        }
    }

    private _getTitleHoursDuplicationModal(selectedTab: HoursModalTabs): string {
        switch (selectedTab) {
            case HoursModalTabs.REGULAR_HOURS:
                return 'information.hours.duplicate_regular_hours';
            case HoursModalTabs.SPECIAL_HOURS:
                return 'information.hours.duplicate_special_hours';
            default:
                return 'information.hours.duplicate_other_hours';
        }
    }

    private _getToastSuccessMessageForHoursDuplication(selectedTab: HoursModalTabs): string {
        switch (selectedTab) {
            case HoursModalTabs.REGULAR_HOURS:
                return this._translateService.instant('information.hours.duplicate_regular_hours_success');
            case HoursModalTabs.SPECIAL_HOURS:
                return this._translateService.instant('information.hours.duplicate_special_hours_success');
            default:
                return this._translateService.instant('information.hours.duplicate_other_hours_success');
        }
    }

    private _prepareDuplication({
        titleKey,
        customUpdateFn,
        withoutBrandBusiness = false,
        toastSuccessMessage,
    }: {
        titleKey: string;
        customUpdateFn: (restaurant: Restaurant) => Partial<Restaurant>;
        withoutBrandBusiness?: boolean;
        toastSuccessMessage?: string;
    }): void {
        const steps = this._getStepsForDuplication(customUpdateFn);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness,
            selectedRestaurants: [],
        };

        this._customDialogService.open(StepperModalComponent, {
            data: {
                steps,
                initialData,
                title: this._translateService.instant(titleKey),
                onSuccess: (results: DuplicationResponse[]) => {
                    this._onDuplicationSuccess({
                        results,
                        toastSuccessMessage: toastSuccessMessage || this._translateService.instant('information.duplication_success'),
                    });
                },
                onError: () => {
                    this._onDuplicationError();
                },
            },
        });
    }

    private _onDuplicationSuccess({ results, toastSuccessMessage }: { results: DuplicationResponse[]; toastSuccessMessage: string }): void {
        const restaurantsInError = results.filter((e) => !e.success);
        const restaurantNamesInError = restaurantsInError.map((e) => e.restaurant.name);
        if (restaurantNamesInError.length) {
            if (restaurantNamesInError.length === results.length) {
                this._toastService.openErrorToast(this._translateService.instant('information.duplication_error'));
            } else {
                this._toastService.openWarnToast(
                    this._translateService.instant('information.duplication_partial_error', {
                        restaurantNames: restaurantNamesInError.join(', '),
                    })
                );
            }
        } else {
            this._toastService.openSuccessToast(toastSuccessMessage);
        }
        this._store.dispatch(RestaurantsActions.loadRestaurants());
        this._customDialogService.closeAll();
    }

    private _onDuplicationError(): void {
        this._toastService.openErrorToast(this._translateService.instant('information.duplication_error'));
        this._customDialogService.closeAll();
    }

    private _getStepsForDuplication(customUpdateFn: (restaurant: Restaurant) => Partial<Restaurant>): Step[] {
        return [
            {
                component: RestaurantsSelectionComponent,
                subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                primaryButtonText: this._translateService.instant('common.duplicate'),
                nextFunction$: (data: RestaurantsSelectionData): Observable<DuplicationResponse[]> =>
                    this._dialogService
                        .open<Observable<DuplicationResponse[]> | null>({
                            title: this._translateService.instant('information.duplication_warning_dialog_title'),
                            message: this._translateService.instant('information.duplication_warning_dialog_message'),
                            variant: DialogVariant.INFO,
                            primaryButton: {
                                label: this._translateService.instant('common.ok'),
                                action: () => this._duplicateToRestaurants$(data.selectedRestaurants ?? [], customUpdateFn),
                            },
                            secondaryButton: {
                                label: this._translateService.instant('common.back'),
                                action: () => null,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            filter(Boolean),
                            switchMap((res) => res)
                        ),
            },
        ];
    }

    private _duplicateToRestaurants$(
        restaurants: Restaurant[],
        customUpdateFn: (restaurant: Restaurant) => Partial<Restaurant>
    ): Observable<DuplicationResponse[]> {
        const currentRestaurant = this.restaurant();
        if (!currentRestaurant) {
            return of([]);
        }
        const duplicatedFromRestaurantId = currentRestaurant._id;
        const observables = restaurants.map((restaurant) => {
            const update = customUpdateFn(restaurant);
            const publishRes$: Observable<void> = this._saveInformationUpdatesData(restaurant, update);
            return publishRes$.pipe(
                switchMap(() =>
                    this._restaurantsService.update(restaurant._id, update, duplicatedFromRestaurantId).pipe(
                        map((e) => ({
                            success: true,
                            restaurant: e.data,
                        }))
                    )
                ),
                catchError((err) => {
                    console.error(err);
                    return of({ success: false, restaurant });
                })
            );
        });
        return forkJoin(observables);
    }

    private _getTotalDiff(): number {
        let total = 0;
        this._platformsComparisonsWithStatus().forEach((plat) => {
            total += plat.getNbDiff();
        });
        return total;
    }

    private _initializeUpdateState$(restaurant: Restaurant): Observable<{
        mergedInformationUpdateResponseDto: GetMergedInformationUpdateResponseDto;
        hasRestaurantEverBeenUpdated: boolean;
    }> {
        const platformKeysToFetch = restaurant.access
            .filter((a) => a.active)
            .filter((a) => a.accessType !== PlatformAccessType.AUTO)
            .map((a) => a.platformKey);

        const body = [
            {
                restaurantId: restaurant._id,
                platformKeys: platformKeysToFetch,
            },
        ];

        return forkJoin({
            mergedInformationUpdateResponseDto: this._informationUpdatesService
                .getMergedInformationUpdateData(body)
                .pipe(map((res) => res.data)),
            hasRestaurantEverBeenUpdated: this._informationUpdatesService
                .hasRestaurantEverBeenUpdated({ restaurantId: restaurant._id })
                .pipe(map((res) => res.data)),
        });
    }

    private _setUpdateState(
        mergedInformationUpdateDto: MergedInformationUpdateDto[] | undefined,
        hasRestaurantEverBeenUpdated: boolean
    ): void {
        if (!hasRestaurantEverBeenUpdated) {
            this.updateState.set(UpdateState.DIFFERENT);
            return;
        }

        if (!mergedInformationUpdateDto || mergedInformationUpdateDto.length === 0) {
            this.updateState.set(UpdateState.UPDATED);
            return;
        }

        const atLeastOnePlatformIsInError = mergedInformationUpdateDto.some((p) =>
            [
                InformationUpdatePlatformStateStatus.ERROR,
                InformationUpdatePlatformStateStatus.MANUAL_UPDATE_ERROR,
                InformationUpdatePlatformStateStatus.BAD_ACCESS,
                InformationUpdatePlatformStateStatus.UNCLAIMED_PAGE,
                InformationUpdatePlatformStateStatus.INVALID_PAGE,
            ].includes(p.platformState.status)
        );
        if (atLeastOnePlatformIsInError) {
            this.updateState.set(UpdateState.ERROR);
            return;
        }

        const atLeastOnePlatformIsPending = mergedInformationUpdateDto.some(
            (p) => p.platformState.status === InformationUpdatePlatformStateStatus.PENDING
        );
        if (atLeastOnePlatformIsPending) {
            this.updateState.set(UpdateState.WAITING);
            return;
        }

        const updatesDoneAtWithDelay = mergedInformationUpdateDto
            .map((e) => {
                const platformDelayInMilliseconds = platformsUpdateConfig.find((p) => p.key === e.platformState.key)?.delayInMilliseconds;
                if (!platformDelayInMilliseconds || !e.platformState.updateDoneAt) {
                    return null;
                }
                return DateTime.fromJSDate(e.platformState.updateDoneAt).plus({ milliseconds: platformDelayInMilliseconds }).toJSDate();
            })
            .filter(isNotNil);

        if (updatesDoneAtWithDelay.length > 0 && updatesDoneAtWithDelay.some((e) => e > new Date())) {
            this.updateState.set(UpdateState.WAITING);
            return;
        }

        this.updateState.set(UpdateState.UPDATED);
    }

    private _handleSuggestionUpdated(data: Record<string, any>): void {
        this._isSuggestionModalOpen.set(false);
        this._updateRestaurant(data);
        const restaurant = this.restaurant();
        if (restaurant) {
            this._suggestionService.emitShouldFetchSuggestions(restaurant, true);
        }
    }

    private _openSuggestionErrorModal(): void {
        this._isSuggestionModalOpen.set(false);
        this._toastService.openErrorToast(this._informationTranslate.suggestions.unknown_error);
        const restaurant = this.restaurant();
        if (restaurant) {
            this._suggestionService.emitShouldFetchSuggestions(restaurant, true);
        }
    }

    private _buildPlatformsComparisonWithStatus(
        platformList: PlatformDefinition[],
        restaurantPlatforms: Platform[],
        restaurant: Restaurant
    ): PlatformComparisonWithStatus[] {
        return platformList
            .filter((pl) => PlatformDefinitions.shouldCompareInformation(pl.key as PlatformKey))
            .map((p) => {
                const specificComparisonKeysForPlatforms: Partial<Record<PlatformKey, ComparisonKey[]>> = {
                    [PlatformKey.UBEREATS]: [ComparisonKey.NAME, ComparisonKey.SPECIAL_HOURS],
                };
                const restaurantPlatform = restaurantPlatforms.find((rp) => rp.key === p.key) || null;
                return new PlatformComparisonWithStatus(restaurant, restaurantPlatform, p, specificComparisonKeysForPlatforms[p.key]);
            });
    }

    private _canOpenPopin(restaurant: Restaurant): boolean {
        try {
            const disconnectedPlatformsPopinLastOpenedAt = this._getDisconnectedPlatformsPopinLastOpenedAtFromLocalStorage();
            const lastOpenedAt = disconnectedPlatformsPopinLastOpenedAt[restaurant._id];
            return !lastOpenedAt || new Date().getTime() - new Date(lastOpenedAt).getTime() > 1 * TimeInMilliseconds.DAY;
        } catch {
            return true;
        }
    }

    private _getListOfDisconnectedPlatforms$(restaurant: Restaurant): Observable<Platform[]> {
        return this._platformsService.getDisconnectedPlatformsForRestaurant(restaurant._id).pipe(map((res) => res.data));
    }

    private _showDisconnectedPlatformsPopin(platforms: Platform[]): void {
        const restaurant = this.restaurant();
        if (!restaurant) {
            return;
        }

        this._customDialogService
            .open<DisconnectedPlatformsModalComponent, DisconnectedPlatformsModalProps, DisconnectedPlatformsModalResult>(
                DisconnectedPlatformsModalComponent,
                {
                    width: '600px',
                    height: 'auto',
                    data: {
                        platforms,
                        restaurantId: restaurant._id,
                    },
                }
            )
            .afterClosed()
            .subscribe((res) => {
                if (!res?.ignoreUpdateLocalStorage) {
                    this._updateDisconnectedPlatformsPopinLastOpenedAtInLocalStorage(restaurant);
                }
            });
    }

    private _getDisconnectedPlatformsPopinLastOpenedAtFromLocalStorage(): Record<string, string> {
        const disconnectedPlatformsPopinLastOpenedAtString =
            LocalStorage.getItem(LocalStorageKey.DISCONNECTED_PLATFORMS_POPIN_LAST_OPENED_AT) ?? '{}';
        return JSON.parse(disconnectedPlatformsPopinLastOpenedAtString);
    }

    private _updateDisconnectedPlatformsPopinLastOpenedAtInLocalStorage(restaurant: Restaurant): void {
        const now = new Date();
        try {
            const disconnectedPlatformsPopinLastOpenedAt = this._getDisconnectedPlatformsPopinLastOpenedAtFromLocalStorage();
            disconnectedPlatformsPopinLastOpenedAt[restaurant._id] = now.toISOString();
            LocalStorage.setItem(
                LocalStorageKey.DISCONNECTED_PLATFORMS_POPIN_LAST_OPENED_AT,
                JSON.stringify(disconnectedPlatformsPopinLastOpenedAt)
            );
        } catch (error) {
            console.error('Error updating disconnected platforms popin last opened at in local storage', error);
        }
    }
}
