import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy, omit, uniq } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, skip, startWith, switchMap, take, tap } from 'rxjs/operators';

import { HeapEventName, NotificationType, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { selectOpenedFooterCount } from ':core/components/restaurant/footer-manager/store/footer-manager.reducer';
import { ExperimentationService } from ':core/services/experimentation.service';
import { KeywordsService } from ':core/services/keywords.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantAiSettingsService } from ':core/services/restaurant-ai-settings.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { selectCurrentKeywords } from ':modules/keywords/store/keywords.selectors';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { ReviewsHeaderComponent } from ':modules/reviews/reviews-header/reviews-header.component';
import { ReviewsContext } from ':modules/reviews/reviews.context';
import * as ReviewsSelectors from ':modules/reviews/store/reviews.selectors';
import { NoConnectedPlatformsComponent } from ':shared/components-v3/no-connected-platforms/no-connected-platforms.component';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { ReviewPreviewComponent } from ':shared/components/review-preview/review-preview.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { LowerUpperPagination } from ':shared/interfaces/lower-upper-pagination.interface';
import { Keyword, MalouPeriod, Pagination, Restaurant, Review, ReviewsFilters } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';
import { RestaurantAiSettings } from ':shared/models/restaurant-ai-settings';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';
import { HeapEmailEventsTrackerService } from ':shared/services/heap-email-events-tracker.service';

import { AnswerReviewContainerComponent } from '../answer-review-container/answer-review-container.component';
import { AnswerReviewModalComponent } from '../answer-review-modal/answer-review-modal.component';
import { ReviewsService } from '../reviews.service';
import * as ReviewsActions from '../store/reviews.actions';
import { SynchronizationStatus } from '../store/reviews.interface';
import {
    selectArchivesReviewsFilters,
    selectCouldNotGetPageNumberFromReviewIdError,
    selectHasLoadedAllReviews,
    selectReviews,
    selectReviewsFilters,
    selectReviewsPagination,
    selectReviewsSynchronizationStatus,
    selectTextReviewsFilters,
} from '../store/reviews.selectors';
import { DownloadReviewsSelectMenuComponent } from './download-select-menu/download-reviews-select-menu.component';
import { ReviewStrategyType } from './reviews.strategy';

const MINIMUM_CHARACTERS_FOR_SEARCH = 3;
const DEFAULT_PAGINATION: LowerUpperPagination = { lower: 0, upper: 0 };
const DEFAULT_STORE_PAGINATION: Pagination = { pageSize: 20, pageNumber: 0, total: 0 };
const AGGREGATED_REVIEWS_RESTAURANTS_SELECTION_LIMIT = 100;

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        AnswerReviewContainerComponent,
        DownloadReviewsSelectMenuComponent,
        NoConnectedPlatformsComponent,
        ReviewPreviewComponent,
        ReviewsHeaderComponent,
        ScrollToTopComponent,
        SkeletonComponent,
        InfiniteScrollModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
        IllustrationPathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsComponent implements OnInit, OnDestroy {
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly text$: Observable<string> = this._store.select(selectTextReviewsFilters);
    readonly hasTextSearch = signal(false);
    readonly filters$: Observable<ReviewsFilters> = this._store.select(selectReviewsFilters).pipe(
        map((reviewFilter) => omit(reviewFilter, 'text')),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
    readonly filters = toSignal<ReviewsFilters | undefined>(this.filters$, { initialValue: undefined });
    readonly atLeastOneFilterIsActive = computed((): boolean => {
        const filters = this.filters();
        return (
            !!filters &&
            (!filters.answered ||
                !filters.notAnswered ||
                !filters.pending ||
                !filters.showPrivate ||
                !filters.withText ||
                !filters.withoutText ||
                !filters.archived ||
                !filters.unarchived ||
                filters.period !== MalouPeriod.ALL ||
                (filters.platforms?.length || 0) < Object.values(PlatformKey).length)
        );
    });

    readonly reviews = signal<(Review | PrivateReview)[]>([]);

    readonly restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    readonly hasLoadedAllReviews$ = this._store.select(selectHasLoadedAllReviews);
    readonly hasConnectedPlatforms$: Observable<boolean>;
    readonly isSyncReviewsFinished = signal(true);
    readonly reviewIdToOpen$: BehaviorSubject<string | null> = new BehaviorSubject(null);

    readonly isLoading = signal(true);
    readonly scrollTopTrigger$: Subject<void> = new Subject();
    readonly footersVisibilityCount = signal(0);

    readonly isAggregatedView = signal(false);

    readonly firstFetchInProgress = signal(false);

    readonly selectedRestaurants$ = this._reviewsContext.selectedRestaurants$;
    readonly restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);

    readonly restaurantsKeywords = signal<{ restaurantId: string; keywords$: Observable<Keyword[]> }[]>([]);
    readonly triggerDisplayedAndStoreReviewsSync$: Subject<void> = new Subject<void>();
    readonly answerReviewsContainerData = signal<
        | {
              review: Review | PrivateReview;
              pagination: LowerUpperPagination;
              isAggregatedView: boolean;
              fromNotificationId: string | null;
          }
        | undefined
    >(undefined);
    readonly simplerCombinedReviewsExperimentationEnabled = toSignal(
        this.experimentationService.isFeatureEnabled$('experiment-simpler-combined-reviews'),
        { initialValue: false }
    );

    readonly restaurantAiSettingsList = signal<RestaurantAiSettings[]>([]);
    readonly restaurantAiSettings = computed(
        () =>
            (restaurantId?: string): RestaurantAiSettings | undefined =>
                this.restaurantAiSettingsList().find((restaurantAiSettings) => restaurantAiSettings.restaurantId === restaurantId)
    );

    private _hasReplyModalOpened = false;
    private _shouldResetDatesFilters = false;
    private _pagination = { ...DEFAULT_PAGINATION };
    private _shouldSetPaginationOnNextReviewChange = false;
    private _selectedRestaurants: Restaurant[];
    private _isArchivedToggled: boolean | undefined;
    private readonly _hasTryToGetPageAndFetchReviews$ = new BehaviorSubject(false);
    readonly fromNotificationId = signal<string | null>(null);

    private readonly _destroyRef = inject(DestroyRef);
    private readonly _restaurantAiSettingsService = inject(RestaurantAiSettingsService);

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _platformsService: PlatformsService,
        private readonly _keywordsService: KeywordsService,
        private readonly _reviewsService: ReviewsService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _reviewsContext: ReviewsContext,
        private readonly _notificationCenterContext: NotificationCenterContext,
        private readonly _activatedRoute: ActivatedRoute,
        public readonly screenSizeService: ScreenSizeService,
        public readonly experimentationService: ExperimentationService,
        private readonly _heapEmailEventsTrackerService: HeapEmailEventsTrackerService
    ) {
        this.isAggregatedView.set(this._router.url.includes('groups'));
        this.hasConnectedPlatforms$ = this._computeHasConnectedPlatforms();
    }

    ngOnInit(): void {
        this._trackReviewReplyNotificationButtonClick();
        this._heapEmailEventsTrackerService.trackEmailEvent();
        this._initFilterRestaurantsSelection();
        this._initFilterReviews();
        this._initScrollTopTrigger();
        this._fetchRestaurantsPlatformsAndSetSelectablePlatforms();

        this._store.select(selectArchivesReviewsFilters).subscribe((archivedFilters) => {
            this._isArchivedToggled = archivedFilters.archived;
        });
        this.restaurant$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._resetReviewsForNewRestaurant();
            if (!this.isAggregatedView()) {
                this.isLoading.set(true);
            }
        });

        this._route.queryParamMap.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((queryParams) => {
            const reviewId = queryParams.get('reviewId');
            if (!this.fromNotificationId()) {
                this.fromNotificationId.set(queryParams.get('nid'));
            }

            if (reviewId && !this._hasReplyModalOpened) {
                this.reviewIdToOpen$.next(reviewId);
            }
            this._shouldResetDatesFilters = queryParams.get('resetDatesFilters') === 'true';
        });

        combineLatest([
            this._store.select(selectReviews),
            this.reviewIdToOpen$,
            this.selectedRestaurants$.pipe(filter((restaurants) => !!restaurants.length)),
            this.triggerDisplayedAndStoreReviewsSync$.pipe(startWith('')),
            this.experimentationService.isLoaded$.pipe(filter((isLoaded) => !!isLoaded)),
        ])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(([reviews, reviewIdToOpen, selectedRestaurants]) => {
                this._selectedRestaurants = selectedRestaurants;
                const restaurantIds = selectedRestaurants.map((r) => r._id);
                this._updateRestaurantsKeywords(restaurantIds);
                if (restaurantIds.length > 0) {
                    this._updateRestaurantAiSettings(restaurantIds);
                }

                if (!this._hasReplyModalOpened || this.isAggregatedView()) {
                    // if reply modal is opened, we don't want to update reviews for performance reasons
                    this.reviews.set([...reviews]);
                }
                if (!reviewIdToOpen || reviews.length > 0) {
                    this.firstFetchInProgress.set(false); // first fetch is done if selectReviews triggered combineLatest
                }
                if (this._shouldSetPaginationOnNextReviewChange) {
                    this._store
                        .select(selectReviewsPagination)
                        .pipe(take(1))
                        .subscribe((pagination) => {
                            this._pagination = {
                                lower: Math.max(pagination.pageNumber - 1, 0),
                                upper: pagination.pageNumber + 1,
                            };
                        });
                    this._shouldSetPaginationOnNextReviewChange = false;
                }

                if (reviewIdToOpen) {
                    const review = reviews.find((r) => r._id === reviewIdToOpen);
                    if (review && !this._hasReplyModalOpened) {
                        this._openReplyModal(review);
                    } else if (!review) {
                        this._getPageAndFetchReviews(reviewIdToOpen, ReviewStrategyType.REPLACE);
                        this._shouldSetPaginationOnNextReviewChange = true;
                    }
                } else {
                    this.answerReviewsContainerData.set({
                        review: reviews[0],
                        pagination: this._pagination,
                        isAggregatedView: true,
                        fromNotificationId: this.fromNotificationId(),
                    });
                }

                this.isLoading.set(false);
            });

        this._store
            .select(selectOpenedFooterCount)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((footersVisibilityCount) => this.footersVisibilityCount.set(footersVisibilityCount));
        this._store
            .select(selectReviewsSynchronizationStatus)
            .pipe(skip(1), takeUntilDestroyed(this._destroyRef))
            .subscribe((synchronizationStatus) => {
                this.isSyncReviewsFinished.set(synchronizationStatus !== SynchronizationStatus.LOADING);
                if (this.isSyncReviewsFinished()) {
                    this._resetReviewsForNewRestaurant();
                }
            });

        this.text$
            .pipe(
                skip(1),
                tap((text) => this.onSearchChange(text)),
                map((text) => text.length >= MINIMUM_CHARACTERS_FOR_SEARCH),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((hasTextSearch) => this.hasTextSearch.set(hasTextSearch));

        this.firstFetchInProgress.set(true);
        this._synchronize({ forceSynchronize: false });
        this._store
            .select(selectCouldNotGetPageNumberFromReviewIdError)
            .pipe(
                skip(1),
                switchMap((error) => {
                    // because the page number depends on review filters, we can get an error MalouErrorCode.REVIEW_NOT_IN_RESULTS even though the review exists
                    // so we try to get the review by id to display it
                    if (error?.reviewId) {
                        return forkJoin({
                            error: of(error),
                            review: this._reviewsService.getReviewById(error.reviewId).pipe(catchError(() => of(null))),
                        });
                    }
                    return forkJoin({
                        error: of(error),
                        review: of(null),
                    });
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(({ error, review }) => {
                if (review) {
                    // if the review exists we dispatch it to the store
                    // this will trigger the combineLatest above and display the review
                    this._store.dispatch({
                        type: ReviewsActions.setReviews.type,
                        reviews: [review],
                        strategyType: ReviewStrategyType.CONCAT_BEFORE,
                    });
                } else if (error?.error === null) {
                    // (A) Early return to not make navigation if the error is null (means that it's a store reset, by default error is null)
                    // cf resetReviewsStateExceptRestaurants in ngOnDestroy

                    // (B) It also provokes a bug in the sidenav if we try to navigate here programmatically and by clicking the sidenav
                    // Ex: we click the sidenav to statistics, so the sidenav trigger a navigation,
                    // but we also trigger navigation here by calling navigate() below, cf (A)
                    // Then the routerLinkActive in the sidenav will not work correctly
                    return;
                } else {
                    this.reviewIdToOpen$.next(null);
                    this._router.navigate([], {
                        queryParams: {
                            reviewId: null,
                        },
                        queryParamsHandling: 'merge',
                    });
                    this._toastService.openWarnToast(this._translateService.instant('reviews.toast.review_not_found'));
                }
            });
    }

    ngOnDestroy(): void {
        this._store.dispatch(ReviewsActions.resetReviewsStateExceptRestaurants());
    }

    forceSynchronize(): void {
        this._resetPagination();
        this._synchronize({ forceSynchronize: true });
    }

    onSearchChange(text: string): void {
        if (text.length >= MINIMUM_CHARACTERS_FOR_SEARCH || text.length === 0) {
            this._resetReviewsAndPagination();
        }
    }

    goToPlatforms(): void {
        this._router.navigate(['../../settings/platforms/connection'], { relativeTo: this._route.parent });
    }

    onScrollDown(): void {
        if (this.isLoading()) {
            return;
        }

        this._increasePagination();
        this._fetchReviews(ReviewStrategyType.CONCAT);
    }

    onScrollUp(): void {
        if (this.isLoading() || this._pagination.lower === 0) {
            return;
        }

        this._decreasePagination();
        this._fetchReviews(ReviewStrategyType.CONCAT_BEFORE);
    }

    openReplyModal(review: Review | PrivateReview, _shouldResetDatesFilters: boolean = false): void {
        this._customDialogService
            .open(
                AnswerReviewModalComponent,
                {
                    width: '100%',
                    height: undefined,
                    panelClass: 'malou-dialog-panel--full',
                    autoFocus: false,
                    data: {
                        review,
                        pagination: this._pagination,
                        isAggregatedView: this.isAggregatedView(),
                        fromNotificationId: this.fromNotificationId(),
                    },
                },
                { animateScreenSize: DialogScreenSize.ALL }
            )
            .afterClosed()
            .subscribe((data: undefined | { pagination: LowerUpperPagination }) => {
                this._hasReplyModalOpened = false;
                if (_shouldResetDatesFilters) {
                    this._store.dispatch({ type: ReviewsActions.resetReviewsFiltersDates.type });
                }
                this.triggerDisplayedAndStoreReviewsSync$.next();
                if (data?.pagination) {
                    this._pagination = this._mergePagination(data.pagination, this._pagination);
                }
            });
    }

    getRestaurantFromReview = (review: Review | PrivateReview): Restaurant =>
        this._selectedRestaurants.find((restaurant) => restaurant._id === review.restaurantId)!;

    getRestaurantKeywordsFromReview = (review: Review | PrivateReview): Observable<Keyword[]> => {
        const restaurantKeywordsFound = this.restaurantsKeywords().find(
            (restaurantKeyword) => restaurantKeyword.restaurantId === review.restaurantId
        );
        return restaurantKeywordsFound ? restaurantKeywordsFound.keywords$ : of([]);
    };

    onArchivedReview(index: number): void {
        const review = this.reviews()[index];
        if (!this._isArchivedToggled) {
            this.reviews().splice(index, 1);
        }
        const reviewObj = { ...review, archived: !review.archived };
        const isPrivate = review.isPrivate();
        const newReview = isPrivate ? new PrivateReview(<PrivateReview>reviewObj) : new Review(<Review>reviewObj);
        this._reviewsService.updateReviewArchivedValue(review._id, !review.archived, isPrivate).subscribe({
            next: () => {
                this._toggleReviewArchived(newReview);
                if (!this._isArchivedToggled) {
                    this._toastService.openSuccessToast(this._translateService.instant('reviews.toast.archived'));
                } else {
                    this._toastService.openSuccessToast(this._translateService.instant('reviews.toast.unarchived'));
                }
            },
            error: (err) => {
                if (!this._isArchivedToggled) {
                    this.reviews().splice(index, 0, review);
                }
                this._toastService.openErrorToast(err.message);
            },
        });
    }

    private _openReplyModal(review: Review | PrivateReview): void {
        this._hasReplyModalOpened = true;
        if (!this.isAggregatedView() || !this.simplerCombinedReviewsExperimentationEnabled()) {
            this.openReplyModal(review, this._shouldResetDatesFilters);
        }
        this.reviewIdToOpen$.next(null);
        this.answerReviewsContainerData.set({
            review,
            pagination: this._pagination,
            isAggregatedView: true,
            fromNotificationId: this.fromNotificationId(),
        });
    }

    private _initScrollTopTrigger(): void {
        this.scrollTopTrigger$.next();
    }

    private _toggleReviewArchived(review: Review | PrivateReview): void {
        this._store.dispatch({
            type: ReviewsActions.toggleArchived.type,
            review,
        });
    }

    private _resetReviewsForNewRestaurant(): void {
        this._cancelSynchronization();
    }

    private _resetReviewsAndPagination(): void {
        this._resetPagination();
        this._fetchReviews(ReviewStrategyType.REPLACE);
        this._store.dispatch(ReviewsActions.fetchEstimatedReviewCount());
    }

    private _synchronize({ forceSynchronize = false }): void {
        this._store.dispatch(ReviewsActions.synchronizeReviews({ forceSynchronize }));
    }

    private _cancelSynchronization(): void {
        this._store.dispatch(ReviewsActions.cancelCurrentReviewsSynchronization());
    }

    private _fetchReviews(strategyType: ReviewStrategyType): void {
        this._store.dispatch(ReviewsActions.fetchReviews({ strategyType }));
    }

    private _getPageAndFetchReviews(reviewId: string, strategyType: ReviewStrategyType): void {
        this._store.dispatch(ReviewsActions.getPageAndFetchReviews({ reviewId, strategyType }));
    }

    private _resetPagination(): void {
        this._store.dispatch(ReviewsActions.resetPagination());
        this._pagination = { ...DEFAULT_PAGINATION };
        this.scrollTopTrigger$.next();
    }

    private _increasePagination(): void {
        this._pagination.upper += 1;
        this._setPagination(this._pagination.upper);
    }

    private _decreasePagination(): void {
        this._pagination.lower -= 1;
        this._setPagination(this._pagination.lower);
    }

    private _setPagination(pageNumber: number): void {
        this._store.dispatch(
            ReviewsActions.setPagination({
                pagination: {
                    ...DEFAULT_STORE_PAGINATION,
                    pageNumber,
                },
            })
        );
    }

    private _mergePagination(pagination: LowerUpperPagination, currentPagination: LowerUpperPagination): LowerUpperPagination {
        return {
            lower: Math.min(pagination.lower, currentPagination.lower),
            upper: Math.max(pagination.upper, currentPagination.upper),
        };
    }

    private _initFilterRestaurantsSelection(): void {
        if (this.isAggregatedView()) {
            this._initFilterRestaurantsSelectionWithSelectedRestaurants();
        } else {
            this._initFilterRestaurantsSelectionWithCurrentRestaurant();
        }
    }

    private _initFilterRestaurantsSelectionWithSelectedRestaurants(): void {
        this.selectedRestaurants$.pipe(take(1)).subscribe((restaurantsSelected: Restaurant[]) => {
            if (restaurantsSelected.length === 0) {
                this.restaurants$
                    .pipe(
                        filter((restaurants) => restaurants.length > 0),
                        take(1)
                    )
                    .subscribe((restaurants: Restaurant[]) => {
                        this._store.dispatch(
                            ReviewsActions.editRestaurants({
                                restaurantIds: restaurants
                                    .filter((restaurant) => !restaurant.isBrandBusiness())
                                    .slice(0, AGGREGATED_REVIEWS_RESTAURANTS_SELECTION_LIMIT)
                                    .map((restaurant) => restaurant._id),
                            })
                        );
                    });
            }
        });
    }

    private _initFilterRestaurantsSelectionWithCurrentRestaurant(): void {
        this.restaurant$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((restaurant: Restaurant) => {
            this._store.dispatch(
                ReviewsActions.editRestaurants({
                    restaurantIds: restaurant ? [restaurant._id] : [],
                })
            );
        });
    }

    private _initFilterReviews(): void {
        this.filters$
            .pipe(
                tap(() => {
                    this._store.dispatch(ReviewsActions.fetchUnansweredReviewCount({}));
                    this._resetReviewsAndPagination();
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(() => {});
    }

    private _computeHasConnectedPlatforms(): Observable<boolean> {
        return this.selectedRestaurants$.pipe(
            switchMap((restaurants) => {
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);
                return this._platformsService.getPlatformsForMultipleRestaurants({ restaurantIds });
            }),
            map((result) => result.data),
            map((platforms) => platforms.length !== 0)
        );
    }

    private _updateRestaurantsKeywords(restaurantIds: string[]): void {
        if (this.isAggregatedView()) {
            const restaurantsKeywordsToRetrieve = restaurantIds.filter(
                (restaurantId) => !this.restaurantsKeywords().some((restaurantKeyword) => restaurantKeyword.restaurantId === restaurantId)
            );

            this._keywordsService.getKeywordsForRestaurants(restaurantsKeywordsToRetrieve).subscribe(({ data: keywords }) => {
                if (keywords.length === 0) {
                    return;
                }
                const groupedByRestaurantId = groupBy(keywords, 'restaurantId');
                this.restaurantsKeywords.set(
                    Object.entries(groupedByRestaurantId).map(([restaurantId, kwds]) => ({
                        restaurantId,
                        keywords$: of(kwds),
                    }))
                );
            });
        } else {
            this.restaurantsKeywords.set([
                { restaurantId: this._selectedRestaurants[0]?._id, keywords$: this._store.select(selectCurrentKeywords) },
            ]);
        }
    }

    private _updateRestaurantAiSettings(restaurantIds: string[]): void {
        this._restaurantAiSettingsService
            .getRestaurantAiSettingsList(restaurantIds)
            .subscribe((restaurantAiSettingsList) => this.restaurantAiSettingsList.set(restaurantAiSettingsList));
    }

    private _fetchRestaurantsPlatformsAndSetSelectablePlatforms(): void {
        this._store
            .select(ReviewsSelectors.selectRestaurantsFilter)
            .pipe(
                distinctUntilChanged(),
                switchMap((restaurantIds) => this._platformsService.getPlatformsForMultipleRestaurants({ restaurantIds })),
                map((result) => result.data),
                map((platforms) => platforms.map((platform) => platform.key)),
                map((platformKeys) => {
                    const platformKeysWithReview = PlatformDefinitions.getPlatformKeysWithReview();
                    const filteredPlatformKeys = platformKeys.filter((platformKey) => platformKeysWithReview.includes(platformKey));
                    return uniq(filteredPlatformKeys);
                })
            )
            .subscribe((platformKeys) => this._setSelectablePlatformKeys(platformKeys));
    }

    private _setSelectablePlatformKeys(platforms: PlatformKey[]): void {
        this._store.dispatch(ReviewsActions.editSelectablePlatforms({ platforms }));
    }

    private _trackReviewReplyNotificationButtonClick(): void {
        this._activatedRoute.queryParamMap.subscribe((queryParams) => {
            const utmSource = queryParams.get('utm_source');
            const type = queryParams.get('type');
            if (utmSource === 'email' && type === NotificationType.REVIEW_REPLY_REMINDER) {
                this._notificationCenterContext.trackNotification({
                    heapEventName: HeapEventName.NOTIFICATION_REVIEW_REMINDER_TRACKING_EMAIL_BUTTON_CLICKED,
                    notificationId: queryParams.get('nid') ?? '',
                    properties: {
                        notificationType: NotificationType.REVIEW_REPLY_REMINDER,
                    },
                });
            }
        });
    }
}
