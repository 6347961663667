export enum CaslAction {
    CREATE = 'create',
    DELETE = 'delete',
    MANAGE = 'manage', // MANAGE = CREATE + READ + UPDATE + DELETE
    READ = 'read',
    UPDATE = 'update',

    // Specific actions
    ANSWER = 'answer',
    PUBLISH = 'publish',
}

export enum CaslSubject {
    ALL = 'all',
    CAMPAIGN = 'Campaign',
    CLIENT = 'Client',
    COMMENT = 'Comment',
    CREDENTIAL = 'Credential',
    FEEDBACK = 'Feedback',
    KEYWORD = 'Keyword',
    LABEL = 'Label',
    MEDIA = 'Media',
    PLATFORM = 'Platform',
    POST = 'Post',
    SOCIAL_POST = 'SocialPost',
    SEO_POST = 'SeoPost',
    RESTAURANT = 'Restaurant',
    RESTAURANT_HASHTAG = 'RestaurantHashtag',
    REVIEW = 'Review',
    SUBSCRIPTION = 'Subscription',
    TEMPLATE = 'Template',
    USER = 'User',
    USER_RESTAURANT = 'UserRestaurant',
}
