import { FeedbackMessageVisibility, Role } from '@malou-io/package-utils';

import { PostWithJob } from ':shared/models';

export const showFeedbackNotification = (post: PostWithJob | null | undefined, userRole: Role | undefined | null): boolean => {
    const feedback = post?.feedback;
    if (!feedback || !feedback.isOpen || !feedback.feedbackMessages?.length || !post.canEditPost()) {
        return false;
    }
    if (userRole === Role.ADMIN) {
        return true;
    }
    if (feedback.feedbackMessages?.some((feedbackMessage) => feedbackMessage.visibility !== FeedbackMessageVisibility.ADMIN)) {
        return true;
    }
    return false;
};
