import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { times } from ':core/constants';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { isPastHour } from ':shared/helpers';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';

@Component({
    selector: 'app-schedule-post-form',
    standalone: true,
    imports: [
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        InputTextComponent,
        InputDatePickerComponent,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSelectModule,
        AsyncPipe,
        FormatTimePipe,
        SelectComponent,
        ApplyPurePipe,
        SlideToggleComponent,
        NgClass,
    ],
    templateUrl: './schedule-post-form.component.html',
    styleUrl: './schedule-post-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulePostFormComponent implements OnInit {
    readonly scheduleForm = input.required<
        FormGroup<{
            status: FormControl<PostDateStatus | null>;
            plannedPublicationDate: FormControl<Date | null>;
            postTime: FormControl<string | null>;
        }>
    >();
    readonly willPostAllAtSameTime = model(false);

    readonly MIN_DATE = new Date();
    readonly TIMES = times;
    readonly SvgIcon = SvgIcon;
    readonly PostDateStatus = Object.values(PostDateStatus);

    readonly _translateService = inject(TranslateService);

    readonly currentLang = signal<ApplicationLanguage>(inject(TranslateService).currentLang as ApplicationLanguage);
    isPastHour = isPastHour;
    statusNowIsSelected$: Observable<boolean>;

    statusDisplayWith = (status: PostDateStatus): string => this._translateService.instant('posts.duplication_modal.status.' + status);

    get plannedPublicationDate(): Date {
        return this.scheduleForm().get('plannedPublicationDate')!.value!;
    }

    changeSelectedTime(event: MatSelectChange): void {
        this.scheduleForm().patchValue({
            postTime: event.value,
        });
    }

    ngOnInit(): void {
        this.statusNowIsSelected$ = this.scheduleForm().valueChanges.pipe(map((formValue) => formValue.status === PostDateStatus.NOW));
    }

    onToggleWillPostAllAtSameTime(): void {
        this.willPostAllAtSameTime.update((value) => !value);
    }
}
