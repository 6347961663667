import { z } from 'zod';

import {
    AspectRatio,
    FeedbackMessageParticipantType,
    FeedbackMessageType,
    FeedbackMessageVisibility,
    MapstrCtaButtonType,
    MediaType,
    PlatformKey,
    PostPublicationStatus,
    PostType,
    Role,
} from '@malou-io/package-utils';

import { objectIdValidator, urlValidator } from '../utils/validators';
import { postHashtagsValidator } from './post-hashtags.dto';

export const postLocationValidator = z.object({
    id: z.string(),
    name: z.string(),
    link: urlValidator(),
    location: z
        .object({
            city: z.string(),
            country: z.string(),
            latitude: z.number(),
            longitude: z.number(),
            street: z.string(),
            zip: z.string(),
        })
        .optional(),
});

export const feedbackParticipantValidator = z.object({
    id: z.string(),
    name: z.string(),
    lastname: z.string().nullish(),
    email: z.string().email(),
    role: z.nativeEnum(Role).nullish(),
    userId: objectIdValidator.nullish(),
});

export const feedbackAuthorValidator = z.object({
    id: z.string(),
    name: z.string(),
    lastname: z.string(),
    email: z.string().email(),
    role: z.nativeEnum(Role),
    userId: objectIdValidator.nullish(),
    profilePictureUrl: z.string().nullish(),
});

export const feedbackMessageValidator = z.object({
    id: z.string(),
    author: feedbackAuthorValidator,
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    text: z.string().optional(),
    type: z.nativeEnum(FeedbackMessageType),
    visibility: z.nativeEnum(FeedbackMessageVisibility),
});

export const postFeedbacksValidator = z.object({
    id: z.string(),
    isOpen: z.boolean(),
    participants: z.array(
        z.object({
            participant: feedbackParticipantValidator,
            types: z.array(z.nativeEnum(FeedbackMessageParticipantType)),
        })
    ),
    feedbackMessages: z.array(feedbackMessageValidator),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
});

const upsertSocialPostMediaValidator = z.object({
    id: objectIdValidator,
    thumbnail1024OutsideUrl: z.string(),
    thumbnail256OutsideUrl: z.string(),
    type: z.nativeEnum(MediaType),
    aspectRatio: z.number(),
    transformData: z.object({
        aspectRatio: z.nativeEnum(AspectRatio),
        rotationInDegrees: z.number(),
        left: z.number(),
        top: z.number(),
        width: z.number(),
        height: z.number(),
    }),
    aiDescription: z.string().nullish(),
});

export const updateSocialPostBodyValidator = z
    .object({
        id: objectIdValidator,
        title: z.string().nullish(),
        text: z.string().nullish(),
        platformKeys: z.array(z.nativeEnum(PlatformKey)),
        published: z.nativeEnum(PostPublicationStatus),
        postType: z.nativeEnum(PostType),
        location: postLocationValidator.nullish(),
        callToAction: z
            .object({
                actionType: z.nativeEnum(MapstrCtaButtonType),
                url: urlValidator({ allowEmpty: true }),
            })
            .nullish(),
        error: z
            .object({
                code: z.string(),
                rawData: z.string(),
            })
            .nullish(),
        socialLink: urlValidator().nullish(),
        socialCreatedAt: z.date().nullish(),
        feedbacks: postFeedbacksValidator.nullish(),
        plannedPublicationDate: z.string().datetime(),
        hashtags: postHashtagsValidator,
        attachments: z.array(upsertSocialPostMediaValidator),
    })
    .transform((data) => ({
        ...data,
        plannedPublicationDate: new Date(data.plannedPublicationDate),
        text: data.text ?? '',
    }));

export type SocialPostDto = z.infer<typeof updateSocialPostBodyValidator>;
