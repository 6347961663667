@if (displayType() === KeywordsScoreGaugeDisplayType.ANSWER_REVIEW) {
    <ng-container [ngTemplateOutlet]="answerReviewDisplay"></ng-container>
} @else {
    <ng-container [ngTemplateOutlet]="shouldDisplayTips || shouldDisplayKeywords ? keywordsWithTips : scoreWithTitle"></ng-container>
}

<ng-template #keywordsWithTips>
    <div class="flex h-full flex-col">
        @if (shouldDisplayTips || shouldDisplayKeywords) {
            <div
                class="!m-0 !flex-auto !p-5"
                data-testid="keywords-score-brick-container"
                [ngClass]="{
                    'malou-card': !shouldDisplayKeywordsInTooltip && !withLargeDetails,
                    'malou-card--medium': !shouldDisplayKeywordsInTooltip && withLargeDetails,
                    'malou-color-background-dark': !shouldDisplayKeywordsInTooltip,
                    '!p-5': !shouldDisplayKeywordsInTooltip,
                }">
                <div
                    class="flex h-full flex-row justify-between gap-x-2 sm:block"
                    [ngClass]="{
                        'flex-row-reverse': reverseTipsAndKeywords,
                    }">
                    @if (shouldDisplayTips) {
                        <div class="md:mb-4">
                            <div class="malou-text-12--bold mb-4">{{ 'keywords_score.tips' | translate }}</div>
                            <app-keywords-score-tips
                                [indicationsList]="indicationsList()"
                                [flexDirection]="shouldDisplayKeywords ? 'flex-col' : 'flex-row'"></app-keywords-score-tips>
                        </div>
                    }
                    @if (shouldDisplayKeywords) {
                        <div class="ml-2 flex max-w-[350px] flex-col">
                            <div class="mb-4 flex items-center justify-between gap-2">
                                <ng-container [ngTemplateOutlet]="scoreWithTitle"></ng-container>
                                @if (!shouldDisplayKeywordsInTooltip) {
                                    <app-select
                                        [testId]="'keywords-score-gauge-bricks-lang-input'"
                                        [formControl]="brickLangControl"
                                        [values]="langOptions()"
                                        [displayWith]="brickLangDisplayFn"
                                        [idPrefix]="'tracking_keywords_score_bricks_lang'"
                                        [getIdSuffixFn]="getIdSuffixFn">
                                    </app-select>
                                }
                            </div>
                            @if (!shouldDisplayKeywordsInTooltip) {
                                <ng-container [ngTemplateOutlet]="allBricksTemplate"></ng-container>
                            }
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #allBricksTemplate>
    @if (allBricks()) {
        <div class="grid grid-cols-[auto_auto] gap-x-2 sm:grid-cols-1" data-testid="keywords-score-grid">
            @for (brick of allBricks(); track trackByTextFn($index, brick)) {
                <div class="mb-2 flex" data-testid="keywords-score-brick" (click)="addBrick(brick)" #isFound>
                    <ng-container [ngTemplateOutlet]="brickTemplate" [ngTemplateOutletContext]="{ brick }"></ng-container>
                </div>
            }
        </div>
    }
</ng-template>

<ng-template let-brick="brick" #brickTemplate>
    <div
        class="flex"
        [ngClass]="{
            'malou-chip': displayType() === KeywordsScoreGaugeDisplayType.ANSWER_REVIEW,
            'malou-chip--primary': displayType() === KeywordsScoreGaugeDisplayType.ANSWER_REVIEW && isBrickChecked()(brick),
            'bg-malou-color-background-white': displayType() === KeywordsScoreGaugeDisplayType.ANSWER_REVIEW && !isBrickChecked()(brick),
        }">
        @if (!isBrickChecked()(brick)) {
            <mat-icon class="relative top-[-2px] mr-5 min-w-[16px] cursor-pointer" color="primary" [svgIcon]="SvgIcon.ADD"></mat-icon>
        }
        @if (isBrickChecked()(brick)) {
            <mat-icon class="malou-color-state-success relative top-[-2px] mr-5 min-w-[16px]" [svgIcon]="SvgIcon.CHECK"></mat-icon>
        }
        <span
            class="malou-text-9--medium !text-malou-color-text-2"
            [matTooltip]="!shouldDisplayKeywordsCategory ? categoryMapping[brick.category] || '' : ''">
            {{ brickTranslated()(brick) }}
            @if (shouldDisplayKeywordsCategory && categoryMapping[brick.category]) {
                <small class="font-thin"> &nbsp;({{ categoryMapping[brick.category] }}) </small>
            }
        </span>
    </div>
</ng-template>

<ng-template #scoreWithTitle>
    @if (shouldDisplayScore) {
        <div style="position: relative" [ngClass]="{ 'reduced-size': shouldOnlyDisplayScore }" #scoregauge>
            <div
                class="flex items-center"
                id="scale-content"
                [matTooltip]="formattedIndicationsList()"
                [matTooltipDisabled]="!shouldShowTipsInTooltip || !formattedIndicationsList()">
                @if (!shouldOnlyDisplayScore) {
                    <div class="mr-5 flex flex-col pt-1">
                        <p class="malou-text-10--medium malou-color-text-2">{{ title }}</p>

                        @if (shouldDisplayKeywordsInTooltip) {
                            <a
                                class="malou-color-primary malou-text-10--semibold pt-0.5"
                                [position]="TooltipPosition.BOTTOM"
                                [appCustomTooltip]="tooltipBricksTemplate">
                                {{ 'keywords.keywords' | translate }}
                            </a>
                        }
                    </div>
                }
                <app-score-gauge [score]="score()"></app-score-gauge>
            </div>
        </div>
    }
</ng-template>

<ng-template #tooltipBricksTemplate>
    <div class="flex flex-col pr-1">
        <span class="malou-text-12--semibold mb-3 mt-1">{{ 'keywords.keywords' | translate }}</span>
        <div class="flex gap-x-2">
            @for (bricksColumn of bricksColumns(); track $index) {
                <div class="flex flex-col gap-y-1">
                    @for (brick of bricksColumn; track brick.text) {
                        <div class="mb-1 flex">
                            @if (!isBrickChecked()(brick)) {
                                <mat-icon
                                    class="relative top-[-2px] mr-5 min-w-[16px]"
                                    color="primary"
                                    [svgIcon]="SvgIcon.CROSS"></mat-icon>
                            }
                            @if (isBrickChecked()(brick)) {
                                <mat-icon
                                    class="malou-color-state-success relative top-[-2px] mr-5 min-w-[16px]"
                                    [svgIcon]="SvgIcon.CHECK"></mat-icon>
                            }
                            <span class="malou-text-9--regular">{{ this.brickTranslated()(brick) }}</span>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #answerReviewDisplay>
    <div class="flex flex-col">
        @if (shouldDisplayKeywords) {
            <div class="malou-color-background-dark mb-0.5 mt-2 flex w-full flex-col rounded-t-[10px] p-[22px] !pt-[10px]">
                <div class="mb-3 flex items-center justify-between">
                    <div class="malou-text-12--bold text-malou-color-text-1">{{ 'keywords_score.relevant_keywords' | translate }}</div>
                    <app-select
                        [testId]="'keywords-score-gauge-bricks-lang-input'"
                        [formControl]="brickLangControl"
                        [values]="langOptions()"
                        [displayWith]="brickLangDisplayFn"
                        [idPrefix]="'tracking_keywords_score_bricks_lang'"
                        [getIdSuffixFn]="getIdSuffixFn">
                    </app-select>
                </div>
                @if (bricksToDisplay()) {
                    <div class="flex flex-wrap gap-x-2">
                        @for (brick of bricksToDisplay(); track trackByTextFn($index, brick)) {
                            <div class="mb-2 flex" data-testid="keywords-score-brick" (click)="addBrick(brick)" #isFound>
                                <ng-container [ngTemplateOutlet]="brickTemplate" [ngTemplateOutletContext]="{ brick }"></ng-container>
                            </div>
                        }
                    </div>
                    @if (nonRelevantBricks().length > 0) {
                        <div class="mt-2 flex cursor-pointer items-center gap-2" (click)="toggleShouldOnlyShowRelevantBricks()">
                            <span class="malou-color-primary malou-text-11--medium">
                                @if (shouldOnlyShowRelevantBricks()) {
                                    {{ 'keywords_score.show_other_bricks' | translate: { count: this.nonRelevantBricks().length } }}
                                } @else {
                                    {{ 'keywords_score.hide_other_bricks' | translate: { count: this.nonRelevantBricks().length } }}
                                }
                            </span>
                        </div>
                    }
                } @else {
                    <div class="malou-text-10--regular mt-2 flex items-center">
                        <span>{{ 'keywords_score.fill_keywords' | translate }}</span>
                    </div>
                }
            </div>
        }
        <div
            class="malou-color-background-dark !m-0 w-full !flex-auto p-[22px]"
            [ngClass]="{
                'rounded-b-[10px]': shouldDisplayKeywords,
                'rounded-[10px]': !shouldDisplayKeywords,
            }">
            <div class="mb-2 flex items-start gap-3">
                <div class="malou-text-12--bold mb-4 mt-1 text-malou-color-text-1">{{ 'keywords_score.tips' | translate }}</div>
                <app-score-gauge [score]="score()"></app-score-gauge>
            </div>
            <app-keywords-score-tips class="flex gap-2" [indicationsList]="indicationsList()"></app-keywords-score-tips>
        </div>
    </div>
</ng-template>
