import { TranslateService } from '@ngx-translate/core';

import { PartialRecord, TemplateTag } from '@malou-io/package-utils';

import { escapeString } from ':shared/helpers/escape-regex';

export enum TemplateVariable {
    BUSINESS_NAME = 'business_name',
    MY_FIRSTNAME = 'my_firstname',
    CLIENT_NAME = 'client_name',
    PHONE = 'phone',
    ADDRESS = 'address',
    MENU_LINK = 'menu_link',
    WEBSITE = 'website',
    REGULAR_HOURS = 'regular_hours',
}
export const AVAILABLE_REVIEW_TEMPLATE_VARIABLES = [
    TemplateVariable.CLIENT_NAME,
    TemplateVariable.MY_FIRSTNAME,
    TemplateVariable.BUSINESS_NAME,
];

export const AVAILABLE_MESSAGE_TEMPLATE_VARIABLES = [
    TemplateVariable.CLIENT_NAME,
    TemplateVariable.ADDRESS,
    TemplateVariable.MENU_LINK,
    TemplateVariable.PHONE,
    TemplateVariable.REGULAR_HOURS,
    TemplateVariable.WEBSITE,
];

export enum TemplateReplacerType {
    CHIP_TEXT = 'chip_text',
    VARIABLE_TEXT = 'variable_text',
    READABLE_TEXT = 'readable_text',
}

export class TemplateReplacer {
    readonly TEMPLATE_REVIEW_TRANSLATE = this._translateService.instant('templates.variables');

    readonly CHIP_TEXT_TO_VARIABLE_REPLACER: Record<string, string> = Object.values(TemplateVariable).reduce((acc, key) => {
        acc[this.TEMPLATE_REVIEW_TRANSLATE[key]] = TemplateTag[key.toUpperCase()];
        return acc;
    }, {});

    readonly VARIABLE_TO_CHIP_TEXT_REPLACER: Record<string, string> = Object.values(TemplateVariable).reduce((acc, key) => {
        acc[TemplateTag[key.toUpperCase()]] = this.TEMPLATE_REVIEW_TRANSLATE[key];
        return acc;
    }, {});

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _availableVariables: TemplateVariable[],
        private _readableReplacer: PartialRecord<TemplateVariable, string> = {}
    ) {}

    getChips(): string[] {
        return this._availableVariables.map((key) => this.TEMPLATE_REVIEW_TRANSLATE[key]);
    }

    setReadableReplacer(replacer: PartialRecord<TemplateVariable, string>): void {
        this._readableReplacer = replacer;
    }

    replaceToReadableText(text: string): string {
        const chipText = this.replaceToChipText(text);
        const realReadableReplacer = Object.entries(this._readableReplacer).reduce((acc, [key, value]) => {
            acc[this.TEMPLATE_REVIEW_TRANSLATE[key]] = value;
            return acc;
        }, {});
        return this._replaceWithReplacer(chipText, realReadableReplacer);
    }

    replaceToChipText(text: string): string {
        return this._replaceWithReplacer(text, this.VARIABLE_TO_CHIP_TEXT_REPLACER);
    }

    replaceToVariableText(text: string): string {
        const readableToChipReplacer = Object.entries(this._readableReplacer).reduce((acc, [key, value]) => {
            acc[value] = this.TEMPLATE_REVIEW_TRANSLATE[key];
            return acc;
        }, {});
        const chipText = this._replaceWithReplacer(text, readableToChipReplacer);
        return this._replaceWithReplacer(chipText, this.CHIP_TEXT_TO_VARIABLE_REPLACER);
    }

    replaceText(text: string, { from, to }: { from: TemplateReplacerType; to: TemplateReplacerType }): string {
        const replacer: Record<string, string> = this._buildReplacer(from, to);
        return this._replaceWithReplacer(text, replacer);
    }

    private _buildReplacer(from: TemplateReplacerType, to: TemplateReplacerType): Record<string, string> {
        return this._availableVariables.reduce((acc, key) => {
            const fromKey = this._getKeyForType(from)(key);
            const toKey = this._getKeyForType(to)(key);
            acc[fromKey] = toKey;
            return acc;
        }, {});
    }

    private _getKeyForType(type: TemplateReplacerType): (key: string) => string {
        switch (type) {
            case TemplateReplacerType.CHIP_TEXT:
                return (key) => this.TEMPLATE_REVIEW_TRANSLATE[key];
            case TemplateReplacerType.VARIABLE_TEXT:
                return (key) => TemplateTag[key.toUpperCase()];
            case TemplateReplacerType.READABLE_TEXT:
                return (key) => this._readableReplacer[key];
        }
    }

    private _replaceWithReplacer(text: string, replacer: Record<string, string>): string {
        const replaceRegex = this._replacerToRegex(replacer);
        return text.replace(replaceRegex, (matched) => replacer[matched.toLowerCase()]);
    }

    private _replacerToRegex(replacer: Record<string, string>): RegExp {
        return RegExp(
            Object.keys(replacer)
                .map((key) => escapeString(key))
                .join('|'),
            'gi'
        );
    }
}
