<div class="flex h-full flex-col justify-between gap-3">
    @if (loading()) {
        <div class="flex items-center justify-center">
            <mat-spinner diameter="20"></mat-spinner>
        </div>
    }
    <ng-container *ngTemplateOutlet="list"></ng-container>
    <ng-container *ngTemplateOutlet="chatBar"></ng-container>
</div>

<ng-template #list>
    <div
        class="flex grow flex-col gap-4 overflow-y-auto"
        [id]="FEEDBACK_MESSAGE_LIST_CONTAINER_ID"
        [ngClass]="{ 'opacity-70': feedbacksAreClosed() }">
        @if (feedbackMessages().length > 0) {
            @for (message of feedbackMessages(); track message.id) {
                <app-feedback-message
                    [(editingMessageId)]="editingMessageId"
                    [message]="message"
                    [currentUser]="currentUser()"
                    [feedbacksAreClosed]="feedbacksAreClosed()"
                    [mentionConfig]="mentionConfig()"
                    (deleteMessage)="deleteMessage($event)"
                    (updateMessage)="updateMessage($event)"
                    (changeMessageVisibility)="changeMessageVisibility($event)"></app-feedback-message>
            }
        } @else {
            <div class="flex h-full flex-col items-center justify-center gap-4 p-4">
                <img class="h-40" alt="" [src]="Illustration.MessagesOk | illustrationPathResolver" />
                <div class="text-center">
                    <div class="malou-text-12--semibold text-malou-color-text-1">{{ 'feedbacks.want_to_give_feedback' | translate }}</div>
                    <div class="malou-text-12--regular text-malou-color-text-2">{{ 'feedbacks.feedback_and_tag' | translate }}</div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #chatBar>
    <div class="flex flex-col gap-2">
        @if (feedbackMessages().length > 0) {
            <div class="flex items-center gap-3">
                <span class="malou-text-10--semibold text-malou-color-text-1">{{ 'feedbacks.set_to_close' | translate }}</span>
                <app-slide-toggle
                    [checked]="feedbacksAreClosed()"
                    [disabled]="feedbacksAreClosed() && isPostPublished()"
                    (onToggle)="toggleFeedbackStatus($event)">
                </app-slide-toggle>
            </div>
        }
        @if (!editingMessageId()) {
            <form class="flex items-center" [formGroup]="messagesForm">
                <div class="min-w-0 grow">
                    <app-text-area
                        formControlName="newMessage"
                        [textAreaId]="'feedback-input'"
                        [rows]="1"
                        [mentionConfiguration]="mentionConfig()"
                        [closeEmojiPickerOnSelect]="true"
                        [disabled]="feedbacksAreClosed()">
                        <div
                            class="flex items-center justify-between p-2"
                            footerTemplate
                            [ngClass]="{ 'cursor-not-allowed opacity-50': feedbacksAreClosed() }">
                            <div>
                                <mat-checkbox
                                    color="primary"
                                    appShowIfAdmin
                                    [checked]="adminVisibility()"
                                    [disabled]="feedbacksAreClosed()"
                                    (change)="onChangeMessageVisibility($event)">
                                    <div class="malou-text-10--regular -mt-[3px] text-malou-color-text-2">
                                        {{ 'feedbacks.admin_visibility_v2' | translate }}
                                    </div>
                                </mat-checkbox>
                            </div>

                            <button
                                class="malou-btn-flat btn-sm"
                                [id]="trackingId()"
                                [disabled]="!newMessage.length || feedbacksAreClosed()"
                                (click)="createMessage()">
                                <span class="malou-text-12--semibold" [ngClass]="{ '!text-malou-color-text-light': !newMessage.length }">
                                    {{ 'common.send' | translate }}
                                </span>
                            </button>
                        </div>
                    </app-text-area>
                </div>
            </form>
        }
    </div>
</ng-template>
