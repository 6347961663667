@if (!isAggregatedView() || !simplerCombinedReviewsExperimentationEnabled()) {
    <div
        class="malou-dialog__header"
        [ngClass]="{
            'border !border-b-0 border-malou-color-border-primary !bg-malou-color-background-light': isReviewRevampFeatureEnabled(),
        }">
        <span class="order-1 whitespace-nowrap">
            {{ 'reviews.reply_modal.respond_to_review' | translate }}
        </span>
        <div class="order-3 flex items-center md:order-2">
            <button class="malou-btn-icon" data-testid="answer-review-modal-close-btn" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>
    </div>
}

@let review = review$ | async;

@if (review) {
    <div
        class="malou-dialog__body"
        [ngClass]="{
            'border !border-t-0 border-malou-color-border-primary !bg-malou-color-background-light pb-3': isReviewRevampFeatureEnabled(),
        }">
        <form class="flex flex-col gap-y-4" [formGroup]="replyReviewForm">
            @if (!isReviewRevampFeatureEnabled()) {
                <app-basic-preview
                    [id]="review._id"
                    [review]="review"
                    [withArchiveButton]="false"
                    [withSeeMoreButton]="false"
                    [isSelected]="true"
                    [isSelectable]="false"
                    [hasBorder]="false"
                    [displayFullText]="true"
                    [disabled]="false"
                    [shouldDisplaySemanticAnalysis]="shouldDisplaySemanticAnalysis()"
                    [isAggregatedView]="isAggregatedView()"
                    [restaurant]="restaurant$ | async"
                    [canShowTranslation]="true"
                    (cancel)="close()"
                    (select)="(null)"></app-basic-preview>
            }
            <ng-container [ngTemplateOutlet]="commentsTemplate" [ngTemplateOutletContext]="{ reviewData: review }"></ng-container>
            <div class="flex flex-col gap-y-4" [class.hidden]="isReplySectionHidden()">
                @if (isReviewRevampFeatureEnabled()) {
                    <ng-container [ngTemplateOutlet]="reviewRevampTemplate" [ngTemplateOutletContext]="{ review }"></ng-container>
                } @else {
                    @if (
                        review.comments.length === 0 ||
                        (review.comments.length && (review | canHaveMultipleReplies)) ||
                        (review.comments.length && (review | canBeEdited))
                    ) {
                        @if (review.comments.length && (review | canHaveMultipleReplies)) {
                            <div class="malou-text-14--bold malou-color-text-1">
                                {{ 'reviews.reply_modal.new_reply' | translate }}
                            </div>
                        } @else {
                            @if (review.comments.length && (review | canBeEdited)) {
                                <div class="malou-text-14--bold malou-color-text-1">
                                    {{ 'reviews.reply_modal.edit_reply' | translate }}
                                </div>
                            }
                        }
                        <ng-container
                            [ngTemplateOutlet]="selectReviewReplyTemplate"
                            [ngTemplateOutletContext]="{ reviewData: review }"></ng-container>
                        @if (review | isPrivate) {
                            <ng-container [ngTemplateOutlet]="privateTemplate"></ng-container>
                        }
                        <ng-container [ngTemplateOutlet]="chips"></ng-container>
                        <div class="flex gap-2 lg:flex-col">
                            <ng-container
                                [ngTemplateOutlet]="replyArea"
                                [ngTemplateOutletContext]="{
                                    reviewData: review,
                                }"></ng-container>
                            <div class="mt-2">
                                <ng-container [ngTemplateOutlet]="keywords"></ng-container>
                            </div>
                        </div>
                        @if (!screenSizeService.isPhoneScreen) {
                            <ng-container [ngTemplateOutlet]="tips"></ng-container>
                        }
                        <ng-container [ngTemplateOutlet]="saveTemplate"></ng-container>
                    }
                }
            </div>
        </form>
    </div>

    <div class="malou-dialog__footer md:justify-between">
        <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
            {{ 'common.cancel' | translate }}
        </button>
        @if (!isReplySectionHidden()) {
            <app-button
                id="tracking_answer_review_modal_reply_button"
                buttonClasses="!h-11 md:grow"
                testId="answer-review-reply-btn"
                [disabled]="
                    doesTextHaveErrors() ||
                    (replyText$ | async) === '' ||
                    (saveTemplateForm.invalid && saveTemplateActivated.value) ||
                    (review.isPrivate() && !isPrivateFormValid())
                "
                [text]="nextButtonText()"
                (click)="replyAndGoNext()"></app-button>
        } @else {
            <button class="malou-btn-raised--primary !h-11 md:grow" mat-raised-button (click)="shouldGoNext()">
                {{ 'reviews.reply_modal.next' | translate }}
            </button>
        }
    </div>
}

<ng-template let-review="review" #reviewRevampTemplate>
    @if (
        review.comments.length === 0 ||
        (review.comments.length && (review | canHaveMultipleReplies)) ||
        (review.comments.length && (review | canBeEdited))
    ) {
        @if (review.comments.length && (review | canHaveMultipleReplies)) {
            <div class="malou-text-14--bold malou-color-text-1">
                {{ 'reviews.reply_modal.new_reply' | translate }}
            </div>
        } @else {
            @if (review.comments.length && (review | canBeEdited)) {
                <div class="malou-text-14--bold malou-color-text-1">
                    {{ 'reviews.reply_modal.edit_reply' | translate }}
                </div>
            }
        }
        <ng-container [ngTemplateOutlet]="selectReviewReplyTemplate" [ngTemplateOutletContext]="{ reviewData: review }"></ng-container>
        @if (review | isPrivate) {
            <ng-container [ngTemplateOutlet]="privateTemplate"></ng-container>
        }
        <div class="flex flex-col gap-3">
            <ng-container
                [ngTemplateOutlet]="replyArea"
                [ngTemplateOutletContext]="{
                    reviewData: review,
                }"></ng-container>
            <div class="relative h-min">
                <!-- Using invisible and not if or display: none to have component already initialized when loader is done -->
                <div [ngClass]="shouldShowKeywordSection() ? '!visible' : '!invisible'">
                    <app-keywords-score-gauge
                        [shouldDisplayKeywords]="true"
                        [shouldDisplayKeywordsCategory]="false"
                        [displayType]="KeywordsScoreGaugeDisplayType.ANSWER_REVIEW"
                        [relevantBricks]="selectedReview().aiRelevantBricks ?? []"
                        [text$]="replyText$"
                        [restaurant$]="restaurant$"
                        [textType$]="gaugeTextType$"
                        [lang$]="lang$"
                        [keywords$]="restaurantKeywords$"
                        [reviewerName$]="reviewerName$"
                        [responseTime$]="responseTime$"
                        [parentElementId$]="reviewId$"
                        [langOptions]="langOptions()"
                        [restaurantAiSettings$]="restaurantAiSettings$"
                        [reviewSpecificData]="keywordScoreReviewSpecificData()"
                        (addKeyword)="addElementToText($event)"
                        #keywordsScoreGauge></app-keywords-score-gauge>
                </div>

                @if (!shouldShowKeywordSection()) {
                    <ng-container [ngTemplateOutlet]="loadingKeywordsTemplate"></ng-container>
                }
            </div>
        </div>
        <ng-container
            [ngTemplateOutlet]="saveTemplate"
            [ngTemplateOutletContext]="{ saveTemplateTheme: SaveReplyAsTemplateTheme.OUTLINED }"></ng-container>
    }
</ng-template>

<ng-template let-reviewData="reviewData" #commentsTemplate>
    @for (comment of review | applySelfPure: 'getComments'; track comment) {
        <div>
            <app-review-reply-preview
                [(isReplySectionHidden)]="isReplySectionHidden"
                [replyText]="comment.text"
                [rating]="reviewData.rating"
                [restaurant$]="restaurant$"
                [lang]="reviewData.lang"
                [keywordsLang]="reviewData.keywordsLang ?? ''"
                [restaurantKeywords$]="restaurantKeywords$"
                [reviewerName$]="reviewerName$"
                [restaurantAiSettings$]="restaurantAiSettings$"
                [commentDate]="comment.socialUpdatedAt ?? null"
                [keywordScoreReviewSpecificData]="keywordScoreReviewSpecificData()"
                [reviewSocialCreatedAt]="reviewData.socialCreatedAt">
            </app-review-reply-preview>
        </div>
    }
</ng-template>

<ng-template #chips>
    <div class="flex flex-wrap gap-1">
        @for (chip of CHIP_LIST; track chip) {
            <div class="malou-chip malou-chip--primary" (click)="addChipToText(chip)">
                <span>{{ chip }}</span>
            </div>
        }
    </div>
</ng-template>

<ng-template #loadingKeywordsTemplate>
    <div class="absolute top-[0] w-full">
        <app-skeleton skeletonClass="!h-[160px] secondary-bg !rounded-b-[0] mb-[2px]">
            <ng-template #nestedSkeletonTemplate>
                <div class="flex h-full flex-col justify-between p-5">
                    <app-skeleton class="!h-[20px] !w-[50%] !rounded-3xl"></app-skeleton>
                    <div class="flex gap-x-1">
                        <app-skeleton class="!h-[35px] !w-[20%]" skeletonClass="!w-full !h-[35px]"></app-skeleton>
                        <app-skeleton class="!h-[35px] !w-[20%]" skeletonClass="!w-full !h-[35px]"></app-skeleton>
                        <app-skeleton class="!h-[35px] !w-[20%]" skeletonClass="!w-full !h-[35px]"></app-skeleton>
                        <app-skeleton class="!h-[35px] !w-[20%]" skeletonClass="!w-full !h-[35px]"></app-skeleton>
                    </div>
                    <app-skeleton class="!h-[20px] !w-[20%] !rounded-3xl"></app-skeleton>
                </div>
            </ng-template>
        </app-skeleton>
        <app-skeleton skeletonClass="!h-[110px] secondary-bg !rounded-t-[0]">
            <ng-template #nestedSkeletonTemplate>
                <div class="flex h-full flex-col justify-between p-5">
                    <app-skeleton class="!h-[20px] !w-[30%] !rounded-3xl"></app-skeleton>
                    <div class="flex gap-x-4">
                        <app-skeleton class="!h-[20px] !w-[20%] !rounded-3xl" skeletonClass="!w-full !h-[20px]"></app-skeleton>
                        <app-skeleton class="!h-[20px] !w-[20%] !rounded-3xl" skeletonClass="!w-full !h-[20px]"></app-skeleton>
                        <app-skeleton class="!h-[20px] !w-[20%] !rounded-3xl" skeletonClass="!w-full !h-[20px]"></app-skeleton>
                        <app-skeleton class="!h-[20px] !w-[20%] !rounded-3xl" skeletonClass="!w-full !h-[20px]"></app-skeleton>
                    </div>
                </div>
            </ng-template>
        </app-skeleton>
    </div>
</ng-template>

<ng-template let-reviewData="reviewData" #replyArea>
    <div class="min-w-0 grow">
        @if (!isReviewRevampFeatureEnabled()) {
            <div class="flex justify-between">
                <div class="malou-text-10--regular malou-color-text-2 flex items-center">
                    {{ 'reviews.reply_modal.message' | translate }}
                </div>
                <div class="flex">
                    <ng-container [ngTemplateOutlet]="replyText.value ? aiModifyTemplate : aiGenerateTemplate"></ng-container>
                    @if (reviewData.comments.length === 0) {
                        <div class="pb-1">
                            <app-interactions-browser
                                previousIconId="tracking_answer_review_modal_previous_interaction_button"
                                nextIconId="tracking_answer_review_modal_next_interaction_button"
                                [interactions]="interactions()"
                                [reset]="resetBrowser()"
                                (interactionChanged)="onInteractionChanged($event)">
                            </app-interactions-browser>
                        </div>
                    }
                </div>
            </div>
        }

        <app-text-area
            [testId]="'answer-review-text-area-input'"
            [textAreaId]="TEXT_AREA_ID"
            [placeholder]="'reviews.reply_modal.placeholder_ai' | translate"
            [title]="isReviewRevampFeatureEnabled() ? ('reviews.reply_modal.answer' | translate) : null"
            [shouldDisplayAiButton]="isReviewRevampFeatureEnabled() ?? false"
            [autoResizeOnContentOverflow]="isReviewRevampFeatureEnabled() ?? false"
            [resizable]="false"
            [formControl]="replyText"
            [isEmojiPickerEnabled]="shouldEnableEmojiPicker | applyPure: reviewData"
            [debounceTime]="200"
            [rows]="isReviewRevampFeatureEnabled() ? 5 : 7"
            [shouldDisplayAiInteractionHeadBand]="shouldDisplayAiInteractionHeadBand()"
            [isLoadingAnimationEnabled]="isTextAreaLoadingAnimationEnabled()"
            (textAreaChange)="onReplyTextChange($event)"
            (hideAiInteractionReply)="onHideAiInteractionReply(reviewData)"
            (input)="onTextAreaInput($event)">
            <ng-template #loadingAnimationTemplate>
                <app-infinite-text-slide [defaultText]="loadingAnimationDefaultText()" [slideTextList]="loadingAnimationSlideTextList()">
                </app-infinite-text-slide>
            </ng-template>
            @if (isReviewRevampFeatureEnabled() && !isTextAreaLoadingAnimationEnabled() && replyText.value.length === 0) {
                <div class="mt-3 h-full" afterTextAreaAiButtonTemplate>
                    <button class="malou-btn-flat !pl-[3px] !pr-0" id="aiBtnInsideTextarea" mat-button (click)="answerReviewWithAi()">
                        <mat-icon class="h-4 !w-4 text-malou-color-chart-purple--accent" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
                        <span class="!malou-text-10--semibold text-malou-color-chart-purple--accent">{{
                            'reviews.reply_modal.generate_with_ai' | translate
                        }}</span>
                    </button>
                </div>
            }
            @if (isUbereatsPromotionOfferFeatureIsEnabled()) {
                <div footerTemplate>
                    <ng-container [ngTemplateOutlet]="sendUbereatsOfferTemplate"></ng-container>
                </div>
            }
            @if (isReviewRevampFeatureEnabled() && (isTextAreaLoadingAnimationEnabled() || replyText.value.length !== 0)) {
                <div class="flex flex-col" footerTemplate>
                    <app-ai-generation-actions
                        optimizeButtonId="tracking_answer_review_optimize_answer_with_ai_button"
                        translateButtonIdPrefix="tracking_answer_review_translate_answer_to_"
                        [trackerId]="'tracking_answer_review_translate_answer_to_custom_language_with_ai_button'"
                        [disabled]="isAiResponseLoading() || aiRateLimitReached()"
                        [interactions]="interactions()"
                        [theme]="AiGenerationActionsDisplayStyle.FILLED"
                        [onReset]="resetBrowser()"
                        (onWandIconClick)="answerReviewWithAi()"
                        (onTranslate)="translateTextWithAi($event)"
                        (onOptimize)="aiOptimize()"
                        (interactionChanged)="onInteractionChanged($event)"></app-ai-generation-actions>
                </div>
            }
        </app-text-area>

        @if (doesTextHaveErrors()) {
            <div class="malou-text-10--medium malou-color-state-error">
                {{ replyText$ | async | replyTextToError }}
            </div>
        }
    </div>
</ng-template>

<ng-template #keywords>
    <app-keywords-score-gauge
        [text$]="replyText$"
        [restaurant$]="restaurant$"
        [textType$]="gaugeTextType$"
        [lang$]="lang$"
        [withLargeDetails]="true"
        [shouldCacheScore]="false"
        [langOptions]="langOptions()"
        [keywords$]="restaurantKeywords$"
        [shouldDisplayKeywords]="true"
        [shouldDisplayKeywordsCategory]="false"
        [shouldDisplayTips]="screenSizeService.isPhoneScreen"
        [reviewerName$]="reviewerName$"
        [responseTime$]="responseTime$"
        [parentElementId$]="reviewId$"
        [restaurantAiSettings$]="restaurantAiSettings$"
        [title]="'reviews.reply_modal.keyword_score_gauge' | translate"
        [reviewSpecificData]="keywordScoreReviewSpecificData()"
        (addKeyword)="addElementToText($event)"
        (indicationListChanged)="keywordsIndicationList.set($event)"
        #keywordsScoreGauge>
    </app-keywords-score-gauge>
</ng-template>

<ng-template #tips>
    <div class="malou-card malou-color-background-dark !m-0 w-full !flex-auto">
        <div class="malou-text-12--bold mb-4">{{ 'keywords_score.tips' | translate }}</div>
        <app-keywords-score-tips [indicationsList]="keywordsIndicationList()"></app-keywords-score-tips>
    </div>
</ng-template>

<ng-template let-saveTemplateTheme="saveTemplateTheme" #saveTemplate>
    <app-save-reply-as-template
        [(shouldDisplayTemplateUpdateWarning)]="shouldDisplayTemplateUpdateWarning"
        [templates]="templates()"
        [theme]="saveTemplateTheme"
        [saveTemplateForm]="saveTemplateForm"
        [availableCommentOptions]="availableCommentOptions"
        (saveTemplateActivatedChange)="onSaveTemplateActivatedChange($event)"
        (templateNameChange)="onTemplateNameChange($event)"
        (ratingsChange)="onRatingsChange($event)"></app-save-reply-as-template>
</ng-template>

<ng-template #aiGenerateTemplate>
    <app-ai-generate-reply
        [aiPromptButtonTooltip]="aiPromptButtonTooltip()"
        [disabled]="isAiResponseLoading() || aiRateLimitReached()"
        (answerReviewWithAi)="answerReviewWithAi()"></app-ai-generate-reply>
</ng-template>

<ng-template #aiModifyTemplate>
    <app-ai-modify-reply
        [aiTranslateLangs]="langOptions()"
        [aiPromptButtonTooltip]="aiPromptButtonTooltip()"
        [disabled]="isAiResponseLoading() || aiRateLimitReached()"
        [displayAiOptimizeButton]="isAiOptimizeButtonDisplayed()"
        (answerReviewWithAi)="answerReviewWithAi()"
        (aiOptimize)="aiOptimize()"
        (translateTextWithAi)="translateTextWithAi($event)"></app-ai-modify-reply>
</ng-template>

<ng-template let-reviewData="reviewData" #selectReviewReplyTemplate>
    <app-select-review-reply-template
        [review]="reviewData"
        [templates]="templates()"
        (templateChange)="onTemplateChange($event)"></app-select-review-reply-template>
</ng-template>

<ng-template #privateTemplate>
    <app-answer-private-review
        [restaurant]="restaurant$ | async"
        [currentUser]="currentUser()"
        [review]="review"
        (onValidChange)="updateIsPrivateFormValid($event)"
        #answerPrivateReview></app-answer-private-review>
</ng-template>

<ng-template #skeletonTemplate>
    <app-skeleton skeletonClass="secondary-bg h-[70vh] w-full p-4">
        <app-skeleton skeletonClass="h-[70px] w-full mt-4" [count]="5"></app-skeleton>
    </app-skeleton>
</ng-template>

<ng-template #sendUbereatsOfferTemplate>
    @let reviewOrderCurrencyCodeValue = reviewOrderCurrencyCode();
    @if (reviewOrderCurrencyCodeValue) {
        <app-send-ubereats-offer
            [reviewerName]="reviewReviewerName()"
            [orderTotal]="reviewOrderTotal()"
            [orderCurrencyCode]="reviewOrderCurrencyCodeValue"
            [ordersCount]="reviewOrdersCount()"
            [restaurantCountry]="(restaurant$ | async)?.address?.country"
            (selected)="onUbereatsOfferSelected($event)" />
    }
</ng-template>
