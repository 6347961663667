import { Action, createReducer, on } from '@ngrx/store';

import { PlatformDefinitions, PostPublicationStatus } from '@malou-io/package-utils';

import { MalouDateFilters, MalouPeriod, PostsFilters } from ':shared/models';
import { ImageEdition } from ':shared/models/image-edition';

import * as StoriesActions from './stories.actions';

export interface StoriesState {
    filters: PostsFilters;
    lastSyncDates?: Record<string, Date | null>;
    imageEditionByStoryId?: Record<string, ImageEdition>;
}

const dateFilters = new MalouDateFilters();

export const initialState: StoriesState = {
    filters: {
        ...dateFilters.getFilter({ period: MalouPeriod.LAST_AND_COMING_SIX_MONTH }),
        sortBy: 'date',
        sortOrder: -1,
        publicationStatus: [PostPublicationStatus.PUBLISHED, PostPublicationStatus.PENDING, PostPublicationStatus.DRAFT],
        platforms: PlatformDefinitions.getPlatformKeysWithStories(),
    },
    lastSyncDates: {},
    imageEditionByStoryId: {},
};

const storiesReducer = createReducer(
    initialState,
    on(
        StoriesActions.editStoriesFilters,
        (state, { filters }) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }) // better way ? https://github.com/paularmstrong/normalizr https://github.com/reduxjs/redux/issues/994
    ),
    on(StoriesActions.changeStoriesSortOrder, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            sortOrder: (state.filters.sortOrder || -1) * -1,
        },
    })),
    on(StoriesActions.resetStoriesFiltersDates, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            ...dateFilters.getFilter({ period: MalouPeriod.LAST_AND_COMING_THIRTY_DAYS }),
        },
    })),
    on(StoriesActions.editLastSyncDate, (state, { restaurantId, lastSyncDate }) => ({
        ...state,
        lastSyncDates: {
            ...state.lastSyncDates,
            [restaurantId]: lastSyncDate,
        },
    })),
    on(StoriesActions.resetStoriesFilters, () => ({
        ...initialState,
    })),
    on(StoriesActions.editImageEdition, (state, { storyId, imageEdition }) => ({
        ...state,
        imageEditionByStoryId: {
            ...state.imageEditionByStoryId,
            [storyId]: imageEdition,
        },
    })),
    on(StoriesActions.deleteImageEdition, (state, { storyId }) => {
        const imageEditions = { ...state.imageEditionByStoryId };
        delete imageEditions[storyId];
        return {
            ...state,
            imageEditionByStoryId: imageEditions,
        };
    }),
    on(StoriesActions.resetImageEditions, (state) => ({
        ...state,
        imageEditionByStoryId: {},
    }))
);

export function reducer(state: StoriesState | undefined, action: Action): StoriesState {
    return storiesReducer(state, action);
}
