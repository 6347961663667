<div
    class="malou-color-bg-white flex h-[50px] items-center rounded-lg {{ class }}"
    [ngClass]="select?.panelOpen ? 'malou-border-secondary' : 'malou-border-primary'">
    <button class="malou-btn-icon !h-8 !w-8" mat-icon-button (click)="onSortOrderChange()">
        <mat-icon class="malou-color-primary" [svgIcon]="isUp ? 'arrow-up' : 'arrow-down'"></mat-icon>
    </button>
    <div class="no-line grow">
        <mat-form-field
            class="custom-malou-filled-form-field--no-border flex h-[50px] w-full flex-col justify-center"
            subscriptSizing="dynamic">
            <mat-select
                panelClass="sort-filter-option-panel"
                [value]="currentOption?.key"
                [disableOptionCentering]="true"
                [disabled]="disabled"
                [hideSingleSelectionIndicator]="true"
                (selectionChange)="onSortByChange($event)"
                #select>
                <mat-select-trigger class="malou-text-13--bold sm:absolute sm:inset-0 sm:top-[-12.5px] sm:h-6 sm:truncate">
                    <span class="malou-text-13--medium malou-color-text-2">{{ 'common.sort' | translate }} : </span>
                    {{ displaySelectedOption | applyPure: currentOption }}
                </mat-select-trigger>
                @for (opt of sortOptions; track trackByKeyFn($index, opt)) {
                    <mat-option class="mb-[1px]" [value]="opt?.key">
                        <span class="malou-color-text-2">{{ opt?.label }}</span>
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</div>
