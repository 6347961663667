import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { ViewBy } from ':shared/enums/view-by.enum';
import { FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, Restaurant } from ':shared/models';
import { ScanForAggregatedInsights } from ':shared/models/scan';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedBoostersStatisticsDataV2 } from '../booster.interface';
import {
    AggregatedBoosterData,
    AggregatedBoostersScanCountChartComponent,
    AggregatedScansData,
} from './aggregated-boosters-scan-count-chart/aggregated-boosters-scan-count-chart.component';

@Component({
    selector: 'app-aggregated-boosters-scan-count-v2',
    templateUrl: './aggregated-boosters-scan-count.component.html',
    styleUrls: ['./aggregated-boosters-scan-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        AggregatedBoostersScanCountChartComponent,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedBoostersScanCountV2Component implements OnInit {
    readonly chartSortBy = input<ChartSortBy>();
    readonly data = input.required<AggregatedBoostersStatisticsDataV2 | null>();
    readonly restaurants = input.required<Restaurant[]>();
    readonly isParentLoading = input<boolean>(true);
    readonly isParentError = input<boolean>(false);

    readonly chartSortByChange = output<ChartSortBy>();
    readonly hasDataChange = output<boolean>();

    private readonly _enumTranslate = inject(EnumTranslatePipe);

    readonly Illustration = Illustration;
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly sortByChanged$ = new BehaviorSubject(false);
    readonly data$ = toObservable(this.data);
    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(ChartSortBy.ALPHABETICAL) as FormControl<ChartSortBy>;
    readonly SORT_BY_VALUES = Object.values(ChartSortBy);

    readonly boosterData = computed(() => this._computeBoosterData(this.data(), this.restaurants()));

    ngOnInit(): void {
        if (this.chartSortBy) {
            this.sortByControl.setValue(this.chartSortBy() ?? ChartSortBy.ALPHABETICAL);
        }
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    onSortByChange(sortByElt: ChartSortBy): void {
        this.sortByControl.setValue(sortByElt);
        this.chartSortByChange.emit(sortByElt);
        this.sortByChanged$.next(true);
    }

    private _computeBoosterData(
        boostersData: AggregatedBoostersStatisticsDataV2 | null,
        restaurants: Restaurant[]
    ): {
        restaurantsToDisplay: Restaurant[];
        chartData: AggregatedBoosterData[];
        wheelOfFortuneCountOnPeriod: number;
        wheelOfFortuneCountDifferenceWithPreviousPeriod: number;
        scanCountOnPeriod: number;
        scanCountDifferenceWithPreviousPeriod: number;
        hasData: boolean;
    } {
        if (!boostersData || !boostersData?.scans.length) {
            this.hasDataChange.emit(false);
            return {
                restaurantsToDisplay: [],
                chartData: [],
                wheelOfFortuneCountOnPeriod: 0,
                wheelOfFortuneCountDifferenceWithPreviousPeriod: 0,
                scanCountOnPeriod: 0,
                scanCountDifferenceWithPreviousPeriod: 0,
                hasData: false,
            };
        }
        const { nfcs, scans, scanEvolution, wheelOfFortuneCount, wofScansEvolution } = boostersData;

        const sortedRestaurants = this._sortRestaurants(this.sortByControl.value, [...restaurants], scans);

        const restaurantIds = sortedRestaurants.map(({ _id }) => _id.toString());
        const wheelOfFortuneScansByRestaurant: (AggregatedScansData | null)[] = Array.from({
            length: restaurantIds.length,
        }).fill(null) as (AggregatedScansData | null)[];

        const scansByNfc = nfcs.map((nfc) => {
            const restaurantIndex = restaurantIds.indexOf(nfc.restaurantId);
            const nfcScans = scans.filter((scan) => scan.nfcId === nfc.id && scan.nfcSnapshot?.restaurantId === nfc.restaurantId);
            if (nfc.id === FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS) {
                wheelOfFortuneScansByRestaurant[restaurantIndex] = nfcScans.length > 0 ? this._getScansByPlatform(nfcScans) : null;
                return null;
            }
            const emptyRestaurantArray: (AggregatedScansData | null)[] = Array.from({ length: restaurantIds.length }).fill(
                null
            ) as (AggregatedScansData | null)[];
            emptyRestaurantArray[restaurantIndex] = nfcScans.length > 0 ? this._getScansByPlatform(nfcScans) : null;
            return {
                name: nfc.name ?? nfc.chipName,
                scans: [...emptyRestaurantArray],
            };
        });
        const wheelOfFortuneName = nfcs.find((nfc) => nfc.id === FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS)?.name ?? '';
        this.hasDataChange.emit(!!scans.length);
        return {
            restaurantsToDisplay: sortedRestaurants,
            chartData: [
                { name: wheelOfFortuneName, scans: wheelOfFortuneScansByRestaurant },
                ...(scansByNfc.filter(Boolean) as AggregatedBoosterData[]),
            ],
            wheelOfFortuneCountOnPeriod: wheelOfFortuneCount,
            wheelOfFortuneCountDifferenceWithPreviousPeriod: wofScansEvolution,
            scanCountOnPeriod: scans.length,
            scanCountDifferenceWithPreviousPeriod: scanEvolution,
            hasData: !!scans.length,
        };
    }

    private _sortRestaurants(sortByElt: ChartSortBy, restaurants: Restaurant[], scans: ScanForAggregatedInsights[]): Restaurant[] {
        if (sortByElt === ChartSortBy.ALPHABETICAL) {
            return restaurants.sort(sortRestaurantsByInternalNameThenName);
        }
        const totalByRestaurants = restaurants.map((restaurant) => ({
            restaurantId: restaurant.id,
            name: restaurant.name,
            total: scans.filter((scan) => scan.nfcSnapshot?.restaurantId === restaurant.id).length,
        }));
        const sortedTotalByRestaurants = orderBy(totalByRestaurants, 'total', sortByElt);
        const sortRestaurantByTotal = sortedTotalByRestaurants.map(
            ({ restaurantId }) => restaurants.find((rest) => rest.id === restaurantId) as Restaurant
        );
        return sortRestaurantByTotal;
    }

    private _getScansByPlatform(nfcScans: ScanForAggregatedInsights[]): AggregatedScansData {
        const nfcScansByPlatformKey = groupBy(nfcScans, 'nfcSnapshot.statsPlatformKey');
        return Object.entries(nfcScansByPlatformKey).reduce(
            (acc, [platformKey, scans]) => ({
                ...acc,
                [platformKey]: scans.length,
            }),
            { total: nfcScans.length }
        );
    }
}
