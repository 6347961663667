import { sum } from 'lodash';

import { DailyPlatformInsights } from '@malou-io/package-dto';
import {
    AggregationTimeScale,
    getMonthsFromPeriod,
    getWeeksFromPeriod,
    MalouMetric,
    Month,
    PlatformKey,
    Week,
} from '@malou-io/package-utils';

import { ChartDataArray, ChartDataElement, formatDateToISO, getDaysFromCurrentRange, mergeArrays } from ':shared/helpers';

export interface CommunityChartData {
    [PlatformKey.FACEBOOK]?: DailyPlatformInsights['insights'];
    [PlatformKey.INSTAGRAM]?: DailyPlatformInsights['insights'];
}

export class CommunityInsightsChartData {
    private _startDate: Date;
    private _endDate: Date;

    facebookFollowers: ChartDataArray = [];
    instagramFollowers: ChartDataArray = [];
    followers: ChartDataArray = [];

    instagramNewFollowers: ChartDataArray = [];
    facebookNewFollowers: ChartDataArray = [];
    totalFollowers: number;

    dates: Date[];
    aggregationTimeScale: AggregationTimeScale;

    constructor({
        data,
        startDate,
        endDate,
        aggregationTimeScale,
    }: {
        data: CommunityChartData;
        startDate: string;
        endDate: string;
        aggregationTimeScale: AggregationTimeScale;
    }) {
        this._startDate = new Date(startDate);
        this._endDate = new Date(endDate);
        this.aggregationTimeScale = aggregationTimeScale;
        this._initInsightsAndDates(data);
        this.followers = mergeArrays(this.facebookFollowers, this.instagramFollowers);
        this.instagramNewFollowers = this._computeNewFollowers(this.instagramFollowers);
        this.facebookNewFollowers = this._computeNewFollowers(this.facebookFollowers);
        this.instagramFollowers = this._enhanceData(this.instagramFollowers);
        this.facebookFollowers = this._enhanceData(this.facebookFollowers);
        this.totalFollowers = sum(this.followers);
    }

    private _initInsightsAndDates(data: CommunityChartData): void {
        switch (this.aggregationTimeScale) {
            case AggregationTimeScale.BY_MONTH:
                const months = getMonthsFromPeriod(this._startDate, this._endDate);
                this.dates = months.map((month) => month.start);
                this._initInsightsAggregatedByMonth(data, months);
                break;

            case AggregationTimeScale.BY_WEEK:
                const weeks = getWeeksFromPeriod(this._startDate, this._endDate);
                this.dates = weeks.map((week) => week.start);
                this._initInsightsAggregatedByWeek(data, weeks);
                break;

            case AggregationTimeScale.BY_DAY:
            default:
                const days = getDaysFromCurrentRange(this._startDate, this._endDate);
                this.dates = days;
                this._initInsightsAggregatedByDay(data, days);
                break;
        }
    }

    private _initInsightsAggregatedByDay(data: CommunityChartData, days: Date[]): void {
        days.forEach((day) => {
            const formattedDay = formatDateToISO(day);
            this.instagramFollowers.push(data![PlatformKey.INSTAGRAM]?.[MalouMetric.FOLLOWERS]?.[formattedDay] ?? 0);
            this.facebookFollowers.push(data![PlatformKey.FACEBOOK]?.[MalouMetric.FOLLOWERS]?.[formattedDay] ?? 0);
        });
    }

    private _initInsightsAggregatedByWeek(data: CommunityChartData, weeks: Week[]): void {
        this._initInsightsAggregatedByPeriod(data, weeks);
    }

    private _initInsightsAggregatedByMonth(data: CommunityChartData, months: Month[]): void {
        this._initInsightsAggregatedByPeriod(data, months);
    }

    private _initInsightsAggregatedByPeriod(data: CommunityChartData, periods: { days: Date[] }[]): void {
        periods.forEach((period) => {
            const days = period.days;
            if (data![PlatformKey.INSTAGRAM]) {
                this.instagramFollowers.push(this._getFollowersInsights(data![PlatformKey.INSTAGRAM], days, MalouMetric.FOLLOWERS));
            }
            if (data![PlatformKey.FACEBOOK]) {
                this.facebookFollowers.push(this._getFollowersInsights(data![PlatformKey.FACEBOOK], days, MalouMetric.FOLLOWERS));
            }
        });
    }

    private _getFollowersInsights(data: DailyPlatformInsights['insights'], days: Date[], metric: MalouMetric): ChartDataElement {
        const daysData = days.map((day) => data![metric]?.[formatDateToISO(day)] ?? 0);
        return daysData.reverse().find((e) => e !== 0) ?? 0;
    }

    private _computeNewFollowers(arr: ChartDataArray): ChartDataArray {
        return arr.slice(0, arr.length - 1).map((e, index) => (arr[index + 1] && e ? (arr[index + 1] || 0) - e || 0 : null));
    }

    private _enhanceData(arr: ChartDataArray): ChartDataArray {
        const result = [...arr];

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 0) {
                const prevValues = arr.slice(Math.max(0, i - 3), i);
                const nextValues = arr.slice(i + 1, i + 4);
                const prevCount = prevValues.filter((value) => value !== 0).length;
                const nextCount = nextValues.filter((value) => value !== 0).length;
                // if we have at least 2 non 0 values before or after the current date, we consider it a gap
                if (prevCount >= 2 || nextCount >= 2) {
                    result[i] = null;
                }
            }
        }
        return result;
    }
}
