<div class="modal-container h-[90vh]">
    <div class="malou-dialog malou-dialog-mobile h-full">
        <div class="malou-dialog__header">
            <div class="title">
                <div class="malou-text-18--bold text-malou-color-text-1">
                    {{ data.dialogTitle }}
                </div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ data.dialogSubtitle }}
                </div>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="cancel()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <div class="mb-3">
                <app-search
                    [debounceTime]="0"
                    [placeholder]="'common.search' | translate"
                    (searchChange)="onSearchChange($event)"></app-search>
            </div>
            <div class="hide-scrollbar flex h-[60vh] flex-col overflow-auto">
                <mat-checkbox
                    class="pl-[27px]"
                    color="primary"
                    [checked]="isOneOrMoreRestaurantSelected() && isAllRestaurantsSelected()"
                    [indeterminate]="isOneOrMoreRestaurantSelected() && !isAllRestaurantsSelected()"
                    (change)="$event ? toggleAllRows() : null">
                    <span class="malou-text-12--bold text-malou-color-text-1">
                        {{ 'duplicate_to_restaurants_dialog.all_businesses' | translate }}
                    </span>
                </mat-checkbox>
                <ng-container
                    [ngTemplateOutlet]="displayOrganizations ? organizationTableTemplate : simpleTableTemplate"
                    [ngTemplateOutletContext]="
                        displayOrganizations ? null : { dataSourceInput: restaurantDataSource, showPagination: true }
                    ">
                </ng-container>
                <app-paginator
                    [shouldBeHidden]="displayOrganizations || restaurantDataSource.filteredData.length < DEFAULT_RESTAURANT_PAGINATION"
                    [pageSize]="DEFAULT_RESTAURANT_PAGINATION"
                    [pageSizeOptions]="[DEFAULT_RESTAURANT_PAGINATION]"
                    #RestaurantPaginator>
                </app-paginator>
                <app-paginator
                    [shouldBeHidden]="!displayOrganizations || organizationDataSource.filteredData.length < DEFAULT_ORGANIZATION_PAGINATION"
                    [pageSize]="DEFAULT_ORGANIZATION_PAGINATION"
                    [pageSizeOptions]="[DEFAULT_ORGANIZATION_PAGINATION]"
                    #OrganizationPaginator>
                </app-paginator>
            </div>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary--alt !h-11 md:grow" mat-raised-button (click)="cancel()">
                {{ data.dialogSecondaryButtonText }}
            </button>
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                mat-raised-button
                [disabled]="!isOneOrMoreRestaurantSelected()"
                (click)="save()">
                {{ data.dialogPrimaryButtonText }}
            </button>
        </div>
    </div>
</div>

<ng-template #organizationTableTemplate>
    <div class="malou-expansion-panel">
        <mat-accordion multi>
            @for (organizationName of paginatedOrganizations; track organizationName) {
                <mat-expansion-panel [expanded]="paginatedOrganizations.length === 1">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="malou-text-12--bold malou-color-text-1">
                            <mat-checkbox
                                class="rounded-[10px] py-1 pl-3 pr-1.5"
                                color="primary"
                                [value]="organizationName"
                                [checked]="
                                    (isOrganisationSelected | applyPure: organizationName) ||
                                    (isAllRestaurantsSelectedInOrganization | applyPure: organizationName)
                                "
                                [indeterminate]="
                                    (isOneOrMoreRestaurantSelectedInOrganization | applyPure: organizationName) &&
                                    !(isOrganisationSelected | applyPure: organizationName) &&
                                    !(isAllRestaurantsSelectedInOrganization | applyPure: organizationName)
                                "
                                (click)="$event.stopPropagation()"
                                (change)="selectOrganisation(organizationName)"></mat-checkbox>
                            <span> {{ organizationName }} ({{ restaurantsGroupedByOrganization[organizationName].length }} ) </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="ml-3">
                        <ng-container
                            [ngTemplateOutlet]="simpleTableTemplate"
                            [ngTemplateOutletContext]="{
                                dataSourceInput: toDataSource | applyPure: restaurantsGroupedByOrganization[organizationName],
                                showPagination: false,
                            }">
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template let-dataSourceInput="dataSourceInput" #simpleTableTemplate>
    <mat-table class="malou-mat-table" matSort matSortDisableClear [dataSource]="dataSourceInput" #table="matTable">
        <ng-container matColumnDef="restaurant">
            <mat-cell *matCellDef="let restaurant; table: table">
                <mat-checkbox
                    class="mb-[^2px] w-full rounded-[10px] px-6.5 py-1"
                    color="primary"
                    [value]="restaurant!._id"
                    [checked]="isRestaurantSelected | applyPure: restaurant"
                    (change)="selectRestaurant(restaurant)">
                    <div class="ml-2 flex w-full items-center gap-x-2">
                        <img
                            class="malou-avatar--medium h-[45px] w-[45px]"
                            [lazyLoad]="
                                (restaurant?.logo | applySelfPure: 'getMediaUrl' : 'small') ?? ('default-picture-grey' | imagePathResolver)
                            " />
                        <div class="ml-2">
                            <div class="flex items-center">
                                <div
                                    class="flex !h-3 !w-3 items-center justify-center rounded-[50%]"
                                    [matTooltip]="
                                        ((restaurant | applySelfPure: 'isBrandBusiness') ? 'common.brand_account' : 'common.business')
                                            | translate
                                    "
                                    [ngClass]="
                                        (restaurant | applySelfPure: 'isBrandBusiness')
                                            ? 'bg-malou-color-state-warn'
                                            : 'bg-malou-color-chart-pink--light'
                                    ">
                                    <mat-icon
                                        class="!h-2 text-white"
                                        [svgIcon]="(restaurant | applySelfPure: 'isBrandBusiness') ? 'heart' : 'localisation'"></mat-icon>
                                </div>
                                <div class="malou-text-12--bold leading-6 text-malou-color-text-1">
                                    {{ restaurant.internalName }}
                                </div>
                            </div>
                            @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
                                <div class="malou-text-12 italic leading-6">
                                    {{ restaurant.address }}
                                </div>
                            }
                        </div>
                    </div>
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <mat-row
            *matRowDef="let restaurant; columns: DISPLAYED_COLUMNS; table: table; let i = index"
            class="row"
            [class.selected]="isRestaurantSelected | applyPure: restaurant"></mat-row>
    </mat-table>
</ng-template>
