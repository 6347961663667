import { DateTime } from 'luxon';

import { ReviewWithTranslationsResponseDto } from '@malou-io/package-dto';
import {
    ApplicationLanguage,
    CurrencyCode,
    ITranslations,
    PlatformDefinitions,
    PlatformKey,
    PostedStatus,
    TimeInMilliseconds,
    TranslationSource,
    UbereatsPromotionValue,
} from '@malou-io/package-utils';

import { SimpleUserInterface } from ':modules/user/user';
import { IReview } from ':shared/interfaces';

import { formatStringDate } from '../helpers';
import { CommentReviewsFilters, KeywordAnalysis } from './';
import { PrivateReview } from './private-review';
import { SemanticAnalysis } from './review-analyses';

export interface ReviewSocialAttachment {
    urls: {
        original: string;
        small?: string;
    };
    type: string;
}

export enum ReviewType {
    RATING = 'rating',
    VISITOR_POST = 'visitor_post',
}

export class Review implements IReview {
    _id: string;
    platformId: string;
    restaurantId: string;
    key: PlatformKey;
    socialId: string;
    socialLink: string;
    businessSocialLink: string;
    socialCreatedAt: Date;
    socialUpdatedAt: Date | null;
    rating: number;
    text?: string;
    socialTranslatedText: string;
    lang: string;
    reviewer?: {
        profilePhotoUrl?: string;
        displayName: string;
        email?: string;
    };
    comments: ReviewReply[] = [];
    type: ReviewType;
    archived: boolean;
    updatedAt: Date;
    createdAt: Date;
    socialAttachments?: ReviewSocialAttachment[];
    wasAnsweredAutomatically?: boolean;

    ratingTags?: string[];
    menuItemReviews?: MenuItemReview[] = [];
    eaterTotalOrders?: number;
    isReplyScheduled?: boolean;
    order?: Order;

    nbDaysLeftToReply?: number | null;
    semanticAnalysis: SemanticAnalysis | null = null;
    keywordsLang?: string;
    aiRelevantBricks?: {
        text: string;
        category: string;
        translationsId?: string;
        translations?: ITranslations;
    }[];
    aiRelatedBricksCount?: number;

    couldNotSendReply = false;

    translations: ITranslations | undefined = undefined;

    public constructor(init?: Partial<Review>) {
        Object.assign(this, init);
        this.socialCreatedAt = new Date(this.socialCreatedAt);
        this.socialUpdatedAt = this.socialUpdatedAt ? new Date(this.socialUpdatedAt) : null;
        this.createdAt = new Date(this.createdAt);
        this.updatedAt = new Date(this.updatedAt);
        if (this.comments) {
            this.comments = this.comments.map((c) => new ReviewReply(c));
        }
        this.nbDaysLeftToReply = this._getNbDaysLeftToReply();
        this.semanticAnalysis = this.semanticAnalysis ? new SemanticAnalysis(this.semanticAnalysis, this.text ?? '') : null;
    }

    static fromReviewWithTranslationsResponseDto(reviewDto: ReviewWithTranslationsResponseDto): Review {
        return new Review({
            ...reviewDto,
            socialCreatedAt: reviewDto.socialCreatedAt ? new Date(reviewDto.socialCreatedAt) : undefined,
            socialUpdatedAt: reviewDto.socialUpdatedAt ? new Date(reviewDto.socialUpdatedAt) : undefined,
            comments: reviewDto.comments.map((comment) => ({
                ...comment,
                socialUpdatedAt: comment.socialUpdatedAt ? new Date(comment.socialUpdatedAt) : undefined,
            })),
            translations: reviewDto.translations,
            semanticAnalysis: SemanticAnalysis.fromReviewAnalysisDto(reviewDto.semanticAnalysis, reviewDto.text),
        });
    }

    static getRatingRange(rating: number | undefined): number[] {
        if (!rating) {
            return [0];
        }
        return Number.isInteger(rating) ? [rating] : [Math.floor(rating), Math.ceil(rating)];
    }

    public getCommentOption(): CommentReviewsFilters | null {
        return this.text !== null || this.ratingTags?.length || this.menuItemReviews?.length
            ? CommentReviewsFilters.WITH_TEXT
            : CommentReviewsFilters.WITHOUT_TEXT;
    }

    public canBeLabelized(): boolean {
        return this.text !== undefined || this.text !== null || this.text !== '';
    }

    public canBeReplied(): boolean {
        switch (this.key) {
            case PlatformKey.UBEREATS: {
                const hasOnlyRejectedComment =
                    this.comments.length === this.comments.filter((comment) => comment.posted === PostedStatus.REJECTED).length;
                const diffDays = DateTime.now().diff(DateTime.fromJSDate(this.socialCreatedAt), 'day').toObject().days;
                return (
                    (hasOnlyRejectedComment || this.comments?.length === 0) && (diffDays ?? 0) < (this.getNbDaysUntilCantBeAnswered() ?? 0)
                );
            }
            case PlatformKey.DELIVEROO: {
                const diffDays = DateTime.now().diff(DateTime.fromJSDate(this.socialCreatedAt), 'day').toObject().days;
                return this.comments?.length === 0 && (diffDays ?? 0) < (this.getNbDaysUntilCantBeAnswered() ?? 0);
            }
            case PlatformKey.FOURSQUARE:
            case PlatformKey.RESY:
                return false;
            case PlatformKey.LAFOURCHETTE:
                return !!this.text?.length;
            default:
                return true;
        }
    }

    public canHaveMultipleReplies(): boolean {
        return PlatformDefinitions.canHaveMultipleReplies(this.key);
    }

    public hasReply(): boolean {
        return this.comments?.length > 0;
    }

    public hasOnlyPendingComment(): boolean {
        return (this.comments ?? []).every((comment) => [PostedStatus.PENDING, PostedStatus.RETRY].includes(comment.posted));
    }

    public hasRejectedComment(): boolean {
        return (this.comments ?? []).some((comment) => [PostedStatus.REJECTED].includes(comment.posted));
    }

    public canBeEdited(): boolean {
        const isUbereats = this.key === PlatformKey.UBEREATS;
        const hasOnlyRejectedComment =
            this.comments.length === this.comments.filter((comment) => comment.posted === PostedStatus.REJECTED).length;
        if (isUbereats && hasOnlyRejectedComment) {
            return true;
        }
        return PlatformDefinitions.canReviewBeModified(this.key);
    }

    public isPrivate(): this is PrivateReview {
        return false;
    }

    public getMenuItemReviews(): MenuItemReview[] {
        return this.menuItemReviews ?? [];
    }

    public getUbereatsPromotionAmountInHundredths(): number | undefined {
        return this.comments[0]?.ubereatsPromotionAmountInHundredths;
    }

    public getEaterTotalOrders(): number | undefined {
        return this.eaterTotalOrders;
    }

    public getOrderTotal(): number | undefined {
        return this.order?.orderTotal;
    }

    public getOrderCurrencyCode(): CurrencyCode | undefined {
        return this.order?.currencyCode;
    }

    public getNbDaysUntilCantBeAnswered(): number | null {
        switch (this.key) {
            case PlatformKey.DELIVEROO:
                return 60;
            case PlatformKey.UBEREATS:
                return 14;
            default:
                return null;
        }
    }

    public getReviewDate(): string {
        if (this.socialUpdatedAt?.getTime()) {
            return formatStringDate(this.socialUpdatedAt);
        }
        return formatStringDate(this.socialCreatedAt);
    }

    public getReviewOrderDate(): string | null {
        return this.order?.deliveredAt ? formatStringDate(this.order.deliveredAt) || null : null;
    }

    public hasAttachments(): boolean {
        return (this.socialAttachments?.length ?? 0) > 0;
    }

    public hasText(): boolean {
        return !!this.text?.length;
    }

    public getDisplayName(): string {
        return this.reviewer?.displayName ?? '';
    }

    public getFirstCommentDate(): Date | undefined {
        const date = this.comments?.find((r) => r.posted === PostedStatus.POSTED)?.socialUpdatedAt;
        return date ? new Date(date) : undefined;
    }

    public hasScanId(): boolean {
        return false;
    }

    public getSocialAttachments(): ReviewSocialAttachment[] {
        return this.socialAttachments ?? [];
    }

    public getWasAnsweredAutomatically(): boolean {
        return this.wasAnsweredAutomatically ?? false;
    }

    public getNbDaysLeftToReply(): number | null {
        return this.nbDaysLeftToReply ?? null;
    }

    public getComments(): ReviewReply[] {
        switch (this.key) {
            case PlatformKey.FACEBOOK:
            case PlatformKey.ZENCHEF:
            case PlatformKey.OPENTABLE:
                return this.comments;
            default:
                return this.comments[this.comments.length - 1] ? [this.comments[this.comments.length - 1]] : [];
        }
    }

    public hasToHideReplySection(): boolean {
        switch (this.key) {
            case PlatformKey.FACEBOOK:
            case PlatformKey.ZENCHEF:
            case PlatformKey.OPENTABLE:
                return false;
            default:
                return this.comments.length > 0;
        }
    }

    public copyWith(review: Partial<Review>): Review {
        return new Review({
            ...this,
            ...review,
        });
    }

    public hasTranslations(lang: string): boolean {
        return !!this.translations?.[lang];
    }

    public addTranslation(text: string, language: ApplicationLanguage, source: TranslationSource): ITranslations {
        return this.translations
            ? { ...this.translations, [language]: text }
            : {
                  id: 'fakeId',
                  [language]: text,
                  language,
                  source,
              };
    }

    public getTranslation(lang: string): string {
        return this.translations?.[lang] ?? this.text;
    }

    private _getNbDaysLeftToReply(): number | null {
        if (!PlatformDefinitions.hasDelayToReplyReview(this.key)) {
            return null;
        }
        const createdAt = this.socialCreatedAt;
        const createdAtStartDate = createdAt.setHours(0, 0, 0, 0);
        const fourteenDaysAfterReception =
            new Date(createdAtStartDate).getTime() + TimeInMilliseconds.DAY * (this.getNbDaysUntilCantBeAnswered() ?? 1);
        const today = new Date().setHours(0, 0, 0, 0);
        const todayDateTime = new Date(today).getTime();
        const diffTime = fourteenDaysAfterReception - todayDateTime;
        if (diffTime < 0) {
            return 0;
        }
        const diffDays = Math.ceil(diffTime / TimeInMilliseconds.DAY);
        return diffDays;
    }
}

export class ReviewReply {
    _id: string;
    socialId: string;
    text: string;
    socialTranslatedText: string;
    keywordAnalysis: KeywordAnalysis;
    socialUpdatedAt?: Date;
    posted: PostedStatus;
    user?: { socialId: string };
    isMalou: boolean;
    author: SimpleUserInterface;
    templateIdUsed: string;
    isRepliedFromAggregatedView?: boolean;
    ubereatsPromotionValue?: UbereatsPromotionValue;
    ubereatsPromotionAmountInHundredths?: number;

    public constructor(init?: Partial<ReviewReply>) {
        Object.assign(this, init);
    }
}

export class ReviewFactory {
    static createTestReview(): Review {
        return new Review({
            _id: 'some review id',
            platformId: 'some platform id',
            restaurantId: 'some restaurant id',
            key: PlatformKey.GMB,
            socialId: 'some social id',
            socialLink: 'some social link',
            socialCreatedAt: new Date(),
            socialUpdatedAt: new Date(),
            rating: 4,
            text: 'some text',
            lang: 'fr',
            reviewer: {
                profilePhotoUrl: 'some url',
                displayName: 'Hugo Jallet',
                email: 'hugo.jallet@gmail.com',
            },
            comments: [],
            type: ReviewType.RATING,
            updatedAt: new Date(),
            createdAt: new Date(),
        });
    }
}

export class MenuItemReview {
    socialId: string;
    rating: boolean;
    name: string;
    comment: string;
    tags: string[];
}

class Order {
    workflowId?: string;
    deliveredAt?: Date;
    orderTotal?: number;
    currencyCode?: CurrencyCode;
    appVariant?: string;
}

export type ReviewWithAnalysis = Pick<
    Review,
    '_id' | 'socialId' | 'text' | 'key' | 'semanticAnalysis' | 'rating' | 'socialCreatedAt' | 'reviewer' | 'restaurantId'
>;
