@if (!isParentError()) {
    @if (!isParentLoading()) {
        <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
            <div>
                <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            </div>
            @if (hasData()) {
                <div>
                    <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                </div>
                <div>
                    <ng-container [ngTemplateOutlet]="cardTemplate"></ng-container>
                </div>
            } @else {
                <div class="flex h-[442px] flex-col items-center justify-center gap-3 py-6">
                    <img class="mb-6 h-40" alt="Search illustration" [src]="Illustration.Search | illustrationPathResolver" />
                    <div class="malou-text-14--bold malou-color-text-1 mb-2">{{ 'statistics.errors.no_result' | translate }}</div>
                    <div class="malou-text-10--regular malou-color-text-2">{{ 'statistics.errors.change_period' | translate }}</div>
                </div>
            }
        </div>
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
} @else {
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
}

<ng-template #titleTemplate>
    <div class="flex items-center gap-x-2">
        <span class="malou-text-section-title malou-color-text-1">{{
            'statistics.totems.private_review_count.private_review_count' | translate
        }}</span>
        <mat-icon
            class="!h-5 !w-5"
            [svgIcon]="SvgIcon.INFO"
            [matTooltip]="'statistics.totems.private_review_count.title_tooltip' | translate"></mat-icon>
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-statistics-totems-private-review-count-chart-v2
        [privateReviewCountData]="computedData().privateReviewCountData"></app-statistics-totems-private-review-count-chart-v2>
</ng-template>

<ng-template #cardTemplate>
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">
                {{ computedData().privateReviewCountOnPeriod | shortNumber }}
            </div>
            @if (computedData().privateReviewCountOnPeriod) {
                <app-number-evolution
                    [value]="computedData().privateReviewCountDifferenceWithPreviousPeriod"
                    [displayedValue]="computedData().privateReviewCountDifferenceWithPreviousPeriod | shortNumber"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
            {{ 'statistics.totems.private_review_count.private_review' | translate }}
        </div>
    </div>
</ng-template>
