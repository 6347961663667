import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, input, output, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';

import { MediaType } from '@malou-io/package-utils';

import { SocialPostMedia } from ':modules/posts-v2/social-posts/models/social-post-media';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export enum SizeStrategy {
    FIT = 'fit',
    FULL = 'full',
}

@Component({
    selector: 'app-social-post-media-item',
    templateUrl: './social-post-media-item.component.html',
    styleUrls: ['./social-post-media-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        MatRippleModule,
        TranslateModule,
        ImagePathResolverPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostMediaItemComponent {
    readonly containerSizeStrategy = input<SizeStrategy>(SizeStrategy.FIT);

    readonly media = input.required<SocialPostMedia | null>();
    readonly customMediaClass = input<string>('');

    readonly icon = input<SvgIcon>();
    readonly customIconClass = input<Record<string, boolean>>({});

    readonly statusIcon = input<SvgIcon>();
    readonly customStatusIconClass = input<Record<string, boolean>>({});
    readonly customStatusIconContainerClass = input<Record<string, boolean>>({});

    readonly tag = input<string | undefined>();
    readonly link = input<string | undefined>();
    readonly tooltipText = input<string>('');

    readonly shouldLazyLoadMedia = input<boolean>(true);
    readonly showVideoControls = input<boolean>(true);

    readonly refreshMedia = output<ErrorEvent>();
    readonly loadedVideoMetadata = output<Event>();
    readonly tagClick = output<void>();

    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    readonly MediaType = MediaType;
    readonly SizeStrategy = SizeStrategy;

    readonly shouldLoadVideo = signal(false);

    readonly statusIconContainerClass = computed(() => ({
        ...this.customStatusIconContainerClass(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'right-7': this.icon(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'right-2': !this.icon(),
    }));

    onLoadedVideoMetadata(event: Event): void {
        this.loadedVideoMetadata.emit(event);
    }

    async playMedia(event: MouseEvent): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        const playIcon = event.target as HTMLElement;

        this.shouldLoadVideo.set(true);
        this._changeDetectorRef.detectChanges(); // Detect the refresh of the component and that Angular added the video element

        // get closest video tag
        const video = playIcon?.parentNode?.parentNode?.children[0] as HTMLVideoElement;

        // is video playing?
        if (!video.paused) {
            playIcon.innerHTML = 'play_arrow';
            video.pause();
            return;
        }

        video.play();
        playIcon.innerHTML = 'pause';
    }

    refresh(event: ErrorEvent): void {
        this.refreshMedia.emit(event);
    }

    refreshIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this.refresh(new ErrorEvent('Could not load some media'));
        }
    }

    openLink(): void {
        const link = this.link();
        if (link) {
            window.open(link, '_blank');
        }
    }

    onTagClick(): void {
        this.tagClick.emit();
    }
}
