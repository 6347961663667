import { InsightsByPlatform, InsightsByPlatformByRestaurant } from ':shared/models';

export class ReviewRatingInsightsByRestaurantId extends Map<string, InsightsByPlatform> {
    constructor(data: Map<string, InsightsByPlatform>) {
        super(data);
    }

    static fromDto(data: InsightsByPlatformByRestaurant): ReviewRatingInsightsByRestaurantId {
        return new ReviewRatingInsightsByRestaurantId(new Map(Object.entries(data)));
    }

    merge(data: ReviewRatingInsightsByRestaurantId): ReviewRatingInsightsByRestaurantId {
        const restaurantIds = [...data.keys()];
        restaurantIds.forEach((restaurantId) => {
            const value = data.get(restaurantId);
            if (value) {
                this.set(restaurantId, value);
            }
        });
        return new ReviewRatingInsightsByRestaurantId(this);
    }

    getPresentRestaurantIds(): string[] {
        return [...this.keys()];
    }

    getChartReviewStats(): InsightsByPlatform[] {
        return [...this.values()];
    }

    deleteMany(restaurantIds: string[]): ReviewRatingInsightsByRestaurantId {
        restaurantIds.forEach((restaurantId) => {
            this.delete(restaurantId);
        });
        return new ReviewRatingInsightsByRestaurantId(this);
    }
}
