import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'reviewText',
    standalone: true,
})
export class ReviewTextPipe implements PipeTransform {
    constructor(private _translate: TranslateService) {}

    transform(review: Review | PrivateReview, options?: { showTranslation: boolean; language: string }): string {
        if (review.key !== PlatformKey.UBEREATS) {
            return options?.showTranslation ? review.getTranslation(options.language) : (review.text ?? '');
        }
        const ratingTags = (<Review>review).ratingTags;
        return ratingTags?.length ? this._translate.instant('reviews.general_html') + ratingTags?.join(' ● ') : (review.text ?? '');
    }
}
