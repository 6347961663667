import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, sum, uniq } from 'lodash';
import { DateTime, DateTimeUnit } from 'luxon';
import { shareReplay } from 'rxjs';

import { getMonthsFromPeriod, NfcType, PlatformKey, WHEEL_OF_FORTUNE_PLATFORM_KEY } from '@malou-io/package-utils';

import { BoostersStatisticsDataV2 } from ':modules/statistics/boosters/boosters.interface';
import { TotemData, TotemsDataByNfcName } from ':modules/statistics/boosters/scan-count-v2/scan-count-chart/scan-count-chart.component';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { getDaysFromCurrentRange, getWeeksFromCurrentRange } from ':shared/helpers';
import { LightNfc } from ':shared/models';
import { ScanForRestaurantInsights } from ':shared/models/scan';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { ScanCountChartV2Component } from './scan-count-chart/scan-count-chart.component';

@Component({
    selector: 'app-statistics-boosters-scan-count-v2',
    templateUrl: './scan-count.component.html',
    styleUrls: ['./scan-count.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        NumberEvolutionComponent,
        SelectComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        ScanCountChartV2Component,
    ],
    providers: [EnumTranslatePipe],
})
export class ScanCountV2Component implements OnInit {
    readonly viewBy = input<ViewBy>();
    readonly hiddenDatasetIndexes = input<number[]>();
    readonly data = input<BoostersStatisticsDataV2 | null>();
    readonly isParentLoading = input<boolean>();
    readonly isParentError = input<boolean>();
    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();

    private readonly _enumTranslate = inject(EnumTranslatePipe);
    private readonly _translateService = inject(TranslateService);

    readonly Illustration = Illustration;
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly wheelOfFortuneDisplayedText = this._translateService.instant('aggregated_statistics.boosters.scans.wheel_of_fortune');

    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly viewByControlValue = toSignal(this.viewByControl.valueChanges.pipe(shareReplay(1)), {
        initialValue: ViewBy.DAY,
    });

    readonly hasData: Signal<boolean> = computed(() => {
        const boosterData = this.data();
        return boosterData ? boosterData.scans.length > 0 || boosterData.wheelOfFortuneCount > 0 : false;
    });

    readonly computedData = computed(() => {
        const boosterData = this.data();
        if (!boosterData) {
            return {
                scanCountOnPeriod: null,
                scanCountDifferenceWithPreviousPeriod: null,
                wheelOfFortuneCountOnPeriod: null,
                wheelOfFortuneCountDifferenceWithPreviousPeriod: null,
            };
        }
        const scanCountOnPeriod = boosterData.scans.length - boosterData.wheelOfFortuneCount;
        this.hasDataChange.emit(scanCountOnPeriod > 0);
        return {
            wheelOfFortuneCountOnPeriod: boosterData.wheelOfFortuneCount,
            wheelOfFortuneCountDifferenceWithPreviousPeriod: boosterData.wheelOfFortuneCount - boosterData.previousWheelOfFortuneCount,
            scanCountOnPeriod,
            scanCountDifferenceWithPreviousPeriod:
                scanCountOnPeriod - (boosterData.previousScans.length - boosterData.previousWheelOfFortuneCount),
        };
    });

    readonly totemsData: Signal<TotemsDataByNfcName> = computed(() => {
        const boosterData = this.data();
        const viewBy = this.viewByControlValue();
        if (!boosterData || !viewBy) {
            return {};
        }
        const { nfcs, scans, startDate, endDate } = boosterData;
        let dateLabels: Date[] = [];
        switch (viewBy) {
            case ViewBy.DAY:
                dateLabels = getDaysFromCurrentRange(startDate, endDate);
                break;
            case ViewBy.WEEK:
                dateLabels = getWeeksFromCurrentRange(startDate, endDate).map((week) => week.start);
                break;
            case ViewBy.MONTH:
                dateLabels = getMonthsFromPeriod(startDate, endDate).map((month) => month.start);
                break;
        }
        this.viewByChange.emit(viewBy);
        const dateTimeUnit = this._viewByToLuxonDateTimeUnit(viewBy);

        return nfcs.reduce((acc, cur) => {
            const nfcScans = scans.filter((scan) => scan.nfcId === cur.id);
            const name = this._getTotemName(cur);
            const scansForNfc = this._getChartDataForScans(nfcScans, dateLabels, dateTimeUnit);
            const fullDisplayedName = this._addTypeToTotemName(name, cur, scansForNfc);
            return {
                ...acc,
                [fullDisplayedName]: scansForNfc,
            };
        }, {});
    });

    ngOnInit(): void {
        const viewBy = this.viewBy();
        if (viewBy) {
            this.viewByControl.setValue(viewBy);
        }
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    private _getChartDataForScans(nfcScans: ScanForRestaurantInsights[], dateLabels: Date[], dateTimeUnit: DateTimeUnit): TotemData {
        return dateLabels.map((date) => {
            const dateScans = nfcScans.filter((scan) =>
                DateTime.fromJSDate(date).hasSame(DateTime.fromJSDate(new Date(scan.scannedAt)), dateTimeUnit)
            );
            const platformKeysForDate = compact(uniq(dateScans.map((scan) => scan.nfcSnapshot?.statsPlatformKey)));
            const scanCountByPlatformKey = platformKeysForDate.reduce(
                (acc, cur) => {
                    acc[cur] = dateScans.filter((scan) => scan.nfcSnapshot?.statsPlatformKey === cur).length;
                    return acc;
                },
                {} as Record<PlatformKey, number>
            );
            return {
                ...scanCountByPlatformKey,
                total: dateScans.length,
                date: date,
            };
        });
    }

    private _isWheelOfFortuneTotem(totem: LightNfc): boolean {
        return totem.name === this.wheelOfFortuneDisplayedText;
    }

    private _getTotemName(totem: LightNfc): string {
        const stickerLabel = this._enumTranslate.transform(NfcType.STICKER, 'nfc_type');
        if (!totem.isTotem()) {
            return stickerLabel;
        }
        const name = totem.name ?? totem.chipName;
        if (name && !this._isWheelOfFortuneTotem(totem)) {
            return `${this._translateService.instant('statistics.totems.totem')} ${totem.name ?? totem.chipName}`;
        } else if (!name) {
            return stickerLabel;
        }
        return name;
    }

    private _addTypeToTotemName(name: string, cur: LightNfc, scansForNfc: TotemData): string {
        const total = sum(scansForNfc.map((scan) => scan.total ?? 0));
        if (total > 0 && !this._isWheelOfFortuneTotem(cur)) {
            const scansWithValue = scansForNfc.filter((scan) => !!scan.total);
            const type = Object.keys(scansWithValue[scansWithValue.length - 1]).includes(WHEEL_OF_FORTUNE_PLATFORM_KEY)
                ? this._translateService.instant('statistics.totems.wheel_of_fortune')
                : this._translateService.instant('statistics.totems.direct');
            return `${name} (${type})`;
        }
        return name;
    }

    private _viewByToLuxonDateTimeUnit(viewBy: ViewBy): DateTimeUnit {
        switch (viewBy) {
            case ViewBy.DAY:
                return 'day';
            case ViewBy.WEEK:
                return 'week';
            case ViewBy.MONTH:
                return 'month';
        }
    }
}
