import { Component, input, model } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';

@Component({
    selector: 'app-keep-same-post-caption-toggle',
    standalone: true,
    imports: [TranslateModule, SlideToggleComponent],
    templateUrl: './keep-same-post-caption-toggle.component.html',
    styleUrl: './keep-same-post-caption-toggle.component.scss',
})
export class KeepSamePostCaptionToggleComponent {
    readonly shouldKeepSameCaptionForAllPosts = model.required<boolean>();
    readonly disabled = input.required<boolean>();

    onToggleChange(): void {
        this.shouldKeepSameCaptionForAllPosts.update((value) => !value);
    }
}
