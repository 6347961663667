import * as Sentry from '@sentry/angular';

import { environment } from './environments/environment';
import * as developmentEnvironment from './environments/environment.development';
import * as productionEnvironment from './environments/environment.prod';
import * as stagingEnvironment from './environments/environment.staging';

// angular material gestures

// angular material gestures

const errorsToFilter = [
    /ChunkLoadError/,
    /{"headers":{"normalizedNames/,
    /<unknown>/,
    /Non-Error exception captured with keys/,
    /Http failure response for/,
    /Non-Error promise rejection/,
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const shouldFilterError = (message: string) => {
    const matches = errorsToFilter.filter((pattern) => RegExp(pattern).test(message));
    return matches.length !== 0;
};

const host = {
    development: developmentEnvironment.environment.APP_MALOU_API_URL,
    staging: stagingEnvironment.environment.APP_MALOU_API_URL,
    prod: productionEnvironment.environment.APP_MALOU_API_URL,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const initSentry = () => {
    if (environment.environment === 'production') {
        Sentry.init({
            release: 'malouapp-v3@latest',
            denyUrls: [/localhost/],
            dsn: 'https://a99c0618253340088a00f7836b92671b@o785966.ingest.sentry.io/4504751295430656',
            maxBreadcrumbs: 30,
            debug: false,
            tracePropagationTargets: ['xhr', 'fetch', host[environment.environment], /^\/api\//],
            integrations: [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance, including custom Angular routing instrumentation
                Sentry.browserTracingIntegration({
                    traceFetch: true,
                    traceXHR: true,
                }),
                Sentry.replayIntegration({
                    blockAllMedia: false,
                    maskAllInputs: false,
                    maskAllText: false,
                }),
            ],
            ignoreTransactions: [/experimentation/, /ads/],
            ignoreErrors: [
                // Random plugins/extensions
                '<unknown>',
                'top.GLOBALS',
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                // eslint-disable-next-line @typescript-eslint/quotes
                "Can't find variable: ZiteReader",
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                'conduitPage',
                'Script error',
                // Intern errors Ignored
                'unknown',
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            environment: environment.environment,
            beforeSend(event, hint) {
                const error = hint.originalException as Error;
                if (error?.message && shouldFilterError(error?.message)) {
                    return null;
                }

                event.fingerprint = ['{{ default }}', error?.message];
                return event;
            },
        });
    }
};
