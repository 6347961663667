@if (isLoading()) {
    <app-skeleton skeletonClass="secondary-bg h-[100px] w-full"></app-skeleton>
} @else {
    <div class="rounded-xl bg-malou-color-background-dark p-6.5">
        <ng-container [ngTemplateOutlet]="restaurantAiSettingsHeaderTemplate"></ng-container>

        @if (showDetails()) {
            <ng-container [ngTemplateOutlet]="settingsDetailsTemplate"></ng-container>
        }
    </div>
}

<ng-template #restaurantAiSettingsHeaderTemplate>
    <div class="flex items-center justify-between">
        <div class="flex items-center gap-x-3">
            <div class="flex items-center gap-x-5">
                <div class="malou-text-30">✍️</div>
                <div class="flex flex-col gap-y-2">
                    <span class="malou-text-14--bold malou-color-text-1">{{ 'ai.posts.name' | translate }}</span>
                    <span class="malou-text-10--regular malou-color-text-2">{{ 'ai.posts.details' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="flex gap-x-2">
            <button
                class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-fit"
                data-testid="restaurants-post-ai-settings-edit-btn"
                mat-raised-button
                (click)="openRestaurantAiSettingsModal()">
                {{ 'common.edit' | translate }}
            </button>
            <button class="malou-btn-icon--secondary !h-12.5 !w-12.5" mat-icon-button (click)="toggleShowDetails()">
                <mat-icon class="!h-full" color="primary" [svgIcon]="showDetails() ? SvgIcon.CHEVRON_UP : SvgIcon.CHEVRON_DOWN"></mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #settingsDetailsTemplate>
    <div class="mt-6 flex flex-col gap-y-8">
        @if (aiSettingsContext.restaurantAiSettings(); as restaurantAiSettings) {
            <div class="ml-[-26px] h-[2px] w-[calc(100%+52px)] bg-malou-color-background-light"></div>
            <div class="grid grid-cols-2 gap-x-3 gap-y-5 md:!grid-cols-1">
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.modals.upsert.tabs.general.reply_tone.title' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ denominationDisplay() }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'ai.posts.length' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ lengthDisplay() }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'ai.posts.emoji' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ emojiDisplay() }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'ai.posts.tone' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ toneDisplay() }}
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
