import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { NotificationSettingsProperty, NotificationType } from '@malou-io/package-utils';

import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { ExperimentationService } from ':core/services/experimentation.service';
import { ToastService } from ':core/services/toast.service';
import { NotificationReminderItemComponent } from ':modules/user/notifications-settings/notification-reminder-item/notification-reminder-item.component';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

interface AppState {
    user: any;
}

@Component({
    selector: 'app-notifications',
    standalone: true,
    templateUrl: './notifications-settings.component.html',
    styleUrls: ['./notifications-settings.component.scss'],
    imports: [
        SlideToggleComponent,
        TranslateModule,
        NgTemplateOutlet,
        MatButtonModule,
        NotificationReminderItemComponent,
        MatProgressSpinnerModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsSettingsComponent implements OnInit {
    wantsFeedbackNotifications = false;

    readonly wantsReviewReplyReminderNotification: WritableSignal<boolean> = signal(true);
    readonly wantsSpecialHoursReminderNotification: WritableSignal<boolean> = signal(true);
    readonly wantsPostSuggestionReminderNotification: WritableSignal<boolean> = signal(true);
    readonly wantsPlatformDisconnectedNotification: WritableSignal<boolean> = signal(true);
    readonly wantsNotificationsSummary: WritableSignal<boolean> = signal(true);

    readonly sendingTestNotification = signal(false);
    readonly NotificationType = NotificationType;

    user: User | null = null;

    shouldRedirect = false;

    readonly isNegativeReviewsEmailNotificationsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-negative-reviews-email-notifications'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-negative-reviews-email-notifications'),
        }
    );

    readonly isSpecialHourEmailNotificationsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-special-hour-email-notifications'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-special-hour-email-notifications'),
        }
    );

    readonly isEmailNotificationsEnabled = toSignal(this._experimentationService.isFeatureEnabled$('release-email-notifications'), {
        initialValue: this._experimentationService.isFeatureEnabled('release-email-notifications'),
    });

    readonly isEmailPostSuggestionsNotificationsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-post-suggestion-email-notifications'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-post-suggestion-email-notifications'),
        }
    );

    readonly isNotificationsSummaryEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-notifications-email-summary'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-notifications-email-summary'),
        }
    );

    constructor(
        private readonly _route: ActivatedRoute,
        private readonly _store: Store<AppState>,
        private readonly _userService: UsersService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _translateService: TranslateService,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _notificationService: NotificationService,
        private readonly _experimentationService: ExperimentationService
    ) {}

    ngOnInit(): void {
        if (this.shouldRedirect) {
            return;
        }

        if (this._route.snapshot.queryParams.unsubscribed) {
            const isUnsubscribed = this._route.snapshot.queryParams.unsubscribed === 'true';
            const field = this._route.snapshot.queryParams.field;
            this._showUnsubscribedToast(isUnsubscribed, field);
        }

        this._store.select(selectUserInfos).subscribe((user) => {
            this.user = user;
            this.wantsFeedbackNotifications = this.user?.settings?.receiveFeedbacks ?? false;
            this.wantsReviewReplyReminderNotification.set(this.user?.settings?.notifications.email.reviewReplyReminder?.active ?? false);
            this.wantsSpecialHoursReminderNotification.set(this.user?.settings?.notifications.email.specialHourReminder?.active ?? false);
            this.wantsPostSuggestionReminderNotification.set(this.user?.settings?.notifications.email.postSuggestion?.active ?? false);
            this.wantsNotificationsSummary.set(this.user?.settings?.notifications.email.summary?.active ?? false);
            this.wantsPlatformDisconnectedNotification.set(this.user?.settings?.notifications.email?.platformDisconnected?.active ?? false);
        });
    }

    toggleReceiveFeedback(): void {
        if (!this.user?.settings) {
            return;
        }
        this.wantsFeedbackNotifications = !this.wantsFeedbackNotifications;
        const settingsUpdate = {
            ...this.user.settings,
            receiveFeedbacks: this.wantsFeedbackNotifications,
        };
        this._updateUserSettings(settingsUpdate);
        this._changeDetector.markForCheck();
    }

    toggleReceiveReminderNotifications(notificationType: NotificationType, value: boolean): void {
        if (!this.user?.settings) {
            return;
        }
        const updatedSettings = {
            ...this.user.settings,
            notifications: {
                ...this.user.settings.notifications,
                email: {
                    ...this.user.settings.notifications.email,
                    [NotificationSettingsProperty[notificationType.toUpperCase()]]: {
                        active: !value,
                    },
                },
            },
        };
        this._updateUserSettings(updatedSettings);
        this._changeDetector.markForCheck();
    }

    sendTestNotification(notificationType: NotificationType): void {
        this.sendingTestNotification.set(true);
        this._notificationService.sendTestNotification({ notificationType }).subscribe({
            next: () => {
                this.sendingTestNotification.set(false);
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                this.sendingTestNotification.set(false);
            },
        });
    }

    private _updateUserSettings(update: User['settings']): void {
        if (!this.user) {
            return;
        }
        this._userService.updateUser$(this.user._id, { settings: update }).subscribe({
            next: (res) => {
                this.user = new User(res.data);
                this._store.dispatch(UserActions.editUserInfos({ infos: new User(res.data) }));
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    private _showUnsubscribedToast(isUnsubscribed: boolean, field: string): void {
        if (!isUnsubscribed) {
            this._toastService.openErrorToast(this._translateService.instant('admin.profile.error_update_user_settings'));
            return;
        }
        let prettyField;
        switch (field) {
            case 'receiveFeedbacks':
                prettyField = this._translateService.instant('admin.profile.unsubscribed_fields.feedbacks');
                break;
            case 'receiveMessagesNotifications':
                prettyField = this._translateService.instant('admin.profile.unsubscribed_fields.messages');
                break;
            default:
                prettyField = null;
        }

        const fullText = prettyField
            ? this._translateService.instant('admin.profile.unsubscribed_successful_text') +
              this._translateService.instant('admin.profile.about') +
              prettyField
            : this._translateService.instant('admin.profile.unsubscribed_successful_text');
        this._toastService.openSuccessToast(fullText);
    }
}
