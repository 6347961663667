import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import {
    malouChartColorBluePurple,
    malouChartColorLighterPink,
    malouChartColorLightPink,
    malouChartColorLightPurple,
    malouChartColorPink,
} from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';

type BarChartType = Extract<ChartType, 'bar'>;

const CHART_COLORS = [
    malouChartColorPink,
    malouChartColorLightPink,
    malouChartColorLighterPink,
    malouChartColorLightPurple,
    malouChartColorBluePurple,
];

export type PrivateReviewCountDataTotemsDetails = Record<string, number>;
export type PrivateReviewCountData = {
    translation: string;
    rating: number;
    value: number;
    details: PrivateReviewCountDataTotemsDetails;
}[];

@Component({
    selector: 'app-statistics-totems-private-review-count-chart-v2',
    templateUrl: './private-review-count-chart.component.html',
    styleUrls: ['./private-review-count-chart.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgChartsModule, TranslateModule],
})
export class PrivateReviewCountChartV2Component implements OnInit {
    readonly privateReviewCountData = input.required<PrivateReviewCountData>();

    readonly CHART_TYPE: BarChartType = 'bar';

    readonly chartDataSets: Signal<ChartDataset<BarChartType, PrivateReviewCountData>[]> = computed(() =>
        this._computeChartData(this.privateReviewCountData())
    );
    chartOption: ChartOptions<BarChartType>;

    private readonly _translateService = inject(TranslateService);

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    private _computeChartData(privateReviewCountData: PrivateReviewCountData): ChartDataset<BarChartType, PrivateReviewCountData>[] {
        return [
            {
                borderColor: CHART_COLORS,
                backgroundColor: CHART_COLORS,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: privateReviewCountData,
                parsing: {
                    xAxisKey: 'translation',
                    yAxisKey: 'value',
                },
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<any>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    offset: false,
                    ticks: {
                        stepSize: 1,
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string {
        return `${SMALL_TOOLTIP_TAB}${this._translateService.instant('statistics.totems.private_review_count.total')} : ${item.formattedValue}`;
    }

    private _computeTooltipAfterLabel(item: TooltipItem<BarChartType>): string[] {
        const privateReviewCountData: PrivateReviewCountData[number] = item.raw as PrivateReviewCountData[number];
        return Object.entries(privateReviewCountData.details).map(([totemName, count]) => `${LARGE_TOOLTIP_TAB}${totemName}: ${count}`);
    }
}
