import { ChangeDetectionStrategy, Component, computed, input, output, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { getDateRangeFromMalouComparisonPeriod, MalouComparisonPeriod } from '@malou-io/package-utils';

import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';

export type ComparisonPeriodOption = {
    [Key in MalouComparisonPeriod]: boolean;
};

@Component({
    selector: 'app-comparison-period-selector',
    templateUrl: './comparison-period-selector.component.html',
    styleUrls: ['./comparison-period-selector.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatCheckboxModule, TranslateModule, MatExpansionModule, SlideToggleComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonPeriodSelectorComponent {
    readonly restaurantStartDate = input.required<Date>();
    readonly startDate = input.required<Date>();
    readonly endDate = input.required<Date>();

    readonly onComparisonPeriodChange = output<MalouComparisonPeriod>();

    readonly isComparePeriodSelectorExpanded: WritableSignal<boolean> = signal(false);

    readonly comparisonOptions = computed(() =>
        Object.values(MalouComparisonPeriod).map((key) => {
            const label = this._getDateRangeLabel(key);
            return {
                key,
                disabled: label === null,
                label,
            };
        })
    );

    readonly selectedComparisonPeriod: WritableSignal<MalouComparisonPeriod> = signal(MalouComparisonPeriod.LAST_PERIOD);

    private _getDateRangeLabel(period: MalouComparisonPeriod): string | null {
        const dateFromMalouComparisonPeriod = getDateRangeFromMalouComparisonPeriod({
            comparisonPeriod: period,
            dateFilters: {
                startDate: this.startDate(),
                endDate: this.endDate(),
            },
            restaurantStartDate: this.restaurantStartDate(),
        });
        if (dateFromMalouComparisonPeriod.startDate === null || dateFromMalouComparisonPeriod.endDate === null) {
            return null;
        }

        const { startDate, endDate } = dateFromMalouComparisonPeriod;
        return `${this._formatRange(this.startDate(), this.endDate())} - ${this._formatRange(startDate, endDate)}`;
    }

    toggleExpandComparePeriodSelector(): void {
        this.isComparePeriodSelectorExpanded.set(!this.isComparePeriodSelectorExpanded());
    }

    onComparePeriodChange(value: MalouComparisonPeriod): void {
        this.selectedComparisonPeriod.set(value);
        this.onComparisonPeriodChange.emit(value);
    }

    private _formatRange(startDate: Date, endDate: Date): string {
        const luxonStartDate = DateTime.fromJSDate(startDate);
        const luxonEndDate = DateTime.fromJSDate(endDate);
        return `${luxonStartDate.toFormat('dd.MM.yyyy')}/${luxonEndDate.toFormat('dd.MM.yyyy')}`;
    }
}
