import { DateTime } from 'luxon';

import { MalouComparisonPeriod } from './interface';

interface GetDateRangeFromMalouComparisonPeriodInput {
    restaurantStartDate: Date;
    dateFilters: {
        startDate: Date;
        endDate: Date;
    };
    comparisonPeriod: MalouComparisonPeriod;
}

interface GetDateRangeFromMalouComparisonPeriodOutput {
    startDate: Date | null;
    endDate: Date | null;
}

export const getDateRangeFromMalouComparisonPeriod = ({
    restaurantStartDate,
    dateFilters,
    comparisonPeriod,
}: GetDateRangeFromMalouComparisonPeriodInput): GetDateRangeFromMalouComparisonPeriodOutput => {
    const startDateLuxon = DateTime.fromJSDate(dateFilters.startDate).setZone('utc');
    const endDateLuxon = DateTime.fromJSDate(dateFilters.endDate).setZone('utc');
    const duration = endDateLuxon.diff(startDateLuxon);
    const restaurantStartDateLuxon = DateTime.fromJSDate(restaurantStartDate).setZone('utc');
    const offset = DateTime.local().offset;
    switch (comparisonPeriod) {
        case MalouComparisonPeriod.LAST_PERIOD:
            return {
                startDate: startDateLuxon.minus(duration).toJSDate(),
                endDate: startDateLuxon.toJSDate(),
            };
            break;
        case MalouComparisonPeriod.SAME_PERIOD_LAST_YEAR:
            const samePeriodLastYearDate = DateTime.fromObject({
                year: startDateLuxon.year,
                month: startDateLuxon.month,
                day: startDateLuxon.day,
                hour: 0,
                minute: 0,
                second: 0,
            })
                .plus({ minutes: offset })
                .minus({ years: 1 });

            if (samePeriodLastYearDate.valueOf() < restaurantStartDateLuxon.valueOf()) {
                return {
                    startDate: null,
                    endDate: null,
                };
            }
            return {
                startDate: startDateLuxon.minus({ years: 1 }).toJSDate(),
                endDate: endDateLuxon.minus({ years: 1 }).toJSDate(),
            };
            break;
        case MalouComparisonPeriod.SAME_PERIOD_FIRST_YEAR:
            const firstYearStartDate = DateTime.fromObject({
                year: restaurantStartDateLuxon.year,
                month: startDateLuxon.month,
                day: startDateLuxon.day,
                hour: 0,
                minute: 0,
                second: 0,
            }).plus({ minutes: offset });

            if (firstYearStartDate.valueOf() < restaurantStartDateLuxon.valueOf()) {
                return {
                    startDate: null,
                    endDate: null,
                };
            }
            const firstYearEndDate = firstYearStartDate.plus(duration);

            return {
                startDate: firstYearStartDate.toJSDate(),
                endDate: firstYearEndDate.toJSDate(),
            };
        case MalouComparisonPeriod.SINCE_START:
            return {
                startDate: restaurantStartDateLuxon.toJSDate(),
                endDate: restaurantStartDateLuxon.plus(duration).toJSDate(),
            };
        default:
            throw new Error('Invalid comparison period');
            break;
    }
};
