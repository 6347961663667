<div
    class="flex items-center text-malou-color-text-white bg-malou-color-gradient-primary {{ customButtonClasses() }}"
    [ngClass]="{
        'h-[36px]': size() === MenuButtonSize.MEDIUM,
        'h-[50px]': size() === MenuButtonSize.LARGE,
    }">
    <div
        class="malou-text-12--medium flex h-full items-center border-r border-malou-color-background-white/50 px-5"
        [ngClass]="{
            'cursor-pointer': !disabled(),
            'cursor-not-allowed': disabled(),
            'opacity-50': disabled(),
        }"
        (click)="onButtonClick()">
        {{ selectedOptionText() }}
    </div>
    <div class="flex h-full cursor-pointer items-center px-5" [matMenuTriggerFor]="matMenu">
        <mat-icon class="!w-4" [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
    </div>
</div>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" xPosition="before" #matMenu="matMenu">
    @for (option of options(); track option) {
        <button class="flex !px-5" mat-menu-item (click)="onMenuItemButtonClick(option)">
            <span
                class="text-malou-color-text-2"
                [ngClass]="{
                    'malou-text-14--regular': option !== selectedOption(),
                    'malou-text-14--semibold': option === selectedOption(),
                }"
                >{{ displayOption() | applyPure: option }}</span
            >
        </button>
    }
</mat-menu>
