import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { isNumber } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { ChartDataArray, malouChartColorBluePurple, malouChartColorPink } from ':shared/helpers';
import { hasSimpleChangesAtLeastOneProperty } from ':shared/helpers/on-changes';
import { LightRestaurant } from ':shared/models';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

type BarChartType = Extract<ChartType, 'bar'>;

const RESTAURANT_NAME_MAX_LENGTH = 20;

export interface GmbImpressionsData {
    impressionsMaps: ChartDataArray;
    impressionsSearch: ChartDataArray;
}

@Component({
    selector: 'app-gmb-impressions-chart',
    templateUrl: './gmb-impressions-chart.component.html',
    styleUrls: ['./gmb-impressions-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    providers: [ShortNumberPipe, ShortTextPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbImpressionsChartComponent implements OnChanges {
    @Input() gmbImpressionsData: GmbImpressionsData;
    @Input() previousGmbImpressionsData: GmbImpressionsData;
    @Input() restaurants: LightRestaurant[];
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() hiddenDatasetIndexesChange = new EventEmitter<number[]>();

    readonly CHART_TYPE: BarChartType = 'bar';

    chartDataSets: ChartDataset<BarChartType, ChartDataArray>[];
    chartLabels: string[];
    chartOption: ChartOptions<BarChartType>;

    readonly DATA_SETS_TITLES: string[] = [
        this._translateService.instant('aggregated_statistics.seo.gmb_impressions.impressions_maps'),
        this._translateService.instant('aggregated_statistics.seo.gmb_impressions.impressions_search'),
    ];
    private _hiddenDatasetIndexes: number[] = [];

    constructor(
        private readonly _shortNumberPipe: ShortNumberPipe,
        private readonly _shortTextPipe: ShortTextPipe,
        private readonly _translateService: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSimpleChangesAtLeastOneProperty(changes, 'gmbImpressionsData')) {
            this.chartDataSets = this._computeChartData(this.gmbImpressionsData);
            if (this.hiddenDatasetIndexes.length) {
                this.chartDataSets = this.chartDataSets.filter((_, index) => !this.hiddenDatasetIndexes.includes(index));
            }
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'restaurants')) {
            this.chartLabels = this._computeChartLabels(this.restaurants);
        }
        if (hasSimpleChangesAtLeastOneProperty(changes, 'gmbImpressionsData', 'previousGmbImpressionsData')) {
            this.chartOption = this._computeChartOptions();
        }
    }

    private _computeChartData(data: GmbImpressionsData): ChartDataset<BarChartType, ChartDataArray>[] {
        return [
            {
                label: this.DATA_SETS_TITLES[0],
                borderColor: malouChartColorPink,
                backgroundColor: malouChartColorPink,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.impressionsMaps,
            },
            {
                label: this.DATA_SETS_TITLES[1],
                borderColor: malouChartColorBluePurple,
                backgroundColor: malouChartColorBluePurple,
                xAxisID: 'xAxis',
                yAxisID: 'yAxis',
                data: data.impressionsSearch,
            },
        ];
    }

    private _computeChartLabels(restaurants: LightRestaurant[]): string[] {
        return restaurants.map((e) => e.getDisplayName());
    }

    private _computeChartOptions(): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        afterTitle: (tooltipItem: TooltipItem<BarChartType>[]) => this._computeTooltipAfterLabel(tooltipItem),
                        label: (tooltipItem: TooltipItem<BarChartType>): string => this._computeTooltipLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const ci = legend.chart;
                        if (ci.isDatasetVisible(index)) {
                            ci.hide(index);
                            this._hiddenDatasetIndexes.push(index);
                            legendItem.hidden = true;
                        } else {
                            ci.show(index);
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_tickValue: number, _index: number, _ticks: Tick[]): string => {
                            const label = this.restaurants[_index].internalName;
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    ticks: {
                        callback: (tickValue: number, _index: number, _ticks: Tick[]) => this._shortNumberPipe.transform(tickValue),
                    },
                },
            },
        };
    }

    private _computeTooltipAfterLabel(item: TooltipItem<BarChartType>[]): string | undefined {
        const index = item[0].dataIndex;
        return this.restaurants[index].address?.getAddressWithDistrict();
    }

    private _getGmbImpressionsDataKeyByIndex(index: number): string {
        return ['impressionsMaps', 'impressionsSearch'][index];
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string {
        const previousValue = this.previousGmbImpressionsData[this._getGmbImpressionsDataKeyByIndex(item.datasetIndex)][item.dataIndex];
        const difference = item.parsed.y - previousValue;
        const sign = difference > 0 ? '+' : '-';
        return `${this.DATA_SETS_TITLES[item.datasetIndex]}: ${item.parsed.y} (${sign}${Math.abs(difference)})`;
    }
}
