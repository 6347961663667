<div class="malou-simple-card flex flex-col gap-y-5 p-6.5">
    <div>
        <div class="malou-text-18--bold text-malou-color-text-1">{{ 'user.reports-settings.reviews-reports' | translate }}</div>
        <div class="malou-text-13 text-malou-color-text-2">
            {{ 'user.reports-settings.reviews-reports-subtitle' | translate }}
        </div>
    </div>

    <!-- DAILY REPORTS -->
    <ng-container *ngTemplateOutlet="dailyReports"></ng-container>

    <!-- WEEKLY REPORTS -->
    <ng-container *ngTemplateOutlet="weeklyReports"> </ng-container>
</div>

<ng-template #dailyReports>
    <div class="expansion-header malou-expansion-panel">
        <mat-accordion>
            <mat-expansion-panel hideToggle [disabled]="!dailyReport()?.active" [expanded]="false">
                <mat-expansion-panel-header>
                    <div class="flex w-full items-center justify-between">
                        <div>
                            <div class="malou-text-13--bold text-malou-color-text-1">
                                {{ 'user.reports-settings.daily-reviews-report' | translate }}
                            </div>
                            <div class="malou-text-10 font-normal italic text-malou-color-text-2">
                                {{ 'user.reports-settings.daily-reviews-report-send-date' | translate }}
                            </div>
                        </div>
                        <div class="flex items-center">
                            <app-slide-toggle
                                class="mr-3"
                                [checked]="dailyReport()?.active ?? false"
                                (onToggle)="toggleDailyReportState(0)">
                            </app-slide-toggle>
                            @if (dailyReport()?.active) {
                                <mat-icon
                                    class="!w-3 transition-all"
                                    color="primary"
                                    [svgIcon]="SvgIcon.CHEVRON_DOWN"
                                    [class.rotate-180]="expansionPanels()[0].expanded"></mat-icon>
                            }
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div>
                        <div class="flex flex-col gap-4 py-7.5">
                            @for (configuration of dailyReportsConfigurations$ | async; track configuration; let i = $index) {
                                <div class="flex flex-col gap-3">
                                    @if (i > 0) {
                                        <hr class="w-full border-malou-color-background-dark" />
                                    }
                                    <!-- report entry header -->
                                    <div class="flex items-center justify-between px-5">
                                        <span class="malou-text-13--bold text-malou-color-text-1">
                                            {{ 'user.reports-settings.daily-reviews-report' | translate }} {{ i + 1 }}
                                        </span>
                                        <div class="flex gap-x-4">
                                            @if (currentUser?.isAdmin()) {
                                                <button
                                                    class="malou-btn-icon--primary btn-normal"
                                                    mat-icon-button
                                                    [disabled]="isSendButtonDisabled(configuration)"
                                                    (click)="sendTestReportConfiguration(dailyReport, configuration.controls.id.value)">
                                                    <mat-icon
                                                        class="icon-btn"
                                                        [class.malou-spin]="configuration.controls.isCurrentlySendingTestReport.value"
                                                        [svgIcon]="
                                                            configuration.controls.isCurrentlySendingTestReport.value ? 'spinner' : 'flash'
                                                        "></mat-icon>
                                                </button>
                                            }
                                            @if (((dailyReportsConfigurations$ | async)?.length ?? 0) > 1) {
                                                <button
                                                    class="malou-btn-icon--alt btn-normal"
                                                    mat-icon-button
                                                    (click)="removeDailyReportConfiguration(configuration.controls.id.value)">
                                                    <mat-icon
                                                        class="icon-btn text-malou-color-primary"
                                                        [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <!-- End header -->
                                    <div class="flex flex-col gap-4 px-5">
                                        <app-select-restaurants
                                            [values]="(localRestaurants$ | async) ?? []"
                                            [formControl]="configuration.controls.restaurants | asType: FormControl"
                                            [required]="true"
                                            [errorMessage]="
                                                configuration.controls.restaurants.invalid && configuration.controls.restaurants.dirty
                                                    ? ('user.reports-settings.add_restaurants_to_activate' | translate)
                                                    : null
                                            "
                                            [placeholder]="
                                                configuration.controls.restaurants.value.length > 0
                                                    ? ''
                                                    : ('restaurants_list.business.add_restaurant' | translate)
                                            "
                                            [selectedValues]="configuration.controls.restaurants.value"
                                            [multiSelectionElementWrap]="false"
                                            [shouldSwitchToWrapModeOnClick]="false"
                                            [displayedOptionCount]="50"
                                            [defaultEmptyValueMessage]="'app_select_restaurants.no_business_found' | translate"
                                            [shouldUpdateValuesToDisplayAfterSelection]="true"
                                            [computeObjectHash]="compareByRestaurantId"
                                            [title]="'common.restaurants' | translate">
                                        </app-select-restaurants>
                                        <app-select-recipients
                                            [values]="(candidates$ | async) ?? []"
                                            [formControl]="configuration.controls.recipients | asType: FormControl"
                                            [errorMessage]="
                                                configuration.controls.recipients.invalid && configuration.controls.recipients.dirty
                                                    ? ('user.reports-settings.add_recipients_to_activate' | translate)
                                                    : null
                                            "
                                            [placeholder]="
                                                configuration.controls.recipients.value.length > 0
                                                    ? ''
                                                    : ('user.reports-settings.add_recipients' | translate)
                                            "
                                            [required]="true"
                                            [selectedValues]="configuration.controls.recipients.value"
                                            [multiSelectionElementWrap]="false"
                                            [shouldSwitchToWrapModeOnClick]="false"
                                            [shouldUpdateValuesToDisplayAfterSelection]="true"
                                            [showValuesSelectedCount]="false"
                                            [buildValueFromText]="buildRecipientFromText()"
                                            [title]="'common.recipients' | translate"
                                            [onAddValue]="onAddRecipient()"
                                            [maxSelectableValues]="MAX_RECIPIENTS">
                                        </app-select-recipients>
                                    </div>
                                </div>
                            }
                            <div class="w-full px-5">
                                <button class="malou-btn-flat btn-normal flex items-center gap-x-2" (click)="addDailyReportConfiguration()">
                                    <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>

                                    <span> {{ 'common.add' | translate }} </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<ng-template #weeklyReports>
    <div class="expansion-header malou-expansion-panel">
        <mat-accordion>
            <mat-expansion-panel hideToggle [disabled]="!weeklyReport()?.active" [expanded]="false">
                <mat-expansion-panel-header>
                    <div class="flex w-full items-center justify-between">
                        <div>
                            <div class="malou-text-13--bold text-malou-color-text-1">
                                {{ 'user.reports-settings.weekly-reviews-report' | translate }}
                            </div>
                            <div class="malou-text-10 font-normal italic text-malou-color-text-2">
                                {{ 'user.reports-settings.weekly-reviews-report-send-date' | translate }}
                            </div>
                        </div>
                        <div class="flex items-center">
                            <app-slide-toggle
                                class="mr-3"
                                [checked]="weeklyReport()?.active ?? false"
                                (onToggle)="toggleWeeklyReportState(1)">
                            </app-slide-toggle>
                            @if (weeklyReport()?.active) {
                                <mat-icon
                                    class="!w-3 transition-all"
                                    color="primary"
                                    [svgIcon]="SvgIcon.CHEVRON_DOWN"
                                    [class.rotate-180]="expansionPanels()[1].expanded"></mat-icon>
                            }
                        </div>
                    </div>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <div>
                        <div class="flex flex-col gap-4 py-7.5">
                            @for (configuration of weeklyReportsConfigurations$ | async; track configuration; let i = $index) {
                                <div class="flex flex-col gap-3">
                                    @if (i > 0) {
                                        <hr class="w-full border-malou-color-background-dark" />
                                    }
                                    <!-- report entry header -->
                                    <div class="flex items-center justify-between px-5">
                                        <span class="malou-text-13--bold text-malou-color-text-1">
                                            {{ 'user.reports-settings.weekly-reviews-report' | translate }} {{ i + 1 }}
                                        </span>
                                        <div class="flex gap-x-4">
                                            @if (currentUser?.isAdmin()) {
                                                <button
                                                    class="malou-btn-icon--primary btn-normal"
                                                    mat-icon-button
                                                    [disabled]="isSendButtonDisabled(configuration)"
                                                    (click)="sendTestReportConfiguration(weeklyReport, configuration.controls.id.value)">
                                                    <mat-icon
                                                        class="icon-btn"
                                                        [class.malou-spin]="configuration.controls.isCurrentlySendingTestReport.value"
                                                        [svgIcon]="
                                                            configuration.controls.isCurrentlySendingTestReport.value ? 'spinner' : 'flash'
                                                        "></mat-icon>
                                                </button>
                                            }
                                            @if (((weeklyReportsConfigurations$ | async)?.length ?? 0) > 1) {
                                                <button
                                                    class="malou-btn-icon--alt btn-normal"
                                                    mat-icon-button
                                                    (click)="removeWeeklyReportConfiguration(configuration.controls.id.value)">
                                                    <mat-icon
                                                        class="icon-btn text-malou-color-primary"
                                                        [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <!-- End header -->
                                    <div class="flex flex-col gap-4 px-5">
                                        <app-select-restaurants
                                            [values]="(localRestaurants$ | async) ?? []"
                                            [formControl]="configuration.controls.restaurants | asType: FormControl"
                                            [required]="true"
                                            [errorMessage]="
                                                configuration.controls.restaurants.invalid && configuration.controls.restaurants.dirty
                                                    ? ('user.reports-settings.add_restaurants_to_activate' | translate)
                                                    : null
                                            "
                                            [placeholder]="
                                                configuration.controls.restaurants.value.length > 0
                                                    ? ''
                                                    : ('restaurants_list.business.add_restaurant' | translate)
                                            "
                                            [selectedValues]="configuration.controls.restaurants.value"
                                            [multiSelectionElementWrap]="false"
                                            [displayedOptionCount]="50"
                                            [shouldSwitchToWrapModeOnClick]="false"
                                            [defaultEmptyValueMessage]="'app_select_restaurants.no_business_found' | translate"
                                            [shouldUpdateValuesToDisplayAfterSelection]="true"
                                            [computeObjectHash]="compareByRestaurantId"
                                            [title]="'common.restaurants' | translate">
                                        </app-select-restaurants>
                                        <app-select-recipients
                                            [values]="(candidates$ | async) ?? []"
                                            [formControl]="configuration.controls.recipients | asType: FormControl"
                                            [errorMessage]="
                                                configuration.controls.recipients.invalid && configuration.controls.recipients.dirty
                                                    ? ('user.reports-settings.add_recipients_to_activate' | translate)
                                                    : null
                                            "
                                            [placeholder]="
                                                configuration.controls.recipients.value.length > 0
                                                    ? ''
                                                    : ('user.reports-settings.add_recipients' | translate)
                                            "
                                            [required]="true"
                                            [selectedValues]="configuration.controls.recipients.value"
                                            [multiSelectionElementWrap]="false"
                                            [shouldSwitchToWrapModeOnClick]="false"
                                            [shouldUpdateValuesToDisplayAfterSelection]="true"
                                            [showValuesSelectedCount]="false"
                                            [buildValueFromText]="buildRecipientFromText()"
                                            [title]="'common.recipients' | translate"
                                            [onAddValue]="onAddRecipient()"
                                            [maxSelectableValues]="MAX_RECIPIENTS">
                                        </app-select-recipients>
                                    </div>
                                </div>
                            }
                            <div class="w-full px-5">
                                <button
                                    class="malou-btn-flat btn-normal flex items-center gap-x-2"
                                    (click)="addWeeklyReportConfiguration()">
                                    <mat-icon class="!h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>

                                    <span> {{ 'common.add' | translate }} </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>
