import { CallToActionType } from '@malou-io/package-utils';

import { Phone, Restaurant } from ':shared/models';

export const getCallToActionDefaultUrl = (callToActionType: CallToActionType, restaurant?: Restaurant): string => {
    let associatedField: string | Phone | undefined = '';
    switch (callToActionType) {
        case CallToActionType.BOOK:
            associatedField = restaurant?.reservationUrl;
            break;
        case CallToActionType.ORDER:
            associatedField = restaurant?.orderUrl;
            break;
        case CallToActionType.LEARN_MORE:
        case CallToActionType.SIGN_UP:
            associatedField = restaurant?.website;
            break;
        case CallToActionType.CALL:
            associatedField = restaurant?.phone;
            break;
        default:
            associatedField = '';
            break;
    }
    return associatedField?.toString() || '';
};
