import { createAction, props } from '@ngrx/store';

import { PostsFilters } from ':shared/models';
import { ImageEdition } from ':shared/models/image-edition';

export const editStoriesFilters = createAction('[Stories] Edit_Stories_Filters', props<{ filters: PostsFilters }>());

export const changeStoriesSortOrder = createAction('[Stories] Change_Stories_Sort_Order');

export const resetStoriesFiltersDates = createAction('[Stories] Reset_Stories_Filters_Dates');

export const resetStoriesFilters = createAction('[Stories] Reset_Stories_Filters');

export const editLastSyncDate = createAction(
    '[Stories] Edit_Last_Sync_Date',
    props<{
        restaurantId: string;
        lastSyncDate: Date | null;
    }>()
);

export const editCurrentRestaurant = createAction(
    '[Stories] Edit_Current_Restaurant',
    props<{
        restaurantId: string;
    }>()
);

export const editImageEdition = createAction(
    '[Stories] Edit_Image_Edition',
    props<{
        storyId: string;
        imageEdition: ImageEdition;
    }>()
);

export const deleteImageEdition = createAction(
    '[Stories] Delete_Image_Edition',
    props<{
        storyId: string;
    }>()
);

export const resetImageEditions = createAction('[Stories] Delete_Image_Editions');
