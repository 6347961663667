import { z } from 'zod';

import { KeywordScoreTextType } from '@malou-io/package-utils';

export const processKeywordsScoreBodyValidator = z.object({
    textType: z.nativeEnum(KeywordScoreTextType),
    text: z.string(),
    bricks: z.array(z.string()),
    venueName: z.string(),
    language: z.string(),
    reviewerName: z.string().optional(),
    responseTime: z.number().optional(),
    keywordScoreMethod: z.string().optional(),
    reviewRelatedBricksCount: z.number().nullish(),
    reviewText: z.string().nullish(),
});

export type ProcessKeywordsScoreBodyDto = z.infer<typeof processKeywordsScoreBodyValidator>;
