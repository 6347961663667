import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';

import { MediaType, waitFor } from '@malou-io/package-utils';

import { Media } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

export enum SizeStrategy {
    FIT = 'fit',
    FULL = 'full',
}

@Component({
    selector: 'app-media-item',
    templateUrl: './media-item.component.html',
    styleUrls: ['./media-item.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatTooltipModule,
        MatRippleModule,
        TranslateModule,
        ImagePathResolverPipe,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaItemComponent {
    readonly containerSizeStrategy = input<SizeStrategy>(SizeStrategy.FIT);

    readonly media = input.required<Media | null>();
    readonly customMediaClass = input<string>('');
    readonly customMediaStyle = input<object>({});

    readonly icon = input<SvgIcon>();
    readonly customIconClass = input<Record<string, boolean>>({});

    readonly statusIcon = input<SvgIcon>();
    readonly customStatusIconClass = input<Record<string, boolean>>({});
    readonly customStatusIconContainerClass = input<Record<string, boolean>>({});

    readonly tag = input<string | undefined>();
    readonly link = input<string | undefined>();
    readonly tooltipText = input<string>('');

    readonly shouldLazyLoadMedia = input<boolean>(true);
    readonly showVideoControls = input<boolean>(true);

    readonly refreshMedia = output<ErrorEvent>();
    readonly loadedVideoMetadata = output<Event>();
    readonly tagClick = output<void>();

    readonly MediaType = MediaType;

    readonly shouldLoadVideo = signal(false);

    readonly SizeStrategy = SizeStrategy;

    onLoadedVideoMetadata(event: Event): void {
        this.loadedVideoMetadata.emit(event);
    }

    async playMedia(event: MouseEvent): Promise<void> {
        event.preventDefault();
        event.stopPropagation();
        const playIcon = event.target as HTMLElement;

        this.shouldLoadVideo.set(true);
        await waitFor(0); // Wait for Angular to refresh component and add the video element

        // get closest video tag
        const video = playIcon?.parentNode?.parentNode?.children[0] as HTMLVideoElement;

        // is video playing?
        if (!video.paused) {
            playIcon.innerHTML = 'play_arrow';
            video.pause();
            return;
        }

        video.play();
        playIcon.innerHTML = 'pause';
    }

    refresh(event: ErrorEvent): void {
        this.refreshMedia.emit(event);
    }

    refreshIfFailed(event: StateChange): void {
        if (event.reason === 'loading-failed') {
            this.refresh(new ErrorEvent('Could not load some media'));
        }
    }

    openLink(): void {
        const link = this.link();
        if (link) {
            window.open(link, '_blank');
        }
    }

    onTagClick(): void {
        this.tagClick.emit();
    }
}
