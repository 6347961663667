import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    OnInit,
    output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { DailyPlatformInsights, GetStoredInsightsRequestInputBodyDto, StoredInsightsResponseDto } from '@malou-io/package-dto';
import { AggregationTimeScale, isNotNil, MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { InsightsService } from ':modules/statistics/insights.service';
import { CommunityChartV2Component } from ':modules/statistics/social-networks/community-v2/community-chart/community-chart.component';
import { CommunityMainInfosV2Component } from ':modules/statistics/social-networks/community-v2/community-main-infos/community-main-infos.component';
import { CommunityData } from ':modules/statistics/social-networks/community-v2/community.interface';
import { CommunityInsightsChartData } from ':modules/statistics/social-networks/models/community-insight-chart-data';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { PlatformFilterPage } from ':modules/statistics/store/statistics.interface';
import * as StatisticsSelector from ':modules/statistics/store/statistics.selectors';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { isDateSetOrGenericPeriod, mergeArrays } from ':shared/helpers';
import { SocialNetworkInsights } from ':shared/interfaces/social-network-insights.interface';
import { DatesAndPeriod, getInsightsErrorText, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

const DEFAULT_COMMUNITY_DATA: CommunityData = {
    instagramFollowers: [],
    facebookFollowers: [],
    totalFollowers: [],
    previousInstagramFollowers: [],
    previousFacebookFollowers: [],
    previousTotalFollowers: [],
    instagramNewFollowers: [],
    facebookNewFollowers: [],
    totalNewFollowers: [],
};
@Component({
    selector: 'app-community-v2',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        FormsModule,
        ReactiveFormsModule,
        CommunityChartV2Component,
        MatProgressSpinnerModule,
        AsyncPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        StatisticsHttpErrorPipe,
        LowerCasePipe,
        ApplyPurePipe,
        CommunityMainInfosV2Component,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityV2Component implements OnInit {
    readonly showViewByTextInsteadOfSelector = input<boolean>(false);
    readonly viewBy = input<ViewBy>(ViewBy.DAY);
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly hasDataChange = output<boolean>();
    readonly isLoadingEvent = output<boolean>();

    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _insightsService = inject(InsightsService);
    private readonly _store = inject(Store);
    private readonly _translate = inject(TranslateService);
    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _destroyRef = inject(DestroyRef);

    readonly SvgIcon = SvgIcon;
    readonly ViewBy = ViewBy;
    readonly Illustration = Illustration;

    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;

    readonly platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelector.selectPlatformsFilter({ page: PlatformFilterPage.SOCIAL_NETWORKS })
    );
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelector.selectDatesFilter);

    readonly httpError: WritableSignal<string | null> = signal('invalid_platform');
    readonly isLoading = signal(true);
    readonly insightsError: WritableSignal<string | null> = signal(null);
    readonly areAllPlatformsInError: WritableSignal<boolean> = signal(false);
    readonly platformsErrorTooltip: WritableSignal<string | null> = signal(null);

    readonly hasFoundInsightsData: WritableSignal<Boolean> = signal(true);

    readonly dailyInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly dailyPreviousInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly weeklyInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly weeklyPreviousInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly monthlyInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);
    readonly monthlyPreviousInsightsChartData = signal<CommunityInsightsChartData>({} as CommunityInsightsChartData);

    readonly viewByFilter: WritableSignal<ViewBy> = signal(ViewBy.DAY);

    readonly previousPeriodDates: Signal<Date[]> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return this.monthlyPreviousInsightsChartData().dates;
            case ViewBy.WEEK:
                return this.weeklyPreviousInsightsChartData().dates;
            default:
            case ViewBy.DAY:
                return this.dailyPreviousInsightsChartData().dates;
        }
    });

    readonly dateLabels: Signal<Date[]> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return this.monthlyInsightsChartData().dates;
            case ViewBy.WEEK:
                return this.weeklyInsightsChartData().dates;
            default:
            case ViewBy.DAY:
                return this.dailyInsightsChartData().dates;
        }
    });
    readonly communityData: Signal<CommunityData> = computed(() => {
        switch (this.viewByFilter()) {
            case ViewBy.MONTH:
                return this._getCommunityData(this.monthlyInsightsChartData(), this.monthlyPreviousInsightsChartData());
                break;
            case ViewBy.WEEK:
                return this._getCommunityData(this.weeklyInsightsChartData(), this.weeklyPreviousInsightsChartData());
                break;
            default:
            case ViewBy.DAY:
                return this._getCommunityData(this.dailyInsightsChartData(), this.dailyPreviousInsightsChartData());
                break;
        }
        return DEFAULT_COMMUNITY_DATA;
    });

    constructor() {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        const viewBy = this.viewBy();
        if (viewBy) {
            this.viewByFilterSubject$.next(viewBy);
        }
        combineLatest([this._restaurantsService.restaurantSelected$, this.dates$, this.platformKeys$])
            .pipe(
                filter(
                    ([restaurant, dates, _platforms]) =>
                        !!restaurant && isNotNil(dates.startDate) && isNotNil(dates.endDate) && isDateSetOrGenericPeriod(dates)
                ),
                map(([restaurant, dates, platformKeys]) => [
                    restaurant,
                    {
                        startDate: dates.startDate,
                        endDate: dates.endDate,
                    },
                    platformKeys,
                ]),
                tap(() => this._reset()),
                switchMap(
                    ([restaurant, { startDate, endDate }, platformKeys]: [
                        Restaurant,
                        { startDate: Date; endDate: Date },
                        PlatformKey[],
                    ]) => {
                        const requestBody: GetStoredInsightsRequestInputBodyDto = {
                            restaurantIds: [restaurant._id],
                            platformKeys,
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                            metrics: [MalouMetric.FOLLOWERS],
                        };
                        return forkJoin([
                            this._insightsService.getStoredInsights(requestBody),
                            this._insightsService.getStoredInsights({ ...requestBody, previousPeriod: true }),
                            of(restaurant._id),
                            of(platformKeys),
                        ]);
                    }
                ),
                filter(([current]) => !!current),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: ([currentInsights, previousInsights, restaurantId, platformKeys]) => {
                    const currentRestaurantInsights = currentInsights.data?.[restaurantId];
                    const previousRestaurantInsights = previousInsights.data?.[restaurantId];

                    const platformsInError = this._getPlatformsInError(currentInsights.data, restaurantId, platformKeys);
                    if (platformsInError.length) {
                        this.platformsErrorTooltip.set(this._getPlatformsErrorTooltip(platformsInError));
                    }
                    if (platformKeys.length === platformsInError.length) {
                        this.areAllPlatformsInError.set(true);
                        this._setInsightsError(currentRestaurantInsights?.[PlatformKey.FACEBOOK]?.malouErrorCode);
                        this.hasDataChange.emit(false);
                    } else {
                        platformKeys.forEach((platform) => {
                            if (
                                platformsInError.includes(platform) ||
                                !currentRestaurantInsights?.[platform]?.hasData ||
                                !previousRestaurantInsights?.[platform]?.hasData
                            ) {
                                delete currentRestaurantInsights?.[platform];
                                delete previousRestaurantInsights?.[platform];
                            }
                        });

                        // TODO: Hamza implement this later
                        // this._setActionStoreData(currentData);

                        this.dailyInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_DAY)
                        );
                        this.dailyPreviousInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(previousInsights.data, restaurantId, AggregationTimeScale.BY_DAY)
                        );
                        this.weeklyInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_WEEK)
                        );
                        this.weeklyPreviousInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(previousInsights.data, restaurantId, AggregationTimeScale.BY_WEEK)
                        );
                        this.monthlyInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(currentInsights.data, restaurantId, AggregationTimeScale.BY_MONTH)
                        );
                        this.monthlyPreviousInsightsChartData.set(
                            this._getInsightsChartDataByTimeScale(previousInsights.data, restaurantId, AggregationTimeScale.BY_MONTH)
                        );
                    }
                    this.isLoadingEvent.emit(false);
                    this.isLoading.set(false);
                },
                error: (error) => {
                    this.httpError.set(error);
                    this.hasDataChange.emit(false);
                    this.isLoading.set(false);
                },
            });
        this.viewByFilterSubject$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe({
            next: (viewByFilter) => {
                this.viewByChange.emit(viewByFilter);
                this.viewByFilter.set(viewByFilter);
            },
        });
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslatePipe.transform(option, 'view_by');

    private _getCommunityData(
        currentFollowersData: CommunityInsightsChartData,
        previousFollowersData: CommunityInsightsChartData
    ): CommunityData {
        return {
            instagramFollowers: currentFollowersData.instagramFollowers || [],
            facebookFollowers: currentFollowersData.facebookFollowers || [],
            totalFollowers: mergeArrays(currentFollowersData.instagramFollowers || [], currentFollowersData.facebookFollowers || []),
            previousInstagramFollowers: previousFollowersData.instagramFollowers || [],
            previousFacebookFollowers: previousFollowersData.facebookFollowers || [],
            previousTotalFollowers: mergeArrays(
                previousFollowersData.instagramFollowers || [],
                previousFollowersData.facebookFollowers || []
            ),
            instagramNewFollowers: currentFollowersData.instagramNewFollowers,
            facebookNewFollowers: currentFollowersData.facebookNewFollowers,
            totalNewFollowers: mergeArrays(currentFollowersData.instagramNewFollowers, currentFollowersData.facebookNewFollowers),
        };
    }

    private _setInsightsError(message?: string): void {
        this.insightsError.set(
            this._translate.instant(getInsightsErrorText(message), {
                platformName: this._enumTranslatePipe.transform(PlatformKey.FACEBOOK, 'platform_key'),
            })
        );
    }

    private _getInsightsChartDataByTimeScale(
        data: SocialNetworkInsights,
        restaurantId: string,
        aggregationTimeScale: AggregationTimeScale
    ): CommunityInsightsChartData {
        return new CommunityInsightsChartData({
            data: {
                [PlatformKey.FACEBOOK]: data[restaurantId]?.[PlatformKey.FACEBOOK]?.insights,
                [PlatformKey.INSTAGRAM]: data[restaurantId]?.[PlatformKey.INSTAGRAM]?.insights,
            },
            startDate: data.startDate as string,
            endDate: data.endDate as string,
            aggregationTimeScale,
        });
    }

    private _reset(): void {
        this.httpError.set(null);
        this.insightsError.set(null);
        this.isLoading.set(true);
        this.platformsErrorTooltip.set(null);
        this.areAllPlatformsInError.set(false);
    }

    private _getPlatformsInError(
        storedInsights: StoredInsightsResponseDto,
        restaurantId: string,
        filteredPlatforms: PlatformKey[]
    ): PlatformKey[] {
        return filteredPlatforms.filter((platform) => storedInsights?.[restaurantId]?.[platform]?.malouErrorCode);
    }

    private _getPlatformsErrorTooltip(platformsInError: PlatformKey[]): string {
        return platformsInError.map((platform) => this._enumTranslatePipe.transform(platform, 'platform_key')).join(', ');
    }

    private _setActionStoreData(_currentData: DailyPlatformInsights | undefined): void {
        // TO BE IMPLEMENTED
        return;
    }
}
