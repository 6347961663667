import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { filter, map, Observable, of, take } from 'rxjs';

import { SocialPostDto } from '@malou-io/package-dto';
import { ApiResultV2, isNotNil, MediaType, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { mapsterPostCaptionTextLimit, maxHashtagInIgPosts, postCaptionTextLimit } from ':core/constants';
import { environment } from ':environments/environment';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { IUpsertSocialPost, UpsertSocialPost } from ':modules/posts-v2/social-posts/models/upsert-social-post';
import { isPastHour } from ':shared/helpers';
import { Platform } from ':shared/models';

@Injectable({
    providedIn: 'root',
})
export class UpsertSocialPostService {
    private readonly _http = inject(HttpClient);
    private readonly _API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/posts/v2`;
    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);

    getConnectedSocialPlatforms$(): Observable<Platform[]> {
        return this._store.select(selectCurrentPlatforms).pipe(
            filter(isNotNil),
            map((platforms) =>
                platforms.filter(
                    (platform) =>
                        PlatformDefinitions.getSocialPlatformKeys().includes(platform.key) &&
                        (platform.key !== PlatformKey.MAPSTR || !!platform.credentials?.length)
                )
            ),
            take(1)
        );
    }

    getPost$(postId: string): Observable<UpsertSocialPost | null> {
        return this._http
            .get<ApiResultV2<SocialPostDto>>(`${this._API_BASE_URL}/${postId}`)
            .pipe(map((res) => UpsertSocialPost.fromDto(res.data)));
    }

    createPost$(restaurantId: string): Observable<UpsertSocialPost> {
        return this._http
            .post<ApiResultV2<SocialPostDto>>(`${this._API_BASE_URL}`, { restaurantId })
            .pipe(map((res) => UpsertSocialPost.fromDto(res.data)));
    }

    updatePost$(post: IUpsertSocialPost): Observable<UpsertSocialPost> {
        return this._http
            .put<ApiResultV2<SocialPostDto>>(`${this._API_BASE_URL}`, post)
            .pipe(map((res) => UpsertSocialPost.fromDto(res.data)));
    }

    deletePost(_postId: string): Observable<null> {
        // TODO implement
        return of(null);
    }

    // See "get formErrors(): string[] {" in new-social-post-modal.component.ts
    getPostErrors(post: IUpsertSocialPost): string[] {
        const errorMessages: string[] = [];

        if (post.text.trim().length === 0) {
            errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.add_text'));
        }

        if (post.platformKeys.includes(PlatformKey.MAPSTR)) {
            if (post.text.length > mapsterPostCaptionTextLimit) {
                errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.mapstr_caption_too_long'));
            }
            if (!post.title) {
                errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.add_title'));
            }
        } else {
            if (post.text.length > postCaptionTextLimit) {
                errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.caption_too_long'));
            }
        }

        if (post.platformKeys.length === 0) {
            errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.no_platform_selected'));
        }

        // eslint-disable-next-line @typescript-eslint/quotes
        const hourWithMinute = DateTime.fromJSDate(post.plannedPublicationDate).toFormat("HH':'mm");
        if (isPastHour({ date: post.plannedPublicationDate, hourWithMinute })) {
            errorMessages.push(
                this._translateService.instant('social_posts.upsert_social_post_modal.errors.planned_publication_date_in_past')
            );
        }

        if (post.hashtags.selected.length > maxHashtagInIgPosts) {
            errorMessages.push(
                this._translateService.instant('social_posts.upsert_social_post_modal.errors.too_many_hashtags_error', {
                    max_hashtags_number: maxHashtagInIgPosts,
                })
            );
        }

        if (post.attachments.length === 0) {
            errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.no_media_selected'));
        }

        // TODO when video is handled in media V2
        // if (post.attachments.some((media) => media.hasErrors())) {
        //     errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.edit_or_delete_medias'));
        // }

        if (this._isCarouselVideosAndFacebookChecked(post.attachments, post.platformKeys)) {
            errorMessages.push(
                this._translateService.instant('social_posts.upsert_social_post_modal.errors.carousel_facebook_videos_error')
            );
        }

        if (this._isNoImageMediaPostAndMapstrChecked(post.attachments, post.platformKeys)) {
            errorMessages.push(
                this._translateService.instant('social_posts.upsert_social_post_modal.errors.no_image_and_mapstr_checked_error')
            );
        }

        if (this._isNotOnlyOneImageMediaAndOnlyMapstrChecked(post.attachments, post.platformKeys)) {
            errorMessages.push(
                this._translateService.instant(
                    'social_posts.upsert_social_post_modal.errors.not_only_one_image_and_only_mapstr_checked_error'
                )
            );
        }

        // TODO edit "false" to isReel when reels are implemented
        if (this._isSingleVideoAttachmentPost(post.attachments, post.platformKeys, false)) {
            errorMessages.push(this._translateService.instant('social_posts.upsert_social_post_modal.errors.single_video_error_message'));
        }

        if (this._isTikTokCheckedAndContentDisclosureIsActivatedButNoOptionChecked(post.platformKeys)) {
            errorMessages.push(
                this._translateService.instant('social_posts.upsert_social_post_modal.errors.tiktok_content_disclosure_error')
            );
        }

        return errorMessages;
    }

    private _isCarouselVideosAndFacebookChecked(medias: EditionMedia[], platformKeys: PlatformKey[]): boolean {
        return medias.length > 1 && medias.some((m) => m.type === MediaType.VIDEO) && platformKeys.includes(PlatformKey.FACEBOOK);
    }

    private _isNoImageMediaPostAndMapstrChecked(medias: EditionMedia[], platformKeys: PlatformKey[]): boolean {
        return !medias.some((m) => m.type === MediaType.PHOTO) && platformKeys.length > 1 && platformKeys.includes(PlatformKey.MAPSTR);
    }

    private _isNotOnlyOneImageMediaAndOnlyMapstrChecked(medias: EditionMedia[], platformKeys: PlatformKey[]): boolean {
        return (
            (medias.length !== 1 || medias[0].type !== MediaType.PHOTO) &&
            platformKeys.length === 1 &&
            platformKeys[0] === PlatformKey.MAPSTR
        );
    }

    private _isSingleVideoAttachmentPost(medias: EditionMedia[], platformKeys: PlatformKey[], isReel: boolean): boolean {
        return medias.length === 1 && medias[0].type === MediaType.VIDEO && !isReel && platformKeys.includes(PlatformKey.INSTAGRAM);
    }

    // TODO edit when TikTok content disclosure is implemented
    private _isTikTokCheckedAndContentDisclosureIsActivatedButNoOptionChecked(platformKeys: PlatformKey[]): boolean {
        return platformKeys.includes(PlatformKey.TIKTOK);
        // this.contentDisclosureSettingsIsActivated?.value &&
        // !this.contentDisclosureSettingsYourBrand?.value &&
        // !this.contentDisclosureSettingsBrandedContent?.value
    }
}
