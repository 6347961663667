import { Injectable } from '@angular/core';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { LocalStorage } from ':core/storage/local-storage';

@Injectable({
    providedIn: 'root',
})
export class LangService {
    getLangFromLocalStorage(): ApplicationLanguage {
        return LocalStorage.getLang();
    }

    getLangFromNavigator(): ApplicationLanguage | null {
        const localizedBrowserLang =
            navigator.language ||
            (
                navigator as {
                    userLanguage?: string; // For IE only
                }
            ).userLanguage; // Could be 'it' or 'en-GB' for example
        const browserLang = localizedBrowserLang?.split('-')[0];
        return this.isLangSupported(browserLang) ? browserLang : null;
    }

    getLangsFromNavigator(): string[] {
        const userLanguage = (
            navigator as {
                userLanguage?: string;
            }
        ).userLanguage;
        return [...(navigator.languages ? navigator.languages : []), ...(userLanguage ? [userLanguage] : [])];
    }

    isLangSupported(lang?: string): lang is ApplicationLanguage {
        return !!lang && Object.values(ApplicationLanguage).includes(lang as ApplicationLanguage);
    }
}
