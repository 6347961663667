import { Pipe, PipeTransform } from '@angular/core';

import { CaslAction, CaslSubject } from '@malou-io/package-utils';

import { AbilitiesContext } from ':core/context/abilities.context';

@Pipe({
    name: 'caslAble',
    standalone: true,
})
export class CaslAblePipe implements PipeTransform {
    constructor(private readonly _abilitiesContext: AbilitiesContext) {}

    transform(action: CaslAction, subject: CaslSubject): boolean {
        return (
            this._abilitiesContext.userAbilities.can(action, subject) || this._abilitiesContext.userRestaurantAbilities.can(action, subject)
        );
    }
}
