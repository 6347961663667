import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PlatformKey } from '@malou-io/package-utils';

import { formatStringDate } from ':shared/helpers';
import { Review } from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

@Pipe({
    name: 'reviewDate',
    standalone: true,
})
export class ReviewDatePipe implements PipeTransform {
    constructor(private readonly _translate: TranslateService) {}

    transform(review: Review | PrivateReview, withHours = false): string {
        if (!review) {
            return '';
        }
        if (![PlatformKey.FACEBOOK, PlatformKey.GMB].includes(review.key)) {
            return formatStringDate(review.socialCreatedAt, withHours);
        }

        const socialCreatedAtDate = review.socialCreatedAt;
        const socialUpdatedAtDate = (<Review>review).socialUpdatedAt;
        if (!socialUpdatedAtDate || socialCreatedAtDate?.getTime() === socialUpdatedAtDate?.getTime()) {
            return formatStringDate(socialCreatedAtDate, withHours);
        }
        const prefix = `${this._translate.instant('reviews.updated_at')} `;
        const socialUpdatedAt = socialUpdatedAtDate ? prefix + formatStringDate(socialUpdatedAtDate, withHours) : null;
        return [formatStringDate(socialCreatedAtDate, withHours), socialUpdatedAt].filter(Boolean).join(' - ');
    }
}
