import { Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { uniqBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { SelectionModel } from ':shared/helpers/selection-model';

/**
 * Extend SelectionModel with  new variable : selectableValues .
 * Features :
 *   - The SelectionModel will be updated if selectableValues change
 *   - The select method param will be filtered by the values present in selectableValues. (ie: you cannot select values outside selectableValues)
 *   - Add a selectAll() method that will select all values in selectableValues
 */
export class SelectionModelExtended<T> extends SelectionModel<T> {
    private readonly _selectableValues$ = new BehaviorSubject<T[]>([]);

    constructor(selectableValues: T[], hashFn?: (item: T) => any) {
        super(hashFn);
        this._selectableValues$ = new BehaviorSubject<T[]>(selectableValues);
    }

    getSelectableValues(): T[] {
        return this._selectableValues$.value;
    }

    getSelectableValues$(): Observable<T[]> {
        return this._selectableValues$.asObservable();
    }

    getSelectableValuesAsSignal(): Signal<T[]> {
        return toSignal(this.getSelectableValues$(), { initialValue: this.getSelectableValues() });
    }

    selectAll(): void {
        const selectableValues = this.getSelectableValues();
        if (selectableValues.length !== this.getSelection().length) {
            this._selection$.next(selectableValues);
        }
    }

    protected override _select(values: T[], selection: T[]): T[] {
        const filteredValues = values.filter((value) =>
            this.getSelectableValues().some((sValue) => this._hashFn(value) === this._hashFn(sValue))
        );
        return uniqBy([...selection, ...filteredValues], this._hashFn);
    }

    updateSelectableValues(selectableValues: T[]): void {
        this._selectableValues$.next(selectableValues);
        this._computeSelectionAgainstSelectableValues();
    }

    private _computeSelectionAgainstSelectableValues(): void {
        const selection = this.getSelection();
        const selectableValues = this.getSelectableValues();
        const filteredSelection = selection.filter((value) =>
            selectableValues.some((sValue) => this._hashFn(value) === this._hashFn(sValue))
        );
        if (filteredSelection.length !== selection.length) {
            this._selection$.next(filteredSelection);
        }
    }
}
