<div class="malou-text-10--regular text-malou-color-text-2">
    @switch (platformAccessStatus()) {
        @case (InformationUpdatesStatus.MISSING_PERMISSIONS) {
            {{ 'informations.missing_permissions' | translate: { platformName: platformKey() | platformName } }}
            <ng-container [ngTemplateOutlet]="connectTemplate"></ng-container>
        }
        @case (InformationUpdatesStatus.MANUAL_UPDATE_ERROR) {
            {{ 'informations.manual_update_error' | translate }}
            <ng-container [ngTemplateOutlet]="contactSupportTemplate"></ng-container>
        }
        @case (InformationUpdatesStatus.BAD_ACCESS) {
            @if (
                [
                    informationUpdateSupportedKeyMap.pagesjaunes,
                    informationUpdateSupportedKeyMap.deliveroo,
                    informationUpdateSupportedKeyMap.lafourchette,
                ] | includes: platformKey()
            ) {
                {{ 'informations.invalid_credentials' | translate }}
            } @else if (
                [
                    informationUpdateSupportedKeyMap.tripadvisor,
                    informationUpdateSupportedKeyMap.yelp,
                    informationUpdateSupportedKeyMap.foursquare,
                    informationUpdateSupportedKeyMap.resy,
                ] | includes: platformKey()
            ) {
                {{ 'informations.need_gestion_clients_as_admin' | translate }}
            } @else {
                {{ 'platforms.connection.bad_access' | translate }}
            }
            <ng-container [ngTemplateOutlet]="connectTemplate"></ng-container>
        }
        @case (InformationUpdatesStatus.UNCLAIMED_PAGE) {
            {{ 'informations.unclaimed_page_reconnect_platform' | translate: { platformName: platformKey() | platformName } }}
        }
        @case (InformationUpdatesStatus.NOT_FOUND) {
            {{ 'informations.not_found_page' | translate }}
        }
        @case (InformationUpdatesStatus.INVALID_PAGE) {
            {{ 'informations.page_does_not_exist_yet_details' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (InformationUpdatesStatus.UNVERIFIED) {
            {{ 'platforms.connection.disconnected_platform' | translate }}
        }
        <!-- not sure that this status still exists. -->
        @case (InformationUpdatesStatus.FAILED) {
            {{ 'platforms.connection.failed' | translate }}
        }
        @case (InformationUpdatesStatus.ERROR) {
            @if (errors().length === 0) {
                <div>
                    {{ getErrorMessage | applyPure: { reason: InformationUpdateErrorReason.UNKNOWN } }}
                </div>
            } @else {
                <div class="flex flex-col gap-2">
                    @for (error of errors(); track error) {
                        <div>
                            {{ getErrorMessage | applyPure: error }}
                            @if (
                                [
                                    InformationUpdateErrorReason.LAT_LNG_UPDATES_NOT_PERMITTED,
                                    InformationUpdateErrorReason.PIN_DROP_REQUIRED,
                                    InformationUpdateErrorReason.STALE_DATA,
                                    InformationUpdateErrorReason.GMB_PLATFORM_DISCONNECTED,
                                    InformationUpdateErrorReason.GMB_PLATFORM_DISABLED,
                                    InformationUpdateErrorReason.GMB_PLATFORM_SUSPENDED,
                                    InformationUpdateErrorReason.GMB_PLATFORM_PENDING_VERIFICATION,
                                    InformationUpdateErrorReason.GMB_PLATFORM_NOT_VERIFIED,
                                ].includes(error.reason)
                            ) {
                                <ng-container [ngTemplateOutlet]="goToGmbProfileTemplate"></ng-container>
                            }
                        </div>
                    }
                </div>
            }
        }
        @default {}
    }
</div>

<ng-template #connectTemplate>
    <span class="malou-text-10--semibold malou-color-primary cursor-pointer" (click)="goToPlatforms()">
        {{ 'informations.connect' | translate }}
    </span>
</ng-template>

<ng-template #goToGmbProfileTemplate>
    <span class="malou-text-10--semibold malou-color-primary cursor-pointer" (click)="goToGmbProfile()">
        {{ 'informations.go_to_gmb_profile' | translate }}
    </span>
</ng-template>

<ng-template #contactSupportTemplate>
    <span class="malou-text-10--semibold malou-color-primary cursor-pointer" (click)="contactSupport()">
        {{ 'informations.contact_support' | translate }}.
    </span>
</ng-template>
