import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { map, skip, switchMap, take } from 'rxjs/operators';

import { InsightsChart, InsightsTab, PlatformKey } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT } from ':modules/aggregated-statistics/aggregated-statistics.component';
import { ReviewsCountComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-count/reviews-count.component';
import { ReviewsRatingKpisComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-rating-kpis/reviews-rating-kpis.component';
import { ReviewsRatingsAverageComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews-ratings-average/reviews-ratings-average.component';
import { ReviewsInsightsComponent } from ':modules/aggregated-statistics/e-reputation/reviews/reviews.component';
import { FiltersComponent } from ':modules/aggregated-statistics/filters/filters.component';
import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsActions from ':modules/aggregated-statistics/store/aggregated-statistics.actions';
import { PlatformFilterPage } from ':modules/aggregated-statistics/store/aggregated-statistics.interface';
import * as StatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions, StatisticsDataViewMode } from ':shared/components/download-insights-modal/download-insights.interface';
import { ReviewAnalysesV2Component } from ':shared/components/review-analyses-v2/review-analyses.component';
import { ReviewAnalysesComponent } from ':shared/components/review-analyses/review-analyses.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-e-reputation',
    templateUrl: './e-reputation.component.html',
    styleUrls: ['./e-reputation.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        TranslateModule,
        FiltersComponent,
        ReviewsCountComponent,
        ReviewsInsightsComponent,
        ReviewsRatingsAverageComponent,
        ReviewAnalysesComponent,
        ReviewAnalysesV2Component,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ReviewsRatingKpisComponent,
        MatButtonModule,
    ],
})
export class EReputationComponent implements OnInit {
    readonly PlatformFilterPage = PlatformFilterPage;
    readonly Illustration = Illustration;

    hasPlatformsLoadedOnce = false;

    platformKeys$: Observable<PlatformKey[]> = this._store.select(
        StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })
    );
    readonly restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$;
    isRestaurantsCountUiLimitExceeded$: Observable<boolean> = this.restaurants$.pipe(
        map((restaurants) => restaurants.length > AGGREGATED_STATISTICS_RESTAURANTS_COUNT_UI_LIMIT)
    );

    readonly InsightsChart = InsightsChart;
    readonly reviewsRatingKpisDisplayedColumnsCount: WritableSignal<number> = signal(0);
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.AGGREGATED_REVIEWS_COUNT]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEWS_RATING_AVERAGE]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEW_ANALYSES_TAG_EVOLUTION]: {
            chartSortBy: ChartSortBy.ALPHABETICAL,
        },
        [InsightsChart.AGGREGATED_REVIEW_RATINGS_KPIS]: {
            tableSortOptions: undefined,
        },
    });

    isReviewsRatingsKpisLoading = signal(true);
    isReviewsCountLoading = signal(true);
    isReviewsRatingsAverageLoading = signal(true);
    isReviewsAnalysesLoading = signal(true);
    areReviewsLoading = signal(true);

    // TODO: Remove when the feature flag is removed
    readonly isReleaseReviewAnalysisPerformanceImprovementsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-review-analysis-insights-v2'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-review-analysis-insights-v2'),
        }
    );
    readonly isReleaseReviewPerformanceImprovementsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-review-insights-v2'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-review-insights-v2'),
        }
    );

    isLoading = computed(() => {
        const isReviewsLoading = this.isReleaseReviewPerformanceImprovementsEnabled()
            ? this.areReviewsLoading()
            : this.isReviewsRatingsKpisLoading() || this.isReviewsCountLoading() || this.isReviewsRatingsAverageLoading();
        return isReviewsLoading || this.isReviewsAnalysesLoading();
    });

    constructor(
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _experimentationService: ExperimentationService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this._store
            .select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION }))
            // We skip the first emission because it is the store initialization
            .pipe(skip(1), take(1))
            .subscribe(() => {
                this.hasPlatformsLoadedOnce = true;
            });
    }

    ngOnInit(): void {
        this._store.dispatch(AggregatedStatisticsActions.editSelectedPage({ page: PlatformFilterPage.E_REPUTATION }));
    }

    openDownloadStatisticsModal(): void {
        combineLatest([
            this._store.select(StatisticsSelectors.selectDatesFilter),
            this._store.select(StatisticsSelectors.selectRestaurantIdsFilter),
            this._store.select(StatisticsSelectors.selectPlatformsFilter({ page: PlatformFilterPage.E_REPUTATION })),
        ])
            .pipe(
                take(1),
                switchMap(([{ startDate, endDate }, restaurantIds, platforms]) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.AGGREGATED_E_REPUTATION,
                                filters: {
                                    dates: { startDate, endDate },
                                    restaurantIds,
                                    platforms,
                                },
                                chartOptions: this.chartOptions(),
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onSortByChange(chart: InsightsChart, value: ChartSortBy): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                chartSortBy: value,
            },
        }));
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                tableSortOptions: value,
            },
        }));
    }

    onViewModeChange(chart: InsightsChart, value: StatisticsDataViewMode): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                viewMode: value,
            },
        }));
    }
}
