import { CaslRole } from '../../../constants';
import { CaslAction, CaslSubject } from '../casl.enum';
import { userRestaurantCaslPermissions, UserRestaurantPermission, UserRestaurantPermissionArgs } from '../permissions';

export const userRestaurantAbilities: Record<CaslRole, (ur: UserRestaurantPermissionArgs, { can }) => any> = {
    [CaslRole.ADMIN]: (_ur: UserRestaurantPermissionArgs, { can }) => {
        can(CaslAction.MANAGE, CaslSubject.ALL);
    },
    [CaslRole.OWNER]: (ur: UserRestaurantPermissionArgs, { can }) => {
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MODIFY_INFORMATION, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.DELETE_PLATFORM, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_PLATFORM, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_PLATFORM, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_SUBSCRIPTION, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.DISABLE_VENUE, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPLOAD_MEDIA, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_BRICKS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_KEYWORDS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_REVIEWS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_TEMPLATES, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_HASHTAGS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_COMMENTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_SEO_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.PUBLISH_SEO_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_SOCIAL_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.PUBLISH_SOCIAL_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_INSPIRATIONS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.SAVE_INSPIRATIONS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.READ_USER_RESTAURANTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.DELETE_USER_RESTAURANTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_USER_RESTAURANTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.CREATE_USER, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_LABELS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_CLIENTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_CAMPAIGNS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_USER_ORGANIZATIONS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_FEEDBACKS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.ANSWER_FEEDBACK, ur));
    },
    [CaslRole.EDITOR]: (ur: UserRestaurantPermissionArgs, { can }) => {
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MODIFY_INFORMATION, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPLOAD_MEDIA, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_PLATFORM, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_BRICKS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_KEYWORDS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_REVIEWS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_TEMPLATES, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_HASHTAGS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_COMMENTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_SEO_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.PUBLISH_SEO_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_SOCIAL_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.PUBLISH_SOCIAL_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_INSPIRATIONS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.SAVE_INSPIRATIONS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.READ_USER_RESTAURANTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_LABELS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_CLIENTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_CAMPAIGNS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_FEEDBACKS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.ANSWER_FEEDBACK, ur));
    },
    [CaslRole.MODERATOR]: (ur: UserRestaurantPermissionArgs, { can }) => {
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MODIFY_INFORMATION, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPLOAD_MEDIA, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.UPDATE_PLATFORM, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_REVIEWS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_COMMENTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_TEMPLATES, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_SOCIAL_POSTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.READ_USER_RESTAURANTS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.MANAGE_LABELS, ur));
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.ANSWER_FEEDBACK, ur));
    },
    [CaslRole.GUEST]: (ur: UserRestaurantPermissionArgs, { can }) => {
        can(...userRestaurantCaslPermissions(UserRestaurantPermission.READ_USER_RESTAURANTS, ur));
    },
};
