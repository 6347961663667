import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const updateFeedbackIsOpenParamsValidator = z
    .object({
        feedback_id: objectIdValidator,
    })
    .transform((data) => ({
        feedbackId: data.feedback_id,
    }));
export type UpdateFeedbackIsOpenParamsDto = z.infer<typeof updateFeedbackIsOpenParamsValidator>;

export const updateFeedbackIsOpenQueryValidator = z
    .object({
        restaurant_id: objectIdValidator,
    })
    .transform((data) => ({
        restaurantId: data.restaurant_id,
    }));
export type UpdateFeedbackIsOpenQueryDto = z.infer<typeof updateFeedbackIsOpenQueryValidator>;

export const updateFeedbackIsOpenBodyValidator = z.object({
    isOpen: z.boolean(),
});
export type UpdateFeedbackIsOpenBodyDto = z.infer<typeof updateFeedbackIsOpenBodyValidator>;
