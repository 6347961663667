import { z } from 'zod';

import { AppEntity, FileFormat, MediaCategory, MediaType } from '@malou-io/package-utils';

import { booleanAsStringValidator, objectIdValidator } from '../utils/validators';

export const duplicateRestaurantMediaParamsValidator = z.object({
    restaurant_id: objectIdValidator,
});
const duplicateRestaurantMediaValidator = z.object({
    name: z.string().optional(),
    format: z.nativeEnum(FileFormat),
    type: z.nativeEnum(MediaType),
    duration: z.number().nullish(),
    urls: z.object({
        original: z.string(),
        small: z.string().optional(),
        cover: z.string().optional(),
        smallCover: z.string().optional(),
        igFit: z.string().optional(),
    }),
    sizes: z.object({
        original: z.number().optional(),
        small: z.number().optional(),
        cover: z.number().optional(),
        smallCover: z.number().optional(),
        igFit: z.number().optional(),
    }),
    dimensions: z
        .object({
            original: z.object({ width: z.number(), height: z.number() }).optional(),
            small: z.object({ width: z.number(), height: z.number() }).optional(),
            cover: z.object({ width: z.number(), height: z.number() }).optional(),
            smallCover: z.object({ width: z.number(), height: z.number() }).optional(),
            igFit: z.object({ width: z.number(), height: z.number() }).optional(),
        })
        .optional(),
    folderId: objectIdValidator.nullish(),
    category: z.nativeEnum(MediaCategory).optional(),
});
export const duplicateRestaurantMediaBodyValidator = z.object({
    restaurantIds: z.array(objectIdValidator),
    originalMedia: z.array(duplicateRestaurantMediaValidator),
});
export type DuplicateRestaurantMediaParamsDto = z.infer<typeof duplicateRestaurantMediaParamsValidator>;
export type DuplicateRestaurantMediaBodyDto = z.infer<typeof duplicateRestaurantMediaBodyValidator>;
export type DuplicateRestaurantMediaDto = z.infer<typeof duplicateRestaurantMediaValidator>;
// ------------------------------------------------------------------------------------------
export const deleteManyMediaParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export const deleteManyMediaQueryValidator = z
    .object({ media_ids: z.array(objectIdValidator) })
    .transform((data) => ({ mediaIds: data.media_ids }));

export type DeleteManyMediaParamsDto = z.infer<typeof deleteManyMediaParamsValidator>;
export type DeleteManyMediaQueryDto = z.infer<typeof deleteManyMediaQueryValidator>;
// ------------------------------------------------------------------------------------------
export const getRestaurantMediasParamsValidator = z
    .object({ restaurant_id: objectIdValidator })
    .transform((data) => ({ restaurantId: data.restaurant_id }));
export const getRestaurantMediasQueryValidator = z
    .object({
        page_number: z.coerce.number(),
        page_size: z.coerce.number(),
        total: z.coerce.number(),
        media_type: z.string().refine((value) => [...Object.values(MediaType), 'all'].includes(value)),
        sort_order: z.coerce
            .number()
            .refine((value) => [1, -1].includes(value))
            .optional(),
        is_never_used: booleanAsStringValidator.optional(),
        show_resized: booleanAsStringValidator.optional(),
        title: z.string().optional(),
        folder_id: objectIdValidator.or(z.string().regex(/null/)).optional(),
        max_video_size: z.coerce.number().optional(),
    })
    .transform((data) => ({
        ...data,
        is_never_used: data.is_never_used === 'true',
        show_resized: data.show_resized ? data.show_resized === 'true' : undefined,
        folder_id: data.folder_id === 'null' ? null : data.folder_id,
    }));

export type GetRestaurantMediasParamsDto = z.infer<typeof getRestaurantMediasParamsValidator>;
export type GetRestaurantMediasQueryDto = z.infer<typeof getRestaurantMediasQueryValidator>;
// ------------------------------------------------------------------------------------------
export const moveMediaTowardsFolderBodyValidator = z.object({
    folderId: objectIdValidator.nullable(),
    mediaIds: z.array(objectIdValidator),
});

export type MoveMediaTowardsFolderBodyDto = z.infer<typeof moveMediaTowardsFolderBodyValidator>;
// ------------------------------------------------------------------------------------------
export const getVideoInformationBodyValidator = z.object({
    url: z.string(),
});

export type GetVideoInformationBodyDto = z.infer<typeof getVideoInformationBodyValidator>;
// ------------------------------------------------------------------------------------------
export const getCloudStorageUploadParamsBodyValidator = z.object({
    file: z.any(),
});

export type GetCloudStorageUploadParamsBodyDto = z.infer<typeof getCloudStorageUploadParamsBodyValidator>;
// ------------------------------------------------------------------------------------------
export const onlyUploadMediaQueryValidator = z
    .object({
        entity_related: z.nativeEnum(AppEntity),
        entity_id: z.string(),
        category: z.nativeEnum(MediaCategory),
    })
    .transform((data) => ({ entityRelated: data.entity_related, entityId: data.entity_id, category: data.category }));

export type OnlyUploadMediaQueryDto = z.infer<typeof onlyUploadMediaQueryValidator>;
// ------------------------------------------------------------------------------------------
export const uploadAndCreateMediaQueryValidator = z.object({
    restaurant_id: z.string().optional(),
});

export type UploadAndCreateMediaQueryDto = z.infer<typeof uploadAndCreateMediaQueryValidator>;
// ------------------------------------------------------------------------------------------
export const getMediaByIdParamsValidator = z.object({
    medium_id: objectIdValidator,
});

export const createVideoThumbnailBodyValidator = z.object({
    medium_id: objectIdValidator,
    moment_in_seconds: z.number(),
});

export const getMediaThumbnailsListBodyValidator = z.object({
    medium_id: objectIdValidator,
    moments_in_seconds: z.number().array(),
});

export type CreateVideoThumbnailBodyDto = z.infer<typeof createVideoThumbnailBodyValidator>;

export type GetMediaThumbnailsListBodyDto = z.infer<typeof getMediaThumbnailsListBodyValidator>;

export type GetMediaByIdParamsDto = z.infer<typeof getMediaByIdParamsValidator>;
// ------------------------------------------------------------------------------------------

export const fetchMediaDescriptionQueryValidator = z
    .object({
        media_ids: z.array(objectIdValidator),
    })
    .transform((data) => ({ mediaIds: data.media_ids }));

export type FetchMediaDescriptionQueryDto = z.infer<typeof fetchMediaDescriptionQueryValidator>;

// ------------------------------------------------------------------------------------------

export const updateMediaBodyValidator = z.object({
    media: z
        .object({
            id: z.string().optional(),
            type: z.nativeEnum(MediaType).optional(),
            format: z.nativeEnum(FileFormat).optional(),
            restaurantId: z.string().optional(),
            category: z.nativeEnum(MediaCategory).optional(),
            urls: z
                .object({
                    id: z.string().optional(),
                    small: z.string().optional(),
                    igFit: z.string().optional(),
                    cover: z.string().optional(),
                    smallCover: z.string().optional(),
                    original: z.string().optional(),
                })
                .optional(),
            sizes: z
                .object({
                    id: z.string().optional(),
                    original: z.number().optional(),
                    small: z.number().optional(),
                    igFit: z.number().optional(),
                    cover: z.number().optional(),
                    smallCover: z.number().optional(),
                })
                .optional(),
            socialId: z.string().optional(),
            createdAt: z.string().datetime({ offset: true }).optional(),
            updatedAt: z.string().datetime({ offset: true }).optional(),
            title: z.string().nullish(),
            userId: z.string().optional(),
            postIds: z.array(z.string()).optional(),
            originalMediaId: z.string().optional(),
            convertedStatus: z.unknown().optional(),
            dimensions: z
                .object({
                    original: z.object({ width: z.number(), height: z.number() }).optional(),
                    small: z.object({ width: z.number(), height: z.number() }).optional(),
                    igFit: z.object({ width: z.number(), height: z.number() }).optional(),
                    cover: z.object({ width: z.number(), height: z.number() }).optional(),
                    smallCover: z.object({ width: z.number(), height: z.number() }).optional(),
                })
                .optional(),
            name: z.string().optional(),
            thumbnail: z.string().optional(),
            duplicatedFromRestaurantId: z.string().optional(),
            duration: z.number().optional(),
            resizeMetadata: z
                .object({
                    width: z.number().optional(),
                    height: z.number().optional(),
                    aspectRatio: z.number().optional(),
                    cropPosition: z.object({ left: z.number(), top: z.number() }).optional(),
                })
                .optional(),
            folderId: z.string().nullish(),
        })
        .transform((data) => ({
            ...data,
            createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
            updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
        })),
});

export type UpdateMediaBodyDto = z.infer<typeof updateMediaBodyValidator>;
// ------------------------------------------------------------------------------------------
export const createMediaAfterUploadBodyValidator = z.object({
    medias: z.array(
        z.object({
            name: z.string(),
            sizes: z.object({
                original: z.number(),
                igFit: z.number().optional(),
                small: z.number().optional(),
            }),
            urls: z.object({
                original: z.string().url(),
                igFit: z.string().url().optional(),
                small: z.string().url().optional(),
            }),
            format: z.nativeEnum(FileFormat),
            type: z.nativeEnum(MediaType),
            dimensions: z.object({
                original: z.object({
                    width: z.number(),
                    height: z.number(),
                }),
                igFit: z
                    .object({
                        width: z.number(),
                        height: z.number(),
                    })
                    .optional(),
                small: z
                    .object({
                        width: z.number(),
                        height: z.number(),
                    })
                    .optional(),
            }),
            folderId: z.string().nullable(),
        })
    ),
});

export type CreateMediaAfterUploadBodyDto = z.infer<typeof createMediaAfterUploadBodyValidator>;
// ------------------------------------------------------------------------------------------
export const getMediaByIdsQueryValidator = z.object({
    media_ids: z.array(objectIdValidator),
});

export type GetMediaByIdsQueryDto = z.infer<typeof getMediaByIdsQueryValidator>;

// ------------------------------------------------------------------------------------------
export const UploadMediaV2QueryValidator = z.object({
    restaurantId: objectIdValidator,
});
export type UploadMediaV2QueryDto = z.infer<typeof UploadMediaV2QueryValidator>;
