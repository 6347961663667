import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, debounceTime, filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import {
    getTimeDifferenceInHours,
    RoiSettingsDataGatheringStartDateForLegacyRestaurants,
    sortRestaurantsByInternalNameThenName,
} from '@malou-io/package-utils';

import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { RoiContext } from ':modules/roi/roi.context';
import * as UserSelectors from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { MalouNotification } from ':shared/enums/notification';
import { Restaurant } from ':shared/models';

export interface NotificationData {
    id: MalouNotification;
    date: Date;
}

@Injectable({
    providedIn: 'root',
})
export class RoiNotificationsContext {
    private readonly _store = inject(Store);
    private readonly _roiContext = inject(RoiContext);
    private readonly _destroyRef = inject(DestroyRef);

    private readonly _ADMIN_EMAIL_TO_EXCLUDE = 'admin-app@malou.io';

    readonly notifications: WritableSignal<NotificationData[]> = signal([]);

    readonly shouldReload$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private readonly _restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants).pipe(
        filter((restaurants) => !!restaurants.length),
        map(
            (restaurants) =>
                restaurants.filter((restaurant) => !restaurant.isBrandBusiness()).sort(sortRestaurantsByInternalNameThenName) ?? []
        )
    );

    readonly restaurantsWithFetchedRoiSettings$ = combineLatest([
        this._store.select(UserSelectors.selectUserInfos),
        this._restaurants$,
        this.shouldReload$,
    ]).pipe(
        debounceTime(500),
        filter(([user, restaurants, _]) => user?.email !== this._ADMIN_EMAIL_TO_EXCLUDE && !!restaurants.length),
        switchMap(([_user, restaurants, _]: [User, Restaurant[], boolean]) =>
            forkJoin({
                restaurants: of(restaurants),
                roiSettings: this._roiContext.getRoiSettingsForRestaurants(restaurants.map((restaurant) => restaurant._id)),
            })
        ),
        takeUntilDestroyed(this._destroyRef)
    );

    emitReload(): void {
        this.shouldReload$.next(true);
    }

    initRoiNotifications(): void {
        this.restaurantsWithFetchedRoiSettings$.subscribe({
            next: ({ restaurants }) => {
                const restaurantCountWithoutRoiSettings = this._roiContext.restaurantsIdsWithoutRoiSettings().length;

                const sortedRestaurantsByCreatedAt = restaurants.sort((a, b) => getTimeDifferenceInHours(b.createdAt, a.createdAt));

                const latestCreatedRestaurantWithoutRoiSettings =
                    this._roiContext.getLatestCreatedRestaurantsWithoutRoiSettings(restaurants);

                if (restaurantCountWithoutRoiSettings > 0 && !this._isNotificationVisible(MalouNotification.FINISH_RESTAURANT_SETUP)) {
                    const isLegacyRestaurant =
                        new Date(latestCreatedRestaurantWithoutRoiSettings.createdAt).getTime() <
                        RoiSettingsDataGatheringStartDateForLegacyRestaurants.toJSDate().getTime();

                    this._addNotification(
                        MalouNotification.FINISH_RESTAURANT_SETUP,
                        isLegacyRestaurant
                            ? RoiSettingsDataGatheringStartDateForLegacyRestaurants.toJSDate()
                            : new Date(latestCreatedRestaurantWithoutRoiSettings.createdAt)
                    );
                    this._removeNotification(MalouNotification.ROI_SETTINGS_UPDATED);
                } else if (restaurantCountWithoutRoiSettings === 0) {
                    this._removeNotification(MalouNotification.FINISH_RESTAURANT_SETUP);
                    if (!this._isNotificationVisible(MalouNotification.ROI_SETTINGS_UPDATED)) {
                        const isLegacyRestaurant =
                            new Date(sortedRestaurantsByCreatedAt[0].createdAt).getTime() <
                            RoiSettingsDataGatheringStartDateForLegacyRestaurants.toJSDate().getTime();

                        this._addNotification(
                            MalouNotification.ROI_SETTINGS_UPDATED,
                            isLegacyRestaurant
                                ? RoiSettingsDataGatheringStartDateForLegacyRestaurants.toJSDate()
                                : new Date(sortedRestaurantsByCreatedAt[0].createdAt)
                        );
                    }
                }
            },
        });
    }

    private _isNotificationVisible(notificationId: MalouNotification): boolean {
        return this.notifications().some((notification) => notification.id === notificationId);
    }

    private _addNotification(notificationId: MalouNotification, date: Date): void {
        this.notifications.set([
            ...this.notifications(),
            {
                id: notificationId,
                date,
            },
        ]);
    }

    private _removeNotification(notificationId: MalouNotification): void {
        this.notifications.set(this.notifications().filter((notification) => notification.id !== notificationId));
    }
}
