import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { AggregatedWheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';
import { ToRangePipe } from ':shared/pipes/to-range.pipe';

interface AggregatedTotalGiftsData {
    totalWinningGifts: number | null;
    totalRetrievedGifts: number | null;
    totalWinningGiftsDifferenceWithPreviousPeriod: number | null;
    totalRetrievedGiftsDifferenceWithPreviousPeriod: number | null;
}

@Component({
    selector: 'app-aggregated-wheel-of-fortune-gifts-kpis-v2',
    templateUrl: './aggregated-wheel-of-fortune-gifts-kpis.component.html',
    styleUrls: ['./aggregated-wheel-of-fortune-gifts-kpis.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        TranslateModule,
        IllustrationPathResolverPipe,
        SkeletonComponent,
        ShortNumberPipe,
        NumberEvolutionComponent,
        ToRangePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedWheelOfFortuneGiftsKpisV2Component {
    readonly data = input<AggregatedWheelOfFortuneGiftsStatisticsData | null>();
    readonly isParentLoading = input(true);
    readonly isParentError = input(false);
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    readonly hasData: Signal<boolean> = computed(() => {
        const giftsInsightsPerRestaurant = this.data()?.giftsInsightsPerRestaurant ?? [];
        return giftsInsightsPerRestaurant.length > 0;
    });

    readonly totalGiftsData: Signal<AggregatedTotalGiftsData> = computed(() => {
        if (!this.data()) {
            return {
                totalWinningGifts: null,
                totalRetrievedGifts: null,
                totalWinningGiftsDifferenceWithPreviousPeriod: null,
                totalRetrievedGiftsDifferenceWithPreviousPeriod: null,
            };
        }
        const giftsInsightsPerRestaurant = this.data()?.giftsInsightsPerRestaurant ?? [];
        this.hasDataChange.emit(giftsInsightsPerRestaurant.length > 0);
        const totalWinningGifts = giftsInsightsPerRestaurant.reduce((acc, curr) => acc + (curr.giftDrawCount ?? 0), 0);
        const totalRetrievedGifts = giftsInsightsPerRestaurant.reduce((acc, curr) => acc + (curr.retrievedGiftDrawCount ?? 0), 0);
        const previousTotalWinningGifts = giftsInsightsPerRestaurant.reduce((acc, curr) => acc + (curr.previousGiftDrawCount ?? 0), 0);
        const previousTotalRetrievedGifts = giftsInsightsPerRestaurant.reduce(
            (acc, curr) => acc + (curr.previousRetrievedGiftDrawCount ?? 0),
            0
        );
        return {
            totalWinningGifts,
            totalRetrievedGifts,
            totalWinningGiftsDifferenceWithPreviousPeriod: totalWinningGifts - previousTotalWinningGifts,
            totalRetrievedGiftsDifferenceWithPreviousPeriod: totalRetrievedGifts - previousTotalRetrievedGifts,
        };
    });
}
