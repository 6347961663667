<div class="flex h-full flex-col gap-y-6 overflow-y-auto py-4" #topOfComponent>
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }
    <div class="flex h-full flex-col gap-y-4 overflow-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        <div class="mb-4 flex gap-6 md:flex-col">
            <div class="min-w-0 flex-1">
                <app-statistics-seo-keywords-v3
                    (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.KEYWORDS, $event)"
                    (isLoadingEvent)="isKeywordsLoading.set($event)">
                </app-statistics-seo-keywords-v3>
            </div>
        </div>

        @if (isGmbInsightsChartsV2Enabled()) {
            <div class="min-w-0 flex-1">
                <app-gmb-insights
                    (isLoadingEvent)="isGmbInsightsLoading.set($event)"
                    (viewByChange)="onViewByChange($event.chart, $event.viewBy)"
                    (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange($event.chart, $event.hiddenDatasetIndexes)">
                </app-gmb-insights>
            </div>
        } @else {
            <div class="flex items-end gap-6 xl:flex-col xl:items-stretch">
                <div class="min-w-0 flex-1">
                    <app-statistics-seo-gmb-discoveries
                        (viewByChange)="onViewByChange(InsightsChart.APPARITIONS, $event)"
                        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.APPARITIONS, $event)"
                        (isLoadingEvent)="isImpressionsLoading.set($event)">
                    </app-statistics-seo-gmb-discoveries>
                </div>
                <div class="min-w-0 flex-1">
                    <app-statistics-seo-gmb-actions
                        (viewByChange)="onViewByChange(InsightsChart.ACTIONS, $event)"
                        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ACTIONS, $event)"
                        (isLoadingEvent)="isActionsLoading.set($event)">
                    </app-statistics-seo-gmb-actions>
                </div>
            </div>
        }
    </div>
</div>

<ng-template #filtersTemplate>
    <div class="flex items-center justify-between px-8.5 sm:flex-col sm:items-center md:px-0">
        <app-statistics-filters [platformFilterPage]="PlatformFilterPage.SEO"></app-statistics-filters>
        <button
            class="malou-btn-raised--primary !h-12 sm:mt-2"
            mat-raised-button
            [disabled]="isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
