import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, sum, uniq } from 'lodash';
import { DateTime, DateTimeUnit } from 'luxon';
import { combineLatest, Observable, shareReplay, startWith } from 'rxjs';
import { filter } from 'rxjs/operators';

import { getMonthsFromPeriod, NfcType, PlatformKey, WHEEL_OF_FORTUNE_PLATFORM_KEY } from '@malou-io/package-utils';

import { BoostersStatisticsData } from ':modules/statistics/boosters/boosters.interface';
import {
    ScanCountChartComponent,
    TotemData,
    TotemsData,
} from ':modules/statistics/boosters/scan-count/scan-count-chart/scan-count-chart.component';
import { StatisticsHttpErrorPipe } from ':modules/statistics/statistics-http-error.pipe';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { getDaysFromCurrentRange, getWeeksFromCurrentRange } from ':shared/helpers';
import { Nfc } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-statistics-boosters-scan-count',
    templateUrl: './scan-count.component.html',
    styleUrls: ['./scan-count.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        NumberEvolutionComponent,
        ScanCountChartComponent,
        SelectComponent,
        SkeletonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        StatisticsHttpErrorPipe,
    ],
    providers: [EnumTranslatePipe],
})
export class ScanCountComponent implements OnInit {
    @Input() viewBy?: ViewBy;
    @Input() hiddenDatasetIndexes: number[] = [];

    @Input() data$: Observable<BoostersStatisticsData>;
    @Input() isParentLoading = true;
    @Input() isParentError = false;

    @Output() viewByChange: EventEmitter<ViewBy> = new EventEmitter<ViewBy>();
    @Output() hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() readonly hasDataChange = new EventEmitter<boolean>(true);

    readonly Illustration = Illustration;
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;
    viewByControlValue$ = this.viewByControl.valueChanges.pipe(startWith(this.viewByControl.value), shareReplay(1));
    viewByControlValue: Signal<ViewBy> = toSignal(this.viewByControlValue$, { initialValue: ViewBy.DAY });
    totemsData: WritableSignal<TotemsData> = signal({});
    hasData: WritableSignal<boolean> = signal(true);
    scanCountOnPeriod: number | null = null;
    scanCountDifferenceWithPreviousPeriod: number | null = null;
    wheelOfFortuneCountOnPeriod: number | null = null;
    wheelOfFortuneCountDifferenceWithPreviousPeriod: number | null = null;

    wheelOfFortuneDisplayedText = this._translateService.instant('aggregated_statistics.boosters.scans.wheel_of_fortune');

    constructor(
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.viewBy) {
            this.viewByControl.setValue(this.viewBy);
        }
        combineLatest([this.data$, this.viewByControlValue$.pipe(filter((viewBy) => !!viewBy))]).subscribe(
            ([{ nfcs, scans, startDate, endDate }, viewBy]) => {
                let dateLabels: Date[] = [];

                switch (viewBy) {
                    case ViewBy.DAY:
                        dateLabels = getDaysFromCurrentRange(startDate, endDate);
                        break;
                    case ViewBy.WEEK:
                        dateLabels = getWeeksFromCurrentRange(startDate, endDate).map((week) => week.start);
                        break;
                    case ViewBy.MONTH:
                        dateLabels = getMonthsFromPeriod(startDate, endDate).map((month) => month.start);
                        break;
                }
                this.viewByChange.emit(viewBy);
                const dateTimeUnit = this._viewByToLuxonDateTimeUnit(viewBy);

                this.totemsData.set(
                    nfcs.reduce((acc, cur) => {
                        const nfcScans = scans.filter((scan) => scan.nfcId === cur.id);
                        const name = this._getTotemName(cur);
                        const scansForNfc = this._getChartDataForScans(nfcScans, dateLabels, dateTimeUnit);
                        const fullDisplayedName = this._addTypeToTotemName(name, cur, scansForNfc);
                        return {
                            ...acc,
                            [fullDisplayedName]: scansForNfc,
                        };
                    }, {})
                );
            }
        );

        this.data$.subscribe(({ scans, previousScans, wheelOfFortuneCount, previousWheelOfFortuneCount }) => {
            this.wheelOfFortuneCountOnPeriod = wheelOfFortuneCount;
            this.wheelOfFortuneCountDifferenceWithPreviousPeriod = this.wheelOfFortuneCountOnPeriod - previousWheelOfFortuneCount;
            this.scanCountOnPeriod = scans.length - wheelOfFortuneCount;
            this.scanCountDifferenceWithPreviousPeriod = this.scanCountOnPeriod - (previousScans.length - previousWheelOfFortuneCount);
            this.hasData.set(this.scanCountOnPeriod > 0 || this.wheelOfFortuneCountOnPeriod > 0);
            this.hasDataChange.emit(this.scanCountOnPeriod > 0);
        });
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');

    private _getChartDataForScans(nfcScans: ScanForStats[], dateLabels: Date[], dateTimeUnit: DateTimeUnit): TotemData {
        return dateLabels.map((date) => {
            const dateScans = nfcScans.filter((scan) =>
                DateTime.fromJSDate(date).hasSame(DateTime.fromJSDate(new Date(scan.scannedAt)), dateTimeUnit)
            );
            const platformKeysForDate = compact(uniq(dateScans.map((scan) => scan.nfcSnapshot?.statsPlatformKey)));
            const scanCountByPlatformKey = platformKeysForDate.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: dateScans.filter((scan) => scan.nfcSnapshot?.statsPlatformKey === cur).length,
                }),
                {} as Record<PlatformKey, number>
            );
            return {
                ...scanCountByPlatformKey,
                total: dateScans.length,
                date: date,
            };
        });
    }

    private _isWheelOfFortuneTotem(totem: Nfc): boolean {
        return totem.name === this.wheelOfFortuneDisplayedText;
    }

    private _getTotemName(totem: Nfc): string {
        const stickerLabel = this._enumTranslate.transform(NfcType.STICKER, 'nfc_type');
        if (!totem.isTotem()) {
            return stickerLabel;
        }
        const name = totem.name ?? totem.chipName;
        if (name && !this._isWheelOfFortuneTotem(totem)) {
            return `${this._translateService.instant('statistics.totems.totem')} ${totem.name ?? totem.chipName}`;
        } else if (!name) {
            return stickerLabel;
        }
        return name;
    }

    private _addTypeToTotemName(name: string, cur: Nfc, scansForNfc: TotemData): string {
        const total = sum(scansForNfc.map((scan) => scan.total ?? 0));
        if (total > 0 && !this._isWheelOfFortuneTotem(cur)) {
            const scansWithValue = scansForNfc.filter((scan) => !!scan.total);
            const type = Object.keys(scansWithValue[scansWithValue.length - 1]).includes(WHEEL_OF_FORTUNE_PLATFORM_KEY)
                ? this._translateService.instant('statistics.totems.wheel_of_fortune')
                : this._translateService.instant('statistics.totems.direct');
            return `${name} (${type})`;
        }
        return name;
    }

    private _viewByToLuxonDateTimeUnit(viewBy: ViewBy): DateTimeUnit {
        switch (viewBy) {
            case ViewBy.DAY:
                return 'day';
            case ViewBy.WEEK:
                return 'week';
            case ViewBy.MONTH:
                return 'month';
        }
    }
}
