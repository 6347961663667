import { NgClass } from '@angular/common';
import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { of, switchMap } from 'rxjs';

import { HeapEventName, SpecialHourNotificationAction } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { SpecialHourNotification } from ':core/components/notification-center/models/special-hour-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { SpecialHoursNotificationService } from ':core/components/notification-center/services/special-hours-notification.service';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-special-hour-popin',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, ApplyPurePipe, TranslateModule, NgClass],
    templateUrl: './special-hour-popin.component.html',
    styleUrl: './special-hour-popin.component.scss',
})
export class SpecialHourPopinComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly SpecialHourNotificationAction = SpecialHourNotificationAction;

    selectedOption: WritableSignal<SpecialHourNotificationAction> = signal(SpecialHourNotificationAction.NOTHING);

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { restaurant: Restaurant; notification: SpecialHourNotification },
        private readonly _dialogRef: MatDialogRef<SpecialHourPopinComponent>,
        private readonly _router: Router,
        private readonly _specialHoursNotificationService: SpecialHoursNotificationService,
        private readonly _notificationsService: NotificationService,
        private readonly _notificationCenterContext: NotificationCenterContext
    ) {}

    ngOnInit(): void {
        this._notificationCenterContext.trackNotification({
            heapEventName: HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_OPENED_POPIN,
            notificationId: this.data.notification.id,
            properties: {
                restaurantId: this.data.restaurant._id,
                eventName: this.data.notification.data.event.name.fr ?? '',
            },
        });
    }

    close(): void {
        this._notificationCenterContext.trackNotification({
            heapEventName: HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSE_POPIN,
            notificationId: this.data.notification.id,
            properties: {
                restaurantId: this.data.restaurant._id,
                eventName: this.data.notification.data.event.name.fr ?? '',
            },
        });
        this._dialogRef.close();
    }

    selectOption(option: SpecialHourNotificationAction): void {
        this.selectedOption.set(option);
    }

    navigateToInformation(): void {
        this._router.navigate(['/restaurants', this.data.restaurant._id, 'seo', 'informations'], {
            queryParams: { nid: this.data.notification.id, nchannel: this.data.notification.channel, popin: true },
        });
        this.close();
    }

    updateHours(): void {
        this._specialHoursNotificationService
            .updateSpecialHours$(this.data.restaurant, this.data.notification, this.selectedOption())
            .pipe(
                switchMap((result) =>
                    result
                        ? this._notificationsService.updateSpecialHourNotification(this.data.notification.id, {
                              action: this.selectedOption(),
                          })
                        : of(null)
                )
            )
            .subscribe({
                next: (notification) => {
                    if (notification) {
                        this._notificationCenterContext.updateNotification(notification);
                    }
                },
            });
        this._notificationsService
            .trackNotificationAction$({
                heapEventName:
                    this.selectedOption() === SpecialHourNotificationAction.CLOSED
                        ? HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_CLOSED_ACTION
                        : HeapEventName.NOTIFICATION_SPECIAL_HOUR_TRACKING_UPDATE_HOURS_ACTION,
                notificationId: this.data.notification.id,
                properties: {
                    restaurantId: this.data.restaurant._id,
                    eventName: this.data.notification.data.event.name.fr ?? '',
                },
            })
            .subscribe();
        this.close();
    }
}
