import { NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    inject,
    OnInit,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { catchError, combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { ReviewResponseDto } from '@malou-io/package-dto';
import { InsightsChart, InsightsTab, isNotNil } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { PrivateReviewsService } from ':core/services/private-reviews.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import {
    PrivateReviewStatisticsData,
    PrivateReviewStatisticsDataV2,
    WheelOfFortuneGiftsStatisticsData,
} from ':modules/aggregated-statistics/boosters/booster.interface';
import { BoostersStatisticsData, BoostersStatisticsDataV2 } from ':modules/statistics/boosters/boosters.interface';
import { PrivateReviewCountV2Component } from ':modules/statistics/boosters/private-review-count-v2/private-review-count.component';
import { PrivateReviewCountComponent } from ':modules/statistics/boosters/private-review-count/private-review-count.component';
import { ScanCountV2Component } from ':modules/statistics/boosters/scan-count-v2/scan-count.component';
import { ScanCountComponent } from ':modules/statistics/boosters/scan-count/scan-count.component';
import { BoostersDataFetchingServiceV2 } from ':modules/statistics/boosters/services/get-boosters-data-v2.service';
import { BoostersDataFetchingService } from ':modules/statistics/boosters/services/get-boosters-data.service';
import { TotemsEstimatedReviewCountV2Component } from ':modules/statistics/boosters/totems-estimated-review-count-v2/totems-estimated-review-count.component';
import { TotemsEstimatedReviewCountComponent } from ':modules/statistics/boosters/totems-estimated-review-count/totems-estimated-review-count.component';
import { WheelOfFortuneGiftsDistributionV2Component } from ':modules/statistics/boosters/wheel-of-fortune-gifts-distribution-v2/wheel-of-fortune-gifts-distribution.component';
import { WheelOfFortuneGiftsDistributionComponent } from ':modules/statistics/boosters/wheel-of-fortune-gifts-distribution/wheel-of-fortune-gifts-distribution.component';
import { WheelOfFortuneGiftsKpisV2Component } from ':modules/statistics/boosters/wheel-of-fortune-gifts-kpis-v2/wheel-of-fortune-gifts-kpis.component';
import { WheelOfFortuneGiftsKpisComponent } from ':modules/statistics/boosters/wheel-of-fortune-gifts-kpis/wheel-of-fortune-gifts-kpis.component';
import { FiltersComponent } from ':modules/statistics/filters/filters.component';
import { StatisticsFiltersContext } from ':modules/statistics/filters/filters.context';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import * as StatisticsSelectors from ':modules/statistics/store/statistics.selectors';
import { selectDatesFilter } from ':modules/statistics/store/statistics.selectors';
import {
    DownloadInsightsModalComponent,
    DownloadInsightsModalData,
} from ':shared/components/download-insights-modal/download-insights-modal.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { ViewBy } from ':shared/enums/view-by.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { DatesAndPeriod, LightNfc, Nfc, Restaurant } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-boosters',
    templateUrl: './boosters.component.html',
    styleUrls: ['./boosters.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        LazyLoadImageModule,
        TranslateModule,
        FiltersComponent,
        PrivateReviewCountComponent,
        ScanCountComponent,
        TotemsEstimatedReviewCountComponent,
        WheelOfFortuneGiftsDistributionComponent,
        WheelOfFortuneGiftsKpisComponent,
        MatButtonModule,
        ScanCountV2Component,
        TotemsEstimatedReviewCountV2Component,
        WheelOfFortuneGiftsKpisV2Component,
        WheelOfFortuneGiftsDistributionV2Component,
        PrivateReviewCountV2Component,
    ],
})
export class BoostersComponent implements AfterViewInit, OnInit {
    @ViewChild('topOfComponent') topOfComponent: ElementRef<HTMLElement>;

    private readonly _privateReviewsService = inject(PrivateReviewsService);
    private readonly _store = inject(Store);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _boostersDataFetchingService = inject(BoostersDataFetchingService);
    private readonly _statisticsFiltersContext = inject(StatisticsFiltersContext);
    private readonly _experimentationService = inject(ExperimentationService);
    private readonly _boostersDataFetchingServiceV2 = inject(BoostersDataFetchingServiceV2);
    public readonly screenSizeService = inject(ScreenSizeService);

    readonly isLoadingBoosters: WritableSignal<boolean> = signal(false);
    readonly isErrorBoosters: WritableSignal<boolean> = signal(false);
    readonly isLoadingGifts: WritableSignal<boolean> = signal(false);
    readonly isErrorGifts: WritableSignal<boolean> = signal(false);
    readonly isPrivateReviewsLoading: WritableSignal<boolean> = signal(false);
    readonly isPrivateReviewsError: WritableSignal<boolean> = signal(false);

    readonly isLoading = computed(() => this.isLoadingBoosters() || this.isLoadingGifts());

    readonly selectedLightTotems$: Observable<LightNfc[]> = this._statisticsFiltersContext.selectedLightTotems$;
    readonly selectedTotems$: Observable<Nfc[]> = this._statisticsFiltersContext.selectedTotems$;
    readonly dates$: Observable<DatesAndPeriod> = this._store.select(StatisticsSelectors.selectDatesFilter);

    // Old way

    data$: Observable<BoostersStatisticsData>;
    totemData$: Observable<BoostersStatisticsData>;
    wheelOfFortuneData$: Observable<BoostersStatisticsData>;
    giftsData$: Observable<WheelOfFortuneGiftsStatisticsData>;
    privateReviewData$: Observable<PrivateReviewStatisticsData>;

    // --------------------------------------------

    readonly boosterPackActivated = toSignal(
        this._restaurantsService.restaurantSelected$.pipe(
            filter(isNotNil),
            map((restaurant) => restaurant.boosterPack?.activated),
            takeUntilDestroyed(this._destroyRef)
        ),
        { initialValue: false }
    );

    readonly restaurantTotems: Signal<Nfc[]> = toSignal(this._statisticsFiltersContext.restaurantTotems$, {
        initialValue: [],
    });

    readonly restaurantLightTotems: Signal<LightNfc[]> = toSignal(this._statisticsFiltersContext.restaurantLightTotems$, {
        initialValue: [],
    });

    // Optimization with signals

    readonly boosterData: WritableSignal<BoostersStatisticsDataV2 | null> = signal(null);
    readonly boosterData$ = toObservable(this.boosterData);
    readonly estimatedReviewCountData = computed(() => {
        const boosterData = this.boosterData();
        if (!boosterData) {
            return {
                totemsData: null,
                wheelOfFortuneData: null,
            };
        }
        return {
            totemsData: {
                ...boosterData,
                scans: boosterData.scans.filter((scan) => !scan.isWheelOfFortuneRelated()),
                previousScans: boosterData.previousScans.filter((scan) => !scan.isWheelOfFortuneRelated()),
            },
            wheelOfFortuneData: {
                ...boosterData,
                scans: boosterData.scans.filter((scan) => scan.isWheelOfFortuneRelated()),
                previousScans: boosterData.previousScans.filter((scan) => scan.isWheelOfFortuneRelated()),
            },
        };
    });
    readonly giftsDataV2: WritableSignal<WheelOfFortuneGiftsStatisticsData | null> = signal(null);
    readonly privateReviewDataV2: WritableSignal<PrivateReviewStatisticsDataV2 | null> = signal(null);

    // --------------------------------------------
    readonly nfcIds: WritableSignal<string[]> = signal([]);
    readonly privateReviewCount: WritableSignal<number> = signal(0);
    readonly shouldDisplayWheelOfFortuneStats: WritableSignal<boolean> = signal(false);

    readonly shouldDisplayPrivateReviewsStats: Signal<boolean> = computed(
        () => this.restaurantTotems().length !== 0 && this.privateReviewCount() !== 0
    );

    // Chart options
    readonly InsightsChart = InsightsChart;
    readonly chartOptions: WritableSignal<ChartOptions> = signal({
        [InsightsChart.BOOSTERS_SCAN_COUNT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
        [InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION]: {
            tableSortOptions: undefined,
        },
        [InsightsChart.BOOSTERS_PRIVATE_REVIEWS_COUNT]: {
            viewBy: ViewBy.DAY,
            hiddenDatasetIndexes: [],
        },
    });

    // TODO: Remove when the feature flag is removed
    readonly isReleaseBoosterPerformanceImprovementsEnabled = toSignal(
        this._experimentationService.isFeatureEnabled$('release-booster-performance-improvements'),
        {
            initialValue: this._experimentationService.isFeatureEnabled('release-booster-performance-improvements'),
        }
    );

    ngOnInit(): void {
        this._experimentationService.isFeatureEnabled$('release-booster-performance-improvements').subscribe({
            next: (isFeatureEnabled) => {
                if (isFeatureEnabled) {
                    this._initBoosterData();
                    this._initGiftsData();
                    this._initPrivateReviewData();
                } else {
                    this._initData();
                }
            },
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() =>
            this.topOfComponent?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        );
    }

    // Data Init

    private _initBoosterData(): void {
        combineLatest([this.dates$, this.selectedLightTotems$, this._restaurantsService.restaurantSelected$])
            .pipe(
                debounceTime(500),
                filter(([dates]) => isDateSetOrGenericPeriod(dates) || (isNotNil(dates.startDate) && isNotNil(dates.endDate))),
                tap(() => {
                    this.isErrorBoosters.set(false);
                    this.isLoadingBoosters.set(true);
                }),
                switchMap(([dates, nfcs, _]: [DatesAndPeriod, LightNfc[], Restaurant]) => {
                    const data = this._boostersDataFetchingServiceV2.getChartsData(nfcs, dates);
                    this.onNfcChange(nfcs.map((nfc) => nfc.id));
                    return data;
                }),
                shareReplay(1)
            )
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: (data) => {
                    this.boosterData.set(data);
                    this._store.dispatch(StatisticsActions.editBoosterStatsDataV2({ data }));
                    this.isLoadingBoosters.set(false);
                },
                error: (error) => {
                    console.error('error >>', error);
                    this.isErrorBoosters.set(true);
                    this.isLoadingBoosters.set(false);
                },
            });
    }

    private _initGiftsData(): void {
        this.dates$
            .pipe(
                debounceTime(500),
                filter((dates) => isDateSetOrGenericPeriod(dates) && isNotNil(dates.startDate) && isNotNil(dates.endDate)),
                tap(() => {
                    this.isErrorGifts.set(false);
                    this.isLoadingGifts.set(true);
                }),
                switchMap((dates: DatesAndPeriod) => this._boostersDataFetchingService.getGiftsData(dates)),
                shareReplay(1),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (data: WheelOfFortuneGiftsStatisticsData) => {
                    this.giftsDataV2.set(data);
                    this.shouldDisplayWheelOfFortuneStats.set(!!data.giftsInsightsPerGift?.length);
                    this.isLoadingGifts.set(false);
                },
                error: (error) => {
                    console.error('error >>', error);
                    this.isErrorGifts.set(true);
                    this.isLoadingGifts.set(false);
                },
            });
    }

    private _initPrivateReviewData(): void {
        this.boosterData$
            .pipe(
                filter(isNotNil),
                tap(() => {
                    this.isPrivateReviewsError.set(false);
                    this.isPrivateReviewsLoading.set(true);
                }),
                switchMap(({ nfcs, scans, previousScans, startDate }) => {
                    const scanIds = [...scans.map((scan) => scan.id), ...previousScans.map((scan) => scan.id)];
                    const privateReviewsDtos$ = scanIds.length
                        ? this._privateReviewsService.search({ scanIds }).pipe(map((apiResult) => apiResult.data))
                        : of<ReviewResponseDto[]>([]);
                    const previousPrivateReviews$ = privateReviewsDtos$.pipe(
                        map((privateReviewDtos) =>
                            privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) < startDate)
                        )
                    );
                    const currentPrivateReviews$ = privateReviewsDtos$.pipe(
                        map((privateReviewDtos) =>
                            privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) >= startDate)
                        )
                    );
                    return forkJoin({
                        nfcs: of(nfcs),
                        scans: of(scans),
                        privateReviewsDto: currentPrivateReviews$,
                        previousPrivateReviewsDto: previousPrivateReviews$,
                    });
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe({
                next: (data) => {
                    this.privateReviewCount.set(data.privateReviewsDto.length);
                    this.privateReviewDataV2.set(data);
                    this.isPrivateReviewsLoading.set(false);
                },
                error: (error) => {
                    console.error('error >>', error);
                    this.isPrivateReviewsError.set(true);
                    this.isPrivateReviewsLoading.set(false);
                },
            });
    }

    // old version
    private _initData(): void {
        this.data$ = combineLatest([this.dates$, this.selectedTotems$, this._restaurantsService.restaurantSelected$])
            .pipe(
                debounceTime(500),
                tap(() => this.isLoadingBoosters.set(false)),
                filter(([dates]) => isDateSetOrGenericPeriod(dates)),
                tap(() => {
                    this.isErrorBoosters.set(false);
                    this.isLoadingBoosters.set(true);
                }),
                filter(([dates]) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
                switchMap(([dates, nfcs, _]: [DatesAndPeriod, Nfc[], Restaurant]) => {
                    const data = this._boostersDataFetchingService.getChartsData(nfcs, dates);
                    this.onNfcChange(nfcs.map((nfc) => nfc.id));
                    this.isLoadingBoosters.set(false);
                    return data;
                }),
                catchError((error) => {
                    console.error('error >>', error);
                    this.isErrorBoosters.set(true);
                    this.isLoadingBoosters.set(false);
                    return EMPTY;
                }),
                tap(() => setTimeout(() => this.isLoadingBoosters.set(false), 1000)),
                shareReplay(1)
            )
            .pipe(takeUntilDestroyed(this._destroyRef));

        this.totemData$ = this.data$.pipe(
            map((data) => {
                this._store.dispatch(StatisticsActions.editBoosterStatsData({ data }));
                return {
                    ...data,
                    scans: data.scans.filter((scan) => !scan.isWheelOfFortuneRelated()),
                    previousScans: data.previousScans.filter((scan) => !scan.isWheelOfFortuneRelated()),
                };
            }),
            takeUntilDestroyed(this._destroyRef)
        );

        this.giftsData$ = this.dates$.pipe(
            filter((dates) => isDateSetOrGenericPeriod(dates)),
            tap(() => {
                this.isErrorGifts.set(false);
                this.isLoadingGifts.set(true);
            }),
            debounceTime(500),
            filter((dates) => isNotNil(dates.startDate) && isNotNil(dates.endDate)),
            switchMap((dates: DatesAndPeriod) => {
                const data = this._boostersDataFetchingService.getGiftsData(dates);
                this.isLoadingGifts.set(false);
                return data;
            }),
            tap((giftData: WheelOfFortuneGiftsStatisticsData) => {
                this.shouldDisplayWheelOfFortuneStats.set(!!giftData.giftsInsightsPerGift?.length);
            }),
            catchError(() => {
                this.isErrorGifts.set(true);
                this.isLoadingGifts.set(false);
                return EMPTY;
            }),
            shareReplay(1),
            takeUntilDestroyed(this._destroyRef)
        );
        this.giftsData$.subscribe();

        this.wheelOfFortuneData$ = this.data$.pipe(
            map((data) => ({
                ...data,
                scans: data.scans.filter((scan) => scan.isWheelOfFortuneRelated()),
                previousScans: data.previousScans.filter((scan) => scan.isWheelOfFortuneRelated()),
            })),
            takeUntilDestroyed(this._destroyRef)
        );

        this.privateReviewData$ = this.data$.pipe(
            tap(() => {
                this.isPrivateReviewsError.set(false);
                this.isPrivateReviewsLoading.set(true);
            }),
            switchMap(({ nfcs, scans, previousScans, startDate }) => {
                const scanIds = [...scans.map((scan) => scan.id), ...previousScans.map((scan) => scan.id)];
                const privateReviewsDtos$ = scanIds.length
                    ? this._privateReviewsService.search({ scanIds }).pipe(map((apiResult) => apiResult.data))
                    : of<ReviewResponseDto[]>([]);
                const previousPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) < startDate)
                    )
                );
                const currentPrivateReviews$ = privateReviewsDtos$.pipe(
                    map((privateReviewDtos) =>
                        privateReviewDtos.filter((privateReviewDto) => new Date(privateReviewDto.socialCreatedAt) >= startDate)
                    )
                );
                return forkJoin({
                    nfcs: of(nfcs),
                    scans: of(scans),
                    privateReviewsDto: currentPrivateReviews$,
                    previousPrivateReviewsDto: previousPrivateReviews$,
                });
            }),
            tap(({ privateReviewsDto }) => {
                this.privateReviewCount.set(privateReviewsDto.length);
            }),
            catchError(() => {
                this.isPrivateReviewsError.set(true);
                this.isPrivateReviewsLoading.set(false);
                return EMPTY;
            }),
            tap(() => this.isPrivateReviewsLoading.set(false)),
            takeUntilDestroyed(this._destroyRef)
        );
        this.privateReviewData$.subscribe();
    }

    // Download insights
    openStatisticsDownload(): void {
        this._store
            .select(selectDatesFilter)
            .pipe(
                take(1),
                switchMap(({ startDate, endDate }) => {
                    if (!startDate || !endDate) {
                        this._toastService.openErrorToast(
                            this._translateService.instant('aggregated_statistics.download_insights_modal.please_select_dates')
                        );
                        return EMPTY;
                    }
                    return this._customDialogService
                        .open<DownloadInsightsModalComponent, DownloadInsightsModalData>(DownloadInsightsModalComponent, {
                            height: undefined,
                            data: {
                                tab: InsightsTab.BOOSTERS,
                                filters: {
                                    dates: { startDate, endDate },
                                    nfcIds: this.nfcIds(),
                                },
                                chartOptions: this.chartOptions(),
                            },
                        })
                        .afterClosed();
                })
            )
            .subscribe();
    }

    onViewByChange(chart: InsightsChart, value: ViewBy): void {
        this.chartOptions.update((option) => ({
            ...option,
            [chart]: {
                ...option[chart],
                viewBy: value,
            },
        }));
    }

    onHiddenDatasetIndexesChange(chart: InsightsChart, value: number[]): void {
        this.chartOptions.update((option) => ({
            ...option,
            [chart]: {
                ...option[chart],
                hiddenDatasetIndexes: value,
            },
        }));
    }

    onNfcChange(value: string[]): void {
        this.nfcIds.set(value);
    }

    onTableSortOptionsChange(chart: InsightsChart, value: Sort): void {
        this.chartOptions.update((options) => ({
            ...options,
            [chart]: {
                ...options[chart],
                tableSortOptions: value,
            },
        }));
    }
}
