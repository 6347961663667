import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
    FeedItemDto,
    GetSocialPostsCountsDto,
    ProgrammedSocialPostPlatformKeysByDateDto,
    SocialPostItemDto,
    SwapPlannedPublicationDatesPayloadDto,
} from '@malou-io/package-dto';
import { ApiResultV2, SocialPostsListFilter } from '@malou-io/package-utils';

import { environment } from ':environments/environment';
import { FeedItem } from ':modules/posts-v2/social-posts/models/feed-item';
import { objectToQueryParams } from ':shared/helpers/query-params';

@Injectable({
    providedIn: 'root',
})
export class SocialPostsV2Service {
    private readonly _http = inject(HttpClient);
    private readonly _API_BASE_URL = `${environment.APP_MALOU_API_URL}/api/v1/posts/v2`;

    getSocialPostsCountByFilterOptions$(restaurantId: string): Observable<{ filterOption: SocialPostsListFilter; count: number | null }[]> {
        return this._http
            .get<ApiResultV2<GetSocialPostsCountsDto>>(`${this._API_BASE_URL}/restaurants/${restaurantId}/social-posts-counts`)
            .pipe(
                map((res) => [
                    {
                        filterOption: SocialPostsListFilter.ALL,
                        count: res.data.total,
                    },
                    { filterOption: SocialPostsListFilter.DRAFT, count: res.data.draft },
                    { filterOption: SocialPostsListFilter.FEEDBACK, count: res.data.feedbacks },
                    { filterOption: SocialPostsListFilter.ERROR, count: res.data.error },
                ])
            );
    }

    getSocialPosts$(
        filter: SocialPostsListFilter,
        cursor: string | null,
        restaurantId: string,
        limit: number
    ): Observable<{ socialPostItems: SocialPostItemDto[]; nextCursor: string | null }> {
        const params = objectToQueryParams({ filter, cursor, limit });
        return this._http
            .get<
                ApiResultV2<{ socialPostItems: SocialPostItemDto[]; nextCursor: string | null }>
            >(`${this._API_BASE_URL}/restaurants/${restaurantId}/social-posts`, { params })
            .pipe(map((res) => res.data));
    }

    getFeed$(restaurantId: string, cursor: string | null, limit: number): Observable<{ feed: FeedItemDto[]; nextCursor: string }> {
        const params = objectToQueryParams({ cursor, limit });
        return this._http
            .get<
                ApiResultV2<{ feed: FeedItemDto[]; nextCursor: string }>
            >(`${this._API_BASE_URL}/restaurants/${restaurantId}/feed`, { params })
            .pipe(map((res) => res.data));
    }

    deleteSocialPost$(postId: string): Observable<{ success: boolean }> {
        return this._http.delete<ApiResultV2<{ success: boolean }>>(`${this._API_BASE_URL}/${postId}`).pipe(map((res) => res.data));
    }

    swapPlannedPublicationDates$(slicedFeed: FeedItem[], order: 1 | -1): Observable<{ success: boolean }> {
        const body = this._getReassignToSwapPlannedPublicationDate(slicedFeed, order);

        return this._http.put<ApiResultV2<void>>(`${this._API_BASE_URL}/swap-planned-publication-dates`, body).pipe(
            catchError((error) => {
                console.error(error);
                return of({ success: false });
            }),
            map(() => ({ success: true }))
        );
    }

    getProgrammedSocialPostPlatformKeysByDate$(restaurantId: string): Observable<ProgrammedSocialPostPlatformKeysByDateDto> {
        return this._http
            .get<
                ApiResultV2<ProgrammedSocialPostPlatformKeysByDateDto>
            >(`${this._API_BASE_URL}/restaurants/${restaurantId}/programmed-social-post-platform-keys-by-date`)
            .pipe(map((res) => res.data));
    }

    private _getReassignToSwapPlannedPublicationDate(feed: FeedItem[], order: 1 | -1): SwapPlannedPublicationDatesPayloadDto {
        const feedLength = feed.length;

        const reassign = feed.map((item, index) => {
            const sourceIndex = (index + order + feedLength) % feedLength;
            const sourcePostId = feed[sourceIndex]?.postId;
            if (!sourcePostId) {
                throw new Error(`Post not found for index ${sourceIndex}`);
            }
            return {
                sourcePostId,
                destinationPostId: item.postId,
            };
        });

        return { reassign };
    }
}
