<div class="malou-dialog">
    <div class="malou-dialog__header">
        @if (!data.client) {
            <p>{{ 'clients.add_manual.add_client' | translate }}</p>
        }
        @if (data.client) {
            <p>{{ 'clients.add_manual.update_client' | translate }}</p>
        }
        <button class="malou-btn-icon" mat-icon-button (click)="cancel()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <form class="flex flex-col gap-4" [formGroup]="clientForm">
            <div class="flex gap-4 md:flex-col">
                <div class="flex-1">
                    <app-select
                        formControlName="civility"
                        [required]="true"
                        [title]="'clients.add_manual.civility' | translate"
                        [placeholder]="'clients.add_manual.civility' | translate"
                        [values]="CIVILITY_TYPES"
                        [errorMessage]="clientForm.controls.civility.dirty && clientForm.controls.civility.errors?.error"
                        [displayWith]="displayWithSelectInput"></app-select>
                </div>
                <div class="flex-2">
                    <app-input-text
                        formControlName="firstName"
                        [required]="true"
                        [title]="'clients.add_manual.firstname' | translate"
                        [placeholder]="'clients.add_manual.firstname' | translate"
                        [errorMessage]="clientForm.controls.firstName.dirty && clientForm.controls.firstName.errors?.error">
                    </app-input-text>
                </div>
                <div class="flex-2">
                    <app-input-text
                        formControlName="lastName"
                        [required]="true"
                        [title]="'clients.add_manual.lastname' | translate"
                        [placeholder]="'clients.add_manual.lastname' | translate"
                        [errorMessage]="clientForm.controls.lastName.dirty && clientForm.controls.lastName.errors?.error">
                    </app-input-text>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1">
                    <app-input-text
                        formControlName="email"
                        [title]="'clients.add_manual.email' | translate"
                        [placeholder]="'clients.add_manual.email' | translate"
                        [errorMessage]="
                            clientForm.controls.email.dirty &&
                            (clientForm.controls.email.errors?.error || clientForm.errors?.ref_email_phone)
                        ">
                    </app-input-text>
                </div>
            </div>

            <div class="flex gap-4 md:flex-col" formGroupName="phone">
                <div class="flex-1">
                    <app-select
                        formControlName="prefix"
                        [title]="'clients.add_manual.phone' | translate"
                        [placeholder]="'clients.add_manual.phone' | translate"
                        [values]="PHONE_CODES"
                        [errorMessage]="
                            clientForm.controls.phone['controls'].prefix?.dirty &&
                            clientForm.controls.phone['controls'].prefix?.errors?.error
                        "
                        [displayWith]="displayWithPhone"></app-select>
                </div>
                <div class="flex flex-3">
                    <app-input-text
                        class="w-full"
                        formControlName="digits"
                        [title]="'&nbsp;'"
                        [placeholder]="'clients.add_manual.phone_placeholder' | translate"
                        [errorMessage]="
                            clientForm.controls.phone['controls'].digits?.dirty &&
                            (clientForm.controls.phone['controls'].digits?.errors?.error || clientForm.errors?.ref_email_phone)
                        "></app-input-text>
                </div>
            </div>

            <div class="flex gap-4">
                <div class="flex-1">
                    <app-input-date-picker
                        formControlName="lastVisitedAt"
                        [required]="true"
                        [title]="'clients.add_manual.last_visit_date' | translate"
                        [errorMessage]="
                            clientForm.controls.lastVisitedAt.dirty && clientForm.controls.lastVisitedAt.errors?.error
                        "></app-input-date-picker>
                </div>
            </div>

            <div class="flex">
                <div class="flex-1">
                    <app-input-google-maps-autocomplete
                        [title]="'clients.add_manual.address' | translate"
                        [placeholder]="'clients.add_manual.address' | translate"
                        [formControl]="addressControl"
                        (locationSelected)="selectedAddress($event)">
                    </app-input-google-maps-autocomplete>
                </div>
            </div>

            <div class="flex gap-4 md:flex-col md:gap-2">
                <app-select
                    formControlName="language"
                    [values]="LANGS"
                    [required]="true"
                    [title]="'clients.add_manual.language' | translate"
                    [errorMessage]="clientForm.controls.language.dirty && clientForm.controls.language.errors?.error"
                    [displayWith]="displayWithSelectInput">
                </app-select>

                <app-input-text
                    formControlName="visitCount"
                    inputType="number"
                    placeholder="--"
                    [title]="'clients.add_manual.visit_count_title' | translate"
                    [min]="0"></app-input-text>
            </div>

            <div class="flex flex-col">
                <mat-checkbox class="flex items-center" color="primary" formControlName="acceptsEmails">
                    <span class="malou-text-12--regular flex items-center">
                        {{ 'clients.add_manual.accepts_emails' | translate }}
                    </span>
                </mat-checkbox>
                <mat-checkbox class="flex items-center" color="primary" formControlName="acceptsSms">
                    <span class="malou-text-12--regular flex items-center">
                        {{ 'clients.add_manual.accepts_sms' | translate }}
                    </span>
                </mat-checkbox>
            </div>
        </form>
    </div>

    <div class="malou-dialog__footer flex-wrap">
        <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="cancel()">
            {{ 'common.cancel' | translate }}
        </button>
        @if (!data.client) {
            <app-button
                class="md:!hidden"
                buttonClasses="!h-11"
                [disabled]="clientForm.invalid"
                [text]="'clients.add_manual.other' | translate"
                [loading]="isUpsertingClient()"
                (buttonClick)="submit()"></app-button>
        }
        <app-button
            buttonClasses="!h-11"
            [disabled]="clientForm.invalid"
            [text]="(data.client ? 'clients.add_manual.update' : 'clients.add_manual.validate') | translate"
            [loading]="isUpsertingClient()"
            (buttonClick)="submit(false)"></app-button>
    </div>
</div>
