import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { xor } from 'lodash';
import { combineLatest, interval, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { PlatformDefinitions } from '@malou-io/package-utils';

import { periodOptions } from ':core/constants';
import { DialogService } from ':core/services/dialog.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { AnswerCommentModalComponent } from ':modules/comments/answer-comment-modal/answer-comment-modal.component';
import { CommentsListViewComponent } from ':modules/comments/comments-list-view/comments-list-view.component';
import { CommentsPostsViewComponent } from ':modules/comments/comments-posts-view/comments-posts-view.component';
import { ToogleableFiltersKey } from ':modules/comments/comments.interface';
import { CommentsService } from ':modules/comments/comments.service';
import * as CommentsActions from ':modules/comments/store/comments.actions';
import { selectCurrentPlatforms } from ':modules/platforms/store/platforms.reducer';
import { NoConnectedPlatformsComponent } from ':shared/components-v3/no-connected-platforms/no-connected-platforms.component';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { KillSubscriptions } from ':shared/interfaces';
import { AvailablePlatform, CommentsFilters, FetchedState, MalouPeriod, Platform, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';
import { HeapEmailEventsTrackerService } from ':shared/services/heap-email-events-tracker.service';

import {
    selectCommentCount,
    selectCommentsFilters,
    selectMentionCount,
    selectUnansweredCommentCount,
    selectUnansweredMentionCount,
} from './store/comments.reducer';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    standalone: true,
    imports: [
        MatButtonToggleModule,
        MatRippleModule,
        TranslateModule,
        NoConnectedPlatformsComponent,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        CommentsListViewComponent,
        CommentsPostsViewComponent,
        MatMenuModule,
        MatTooltipModule,
        SearchComponent,
        SortByFiltersComponent,
        MatButtonToggleModule,
        GroupedDateFiltersComponent,
        PlatformLogoComponent,
        AsyncPipe,
        ApplyPurePipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class CommentsComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject();
    updateStatusSubscription: Subscription;

    restaurant$ = this._restaurantsService.restaurantSelected$;

    filters$: Observable<CommentsFilters> = this._store
        .select(selectCommentsFilters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    filters: CommentsFilters;
    filterCount = 0;
    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'date', label: this._translate.instant('moderation.filter_label.date') },
        { key: 'platform', label: this._translate.instant('moderation.filter_label.platform') },
    ];

    currentPlatforms$: Observable<Platform[]> = this._store.select(selectCurrentPlatforms);
    availablePlatforms$ = this.getAvailablePlatforms$();
    availablePlatforms: AvailablePlatform[];
    hasConnectedPlatform = false;

    isPostView = false;
    shouldShowAdvancedOptions = false;
    isUpdating = true;

    readonly unansweredCommentCount$: Observable<number> = this._store.select(selectUnansweredCommentCount);
    readonly unansweredMentionCount$: Observable<number> = this._store.select(selectUnansweredMentionCount);
    unansweredCommentCount = 0;
    unansweredMentionCount = 0;

    readonly commentCount$ = this._store.select(selectCommentCount);
    readonly mentionCount$ = this._store.select(selectMentionCount);

    readonly periodOptions = periodOptions.map((period) => period.key);

    constructor(
        private readonly _commentsService: CommentsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _translate: TranslateService,
        private readonly _activeRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _customDialogService: CustomDialogService,
        public screenService: ScreenSizeService,
        private readonly _heapEmailEventsTrackerService: HeapEmailEventsTrackerService
    ) {}

    ngOnInit(): void {
        this._heapEmailEventsTrackerService.trackEmailEvent();
        // When we first determine available platforms, set filters to enable all of them
        this.availablePlatforms$.pipe(take(1)).subscribe({
            next: (platforms) => {
                this.editCommentsFilters({ platforms: platforms.map((p) => p.key) });
                this.isUpdating = false;
            },
        });

        // Every time available platforms change, update platforms list and hasConnectedPlatform
        this.availablePlatforms$.subscribe({
            next: (platforms) => {
                this.availablePlatforms = platforms.filter((p) => p.connected);
                this.hasConnectedPlatform = platforms.some((p) => p.connected);
            },
        });

        this.unansweredCommentCount$.subscribe({
            next: (newUnansweredCommentCount) => (this.unansweredCommentCount = newUnansweredCommentCount),
        });

        this.unansweredMentionCount$.subscribe({
            next: (newUnansweredMentionCount) => (this.unansweredMentionCount = newUnansweredMentionCount),
        });

        this.filters$.subscribe({
            next: (newFilters) => {
                this.filterCount = this.computeFilterCount(newFilters);
                this.filters = newFilters;
            },
        });

        // if active route contains a query param "comment_id", we want to open the comment in the modal
        this._activeRoute.queryParams.subscribe({
            next: (params) => {
                if (params.comment_id || params.mention_id || params.post_social_id) {
                    this.openCommentModal({
                        commentId: params.comment_id,
                        pageSize: params.page_size,
                        mentionId: params.mention_id,
                        fromPosts: params.from_posts === 'true',
                        postSocialId: params.post_social_id,
                    });
                }
            },
        });
    }

    openCommentModal({
        commentId,
        pageSize,
        mentionId,
        fromPosts,
        postSocialId,
    }: {
        commentId?: string;
        pageSize?: string;
        mentionId?: string;
        fromPosts?: boolean;
        postSocialId: string;
    }): void {
        this._customDialogService
            .open(
                AnswerCommentModalComponent,
                {
                    width: '100%',
                    height: undefined,
                    panelClass: 'malou-dialog-panel--full',
                    data: {
                        commentId,
                        pageSize,
                        mentionId,
                        fromPosts,
                        postSocialId,
                        notificationId: this._activeRoute.snapshot.queryParams.nid,
                        notificationChannel: this._activeRoute.snapshot.queryParams.nchannel,
                    },
                },
                { animateScreenSize: DialogScreenSize.ALL }
            )
            .afterClosed()
            .subscribe({
                next: () => {
                    this._activeRoute.queryParams.pipe(take(1)).subscribe({
                        next: (params) => {
                            this._router.navigate([], {
                                relativeTo: this._activeRoute,
                                queryParams: {
                                    ...params,
                                    comment_id: null,
                                    page_size: null,
                                    mention_id: null,
                                    post_social_id: null,
                                    from_posts: null,
                                },
                                queryParamsHandling: 'merge',
                            });
                        },
                    });
                    this._commentsService.reload();
                },
            });
    }

    switchMode(): void {
        this.isPostView = !this.isPostView;
        this._restaurantsService.reloadSelectedRestaurant();
    }

    startUpdateComments(): void {
        this.isUpdating = true;
        const sub = this.restaurant$
            .pipe(
                filter(Boolean),
                switchMap((restaurant: Restaurant) => this._commentsService.synchronizeRestaurantComments(restaurant._id)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: () => {
                    this.startStatusWatcher();
                    sub.unsubscribe();
                },
                error: (err) => {
                    this._dialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._translate.instant('moderation.error_synchronize_comments'),
                        message: new HttpErrorPipe(this._translate).transform(err),
                        primaryButton: {
                            label: this._translate.instant('common.close'),
                        },
                    });
                    this.isUpdating = false;
                    sub.unsubscribe();
                },
            });
    }

    startStatusWatcher(): void {
        this.updateStatusSubscription = interval(1000)
            .pipe(
                switchMap(() => this._restaurantsService.restaurantSelected$),
                filter(Boolean),
                switchMap((restaurant: Restaurant) => this._restaurantsService.show(restaurant._id).pipe(map((res) => res.data))),
                map((restaurant) => restaurant?.currentState?.comments?.fetched),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (status) => {
                    const hasFinished = this.hasFinished(status);
                    this.isUpdating = !hasFinished;
                    if (hasFinished) {
                        this.updateFinished();
                    }
                },
            });
    }

    updateFinished(): void {
        this.updateStatusSubscription?.unsubscribe();
        const endSub$ = new Subject();
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(Boolean),
                switchMap((restaurant: Restaurant) => this._restaurantsService.update(restaurant._id, { commentsLastUpdate: new Date() })),
                takeUntil(endSub$)
            )
            .subscribe({
                next: () => {
                    endSub$.next(true);
                    this._restaurantsService.reloadSelectedRestaurant();
                },
                error: (err) => {
                    this._dialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._translate.instant('moderation.error_synchronize_comments'),
                        message: new HttpErrorPipe(this._translate).transform(err),
                        primaryButton: {
                            label: this._translate.instant('common.close'),
                        },
                    });
                    this.isUpdating = false;
                    endSub$.next(true);
                },
            });
    }

    hasFinished(status: { [key: string]: FetchedState<string> }): boolean {
        if (!status) {
            return true;
        }
        if (
            Object.values(status)
                .map((sts) => sts.status)
                .includes('pending')
        ) {
            return false;
        }
        return true;
    }

    clearFilters(): void {
        this._store.dispatch({
            type: CommentsActions.resetCommentsFilters.type,
            availablePlatforms: this.availablePlatforms.map((p) => p.key),
        });
    }

    sortedBy(value: string): void {
        const filters = {
            sortBy: value,
        };
        this._store.dispatch({ type: CommentsActions.editCommentsFilters.type, filters });
    }

    computeFilterCount(newFilters: CommentsFilters): number {
        const isDefaultPeriod = newFilters.period === MalouPeriod.ALL;
        const isDefaultPlatforms = this.availablePlatforms.every((p) => p.checked);
        const isDefaultStatus = newFilters.answered === true && newFilters.notAnswered === true;
        const isDefaultTaggedIn = newFilters.isComment === true && newFilters.isMention === true;
        const isDefaultOptions = newFilters.archived === false && newFilters.withoutOwnComment === true;

        return [!isDefaultPeriod, !isDefaultPlatforms, !isDefaultStatus, !isDefaultOptions, !isDefaultTaggedIn].filter(Boolean).length;
    }

    getAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return combineLatest([this.currentPlatforms$, this.filters$.pipe(map((filters) => filters.platforms))]).pipe(
            filter(([platforms]) => !!platforms),
            map(([platforms, platformsFilters]) => {
                const commentsPlatforms = PlatformDefinitions.getPlatformKeysWithComment();
                const connectedPlatforms = platforms.map((plat) => plat.key);
                return commentsPlatforms.map((p) => ({
                    key: p,
                    connected: connectedPlatforms.includes(p),
                    checked: !!platformsFilters?.includes(p),
                }));
            }),
            takeUntil(this.killSubscriptions$)
        );
    }

    getRefreshButtonTooltip = (hasConnectedPlatform: boolean): string => {
        if (!hasConnectedPlatform) {
            return this._translate.instant('moderation.please_connect_platforms_short');
        }
        return this._translate.instant('common.synchronize');
    };

    editCommentsFilters(filterDiffs: Partial<CommentsFilters>): void {
        this._store.dispatch({ type: CommentsActions.editCommentsFilters.type, filterDiffs });
    }

    toggleFilter(filterKey: ToogleableFiltersKey): void {
        const currentValue = this.filters[filterKey];
        this.editCommentsFilters({ [filterKey]: !currentValue });
    }

    toggleSelectedPlatforms(platform: AvailablePlatform): void {
        const platforms = xor(this.filters.platforms, [platform.key]);
        this.editCommentsFilters({ platforms });
    }

    onSortByChange(sortBy: string): void {
        this.editCommentsFilters({ sortBy });
    }

    onSortOrderChange(): void {
        this.editCommentsFilters({ sortOrder: this.filters.sortOrder ? this.filters.sortOrder * -1 : undefined });
    }

    onSearchChange(text: string): void {
        if (text.length >= 3 || (text.length === 0 && text !== this.filters.text)) {
            this.editCommentsFilters({ text });
        }
    }
}
