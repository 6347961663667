import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, PostedStatus } from '@malou-io/package-utils';

import { NotificationItemComponent } from ':core/components/notification-center/components/notification-item/notification-item.component';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import {
    NegativeReviewReminderNotification,
    NotificationReview,
} from ':core/components/notification-center/models/negative-review-reminder-notification.model';
import { NotificationService } from ':core/components/notification-center/services/notifications.service';
import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { HeapService } from ':core/services/heap.service';
import { ToastService } from ':core/services/toast.service';
import { ReviewsService } from ':modules/reviews/reviews.service';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { ReviewReply } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

@Component({
    selector: 'app-negative-review-reminder-notification-item',
    templateUrl: './negative-review-reminder-notification-item.component.html',
    styleUrl: './negative-review-reminder-notification-item.component.scss',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslateModule,
        DatePipe,
        PlatformLogoComponent,
        StarGaugeComponent,
        ShortTextPipe,
        MatButtonModule,
        MalouSpinnerComponent,
        MatIconModule,
        NgTemplateOutlet,
    ],
})
export class NegativeReviewReminderNotificationItemComponent extends NotificationItemComponent implements OnInit {
    private readonly _router = inject(Router);
    private readonly _reviewService = inject(ReviewsService);
    private readonly _toastService = inject(ToastService);
    private readonly _notificationService = inject(NotificationService);
    private readonly _notificationsContext = inject(NotificationCenterContext);
    private readonly _heapService = inject(HeapService);
    readonly notification = computed(() => this.initialNotification() as NegativeReviewReminderNotification);

    readonly isFolded = signal(true);
    readonly answeringReview = signal(false);

    readonly review = computed((): NotificationReview | undefined => this.notification()?.getMainReviewToNotify());

    readonly restaurantName = computed(() => this.notification()?.getMainRestaurantName());

    readonly SHORT_TEXT_LENGTH = 100;
    readonly SvgIcon = SvgIcon;

    ngOnInit(): void {
        if (!this.notification().data.suggestedReply) {
            this._notificationService.initReviewReplyReminderNotification({ notificationId: this.notification().id }).subscribe({
                next: (notification) => {
                    this.updateNotification(notification);
                },
            });
        }
    }

    toggleFolded(): void {
        this.isFolded.update((old) => !old);
    }

    onNotificationClick(): void {
        this._notificationsContext.trackNotification({
            notificationId: this.notification().id,
            heapEventName: HeapEventName.NOTIFICATION_NEGATIVE_REVIEW_REMINDER_TRACKING_WEB_REDIRECT,
            properties: {
                restaurantId: this.notification().getMainRestaurantId(),
                notificationType: this.notification()?.type,
            },
        });
        this.markAsRead();
        this._navigateToReviewsPage();
        this.notificationCenterContext.close();
    }

    sendReply(): void {
        const review = this.review();
        if (!review) {
            return;
        }
        this.answeringReview.set(true);
        this._reviewService
            .postReviewComment(
                review.id,
                new ReviewReply({
                    posted: PostedStatus.PENDING,
                    text: this.notification().data.suggestedReply,
                    isRepliedFromAggregatedView: false,
                }),
                this.notification().getMainRestaurantId()
            )
            .subscribe({
                next: () => {
                    this.notificationCenterContext.markNotificationAsCompleted(this.notification());
                    this.answeringReview.set(false);
                    this._heapService.track(HeapEventName.REVIEW_REPLIED, {
                        restaurantId: this.notification().getMainRestaurantId(),
                        cameFromNotificationId: this.notification().id,
                        notificationChannel: this.notification().channel,
                    });
                },
                error: (err) => {
                    this.answeringReview.set(false);
                    this._showReplyErrorToast(err);
                },
            });
    }

    private _navigateToReviewsPage(): void {
        const review = this.review();
        if (!review) {
            return;
        }
        this._router.navigate(['restaurants', this.notification().getMainRestaurantId(), 'reputation', 'reviews'], {
            queryParams: { reviewId: review.id, nid: this.notification().id },
        });
    }

    private _showReplyErrorToast(err): void {
        const errMessage = err?.error?.message || '';
        this._toastService.openErrorToast(errMessage);
    }
}
