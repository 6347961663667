import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';

import { ScanForRestaurantInsightsDto } from '@malou-io/package-dto';
import { PlatformKey } from '@malou-io/package-utils';

import { GiftDrawsService } from ':core/services/gift-draws.service';
import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScansService } from ':core/services/scans.service';
import { WheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { BoostersStatisticsDataV2 } from ':modules/statistics/boosters/boosters.interface';
import { MAX_TOTEM_NUMBER_FOR_FILTER } from ':shared/constants/filters';
import { DatesAndPeriod, FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, LightNfc, Nfc } from ':shared/models';
import { ScanForRestaurantInsights } from ':shared/models/scan';

@Injectable({
    providedIn: 'root',
})
export class BoostersDataFetchingServiceV2 {
    private readonly _translateService = inject(TranslateService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _scansService = inject(ScansService);
    private readonly _nfcService = inject(NfcService);
    private readonly _giftDrawsService = inject(GiftDrawsService);

    getTotems(): Observable<Nfc[]> {
        return this._nfcService
            .search({ page: 1, limit: MAX_TOTEM_NUMBER_FOR_FILTER, restaurantId: this._restaurantsService.currentRestaurant._id })
            .pipe(map((apiResult) => apiResult.data.map((nfc) => Nfc.fromNfcDto(nfc))));
    }

    getChartsData(nfcs: LightNfc[], dates: DatesAndPeriod): Observable<BoostersStatisticsDataV2> {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const scanForRestaurantInsights$: Observable<ScanForRestaurantInsightsDto> = this._scansService
            .getInsightsForRestaurant(this._restaurantsService.currentRestaurant._id, {
                nfcIds: nfcs?.map((nfc) => nfc.id).concat([FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS]) ?? [],
                startScannedAt: startDate.toISOString(),
                endScannedAt: endDate.toISOString(),
            })
            .pipe(
                map((apiResult) => ({
                    scans: apiResult.data.scans,
                    previousScans: apiResult.data.previousScans,
                })),
                shareReplay(1)
            );

        const wheelOfFortuneFakeNfc = Nfc.createLightFakeWheelOfFortuneNfc({
            restaurantId: this._restaurantsService.currentRestaurant._id,
            platformKey: PlatformKey.GMB,
            name: this._translateService.instant('statistics.totems.scan_count.wheel_of_fortune'),
        });

        const scans$ = scanForRestaurantInsights$.pipe(map(({ scans }) => scans.map((scan) => new ScanForRestaurantInsights(scan))));
        const previousScans$ = scanForRestaurantInsights$.pipe(
            map(({ previousScans }) => previousScans.map((scan) => new ScanForRestaurantInsights(scan)))
        );

        const wheelOfFortuneCount$ = scans$.pipe(map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length));
        const previousWheelOfFortuneCount$ = previousScans$.pipe(
            map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length)
        );

        return forkJoin({
            nfcs: of((nfcs ?? []).concat([wheelOfFortuneFakeNfc])),
            scans: scans$,
            previousScans: previousScans$,
            wheelOfFortuneCount: wheelOfFortuneCount$,
            previousWheelOfFortuneCount: previousWheelOfFortuneCount$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    }

    getGiftsData(dates: DatesAndPeriod): Observable<WheelOfFortuneGiftsStatisticsData> {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const giftsInsightsPerGift$ = this._giftDrawsService
            .getInsightsByGifts(this._restaurantsService.currentRestaurant._id, startDate, endDate)
            .pipe(map((res) => res?.data));

        return forkJoin({
            giftsInsightsPerGift: giftsInsightsPerGift$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    }
}
