<div class="malou-dialog m-0 w-full">
    <div class="malou-dialog__header justify-end--forced flex">
        <mat-icon class="cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>

    @if (data.attachments[currentIndex]) {
        <div class="malou-dialog__body text-center" style="padding: 30px">
            <div class="flex items-center justify-between">
                <mat-icon
                    class="mr-2"
                    [svgIcon]="SvgIcon.CHEVRON_LEFT"
                    [ngClass]="{
                        '!fill-malou-color-primary--disabled': currentIndex === 0,
                        'cursor-pointer': currentIndex > 0,
                    }"
                    (click)="previousMedia()"></mat-icon>
                <div class="flex flex-col" style="width: min-content">
                    <div class="flex justify-center">
                        @if (data.attachments[currentIndex].type === SocialAttachmentsMediaTypes.IMAGE) {
                            <img class="media-dimensions" [lazyLoad]="data.attachments[currentIndex].urls.original" [alt]="" />
                        }
                        @if (data.attachments[currentIndex].type === SocialAttachmentsMediaTypes.VIDEO) {
                            <video
                                class="media-dimensions"
                                type="video/mp4"
                                controls
                                muted
                                autoplay
                                [src]="data.attachments[currentIndex].urls.original"></video>
                        }
                        @if (
                            data.attachments[currentIndex].type !== SocialAttachmentsMediaTypes.IMAGE &&
                            data.attachments[currentIndex].type !== SocialAttachmentsMediaTypes.VIDEO
                        ) {
                            <img class="media-dimensions" [lazyLoad]="'default-picture-grey' | imagePathResolver" />
                        }
                    </div>
                </div>
                <mat-icon
                    class="ml-2"
                    [svgIcon]="SvgIcon.CHEVRON_RIGHT"
                    [ngClass]="{
                        '!fill-malou-color-primary--disabled': currentIndex === data.attachments.length - 1,
                        'cursor-pointer': currentIndex < data.attachments.length - 1,
                    }"
                    (click)="nextMedia()"></mat-icon>
            </div>
        </div>
    }
</div>
