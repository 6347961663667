import { CaslRole } from '../../../constants';
import { CaslAction, CaslSubject } from '../casl.enum';
import { userCaslPermissions, UserPermission, UserPermissionArgs } from '../permissions';

export const userAbilities: Record<CaslRole, (user: UserPermissionArgs, { can }) => any> = {
    [CaslRole.ADMIN]: (_user: UserPermissionArgs, { can }) => {
        can(CaslAction.MANAGE, CaslSubject.ALL);
    },
    [CaslRole.OWNER]: (user: UserPermissionArgs, { can }) => {
        can(...userCaslPermissions(UserPermission.MANAGE_ORGANIZATION_USER_RESTAURANT, user));
        can(...userCaslPermissions(UserPermission.CREATE_ORGANIZATION_USER, user));
        can(...userCaslPermissions(UserPermission.MANAGE_ORGANIZATION_USER_CASL_ROLE, user));
        can(...userCaslPermissions(UserPermission.CREATE_ORGANIZATION_RESTAURANT, user));
        can(...userCaslPermissions(UserPermission.MANAGE_ORGANIZATION_CREDENTIALS, user));
        can(...userCaslPermissions(UserPermission.DELETE_ORGANIZATION_USER, user));
    },
    [CaslRole.EDITOR]: (_user: UserPermissionArgs) => {
        // No additional permissions
    },
    [CaslRole.MODERATOR]: (_user: UserPermissionArgs) => {
        // No additional permissions
    },
    [CaslRole.GUEST]: (_user: UserPermissionArgs) => {
        // No additional permissions
    },
};
