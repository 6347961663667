<div class="flex h-full flex-col gap-y-4 overflow-y-auto py-4" #topOfComponent>
    @if (!screenSizeService.isPhoneScreen) {
        <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    }
    <div class="flex h-full flex-col gap-y-4 overflow-y-scroll px-8.5">
        @if (screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
        @if (isReleaseBoosterPerformanceImprovementsEnabled()) {
            <div class="flex h-full w-full gap-4 sm:h-fit sm:flex-col">
                <div class="min-w-[49%] flex-1">
                    <app-statistics-boosters-scan-count-v2
                        [data]="boosterData()"
                        [isParentLoading]="isLoadingBoosters()"
                        [isParentError]="isErrorBoosters()"
                        (viewByChange)="onViewByChange(InsightsChart.BOOSTERS_SCAN_COUNT, $event)"
                        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.BOOSTERS_SCAN_COUNT, $event)">
                    </app-statistics-boosters-scan-count-v2>
                </div>
                <div class="min-w-[49%] flex-1">
                    <app-statistics-totems-estimated-review-count-v2
                        [estimatedReviewCountData]="estimatedReviewCountData()"
                        [isParentLoading]="isLoadingBoosters()"
                        [isParentError]="isErrorBoosters()"
                        (hiddenDatasetIndexesChange)="
                            onHiddenDatasetIndexesChange(InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT, $event)
                        ">
                    </app-statistics-totems-estimated-review-count-v2>
                </div>
            </div>
        } @else {
            <div class="flex h-full w-full gap-4 sm:h-fit sm:flex-col">
                <div class="min-w-[49%] flex-1">
                    <app-statistics-boosters-scan-count
                        [data$]="data$"
                        [isParentLoading]="isLoadingBoosters()"
                        [isParentError]="isErrorBoosters()"
                        (viewByChange)="onViewByChange(InsightsChart.BOOSTERS_SCAN_COUNT, $event)"
                        (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.BOOSTERS_SCAN_COUNT, $event)">
                    </app-statistics-boosters-scan-count>
                </div>
                <div class="min-w-[49%] flex-1">
                    <app-statistics-totems-estimated-review-count
                        [totemData$]="totemData$"
                        [wheelOfFortuneData$]="wheelOfFortuneData$"
                        [isParentLoading]="isLoadingBoosters()"
                        [isParentError]="isErrorBoosters()"
                        (hiddenDatasetIndexesChange)="
                            onHiddenDatasetIndexesChange(InsightsChart.BOOSTERS_TOTEMS_ESTIMATED_REVIEWS_COUNT, $event)
                        ">
                    </app-statistics-totems-estimated-review-count>
                </div>
            </div>
        }
        @if (shouldDisplayWheelOfFortuneStats()) {
            <ng-container [ngTemplateOutlet]="wheelOfFortuneStatisticsTemplate"></ng-container>
        }

        @if (shouldDisplayPrivateReviewsStats()) {
            <ng-container [ngTemplateOutlet]="totemsStatisticsTemplate"></ng-container>
        }
    </div>
</div>

<ng-template #totemsStatisticsTemplate>
    <div class="malou-text-18--bold mt-3 text-malou-color-text-1">{{ 'statistics.totems.totems' | translate }}</div>
    <div class="flex gap-4 md:flex-col">
        <div class="min-w-0 flex-1">
            @if (isReleaseBoosterPerformanceImprovementsEnabled()) {
                <app-statistics-totems-private-review-count-v2
                    [data]="privateReviewDataV2()"
                    [isParentLoading]="isLoadingBoosters() || isPrivateReviewsLoading()"
                    [isParentError]="isErrorBoosters() || isPrivateReviewsError()"></app-statistics-totems-private-review-count-v2>
            } @else {
                <app-statistics-totems-private-review-count
                    [data$]="privateReviewData$"
                    [isParentLoading]="isLoadingBoosters() || isPrivateReviewsLoading()"
                    [isParentError]="isErrorBoosters() || isPrivateReviewsError()"></app-statistics-totems-private-review-count>
            }
        </div>
    </div>
</ng-template>

<ng-template #wheelOfFortuneStatisticsTemplate>
    <div class="malou-text-18--bold mt-3 flex flex-col text-malou-color-text-1">
        <span>{{ 'statistics.totems.scan_count.wheel_of_fortune' | translate }}</span>
        @if (!boosterPackActivated()) {
            <span class="malou-text-12--regular mt-1 text-malou-color-text-2">{{ 'statistics.totems.no_booster_pack' | translate }}</span>
        }
    </div>

    @if (isReleaseBoosterPerformanceImprovementsEnabled()) {
        <app-wheel-of-fortune-gifts-kpis-v2
            [data]="giftsDataV2()"
            [isParentLoading]="isLoadingGifts()"
            [isParentError]="isErrorGifts()"></app-wheel-of-fortune-gifts-kpis-v2>

        <div class="flex gap-4 md:flex-col">
            <div class="h-full min-w-0 flex-1">
                <app-wheel-of-fortune-gifts-distribution-v2
                    class="flex h-[500px]"
                    [data]="giftsDataV2()"
                    [isParentLoading]="isLoadingGifts()"
                    [isParentError]="isErrorGifts()"
                    (tableSortByChange)="
                        onTableSortOptionsChange(InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION, $event)
                    "></app-wheel-of-fortune-gifts-distribution-v2>
            </div>
        </div>
    } @else {
        <app-wheel-of-fortune-gifts-kpis
            [data$]="giftsData$"
            [isParentLoading]="isLoadingGifts()"
            [isParentError]="isErrorGifts()"></app-wheel-of-fortune-gifts-kpis>

        <div class="flex gap-4 md:flex-col">
            <div class="h-full min-w-0 flex-1">
                <app-wheel-of-fortune-gifts-distribution
                    class="flex h-[500px]"
                    [data$]="giftsData$"
                    [isParentLoading]="isLoadingGifts()"
                    [isParentError]="isErrorGifts()"
                    (tableSortByChange)="
                        onTableSortOptionsChange(InsightsChart.BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION, $event)
                    "></app-wheel-of-fortune-gifts-distribution>
            </div>
        </div>
    }
</ng-template>

<ng-template #filtersTemplate>
    <div class="flex items-end justify-between gap-y-4 px-8.5 md:flex-col md:items-center md:px-0">
        <app-statistics-filters [restaurantTotems]="restaurantTotems()"></app-statistics-filters>
        <button
            class="malou-btn-raised--primary !h-12 sm:mt-2"
            mat-raised-button
            [disabled]="isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
