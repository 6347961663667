@if (isLoading()) {
    <app-skeleton skeletonClass="secondary-bg h-[100px] w-full"></app-skeleton>
} @else {
    <div class="rounded-xl bg-malou-color-background-dark p-6.5">
        <ng-container [ngTemplateOutlet]="restaurantAiSettingsHeaderTemplate"></ng-container>

        @if (showDetails()) {
            <ng-container [ngTemplateOutlet]="restaurantAiSettingsDetailsTemplate"></ng-container>
        }
    </div>
}

<ng-template #restaurantAiSettingsHeaderTemplate>
    <div class="flex items-center justify-between sm:flex-col sm:gap-y-3">
        <div class="flex items-center gap-x-3">
            <div class="flex items-center gap-x-5">
                <div class="malou-text-30">⭐️</div>
                <div class="flex flex-col gap-y-2">
                    <span class="malou-text-14--bold malou-color-text-1">{{ 'ai.reviews' | translate }}</span>
                    <span class="malou-text-10--regular malou-color-text-2">{{ 'restaurant_ai_settings.header.title' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="flex gap-x-2">
            <button
                class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-fit"
                data-testid="restaurants-review-ai-settings-edit-btn"
                mat-raised-button
                (click)="openRestaurantAiSettingsModal()">
                {{ 'common.edit' | translate }}
            </button>
            <button class="malou-btn-icon--secondary !h-12.5 !w-12.5" mat-icon-button (click)="toggleShowDetails()">
                <mat-icon class="!h-full" color="primary" [svgIcon]="showDetails() ? SvgIcon.CHEVRON_UP : SvgIcon.CHEVRON_DOWN"></mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #restaurantAiSettingsDetailsTemplate>
    <div class="mt-6 flex flex-col gap-y-8">
        @if (aiSettingsContext.restaurantAiSettings(); as restaurantAiSettings) {
            <div class="ml-[-26px] h-[2px] w-[calc(100%+52px)] bg-malou-color-background-light"></div>
            <div class="grid grid-cols-2 gap-x-3 gap-y-5 md:!grid-cols-1">
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.details.restaurant_name' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ restaurantAiSettings.restaurantName || DEFAULT_DISPLAY }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.details.catchphrase' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ restaurantAiSettings.reviewSettings?.catchphrase || DEFAULT_DISPLAY }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.details.default_language_response' | translate }}
                    </div>
                    <div class="malou-text-12--medium flex items-center gap-x-2 text-malou-color-text-1">
                        <img
                            class="!h-6 !w-6"
                            [src]="restaurantAiSettings.defaultLanguageResponse | flagPathResolver"
                            [alt]="restaurantAiSettings.defaultLanguageResponse" />
                        <span>{{ restaurantAiSettings.defaultLanguageResponse | enumTranslate: 'application_language' }}</span>
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.details.customer_naming' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ restaurantAiSettings.reviewSettings?.customerNaming ?? '' | enumTranslate: 'customer_naming' }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.details.reply_tone' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ restaurantAiSettings.reviewSettings?.replyTone ?? '' | enumTranslate: 'reply_tone' }}
                    </div>
                </div>
                <div class="flex flex-col gap-y-2">
                    <div class="malou-text-10--regular text-malou-color-text-2">
                        {{ 'restaurant_ai_settings.details.signature' | translate }}
                    </div>
                    <div class="malou-text-12--medium text-malou-color-text-1">
                        {{ restaurantAiSettings.reviewSettings?.signatures?.join(' - ') || DEFAULT_DISPLAY }}
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>
