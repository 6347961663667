<div
    class="flex items-center gap-x-2 bg-malou-color-background-light text-malou-color-text-1"
    [ngClass]="{
        'cursor-pointer': !disabled(),
        'cursor-default': disabled(),
        'border border-malou-color-border-primary': withBorder(),
        'h-6 rounded-[3px] px-2': size() === PostDatePickerSize.SMALL,
        'h-[50px] rounded-[5px] px-3': size() === PostDatePickerSize.BIG,
    }"
    (click)="!disabled() && openCalendar()">
    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
    <div
        class="mt-[1px]"
        [ngClass]="{
            'malou-text-10--regular': size() === PostDatePickerSize.SMALL,
            'malou-text-12--semibold': size() === PostDatePickerSize.BIG,
        }">
        @if (selectedDate(); as selectedDate) {
            {{ selectedDate | formatDate: 'full' }}
        } @else {
            --
        }
    </div>
</div>
