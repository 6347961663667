<div class="flex h-full w-full flex-col border-y !border-malou-color-border-primary">
    <div class="flex h-full bg-malou-color-background-light">
        <div class="hide-scrollbar mx-8 mt-6 flex flex-[0_0_38%] flex-col gap-4 overflow-y-auto">
            <app-schedule-post-form [(willPostAllAtSameTime)]="willPostAllAtSameTime" [scheduleForm]="customizedDatePostForm">
            </app-schedule-post-form>
            <app-keep-same-post-caption-toggle
                [(shouldKeepSameCaptionForAllPosts)]="shouldKeepSameCaptionForAllPosts"
                [disabled]="isLoading() || !isLoaderMinDurationReached()"></app-keep-same-post-caption-toggle>
            <app-original-post-preview
                [post]="sharedData.postToDuplicate"
                [callToAction]="callToAction()"
                [restaurantKeywords]="sharedData.restaurantKeywords"
                [keywords$]="sharedData.restaurantKeywords$"></app-original-post-preview>
        </div>
        <mat-divider class="!border-malou-color-border-primary" [vertical]="true"></mat-divider>
        <div class="flex w-full flex-1 flex-col overflow-y-auto bg-white">
            @if (isLoading() || !isLoaderMinDurationReached()) {
                <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
            } @else if (isCaptionsGenerationError()) {
                <ng-container [ngTemplateOutlet]="errorCaptionsGeneration"></ng-container>
            } @else {
                <ng-container [ngTemplateOutlet]="postCaptionsList"></ng-container>
            }
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="m-4 flex h-full items-center justify-center overflow-x-hidden">
        <app-multiple-steps-loader [steps]="loaderSteps"></app-multiple-steps-loader>
    </div>
</ng-template>

<ng-template #errorCaptionsGeneration>
    <div class="flex h-[442px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'common.unknown_error' | translate }}</span>
    </div>
</ng-template>

<ng-template #postCaptionsList>
    @for (data of restaurantsData(); track data.restaurant._id; let isLast = $last; let index = $index) {
        <app-seo-post-caption-preview-card
            [shouldDisplayDateForm]="!willPostAllAtSameTime()"
            [restaurantsData]="data"
            [restaurantIndex]="index"
            [postDateForm]="newPostsDateForms[index]"
            [postLang$]="postLang$"
            [shouldDisableCallToActionUrlInput]="!missingCallToActionUrlError()(data.restaurant) && shouldDisableCallToActionUrlInput()"
            [initialCaption]="initialRestaurantsHtmlCaptions()[index]"
            [callToActionUrl]="data.postCallToActionUrl"
            [shouldKeepSameCaptionForAllPosts]="shouldKeepSameCaptionForAllPosts()"
            [missingCallToActionUrlError]="missingCallToActionUrlError()(data.restaurant)"
            (onUpdateRestaurantsData)="onUpdateRestaurantsData($event)"></app-seo-post-caption-preview-card>
        @if (!isLast) {
            <mat-divider class="!mx-5 !border-malou-color-border-primary"></mat-divider>
        }
    }
</ng-template>
