import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { GmbInsightsContext } from '../context/gmb-insights.context';
import { GmbActionsChartComponent } from '../gmb-actions/gmb-actions-chart/gmb-actions-chart.component';

@Component({
    selector: 'app-gmb-actions-v2',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        SelectComponent,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
        LowerCasePipe,
        GmbActionsChartComponent,
        NumberEvolutionComponent,
        ShortNumberPipe,
    ],
    templateUrl: './gmb-actions-v2.component.html',
    styleUrl: './gmb-actions-v2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbActionsV2Component implements OnInit {
    readonly showSortByTextInsteadOfSelector = input<boolean | undefined>(false);
    readonly hiddenDatasetIndexes = input<number[]>([]);
    readonly sortBy = input<ChartSortBy | undefined>(undefined);
    readonly hiddenDatasetIndexesChange = output<number[]>();
    readonly sortByChange = output<ChartSortBy>();

    readonly gmbInsightsContext = inject(GmbInsightsContext);
    private readonly _enumTranslate = inject(EnumTranslatePipe);

    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_SORT_BY = ChartSortBy.ALPHABETICAL;
    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(this.DEFAULT_SORT_BY) as FormControl<ChartSortBy>;
    readonly sortByFilterSubject$: BehaviorSubject<ChartSortBy> = new BehaviorSubject(this.DEFAULT_SORT_BY);
    readonly SORT_BY_FILTER_VALUES = Object.values(ChartSortBy);

    readonly restaurants = computed(() => this.gmbInsightsContext.currentActionsChartData().restaurants);

    readonly gmbActionsData = computed(() => ({
        websiteClicks: this.gmbInsightsContext.currentActionsChartData().websiteClicks,
        phoneClicks: this.gmbInsightsContext.currentActionsChartData().phoneClicks,
        drivingClicks: this.gmbInsightsContext.currentActionsChartData().drivingClicks,
        bookingClicks: this.gmbInsightsContext.currentActionsChartData().bookingClicks,
        menuClicks: this.gmbInsightsContext.currentActionsChartData().menuClicks,
        foodOrderClicks: this.gmbInsightsContext.currentActionsChartData().foodOrderClicks,
    }));

    readonly previousGmbActionsData = computed(() => ({
        websiteClicks: this.gmbInsightsContext.previousActionsChartData().websiteClicks,
        phoneClicks: this.gmbInsightsContext.previousActionsChartData().phoneClicks,
        drivingClicks: this.gmbInsightsContext.previousActionsChartData().drivingClicks,
        bookingClicks: this.gmbInsightsContext.previousActionsChartData().bookingClicks,
        menuClicks: this.gmbInsightsContext.previousActionsChartData().menuClicks,
        foodOrderClicks: this.gmbInsightsContext.previousActionsChartData().foodOrderClicks,
    }));

    readonly totalActions = computed(() => this.gmbInsightsContext.currentActionsChartData().totalActions);
    readonly conversionRate = computed(() => this.gmbInsightsContext.currentActionsChartData().ratioActionsOverImpressions);

    readonly actionsEvolutionPercentage = computed(() => {
        const totalActions = this.totalActions();
        const previousTotalActions = this.gmbInsightsContext.previousActionsChartData().totalActions;
        return totalActions && previousTotalActions ? ((totalActions - previousTotalActions) / previousTotalActions) * 100 : null;
    });

    readonly conversionRateEvolution = computed(() => {
        const conversionRate = this.conversionRate();
        const previousConversionRate = this.gmbInsightsContext.previousActionsChartData().ratioActionsOverImpressions;
        return conversionRate && previousConversionRate ? conversionRate - previousConversionRate : null;
    });

    ngOnInit(): void {
        this._initSortByFilter();

        this.sortByFilterSubject$.subscribe((sortBy) => {
            if (!this.gmbInsightsContext.isLoading()) {
                this.sortByChange.emit(sortBy);
                this.gmbInsightsContext.setActionChartDataSorted(this.restaurants(), sortBy);
            }
        });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    private _initSortByFilter(): void {
        const sortBy = this.sortBy();
        if (sortBy) {
            this.sortByFilterSubject$.next(sortBy);
        }
    }
}
