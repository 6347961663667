import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, Inject, OnInit, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { debounceTime, filter, map, startWith, switchMap, take } from 'rxjs/operators';

import { checkSocialNetworkUrl, isValidUrl, MediaCategory, SocialNetworkKey } from '@malou-io/package-utils';

import { countries, PHONE_CODES } from ':core/constants';
import { CategoryService } from ':core/services/category.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { MediaService } from ':modules/media/media.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { FileUploadComponent } from ':shared/components/file-upload/file-upload.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputGoogleMapsAutocompleteComponent } from ':shared/components/input-google-maps-autocomplete/input-google-maps-autocomplete.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SelectChipListComponent } from ':shared/components/select-chip-list/select-chip-list.component';
import { SelectSocialNetworkComponent } from ':shared/components/select-social-network/select-social-network.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { getFormControlRecordFromDefaultValue } from ':shared/helpers/form-control-from-default-value';
import { INullableFormControlRecord, INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { MatGoogleMapsAutocompleteEvent } from ':shared/interfaces/mat-google-maps-autocomplete-event.interface';
import { Address, Category, Media, Phone, Restaurant, RestaurantInterface, SocialNetworkUrl } from ':shared/models';
import { PhoneCode } from ':shared/models/phone-code';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

const EMPTY_ADDRESS = new Address({
    streetNumber: undefined,
    route: undefined,
    locality: undefined,
    regionCode: undefined,
    country: undefined,
    postalCode: undefined,
    formattedAddress: undefined,
    administrativeArea: undefined,
});

export type InformationUpdateData = Partial<
    Pick<
        Restaurant,
        | 'address'
        | 'reservationUrl'
        | 'category'
        | 'categoryList'
        | 'cover'
        | 'latlng'
        | 'logo'
        | 'menuUrl'
        | 'name'
        | 'openingDate'
        | 'orderUrl'
        | 'socialNetworkUrls'
        | 'phone'
        | 'website'
    >
>;

interface InfoForm {
    reservationUrl?: string;
    orderUrl?: string;
    name: string;
    menuUrl: string;
    website: string;
    openingDate: Date;
    logo: Media;
    cover: Media;
    logoFile: File;
    coverFile: File;
    phone: INullableFormGroup<InfoPhoneForm>;
    category: Category;
    categoryList: Category[];
    categoryListAutoComplete: string[];
    address?: Address;
    socialNetworkUrls: FormArray<FormGroup<SocialNetworkUrlsForm>>;
    latlng?: {
        lat: number;
        lng: number;
    };
}

interface InfoPhoneForm {
    digits: string;
    prefix: PhoneCode;
}

interface SocialNetworkUrlsForm {
    key: FormControl<SocialNetworkKey | null>;
    url: FormControl<string | null>;
}

const DEFAULT_REQUIRED_ADDRESS_KEYS = ['formattedAddress', 'locality', 'country'];

@Component({
    selector: 'app-infos-restaurant-modal',
    templateUrl: './infos-restaurant-modal.component.html',
    styleUrls: ['./infos-restaurant-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        FileUploadComponent,
        InputDatePickerComponent,
        InputGoogleMapsAutocompleteComponent,
        InputTextComponent,
        SelectChipListComponent,
        SelectComponent,
        SelectBaseComponent,
        SkeletonComponent,
        SelectChipListComponent,
        SelectSocialNetworkComponent,
        ApplySelfPurePipe,
        ApplyPurePipe,
        ImagePathResolverPipe,
        AsyncPipe,
    ],
})
export class InfosRestaurantModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    @ViewChild('categoryInput') categoryInput;
    @ViewChild('categoryListInput') categoryListInput;
    @ViewChild('addressInput') addressInput;

    readonly PHONE_CODES = [...PHONE_CODES];
    readonly MAX_SECONDARY_CHIPS = 9;

    readonly currentLang = LocalStorage.getLang();

    infoForm: INullableFormGroup<InfoForm>;
    addressForm: FormControl<string | null> = new FormControl<string>('');

    categories: Array<Category>;
    restaurant: Restaurant;
    initialPhone: Phone | undefined;

    displayCloseModal = false;

    coverErrorMessage: string | undefined;
    logoErrorMessage: string | undefined;

    isLoading = true;

    readonly socialPlatformOptions = signal<SocialNetworkKey[][]>([]);
    readonly MAX_SOCIAL_NETWORKS = Object.values(SocialNetworkKey).length;

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _dialogRef: MatDialogRef<InfosRestaurantModalComponent>,
        @Inject(MAT_DIALOG_DATA) readonly data: { restaurant: Restaurant },
        private readonly _fb: FormBuilder,
        private readonly _categoriesService: CategoryService,
        private readonly _spinnerService: SpinnerService,
        private readonly _mediaService: MediaService,
        private readonly _translate: TranslateService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.restaurant = new Restaurant(data.restaurant);

        this.infoForm = this._initForm(this.restaurant);
        if (!this.restaurant.isBrandBusiness()) {
            const displayedAddress = this.restaurant.address?.getDisplayedValue() || '';
            this.addressForm = new FormControl(displayedAddress, [this._validSelectedAddress()]);
            this.addressForm.valueChanges.pipe(debounceTime(1500), takeUntilDestroyed()).subscribe((value) => {
                if (value === '') {
                    this.infoForm.controls['address']?.setValue(EMPTY_ADDRESS);
                }
            });
        }
    }

    get categoryList(): Category[] {
        return this.infoForm.get(['categoryList'])?.value;
    }

    get category(): Category | null | undefined {
        return this.infoForm.get('category')?.value;
    }

    ngOnInit(): void {
        this._categoriesService
            .getUsableCategories({ selectBrandBusinessCategories: this.restaurant.isBrandBusiness() })
            .pipe(
                take(1),
                map((res) => {
                    const sortedCategories = res.data.sort((a: Category, b: Category) => {
                        const aName = a?.getCategoryNameForLang(this.currentLang) ?? '';
                        const bName = b?.getCategoryNameForLang(this.currentLang) ?? '';
                        return aName.localeCompare(bName);
                    });

                    return sortedCategories;
                })
            )
            .subscribe((res) => {
                this.categories = res;
                const restaurantCategories = this.categories.filter((cat) =>
                    this.restaurant.categoryList?.map((c) => c._id).includes(cat._id)
                );
                this.infoForm.get('categoryList')?.patchValue(restaurantCategories);
                this.isLoading = false;
            });

        this.infoForm.setValidators([
            this._validPhoneNumberValidator(),
            this._validSecondaryCategory(),
            this._validPrimaryCategory(),
            this._validAddress(),
            this._validSocialNetworkUrls(),
            this._isValidUrl('website'),
            this._isValidUrl('menuUrl'),
            this._isValidUrl('reservationUrl'),
            this._isValidUrl('orderUrl'),
        ]);

        this.checkInfoFormInitialValidity();

        this.infoForm.get('phone')?.valueChanges.subscribe((phoneInputs) => {
            if (!phoneInputs.digits || !phoneInputs.prefix) {
                return;
            }

            const setNumber = (number: PhoneNumber): void => {
                const callingCode = PHONE_CODES.find((c) => c.code.toString() === number.countryCallingCode);
                if (!callingCode || !phoneInputs.prefix) {
                    return;
                }
                const prefixLen = 1 + callingCode.code.toString().length;
                const formattedDigits = number.formatInternational().slice(prefixLen).trim();
                if (phoneInputs.digits !== formattedDigits || phoneInputs.prefix.code !== callingCode.code) {
                    this.infoForm.get('phone')?.setValue({
                        prefix: callingCode ?? null,
                        digits: formattedDigits,
                    });
                }
            };

            let phoneNumber = parsePhoneNumberFromString(phoneInputs.digits);
            if (phoneNumber && phoneNumber.isValid()) {
                setNumber(phoneNumber);
                return;
            }

            phoneNumber = parsePhoneNumberFromString('+' + phoneInputs.prefix.code + phoneInputs.digits);
            if (phoneNumber && phoneNumber.isValid()) {
                setNumber(phoneNumber);
                return;
            }
        });

        this.infoForm
            .get('logoFile')
            ?.valueChanges.pipe(
                filter(Boolean),
                switchMap((logo) => {
                    this._spinnerService.show();
                    return this._mediaService.uploadAndCreateMedia([
                        {
                            data: logo,
                            metadata: {
                                restaurantId: this.restaurant._id,
                                category: MediaCategory.PROFILE,
                            },
                        },
                    ]);
                })
            )
            .subscribe({
                next: (res) => {
                    this.infoForm.controls['logo'].setValue(new Media(res.data[0]));
                    this.infoForm.controls['logo'].markAsDirty();
                    this.infoForm.controls['logoFile'].markAsPristine();
                    this._spinnerService.hide();
                },
                error: (err) => {
                    this._spinnerService.hide();
                    if (err.error.errorData && err.error.errorData.code === 'LIMIT_FILE_SIZE') {
                        this._toastService.openErrorToast(this._translate.instant('information.information.file_max_size'));
                    } else {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    }
                },
            });
        this.infoForm
            .get('coverFile')
            ?.valueChanges.pipe(
                filter(Boolean),
                switchMap((logo) => {
                    this._spinnerService.show();
                    return this._mediaService.uploadAndCreateMedia([
                        {
                            data: logo,
                            metadata: {
                                restaurantId: this.restaurant._id,
                                category: MediaCategory.COVER,
                            },
                        },
                    ]);
                })
            )
            .subscribe({
                next: (res) => {
                    this.infoForm.controls['cover'].setValue(new Media(res.data[0]));
                    this.infoForm.controls['cover'].markAsDirty();
                    this.infoForm.controls['coverFile'].markAsPristine();
                    this._spinnerService.hide();
                },
                error: (err) => {
                    this._spinnerService.hide();
                    if (err.error.errorData && err.error.errorData.code === 'LIMIT_FILE_SIZE') {
                        this._toastService.openErrorToast(this._translate.instant('information.information.file_max_size'));
                    } else {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    }
                },
            });

        this._initOnSocialNetworkUrlsChanges();
    }

    isCategoryOptionDisabled(category: Category): boolean {
        return this.categoryList.length > this.MAX_SECONDARY_CHIPS && !this.categoryList.map((c) => c._id).includes(category._id);
    }

    computeHashWithId(obj: any): string {
        return obj?._id;
    }

    checkInfoFormInitialValidity(): void {
        for (const key of Object.keys(this.infoForm.controls)) {
            const control = this.infoForm.controls[key];
            if (!control.valid) {
                control.markAsTouched();
            }
        }
    }

    selectedAddress($event: MatGoogleMapsAutocompleteEvent): void {
        // map return google places object to our address format
        try {
            this.infoForm.get('address')?.markAsDirty();
            const { address_components, geometry } = $event;
            if (geometry?.location) {
                const latlng = {
                    lat: geometry.location.lat(),
                    lng: geometry.location.lng(),
                };
                this.infoForm.patchValue({
                    latlng,
                });
                this.infoForm.get(['latlng'])?.markAsDirty();
            }
            const streetNumber = address_components.find((el) => el.types.includes('street_number'))?.long_name || undefined;
            const route = address_components.find((el) => el.types.includes('route'))?.long_name || undefined;
            const locality =
                address_components.find((el) => el.types.includes('locality') || el.types.includes('postal_town'))?.long_name || undefined;
            const regionCode = address_components.find((el) => el.types.includes('country'))?.short_name || undefined;
            const country = address_components.find((el) => el.types.includes('country'))?.long_name || undefined;
            const postalCode =
                regionCode === 'GB'
                    ? this._getBritishPostalCode()
                    : address_components.find((el) => el.types.includes('postal_code'))?.long_name || undefined;
            const administrativeArea =
                address_components.find((el) => el.types.includes('administrative_area_level_1'))?.long_name || undefined;
            const formattedAddress = ((streetNumber || '') + ' ' + (route || '')).trim();
            const newAddress = new Address({
                streetNumber,
                route,
                locality,
                administrativeArea,
                regionCode,
                country,
                postalCode,
                formattedAddress,
            });
            this.infoForm.patchValue({
                address: newAddress,
            });
            this.addressForm.patchValue(newAddress.getDisplayedValue());
        } catch (err) {}
    }

    save(): void {
        if (!this.infoForm.valid) {
            return;
        }

        const rawData = this.infoForm.value;
        const phone = this._getPhoneValue({ digits: rawData.phone?.digits || undefined, prefix: rawData.phone?.prefix || undefined });

        const infoFormValue = this.infoForm.value;

        const updateData: InformationUpdateData = {
            ...infoFormValue,
            address: this.addressForm.value === '' ? undefined : this.infoForm.value.address || undefined,
            reservationUrl: this.infoForm.value.reservationUrl ?? '',
            categoryList: this.infoForm.value.categoryList || undefined,
            cover: this.infoForm.value.cover || undefined,
            latlng: this.infoForm.value.latlng || undefined,
            menuUrl: this.infoForm.value.menuUrl ?? '',
            name: this.infoForm.value.name || undefined,
            openingDate: this.infoForm.value.openingDate || undefined,
            orderUrl: this.infoForm.value.orderUrl ?? '',
            socialNetworkUrls:
                this.infoForm.value.socialNetworkUrls?.map((socialNetworkUrl) => ({
                    key: socialNetworkUrl.key!,
                    url: socialNetworkUrl.url!,
                })) ?? undefined,
            phone,
            website: this.infoForm.value.website ?? '',
        };

        const updateKeys = Object.keys(this._getDirtyValues(this.infoForm)).filter((key) => key !== 'categoryListAutoComplete');

        // send only what has been touched to avoid gmb long update
        Object.keys(updateData).forEach((key) => !updateKeys.includes(key) && delete updateData[key]);

        // avoid sending null props on address updates for gmb
        if (updateData?.address) {
            Object.keys(updateData?.address).forEach(
                (key) => updateData.address && updateData.address[key] === null && delete updateData.address[key]
            );
        }

        this.confirmClose(updateData);
    }

    close(): void {
        if (this.infoForm.dirty) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose();
        }
    }

    confirmClose(data?: InformationUpdateData): void {
        this._dialogRef.close(data);
    }

    categoryDisplayWith = (category: Category): string => category.getCategoryNameForLang(this.currentLang);

    phoneCodesDisplayWith(phoneCode): string {
        return phoneCode.text;
    }

    private _isValidUrl(key): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const url = group.get(key)?.value;
            if (!url) {
                return null;
            }

            if (!isValidUrl(url)) {
                group.get(key)?.setErrors({
                    error: this._translate.instant('common.wrong_format_example', {
                        example: this._getUrlExample(key),
                    }),
                });
            }

            return null;
        };
    }

    private _getUrlExample(key: string): string | undefined {
        switch (key) {
            case 'menuUrl':
                return 'https://www.website.com/menu';
            case 'website':
                return 'https://www.website.com';
            case 'reservationUrl':
                return 'https://www.website.com/book';
            case 'orderUrl':
                return 'https://www.website.com/order';
            default:
                return undefined;
        }
    }

    addSocialNetworkUrl(): void {
        const socialPlatformKeys = Object.values(SocialNetworkKey);
        const previouslySelectedSocialPlatforms = this.infoForm.controls.socialNetworkUrls.value.map((platform) => platform.key);
        const availableSocialPlatforms = socialPlatformKeys.filter((platform) => !previouslySelectedSocialPlatforms.includes(platform));
        this.infoForm.controls.socialNetworkUrls.push(
            this._fb.group({
                key: new FormControl(availableSocialPlatforms[0], Validators.required),
                url: new FormControl('', Validators.required), // TODO prefill
            })
        );
        this.infoForm.controls.socialNetworkUrls.markAsDirty();
    }

    removeSocialNetworkAt(index: number): void {
        this.infoForm.controls.socialNetworkUrls.removeAt(index);
        this.infoForm.controls.socialNetworkUrls.markAsDirty();
    }

    getPlaceholderUrlForPlatform(key: SocialNetworkKey | null): string {
        switch (key) {
            case SocialNetworkKey.FACEBOOK:
                return 'https://www.facebook.com/';
            case SocialNetworkKey.INSTAGRAM:
                return 'https://www.instagram.com/';
            case SocialNetworkKey.LINKEDIN:
                return 'https://www.linkedin.com/in/';
            case SocialNetworkKey.TIKTOK:
                return 'https://www.tiktok.com/';
            case SocialNetworkKey.X:
                return 'https://www.x.com/';
            case SocialNetworkKey.YOUTUBE:
                return 'https://www.youtube.com/';
            case SocialNetworkKey.PINTEREST:
                return 'https://www.pinterest.com/';
            default:
                return '';
        }
    }

    private _getDirtyValues(form: INullableFormGroup<any>): RestaurantInterface {
        const dirtyValues = {};
        Object.keys(form.controls).forEach((key) => {
            const currentControl = form.controls[key];
            if (currentControl.dirty) {
                if (currentControl.controls) {
                    dirtyValues[key] = this._getDirtyValues(currentControl);
                } else {
                    dirtyValues[key] = currentControl.value;
                }
            }
        });

        return dirtyValues;
    }

    private _getBritishPostalCode(): string {
        const splitAddress = this.addressInput.nativeElement.value.split(',')[1].split(' ');
        return [splitAddress.at(-2), splitAddress.at(-1)].join(' ');
    }

    private _getPhoneValue(
        inputPhone: Partial<{
            digits: string;
            prefix: PhoneCode;
        }>
    ): Phone | undefined {
        if (!inputPhone?.digits) {
            return undefined;
        }
        const phoneNumber = parsePhoneNumberFromString('+' + String(inputPhone.prefix?.code) + String(inputPhone.digits));
        if (!phoneNumber || !phoneNumber.isValid()) {
            return undefined;
        }
        return new Phone({
            digits: parseInt(phoneNumber?.nationalNumber.toString(), 10),
            prefix: inputPhone.prefix?.code,
        });
    }

    private _initForm(restaurant: Restaurant): INullableFormGroup<InfoForm> {
        const {
            name,
            logo,
            cover,
            website,
            openingDate,
            menuUrl,
            phone,
            address,
            category,
            categoryList,
            latlng,
            reservationUrl,
            orderUrl,
            socialNetworkUrls,
        } = restaurant;

        const infoFormRecord: INullableFormControlRecord<InfoForm> = getFormControlRecordFromDefaultValue(
            {
                name,
                menuUrl,
                reservationUrl,
                orderUrl,
                socialNetworkUrls: this._initSocialNetworkUrlsForm(socialNetworkUrls),
                website,
                openingDate,
                logo,
                cover: cover || null,
                logoFile: null,
                coverFile: null,
                phone: this._initPhoneForm(phone),
                category: category || null,
                categoryList: categoryList || null,
                categoryListAutoComplete: [''],
                ...(!this.restaurant.isBrandBusiness() && {
                    address,
                    latlng,
                }),
            },
            {
                name: { validators: Validators.required },
                category: { validators: Validators.required },
                address: { validators: Validators.required },
            }
        );
        return this._fb.group(infoFormRecord);
    }

    private _initPhoneForm(phone?: Phone): INullableFormGroup<InfoPhoneForm> {
        this.initialPhone = phone;

        const prefix = (phone && PHONE_CODES.find((pc) => pc.code === phone.prefix)) || null;
        let digits = phone?.digits?.toString() || null;
        if (digits && prefix) {
            const prefixString = '+' + prefix.code;
            const phoneNumber = parsePhoneNumberFromString(prefixString + digits);
            if (phoneNumber?.isValid()) {
                digits = phoneNumber.formatInternational().slice(prefixString.length).trim();
            }
        }

        return this._fb.group({
            digits,
            prefix,
        });
    }

    private _initSocialNetworkUrlsForm(socialNetworkUrls?: SocialNetworkUrl[]): FormArray<FormGroup<SocialNetworkUrlsForm>> {
        const socialNetworkUrlsFormArray = new FormArray<FormGroup<SocialNetworkUrlsForm>>([]);
        socialNetworkUrls?.forEach((socialNetworkUrl) => {
            const socialNetworkUrlsFormGroup = {
                key: new FormControl(socialNetworkUrl.key, Validators.required),
                url: new FormControl(socialNetworkUrl.url, Validators.required),
            };
            socialNetworkUrlsFormArray.push(this._fb.group(socialNetworkUrlsFormGroup));
        });
        return socialNetworkUrlsFormArray;
    }

    /**
     * Validate phone number using libphonenumber-js : check if combination of prefix and digits is a valid number
     */
    private _validPhoneNumberValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const prefix = group.get(['phone', 'prefix']);
            const digits = group.get(['phone', 'digits']);
            if (!digits || !prefix) {
                return null;
            }
            const phoneNumber = parsePhoneNumberFromString('+' + String(prefix.value?.code) + String(digits.value));
            if (!this.initialPhone?.digits && !this.initialPhone?.prefix && !digits.value) {
                digits.setErrors(null);
                return null;
            }
            if (this.initialPhone?.digits && !digits.value) {
                digits.setErrors({ error: this._translate.instant('information.information.required_phone_number') });
                return null;
            }
            if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
                digits.setErrors({ error: this._translate.instant('information.information.invalid_phone_number') });
                return null;
            }
            digits.setErrors(null);
            return null;
        };
    }

    private _validPrimaryCategory(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const category = group.get(['category']);
            const categoryList = group.get(['categoryList']);
            if (!this.categories) {
                category?.setErrors(null);
                return null;
            }
            if (!category?.value?.categoryId) {
                category?.setErrors({ error: this._translate.instant('information.information.invalid_category') });
            } else if (!this.categories?.find((cat) => cat.categoryId === category.value?.categoryId)) {
                category.setErrors({ error: this._translate.instant('information.information.invalid_category') });
            } else if (categoryList?.value.map((c) => c.categoryId).includes(category.value?.categoryId)) {
                category.setErrors({
                    error: this._translate.instant('information.information.cant_choose_same'),
                });
            } else {
                category.setErrors(null);
            }
            return null;
        };
    }

    private _validSecondaryCategory(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const maxChips = 9;
            const category = group.get(['category']);
            const categoryList = group.get(['categoryList']);
            if (!categoryList) {
                return null;
            }
            if (categoryList.value.map((c) => c.categoryId).includes(category?.value?.categoryId)) {
                categoryList.setErrors({
                    error: this._translate.instant('information.information.cant_choose_same'),
                });
            } else if (categoryList.value.length > maxChips) {
                categoryList.setErrors({
                    error: this._translate.instant('information.information.max_categories'),
                });
            } else if (categoryList.value.length !== new Set(categoryList.value.map((c) => c.categoryId)).size) {
                categoryList.setErrors({
                    error: this._translate.instant('information.information.category_already_selected'),
                });
            } else {
                categoryList.setErrors(null);
            }
            return null;
        };
    }

    private _validAddress(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (this.addressForm.value === '') {
                return null;
            }
            const address: Address = group.get('address')?.value;
            const hasError = this._getAddressValidatorsRequiredKeys(address?.postalCode).some((key) => {
                const value = address[key];
                return [null, undefined, ''].includes(value);
            });
            if (hasError) {
                group.get('address')?.setErrors({ error: this._translate.instant('information.information.address_error') });
            }
            return null;
        };
    }

    private _validSocialNetworkUrls(): ValidatorFn {
        return (_group: FormGroup): ValidationErrors | null => {
            const socialNetworkUrls = this.infoForm.get('socialNetworkUrls') as FormArray<FormGroup<SocialNetworkUrlsForm>> | null;
            if (!socialNetworkUrls) {
                return null;
            }

            socialNetworkUrls.controls.forEach((socialNetworkUrl) => {
                const key = socialNetworkUrl.get('key')?.value;
                const url = socialNetworkUrl.get('url')?.value;
                if (key && url && !checkSocialNetworkUrl(url, key)) {
                    socialNetworkUrl.get('url')?.setErrors({
                        error: this._translate.instant('common.wrong_format_example', {
                            example: this._getSocialNetworkUrlExample(key),
                        }),
                    });
                }
            });

            return null;
        };
    }

    private _getAddressValidatorsRequiredKeys(countryCode: string | undefined): string[] {
        if (!countryCode) {
            return [];
        }
        const requiredKeys = [...DEFAULT_REQUIRED_ADDRESS_KEYS];
        const hasPostalCode = countries.some((country) => country.code === countryCode && country.hasPostalCode);
        if (hasPostalCode) {
            requiredKeys.push('postalCode');
        }

        return requiredKeys;
    }

    private _validSelectedAddress(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            const displayedAddress: string = control.value;
            if (displayedAddress === '') {
                return null;
            }

            const address = this.infoForm.get('address')?.value;
            if (displayedAddress !== address?.getDisplayedValue()) {
                return { error: this._translate.instant('information.information.select_address_error') };
            }
            return null;
        };
    }

    private _initOnSocialNetworkUrlsChanges(): void {
        const socialPlatformKeys = [
            SocialNetworkKey.FACEBOOK,
            SocialNetworkKey.INSTAGRAM,
            SocialNetworkKey.LINKEDIN,
            SocialNetworkKey.TIKTOK,
            SocialNetworkKey.X,
            SocialNetworkKey.YOUTUBE,
            SocialNetworkKey.PINTEREST,
        ];
        this.infoForm
            .get('socialNetworkUrls')
            ?.valueChanges.pipe(startWith(this.infoForm.get('socialNetworkUrls')?.value ?? []), takeUntilDestroyed(this._destroyRef))
            .subscribe((socialNetworkUrls) => {
                const previouslySelectedSocialPlatforms = socialNetworkUrls.map((platform) => platform.key);
                const availableSocialPlatforms = socialPlatformKeys.filter(
                    (platform) => !previouslySelectedSocialPlatforms.includes(platform)
                );

                const socialPlatformOptions: SocialNetworkKey[][] = socialNetworkUrls.map((socialNetworkUrl) =>
                    socialNetworkUrl.key ? [socialNetworkUrl.key, ...availableSocialPlatforms] : [...availableSocialPlatforms]
                );

                this.socialPlatformOptions.set(socialPlatformOptions);
            });
    }

    private _getSocialNetworkUrlExample(key: SocialNetworkKey): string {
        switch (key) {
            case SocialNetworkKey.FACEBOOK:
                return 'https://www.facebook.com/{username}';
            case SocialNetworkKey.INSTAGRAM:
                return 'https://www.instagram.com/{username}';
            case SocialNetworkKey.LINKEDIN:
                return 'https://www.linkedin.com/in/{username}';
            case SocialNetworkKey.TIKTOK:
                return 'https://www.tiktok.com/@{username}';
            case SocialNetworkKey.X:
                return 'https://www.x.com/{username}';
            case SocialNetworkKey.YOUTUBE:
                return 'https://www.youtube.com/@{username}';
            case SocialNetworkKey.PINTEREST:
                return 'https://www.pinterest.com/{username}';
            default:
                return '';
        }
    }
}
