import { z } from 'zod';

import { PlatformKey, ReviewAnalysisTag } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const getReviewAnalysesChartDataBodyValidator = z
    .object({
        restaurantIds: z.array(objectIdValidator),
        startDate: z.string().datetime({ offset: true }).nullish(),
        endDate: z.string().datetime({ offset: true }).nullish(),
        keys: z.array(z.nativeEnum(PlatformKey)),
    })
    .transform((data) => ({
        restaurantIds: data.restaurantIds ?? [],
        startDate: data.startDate ? new Date(data.startDate) : null,
        endDate: data.endDate ? new Date(data.endDate) : null,
        keys: data.keys ?? [],
    }));

export type GetReviewAnalysesChartDataBodyDto = z.infer<typeof getReviewAnalysesChartDataBodyValidator>;

// ------------------------------------------------------------------------------------------

export const getReviewAnalysesSegmentDetailsBodyValidator = getReviewAnalysesChartDataBodyValidator;
export const getReviewAnalysesSegmentDetailsParamsValidator = z.object({
    tag: z.nativeEnum(ReviewAnalysisTag),
});

export type GetReviewAnalysesSegmentDetailsBodyDto = z.infer<typeof getReviewAnalysesSegmentDetailsBodyValidator>;
export type GetReviewAnalysesSegmentDetailsParamsDto = z.infer<typeof getReviewAnalysesSegmentDetailsParamsValidator>;
