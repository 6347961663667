import { Action, createReducer, on } from '@ngrx/store';
import { pick, uniqBy, xor } from 'lodash';

import { MalouPeriod, PlatformDefinitions } from '@malou-io/package-utils';

import {
    DEFAULT_ARCHIVES,
    DEFAULT_COMMENTS,
    DEFAULT_PERIOD,
    DEFAULT_RATINGS,
    DEFAULT_STATUSES,
} from ':modules/reviews/reviews-header/reviews-header-filters/default-filters';
import { ReviewStrategyType } from ':modules/reviews/reviews/reviews.strategy';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { ReviewsState, SynchronizationStatus } from ':modules/reviews/store/reviews.interface';
import {
    ArchiveReviewsFilters,
    CommentReviewsFilters,
    GetFilterInput,
    MalouDateFilters,
    Pagination,
    Review,
    StatusReviewsFilter,
} from ':shared/models';
import { PrivateReview } from ':shared/models/private-review';

const dateFilters = new MalouDateFilters();
const DEFAULT_PAGINATION: Pagination = { pageSize: 20, pageNumber: 0, total: 0 };
const getReviewUniqueId = (review: Review | PrivateReview): string =>
    review.isPrivate() ? (<PrivateReview>review)._id : (<Review>review).socialId;

export enum ReviewsDisplayMode {
    SINGLE_RESTAURANT,
    AGGREGATED_RESTAURANTS,
}

export const initialState: ReviewsState = {
    filters: {
        ...dateFilters.getFilter({ period: DEFAULT_PERIOD }),
        platforms: PlatformDefinitions.getNonFeatureFlaggedPlatformsWithReviews(), // First load all platforms without feature flags, it will be updated later
        text: '',
        ratings: DEFAULT_RATINGS.slice(),
        answered: DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.ANSWERED),
        notAnswered: DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.NOT_ANSWERED),
        pending: DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.PENDING),
        notAnswerable: DEFAULT_STATUSES.some((e) => e === StatusReviewsFilter.NOT_ANSWERABLE),
        showPrivate: true,
        withText: DEFAULT_COMMENTS.some((e) => e === CommentReviewsFilters.WITH_TEXT),
        withoutText: DEFAULT_COMMENTS.some((e) => e === CommentReviewsFilters.WITHOUT_TEXT),
        archived: DEFAULT_ARCHIVES.some((e) => e === ArchiveReviewsFilters.ARCHIVED),
        unarchived: DEFAULT_ARCHIVES.some((e) => e === ArchiveReviewsFilters.UNARCHIVED),
        restaurantIds: [],
        aggregatedViewRestaurantIds: [],
    },
    selectablePlatforms: [],
    reviews: [],
    estimatedReviewCount: undefined,
    hasLoadedAllReviews: false,
    replies: {},
    fetchStates: {},
    pagination: DEFAULT_PAGINATION,
    isFooterVisible: true,
    synchronizationStatus: SynchronizationStatus.NOT_STARTED,
    unansweredReviewCount: 0,
    currentView: ReviewsDisplayMode.SINGLE_RESTAURANT,
    couldNotGetPageNumberFromReviewId: {
        error: null,
        timestamp: 0,
        reviewId: null,
    },
    semanticAnalysisToggle: false,
    isAggregatedReviewsFiltersLoaded: false,
    isRestaurantReviewsFiltersLoaded: false,
};

const reviewsReducer = createReducer(
    initialState,
    on(ReviewsActions.initializeState, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...data.filters,
            startDate: dateFilters.getFilter(getMalouDateFilterPeriod(data.filters.period)).startDate,
            endDate: dateFilters.getFilter(getMalouDateFilterPeriod(data.filters.period)).endDate,
            ratings: data.filters.ratings as (0 | 1 | 2 | 3 | 4 | 5)[],
            aggregatedViewRestaurantIds: data.restaurantIds,
        },
        isAggregatedReviewsFiltersLoaded: true,
        isRestaurantReviewsFiltersLoaded: false,
    })),
    on(ReviewsActions.initializeRestaurantFiltersState, (state, { data }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...data,
            startDate: dateFilters.getFilter(getMalouDateFilterPeriod(data.period)).startDate,
            endDate: dateFilters.getFilter(getMalouDateFilterPeriod(data.period)).endDate,
            ratings: data.ratings as (0 | 1 | 2 | 3 | 4 | 5)[],
            aggregatedViewRestaurantIds: [],
        },
        isAggregatedReviewsFiltersLoaded: false,
        isRestaurantReviewsFiltersLoaded: true,
    })),
    on(
        ReviewsActions.editReviewsFilters,
        (state, { filters }) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }) // better way ? https://github.com/paularmstrong/normalizr https://github.com/reduxjs/redux/issues/994
    ),
    on(ReviewsActions.resetReviewsState, () => initialState),
    on(ReviewsActions.resetReviewsStateExceptRestaurants, (state) => {
        const { restaurantIds, aggregatedViewRestaurantIds } = state.filters;
        return {
            ...initialState,
            filters: {
                ...initialState.filters,
                restaurantIds,
                aggregatedViewRestaurantIds,
            },
            isRestaurantReviewsFiltersLoaded: state.isRestaurantReviewsFiltersLoaded,
            isAggregatedReviewsFiltersLoaded: state.isAggregatedReviewsFiltersLoaded,
        };
    }),
    on(ReviewsActions.editReviewsFiltersSearch, (state, { search }) => ({
        ...state,
        filters: {
            ...state.filters,
            text: search,
        },
    })),
    on(ReviewsActions.resetReviewsFiltersDates, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            period: initialState.filters.period,
            startDate: initialState.filters.startDate,
            endDate: initialState.filters.endDate,
        },
    })),
    on(ReviewsActions.editReviewsFiltersPlatforms, (state, { platforms }) => ({
        ...state,
        filters: {
            ...state.filters,
            platforms,
        },
    })),
    on(ReviewsActions.editReviewsFiltersRatings, (state, { ratings }) => ({
        ...state,
        filters: {
            ...state.filters,
            ratings: xor(state.filters.ratings, ratings).filter((n): n is 0 | 1 | 2 | 3 | 4 | 5 => [0, 1, 2, 3, 4, 5].includes(n)),
        },
    })),
    on(ReviewsActions.toggleReviewsFiltersPlatform, (state, { platform }) => {
        const platforms = xor(state.filters.platforms, [platform]);
        return {
            ...state,
            filters: {
                ...state.filters,
                platforms,
            },
        };
    }),
    on(ReviewsActions.toggleReviewsFiltersStatus, (state, { status }) => ({
        ...state,
        filters: {
            ...state.filters,
            [status]: !state.filters[status],
        },
    })),
    on(ReviewsActions.toggleReviewsFiltersComment, (state, { commentFilter }) => ({
        ...state,
        filters: {
            ...state.filters,
            [commentFilter]: !state.filters[commentFilter],
        },
    })),
    on(ReviewsActions.toggleReviewsFiltersArchive, (state, { archiveFilter }) => ({
        ...state,
        filters: {
            ...state.filters,
            [archiveFilter]: !state.filters[archiveFilter],
        },
    })),
    on(ReviewsActions.editSelectablePlatforms, (state, { platforms }) => ({
        ...state,
        selectablePlatforms: platforms,
    })),
    on(ReviewsActions.setReviews, (state, { reviews, strategyType }) => ({
        ...state,
        reviews: processReviewsWithStrategy(state.reviews, reviews, strategyType),
    })),
    on(ReviewsActions.couldNotGetPageNumberFromReviewId, (state, { errorMessage, reviewId }) => ({
        ...state,
        couldNotGetPageNumberFromReviewId: {
            error: errorMessage,
            timestamp: new Date().getTime(),
            reviewId,
        },
    })),
    on(ReviewsActions.editReview, (state, { review }) => {
        const index = state.reviews.findIndex((r) => getReviewUniqueId(r) === getReviewUniqueId(review));
        if (index === -1) {
            return state;
        }
        const reviews = [...state.reviews];
        reviews[index] = review;
        return {
            ...state,
            reviews,
        };
    }),
    on(ReviewsActions.toggleArchived, (state, { review }) => {
        const index = state.reviews.findIndex((r) => getReviewUniqueId(r) === getReviewUniqueId(review));
        if (index === -1) {
            return state;
        }
        const reviews = [...state.reviews];
        const shouldRemoveReviewFromState = (!state.filters.archived && review.archived) || (!state.filters.unarchived && !review.archived);
        if (shouldRemoveReviewFromState) {
            return {
                ...state,
                reviews: reviews.filter((r) => getReviewUniqueId(r) !== getReviewUniqueId(review)),
                estimatedReviewCount: state.estimatedReviewCount === undefined ? undefined : state.estimatedReviewCount - 1,
            };
        }
        const newReview = {
            ...reviews[index],
            archived: review.archived,
        };
        reviews[index] = review.isPrivate() ? new PrivateReview(<PrivateReview>newReview) : new Review(<Review>newReview);
        return {
            ...state,
            reviews,
        };
    }),
    on(ReviewsActions.setEstimatedReviewCount, (state, { count }) => ({
        ...state,
        estimatedReviewCount: count,
    })),
    on(ReviewsActions.setHasLoadedAllReviews, (state, { hasLoadedAllReviews }) => ({
        ...state,
        hasLoadedAllReviews,
    })),
    on(ReviewsActions.editReviewsReply, (state, { reply }) => ({
        ...state,
        replies: {
            ...state.replies,
            [reply.reviewId]: {
                ...state.replies[reply.reviewId],
                ...reply,
            },
        },
    })),
    on(ReviewsActions.editCurrentReplyReviewId, (state, { reviewId }) => ({
        ...state,
        currentReplyReviewId: reviewId,
    })),
    on(ReviewsActions.resetReplies, (state) => ({
        ...state,
        replies: {},
    })),
    on(ReviewsActions.synchronizeReviews, (state) => ({
        ...state,
        fetchStates: {},
    })),
    on(ReviewsActions.setFetchStates, (state, { fetchStates }) => ({
        ...state,
        fetchStates: {
            ...state.fetchStates,
            ...fetchStates,
        },
    })),
    on(ReviewsActions.setSynchronizationStatus, (state, { synchronizationStatus }) => ({
        ...state,
        synchronizationStatus,
        isFooterVisible: synchronizationStatus !== SynchronizationStatus.NOT_STARTED,
    })),
    on(ReviewsActions.setPagination, (state, { pagination }) => ({
        ...state,
        pagination,
    })),
    on(ReviewsActions.resetPagination, (state) => ({
        ...state,
        pagination: DEFAULT_PAGINATION,
    })),
    on(ReviewsActions.increasePaginationNumber, (state) => ({
        ...state,
        pagination: {
            ...state.pagination,
            pageNumber: state.pagination.pageNumber + 1,
        },
    })),
    on(ReviewsActions.editReviewsFiltersDates, (state, { datesFilters }) => ({
        ...state,
        filters: {
            ...state.filters,
            ...datesFilters,
        },
    })),
    on(ReviewsActions.setUnansweredReviewCount, (state, { count }) => ({
        ...state,
        unansweredReviewCount: count,
    })),
    on(ReviewsActions.editRestaurants, (state, { restaurantIds }: { restaurantIds: string[] }) => ({
        ...state,
        filters: {
            ...state.filters,
            [state.currentView === ReviewsDisplayMode.SINGLE_RESTAURANT ? 'restaurantIds' : 'aggregatedViewRestaurantIds']: restaurantIds,
        },
    })),
    on(ReviewsActions.editReviewsFiltersCurrentView, (state, { currentView }) => ({
        ...state,
        currentView,
    })),
    on(ReviewsActions.deleteReviewById, (state, { reviewId }) => ({
        ...state,
        reviews: state.reviews.filter((review) => review._id !== reviewId),
        estimatedReviewCount: state.estimatedReviewCount === undefined ? undefined : state.estimatedReviewCount - 1,
    })),
    on(ReviewsActions.editSemanticAnalysisToggle, (state, { toggle }) => ({
        ...state,
        semanticAnalysisToggle: toggle,
    })),
    on(ReviewsActions.clearFilters, (state) => ({
        ...state,
        filters: {
            ...state.filters,
            ...pick(initialState.filters, [
                'platforms',
                'startDate',
                'endDate',
                'period',
                'ratings',
                'answered',
                'notAnswered',
                'pending',
                'notAnswerable',
                'archived',
                'unarchived',
                'withText',
                'withoutText',
                'notAnswerable',
            ]),
        },
    }))
);

function getMalouDateFilterPeriod(period: MalouPeriod): GetFilterInput {
    if (period === MalouPeriod.CUSTOM) {
        return { period: MalouPeriod.ALL };
    } else {
        return { period };
    }
}

export function reducer(state: ReviewsState | undefined, action: Action): ReviewsState {
    return reviewsReducer(state, action);
}

function processReviewsWithStrategy(
    currentReviews: (Review | PrivateReview)[],
    newReviews: (Review | PrivateReview)[],
    strategyType: ReviewStrategyType
): (Review | PrivateReview)[] {
    switch (strategyType) {
        case ReviewStrategyType.CONCAT_BEFORE:
            return uniqBy([...newReviews, ...currentReviews], getReviewUniqueId);
        case ReviewStrategyType.CONCAT:
            return uniqBy([...currentReviews, ...newReviews], getReviewUniqueId);
        case ReviewStrategyType.REPLACE:
        default:
            return newReviews;
    }
}
