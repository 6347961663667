import { ChangeDetectionStrategy, Component, inject, model, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

import { FileFormat } from '@malou-io/package-utils';

import { ToastService } from ':core/services/toast.service';
import { EditionMedia } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/edition-media.interface';
import { MediaUploaderService } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/components/social-post-content-form/social-post-medias/media-uploader.service';

import { UploadAndEditMediasComponent } from './components/upload-and-edit-medias/upload-and-edit-medias.component';

const MAX_MEDIA_COUNT = 10;

@Component({
    selector: 'app-social-post-medias',
    templateUrl: './social-post-medias.component.html',
    standalone: true,
    imports: [UploadAndEditMediasComponent],
    providers: [MediaUploaderService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostMediasComponent {
    private readonly _mediaUploaderService = inject(MediaUploaderService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);

    readonly medias = model.required<EditionMedia[]>();

    readonly uploadingMediaCount = this._mediaUploaderService.uploadingMediaCount();

    readonly maxMediaSizeInMo = signal(50);
    readonly mediaFormatAccepted = signal([FileFormat.PNG, FileFormat.JPEG]);

    constructor() {
        this._mediaUploaderService
            .onSuccess()
            .pipe(takeUntilDestroyed())
            .subscribe((media) => {
                this.medias.set([...this.medias(), media]);
            });
    }

    onFileAdded(file: File): void {
        const freeSlots = MAX_MEDIA_COUNT - this.medias().length - this.uploadingMediaCount();
        if (freeSlots <= 0) {
            this._toastService.openWarnToast(this._translateService.instant('social_post_medias.max_medias_error'));
            return;
        }
        this._mediaUploaderService.upload(file);
    }

    onImportMediaFromGallery(): void {
        console.log('onImportMediaFromGallery');
        // todo next PR
    }
}
