import { inject, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { RequireAtLeastOne } from 'type-fest';
import { v4 } from 'uuid';

import { isNotNil, PlatformDefinitions, PlatformKey, PostPublicationStatus } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { MediaService } from ':modules/media/media.service';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import {
    PersonalizePostDuplicationComponent,
    PersonalizePostDuplicationData,
} from ':shared/components/personalize-post-duplication/personalize-post-duplication.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { addMinutes, getTimeStringFromDate, isControlValueInTimeFormat } from ':shared/helpers';
import { Step } from ':shared/interfaces/step.interface';
import { Media, Story } from ':shared/models';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Injectable({ providedIn: 'root' })
export class DuplicateStoriesService {
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _translateService = inject(TranslateService);
    private readonly _postsService = inject(PostsService);
    private readonly _mediaService = inject(MediaService);
    private readonly _formBuilder = inject(FormBuilder);

    duplicateStories(
        destination: DuplicationDestination,
        stories: Story[],
        restaurantId: string,
        onSuccess: (duplicatedStories: Story[]) => void,
        onError: (err: unknown) => void
    ): void {
        if (stories.length === 0) {
            return;
        }

        if (destination === DuplicationDestination.HERE) {
            this._duplicateStories(stories, restaurantId, [restaurantId], []).subscribe({
                next: (duplicatedStories) => {
                    onSuccess(duplicatedStories);
                },
                error: (err) => {
                    onError(err);
                },
            });
            return;
        }

        const duplicationSteps = this._getStepsForDuplication(stories, restaurantId);

        const initialData: RestaurantsSelectionData = {
            skipOwnRestaurant: true,
            withoutBrandBusiness: false,
            selectedRestaurants: [],
            hasPlatform: [PlatformKey.INSTAGRAM, PlatformKey.FACEBOOK],
        };

        const dialog = this._customDialogService.open(StepperModalComponent, {
            width: stories.length === 1 ? '900px' : '600px',
            height: 'unset',
            data: {
                steps: duplicationSteps,
                initialData,
                sharedData: {
                    isStory: true,
                },
                title: this._translateService.instant('duplicate_to_restaurants_dialog.title'),
                onSuccess: (duplicatedStories: Story[]) => {
                    this._customDialogService.close(dialog.id);
                    onSuccess(duplicatedStories);
                },
                onError: (error: unknown) => {
                    onError(error);
                },
                shouldDisplayConfirmationCloseModalAfterClosed: true,
            },
        });
    }

    private _getStepsForDuplication(stories: Story[], restaurantId: string): Step[] {
        return stories.length === 1
            ? [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translateService.instant('common.next'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizePostDuplicationData[]> =>
                          of(this._getDataForPersonalizeStoryStep(data, stories[0])),
                  },
                  {
                      component: PersonalizePostDuplicationComponent,
                      subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.personalize_post_data.story_subtitle'),
                      primaryButtonText: this._translateService.instant('common.duplicate'),
                      nextFunction$: (data: PersonalizePostDuplicationData[]): Observable<Story[]> =>
                          this._duplicateStories(
                              stories,
                              restaurantId,
                              data.map((d) => d.restaurant._id),
                              data
                                  .map((d) => {
                                      const status = d.post.value.status;
                                      const date = d.post.value.date;
                                      const time = d.post.value.time;
                                      if (!status) {
                                          return null;
                                      }
                                      return {
                                          restaurantId: d.restaurant._id,
                                          postDateStatus: status,
                                          plannedPublicationDate: this._getPostDuplicationDate(status, date, time),
                                      };
                                  })
                                  .filter(isNotNil)
                          ),
                  },
              ]
            : [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translateService.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translateService.instant('common.duplicate'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<Story[]> =>
                          this._duplicateStories(stories, restaurantId, data.selectedRestaurants?.map((r) => r._id) ?? [], []),
                  },
              ];
    }

    /**
     * Duplicate @param stories in each @param restaurantIds applying @param overrideStoryDataByRestaurant to each stories
     */
    private _duplicateStories(
        stories: Story[],
        fromRestaurantId: string,
        toRestaurantIds: string[],
        overrideStoryDataByRestaurant: { restaurantId: string; postDateStatus: PostDateStatus; plannedPublicationDate: Date }[]
    ): Observable<Story[]> {
        const groupedStories = this._getStoriesListGroupedByMalouId(stories);

        const duplicatedStories$ = groupedStories.flatMap((storiesGroup) =>
            storiesGroup.flatMap((story) => {
                const newMalouStoryIdForGroup = v4();
                return toRestaurantIds.map((toRestaurantId) => {
                    const overrideStoryData = overrideStoryDataByRestaurant.find((d) => d.restaurantId === toRestaurantId);
                    const plannedPublicationDate = overrideStoryData?.plannedPublicationDate ?? DateTime.now().plus({ days: 1 }).toJSDate();
                    const duplicatedStory$ = this._duplicateStory$(
                        { attachments: story.attachments ?? [], plannedPublicationDate, key: story.key, keys: story.keys },
                        newMalouStoryIdForGroup,
                        fromRestaurantId,
                        toRestaurantId
                    );
                    // Rules definitions for calling "prepare story" endpoint :
                    //  - if we duplicate in the same restaurant -> draft so NOT calling "prepare story"
                    //  - if we duplicate in a different restaurant :
                    //    - if we selected 'now' or 'later' in the personalize step (aka overrideStoryData.postDateStatus) ->  calling "prepare story"
                    //    - if the original story is PENDING (aka story.published) ->  calling "prepare story"
                    //    - otherwise ->  NOT calling "prepare story"
                    const isDifferentRestaurant = fromRestaurantId !== toRestaurantId;
                    const shouldPrepareStory = overrideStoryData
                        ? [PostDateStatus.LATER, PostDateStatus.NOW].includes(overrideStoryData.postDateStatus)
                        : [PostPublicationStatus.PENDING].includes(story.published);

                    if (isDifferentRestaurant && shouldPrepareStory) {
                        return duplicatedStory$.pipe(
                            switchMap((duplicatedStory) =>
                                this._postsService
                                    .prepareStory$({
                                        malouStoryId: duplicatedStory.malouStoryId,
                                        keys: PlatformDefinitions.getPlatformKeysWithStories(),
                                    })
                                    .pipe(map(() => duplicatedStory))
                            )
                        );
                    }

                    return duplicatedStory$;
                });
            })
        );

        return forkJoin(duplicatedStories$);
    }

    private _duplicateStory$(
        story: RequireAtLeastOne<
            { attachments: Media[]; plannedPublicationDate: Date; key?: PlatformKey; keys?: PlatformKey[] },
            'key' | 'keys'
        >,
        malouStoryId: string,
        fromRestaurantId: string,
        toRestaurantId: string
    ): Observable<Story> {
        const isDifferentRestaurant = fromRestaurantId !== toRestaurantId;
        const originalMedia: Media = story.attachments[0];
        const mediaId$ =
            isDifferentRestaurant && originalMedia
                ? this._mediaService
                      .duplicateMediaForRestaurants(fromRestaurantId, [originalMedia], [toRestaurantId])
                      .pipe(map((res) => res.data[0]?.id))
                : of(originalMedia?.id);

        return mediaId$.pipe(
            switchMap((mediaId) =>
                this._postsService
                    .createStory$(toRestaurantId, {
                        // when key is set, it means that the post has been published otherwise it's a draft
                        keys: story.key ? [story.key] : story.keys,
                        malouStoryId,
                        plannedPublicationDate: new Date(story.plannedPublicationDate).toISOString(),
                        attachmentId: mediaId,
                        duplicatedFromRestaurantId: fromRestaurantId,
                    })
                    .pipe(map((res) => new Story(res.data)))
            )
        );
    }

    private _getStoriesListGroupedByMalouId(stories: Story[]): Story[][] {
        const groupedStoriesByMalouId = groupBy(stories, 'malouStoryId');
        return Object.values(groupedStoriesByMalouId);
    }

    private _getDataForPersonalizeStoryStep(
        restaurantSelectionData: RestaurantsSelectionData,
        story: Story
    ): PersonalizePostDuplicationData[] {
        return (
            restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                const defaultDate = addMinutes(2, new Date()); // why now + 2 minutes ?
                const date = new Date(story.plannedPublicationDate || story.socialCreatedAt || defaultDate);
                const post = this._formBuilder.group({
                    status: [
                        story.published === PostPublicationStatus.PENDING ? PostDateStatus.LATER : PostDateStatus.DRAFT,
                        Validators.required,
                    ],
                    text: [''],
                    date: [date],
                    time: [getTimeStringFromDate(date), isControlValueInTimeFormat(this._translateService)],
                });
                return {
                    restaurant,
                    post,
                };
            }) ?? []
        );
    }

    private _getPostDuplicationDate(status: PostDateStatus, date: Date | undefined | null, time: string | undefined | null): Date {
        switch (status) {
            case PostDateStatus.DRAFT:
            case PostDateStatus.LATER:
                const dateComputed = date ?? new Date();
                if (dateComputed && time) {
                    dateComputed.setHours(parseInt(time.substring(0, 2), 10));
                    dateComputed.setMinutes(parseInt(time.substring(3), 10));
                }
                return dateComputed;
            case PostDateStatus.NOW:
            default:
                return new Date();
        }
    }
}
