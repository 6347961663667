<form class="m-5 flex" [formGroup]="postForm()">
    <div class="mr-6 flex min-w-[300px] max-w-[300px] flex-col">
        <ng-container
            [ngTemplateOutlet]="restaurantInformationsTemplate"
            [ngTemplateOutletContext]="{ restaurant: restaurant }"></ng-container>
        @if (shouldDisplayDateForm()) {
            <ng-container [ngTemplateOutlet]="postPublicationStatusFormTemplate"></ng-container>
        }
    </div>
    <div class="w-full">
        <div class="flex flex-col">
            <div
                class="malou-text-13--semibold malou-chip--purple flex w-full items-center justify-start rounded-t-md p-2 pl-4"
                [class.hidden]="shouldKeepSameCaptionForAllPosts()">
                <mat-icon class="mb-1 mr-1 h-4 !w-4" [svgIcon]="SvgIcon.MAGIC_WAND"></mat-icon>
                <span>{{ 'restaurant_ai_settings.modals.upsert.proposal' | translate }}</span>
            </div>
            <div
                class="flex flex-col gap-4 rounded-b-md p-4 !pt-0"
                [class.malou-bg-purple-accent-light]="!shouldKeepSameCaptionForAllPosts()"
                [class.p-4]="!shouldKeepSameCaptionForAllPosts()">
                <textarea
                    class="malou-text-12--medium w-full resize-none rounded-md border border-transparent bg-white p-3 leading-5 text-malou-color-text-2 focus:!border-[1px] focus:!border-[#c4b4fe] focus:outline-none"
                    formControlName="postCaption"
                    [class.hidden]="shouldKeepSameCaptionForAllPosts()"
                    [cdkTextareaAutosize]="true"
                    [cdkAutosizeMaxRows]="6"
                    [cdkAutosizeMinRows]="6"
                    [maxlength]="MAX_LENGTH_FOR_SOCIAL_POST">
                </textarea>
                <textarea
                    class="malou-text-12--medium w-full resize-none rounded-md border border-malou-color-border-primary bg-white p-3 leading-5 text-malou-color-text-2 focus:!border-[1px] focus:!border-[#c4b4fe] focus:outline-none"
                    formControlName="postCaptionTriggeredByKeepSameCaptionToggle"
                    [class.hidden]="!shouldKeepSameCaptionForAllPosts()"
                    [cdkTextareaAutosize]="true"
                    [cdkAutosizeMaxRows]="6"
                    [cdkAutosizeMinRows]="6"
                    [maxlength]="MAX_LENGTH_FOR_SOCIAL_POST">
                </textarea>
                <app-fb-add-location-v2 [location]="location" [fbPlatformName]="fbPlatformName"></app-fb-add-location-v2>
            </div>
        </div>
    </div>
</form>

<ng-template #postPublicationStatusFormTemplate>
    <div class="ml-[33px] flex items-center gap-2" [formGroup]="postForm()">
        <!-- TODO: use new datepicker component with modal from RS v2 when created -->
        <app-input-date-picker
            class="mt-1 flex h-[30px] w-[130px] cursor-pointer items-baseline gap-x-2 text-malou-color-text-1"
            formControlName="plannedPublicationDate"
            [ownInputValidation]="true"
            [min]="MIN_DATE"
            [shouldDisplayBorder]="false"
            [theme]="InputTextTheme.INLINE_CARD"></app-input-date-picker>
        <div class="mt-[2px] flex h-7 w-[75px] flex-col">
            <mat-select
                class="!m-0 mt-8 !h-0 !w-[95px] bg-malou-color-background-light !opacity-0"
                panelClass="malou-select-panel"
                formControlName="postTime"
                [hideSingleSelectionIndicator]="true"
                (selectionChange)="changeSelectedTime($event)">
                @for (time of TIMES; track time) {
                    <mat-option
                        [value]="time"
                        [disabled]="
                            isPastHour
                                | applyPure
                                    : {
                                          hourWithMinute: time,
                                          date: postForm().get('plannedPublicationDate')?.value,
                                      }
                        ">
                        {{ time | formatTime: currentLang() === 'en' }}
                    </mat-option>
                }
            </mat-select>
            <app-input-text
                class="grow"
                formControlName="postTime"
                inputType="time"
                [svgIcon]="SvgIcon.CLOCK"
                [theme]="InputTextTheme.INLINE_CARD"></app-input-text>
        </div>
    </div>
    @if (postForm().errors?.publicationDateInTheFuture) {
        <span class="malou-text-10 malou-color-state-error py-1 italic"> {{ 'common.date_past' | translate }} </span>
    }
</ng-template>

<ng-template let-restaurant="restaurant" #restaurantInformationsTemplate>
    <div class="flex items-center gap-x-2">
        <div class="shrink-0 self-start">
            <div class="relative">
                <img
                    class="malou-avatar--small !rounded-md"
                    [src]="(restaurant.logo | applySelfPure: 'getMediaUrl' : 'small') || ('default_logo' | imagePathResolver)" />
            </div>
        </div>
        <div class="min-w-0 grow">
            <div class="flex flex-col">
                <div
                    class="malou-text-13--semibold malou-color-text-1 truncate"
                    [matTooltip]="restaurant.internalName || restaurant.name"
                    [matTooltipShowDelay]="700">
                    {{ restaurant.internalName || restaurant.name }}
                </div>
                <div
                    class="malou-text-10--regular malou-color-text-2 truncate italic"
                    [matTooltip]="(restaurant | applySelfPure: 'getFullFormattedAddress') ?? ''"
                    [matTooltipShowDelay]="700">
                    {{ restaurant | applySelfPure: 'getFullFormattedAddress' }}
                </div>
                <ng-container [ngTemplateOutlet]="platformKeys"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #platformKeys>
    <div class="my-2 flex gap-1">
        @for (platformKey of keys; track platformKey; let index = $index) {
            <app-platform-logo imgClasses="h-5 w-5 max-w-fit" [logo]="platformKey"></app-platform-logo>
        }
    </div>
</ng-template>
