<form class="flex flex-col rounded-[10px] border border-malou-color-border-primary bg-white p-5" [formGroup]="scheduleForm()">
    <div class="flex items-center justify-between">
        <span class="malou-text-13--bold text-malou-color-text-1">{{ 'posts.duplicate_post_modal.post_all_same_time' | translate }}</span>
        <app-slide-toggle [checked]="willPostAllAtSameTime()" (onToggle)="onToggleWillPostAllAtSameTime()"></app-slide-toggle>
    </div>

    @if (willPostAllAtSameTime()) {
        <div class="mt-4 flex gap-2">
            <app-select
                class="flex-1"
                formControlName="status"
                [displayWith]="statusDisplayWith"
                [values]="PostDateStatus"
                [inputReadOnly]="true">
            </app-select>
            <app-input-date-picker
                class="max-w-40"
                formControlName="plannedPublicationDate"
                [manualInputAvailable]="false"
                [ngClass]="{ invisible: (statusNowIsSelected$ | async) }"
                [ownInputValidation]="true"
                [min]="MIN_DATE"></app-input-date-picker>
            <div class="flex flex-col" [ngClass]="{ invisible: (statusNowIsSelected$ | async) }">
                <mat-select
                    class="!m-0 mt-8 !h-0 !opacity-0"
                    panelClass="malou-select-panel"
                    formControlName="postTime"
                    [hideSingleSelectionIndicator]="true"
                    (selectionChange)="changeSelectedTime($event)">
                    @for (time of TIMES; track time) {
                        <mat-option
                            [value]="time"
                            [disabled]="isPastHour | applyPure: { hourWithMinute: time, date: plannedPublicationDate }">
                            {{ time | formatTime: currentLang() === 'en' }}
                        </mat-option>
                    }
                </mat-select>
                <app-input-text class="grow" formControlName="postTime" inputType="time" [svgIcon]="SvgIcon.CLOCK"></app-input-text>
            </div>
        </div>
        @if (scheduleForm().errors?.publicationDateInTheFuture) {
            <span class="malou-text-10 malou-color-state-error py-1 pl-1 italic"> {{ 'common.date_past' | translate }} </span>
        }
    }
</form>
