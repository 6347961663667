import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EventEmitter,
    input,
    OnInit,
    Output,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { round } from 'lodash';

import { AggregatedWheelOfFortuneGiftsStatisticsData } from ':modules/aggregated-statistics/boosters/booster.interface';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

const GIFT_PERCENTAGE_DECIMALS = 1;

enum AggregatedWheelOfFortuneGiftsDistributionTableFieldName {
    BUSINESS = 'business',
    WINNING = 'winning',
    RETRIEVED = 'retrieved',
    PERCENTAGE = 'percentage',
}

interface AggregatedWheelOfFortuneGiftsDistributionTableData {
    restaurantId: string;
    restaurantName: string;
    giftDrawCount: number;
    giftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedGiftDrawCount: number;
    retrievedGiftDrawCountDifferenceWithPreviousPeriod: number | null;
    retrievedPercentage: number;
}

@Component({
    selector: 'app-aggregated-wheel-of-fortune-gifts-distribution-v2',
    templateUrl: './aggregated-wheel-of-fortune-gifts-distribution.component.html',
    styleUrls: ['./aggregated-wheel-of-fortune-gifts-distribution.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatTableModule,
        MatSortModule,
        TranslateModule,
        NumberEvolutionComponent,
        SkeletonComponent,
        IllustrationPathResolverPipe,
        ShortNumberPipe,
        NumberEvolutionComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedWheelOfFortuneGiftsDistributionV2Component implements OnInit {
    readonly data = input.required<AggregatedWheelOfFortuneGiftsStatisticsData | null>();
    readonly tableSort = input<Sort>();
    readonly restaurants = input.required<Restaurant[]>();
    readonly isParentLoading = input(true);
    readonly isParentError = input(false);
    @Output() tableSortByChange: EventEmitter<Sort> = new EventEmitter<Sort>();

    readonly Illustration = Illustration;
    readonly TableFieldName = AggregatedWheelOfFortuneGiftsDistributionTableFieldName;

    readonly displayedColumns = Object.values(AggregatedWheelOfFortuneGiftsDistributionTableFieldName);
    readonly trackByIdFn = TrackByFunctionFactory.get('restaurantId');
    readonly dataSource: MatTableDataSource<AggregatedWheelOfFortuneGiftsDistributionTableData> =
        new MatTableDataSource<AggregatedWheelOfFortuneGiftsDistributionTableData>([]);

    readonly isLoading: WritableSignal<boolean> = signal(false);
    readonly isError: WritableSignal<boolean> = signal(false);

    defaultSort: Sort = {
        active: this.tableSort()?.active ?? AggregatedWheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED,
        direction: this.tableSort()?.direction ?? ChartSortBy.DESC,
    };

    readonly hasData: Signal<boolean> = computed(() => {
        const giftsInsightsPerRestaurant = this.data()?.giftsInsightsPerRestaurant ?? [];
        return giftsInsightsPerRestaurant.length > 0;
    });

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sortingDataAccessor = (item, property): string | number => {
                switch (property) {
                    default:
                    case AggregatedWheelOfFortuneGiftsDistributionTableFieldName.BUSINESS:
                        return item.restaurantName;
                    case AggregatedWheelOfFortuneGiftsDistributionTableFieldName.WINNING:
                        return item.giftDrawCount ?? -1;
                    case AggregatedWheelOfFortuneGiftsDistributionTableFieldName.RETRIEVED:
                        return item.retrievedGiftDrawCount ?? -1;
                }
            };
            this.dataSource.sort = sort;
        }
    }

    readonly matTableData = computed(() => {
        const giftsInsightsPerRestaurant = this.data()?.giftsInsightsPerRestaurant ?? [];
        return this.restaurants().map((restaurant) => {
            const giftsInsights = giftsInsightsPerRestaurant.find((giftInsights) => giftInsights.restaurant === restaurant.name);
            if (!giftsInsights) {
                return {
                    restaurantId: restaurant?._id ?? '',
                    restaurantName: restaurant?.getDisplayedValue() ?? '',
                    giftDrawCount: 0,
                    giftDrawCountDifferenceWithPreviousPeriod: null,
                    retrievedGiftDrawCount: 0,
                    retrievedGiftDrawCountDifferenceWithPreviousPeriod: null,
                    retrievedPercentage: 0,
                };
            }
            return {
                restaurantId: restaurant?._id ?? '',
                restaurantName: restaurant?.getDisplayedValue() ?? '',
                giftDrawCount: giftsInsights.giftDrawCount ?? 0,
                giftDrawCountDifferenceWithPreviousPeriod: (giftsInsights.giftDrawCount ?? 0) - (giftsInsights.previousGiftDrawCount ?? 0),
                retrievedGiftDrawCount: giftsInsights.retrievedGiftDrawCount ?? 0,
                retrievedGiftDrawCountDifferenceWithPreviousPeriod:
                    (giftsInsights.retrievedGiftDrawCount ?? 0) - (giftsInsights.previousRetrievedGiftDrawCount ?? 0),
                retrievedPercentage: giftsInsights.giftDrawCount
                    ? round((giftsInsights.retrievedGiftDrawCount * 100) / giftsInsights.giftDrawCount, GIFT_PERCENTAGE_DECIMALS)
                    : 0,
            };
        });
    });

    constructor() {
        effect(
            () => {
                this._updateMatTableData();
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    ngOnInit(): void {
        const tableSort = this.tableSort();
        if (tableSort) {
            this.defaultSort = tableSort;
        }
    }

    private _updateMatTableData(): void {
        try {
            this.isLoading.set(true);
            this.isError.set(false);
            this.dataSource.data = this.matTableData();
            this.isLoading.set(false);
            this.isError.set(false);
        } catch (error) {
            console.warn(error);
            this.isError.set(true);
            this.isLoading.set(false);
        }
    }
}
