import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, computed, effect, EventEmitter, Inject, OnInit, Output, Signal, viewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isNil, shuffle } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, forkJoin, from, interval, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
    ApplicationLanguage,
    CallToActionType,
    DEFAULT_MAX_IMAGE_SIZE,
    HeapEventName,
    InputMediaType,
    isLangInApplicationLanguages,
    KeywordScoreTextType,
    MalouErrorCode,
    MediaCategory,
    phoneRegex,
    PlatformDefinitions,
    PlatformKey,
    PostPublicationStatus,
    SeoPostTopic,
    TimeInMilliseconds,
    urlRegex,
} from '@malou-io/package-utils';

import { AutoSaveState, BindingIdKey, eventTitleTextLimit, Mo, postCaptionTextLimit, times } from ':core/constants';
import { DialogService } from ':core/services/dialog.service';
import { HeapService } from ':core/services/heap.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { UserState } from ':modules/user/store/user.reducer';
import { User } from ':modules/user/user';
import { FeedbacksPanelComponent } from ':shared/components/feedbacks-panel/feedbacks-panel.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { MediaEditorComponent } from ':shared/components/media-editor/media-editor.component';
import { PostCaptionAiGenerationComponent } from ':shared/components/post-caption-ai-generation/post-caption-ai-generation.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { getTimeStringFromDate, isControlValueInTimeFormat, isPastHour, IsUrl } from ':shared/helpers';
import { getCallToActionDefaultUrl } from ':shared/helpers/call-to-action-url';
import { createTextWithEmoji, CursorPosition } from ':shared/helpers/text-area-emoji.helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { ActionsAfterEditPostClosed, ApiResult, Keyword, Media, Post, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { FormatTimePipe } from ':shared/pipes/format-time.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { MediaService } from '../../media/media.service';
import * as fromPlatformsStore from '../../platforms/store/platforms.reducer';
import { NewPostModalAiContext } from '../context/new-post-modal-ai.context';
import { NewPostModalContext } from '../context/new-post-modal.context';
import { updatePostsBindingId } from '../posts.actions';
import { PostCallToActionComponent } from './post-call-to-action/post-call-to-action.component';
import { PostCaptionComponent } from './post-caption/post-caption.component';
import { AiButton, AppState, DateFormGroup, DialogStep, PostDateStatus, PostForm } from './types';
import { CrossCallToActionValidator, EventDatesValidator, isNotNull } from './utils';

export interface CallToActionOption {
    type: CallToActionType;
    text: string;
    actionLink?: string;
    disabledWithTooltip?: boolean;
    tooltipMessage?: string;
}

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-new-post-modal',
    templateUrl: './new-post-modal.component.html',
    styleUrls: ['./new-post-modal.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatFormFieldModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        TranslateModule,
        MediaEditorComponent,
        InputTextComponent,
        KeywordsScoreGaugeComponent,
        FeedbacksPanelComponent,
        SkeletonComponent,
        InputDatePickerComponent,
        SimpleSelectComponent,
        PostCaptionAiGenerationComponent,
        PostCaptionComponent,
        AsyncPipe,
        PluralTranslatePipe,
        IncludesPipe,
        ApplyPurePipe,
        FormatTimePipe,
        PostCallToActionComponent,
    ],
})
export class NewPostModalComponent implements OnInit, KillSubscriptions {
    readonly keywordsScoreGauge = viewChild<KeywordsScoreGaugeComponent>('keywordsScoreGauge');
    @Output() changePostEmitter = new EventEmitter<number>();

    readonly SvgIcon = SvgIcon;
    readonly DEFAULT_MAX_IMAGE_SIZE = DEFAULT_MAX_IMAGE_SIZE;

    readonly killSubscriptions$ = new Subject<void>();

    readonly isPastHour = isPastHour;

    readonly AiButton = AiButton;
    readonly PostDateStatus = PostDateStatus;
    readonly DialogStep = DialogStep;
    readonly InputMediaType = InputMediaType;
    readonly SeoPostTopic = SeoPostTopic;
    readonly AutoSaveStates = AutoSaveState;

    private readonly _IN_ONE_HOUR = new Date(new Date().setHours(new Date().getHours() + 1));
    private readonly _POST_TRANSLATE = this._translate.instant('posts.new_post');
    readonly FROM_DUPLICATE: boolean = this._route.snapshot.queryParams.FROM_DUPLICATE;
    readonly MIN_DATE = new Date();
    readonly DEFAULT_MAX_EVENT_DATE = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    readonly DEFAULT_MIN_EVENT_DATE = new Date(new Date().setHours(new Date().getHours() + 1));
    readonly POSITION_PAIRS: ConnectionPositionPair[] = [
        { offsetX: 20, offsetY: 90, originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', panelClass: undefined },
        { offsetX: 0, offsetY: 90, originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'bottom', panelClass: undefined },
    ];
    readonly APPLICATION_LANGUAGES = Object.values(ApplicationLanguage);

    readonly shouldAutoSave$ = new BehaviorSubject<{ field?: string; value?: any }>({});
    readonly postText$: BehaviorSubject<string> = new BehaviorSubject(this.data.post.text ?? '');
    readonly dialogStep$ = new BehaviorSubject(DialogStep.ONE);
    readonly restaurant$ = this._restaurantsService.restaurantSelected$;

    readonly showPicEditor$ = combineLatest([this._screenSizeService.resize$.pipe(map((resized) => resized.size)), this.dialogStep$]).pipe(
        map(([size, step]) => {
            if (size !== ScreenSize.IsSmallScreen) {
                return true;
            }
            return step === DialogStep.ONE;
        })
    );
    readonly showPostForm$ = combineLatest([this._screenSizeService.resize$.pipe(map((resized) => resized.size)), this.dialogStep$]).pipe(
        map(([size, step]) => {
            if (size !== ScreenSize.IsSmallScreen) {
                return true;
            }
            return step === DialogStep.TWO;
        })
    );
    readonly showFeedbackPanel$ = this.showPostForm$;
    readonly gaugeTextType$: BehaviorSubject<KeywordScoreTextType> = new BehaviorSubject<KeywordScoreTextType>(KeywordScoreTextType.POST);

    EVENT_TITLE_TEXT_LIMIT = eventTitleTextLimit;
    POST_CAPTION_TEXT_LIMIT = postCaptionTextLimit;

    showDateSection = true;
    sendEmptyAttachments = false;
    restaurant: Restaurant;
    restaurantKeywords$: Observable<Keyword[]>;
    imgUrl: string | null;
    currentUser$ = this._store.select('user');
    callToActions: CallToActionOption[];
    postTopics: { type: SeoPostTopic; text: string }[] = [];
    imgProperties: {
        bytes: number | null;
        width: number | null;
        height: number | null;
    } = {
        bytes: null,
        width: null,
        height: null,
    };
    shouldCreateMedia = false;
    croppedImage: string | null;
    shouldDuplicatePost: boolean | null = false;
    isLoading = false;
    isAlreadyPublished = false;
    autoSaveState: string = AutoSaveState.SAVED;
    hasBeenSavedByUser = false;
    isGmbConnected = true;
    postIndex = 0;
    duplicatedPostId: string | null;
    currentUser: UserState;
    isInitialLoading = true;
    times: string[] = times;
    medias: Media[] = [];
    restaurantManagers: User[];

    postLang: Signal<string | null> = computed(() => this.newPostModalContext.postLang() ?? null);
    postLang$: Observable<string | null> = toObservable(this.postLang);
    postId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    readonly isSmallScreen = toSignal(this._screenSizeService.resize$.pipe(map((elt) => elt.size === ScreenSize.IsSmallScreen)));

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _postsService: PostsService,
        private readonly _route: ActivatedRoute,
        private readonly _spinnerService: SpinnerService,
        private readonly _mediaService: MediaService,
        private readonly _store: Store<AppState>,
        private readonly _translate: TranslateService,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _malouDialogService: DialogService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _router: Router,
        private readonly _heapService: HeapService,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<NewPostModalComponent, { next: ActionsAfterEditPostClosed }>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            post: Post;
            restaurantKeywords: Keyword[];
            restaurantManagers: User[];
            restaurant: Restaurant;
            allPostIds?: string[];
        },
        private readonly _changeDetectorRef: ChangeDetectorRef,
        readonly newPostModalContext: NewPostModalContext,
        readonly newPostModalAiContext: NewPostModalAiContext
    ) {
        this.restaurant = this.data.restaurant;
        this.newPostModalContext.currentPost.set(this.data.post);
        this.newPostModalContext.postLang.set(this.data.post.language ?? null);
        this.newPostModalContext.postMedias.set(this.data.post.attachments ?? []);
        this.restaurantManagers = this.data.restaurantManagers;

        this.restaurantKeywords$ = of(this.data.restaurantKeywords);
        this.postId$.next(this.newPostModalContext.currentPost()!._id ?? null);

        const selectedRestaurantKeywords = this.data.restaurantKeywords.filter((keyword) => keyword.selected);
        this.newPostModalAiContext.initProperties(!!selectedRestaurantKeywords?.length, this.restaurant.ai?.activated);

        effect(() => {
            const postLang = this.postLang();
            if (postLang) {
                this.keywordsScoreGauge()?.brickLangControl.setValue(postLang);
            }
        });
    }

    get callToAction(): { actionType: { type: CallToActionType; text: string }; url: string | null } | null {
        return this.newPostModalContext.postForm.get('post.callToAction')?.value ?? null;
    }

    get postDescriptionControl(): FormControl {
        return this.newPostModalContext.postForm.get('post.text') as FormControl;
    }

    get attachments(): Media[] {
        return this.newPostModalContext.postForm.get('post.attachments')?.value ?? [];
    }

    get plannedPublicationDate(): Date | null {
        return this.newPostModalContext.postForm.get('post.plannedPublicationDate')?.value ?? null;
    }

    get postTopic(): { type: SeoPostTopic; text: string } | null {
        return this.newPostModalContext.postForm.get('post.postTopic')?.value ?? null;
    }

    get event(): { title: string | null; startDate: Date | null; endDate: Date | null } | null {
        return this.newPostModalContext.postForm.get('post.event')?.value ?? null;
    }

    get hasTextError(): boolean {
        return (
            (this.newPostModalContext.postForm.get('post.text')?.invalid && this.newPostModalContext.postForm.get('post.text')?.touched) ??
            false
        );
    }

    get eventEndDate(): Date | null {
        return this.newPostModalContext.postForm.get('post.event.endDate')?.value ?? null;
    }

    get offer(): { couponCode: string; onlineUrl: string; termsConditions: string } | null {
        return this.newPostModalContext.postForm.get('post.offer')?.value ?? null;
    }

    get maxLengthError(): string {
        const maxLengthError = this.newPostModalContext.postForm.get('post.text')?.errors?.maxlength;
        return maxLengthError ? this._POST_TRANSLATE.message_too_long : null;
    }

    get postDate(): Date | null {
        return this.newPostModalContext.postForm.get('date.postDate')?.value ?? null;
    }

    get attachmentsName(): string | null {
        return this.newPostModalContext.postForm.get('post.attachmentsName')?.value ?? null;
    }

    get containPhoneNumberError(): boolean {
        return this.newPostModalContext.postForm.get('post.text')?.value?.search(phoneRegex) !== -1;
    }

    get formErrors(): string[] {
        const errors: string[] = [];
        if (!this.newPostModalContext.postForm.get('post.text')?.value?.trim().length) {
            errors.push(this._POST_TRANSLATE.write_message);
        }
        if (this.newPostModalContext.postForm.get('post.event')?.errors?.error) {
            errors.push(this._POST_TRANSLATE[this.newPostModalContext.postForm.get('post.event')?.errors?.error]);
        }
        if (this.postTopic?.type !== SeoPostTopic.STANDARD) {
            if (!this.event?.title?.trim()?.length) {
                errors.push(this._POST_TRANSLATE.event_should_have_title);
            }
            if (!this.event?.startDate) {
                errors.push(this._POST_TRANSLATE.event_needs_start_date);
            }
            if (!this.event?.endDate) {
                errors.push(this._POST_TRANSLATE.event_needs_end_date);
            }
            const endDate = this.event?.endDate && DateTime.fromJSDate(new Date(this.event.endDate));
            if (endDate && endDate < DateTime.now()) {
                errors.push(this._POST_TRANSLATE.event_needs_end_date_future);
            }

            if (this.postTopic?.type === SeoPostTopic.OFFER) {
                if (this.offer?.onlineUrl && !this.isUrl(this.offer?.onlineUrl)) {
                    errors.push(this._POST_TRANSLATE.url_offer_invalid);
                }
            }
        }
        if (
            this.postTopic &&
            [SeoPostTopic.STANDARD, SeoPostTopic.EVENT].includes(this.postTopic.type) &&
            this.showCallToActionUrl(this.callToAction?.actionType?.type ?? null)
        ) {
            if (!this.callToAction?.url) {
                errors.push(this._POST_TRANSLATE.url_action_should_not_be_empty);
            } else if (!this.isUrl(this.callToAction?.url)) {
                errors.push(this._POST_TRANSLATE.url_action_invalid);
            }
        }
        if (this.maxLengthError) {
            errors.push(this.maxLengthError);
        }
        return errors;
    }

    getPostCaptionErrorMessages(
        textLength: number | undefined,
        captionTextLimit: number,
        containPhoneNumberError: boolean,
        hasTextError: boolean
    ): string | undefined {
        if (textLength && textLength > captionTextLimit) {
            return this._translate.instant('posts.new_post.post_caption_text_limit', {
                captionTextLimit,
            });
        } else if (containPhoneNumberError) {
            return this._translate.instant('posts.new_post.post_caption_contains_phone_number');
        } else if (hasTextError) {
            return this._translate.instant('common.required_field');
        } else {
            return undefined;
        }
    }

    ngOnInit(): void {
        this.newPostModalAiContext.initInteractions(this.newPostModalContext.currentPost()!._id);
        this.newPostModalContext.currentPost()!.initMedias();
        this.postTopics = [
            { type: SeoPostTopic.STANDARD, text: this._translate.instant('posts.new_post.new') },
            { type: SeoPostTopic.EVENT, text: this._translate.instant('posts.new_post.event') },
            { type: SeoPostTopic.OFFER, text: this._translate.instant('posts.new_post.offer') },
        ];
        this.callToActions = [
            {
                type: CallToActionType.BOOK,
                text: this._enumTranslatePipe.transform(CallToActionType.BOOK, 'call_to_action_type'),
                actionLink: getCallToActionDefaultUrl(CallToActionType.BOOK, this.data.restaurant),
                disabledWithTooltip: !getCallToActionDefaultUrl(CallToActionType.BOOK, this.data.restaurant),
                tooltipMessage: this._translate.instant('posts.new_post.add_informations_to_use'),
            },
            {
                type: CallToActionType.ORDER,
                text: this._enumTranslatePipe.transform(CallToActionType.ORDER, 'call_to_action_type'),
                actionLink: getCallToActionDefaultUrl(CallToActionType.ORDER, this.data.restaurant),
                disabledWithTooltip: !getCallToActionDefaultUrl(CallToActionType.ORDER, this.data.restaurant),
                tooltipMessage: this._translate.instant('posts.new_post.add_informations_to_use'),
            },
            {
                type: CallToActionType.LEARN_MORE,
                text: this._enumTranslatePipe.transform(CallToActionType.LEARN_MORE, 'call_to_action_type'),
                actionLink: getCallToActionDefaultUrl(CallToActionType.LEARN_MORE, this.data.restaurant),
                disabledWithTooltip: false,
            },
            {
                type: CallToActionType.SIGN_UP,
                text: this._enumTranslatePipe.transform(CallToActionType.SIGN_UP, 'call_to_action_type'),
                actionLink: getCallToActionDefaultUrl(CallToActionType.SIGN_UP, this.data.restaurant),
                disabledWithTooltip: false,
            },
            {
                type: CallToActionType.CALL,
                text: this._enumTranslatePipe.transform(CallToActionType.CALL, 'call_to_action_type'),
                actionLink: getCallToActionDefaultUrl(CallToActionType.CALL, this.data.restaurant),
                disabledWithTooltip: !getCallToActionDefaultUrl(CallToActionType.CALL, this.data.restaurant),
                tooltipMessage: this._translate.instant('posts.new_post.add_informations_to_use'),
            },
            { type: CallToActionType.NONE, text: this._enumTranslatePipe.transform(CallToActionType.NONE, 'call_to_action_type') },
        ];
        if (this.newPostModalContext.currentPost()!.published === PostPublicationStatus.PUBLISHED) {
            this.showDateSection = false;
            this.isAlreadyPublished = true;
        }

        this.postIndex = this.data?.allPostIds?.findIndex((postId) => this.data.post._id === postId) ?? 0;
        this.initPostForm();

        this.shouldDuplicatePost = this.newPostModalContext.currentPost()!.shouldDuplicateInOtherPlatforms ?? null;
        this._store.select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB })).subscribe({
            next: (platform) => {
                this.isGmbConnected = (platform?.credentials?.length ?? 0) > 0;
            },
        });

        this.newPostModalContext.postForm.get('post.postTopic')?.valueChanges.subscribe(() => {
            this.newPostModalContext.postForm.get('post.event')?.patchValue({
                title: this.newPostModalContext.postForm.get('post.event.title')?.value ?? null,
                startDate: this.newPostModalContext.postForm.get('post.event.startDate')?.value ?? null,
                endDate: this.newPostModalContext.postForm.get('post.event.endDate')?.value ?? null,
            });
        });
        this.newPostModalContext.postForm
            .get('post.postTopic')
            ?.valueChanges.pipe(skip(1))
            .subscribe(() => this._setDefaultPostCallToAction());
        this._initAutoSaveContent();

        this.isInitialLoading = false;
        this._changeDetectorRef.detectChanges(); // prevent ExpressionChangedAfterItHasBeenCheckedError

        this.isLoading = false;

        this._dialogRef
            .backdropClick()
            .pipe(takeUntil(this.killSubscriptions$))
            .subscribe({ next: () => this.openSaveBeforeClose(), error: () => {} });
        this._dialogRef
            .keydownEvents()
            .pipe(
                filter((event) => event.key === 'Escape'),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({ next: () => this.openSaveBeforeClose(), error: () => {} });

        this.shouldAutoSave$
            .pipe(
                filter(({ field }) => !!field),
                switchMap(({ field, value }: { field: string; value: any }): Observable<null> | Observable<ApiResult<Post>> => {
                    if (this.hasBeenSavedByUser) {
                        return of(null);
                    }
                    if (
                        this.data?.post._id &&
                        (this.data?.post?.published === PostPublicationStatus.DRAFT ||
                            (this.newPostModalContext.currentPost()?.published === PostPublicationStatus.PENDING &&
                                !this.newPostModalContext.currentPost()?.plannedPublicationDate))
                    ) {
                        this.newPostModalContext.currentPost()?.refreshData({ [field]: value });
                        let post;
                        if (field === 'attachments') {
                            post = { [field]: value.map((media: Media) => media.id) };
                        } else {
                            post = { [field]: value };
                        }
                        return this._postsService.preparePost(this.newPostModalContext.currentPost()!._id, {
                            post,
                            keys: PlatformDefinitions.getSeoPlatformKeysWithPost(),
                            draft: true,
                        });
                    }
                    return of(null);
                })
            )
            .subscribe({
                next: () => {
                    this.autoSaveState = AutoSaveState.SAVED;
                },
                error: (err) => {
                    console.warn('Error: > > Error autosaving : ' + err);
                },
            });

        this.currentUser$.subscribe((user) => {
            this.currentUser = user;
        });

        this._screenSizeService.resize$.subscribe((elt) => {
            this.newPostModalContext.isSmallScreen.update(() => elt.size === ScreenSize.IsSmallScreen);
        });
    }

    showCallToActionUrl(callToActionType: CallToActionType | null): boolean {
        if (!callToActionType) {
            return false;
        }
        switch (callToActionType) {
            case CallToActionType.CALL:
            case CallToActionType.NONE:
                return false;
            default:
                return true;
        }
    }

    mapFormValueToPostValue(field: string, value: any): any {
        switch (field) {
            case 'postTopic':
                return (value as { type: SeoPostTopic; text: string }).type;
            case 'callToAction.actionType':
                return (value as { type: CallToActionType; text: string })?.type === CallToActionType.NONE ? null : value?.type;
            default:
                return value;
        }
    }

    navigateToInformations(): void {
        this.openSaveBeforeClose({ afterSaveFunction: this._redirectToInformations.bind(this) });
    }

    displayWithPostTopic(topic: { type: SeoPostTopic; text: string }): string {
        return topic.text;
    }

    openSaveBeforeClose({ afterSaveFunction }: { afterSaveFunction?: Function | null } = { afterSaveFunction: null }): void {
        if (this.data?.post?.published === PostPublicationStatus.DRAFT) {
            this.willCreateMedium$().subscribe();
            this.cancel({ reload: false, postId: this.newPostModalContext.currentPost()!._id });
            if (afterSaveFunction) {
                afterSaveFunction();
            }
        } else if (this.newPostModalContext.postForm.dirty) {
            this._malouDialogService.open({
                variant: DialogVariant.INFO,
                title: this._translate.instant('posts.new_post.should_we_save'),
                message: this._translate.instant('posts.new_post.changes_have_been_made'),
                secondaryButton: {
                    label: this._translate.instant('posts.new_post.close_without_saving'),
                    action: () => {
                        this.cancel({ reload: false });
                        if (afterSaveFunction) {
                            afterSaveFunction();
                        }
                        return;
                    },
                },
                primaryButton: {
                    label: this._translate.instant('social_posts.new_social_post.back_to_editing'),
                },
            });
        } else {
            this.cancel({ reload: false });
        }
    }

    getPostDateStatus(post: Post): PostDateStatus {
        switch (post.published) {
            case PostPublicationStatus.PUBLISHED:
            case PostPublicationStatus.ERROR:
            case PostPublicationStatus.REJECTED:
                return PostDateStatus.NOW;
            case PostPublicationStatus.PENDING:
                return PostDateStatus.LATER;
            case PostPublicationStatus.DRAFT:
                if (this._isPostEmpty()) {
                    // the post is a new draft
                    return PostDateStatus.LATER;
                } else {
                    return PostDateStatus.DRAFT;
                }
            default:
                return PostDateStatus.DRAFT;
        }
    }

    getCorrectEndDate(): Date {
        const now = new Date();
        return this.eventEndDate && new Date(this.eventEndDate)?.getTime() > now.getTime()
            ? this.eventEndDate
            : this.DEFAULT_MAX_EVENT_DATE;
    }

    initPostForm(): void {
        const cleanRestaurantName = this.data.restaurant.name?.normalize('NFD').trim().toLowerCase();

        const publicationDate = this._computePostPublicationDate(this.newPostModalContext.currentPost()!.plannedPublicationDate);
        this.newPostModalContext.postForm = this._fb.group({
            post: this._fb.group({
                text: [
                    this.newPostModalContext.currentPost()!.text ?? '',
                    [Validators.required, Validators.maxLength(this.POST_CAPTION_TEXT_LIMIT)],
                ],
                language: [this.newPostModalContext.currentPost()!.language || LocalStorage.getLang(), Validators.required],
                attachments: [this.newPostModalContext.currentPost()!.attachments?.filter((v) => !!v) || []],
                attachmentsName: [
                    this.newPostModalContext.currentPost()!.attachmentsName ||
                        shuffle(this.data.restaurantKeywords?.filter((k) => k.selected) || [])
                            .slice(0, 3)
                            .map((k) => k.text)
                            .concat(cleanRestaurantName)
                            .join(' - ') ||
                        cleanRestaurantName,
                ],
                plannedPublicationDate: [publicationDate],
                postTopic: [
                    this.newPostModalContext.currentPost()!.postTopic
                        ? this.postTopics.find((topic) => topic.type === this.newPostModalContext.currentPost()!.postTopic)
                        : this.postTopics[0],
                ],
                event: this._fb.group(
                    {
                        title: [this.newPostModalContext.currentPost()!.event?.title ?? null],
                        startDate: [this.newPostModalContext.currentPost()!.event?.startDate ?? null],
                        endDate: [this.newPostModalContext.currentPost()!.event?.endDate ?? null],
                    },
                    { validators: EventDatesValidator }
                ),
                offer: this._fb.group({
                    couponCode: [this.newPostModalContext.currentPost()!.offer?.couponCode ?? null],
                    onlineUrl: [this.newPostModalContext.currentPost()!.offer?.onlineUrl ?? null, [IsUrl()]],
                    termsConditions: [this.newPostModalContext.currentPost()!.offer?.termsConditions ?? null],
                }),
                callToAction: this._fb.group(
                    {
                        actionType: [this._initCallToActionType()],
                        url: [this.newPostModalContext.currentPost()!.callToAction?.url ?? null, [IsUrl(), isNotNull]],
                    },
                    { validators: CrossCallToActionValidator }
                ),
            }),
            keys: this._fb.array(PlatformDefinitions.getSeoPlatformKeysWithPost()),
            date: this._fb.group({
                postDateStatus: [this.getPostDateStatus(this.newPostModalContext.currentPost()!)],
                postDate: [publicationDate],
                postTime: [getTimeStringFromDate(publicationDate), isControlValueInTimeFormat(this._translate)],
            }),
        });
        this.newPostModalContext.postForm.get('post.text')?.valueChanges.subscribe((value) => {
            this.postText$.next(value);
            this.newPostModalAiContext.isAiOptimizeButtonDisplayed.set(true);
        });
        if (!this.newPostModalContext.currentPost()!.callToAction) {
            this._setDefaultPostCallToAction({ type: this.newPostModalContext.currentPost()!.callToAction?.actionType });
        }
    }

    updateImageProperties(url: string | null, bytes: number | null = null): void {
        if (url) {
            const img = new Image();
            img.onload = (): void => {
                this.imgProperties.width = img.width;
                this.imgProperties.height = img.height;
            };
            img.src = url;
        } else {
            this.imgProperties.width = null;
            this.imgProperties.height = null;
        }
        this.imgProperties.bytes = bytes || null;
    }

    changeSelectedTime(event: MatSelectChange): void {
        this.newPostModalContext.postForm.get('date.postTime')?.patchValue(event.value);
    }

    hasMediaErrors(): boolean {
        return (this.newPostModalContext.postForm.get('post.attachments')?.value as Media[]).some((m) => !!m.hasErrors());
    }

    isUrl(url: string): boolean {
        return urlRegex.test(url);
    }

    removeMedia(): void {
        this.newPostModalContext.postForm.get('post.attachments')?.patchValue([]);
        this.newPostModalContext.postMedias.set([]);
        this.imgUrl = null;
        this.updateImageProperties(this.imgUrl, null);
        this.sendEmptyAttachments = true;
        this.shouldCreateMedia = false;
        this.croppedImage = null;
    }

    onMediaSelect(medias: Media[]): void {
        if (!medias?.length) {
            this.removeMedia();
            return;
        }
        const [media] = medias;
        this.newPostModalContext.postForm.get('post.attachments')?.patchValue(medias);
        this.newPostModalContext.postMedias.set(medias);
        this.checkFileSize(media.getBytesForSize(), media.format);
        if (media.title) {
            this.newPostModalContext.postForm.get('post.attachmentsName')?.patchValue(media.title);
        }
        if (media.urls.igFit) {
            this.updateImageProperties(media.urls.igFit as string, media.getBytesForSize());
        }

        this.shouldCreateMedia = false;
        this.croppedImage = null;
        this._updateWorkingImage(media);
        this.newPostModalContext.postForm.markAsDirty();
    }

    checkFileSize(size: number, format: string): void {
        if (this._isFileTooBig(size, format)) {
            this._malouDialogService.open({
                variant: DialogVariant.INFO,
                title: '',
                message: this._translate.instant('posts.new_post.large_file'),
                primaryButton: {
                    label: this._translate.instant('common.close'),
                },
            });
        }
    }

    getPublicationDate(): Date | null {
        if (!this.newPostModalContext.postForm.get('date')?.value) {
            return null;
        }

        const { postDate, postTime, postDateStatus } = this.newPostModalContext.postForm.get('date')?.value as DateFormGroup;
        switch (postDateStatus) {
            case PostDateStatus.NOW:
                return new Date();
            case PostDateStatus.LATER:
            case PostDateStatus.DRAFT:
                const publicationDate = postDate instanceof DateTime ? postDate.toJSDate() : postDate;
                const [hours, minutes] = postTime.split(':');
                if (hours && minutes) {
                    publicationDate?.setHours(parseInt(hours, 10));
                    publicationDate?.setMinutes(parseInt(minutes, 10));
                    if (publicationDate?.getTime() < new Date().getTime()) {
                        this._toastService.openErrorToast(this._translate.instant('social_posts.feed_view.not_previous_date'));
                        return null;
                    }
                }
                return publicationDate;
            default:
                break;
        }
        return this.newPostModalContext.postForm.get('post.plannedPublicationDate')?.value ?? null;
    }

    willCreateMedium$(originalMediaId = null): Observable<Media | null> {
        if (!this.croppedImage) {
            return of(null);
        }
        return this.shouldCreateMedia
            ? from(
                  fetch(this.croppedImage)
                      .then((res) => res.blob())
                      .then((blob) => new File([blob], String(Math.random()), { type: 'image/png' }))
              ).pipe(
                  switchMap((file) =>
                      this.currentUser$.pipe(
                          switchMap(() =>
                              this._mediaService.uploadAndCreateMedia([
                                  {
                                      data: file,
                                      metadata: {
                                          restaurantId: this.restaurant._id,
                                          category: MediaCategory.ADDITIONAL,
                                          originalMediaId,
                                      },
                                  },
                              ])
                          ),
                          tap(() => console.info('Will create medium')),
                          map((res) => res.data[0]),
                          tap((media: Media) => {
                              if (media) {
                                  this.onMediaSelect([new Media(media)]);
                              }
                          }),
                          switchMap(() =>
                              this.attachments?.length
                                  ? this._mediaService
                                        .updateMediaById(this.attachments[0].id, {
                                            title: this.newPostModalContext.postForm.get('post.attachmentsName')?.value,
                                        })
                                        .pipe(map((res) => res.data))
                                  : of(null)
                          )
                      )
                  )
              )
            : of(null);
    }

    updatePost$({ postData, draft = false }: { postData: Partial<Post>; draft: boolean }): Observable<ApiResult<Post>> {
        return this.willCreateMedium$().pipe(
            switchMap((newMedia) => {
                if (!this.sendEmptyAttachments && this.attachments.length === 0) {
                    // we send [] only if it is clearly asked otherwise we don't touch the platform's post media
                    delete postData.attachments;
                }
                if (newMedia && postData?.attachments?.length) {
                    Object.assign(postData, { attachments: [newMedia.id] });
                }
                if (postData.attachments?.length) {
                    Object.assign(postData, { attachments: postData.attachments.map((media) => media.id) });
                }
                return this._postsService.preparePost(this.newPostModalContext.currentPost()!._id, {
                    post: postData,
                    keys: PlatformDefinitions.getSeoPlatformKeysWithPost(),
                    draft,
                });
            })
        );
    }

    getTrackingNameFromPublishButtonType(postDateStatus: PostDateStatus): string {
        switch (postDateStatus) {
            case PostDateStatus.NOW:
                return 'tracking_new_post_modal_publish_post_button';
            case PostDateStatus.LATER:
                return 'tracking_new_post_modal_schedule_post_button';
            default:
                return 'tracking_new_post_modal_draft_post_button';
        }
    }

    getPublishButtonText(): string {
        if (this.isAlreadyPublished) {
            return this._translate.instant('posts.new_post.update');
        }
        switch (this.newPostModalContext.postForm.get('date.postDateStatus')?.value) {
            case PostDateStatus.NOW:
                return this._translate.instant('posts.new_post.publish');
            case PostDateStatus.LATER:
                return this._translate.instant('posts.new_post.later');
            default:
                return this._translate.instant('posts.new_post.draft');
        }
    }

    mapFormToPost(formData: PostForm): Post {
        const language = formData.get('post.language')?.value;
        return new Post({
            text: this._formatPostTextBeforeSend(formData.get('post.text')?.value),
            language: isLangInApplicationLanguages(language ?? '') ? language : this.newPostModalContext.currentPost()?.language,
            plannedPublicationDate: formData.get('date.postDate')?.value,
            attachments: formData.get('post.attachments')?.value,
            attachmentsName: formData.get('post.attachmentsName')?.value,
            callToAction: {
                actionType: this.mapFormValueToPostValue('callToAction.actionType', formData.get('post.callToAction.actionType')?.value),
                url: formData.get('post.callToAction.url')?.value ?? '',
            },
            postTopic: this.mapFormValueToPostValue('postTopic', formData.get('post.postTopic')?.value),
            event: {
                title: formData.get('post.event.title')?.value ?? '',
                startDate: formData.get('post.event.startDate')?.value ?? new Date(),
                endDate: formData.get('post.event.endDate')?.value ?? DateTime.now().plus({ days: 1 }).toJSDate(),
            },
            offer: {
                couponCode: formData.get('post.offer.couponCode')?.value ?? '',
                onlineUrl: (formData.get('post.offer.onlineUrl')?.valid && formData.get('post.offer.onlineUrl')?.value) || '',
                termsConditions: formData.get('post.offer.termsConditions')?.value ?? '',
            },
        });
    }

    upsertRelatedUrl(restaurant: Restaurant, url: string | null): void {
        if (!url) {
            return;
        }
        if (!restaurant.relatedUrls?.includes(url)) {
            const relatedUrls = [...restaurant.relatedUrls, url].filter(Boolean);
            this._restaurantsService.update(restaurant._id, { relatedUrls }).subscribe({
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
        }
    }

    save(
        { createDraft } = { createDraft: this.newPostModalContext.postForm.get('date.postDateStatus')?.value === PostDateStatus.DRAFT }
    ): void {
        this._spinnerService.show();
        if (this.newPostModalContext.postForm.get('date.postDateStatus')?.value === PostDateStatus.DRAFT) {
            createDraft = true;
        }
        this.hasBeenSavedByUser = true;
        const publicationDate = this.getPublicationDate();
        if (!publicationDate) {
            return;
        }
        if (
            !this.callToAction?.actionType?.type ||
            ![CallToActionType.CALL, CallToActionType.NONE].includes(this.callToAction.actionType.type)
        ) {
            this.upsertRelatedUrl(this.restaurant, this.callToAction?.url ?? null);
        }
        const postFormData = this.mapFormToPost(this.newPostModalContext.postForm);
        const postData = {
            ...postFormData,
            keywordAnalysis: {
                score: this.keywordsScoreGauge()?.score() ?? 0,
                keywords: this.keywordsScoreGauge()?.bricksFound() ?? [],
                count: this.keywordsScoreGauge()?.bricksFound()?.length,
            },
            feedbackId: this.newPostModalContext.currentPost()!.feedbackId,
            plannedPublicationDate: publicationDate,
        };

        // The request uses JSON.stringify which can cause the date to change because of the timezone
        if (postData?.event) {
            postData.event.startDate = this._formatDatesForBeforeSend(new Date(postData.event.startDate));
            postData.event.endDate = this._formatDatesForBeforeSend(new Date(postData.event.endDate));
        }
        if (postData?.event && new Date(postData.event.startDate).getTime() === new Date(postData.event.endDate).getTime()) {
            postData.event.endDate = new Date(postData.event.endDate.getTime() + 2 * TimeInMilliseconds.MINUTE);
        }
        this.updatePost$({ postData, draft: createDraft })
            .pipe(
                switchMap((res) =>
                    forkJoin([
                        of(res.data),
                        this.shouldDuplicatePost
                            ? this._postsService.duplicatePost$(res.data, PlatformDefinitions.getSocialPlatformKeysWithDuplicablePosts())
                            : of(null),
                    ])
                )
            )
            .subscribe({
                next: ([newPost, duplicatedPost]) => {
                    this._spinnerService.hide();
                    this.duplicatedPostId = duplicatedPost?._id ?? null;
                    this._heapService.track(HeapEventName.POST_SAVED, {
                        postId: newPost?._id,
                        restaurantId: newPost?.restaurantId,
                        postSource: newPost?.source,
                        aiImageUsed: this.newPostModalAiContext.hasUsedMediaAnalysisToGeneratePostText(),
                        aiUsed: this.newPostModalAiContext.hasUsedAiToGeneratePostText(),
                    });
                    if (createDraft) {
                        this.cancel({
                            reload: true,
                            postId: this.isAlreadyPublished ? null : this.newPostModalContext.currentPost()!._id,
                            duplicatedPostId: this.duplicatedPostId,
                        });
                    } else {
                        if (this.newPostModalContext.postForm.get('date.postDateStatus')?.value === PostDateStatus.NOW) {
                            if (newPost?.bindingId) {
                                this._store.dispatch(
                                    updatePostsBindingId({ bindingId: newPost.bindingId, bindingIdKey: BindingIdKey.BINDING_ID })
                                );
                            }
                            this.cancel({ reload: false, duplicatedPostId: this.duplicatedPostId });
                        } else {
                            if (this.newPostModalContext.currentPost()!._id) {
                                this._toastService.openSuccessToast(this.getSaveText());
                                this.cancel({
                                    postId: this.newPostModalContext.currentPost()!._id,
                                    duplicatedPostId: this.duplicatedPostId,
                                });
                                return;
                            }
                            this.cancel({ reload: true, duplicatedPostId: this.duplicatedPostId });
                        }
                    }
                },
                error: (err) => {
                    console.warn('error :>> ', err);
                    this._spinnerService.hide();
                    if (err.status !== 403) {
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    }
                },
            });
    }

    // todo delete unusued
    startStatusWatcher(bindingId: string): void {
        this._spinnerService.show();
        const killStatus$ = new Subject();
        let count = 0;
        interval(1000)
            .pipe(
                switchMap(() => this._postsService.getPostsByBindingId(bindingId)),
                takeUntil(killStatus$)
            )
            .subscribe({
                next: (res) => {
                    count += 1;
                    if (res?.data) {
                        if (this._allPostsHaveBeenPublished(res?.data)) {
                            this._spinnerService.hide();
                            killStatus$.next(true);
                            this.cancel({ reload: true, duplicatedPostId: this.duplicatedPostId });
                        }

                        if (this._onePostHasFailedToPublish(res?.data)) {
                            this._spinnerService.hide();
                            killStatus$.next(true);
                            this.cancel({ reload: true });
                            count = 0;
                            return;
                        }

                        if (count > 60) {
                            this._spinnerService.hide();
                            killStatus$.next(true);
                            this.cancel({ reload: true, duplicatedPostId: this.duplicatedPostId });
                        }
                    }
                },
                error: (err) => {
                    console.warn('err', err);
                    this._spinnerService.hide();
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    getSaveText(): string {
        if (this.isAlreadyPublished) {
            return this._POST_TRANSLATE.updated_post;
        }
        switch (this.newPostModalContext.postForm.get('date.postDateStatus')?.value) {
            case PostDateStatus.NOW:
                return this._POST_TRANSLATE.post_in_progress;
            case PostDateStatus.LATER:
                return this._POST_TRANSLATE.planned_post;
        }

        return this._POST_TRANSLATE.planned_post;
    }

    clarifyError(err: any): string {
        const message: string = err?.error?.malouErrorCode || err?.error?.message || err?.message || String(err);
        if (message.match(/Forbidden/)) {
            return this._POST_TRANSLATE.operation_not_carried;
        }
        if (message.match(MalouErrorCode.PLATFORM_CREDENTIAL_NOT_FOUND)) {
            return this._POST_TRANSLATE.reconnect_platform;
        }
        if (message.match(/Image format is not supported/)) {
            return this._POST_TRANSLATE.format_not_supported;
        }
        if (message.match(/Fetching image failed/)) {
            return this._POST_TRANSLATE.fetching_image_failed;
        }
        if (message.match(/HEVC/)) {
            return this._translate.instant('social_posts.new_social_post.unsupported_codec');
        }
        return message;
    }

    toggleShouldDuplicatePost(): void {
        this.shouldDuplicatePost = !this.shouldDuplicatePost;
        this.shouldAutoSave$.next({ field: 'shouldDuplicateInOtherPlatforms', value: this.shouldDuplicatePost });
    }

    canPublish(): boolean {
        if (this.newPostModalAiContext.isGeneratingPostTextFromAI()) {
            return false;
        }
        const postDateStatus = this.newPostModalContext.postForm?.get('date.postDateStatus')?.value;
        if (postDateStatus === PostDateStatus.DRAFT) {
            return !this.containPhoneNumberError && this._isPublicationDateValid();
        }

        const postAttachments = this.newPostModalContext.postForm?.get('post.attachments')?.value;

        if ([PostDateStatus.LATER, PostDateStatus.NOW].includes(postDateStatus!) && !postAttachments?.length) {
            return false;
        }

        return (
            !this.hasMediaErrors() &&
            this._isPublicationDateValid() &&
            this.isGmbConnected &&
            !this.formErrors.length &&
            !this.containPhoneNumberError
        );
    }

    private _isPublicationDateValid(): boolean {
        if (!this.postDate) {
            return false;
        }
        return this.postDate >= new Date();
    }

    cancel(actionsAfterClosed: ActionsAfterEditPostClosed): void {
        this.killSubscriptions$.next();
        this._dialog.closeAll();
        const shouldDeleteAfterClose = this._isPostFormEmpty();

        this._dialogRef.close({ next: { ...actionsAfterClosed, shouldDeleteAfterClose } });
    }

    addKeyword(keyword: string): void {
        const currentText = this.newPostModalContext.postForm.get('post')?.get('text')?.value;
        if (currentText && currentText?.length + keyword.length > this.POST_CAPTION_TEXT_LIMIT) {
            return;
        }
        const textarea = document.getElementById('postText') as HTMLTextAreaElement | null;
        if (!textarea) {
            console.error('Cannot get text area element');
            return;
        }
        const cursorPosition: CursorPosition = {
            startPosition: textarea?.selectionStart,
            endPosition: textarea?.selectionEnd,
        };
        const newText = createTextWithEmoji(
            cursorPosition,
            currentText ?? '',
            this._computeTextWithSpace(currentText ?? '', cursorPosition, keyword)
        );
        this.newPostModalContext.postForm.get('post')?.get('text')?.setValue(newText);
        setTimeout(() => {
            textarea.selectionStart = cursorPosition.endPosition + keyword?.length + 1;
            textarea.focus();
        }, 1);
    }

    onSelectUrl(value: string | null): void {
        this.newPostModalContext.postForm.get('post')?.get('callToAction')?.get('url')?.patchValue(value);
    }

    onSelectCallToAction(value: CallToActionType): void {
        const callToAction = this.callToActions.find((t) => t.type === value);
        if (callToAction) {
            this.newPostModalContext.postForm.get('post')?.get('callToAction.actionType')?.patchValue(callToAction);
            if (callToAction.actionLink) {
                this.newPostModalContext.postForm.get('post')?.get('callToAction.url')?.patchValue(callToAction.actionLink);
            }
            if (callToAction.type === CallToActionType.NONE) {
                this.newPostModalContext.postForm.get('post')?.get('callToAction.url')?.patchValue(null);
            }
        }
    }

    changePost(diff: number): void {
        this.isInitialLoading = true;
        const isFirstPost = this.postIndex === 0;
        const isLastPost = this.postIndex === (this.data.allPostIds?.length ?? 1) - 1;
        if ((isFirstPost && diff === -1) || (isLastPost && diff === 1)) {
            return;
        }
        const currentRouteWithoutParams = this._router.url.split('?')[0];
        this._router.navigate([currentRouteWithoutParams], { queryParams: { postId: this.data.allPostIds?.[this.postIndex + diff] } });
    }

    private _initCallToActionType(): CallToActionOption | null {
        const callToActionType = this.newPostModalContext.currentPost()!.callToAction?.actionType;
        if (isNil(callToActionType)) {
            return this.callToActions.find((cta) => cta.type === CallToActionType.NONE) ?? null;
        }
        return this.callToActions.find((cta) => cta.type === callToActionType) ?? null;
    }

    private _computeTextWithSpace(currentText: string, { startPosition, endPosition }: CursorPosition, text: string): string {
        const hasSpaceBefore = currentText[startPosition - 1] === ' ';
        const hasSpaceAfter = currentText[endPosition] === ' ';
        const shouldAddSpaceBefore = currentText[startPosition - 1] && !hasSpaceBefore;
        const shouldAddSpaceAfter = currentText[endPosition] && !hasSpaceAfter;
        return `${shouldAddSpaceBefore ? ' ' : ''}${text}${shouldAddSpaceAfter ? ' ' : ''}`;
    }

    private _setDefaultPostCallToAction(defaultType?: { type: CallToActionType }): void {
        const postTopic = this.newPostModalContext.postForm.get('post.postTopic')?.value?.type;
        const hasCallToAction = defaultType
            ? !!defaultType.type
            : !!this.newPostModalContext.postForm.get('post.callToAction.actionType')?.value;
        if (!hasCallToAction && (postTopic === SeoPostTopic.EVENT || postTopic === SeoPostTopic.STANDARD)) {
            if (this.data.restaurant.website) {
                this.onSelectCallToAction(CallToActionType.LEARN_MORE);
            } else if (this.data.restaurant.reservationUrl) {
                this.onSelectCallToAction(CallToActionType.BOOK);
            } else if (this.data.restaurant.orderUrl) {
                this.onSelectCallToAction(CallToActionType.ORDER);
            } else if (this.data.restaurant.phone) {
                this.onSelectCallToAction(CallToActionType.CALL);
            } else {
                this.onSelectCallToAction(CallToActionType.NONE);
            }
        }
    }

    private _isDraftOrIsPendingWithNoPlannedDate(post: Post): boolean {
        return (
            post?.published === PostPublicationStatus.DRAFT ||
            (post?.published === PostPublicationStatus.PENDING && !post?.plannedPublicationDate)
        );
    }

    private _updatePostFromForm$({ draft }: { draft: true }): Observable<ApiResult<Post>> {
        const postData = this.mapFormToPost(this.newPostModalContext.postForm);
        Object.assign(postData, { attachments: postData.attachments?.map((a) => a.id) ?? [] });
        return this._postsService.preparePost(this.newPostModalContext.currentPost()!._id, {
            post: postData,
            keys: PlatformDefinitions.getSeoPlatformKeysWithPost(),
            draft,
        });
    }

    private _initAutoSaveContent(): void {
        if (this._isDraftOrIsPendingWithNoPlannedDate(this.newPostModalContext.currentPost()!)) {
            this.newPostModalContext.postForm
                .get('post')
                ?.valueChanges.pipe(
                    debounceTime(500),
                    switchMap(() => {
                        this.autoSaveState = AutoSaveState.SAVING;
                        return this._updatePostFromForm$({ draft: true });
                    })
                )
                .subscribe({
                    next: (_updated) => {
                        this.autoSaveState = AutoSaveState.SAVED;
                    },
                    error: (err) => {
                        if (err.status !== 403) {
                            this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                        }
                        this.autoSaveState = AutoSaveState.NOT_SAVING;
                    },
                });
        }
    }

    private _redirectToInformations(): void {
        const seoInformationUrl = `/restaurants/${this.restaurant._id}/seo/informations`;
        this._router.navigate([seoInformationUrl], { queryParams: { shouldOpenGeneralInformations: true } });
    }

    private _updateWorkingImage(attachment: Media): void {
        this.newPostModalContext.currentPost()?.refreshData({ attachments: [attachment] });
        this.newPostModalContext.postForm.markAsPristine();
    }

    private _formatDatesForBeforeSend(date: Date): Date {
        const newDate = date;
        const hoursDiff = newDate.getHours() - newDate.getTimezoneOffset() / 60;
        const minutesDiff = (newDate.getHours() - newDate.getTimezoneOffset()) % 60;
        newDate.setHours(hoursDiff);
        newDate.setMinutes(minutesDiff);
        return newDate;
    }

    private _isPostFormEmpty(): boolean {
        const hasAttachments = (this.newPostModalContext.postForm.get('post')?.get('attachments')?.value?.length ?? 0) > 0;
        const hasText = (this.newPostModalContext.postForm.get('post')?.get('text')?.value?.length ?? 0) > 0;
        const isDraft = this.newPostModalContext.currentPost()!.published === PostPublicationStatus.DRAFT;
        const hasFeedback = !!this.newPostModalContext.currentPost()!.feedbackId;
        return !hasAttachments && !hasText && isDraft && !hasFeedback;
    }

    private _isPostEmpty(): boolean {
        const hasAttachments = (this.newPostModalContext.currentPost()!.attachments?.length ?? 0) > 0;
        const hasText = this.newPostModalContext.currentPost()!.text?.length > 0;
        const isDraft = this.newPostModalContext.currentPost()!.published === PostPublicationStatus.DRAFT;
        const hasFeedback = !!this.newPostModalContext.currentPost()!.feedbackId;
        return !hasAttachments && !hasText && isDraft && !hasFeedback;
    }

    private _allPostsHaveBeenPublished(posts: Post[]): boolean {
        return posts.every((post) => post.published === PostPublicationStatus.PUBLISHED);
    }

    private _onePostHasFailedToPublish(posts: Post[]): boolean {
        return posts.some((post) => post.published === PostPublicationStatus.REJECTED || post.published === PostPublicationStatus.ERROR);
    }

    private _isImage(format: string): boolean {
        return !!format.match(/image/);
    }

    private _isFileTooBig(size: number, format: string): boolean {
        const maxFileSize = this._isImage(format) ? 20 * Mo : 50 * Mo;
        return size > maxFileSize;
    }

    private _computePostPublicationDate(plannedPublicationDate: Date): Date {
        if (!plannedPublicationDate) {
            return this._IN_ONE_HOUR;
        }
        const isPassed = Number(plannedPublicationDate) - Number(new Date()) < 0;
        if (!isPassed) {
            return plannedPublicationDate;
        }
        return this._IN_ONE_HOUR;
    }

    /**
     * This method is a pre-formatting before sending the post to the API.
     *
     * 1 - remove replacement character �
     */
    private _formatPostTextBeforeSend(postText: string | undefined): string | undefined {
        if (!postText) {
            return undefined;
        }

        let result = postText;

        const replacementCharacterRegex = /�/g;
        result = result.replace(replacementCharacterRegex, '');

        return result;
    }
}
