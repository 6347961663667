import { z } from 'zod';

import {
    PlatformKey,
    ReviewAnalysisChartDataSentiment,
    ReviewAnalysisChartDataTag,
    ReviewAnalysisSentiment,
} from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

const sentimentValidator = z.object({
    [ReviewAnalysisChartDataSentiment.POSITIVE]: z.number(),
    [ReviewAnalysisChartDataSentiment.NEGATIVE]: z.number(),
    [ReviewAnalysisChartDataSentiment.TOTAL]: z.number(),
});

export const reviewAnalysesChartDataValidator = z.object({
    [ReviewAnalysisChartDataTag.ATMOSPHERE]: sentimentValidator,
    [ReviewAnalysisChartDataTag.EXPEDITIOUSNESS]: sentimentValidator,
    [ReviewAnalysisChartDataTag.FOOD]: sentimentValidator,
    [ReviewAnalysisChartDataTag.HYGIENE]: sentimentValidator,
    [ReviewAnalysisChartDataTag.PRICE]: sentimentValidator,
    [ReviewAnalysisChartDataTag.SERVICE]: sentimentValidator,
    [ReviewAnalysisChartDataTag.TOTAL]: sentimentValidator.required(),
});

export const getReviewAnalysesChartDataByRestaurantIdResponseValidator = z.record(reviewAnalysesChartDataValidator);

export type ReviewAnalysesChartDataDto = Required<z.infer<typeof reviewAnalysesChartDataValidator>>;
export type GetReviewAnalysesChartDataByRestaurantIdResponseDto = z.infer<typeof getReviewAnalysesChartDataByRestaurantIdResponseValidator>;

// ------------------------------------------------------------------------------------------

const reviewAnalysesSegmentDetailsValidator = z.object({
    sentiment: z.enum([ReviewAnalysisSentiment.POSITIVE, ReviewAnalysisSentiment.NEGATIVE]),
    segmentText: z.string(),
    segmentCount: z.number(),
    reviewAnalyses: z.array(
        z.object({
            id: objectIdValidator,
            platformKey: z.nativeEnum(PlatformKey),
            restaurantId: objectIdValidator,
            reviewSocialId: z.string(),
        })
    ),
});

export const getReviewAnalysesSegmentDetailsResponseValidator = z.array(reviewAnalysesSegmentDetailsValidator);

export type ReviewAnalysesSegmentDetailsDto = z.infer<typeof reviewAnalysesSegmentDetailsValidator>;
export type GetReviewAnalysesSegmentDetailsResponseDto = z.infer<typeof getReviewAnalysesSegmentDetailsResponseValidator>;
