@if (isNewSocialPostsAndMediasEnabled()) {
    @if (restaurantHasNoPost()) {
        <app-no-social-post (createPost)="onCreatePost()" (createReelOrTikTok)="onCreateReelOrTikTok()"></app-no-social-post>
    } @else {
        <app-social-posts-list-v2
            (createPost)="onCreatePost()"
            (updatePost)="onUpdatePost($event)"
            (deletePost)="onDeletePost($event)"
            (createReelOrTikTok)="onCreateReelOrTikTok()"></app-social-posts-list-v2>
    }
} @else {
    <app-social-posts-list></app-social-posts-list>
}
