import { z } from 'zod';

import { PlatformKey } from '@malou-io/package-utils';

import { restaurantIdParamsTransformValidator } from '../common';

export const getProgrammedSocialPostPlatformKeysByDateParamsValidator = restaurantIdParamsTransformValidator;
export type GetProgrammedSocialPostPlatformKeysByDateParamsDto = z.infer<typeof getProgrammedSocialPostPlatformKeysByDateParamsValidator>;

export type ProgrammedSocialPostPlatformKeysByDateDto = {
    date: string;
    platformKeys: PlatformKey[];
}[];
