import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnInit, output, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
    GmbActionsChartComponent,
    GmbActionsData,
} from ':modules/statistics/seo/gmb-actions/gmb-actions-chart/gmb-actions-chart.component';
import { GmbInsightsChartData } from ':modules/statistics/seo/models/gmb-insight-chart-data';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { ViewBy } from ':shared/enums/view-by.enum';
import { KillSubscriptions } from ':shared/interfaces';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-gmb-actions-v2',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SelectComponent,
        TranslateModule,
        ApplyPurePipe,
        LowerCasePipe,
        GmbActionsChartComponent,
        ShortNumberPipe,
        MatTooltipModule,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        AsyncPipe,
    ],
    templateUrl: './gmb-actions-v2.component.html',
    styleUrl: './gmb-actions-v2.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GmbActionsV2Component implements OnInit, KillSubscriptions {
    readonly dailyInsightsChartData = input.required<GmbInsightsChartData>();
    readonly dailyPreviousInsightsChartData = input.required<GmbInsightsChartData>();
    readonly weeklyInsightsChartData = input.required<GmbInsightsChartData>();
    readonly monthlyInsightsChartData = input.required<GmbInsightsChartData>();

    readonly showViewByTextInsteadOfSelector = input<boolean>();
    readonly viewBy = input<ViewBy>();
    readonly hiddenDatasetIndexes = input<number[]>([]);

    readonly viewByChange = output<ViewBy>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    readonly VIEW_BY_FILTER_VALUES = Object.values(ViewBy);
    readonly ViewBy = ViewBy;

    readonly viewByControl: FormControl<ViewBy> = new FormControl<ViewBy>(ViewBy.DAY) as FormControl<ViewBy>;
    readonly viewByFilterSubject$: BehaviorSubject<ViewBy> = new BehaviorSubject(ViewBy.DAY);

    // Evolution values
    readonly conversionRateEvolution = computed(() => {
        const currRatioOverImpressions = this.dailyInsightsChartData().ratioActionsOverImpressions;
        const prevRatioOverImpressions = this.dailyPreviousInsightsChartData().ratioActionsOverImpressions;
        if (currRatioOverImpressions === null || prevRatioOverImpressions === null) {
            return null;
        }
        return currRatioOverImpressions - prevRatioOverImpressions;
    });

    readonly actionsEvolutionPercentage = computed(() => {
        const currTotalActions = this.dailyInsightsChartData().totalActions;
        const prevTotalActions = this.dailyPreviousInsightsChartData().totalActions;
        if (currTotalActions === null || prevTotalActions === null || prevTotalActions === 0) {
            return null;
        }
        return ((currTotalActions - prevTotalActions) / prevTotalActions) * 100;
    });

    readonly gmbActionsData = signal<GmbActionsData>({} as GmbActionsData);
    readonly dateLabels = signal<Date[]>([]);

    constructor(private readonly _enumTranslate: EnumTranslatePipe) {}

    ngOnInit(): void {
        this._initViewBySubject();
        this.viewByFilterSubject$.pipe(takeUntil(this.killSubscriptions$)).subscribe((viewByFilter) => {
            this.viewByChange.emit(viewByFilter);

            switch (viewByFilter) {
                case ViewBy.MONTH:
                    this.dateLabels.set(this.monthlyInsightsChartData().dates);
                    this.gmbActionsData.set(this._getGmbActionsData(this.monthlyInsightsChartData()));
                    break;
                case ViewBy.WEEK:
                    this.dateLabels.set(this.weeklyInsightsChartData().dates);
                    this.gmbActionsData.set(this._getGmbActionsData(this.weeklyInsightsChartData()));
                    break;
                default:
                case ViewBy.DAY:
                    this.dateLabels.set(this.dailyInsightsChartData().dates);
                    this.gmbActionsData.set(this._getGmbActionsData(this.dailyInsightsChartData()));
                    break;
            }
        });
    }

    private _getGmbActionsData(gmbInsightsChartData: GmbInsightsChartData): GmbActionsData {
        return {
            websiteClicks: gmbInsightsChartData.websiteClicks,
            phoneClicks: gmbInsightsChartData.phoneClicks,
            drivingClicks: gmbInsightsChartData.drivingClicks,
            bookingClicks: gmbInsightsChartData.bookingClicks,
            menuClicks: gmbInsightsChartData.menuClicks,
            foodOrderClicks: gmbInsightsChartData.foodOrderClicks,
        };
    }

    private _initViewBySubject(): void {
        const viewBy = this.viewBy();
        if (viewBy) {
            this.viewByFilterSubject$.next(viewBy);
        }
    }

    viewByDisplayWith = (option: ViewBy): string => this._enumTranslate.transform(option, 'view_by');
}
