import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe } from '@angular/common';
import { Component, input, OnInit, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { compact } from 'lodash';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { CredentialsService } from ':core/services/credentials.service';
import { WHITELISTED_PAGE_IDS } from ':modules/posts-v2/social-posts/components/upsert-social-post-modal/upsert-social-post-modal.interface';
import { FbLocation } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-fb-add-location-v2',
    templateUrl: './add-fb-location-v2.component.html',
    styleUrls: ['./add-fb-location-v2.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        AsyncPipe,
        ApplyPurePipe,
        TranslateModule,
    ],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'malou-autocomplete-panel' },
        },
    ],
})
export class AddFbLocationV2Component implements OnInit {
    readonly fbPlatformName = input<string | null>();
    readonly location = input.required<FormControl<FbLocation | null>>();

    readonly SvgIcon = SvgIcon;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchLocationControl = new FormControl<string>('');

    locationOptions$: Observable<FbLocation[]>;
    areLocationsLoaded$ = new BehaviorSubject(false);
    isLoading = signal(false);
    constructor(private readonly _credentialService: CredentialsService) {}

    ngOnInit(): void {
        const fbPlatformName = this.fbPlatformName();
        if (fbPlatformName) {
            this.isLoading.set(true);
            this._credentialService
                .pagesSearch(fbPlatformName, {
                    onlyWithLocation: true,
                    whitelistedPageIds: WHITELISTED_PAGE_IDS,
                })
                .subscribe({
                    next: (res) => {
                        this.searchLocationControl.setValue(res.data[0].name, { emitEvent: false });
                        this.location().setValue(res.data[0]);
                        this.isLoading.set(false);
                    },
                    error: () => {
                        this.isLoading.set(false);
                    },
                });
        }

        this.locationOptions$ = this.searchLocationControl.valueChanges.pipe(
            filter((query) => query?.length === 0 || (query?.length ?? 0) >= 3),
            tap(() => this.isLoading.set(true)),
            debounceTime(500),
            switchMap((query) => {
                if (!query || query.length === 0) {
                    const data: FbLocation[] = [];
                    return of({ data });
                }
                // Special case, we whitelist the "Very French Beans" page because it does not contain location
                return this._credentialService.pagesSearch(query, {
                    onlyWithLocation: true,
                    whitelistedPageIds: WHITELISTED_PAGE_IDS,
                });
            }),
            map((res) => {
                this.areLocationsLoaded$.next(!!res.data.length);
                this.isLoading.set(false);
                return res.data;
            })
        );

        this.searchLocationControl.valueChanges.subscribe((value) => {
            if (!value) {
                this.location().setValue(null);
            }
        });
    }

    remove(): void {
        this.searchLocationControl.setValue('');
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const { value } = event.option;
        const displayedValue = `${value.name}${value.location ? ' - ' + this.displayLocationOptionAddress(value) : ''}`;
        this.searchLocationControl.setValue(displayedValue, { emitEvent: false });

        this.location().setValue(value);
    }

    displayLocationOptionAddress(locationOption: FbLocation): string {
        const street = locationOption.location?.street;
        const zipAndCity = compact([locationOption.location?.zip, locationOption.location?.city]).join(' ');
        const country = locationOption.location?.country;
        const addressAsArray = compact([street, zipAndCity, country]);

        return addressAsArray.join(', ');
    }
}
