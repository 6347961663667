import { FeedbacksDto } from '@malou-io/package-dto';
import { FeedbackMessageVisibility } from '@malou-io/package-utils';

import { Post } from './post';

export enum FeedbackMessageType {
    TEXT = 'text',
    CLOSE = 'close',
    REOPEN = 'reopen',
}

export enum FeedbackParticipantType {
    AUTHOR = 'author',
    COMMENTATOR = 'commentator',
    TAGGED = 'tagged',
    OTHER = 'other',
}

export interface FeedbackUser {
    _id: string;
    name: string;
    lastname: string;
    profilePictureUrl?: string;
    email: string;
}

export class Feedback {
    _id: string;
    post?: Partial<Post>;
    isOpen: boolean;
    participants: {
        participant: FeedbackUser;
        types: string[];
    }[];
    feedbackMessages: FeedbackMessage[];

    public constructor(init: Partial<Feedback> = {}) {
        Object.assign(this, init);
        this.feedbackMessages = init?.feedbackMessages?.length ? init.feedbackMessages.map((message) => new FeedbackMessage(message)) : [];
    }

    static fromDto(dto: FeedbacksDto): Feedback {
        return new Feedback({
            _id: dto.id,
            isOpen: dto.isOpen,
            participants: dto.participants.map((participant) => ({
                participant: {
                    _id: participant.participant.id,
                    name: participant.participant.name,
                    lastname: participant.participant.lastname,
                    email: participant.participant.email,
                },
                types: participant.types,
            })),
            feedbackMessages: dto.feedbackMessages.map(
                (message) =>
                    new FeedbackMessage({
                        _id: message.id,
                        createdAt: new Date(message.createdAt),
                        text: message.text,
                        type: message.type,
                        visibility: message.visibility,
                        author: {
                            _id: message.author.id,
                            name: message.author.name,
                            lastname: message.author.lastname,
                            email: message.author.email,
                            profilePictureUrl: message.author.profilePictureUrl,
                        },
                        publishedAt: message.publishedAt ? new Date(message.publishedAt) : undefined,
                        lastUpdatedAt: message.lastUpdatedAt ? new Date(message.lastUpdatedAt) : undefined,
                    })
            ),
        });
    }
}

export class FeedbackMessage {
    _id: string;
    author: FeedbackUser;
    createdAt: Date;
    text?: string;
    type: FeedbackMessageType;
    visibility = FeedbackMessageVisibility.BASIC;
    publishedAt?: Date;
    lastUpdatedAt?: Date;

    public constructor(init: Partial<FeedbackMessage> = {}) {
        Object.assign(this, init);
    }
}
