import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

import { RestaurantsService } from ':core/services/restaurants.service';
import { LocalStorage } from ':core/storage/local-storage';
import { LocalStorageKey } from ':shared/enums/local-storage-key';

import * as RestaurantsActions from './restaurant-list.actions';

@Injectable()
export class RestaurantsEffect {
    readonly loadRestaurants$ = createEffect(() =>
        this._actions$.pipe(
            ofType(RestaurantsActions.loadRestaurants),
            mergeMap(() => {
                const token = LocalStorage.getItem(LocalStorageKey.JWT_TOKEN);
                if (token) {
                    this._restaurantsService.restaurantsLoading$.next(true);
                    return this._restaurantsService
                        .index(
                            'name access type address descriptions openingDate informationUpdate cover logo updatedAt createdAt availableHoursTypes organizationId platformKeys bricksPostalCode internalName active boosterPack roiActivated keywordToolApiLocationId ai regularHours specialHours isClosedTemporarily reviewsLastUpdate website reservationUrl phone orderUrl'
                        )
                        .pipe(
                            map((res) => {
                                if (!res) {
                                    throw new Error('Error while loading restaurants');
                                }
                                return RestaurantsActions.editRestaurants({ restaurants: res });
                            }),
                            catchError((err) => {
                                console.warn('err :', err);
                                return EMPTY;
                            }),
                            finalize(() => this._restaurantsService.restaurantsLoading$.next(false))
                        );
                }
                return EMPTY;
            })
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _restaurantsService: RestaurantsService
    ) {}
}
