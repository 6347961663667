<div
    class="flex w-full flex-col"
    [attr.data-testid]="datePickerTestId()"
    [ngClass]="{ 'rounded-[3px]': theme() === InputTextTheme.INLINE_CARD, 'cursor-pointer': !manualInputAvailable() }"
    (click)="!manualInputAvailable() && openDatePicker()">
    @if (title || subtitle || required) {
        <div class="malou-text-10--regular malou-color-text-2 flex gap-1 py-1">
            @if (title) {
                <span>{{ title }}</span>
            }
            @if (subtitle) {
                <span class="malou-text-10 italic">{{ subtitle }}</span>
            }
            @if (required) {
                <span>*</span>
            }
        </div>
    }

    <div
        class="flex self-stretch"
        [ngClass]="
            theme() === InputTextTheme.INLINE_CARD
                ? 'h-[30px] rounded-[3px] border border-malou-color-border-primary bg-malou-color-background-light'
                : 'h-12 rounded-lg bg-white'
        "
        [class.opacity-50]="control.disabled"
        [class.malou-border-primary]="shouldDisplayBorder"
        [class.malou-border-secondary]="!errorMessage && isFocused && shouldDisplayBorder"
        [class.malou-border-error]="!!errorMessage && shouldDisplayBorder">
        @if (showDatepickerButton && theme() === InputTextTheme.INLINE_CARD) {
            <div class="flex items-center sm:pr-0" [class.pr-2]="theme() !== InputTextTheme.INLINE_CARD">
                <mat-datepicker-toggle [for]="picker" (click)="setInputBlurred()">
                    <mat-icon class="malou-color-primary small-icon" matDatepickerToggleIcon [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                </mat-datepicker-toggle>
            </div>
        }

        <div
            class="flex h-full w-full pl-5 md:pr-0"
            [ngClass]="{
                'pl-5': theme() !== InputTextTheme.INLINE_CARD,
                'pr-2': theme() === InputTextTheme.INLINE_CARD,
                'pr-3': shouldDisplayBorder,
            }">
            <input
                class="box-border border-0 outline-none"
                type="text"
                [ngClass]="{
                    'malou-text-12--medium rounded-lg': theme() !== InputTextTheme.INLINE_CARD,
                    'malou-text-10 rounded-[3px] bg-malou-color-background-light font-normal': theme() === InputTextTheme.INLINE_CARD,
                    italic: isEmptyValue,
                    'bg-white': control.disabled,
                    'malou-text-12': isEmptyValue && theme() !== InputTextTheme.INLINE_CARD,
                    'h-full w-full': manualInputAvailable(),
                    'invisible h-0 w-0': !manualInputAvailable(),
                }"
                [max]="max"
                [min]="min"
                [placeholder]="placeholder"
                [formControl]="control"
                [matDatepicker]="picker"
                [attr.data-testid]="testId()"
                (focus)="isFocused = true"
                (blur)="onBlur()"
                (click)="setInputBlurred()"
                (input)="validateInput($event)"
                (dateInput)="validateDateChange($event)"
                #inputElement />
            @if (!manualInputAvailable()) {
                <div
                    class="flex h-full w-full items-center"
                    [ngClass]="{
                        'malou-text-12--medium': theme() !== InputTextTheme.INLINE_CARD,
                        'malou-text-10--regular': theme() === InputTextTheme.INLINE_CARD,
                    }">
                    {{ displayDate | applyPure: control.value }}
                </div>
            }
            <mat-datepicker [calendarHeaderComponent]="customHeaderComponent" #picker></mat-datepicker>
        </div>

        <div class="flex items-center sm:pr-0" [class.pr-2]="theme() !== InputTextTheme.INLINE_CARD">
            @if (showDatepickerButton && theme() !== InputTextTheme.INLINE_CARD) {
                <mat-datepicker-toggle [for]="picker" (click)="setInputBlurred()" #pickerToggle>
                    <mat-icon class="malou-color-primary small-icon" matDatepickerToggleIcon [svgIcon]="SvgIcon.CALENDAR"></mat-icon>
                </mat-datepicker-toggle>
            }
        </div>
    </div>

    @if (errorMessage) {
        <div class="malou-text-10 malou-color-state-error py-1 italic">{{ errorMessage }}</div>
    }
</div>
