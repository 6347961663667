import { z } from 'zod';

import { NfcStar, NfcType, ScanPlatformKey } from '@malou-io/package-utils';

import { NfcDto } from '../nfc';
import { ReviewResponseDto } from '../review';
import { TimestampeableDto } from '../utils';
import { objectIdValidator } from '../utils/validators';

export interface ScanDto extends TimestampeableDto {
    id: string;
    nfcId: string;
    scannedAt: string;
    nfcSnapshot?: NfcSnapshotDto;
    redirectedAt?: string;
    starClicked?: NfcStar;
    matchedReviewId?: string;
    matchedReview?: ReviewResponseDto;
}

interface LightScanDto {
    id: string;
    nfcId: string;
    nfcSnapshot: Pick<NfcSnapshotDto, 'name' | 'chipName' | 'restaurantId' | 'platformKey'> & {
        isRedirectingToWof: boolean;
    };
}

interface LightScanWithReviewRatingDto extends LightScanDto {
    matchedReview: {
        id: string;
        rating: number;
    } | null;
    starClicked: number;
    scannedAt: string;
}

export interface ScanForAggregatedInsightsDto {
    scansEvolution: number;
    wofScansEvolution: number;
    scans: LightScanDto[];
    estimatedReviewCountPerRestaurant: {
        [restaurantId: string]: {
            estimatedReviewCount: number;
            estimatedReviewCountDifferenceWithPreviousPeriod: number;
        };
    };
}

export interface ScanForRestaurantInsightsDto {
    scans: LightScanWithReviewRatingDto[];
    previousScans: LightScanWithReviewRatingDto[];
}

export interface ScanWithNfcDto extends ScanDto {
    nfc: NfcDto;
}

export interface NfcSnapshotDto extends TimestampeableDto {
    id: string;
    chipName?: string;
    restaurantId: string;
    active: boolean;
    name?: string | null;
    platformKey: ScanPlatformKey;
    redirectionLink: string;
    notes?: string | null;
    starsRedirected: NfcStar[];
    type: NfcType;
}

export const nfcSnapshotDtoValidator = z.object({
    id: objectIdValidator,
    chipName: z.string().optional(),
    restaurantId: objectIdValidator,
    active: z.boolean(),
    name: z.string().nullish(),
    platformKey: z.nativeEnum(ScanPlatformKey),
    redirectionLink: z.string().url(),
    notes: z.string().nullish(),
    starsRedirected: z.array(z.nativeEnum(NfcStar)),
    type: z.nativeEnum(NfcType),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
});
