import { NgStyle } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

export enum DialogVariant {
    ERROR = 'error',
    INFO = 'info',
    ALERT = 'alert',
}

export interface DialogDataType {
    width?: string;
    maxWidth?: string;
    height?: string;
    illustration?: string;
    customIllustrationWidth?: string;
    title: string;
    message?: string;
    variant: DialogVariant;
    primaryButton: {
        label: string;
        action?: () => any;
    };
    secondaryButton?: {
        label: string;
        action?: () => any;
    };
    closeButtonAction?: 'primaryButtonAction' | 'secondaryButtonAction';
}

@Component({
    selector: 'app-malou-dialog',
    templateUrl: './malou-dialog.component.html',
    styleUrls: ['./malou-dialog.component.scss'],
    standalone: true,
    imports: [NgStyle, MatButtonModule, MatIconModule, IllustrationPathResolverPipe],
})
export class MalouDialogComponent {
    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly readableDateWithTime = new Date().toISOString(); // Used to timestamp the screenshots sent by clients

    constructor(
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public readonly data: DialogDataType,
        private readonly _dialogRef: MatDialogRef<MalouDialogComponent>
    ) {}

    onPrimaryButtonClick(): void {
        if (!this.data.primaryButton?.action) {
            this.close();
            return;
        }
        const res = this.data.primaryButton.action();
        this.close(res);
    }

    onSecondaryButtonClick(): void {
        if (!this.data.secondaryButton?.action) {
            this.close();
            return;
        }
        const res = this.data.secondaryButton.action();
        this.close(res);
    }

    onCloseButtonClick(): void {
        if (this.data.closeButtonAction === 'primaryButtonAction') {
            this.onPrimaryButtonClick();
        }
        if (this.data.closeButtonAction === 'secondaryButtonAction') {
            this.onSecondaryButtonClick();
        }
        this.close();
    }

    close(res?: any): void {
        this._dialogRef.close(res);
    }
}
