import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, output, signal, viewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { differenceBy } from 'lodash';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { Interaction } from ':shared/models/interaction';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';

import { InputTextComponent } from '../input-text/input-text.component';

export enum AiGenerationActionsDisplayStyle {
    FILLED = 'filled',
    OUTLINED = 'outlined',
}

@Component({
    selector: 'app-ai-generation-actions',
    templateUrl: './ai-generation-actions.component.html',
    styleUrls: ['./ai-generation-actions.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        TranslateModule,
        FlagPathResolverPipe,
        ApplyPurePipe,
        InputTextComponent,
    ],
})
export class AiGenerationActionsComponent {
    readonly trackerId = input<string>('');
    readonly interactions = input<Interaction[]>([]);
    readonly onReset = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly onTranslate = output<string>();
    readonly interactionChanged = output<Interaction>();
    readonly onOptimize = output<void>();
    readonly onWandIconClick = output<void>();
    readonly optimizeButtonId = input.required<string>();
    readonly translateButtonIdPrefix = input.required<string>();
    readonly theme = input<AiGenerationActionsDisplayStyle>(AiGenerationActionsDisplayStyle.OUTLINED);

    readonly availableTranslations = Object.values(ApplicationLanguage);
    readonly languageFormControl = new UntypedFormControl('');

    readonly currentInteractionIndex = signal<number | undefined>(undefined);
    readonly totalInteractions = signal(0);
    readonly previousInteractions = signal<Interaction[]>(this.interactions());

    readonly languagesMenu = viewChild(MatMenuTrigger);

    readonly SvgIcon = SvgIcon;
    readonly AiGenerationActionsDisplayStyle = AiGenerationActionsDisplayStyle;

    private readonly _translate = inject(TranslateService);

    constructor() {
        effect(
            () => {
                if (!this.interactions().length) {
                    this.totalInteractions.update(() => 0);
                    this.currentInteractionIndex.update(() => undefined);
                    return;
                }
                const newAiInteractionIndex = this._getNewAiInteractionIndex(this.interactions(), this.previousInteractions());
                this.previousInteractions.update(() => this.interactions());
                this.totalInteractions.update(() => this.interactions().length);
                if (this.currentInteractionIndex() === undefined) {
                    this.currentInteractionIndex.update(() => this.totalInteractions() - 1);
                } else if (newAiInteractionIndex !== undefined) {
                    this.currentInteractionIndex.update(() => newAiInteractionIndex);
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                if (this.onReset() && this.totalInteractions() === 1) {
                    this.currentInteractionIndex.update(() => 1);
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    langDisplayWith = (lang: ApplicationLanguage): string => this._translate.instant(`header.langs.${lang}`);

    optimize(): void {
        this.onOptimize.emit();
    }

    toPreviousInteraction(): void {
        const currentInteractionIndex = this.currentInteractionIndex() ?? 0;
        this.currentInteractionIndex.update(() => Math.max(0, currentInteractionIndex - 1));
        this.interactionChanged.emit(this.interactions()[this.currentInteractionIndex() ?? 0]);
    }

    toNextInteraction(): void {
        const currentInteractionIndex = this.currentInteractionIndex() ?? 0;
        this.currentInteractionIndex.update(() => Math.min(this.totalInteractions() - 1, currentInteractionIndex + 1));
        this.interactionChanged.emit(this.interactions()[this.currentInteractionIndex() ?? 0]);
    }

    translate(language: string): void {
        this.onTranslate.emit(language);
        this.languageFormControl.setValue('');
        this.languagesMenu()?.closeMenu();
    }

    wandIconClick(): void {
        this.onWandIconClick.emit();
    }

    private _getNewAiInteractionIndex(updatedInteractions: Interaction[], interactions: Interaction[]): number | undefined {
        if (updatedInteractions.length <= 1 || !interactions.length || updatedInteractions.length === interactions.length) {
            return;
        }
        const newInteraction = differenceBy(updatedInteractions, interactions, 'id')[0];
        if (newInteraction.isAiInteraction) {
            return this._getInteractionIndex(updatedInteractions, newInteraction);
        }
    }

    private _getInteractionIndex(interactions: Interaction[], newInteraction: Interaction): number {
        return interactions.findIndex((interaction) => interaction.id === newInteraction.id);
    }
}
