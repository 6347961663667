import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { pickBy } from 'lodash';
import { forkJoin, map, Observable, of, switchMap, take } from 'rxjs';

import { DailyPlatformInsights, StoredInsightsResponseDto } from '@malou-io/package-dto';
import { getDatesBetween, isNotNil, MalouMetric, PlatformKey } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import * as AggregatedStatisticsSelectors from ':modules/aggregated-statistics/store/aggregated-statistics.selectors';
import { InsightsService } from ':modules/statistics/insights.service';
import { formatDateToISO } from ':shared/helpers';
import { GMB_METRICS } from ':shared/interfaces';
import { DatesAndPeriod, Restaurant } from ':shared/models';
import { AbstractCsvService, CsvAsStringArrays } from ':shared/services/csv-services/csv-service.abstract';

interface Data {
    name: string;
    internalName: string;
    address: string;
    data: DailyPlatformInsights['insights'];
}

type RestaurantInsightsAndDates = { restaurantInsights: Data[]; startDate: Date; endDate: Date } | null;

@Injectable({ providedIn: 'root' })
export class AggregatedGmbVisibilityCsvInsightsServiceV2 extends AbstractCsvService<RestaurantInsightsAndDates> {
    constructor(
        private readonly _store: Store,
        private readonly _insightsService: InsightsService,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        super();
    }

    protected _isDataValid(data: RestaurantInsightsAndDates): boolean {
        return !!data;
    }

    protected override _getData$(): Observable<RestaurantInsightsAndDates> {
        return forkJoin([
            this._store.select(AggregatedStatisticsSelectors.selectDatesFilter).pipe(take(1)),
            this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(take(1)),
        ]).pipe(
            switchMap(([dates, restaurants]: [DatesAndPeriod, Restaurant[]]) => {
                if (!dates.startDate || !dates.endDate) {
                    return of(null);
                }

                const { startDate, endDate } = dates;
                const restaurantIds = restaurants.map((restaurant) => restaurant._id);

                return forkJoin([
                    this._insightsService
                        .getStoredInsights({
                            restaurantIds,
                            platformKeys: [PlatformKey.GMB],
                            startDate: startDate.toISOString(),
                            endDate: endDate.toISOString(),
                            metrics: GMB_METRICS.map((m) => m.metric),
                        })
                        .pipe(map((res) => res.data)),
                    of(restaurants),
                    of(dates),
                ]);
            }),
            map(([data, restaurants, dates]: [StoredInsightsResponseDto, Restaurant[], DatesAndPeriod]) => {
                const { startDate, endDate } = dates;
                const currentInsightsData = pickBy(data, (value) => value?.[PlatformKey.GMB]?.hasData);

                if (Object.values(currentInsightsData).length === 0 || !startDate || !endDate) {
                    return null;
                }

                const restaurantIdsWithData = Object.keys(currentInsightsData);

                const restaurantInsights: Data[] = restaurantIdsWithData
                    .map((restaurantId) => {
                        const restaurant = restaurants.find((r) => r._id === restaurantId);
                        if (!restaurant) {
                            return null;
                        }
                        return {
                            name: restaurant.name,
                            internalName: restaurant.internalName ?? '',
                            address: restaurant.getFullFormattedAddress().replace(/,/g, ''),
                            data: currentInsightsData[restaurantId]?.[PlatformKey.GMB]?.insights,
                        };
                    })
                    .filter(isNotNil)
                    .sort((a, b) => a.name.localeCompare(b.name));

                return { restaurantInsights, startDate, endDate };
            })
        );
    }

    protected override _getCsvHeaderRow(): CsvAsStringArrays[0] {
        return [
            'Date',
            'Location',
            'Location Internal Name',
            'Location Address',
            'Google Maps Impressions Desktop',
            'Google Maps Impressions Mobile',
            'Google Search Impressions Desktop',
            'Google Search Impressions Mobile',
            'Calls',
            'Direction Requests',
            'Website Clicks',
            'Menu Clicks',
            'Booking Clicks',
            'Order Clicks',
        ];
    }

    protected override _getCsvDataRows({
        restaurantInsights,
        startDate,
        endDate,
    }: Exclude<RestaurantInsightsAndDates, null>): CsvAsStringArrays {
        const dates = getDatesBetween(startDate, endDate).filter(isNotNil);

        return dates
            .map((date) =>
                restaurantInsights.map((restaurantInsight) => {
                    const dateStr = date.toLocaleDateString();
                    const formattedDay = formatDateToISO(date);

                    const location = restaurantInsight.name;
                    const locationInternalName = restaurantInsight.internalName;
                    const locationAddress = restaurantInsight.address;

                    const businessImpressionsDesktopMaps =
                        restaurantInsight.data?.[MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS]?.[formattedDay]?.toString() ?? '';
                    const businessImpressionsMobileMaps =
                        restaurantInsight.data?.[MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS]?.[formattedDay]?.toString() ?? '';
                    const businessImpressionsDesktopSearch =
                        restaurantInsight.data?.[MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH]?.[formattedDay]?.toString() ?? '';
                    const businessImpressionsMobileSearch =
                        restaurantInsight.data?.[MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH]?.[formattedDay]?.toString() ?? '';
                    const actionsPhone = restaurantInsight.data?.[MalouMetric.ACTIONS_PHONE]?.[formattedDay]?.toString() ?? '';
                    const actionsDrivingDirections =
                        restaurantInsight.data?.[MalouMetric.ACTIONS_DRIVING_DIRECTIONS]?.[formattedDay]?.toString() ?? '';
                    const actionsWebsite = restaurantInsight.data?.[MalouMetric.ACTIONS_WEBSITE]?.[formattedDay]?.toString() ?? '';
                    const actionsMenu = restaurantInsight.data?.[MalouMetric.ACTIONS_MENU_CLICK]?.[formattedDay]?.toString() ?? '';
                    const actionsBooking = restaurantInsight.data?.[MalouMetric.ACTIONS_BOOKING_CLICK]?.[formattedDay]?.toString() ?? '';
                    const actionsOrder = restaurantInsight.data?.[MalouMetric.BUSINESS_FOOD_ORDERS]?.[formattedDay]?.toString() ?? '';

                    return [
                        dateStr,
                        location,
                        locationInternalName,
                        locationAddress,
                        businessImpressionsDesktopMaps,
                        businessImpressionsMobileMaps,
                        businessImpressionsDesktopSearch,
                        businessImpressionsMobileSearch,
                        actionsPhone,
                        actionsDrivingDirections,
                        actionsWebsite,
                        actionsMenu,
                        actionsBooking,
                        actionsOrder,
                    ];
                })
            )
            .flat();
    }
}
