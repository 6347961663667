import { z } from 'zod';

export interface BookmarkedPostDto {
    socialId: string;
    key: string;
    username: string;
    permalink?: string;
    caption?: string;
    comments?: number;
    likes?: number;
    url?: string;
    createdAt?: string;
    type?: string;
    postType?: string;
    carouselUrls: CarouselURLDto[];
    backupUrl?: string;
    backupCarouselUrls: CarouselURLDto[];
    nbFollowers?: number;
    accountEngagement?: number;
    hoveredPosts: HoveredPostDto[];
    commentsWithoutOwnerComments: number;
    replies: number;
    repliesWithoutOwnerReplies: number;
    impressions: number;
    showOwnerEntities: boolean;
    engagementRate?: number;
    plays?: number;
}

export interface CarouselURLDto {
    url: string;
    type: string;
    _id?: string;
}

export interface HoveredPostDto {
    url: string;
    _id?: string;
}

export const hoveredPostValidator = z.object({
    url: z.string(),
    _id: z.string().optional(),
});

export const carouselURLValidator = z.object({
    url: z.string(),
    type: z.string(),
    _id: z.string().optional(),
});

export const bookmarkedPostValidator = z
    .object({
        socialId: z.string(),
        key: z.string(),
        username: z.string(),
        permalink: z.string().optional(),
        caption: z.string().nullish(),
        comments: z.number().optional(),
        likes: z.number().optional(),
        url: z.string().nullish(),
        thumbnail: z.string().nullish(),
        backupUrl: z.string().nullish().optional(),
        createdAt: z
            .string()
            .datetime()
            .optional()
            .transform((data) => (data ? new Date(data) : undefined)),
        carouselUrls: z.array(carouselURLValidator).optional(),
        backupCarouselUrls: z.array(carouselURLValidator).optional(),
        nbFollowers: z.number().optional(),
        accountEngagement: z.number().optional(),
        hoveredPosts: z.array(hoveredPostValidator),
        replies: z.number().optional(),
        impressions: z.number().optional(),
        postType: z.string().optional(),
        type: z.string().optional(),
    })
    .omit({ postType: true });

export type UpdateBookmarkedPostBody = z.infer<typeof bookmarkedPostValidator>;
