import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    EventEmitter,
    inject,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, map, Observable, of } from 'rxjs';

import {
    Currency,
    getCurrencySymbol,
    HeapEventName,
    isNotNil,
    RevenueOption,
    RoiRevenueOptions,
    SimilarRestaurantCategory,
} from '@malou-io/package-utils';

import { RoiNotificationsContext } from ':core/components/notification-center/context/roi-notification.context';
import { HeapService } from ':core/services/heap.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { SimilarRestaurantsService } from ':core/services/similar-restaurants.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { InputNumberComponent } from ':shared/components/input-number/input-number.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { EmojiPathResolverPipe } from ':shared/pipes/emojis-path-resolver.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { displayRevenue } from ':shared/services/csv-services/e-reputation/helper-functions';

import { RoiSettingsService } from '../roi-settings.service';
import { RoiContext } from '../roi.context';
import { RoiService } from '../roi.service';

const DEFAULT_MONTHS = 3;

interface RoiSettingsForm {
    currency: FormControl<Currency | null>;
    averageTicket: FormControl<number | null>;
    revenue: FormControl<RevenueOption | null>;
    category: FormControl<SimilarRestaurantCategory | null>;
}

@Component({
    selector: 'app-upsert-roi-settings',
    templateUrl: './upsert-roi-settings.component.html',
    styleUrls: ['./upsert-roi-settings.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        MatButtonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonComponent,
        SelectComponent,
        InputNumberComponent,
        EmojiPathResolverPipe,
    ],
})
export class UpsertRoiSettingsComponent implements OnInit {
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

    private readonly _enumTranslatePipe = inject(EnumTranslatePipe);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _roiSettingsService = inject(RoiSettingsService);
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _similarRestaurantsService = inject(SimilarRestaurantsService);
    private readonly _heapService = inject(HeapService);
    public readonly roiContext = inject(RoiContext);
    private readonly _fb = inject(FormBuilder);
    private readonly _roiNotificationsContext = inject(RoiNotificationsContext);
    private readonly _roiService = inject(RoiService);

    readonly initialCategory$: Observable<SimilarRestaurantCategory | null> = this._similarRestaurantsService
        .get(this._restaurantsService.currentRestaurant?._id)
        .pipe(map((res) => res.data?.restaurantCategory ?? null));
    readonly initialCategory: Signal<SimilarRestaurantCategory | null> = toSignal(this.initialCategory$, { initialValue: null });
    readonly alreadyHasSettings: Signal<boolean> = computed(
        () => this.roiContext.isRoiSettingsComplete()(this.roiContext.roiSettings()) && !!this.initialCategory()
    );

    readonly restaurantExistingMonthsWithMalou: WritableSignal<number> = signal(DEFAULT_MONTHS);
    readonly hasCorrectlyFilledForm = signal(true);
    readonly isSavingRoiSettings = signal(false);

    readonly currencyOptions = Object.values(Currency);
    readonly revenueOptions: RevenueOption[] = RoiRevenueOptions;
    readonly categoriesOptions: SimilarRestaurantCategory[] = Object.values(SimilarRestaurantCategory).sort(
        (a: SimilarRestaurantCategory, b: SimilarRestaurantCategory) =>
            this._enumTranslatePipe
                .transform(a, 'similar_restaurants_categories')
                .localeCompare(this._enumTranslatePipe.transform(b, 'similar_restaurants_categories'))
    );

    readonly roiSettingsForm: FormGroup<RoiSettingsForm> = this._fb.group({
        currency: [Currency.EUR, Validators.required],
        averageTicket: [null as number | null, Validators.required],
        revenue: [null as RevenueOption | null, Validators.required],
        category: [null as SimilarRestaurantCategory | null, Validators.required],
    });

    get currency(): Currency | null | undefined {
        return this.roiSettingsForm.get('currency')?.value;
    }

    get averageTicket(): number | null | undefined {
        return this.roiSettingsForm.get('averageTicket')?.value;
    }

    get minRevenue(): number | null | undefined {
        return this.roiSettingsForm.get('revenue')?.value?.min;
    }

    get maxRevenue(): number | null | undefined {
        return this.roiSettingsForm.get('revenue')?.value?.max;
    }

    get category(): SimilarRestaurantCategory | null | undefined {
        return this.roiSettingsForm.get('category')?.value;
    }

    ngOnInit(): void {
        this.restaurantExistingMonthsWithMalou.set(this._restaurantsService.currentRestaurant.numberOfMonthSinceCreated());
        this._initRoiSettingsForm();
    }

    onAverageTicketChange(averageTicket: number | null): void {
        this.roiSettingsForm.get('averageTicket')?.setValue(averageTicket);
    }

    cancel(): void {
        return this.onCancel.emit();
    }

    saveRoiSettings(): void {
        this.isSavingRoiSettings.set(true);
        this.roiSettingsForm.disable();

        if (!this.currency || !this.averageTicket || !this.minRevenue || !this.maxRevenue || !this.category) {
            return;
        }
        const roiSettingsData = {
            currency: this.currency,
            averageTicket: this.averageTicket,
            minRevenue: this.minRevenue,
            maxRevenue: this.maxRevenue,
        };
        const categoryUpdate$ =
            this.category && this.initialCategory() !== this.category
                ? this._similarRestaurantsService.update(this._restaurantsService.currentRestaurant?._id, this.category)
                : of(null);
        forkJoin([
            categoryUpdate$,
            this._roiSettingsService.upsert(this._restaurantsService.currentRestaurant?._id, roiSettingsData),
        ]).subscribe({
            next: () => {
                if (!this.roiContext.roiSettings()) {
                    this._heapService.track(HeapEventName.TRACKING_FIRST_TIME_SETTINGS);
                    this._roiService.startInsightsWatcher(this._restaurantsService.currentRestaurant?._id);
                } else {
                    this._heapService.track(HeapEventName.TRACKING_EDIT_SETTINGS);
                }
                this.isSavingRoiSettings.set(false);
                this.roiSettingsForm.enable();
                this.onSave.emit();
                this._roiNotificationsContext.emitReload();
            },
            error: (err) => {
                console.warn(err);
                this.isSavingRoiSettings.set(false);
                this.roiSettingsForm.enable();
            },
        });
    }

    currencyDisplayWith(currency: Currency): string {
        return getCurrencySymbol(currency);
    }

    revenueDisplayWith(revenue: RevenueOption): string {
        return displayRevenue(revenue);
    }

    categoryDisplayWith = (category: SimilarRestaurantCategory): string =>
        this._enumTranslatePipe.transform(category, 'similar_restaurants_categories');

    private _initRoiSettingsForm(): void {
        this._similarRestaurantsService
            .get(this._restaurantsService.currentRestaurant?._id)
            .pipe(map((res) => res.data?.restaurantCategory ?? null))
            .subscribe({
                next: (initialCategory) => {
                    this.roiSettingsForm.get('category')?.setValue(initialCategory ?? null);

                    const previousRoiSettings = this.roiContext.roiSettings();
                    if (previousRoiSettings) {
                        this.roiSettingsForm.get('currency')?.setValue(previousRoiSettings.currency ?? null);
                        this.roiSettingsForm.get('averageTicket')?.setValue(previousRoiSettings.averageTicket ?? null);
                        if (isNotNil(previousRoiSettings.minRevenue) && isNotNil(previousRoiSettings.maxRevenue)) {
                            this.roiSettingsForm
                                .get('revenue')
                                ?.setValue({ min: previousRoiSettings.minRevenue, max: previousRoiSettings.maxRevenue });
                        }
                    }
                    this._changeDetectorRef.detectChanges();
                },
            });
    }
}
