import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AbilityBuilder } from '@casl/ability';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { AppAbility, AppCaslRule, CaslRole, getAbilityRole, Role, userAbilities } from '@malou-io/package-utils';

import { AbilitiesContext } from ':core/context/abilities.context';
import { CredentialsService } from ':core/services/credentials.service';
import { CustomerIOService } from ':core/services/customerio.service';
import { HeapService } from ':core/services/heap.service';
import { LocalStorage } from ':core/storage/local-storage';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { LocalStorageKey } from ':shared/enums/local-storage-key';

import * as userActions from './user.actions';

@Injectable()
export class UserEffect {
    readonly loadUser$ = createEffect(() =>
        this._actions$.pipe(
            ofType(userActions.loadUser),
            mergeMap(() => {
                const token = LocalStorage.getItem(LocalStorageKey.JWT_TOKEN);
                if (token) {
                    const jwtToken = this._jwtHelper.decodeToken(token);
                    return this._usersService.getUser(jwtToken?._id).pipe(
                        switchMap((user) =>
                            user.defaultLanguage
                                ? of(user)
                                : this._usersService
                                      .updateUser$(user._id, {
                                          defaultLanguage: LocalStorage.getLang(),
                                      })
                                      .pipe(map((res) => res.data))
                        ),
                        map((user) => {
                            this._heapService.identify(user?.email);
                            this._heapService.addUserProperties({ role: user?.role });
                            if (user?.email) {
                                this._customerIOService.identify(user?.email, {
                                    first_name: user?.name,
                                    last_name: user?.lastname,
                                    role: user?.role,
                                });
                            }
                            if (!user?.lastname) {
                                this._router.navigate(['auth/' + user?._id + '/edit-misc']);
                            }
                            this._updateAbility(user);
                            return userActions.editUserInfos({ infos: user });
                        }),
                        catchError(() => EMPTY)
                    );
                }
                return EMPTY;
            })
        )
    );

    loadUserPlatformPermissions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(userActions.loadUserPlatformPermissions),
            mergeMap((action) =>
                this._credentialsService.getPermissionsForAllPlatformConnectionTypes$(action.key).pipe(
                    map((result) => (result ? userActions.editUserPlatformPermissions(result) : { type: '' })),
                    catchError((err) => {
                        console.warn(err);
                        return EMPTY;
                    })
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _usersService: UsersService,
        private readonly _credentialsService: CredentialsService,
        private readonly _jwtHelper: JwtHelperService,
        private readonly _heapService: HeapService,
        private readonly _customerIOService: CustomerIOService,
        private readonly _router: Router,
        private readonly _abilitiesContext: AbilitiesContext
    ) {
        this._customerIOService.initialize();
    }

    private _updateAbility(user: User): void {
        const newRules = this._defineRulesForUser(user);
        this._abilitiesContext.updateUserAbilities(newRules);
    }

    private _defineRulesForUser = (user: {
        role: Role;
        caslRole: CaslRole;
        organizations: { _id: string }[];
        organizationIds: string[];
    }): AppCaslRule[] => {
        const { can, rules } = new AbilityBuilder(AppAbility);

        const caslRole = getAbilityRole({
            userAppRole: user.role,
            userRestaurantRole: user.caslRole,
        });
        if (typeof userAbilities[caslRole] === 'function') {
            userAbilities[caslRole](user, { can });
        }
        return rules;
    };
}
