import { SocialPostMediaDto } from '@malou-io/package-dto';
import { MediaType, RemoveMethodsFromClass } from '@malou-io/package-utils';

export type ISocialPostMedia = RemoveMethodsFromClass<SocialPostMedia>;

export class SocialPostMedia {
    url: string;
    type: MediaType;
    thumbnailUrl?: string;

    constructor(data: ISocialPostMedia) {
        this.url = data.url;
        this.type = data.type;
        this.thumbnailUrl = data.thumbnailUrl;
    }

    static fromDto(dto: SocialPostMediaDto): SocialPostMedia {
        return new SocialPostMedia({
            url: dto.url,
            type: dto.type,
            thumbnailUrl: dto.thumbnailUrl,
        });
    }
}
