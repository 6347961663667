import { z } from 'zod';

import { objectIdValidator } from '../utils';

export const duplicateMediaParamsValidator = z.object({
    mediaId: objectIdValidator,
});

export type DuplicateMediaParamsDto = z.infer<typeof duplicateMediaParamsValidator>;

export type DuplicateMediaResponseDto = { duplicatedMediaId: string };
