export enum UserRestaurantPermission {
    MODIFY_INFORMATION = 'modify_information',
    DISABLE_VENUE = 'disable_venue',
    SAVE_INSPIRATIONS = 'save_inspirations',
    UPDATE_BRICKS = 'update_bricks',
    DELETE_PLATFORM = 'delete_platform',
    MANAGE_PLATFORM = 'manage_platform',
    UPDATE_PLATFORM = 'update_platform',
    UPDATE_INSPIRATIONS = 'update_inspirations',
    UPDATE_SUBSCRIPTION = 'update_subscription',
    UPLOAD_MEDIA = 'upload_media',
    MANAGE_KEYWORDS = 'manage_keywords',
    MANAGE_REVIEWS = 'manage_reviews',
    MANAGE_SEO_POSTS = 'manage_seo_posts',
    MANAGE_SOCIAL_POSTS = 'manage_social_posts',
    PUBLISH_SEO_POSTS = 'publish_seo_posts',
    PUBLISH_SOCIAL_POSTS = 'publish_social_posts',
    MANAGE_TEMPLATES = 'manage_templates',
    MANAGE_HASHTAGS = 'manage_hashtags',
    MANAGE_COMMENTS = 'manage_comments',
    READ_USER_RESTAURANTS = 'read_user_restaurants',
    DELETE_USER_RESTAURANTS = 'delete_user_restaurants',
    UPDATE_USER_RESTAURANTS = 'update_user_restaurants',
    MANAGE_LABELS = 'manage_labels',
    CREATE_USER = 'create_user',
    UPDATE_USER_ORGANIZATIONS = 'update_user_organizations',
    MANAGE_CLIENTS = 'manage_clients',
    MANAGE_CAMPAIGNS = 'manage_campaigns',
    MANAGE_FEEDBACKS = 'manage_feedbacks',
    ANSWER_FEEDBACK = 'answer_feedback',
}

export enum UserPermission {
    DELETE_ORGANIZATION_USER = 'delete_organization_user',
    MANAGE_ORGANIZATION_USER_CASL_ROLE = 'manage_organization_user_casl_role',
    CREATE_ORGANIZATION_USER = 'create_organization_user',
    MANAGE_ORGANIZATION_USER_RESTAURANT = 'manage_organization_user_restaurant',
    CREATE_ORGANIZATION_RESTAURANT = 'create_organization_restaurant',
    MANAGE_ORGANIZATION_CREDENTIALS = 'manage_organization_credentials',
}
