<div
    class="hide-scrollbar h-full overflow-y-auto"
    data-testid="post-modal-feed"
    cdkDropListGroup
    infinite-scroll
    [infiniteScrollDistance]="2"
    [scrollWindow]="false"
    [infiniteScrollContainer]="scrollContainerSelector"
    (scrolled)="scrolledDown()"
    #scrollContainerSelector>
    <div class="grid grid-cols-3">
        @for (post of posts(); track trackByIdFn($index, post)) {
            <div
                [ngClass]="{
                    'aspect-square': !isFeed45Enabled(),
                    'aspect-[4/5]': isFeed45Enabled(),
                }">
                <app-social-post-media
                    [customMediaClass]="'!rounded-[0]'"
                    [postType]="post.postType"
                    [workingMedia]="post.getSocialMediaUrl() || post.getMalouMediaUrl()"
                    [firstAttachmentType]="post.getFirstMediumType()"
                    [showVideoControls]="false"
                    [thumbnailUrl]="thumbnailUrlsForPosts()[post.id]?.url"
                    (refreshMedia)="refreshPost(post)"></app-social-post-media>
            </div>
        }
    </div>
</div>
