import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MalouErrorCode } from '@malou-io/package-utils';

@Injectable({
    providedIn: 'root',
})
export class MalouErrorsService {
    constructor(private readonly _translateService: TranslateService) {}

    getReadableErrorForMalouErrorCode(malouErrorCode: MalouErrorCode): string {
        return (
            {
                [MalouErrorCode.CANNOT_DELETE_MEDIAS_FROM_OTHER_RESTAURANTS]: this._translateService.instant(
                    'malou_errors.cannot_delete_medias_from_other_restaurants'
                ),
                [MalouErrorCode.ATTRIBUTES_GMB_NOT_CONNECTED]: this._translateService.instant(
                    'information.attributes.error.gmb_not_connected_text'
                ),
                [MalouErrorCode.USER_CANNOT_UPDATE_USER_RESTAURANT]: this._translateService.instant(
                    'malou_errors.user_cannot_update_user_restaurant'
                ),
            }[malouErrorCode] ?? malouErrorCode
        );
    }
}
