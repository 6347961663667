import { isNumber, omit, sum } from 'lodash';

import { InsightsChart, MalouMetric, PlatformKey, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { ChartSortBy } from ':shared/enums/sort.enum';
import { ChartDataArray, ChartDataElement, mergeArrays } from ':shared/helpers';
import { GmbAggregatedInsights } from ':shared/interfaces';
import { LightRestaurant } from ':shared/models';

type MetricValue = { [metric in MalouMetric]?: number | undefined };

export class GmbAggregatedInsightsChartData {
    private _rawData: Omit<GmbAggregatedInsights, 'startDate' | 'endDate'>;

    websiteClicks: ChartDataArray = [];
    phoneClicks: ChartDataArray = [];
    drivingClicks: ChartDataArray = [];
    bookingClicks: ChartDataArray = [];
    menuClicks: ChartDataArray = [];
    foodOrderClicks: ChartDataArray = [];

    impressionsDesktopMaps: ChartDataArray = [];
    impressionsDesktopSearch: ChartDataArray = [];
    impressionsMobileMaps: ChartDataArray = [];
    impressionsMobileSearch: ChartDataArray = [];

    impressionsSearch: ChartDataArray = [];
    impressionsMaps: ChartDataArray = [];

    totalImpressionsMaps: ChartDataElement;
    totalImpressionsSearch: ChartDataElement;

    totalImpressions: number;
    totalActions: number;
    ratioActionsOverImpressions: number | null;

    restaurants: LightRestaurant[];
    sortBy: ChartSortBy;

    constructor({
        data,
        restaurants,
        sortBy,
        chart,
    }: {
        data: GmbAggregatedInsights;
        restaurants: LightRestaurant[];
        sortBy: ChartSortBy;
        chart: InsightsChart.AGGREGATED_ACTIONS | InsightsChart.AGGREGATED_APPARITIONS;
    }) {
        this._rawData = omit(data, ['startDate', 'endDate']);
        this.sortBy = ChartSortBy.ALPHABETICAL;
        this.restaurants = restaurants;

        this._initInsightsAndRestaurants(sortBy, chart);

        this.impressionsSearch = mergeArrays(this.impressionsDesktopSearch, this.impressionsMobileSearch);
        this.impressionsMaps = mergeArrays(this.impressionsDesktopMaps, this.impressionsMobileMaps);

        this.totalImpressionsSearch = sum(this.impressionsSearch);
        this.totalImpressionsMaps = sum(this.impressionsMaps);

        this.totalActions = this._getTotalActions();
        this.totalImpressions = this._getTotalImpressions();

        this.ratioActionsOverImpressions = this.totalImpressions === 0 ? null : (this.totalActions / this.totalImpressions) * 100;
    }

    private _initInsightsAndRestaurants(
        sortBy: ChartSortBy,
        chart: InsightsChart.AGGREGATED_ACTIONS | InsightsChart.AGGREGATED_APPARITIONS
    ): void {
        if (sortBy === ChartSortBy.ALPHABETICAL) {
            this._sortInsightsAlphabetically();
            return;
        }

        switch (chart) {
            case InsightsChart.AGGREGATED_ACTIONS:
                this._sortInsightsByTotalActions(sortBy);
                break;
            case InsightsChart.AGGREGATED_APPARITIONS:
                this._sortInsightsByTotalImpressions(sortBy);
                break;
        }
    }

    private _sortInsightsAlphabetically(): void {
        this.restaurants = this.restaurants.sort(sortRestaurantsByInternalNameThenName);
        this._populateInsights();
    }

    private _sortInsightsByTotalActions(sortBy: ChartSortBy): void {
        this.sortBy = sortBy;
        const totalInsightsWithRestaurantId = Object.entries(this._rawData).map(([key, value]) => ({
            restaurantId: key,
            insights: value?.[PlatformKey.GMB]?.insights,
            totalActions: this._computeTotalActionsByRestaurant(value?.[PlatformKey.GMB]?.insights),
        }));

        const sortedTotalInsights = totalInsightsWithRestaurantId.sort(
            (a, b) => (a.totalActions - b.totalActions) * (sortBy === ChartSortBy.DESC ? -1 : 1)
        );

        // sort restaurants by total actions then set insights values accordingly to the sorted restaurants
        this.restaurants = sortedTotalInsights.map((r) => this.restaurants.find((restaurant) => restaurant.id === r.restaurantId)!);
        this._populateInsights();
    }

    private _sortInsightsByTotalImpressions(sortBy: ChartSortBy): void {
        this.sortBy = sortBy;
        const totalInsightsWithRestaurantId = Object.entries(this._rawData).map(([key, value]) => ({
            restaurantId: key,
            insights: value?.[PlatformKey.GMB]?.insights,
            totalImpressions: this._computeTotalImpressionsByRestaurant(value?.[PlatformKey.GMB]?.insights),
        }));

        const sortedTotalInsights = totalInsightsWithRestaurantId.sort(
            (a, b) => (a.totalImpressions - b.totalImpressions) * (sortBy === ChartSortBy.DESC ? -1 : 1)
        );

        // sort restaurants by total impressions then set insights values accordingly to the sorted restaurants
        this.restaurants = sortedTotalInsights.map((r) => this.restaurants.find((restaurant) => restaurant.id === r.restaurantId)!);
        this._populateInsights();
    }

    private _populateInsights(): void {
        const restaurantIds = this.restaurants.map((r) => r.id);

        this.websiteClicks = restaurantIds.map((id) => this._getRestaurantInsightValue(id, MalouMetric.ACTIONS_WEBSITE));
        this.phoneClicks = restaurantIds.map((id) => this._getRestaurantInsightValue(id, MalouMetric.ACTIONS_PHONE));
        this.drivingClicks = restaurantIds.map((id) => this._getRestaurantInsightValue(id, MalouMetric.ACTIONS_DRIVING_DIRECTIONS));
        this.bookingClicks = restaurantIds.map((id) => this._getRestaurantInsightValue(id, MalouMetric.ACTIONS_BOOKING_CLICK));
        this.menuClicks = restaurantIds.map((id) => this._getRestaurantInsightValue(id, MalouMetric.ACTIONS_MENU_CLICK));
        this.foodOrderClicks = restaurantIds.map((id) => this._getRestaurantInsightValue(id, MalouMetric.BUSINESS_FOOD_ORDERS));
        this.impressionsDesktopMaps = restaurantIds.map((id) =>
            this._getRestaurantInsightValue(id, MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS)
        );
        this.impressionsDesktopSearch = restaurantIds.map((id) =>
            this._getRestaurantInsightValue(id, MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH)
        );
        this.impressionsMobileMaps = restaurantIds.map((id) =>
            this._getRestaurantInsightValue(id, MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS)
        );
        this.impressionsMobileSearch = restaurantIds.map((id) =>
            this._getRestaurantInsightValue(id, MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH)
        );
    }

    private _getRestaurantInsightValue(restaurantId: string, metric: MalouMetric): number {
        return this._rawData[restaurantId]?.[PlatformKey.GMB]?.insights?.[metric] ?? 0;
    }

    private _computeTotalActionsByRestaurant(gmbInsightsTotal: MetricValue | undefined): number {
        if (!gmbInsightsTotal) {
            return 0;
        }
        const actions = [
            MalouMetric.ACTIONS_WEBSITE,
            MalouMetric.ACTIONS_PHONE,
            MalouMetric.ACTIONS_DRIVING_DIRECTIONS,
            MalouMetric.ACTIONS_MENU_CLICK,
            MalouMetric.ACTIONS_BOOKING_CLICK,
            MalouMetric.BUSINESS_FOOD_ORDERS,
        ];
        return actions.reduce((acc, action) => acc + (gmbInsightsTotal[action] ?? 0), 0);
    }

    private _computeTotalImpressionsByRestaurant(gmbInsightsTotal: MetricValue | undefined): number {
        if (!gmbInsightsTotal) {
            return 0;
        }
        const impressions = [
            MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
            MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
            MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
            MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
        ];
        return impressions.reduce((acc, impression) => acc + (gmbInsightsTotal[impression] ?? 0), 0);
    }

    private _getTotalActions(): number {
        return [this.websiteClicks, this.phoneClicks, this.drivingClicks, this.bookingClicks, this.menuClicks, this.foodOrderClicks]
            .map(sum)
            .reduce((acc: number, curr) => (isNumber(curr) ? acc + curr : acc + 0), 0) as number;
    }

    private _getTotalImpressions(): number {
        return [this.impressionsDesktopMaps, this.impressionsDesktopSearch, this.impressionsMobileMaps, this.impressionsMobileSearch]
            .map(sum)
            .reduce((acc: number, curr) => (isNumber(curr) ? acc + curr : acc + 0), 0) as number;
    }
}
