@if (withLazyLoading()) {
    <img
        class="malou-logo {{ imgClasses() }}"
        [style]="imgStyle()"
        [attr.data-testid]="testId()"
        [ngClass]="dynamicClassesComputed()"
        [lazyLoad]="logo() | platformLogoPathResolver: { folder: folder() }"
        [alt]="logo()" />
} @else {
    <img
        class="malou-logo {{ imgClasses() }}"
        [style]="imgStyle()"
        [attr.data-testid]="testId()"
        [ngClass]="dynamicClassesComputed()"
        [src]="logo() | platformLogoPathResolver: { folder: folder() }"
        [alt]="logo()" />
}
