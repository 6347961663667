import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, model, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SocialPostsListFilter } from '@malou-io/package-utils';

import { CreateSocialPostMenuButtonComponent } from ':modules/posts-v2/social-posts/components/social-posts-list/social-posts-list-header/create-social-post-menu-button/create-social-post-menu-button.component';
import { SocialPostsContext } from ':modules/posts-v2/social-posts/social-posts.context';
import { SocialPostsV2Service } from ':modules/posts-v2/social-posts/social-posts.service';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

@Component({
    selector: 'app-social-posts-list-header',
    templateUrl: './social-posts-list-header.component.html',
    styleUrls: ['./social-posts-list-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        CreateSocialPostMenuButtonComponent,
        EnumTranslatePipe,
    ],
})
export class SocialPostsListHeaderComponent {
    readonly selectedFilter = model.required<SocialPostsListFilter>();
    readonly restaurant = input.required<Restaurant | null>();
    readonly createPost = output<void>();
    readonly createReelOrTikTok = output<void>();

    private readonly _socialPostsService = inject(SocialPostsV2Service);
    private readonly _socialPostsContext = inject(SocialPostsContext);

    readonly SvgIcon = SvgIcon;
    readonly SocialPostsListFilter = SocialPostsListFilter;

    readonly filterOptionsAndCount = signal<{ filterOption: SocialPostsListFilter; count: number | null }[]>([]);

    readonly isAnyPlatformConnected = signal(true);

    constructor() {
        effect(() => {
            const restaurant = this.restaurant();
            if (restaurant) {
                this._setFilterOptionsCount(restaurant._id);
            }
        });

        this._socialPostsContext.shouldFetchFilterOptionsCount$.subscribe(() => {
            const restaurant = this.restaurant();
            if (restaurant) {
                this._setFilterOptionsCount(restaurant._id);
            }
        });
    }

    synchronize(): void {
        // TODO
        console.log('Synchronize is not implemented yet');
    }

    selectFilter(filterOption: SocialPostsListFilter): void {
        this.selectedFilter.set(filterOption);
    }

    onCreatePost(): void {
        this.createPost.emit();
    }

    onCreateReelOrTikTok(): void {
        this.createReelOrTikTok.emit();
    }

    private _setFilterOptionsCount(restaurantId: string): void {
        this._socialPostsService.getSocialPostsCountByFilterOptions$(restaurantId).subscribe((filterOptionsCount) => {
            const filterOptionsAndCountWithoutZero = filterOptionsCount.filter((filterOptionCount) => filterOptionCount.count !== 0);
            this.filterOptionsAndCount.set(filterOptionsAndCountWithoutZero);
        });
    }
}
