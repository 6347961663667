import { AsyncPipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
    WritableSignal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact, isNumber, omitBy, partition, pickBy } from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AggregationTimeScale, MalouMetric, PlatformKey, sortRestaurantsByInternalNameThenName } from '@malou-io/package-utils';

import { AggregatedStatisticsFiltersContext } from ':modules/aggregated-statistics/filters/filters.context';
import { InsightsService } from ':modules/statistics/insights.service';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { ChartSortBy } from ':shared/enums/sort.enum';
import { isDateSetOrGenericPeriod } from ':shared/helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { DatesAndPeriod, InsightsByPlatformByRestaurant, LightRestaurant, MetricToDataValue, Restaurant, Value } from ':shared/models';
import { GmbInsights } from ':shared/models/gmb-insight';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

import { AggregatedStatisticsHttpErrorPipe } from '../../aggregated-statistics-http-error.pipe';
import * as AggregatedStatisticsSelector from '../../store/aggregated-statistics.selectors';
import { GmbActionsChartComponent, GmbActionsData } from './gmb-actions-chart/gmb-actions-chart.component';

interface MetricAndKey {
    metric: MalouMetric;
    key: string;
}

const GMB_METRICS: MetricAndKey[] = [
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
        key: 'impressionsDesktopMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
        key: 'impressionsDesktopSearch',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
        key: 'impressionsMobileMaps',
    },
    {
        metric: MalouMetric.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
        key: 'impressionsMobileSearch',
    },
    {
        metric: MalouMetric.ACTIONS_WEBSITE,
        key: 'websiteClicks',
    },
    {
        metric: MalouMetric.ACTIONS_PHONE,
        key: 'phoneClicks',
    },
    {
        metric: MalouMetric.ACTIONS_DRIVING_DIRECTIONS,
        key: 'drivingClicks',
    },
    {
        metric: MalouMetric.ACTIONS_MENU_CLICK,
        key: 'menuClicks',
    },
    {
        metric: MalouMetric.ACTIONS_BOOKING_CLICK,
        key: 'bookingClicks',
    },
    {
        metric: MalouMetric.BUSINESS_FOOD_ORDERS,
        key: 'foodOrderClicks',
    },
];

@Component({
    selector: 'app-gmb-actions',
    templateUrl: './gmb-actions.component.html',
    styleUrls: ['./gmb-actions.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        MatTooltipModule,
        MatIconModule,
        GmbActionsChartComponent,
        NumberEvolutionComponent,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        AggregatedStatisticsHttpErrorPipe,
        SelectComponent,
        ApplyPurePipe,
        LowerCasePipe,
        AsyncPipe,
    ],
    providers: [EnumTranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribeOnDestroy()
export class GmbActionsComponent implements OnInit, KillSubscriptions {
    @Input() sortBy: ChartSortBy;
    @Input() showSortByTextInsteadOfSelector = false;
    @Input() hiddenDatasetIndexes: number[] = [];
    @Output() sortByChange: EventEmitter<ChartSortBy> = new EventEmitter<ChartSortBy>();
    @Output() hiddenDatasetIndexesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() hasDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() readonly isLoadingEvent = new EventEmitter<boolean>(true);

    readonly SvgIcon = SvgIcon;

    dates$: Observable<DatesAndPeriod> = this._store
        .select(AggregatedStatisticsSelector.selectDatesFilter)
        .pipe(map((dates) => ({ ...dates })));
    restaurants$: Observable<Restaurant[]> = this._aggregatedStatisticsFiltersContext.selectedRestaurants$.pipe(
        map((restaurants) => [...restaurants])
    );

    httpError: WritableSignal<string | null> = signal(null);
    isLoading = signal(true);
    hasData = signal(true);

    totalActions: WritableSignal<number | null> = signal(null);
    previousTotalActions: WritableSignal<number | null> = signal(null);
    conversionRate: WritableSignal<number | null> = signal(null);
    previousConversionRate: WritableSignal<number | null> = signal(null);
    actionsEvolutionPercentage = computed(() => {
        // We need to use constants to avoid ts error "totalActions can be null" even if checked before
        const totalActions = this.totalActions();
        const previousTotalActions = this.previousTotalActions();
        return totalActions && previousTotalActions ? ((totalActions - previousTotalActions) / previousTotalActions) * 100 : null;
    });
    conversionRateEvolution = computed(() => {
        const conversionRate = this.conversionRate();
        const previousConversionRate = this.previousConversionRate();
        return conversionRate && previousConversionRate ? conversionRate - previousConversionRate : null;
    });

    gmbActionsData: WritableSignal<GmbActionsData> = signal({
        websiteClicks: [],
        phoneClicks: [],
        drivingClicks: [],
        menuClicks: [],
        bookingClicks: [],
        foodOrderClicks: [],
    });
    previousGmbActionsData: WritableSignal<GmbActionsData> = signal({
        websiteClicks: [],
        phoneClicks: [],
        drivingClicks: [],
        menuClicks: [],
        bookingClicks: [],
        foodOrderClicks: [],
    });
    restaurants: WritableSignal<LightRestaurant[]> = signal([]);

    warningTooltip: string | null = null;
    errorTooltip: string | null = null;

    readonly DEFAULT_SORT_BY = ChartSortBy.ALPHABETICAL;
    readonly SORT_BY_FILTER_VALUES = Object.values(ChartSortBy);
    readonly sortByFilterSubject$: BehaviorSubject<ChartSortBy> = new BehaviorSubject(this.DEFAULT_SORT_BY);
    readonly sortByControl: FormControl<ChartSortBy> = new FormControl<ChartSortBy>(this.DEFAULT_SORT_BY) as FormControl<ChartSortBy>;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    constructor(
        private readonly _insightsService: InsightsService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _enumTranslate: EnumTranslatePipe,
        private readonly _aggregatedStatisticsFiltersContext: AggregatedStatisticsFiltersContext
    ) {
        effect(() => this.isLoadingEvent.emit(this.isLoading()));
    }

    ngOnInit(): void {
        if (this.sortBy) {
            this.sortByFilterSubject$.next(this.sortBy);
            this.sortByControl.setValue(this.sortBy);
        }
        combineLatest([this.restaurants$, this.dates$, this.sortByFilterSubject$])
            .pipe(
                filter(
                    ([restaurants, dates, actionsSortBy]) =>
                        isDateSetOrGenericPeriod(dates) && Object.values(ChartSortBy).includes(actionsSortBy) && !!restaurants.length
                ),
                tap(() => this._reset()),
                map(([restaurants, dates, actionsSortBy]) => {
                    this.sortByChange.emit(actionsSortBy);
                    const [businessRestaurant, nonBusinessRestaurants] = partition(restaurants, (r) => r.isBrandBusiness());
                    this.warningTooltip = this._computeWarningTooltip(businessRestaurant);
                    const lightRestaurants = nonBusinessRestaurants.map((r) => LightRestaurant.fromRestaurant(r));
                    return [lightRestaurants, dates];
                }),
                filter(([_restaurants, dates]: [LightRestaurant[], DatesAndPeriod]) => {
                    const { startDate, endDate } = dates;
                    return !!startDate && !!endDate;
                }),
                switchMap(([restaurants, dates]: [LightRestaurant[], DatesAndPeriod]) => {
                    const startDate = dates.startDate as Date;
                    const endDate = dates.endDate as Date;
                    const currentInsights = this._fetchInsightsForRestaurants(restaurants, startDate, endDate);
                    const previousInsights = this._fetchInsightsForRestaurants(restaurants, startDate, endDate, true);
                    return forkJoin([currentInsights, previousInsights, of(restaurants)]);
                }),
                map(
                    ([currentInsights, previousInsights, restaurants]): [
                        InsightsByPlatformByRestaurant,
                        InsightsByPlatformByRestaurant,
                        LightRestaurant[],
                    ] => {
                        const currentInsightsInError = pickBy(currentInsights, (value) => value?.gmb?.error);
                        const restaurantIdsInError = Object.keys(currentInsightsInError);
                        const restaurantsInError = restaurantIdsInError.map((_id) =>
                            restaurants.find((restaurant) => restaurant.id === _id)
                        );
                        this.errorTooltip = this._computeErrorTooltip(compact(restaurantsInError));
                        return [
                            omitBy(currentInsights, (_value, key) => restaurantIdsInError.includes(key)),
                            omitBy(previousInsights, (_value, key) => restaurantIdsInError.includes(key)),
                            restaurants.filter((restaurant) => !restaurantIdsInError.includes(restaurant.id)),
                        ];
                    }
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(([currentInsights, previousInsights, restaurants]) => {
                if (Object.values(currentInsights).length === 0) {
                    this.hasData.set(false);
                    this.hasDataChange.emit(false);
                    this.isLoading.set(false);
                    return;
                }

                // Chart computing
                this.restaurants.set(restaurants);
                const currentGmbInsights = this._getCurrentGmbInsightsSorted(currentInsights);

                this.gmbActionsData.set({
                    websiteClicks: currentGmbInsights.websiteClicks,
                    phoneClicks: currentGmbInsights.phoneClicks,
                    drivingClicks: currentGmbInsights.drivingClicks,
                    menuClicks: currentGmbInsights.menuClicks,
                    bookingClicks: currentGmbInsights.bookingClicks,
                    foodOrderClicks: currentGmbInsights.foodOrderClicks,
                });

                // Label with evolution computing
                this.totalActions.set(currentGmbInsights.totalActions);

                if (Object.values(previousInsights).length === Object.values(previousInsights).filter((e) => e?.gmb?.total).length) {
                    const previousTotalInsights = Object.values(previousInsights).map((e) => e?.gmb?.total);
                    const previousGmbInsights = this._mapTotalInsightsToChart(compact(previousTotalInsights));

                    this.previousGmbActionsData.set({
                        websiteClicks: previousGmbInsights.websiteClicks,
                        phoneClicks: previousGmbInsights.phoneClicks,
                        drivingClicks: previousGmbInsights.drivingClicks,
                        menuClicks: previousGmbInsights.menuClicks,
                        bookingClicks: previousGmbInsights.bookingClicks,
                        foodOrderClicks: previousGmbInsights.foodOrderClicks,
                    });

                    this.previousTotalActions.set(previousGmbInsights.totalActions);
                    this.conversionRate.set(currentGmbInsights?.ratioActionsOverImpressions);
                    this.previousConversionRate.set(previousGmbInsights?.ratioActionsOverImpressions);
                }
                this.isLoading.set(false);
            });
    }

    sortByDisplayWith = (option: ChartSortBy): string => this._enumTranslate.transform(option, 'chart_sort_by');

    private _getCurrentGmbInsightsSorted(currentInsights: InsightsByPlatformByRestaurant): GmbInsights {
        if (this.sortByFilterSubject$.value === ChartSortBy.ALPHABETICAL) {
            this.restaurants.update((currentRestaurants) => {
                currentRestaurants.sort(sortRestaurantsByInternalNameThenName);
                return [...currentRestaurants];
            });
            const totalInsights = this.restaurants().map((rest) => currentInsights[rest.id]?.gmb?.total);
            return this._mapTotalInsightsToChart(compact(totalInsights));
        }

        const totalInsightsWithRestaurantId = Object.entries(currentInsights).map(([key, value]) => ({
            restaurantId: key,
            insights: value?.gmb?.total,
            totalActions: this._computeTotalActionsByRestaurant(value?.gmb?.total),
        }));
        const sortedTotalInsights = totalInsightsWithRestaurantId.sort(
            (a, b) => (a.totalActions - b.totalActions) * (this.sortByFilterSubject$.value === ChartSortBy.DESC ? -1 : 1)
        );
        this.restaurants.update((currentRestaurants) => {
            const restaurantsObject = currentRestaurants.reduce((a, v) => ({ ...a, [v.id]: v }), {});
            return sortedTotalInsights.map((insight) => restaurantsObject[insight.restaurantId]);
        });
        return this._mapTotalInsightsToChart(compact(sortedTotalInsights.map((e) => e.insights)));
    }

    private _computeWarningTooltip(restaurants: Restaurant[]): string | null {
        if (!restaurants.length) {
            return null;
        }
        const restaurantsLabel = restaurants.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_data_does_not_exist_for_business_restaurants', {
            restaurants: restaurantsLabel,
        });
    }

    private _computeErrorTooltip(restaurants: LightRestaurant[]): string | null {
        if (!restaurants.length) {
            return null;
        }
        const restaurantsLabel = restaurants.map((e) => e.internalName).join(', ');
        return this._translate.instant('aggregated_statistics.errors.gmb_fetching_error', {
            restaurants: restaurantsLabel,
        });
    }

    private _fetchInsightsForRestaurants(
        restaurants: LightRestaurant[],
        startDate: Date,
        endDate: Date,
        previousPeriod = false
    ): Observable<InsightsByPlatformByRestaurant> {
        return this._insightsService
            .getInsights({
                restaurantIds: restaurants.map((r) => r.id),
                platformsKeys: [PlatformKey.GMB],
                metrics: GMB_METRICS.map((m) => m.metric),
                aggregators: [AggregationTimeScale.TOTAL],
                startDate,
                endDate,
                previousPeriod,
            })
            .pipe(
                map((res) => res.data),
                catchError((error) => {
                    if (previousPeriod && error.error?.message?.match(/Time range too long. Maximum start time is 18 months ago/)) {
                        return of({});
                    }
                    this.httpError.set(error);
                    this.hasDataChange.emit(false);
                    this.isLoading.set(false);
                    return EMPTY;
                })
            );
    }

    private _computeTotalActionsByRestaurant(gmbInsightsTotal: MetricToDataValue<Value> | undefined): number {
        if (!gmbInsightsTotal) {
            return 0;
        }
        const actions = [
            MalouMetric.ACTIONS_WEBSITE,
            MalouMetric.ACTIONS_PHONE,
            MalouMetric.ACTIONS_DRIVING_DIRECTIONS,
            MalouMetric.ACTIONS_MENU_CLICK,
            MalouMetric.ACTIONS_BOOKING_CLICK,
            MalouMetric.BUSINESS_FOOD_ORDERS,
        ];
        return actions.reduce((acc, action) => acc + (gmbInsightsTotal[action]?.value || 0), 0);
    }

    private _mapTotalInsightsToChart(gmbInsightsTotalArray: MetricToDataValue<Value>[]): GmbInsights {
        const partialGmbInsights: Partial<GmbInsights> = {
            impressionsDesktopMaps: [],
            impressionsDesktopSearch: [],
            impressionsMobileMaps: [],
            impressionsMobileSearch: [],
            websiteClicks: [],
            phoneClicks: [],
            drivingClicks: [],
            menuClicks: [],
            bookingClicks: [],
            foodOrderClicks: [],
        };

        for (const gmbInsightsTotal of gmbInsightsTotalArray) {
            for (const { metric, key: metricKey } of GMB_METRICS) {
                const value = gmbInsightsTotal[metric]?.value;
                partialGmbInsights[metricKey].push(isNumber(value) ? value : 0);
            }
        }

        return new GmbInsights(partialGmbInsights);
    }

    private _reset(): void {
        this.httpError.set(null);
        this.hasData.set(true);
        this.isLoading.set(true);

        this.warningTooltip = null;
        this.errorTooltip = null;
    }
}
