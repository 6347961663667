<button
    class="malou-btn-icon--secondary btn-xl"
    data-testid="reviews-filters-btn"
    mat-icon-button
    [id]="id()"
    [matMenuTriggerFor]="menu"
    [matTooltip]="'common.filters' | translate"
    [disabled]="disabled()">
    @if (((nonDefaultFiltersCount$ | async) ?? 0) > 0) {
        <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
            {{ nonDefaultFiltersCount$ | async }}
        </span>
    }
    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
</button>

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #menu="matMenu">
    <div class="w-[440px] md:w-full" (click)="$event.stopPropagation()">
        <div class="mb-3 flex w-full justify-between p-6">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button
                class="malou-btn-flat"
                mat-button
                [disabled]="disabled() || (nonDefaultFiltersCount$ | async) == 0"
                (click)="clearFilters()">
                {{ 'common.reset' | translate }}
            </button>
        </div>
        <div class="mr-[10px] flex h-[50vh] flex-col gap-y-5 overflow-y-auto pl-[26px] pr-[16px] md:h-d-70">
            <ng-container [ngTemplateOutlet]="periodFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="ratingsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="platformsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="commentsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="archiveFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<ng-template #periodFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.period' | translate }}
        </div>
        <app-grouped-date-filters
            data-testid="reviews-filters-period-input"
            [startDate]="(storedPeriod$ | async)?.startDate ?? null"
            [endDate]="(storedPeriod$ | async)?.endDate ?? null"
            [periodOptions]="periodOptions"
            [defaultPeriod]="(storedPeriod$ | async)?.period ?? DEFAULT_PERIOD"
            [withLabel]="false"
            [placeholder]="'--.--.---- / --.--.----'"
            [disabled]="disabled()"
            (chooseBoundaryDate)="editDateFilters($event)">
        </app-grouped-date-filters>
    </div>
</ng-template>

<ng-template #ratingsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.ratings' | translate }}
        </div>
        <app-ratings-filters
            [availableRatings]="selectableRatings"
            [ratings]="storedRatings()"
            [disabled]="disabled()"
            (onToggleRating)="editRatingsFilter($event)">
        </app-ratings-filters>
    </div>
</ng-template>

<ng-template #platformsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.platforms' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (platformKey of selectablePlatformKeys$ | async; track platformKey) {
                <button [disabled]="disabled()" (click)="togglePlatformsFilter(platformKey)">
                    <app-platform-logo
                        imgClasses="h-[29px] w-[29px]"
                        [dynamicClasses]="{ 'opacity-50': !((storedPlatforms$ | async) ?? [] | includes: platformKey) }"
                        [logo]="platformKey"></app-platform-logo>
                </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #statusFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.status' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (status of selectableStatuses; track status) {
                <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple [disabled]="disabled()">
                    <mat-button-toggle
                        class="!h-12.5 rounded-[10px]"
                        value="{{ status }}"
                        [checked]="(storedStatuses$ | async)?.[status] ?? false"
                        [disabled]="disabled()"
                        (change)="toggleStatusFilter(status)">
                        <div class="malou-text-12--medium">
                            {{ 'reviews.filters.' + status | translate }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </div>
</ng-template>

<ng-template #commentsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.comments' | translate }}
        </div>
        <div class="flex gap-x-4">
            @for (commentFilter of selectableCommentsFilters; track commentFilter) {
                <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple [disabled]="disabled()">
                    <mat-button-toggle
                        class="!h-12.5 rounded-[10px]"
                        value="{{ commentFilter }}"
                        [checked]="(storedComments$ | async)?.[commentFilter] ?? false"
                        [disabled]="disabled()"
                        (change)="toggleCommentFilter(commentFilter)">
                        <div class="malou-text-12--medium">
                            {{ 'reviews.filters.' + commentFilter | translate }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </div>
</ng-template>

<ng-template #archiveFilterTemplate>
    <div class="mb-2">
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.archive' | translate }}
        </div>
        <div class="flex gap-x-4">
            @for (archiveFilter of selectableArchivedFilters; track archiveFilter) {
                <mat-button-toggle-group class="malou-single-toggle-icon-btn--tertiary" multiple [disabled]="disabled()">
                    <mat-button-toggle
                        class="!h-12.5 rounded-[10px]"
                        value="{{ archiveFilter }}"
                        [attr.data-testid]="'reviews-filters-' + archiveFilter"
                        [checked]="(storedArchives$ | async)?.[archiveFilter] ?? false"
                        [disabled]="disabled()"
                        (change)="toggleArchivedFilters(archiveFilter)">
                        <div class="malou-text-12--medium">
                            {{ 'reviews.filters.' + archiveFilter | translate }}
                        </div>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            }
        </div>
    </div>
</ng-template>
