<div class="flex gap-4 md:flex-col">
    <ng-component
        [ngTemplateOutlet]="cardTemplate"
        [ngTemplateOutletContext]="{
        title: 'statistics.social_networks.community.followers' | translate,
        current: totalFollowers().current,
        diff: totalFollowers().diff
    }">
    </ng-component>
    <ng-component
        [ngTemplateOutlet]="cardTemplate"
        [ngTemplateOutletContext]="{
        title: 'statistics.social_networks.community.new_followers' | translate,
        current: totalNewFollowers().current,
        diff: totalNewFollowers().diff
    }"></ng-component>
</div>

<ng-template let-title="title" let-current="current" let-diff="diff" #cardTemplate>
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">{{ current | shortNumber }}</div>
            @if (diff) {
            <app-number-evolution
                [value]="diff ?? null"
                [displayedValue]="diff | shortNumber: { shouldDisplayMinusSign: false }"></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 flex items-center whitespace-nowrap">{{ title }}</div>
    </div>
</ng-template>
