<div class="malou-dialog">
    <div class="malou-dialog__header">
        <div>
            {{ 'admin.organizations.businesses_list' | translate }}
            <span class="malou-text-18--regular">
                ({{ restaurants.length }}
                @if (data.maxRestaurants) {
                    <span> / {{ data.maxRestaurants }}</span>
                }
                )
            </span>
        </div>
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>

    <div class="malou-dialog__body">
        <div class="flex flex-col gap-y-6">
            <app-search [placeholder]="'common.search' | translate" (searchChange)="onValueChange($event)"></app-search>
            <div class="gap-col-2 flex flex-col">
                @for (restaurant of filteredRestaurants; track trackByIdFn($index, restaurant)) {
                    <div class="my-1">
                        <a class="malou-color-primary malou-text-12--semibold" [routerLink]="'/restaurants/' + restaurant._id">
                            {{ restaurant.name }}
                        </a>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
