import { z } from 'zod';

import { HashtagType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const hashtagValidator = z.object({
    id: objectIdValidator,
    text: z.string(),
    isCustomerInput: z.boolean(),
    isMain: z.boolean().optional(),
    type: z.nativeEnum(HashtagType),
});

export type PostHashtagDto = z.infer<typeof hashtagValidator>;

export const postHashtagsValidator = z.object({
    selected: z.array(hashtagValidator),
    suggested: z.array(hashtagValidator),
});

export type PostHashtagsDto = z.infer<typeof postHashtagsValidator>;
