@if (reviewsStats$ | async; as reviewsStats) {
    @if (!isLoading()) {
        @if (reviewsStats.value) {
            @if (reviewsStats.value.length) {
                <div class="malou-simple-card flex h-full flex-col gap-3 px-6 py-3 md:px-2">
                    <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
                    <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
                    <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
                </div>
            } @else {
                <ng-container [ngTemplateOutlet]="timeRangeErrorTemplate"></ng-container>
            }
        }
        @if (reviewsStats.error) {
            <div>
                <ng-container [ngTemplateOutlet]="errorTemplate" [ngTemplateOutletContext]="{ error: reviewsStats.error }"></ng-container>
            </div>
        }
    } @else {
        <app-skeleton skeletonClass="!h-[539px] secondary-bg"></app-skeleton>
    }
} @else {
    <app-skeleton skeletonClass="!h-[539px] secondary-bg"></app-skeleton>
}

<ng-template #titleTemplate>
    <div class="flex grow flex-wrap items-center justify-between gap-x-2">
        <div class="flex items-center gap-x-2 pt-2">
            <div class="malou-text-section-title malou-color-text-1">
                {{ 'aggregated_statistics.e_reputation.reviews_count' | translate }}
            </div>
            @if (warningTooltip) {
                <div class="malou-status--waiting pdf-hidden" [matTooltip]="warningTooltip">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
            @if (errorTooltip) {
                <div class="malou-status--error pdf-hidden" [matTooltip]="errorTooltip">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </div>
            }
        </div>

        <div class="flex gap-x-2">
            @if (!showSortByTextInsteadOfSelector && viewModeSelected === StatisticsDataViewMode.CHART) {
                <div class="flex items-center gap-2">
                    <span class="malou-text-14--medium md:hidden">{{ 'common.sort' | translate }}</span>
                    <app-select
                        [values]="SORT_BY_VALUES"
                        [displayWith]="sortByDisplayWith"
                        [formControl]="chartSortByControl"
                        (selectChange)="onChartSortByChange($event)"></app-select>
                </div>
            }

            @if (showSortByTextInsteadOfSelector && viewModeSelected === StatisticsDataViewMode.CHART) {
                <div class="flex items-center gap-2">
                    <span class="malou-text-14--regular italic">
                        {{ 'common.sorted_by_order' | translate }}:
                        {{ sortByDisplayWith | applyPure: chartSortByControl.value | lowercase }}
                    </span>
                </div>
            }

            @if (showViewModeToggle) {
                <div>
                    <mat-button-toggle-group
                        class="malou-group-toggle-btn"
                        [value]="viewModeSelected"
                        (change)="onViewModeChange($event.value)">
                        <mat-button-toggle [value]="StatisticsDataViewMode.CHART">
                            <mat-icon [svgIcon]="SvgIcon.AGGREGATED_STATS"></mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle [value]="StatisticsDataViewMode.TABLE">
                            <mat-icon [svgIcon]="SvgIcon.LIST"></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #bodyTemplate>
    @if (viewModeSelected === StatisticsDataViewMode.CHART) {
        <div class="h-[350px]">
            <app-reviews-count-chart [sortBy]="chartSortByControl.value" [data]="restaurantReviewsChartData"> </app-reviews-count-chart>
        </div>
    }
    @if (viewModeSelected === StatisticsDataViewMode.TABLE) {
        <div [ngClass]="{ 'h-[350px]': !expandTable }">
            <app-reviews-count-table [sortBy]="tableSort" [data]="restaurantReviewsChartData" (sortChange)="onTableSortChange($event)">
            </app-reviews-count-table>
        </div>
    }
</ng-template>

<ng-template #infoTemplate>
    <div class="malou-simple-card--light flex flex-1 flex-col items-center py-3">
        <div class="flex items-center">
            <div class="malou-text-30--bold malou-color-text-1">
                {{ totalRestaurantsReviews }}
            </div>
            @if (totalRestaurantsReviewsEvolution) {
                <app-number-evolution
                    [value]="totalRestaurantsReviewsEvolution"
                    [displayedValue]="
                        totalRestaurantsReviewsEvolution | shortNumber: { fixNumber: 1, shouldDisplayMinusSign: false }
                    "></app-number-evolution>
            }
        </div>
        <div class="malou-text-12--regular malou-color-text-2 whitespace-nowrap">
            {{ 'aggregated_statistics.e_reputation.reviews_on_period' | translate }}
        </div>
    </div>
</ng-template>

<ng-template #timeRangeErrorTemplate>
    <div class="malou-simple-card flex h-[400px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Goggles illustration" [src]="Illustration.Goggles | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">
            {{ 'aggregated_statistics.errors.wrong_calculations' | translate }}
        </span>
        <span class="malou-text-10--regular">
            {{ 'aggregated_statistics.errors.no_data_change_filters' | translate }}
        </span>
    </div>
</ng-template>

<ng-template let-error="error" #errorTemplate>
    <div class="malou-simple-card flex h-[400px] flex-col items-center justify-center">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'aggregated_statistics.errors.server_is_not_responding' | translate }}</span>
        <span class="malou-text-10--regular">
            {{ 'aggregated_statistics.errors.error_occurred_try_again' | translate }}
        </span>
        <span class="malou-text-10--regular">{{ error }}</span>
    </div>
</ng-template>
