import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { map } from 'rxjs';

import { ReviewAnalysisSentiment, ReviewAnalysisTag } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { ReviewAnalysesService } from ':core/services/review-analyses.service';
import { RestaurantHeaderForReviewPreviewComponent } from ':modules/reviews/review-preview/restaurant-header-for-review-preview/restaurant-header-for-review-preview.component';
import { ReviewsService } from ':modules/reviews/reviews.service';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { ReviewAnalysesChartFilter, ReviewAnalysesSegmentDetails } from ':shared/components/review-analyses-v2/review-analyses.interface';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { MalouPeriod, Restaurant, ReviewWithAnalysis } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { DateToStringPipe } from ':shared/pipes/date.pipe';
import { ToRangePipe } from ':shared/pipes/to-range.pipe';

enum TabIndex {
    POSITIVE,
    NEGATIVE,
}

@Component({
    selector: 'app-segment-analysis-modal',
    templateUrl: './segment-analysis-modal.component.html',
    styleUrls: ['./segment-analysis-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        LazyLoadImageModule,
        TranslateModule,
        PlatformLogoComponent,
        RestaurantHeaderForReviewPreviewComponent,
        StarGaugeComponent,
        SkeletonComponent,
        AvatarPipe,
        DateToStringPipe,
        ToRangePipe,
    ],
})
export class SegmentAnalysisModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly ReviewAnalysisSentiment = ReviewAnalysisSentiment;

    readonly segmentDetails: WritableSignal<ReviewAnalysesSegmentDetails[]> = signal([]);
    readonly isSegmentLoading = signal(false);

    readonly positiveSegmentDetails = computed(() =>
        this.segmentDetails().filter((segment) => segment.sentiment === ReviewAnalysisSentiment.POSITIVE)
    );
    readonly negativeSegmentDetails = computed(() =>
        this.segmentDetails().filter((segment) => segment.sentiment === ReviewAnalysisSentiment.NEGATIVE)
    );
    readonly positiveSegmentCount = computed(() => this._computeSegmentCount(this.positiveSegmentDetails()));
    readonly negativeSegmentCount = computed(() => this._computeSegmentCount(this.negativeSegmentDetails()));

    readonly segmentReviews = signal<ReviewWithAnalysis[]>([]);
    readonly reviewsByPositiveSegmentText: Record<string, ReviewWithAnalysis[]> = {};
    readonly reviewsByNegativeSegmentText: Record<string, ReviewWithAnalysis[]> = {};

    selectedSegment: ReviewAnalysesSegmentDetails | null = null;
    showReviewsTemplate = false;

    selectedTabIndex: TabIndex = TabIndex.POSITIVE;
    restaurantsById: Record<string, Restaurant> = {};
    isFromAggregatedStatistics = false;

    constructor(
        private readonly _dialogRef: MatDialogRef<SegmentAnalysisModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            tag: ReviewAnalysisTag;
            reviewAnalysesFilter: ReviewAnalysesChartFilter;
            isFromAggregatedStatistics: boolean;
        },
        private readonly _sanitizer: DomSanitizer,
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _reviewAnalysesService: ReviewAnalysesService,
        private readonly _reviewsService: ReviewsService
    ) {}

    ngOnInit(): void {
        this.isFromAggregatedStatistics = this.data.isFromAggregatedStatistics;
        if (this.isFromAggregatedStatistics) {
            this.restaurantsById = this._restaurantsService.restaurants().reduce((acc, restaurant) => {
                acc[restaurant._id] = restaurant;
                return acc;
            }, {});
        }

        this.isSegmentLoading.set(true);
        this._reviewAnalysesService
            .getReviewAnalysesSegmentDetails(this.data.tag, this.data.reviewAnalysesFilter)
            .pipe(map((response) => response.data))
            .subscribe({
                next: (segmentDetails) => {
                    this.isSegmentLoading.set(false);
                    this.segmentDetails.set(segmentDetails);
                    this.selectFirstSegment(this.positiveSegmentCount() ? TabIndex.POSITIVE : TabIndex.NEGATIVE);
                },
                error: (error) => {
                    this.isSegmentLoading.set(false);
                    console.error('Error fetching segment details:', error);
                },
            });
    }

    selectSegment(segmentDetails: ReviewAnalysesSegmentDetails, sentiment: ReviewAnalysisSentiment): void {
        this.selectedSegment = segmentDetails;
        const segmentText = segmentDetails.segmentText;
        const reviewsBySegmentText =
            sentiment === ReviewAnalysisSentiment.POSITIVE ? this.reviewsByPositiveSegmentText : this.reviewsByNegativeSegmentText;
        const reviews = reviewsBySegmentText[segmentText];
        if (reviews?.length > 0) {
            this.segmentReviews.set(reviews);
            this.showReviewsTemplate = true;
            return;
        }
        const socialIds = segmentDetails.reviewAnalyses.map((reviewAnalysis) => reviewAnalysis.reviewSocialId);
        const restaurantIds = Object.keys(this.restaurantsById);
        this._reviewsService.getReviewsBySocialIds(socialIds, restaurantIds).subscribe({
            next: (fetchedReviews) => {
                const fetchedReviewsWithHighlightedText = fetchedReviews.map((review) => ({
                    ...review,
                    highlightedText: this._getTextWithHighlightedSegment(segmentDetails, review.text ?? ''),
                }));
                // We do this because a review can have multiple segments
                const groupedReviews = groupBy(fetchedReviewsWithHighlightedText, 'socialId');
                // We are sure that there is only one review per socialId
                const segmentReviews = socialIds.map((socialId) => groupedReviews[socialId][0]);
                this.segmentReviews.set(segmentReviews);
                reviewsBySegmentText[segmentText] = segmentReviews;
                this.showReviewsTemplate = true;
            },
            error: (error) => {
                console.error('Error fetching reviews:', error);
            },
        });
    }

    selectFirstSegment(tabIndex: TabIndex = TabIndex.POSITIVE): void {
        switch (tabIndex) {
            case TabIndex.POSITIVE:
                this.selectedTabIndex = TabIndex.POSITIVE;
                const firstPositiveSegment = this.positiveSegmentDetails()[0];
                if (firstPositiveSegment) {
                    this.selectSegment(firstPositiveSegment, ReviewAnalysisSentiment.POSITIVE);
                }
                break;
            case TabIndex.NEGATIVE:
                this.selectedTabIndex = TabIndex.NEGATIVE;
                const firstNegativeSegment = this.negativeSegmentDetails()?.[0];
                if (firstNegativeSegment) {
                    this.selectSegment(firstNegativeSegment, ReviewAnalysisSentiment.NEGATIVE);
                }
                break;
            default:
                break;
        }
    }

    close(): void {
        this._dialogRef.close();
    }

    handleTabChange(tabIndex: number): void {
        this.selectedSegment = null;
        this.selectFirstSegment(tabIndex);
    }

    goToReview(review: ReviewWithAnalysis): void {
        this.close();
        const startDate = DateTime.fromJSDate(review.socialCreatedAt).startOf('day').toJSDate();
        const endDate = DateTime.fromJSDate(review.socialCreatedAt).endOf('day').plus({ day: 7 }).toJSDate();
        this._store.dispatch(
            ReviewsActions.editReviewsFiltersDates({
                datesFilters: {
                    period: MalouPeriod.CUSTOM,
                    startDate,
                    endDate,
                },
            })
        );
        if (this.isFromAggregatedStatistics) {
            this._router.navigate(['/groups', 'reputation', 'reviews'], {
                queryParams: { reviewId: review._id, resetDatesFilters: true },
            });
            return;
        }
        this._router.navigate(['/restaurants', review.restaurantId, 'reputation', 'reviews'], {
            queryParams: { reviewId: review._id, resetDatesFilters: true },
        });
    }

    private _computeSegmentCount(segmentDetails: ReviewAnalysesSegmentDetails[]): number {
        return segmentDetails.reduce((acc, segment) => acc + segment.segmentCount, 0);
    }

    private _getTextWithHighlightedSegment(segmentDetails: ReviewAnalysesSegmentDetails, reviewText: string): SafeHtml {
        const segmentText = segmentDetails.segmentText;
        const highlightSegmentText = this._getColoredSegmentText(segmentDetails);
        return this._sanitizer.bypassSecurityTrustHtml(reviewText.replace(segmentText, highlightSegmentText));
    }

    private _getColoredSegmentText(segmentDetails: ReviewAnalysesSegmentDetails): string {
        const color = segmentDetails.sentiment === ReviewAnalysisSentiment.POSITIVE ? '#34B467' : '#EE116E';
        const segmentText = segmentDetails.segmentText;
        return `<span style="color: ${color}; font-weight: 500;">${segmentText}</span>`;
    }
}
