import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { GiftDrawsService } from ':core/services/gift-draws.service';
import { ScansService } from ':core/services/scans.service';
import { DatesAndPeriod, FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS, LightNfc, Nfc, Restaurant } from ':shared/models';
import { ScanForStats } from ':shared/models/scan';

import { AggregatedBoostersStatisticsData, AggregatedWheelOfFortuneGiftsStatisticsData } from '../booster.interface';

@Injectable({
    providedIn: 'root',
})
export class BoostersAggregatedDataFetchingService {
    private readonly _translateService = inject(TranslateService);
    private readonly _scansService = inject(ScansService);
    private readonly _giftDrawsService = inject(GiftDrawsService);

    getChartsData(nfcs: LightNfc[], dates: DatesAndPeriod, restaurants: Restaurant[]): Observable<AggregatedBoostersStatisticsData> {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const difference = DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate)).toObject();
        const previousStartDate = DateTime.fromJSDate(startDate).minus(difference).toJSDate();
        const scanDtos$: Observable<ScanForStats[]> = this._scansService
            .search({
                nfcIds: nfcs?.map((nfc) => nfc.id).concat([FAKE_NFC_ID_FOR_WHEEL_OF_FORTUNE_SCANS]) ?? [],
                restaurantIds: restaurants.map(({ _id }) => _id.toString()),
                startScannedAt: previousStartDate.toISOString(),
                endScannedAt: endDate.toISOString(),
            })
            .pipe(
                map((apiResult) => apiResult.data.map((scan) => new ScanForStats(scan))),
                shareReplay(1)
            );
        const wheelOfFortuneFakeNfcs = restaurants.map((restaurant) =>
            Nfc.createLightFakeWheelOfFortuneNfc({
                restaurantId: restaurant._id,
                platformKey: PlatformKey.GMB,
                name: this._translateService.instant('aggregated_statistics.boosters.scans.wheel_of_fortune'),
            })
        );
        const previousScans$ = scanDtos$.pipe(map((scanDtos) => scanDtos.filter((scan) => new Date(scan.scannedAt) < startDate)));
        const scans$ = scanDtos$.pipe(map((scanDtos) => scanDtos.filter((scan) => new Date(scan.scannedAt) >= startDate)));

        const wheelOfFortuneCount$ = scans$.pipe(map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length));
        const previousWheelOfFortuneCount$ = previousScans$.pipe(
            map((scans) => scans.filter((scan) => scan.isWheelOfFortuneRelated()).length)
        );

        return forkJoin({
            nfcs: of(nfcs.concat([...wheelOfFortuneFakeNfcs])),
            scans: scans$,
            previousScans: previousScans$,
            previousStartDate: of(previousStartDate),
            wheelOfFortuneCount: wheelOfFortuneCount$,
            previousWheelOfFortuneCount: previousWheelOfFortuneCount$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    }

    getGiftsData = (dates: DatesAndPeriod, restaurants: Restaurant[]): Observable<AggregatedWheelOfFortuneGiftsStatisticsData> => {
        const { startDate, endDate } = dates as { startDate: Date; endDate: Date };
        const giftsInsightsPerRestaurant$ = this._giftDrawsService
            .getInsightsByRestaurants({
                restaurantIds: restaurants.map((restaurant) => restaurant.id),
                startDate,
                endDate,
            })
            .pipe(map((res) => res?.data));

        return forkJoin({
            giftsInsightsPerRestaurant: giftsInsightsPerRestaurant$,
            startDate: of(startDate),
            endDate: of(endDate),
        });
    };
}
