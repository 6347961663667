import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { SemanticAnalysisSentiment } from '@malou-io/package-utils';

import { RestaurantsService } from ':core/services/restaurants.service';
import { RestaurantHeaderForReviewPreviewComponent } from ':modules/reviews/review-preview/restaurant-header-for-review-preview/restaurant-header-for-review-preview.component';
import * as ReviewsActions from ':modules/reviews/store/reviews.actions';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { StarGaugeComponent } from ':shared/components/star-gauge/star-gauge.component';
import { MalouPeriod, Restaurant, ReviewWithAnalysis, SegmentWithReview } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { AvatarPipe } from ':shared/pipes/avatar.pipe';
import { DateToStringPipe } from ':shared/pipes/date.pipe';

enum TabIndex {
    POSITIVE,
    NEGATIVE,
}

@Component({
    selector: 'app-segment-analysis-modal',
    templateUrl: './segment-analysis-modal.component.html',
    styleUrls: ['./segment-analysis-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        LazyLoadImageModule,
        TranslateModule,
        StarGaugeComponent,
        AvatarPipe,
        DateToStringPipe,
        PlatformLogoComponent,
        RestaurantHeaderForReviewPreviewComponent,
    ],
})
export class SegmentAnalysisModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;

    segments: SegmentWithReview[];
    positiveSegments: { [segmentText: string]: SegmentWithReview[] };
    negativeSegments: { [segmentText: string]: SegmentWithReview[] };
    segmentReviews: ReviewWithAnalysis[];
    semanticAnalysisSentiment = SemanticAnalysisSentiment;
    selectedSegment: string | null;
    showReviewsTemplate = false;
    positiveSegmentsLength: number;
    negativeSegmentsLength: number;
    selectedTabIndex = 0;
    sortedPositiveSegments: string[];
    sortedNegativeSegments: string[];
    restaurantsById: Record<string, Restaurant> = {};
    isFromAggregatedStatistics = false;

    constructor(
        private readonly _dialogRef: MatDialogRef<SegmentAnalysisModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            segments: SegmentWithReview[];
            isFromAggregatedStatistics: boolean;
        },
        private readonly _sanitizer: DomSanitizer,
        private readonly _router: Router,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store
    ) {}

    ngOnInit(): void {
        this.isFromAggregatedStatistics = this.data.isFromAggregatedStatistics;
        if (this.isFromAggregatedStatistics) {
            this.restaurantsById = this._restaurantsService.restaurants().reduce((acc, restaurant) => {
                acc[restaurant._id] = restaurant;
                return acc;
            }, {});
        }
        this.segments = this.data.segments;
        this.positiveSegments = groupBy(
            this.segments?.filter((segment) => segment.sentiment === SemanticAnalysisSentiment.POSITIVE),
            (val) => val.segment.toLowerCase()
        );

        this.sortedPositiveSegments = Object.keys(this.positiveSegments).sort(
            (a, b) => this.positiveSegments[b].length - this.positiveSegments[a].length
        );

        this.negativeSegments = groupBy(
            this.segments?.filter((segment) => segment.sentiment === SemanticAnalysisSentiment.NEGATIVE),
            (val) => val.segment.toLowerCase()
        );
        this.sortedNegativeSegments = Object.keys(this.negativeSegments).sort(
            (a, b) => this.negativeSegments[b].length - this.negativeSegments[a].length
        );

        this.positiveSegmentsLength = Object.values(this.positiveSegments).flat().length;
        this.negativeSegmentsLength = Object.values(this.negativeSegments).flat().length;
        if (this.positiveSegmentsLength) {
            this.selectFirstSegment(TabIndex.POSITIVE);
        } else {
            this.selectFirstSegment(TabIndex.NEGATIVE);
        }
    }

    selectSegment(segmentText: string, sentiment: SemanticAnalysisSentiment): void {
        this.selectedSegment = segmentText;
        const color = sentiment === SemanticAnalysisSentiment.POSITIVE ? '#34B467' : '#EE116E';
        const highlightSegmentText = `<span style="color: ${color}; font-weight: 500;">${segmentText}</span>`;

        if (sentiment === SemanticAnalysisSentiment.NEGATIVE) {
            this.segmentReviews = this.negativeSegments[segmentText]?.map((s) =>
                this._getSegmentsWithHighlightedText(s, highlightSegmentText)
            );
            this.showReviewsTemplate = true;
            return;
        }

        this.segmentReviews = this.positiveSegments[segmentText]?.map((s) => this._getSegmentsWithHighlightedText(s, highlightSegmentText));
        this.showReviewsTemplate = true;
    }

    selectFirstSegment(tabIndex: TabIndex = TabIndex.POSITIVE): void {
        switch (tabIndex) {
            case TabIndex.POSITIVE:
                this.selectedTabIndex = 0;
                const firstPositiveSegment = this.sortedPositiveSegments?.[0];
                this.selectSegment(firstPositiveSegment, SemanticAnalysisSentiment.POSITIVE);
                break;
            case TabIndex.NEGATIVE:
                this.selectedTabIndex = 1;
                const firstNegativeSegment = this.sortedNegativeSegments?.[0];
                this.selectSegment(firstNegativeSegment, SemanticAnalysisSentiment.NEGATIVE);
                break;
            default:
                break;
        }
    }

    close(): void {
        this._dialogRef.close();
    }

    handleTabChange(tabIndex: number): void {
        this.segmentReviews = [];
        this.selectedSegment = null;
        this.selectFirstSegment(tabIndex);
    }

    goToReview(review: ReviewWithAnalysis): void {
        this.close();
        const startDate = DateTime.fromJSDate(review.socialCreatedAt).startOf('day').toJSDate();
        const endDate = DateTime.fromJSDate(review.socialCreatedAt).endOf('day').plus({ day: 7 }).toJSDate();
        this._store.dispatch(
            ReviewsActions.editReviewsFiltersDates({
                datesFilters: {
                    period: MalouPeriod.CUSTOM,
                    startDate,
                    endDate,
                },
            })
        );
        if (this.isFromAggregatedStatistics) {
            this._router.navigate(['/groups', 'reputation', 'reviews'], {
                queryParams: { reviewId: review._id, resetDatesFilters: true },
            });
            return;
        }
        this._router.navigate(['/restaurants', review.restaurantId, 'reputation', 'reviews'], {
            queryParams: { reviewId: review._id, resetDatesFilters: true },
        });
    }

    private _getSegmentsWithHighlightedText(
        segmentsWithReview: SegmentWithReview,
        highlightSegmentText: string
    ): ReviewWithAnalysis & { highlightedText: SafeHtml } {
        const reviewSegmentText = segmentsWithReview.segment;
        return {
            ...segmentsWithReview.review,
            highlightedText: this._sanitizer.bypassSecurityTrustHtml(
                segmentsWithReview.review.text?.replace(reviewSegmentText, highlightSegmentText) ?? ''
            ),
        };
    }
}
