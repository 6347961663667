<div
    class="flex flex-col rounded-[15px]"
    [ngClass]="{
        'p-3.5': theme() === SemanticAnalysisDisplayStyle.FULL,
        'container-border-white': theme() === SemanticAnalysisDisplayStyle.FULL && (review()?.comments?.length || whiteBorders()),
        'container-border-grey': theme() === SemanticAnalysisDisplayStyle.FULL && !review()?.comments?.length && !whiteBorders(),
    }">
    @if (theme() === SemanticAnalysisDisplayStyle.FULL) {
        <div class="flex">
            <span class="malou-text-10--bold">{{ 'reviews.review_analyses.semantic_analysis' | translate }}</span>
        </div>
    }

    <div [ngClass]="{ 'mt-3': theme() === SemanticAnalysisDisplayStyle.FULL }">
        @if (showOriginalTextWarning()) {
            <span class="malou-text-10 italic">{{ 'reviews.review_analyses.show_original_text' | translate }}</span>
        } @else {
            @switch (analysisStatus()) {
                @case (ReviewAnalysisStatus.DONE) {
                    <div class="flex flex-wrap gap-y-2.5">
                        @for (segmentAnalysis of segmentAnalyses(); track segmentAnalysis) {
                            <div class="mr-[10px]">
                                <div
                                    class="malou-chip"
                                    [ngClass]="{
                                        '!malou-text-10 !h-[25px]': theme() === SemanticAnalysisDisplayStyle.LIGHT,
                                        'malou-chip--success':
                                            segmentAnalysis.sentiment.toLowerCase() === SemanticAnalysisSentiment.POSITIVE.toLowerCase(),
                                        'malou-chip--error':
                                            segmentAnalysis.sentiment.toLowerCase() === SemanticAnalysisSentiment.NEGATIVE.toLowerCase(),
                                    }"
                                    [class.chip-hover-opacity]="isAnyChipHovered()"
                                    (mouseenter)="onMouseEnter(segmentAnalysis)"
                                    (mouseleave)="onMouseLeave()">
                                    <span>{{ 'reviews.review_analyses.' + segmentAnalysis.tag | translate }}</span>
                                </div>
                            </div>
                        }
                    </div>
                }
                @case (ReviewAnalysisStatus.REVIEW_TOO_OLD) {
                    <div class="flex">
                        <span class="malou-text-10 italic">{{ 'reviews.review_analyses.review_too_old' | translate }}</span>
                    </div>
                }
                @case (ReviewAnalysisStatus.FAILED) {
                    <div class="flex">
                        <span class="malou-text-10 italic">{{ 'reviews.review_analyses.not_analyzed' | translate }}</span>
                    </div>
                }
                @case (ReviewAnalysisStatus.UNSUPPORTED_PLATFORM) {
                    <div class="flex">
                        <span class="malou-text-10 italic">{{ 'reviews.review_analyses.platform_not_supported' | translate }}</span>
                    </div>
                }
                @case (ReviewAnalysisStatus.NO_RESULTS) {
                    <div class="flex">
                        <span class="malou-text-10 italic">{{ 'reviews.review_analyses.no_results' | translate }}</span>
                    </div>
                }
                @default {
                    <div class="flex">
                        <span class="malou-text-10 italic">{{ 'reviews.review_analyses.not_yet_analyzed' | translate }}</span>
                    </div>
                }
            }
        }
    </div>
</div>
