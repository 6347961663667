<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[400px]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[700px]': shouldDisplayFailedImports(),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : page"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="close()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #page>
    <ng-container *ngTemplateOutlet="shouldDisplayFailedImports() ? failedImportsTemplate : loadFormTemplate"></ng-container>
</ng-template>

<ng-template #loadFormTemplate>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div class="flex flex-col">
                <span>{{ 'admin.nfcs.load_from_sheet_modal.title' | translate }}</span>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close({ shouldCheckChangesBeforeClose: true })">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <form [formGroup]="loadNfcsForm">
                <div class="flex flex-col gap-y-2">
                    <app-input-text
                        formControlName="sheetLink"
                        [required]="true"
                        [title]="'admin.nfcs.load_from_sheet_modal.sheet_link' | translate"
                        [errorMessage]="!sheetLink?.valid ? ('admin.nfcs.load_from_sheet_modal.sheet_link_error' | translate) : ''">
                    </app-input-text>

                    <app-input-number
                        [title]="'admin.nfcs.load_from_sheet_modal.sheet_number' | translate"
                        [value]="sheetNumber?.value"
                        [errorMessage]="!sheetNumber?.valid ? ('admin.nfcs.load_from_sheet_modal.sheet_number_error' | translate) : ''"
                        (inputNumberChange)="onSheetNumberChange($event)">
                    </app-input-number>
                </div>
            </form>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-11" mat-raised-button (click)="close({ shouldCheckChangesBeforeClose: true })">
                {{ 'common.cancel' | translate }}
            </button>
            <button class="malou-btn-raised--primary !h-11" mat-raised-button [disabled]="!loadNfcsForm.valid" (click)="submit()">
                {{ 'common.add' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #failedImportsTemplate>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <div class="flex flex-col">
                <span>
                    {{ 'admin.nfcs.load_from_sheet_modal.failed_imports_title' | translate }}
                </span>
            </div>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <mat-table class="malou-mat-table" [dataSource]="dataSource()" #table="matTable">
                <ng-container matColumnDef="restaurantName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'admin.nfcs.load_from_sheet_modal.failed_imports_restaurant_name' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let failedImport; table: table">
                        <span class="malou-text-12--regular">{{ failedImport.data.restaurantName }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="restaurantId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'admin.nfcs.load_from_sheet_modal.failed_imports_restaurant_id' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let failedImport; table: table">
                        <span class="malou-text-8--regular">{{ failedImport.data.restaurantId }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nfcId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'admin.nfcs.load_from_sheet_modal.failed_imports_nfc_id' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let failedImport; table: table">
                        <span class="malou-text-12--regular">{{ failedImport.data.nfcId }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reason">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'admin.nfcs.load_from_sheet_modal.failed_imports_reason' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let failedImport; table: table">
                        <span class="malou-text-12--regular">{{ failedImport.reason | enumTranslate: 'failed_to_create_nfc_reason' }}</span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS; table: table"></mat-row>
            </mat-table>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--primary !h-11" mat-raised-button (click)="close()">
                {{ 'common.close' | translate }}
            </button>
        </div>
    </div>
</ng-template>
