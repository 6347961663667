import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, Signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy, toInteger } from 'lodash';

import { NfcStar } from '@malou-io/package-utils';

import { PrivateReviewStatisticsDataV2 } from ':modules/aggregated-statistics/boosters/booster.interface';
import { PrivateReviewCountChartV2Component } from ':modules/statistics/boosters/private-review-count-v2/private-review-count-chart/private-review-count-chart.component';
import * as StatisticsActions from ':modules/statistics/store/statistics.actions';
import { NumberEvolutionComponent } from ':shared/components/number-evolution/number-evolution.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ShortNumberPipe } from ':shared/pipes/short-number.pipe';

@Component({
    selector: 'app-statistics-totems-private-review-count-v2',
    templateUrl: './private-review-count.component.html',
    styleUrls: ['./private-review-count.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgTemplateOutlet,
        SkeletonComponent,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NumberEvolutionComponent,
        MatProgressSpinnerModule,
        ShortNumberPipe,
        IllustrationPathResolverPipe,
        TranslateModule,
        MatIconModule,
        PrivateReviewCountChartV2Component,
    ],
    providers: [EnumTranslatePipe],
})
export class PrivateReviewCountV2Component {
    readonly data = input<PrivateReviewStatisticsDataV2 | null>();
    readonly isParentLoading = input<boolean>();
    readonly isParentError = input<boolean>();
    readonly hasDataChange = output<boolean>();

    private readonly _store = inject(Store);
    private readonly _translateService = inject(TranslateService);

    readonly SvgIcon = SvgIcon;
    readonly Illustration = Illustration;
    readonly RATING_TRANSLATION_BY_VALUE = {
        [NfcStar.ONE]: this._translateService.instant('statistics.totems.one_star'),
        [NfcStar.TWO]: this._translateService.instant('statistics.totems.two_stars'),
        [NfcStar.THREE]: this._translateService.instant('statistics.totems.three_stars'),
        [NfcStar.FOUR]: this._translateService.instant('statistics.totems.four_stars'),
        [NfcStar.FIVE]: this._translateService.instant('statistics.totems.five_stars'),
    };

    readonly hasData: Signal<Boolean> = computed(() => {
        const data = this.data();
        if (!data) {
            return false;
        }
        const { privateReviewsDto } = data;
        const privateReviewCountOnPeriod = privateReviewsDto.length;
        this.hasDataChange.emit(privateReviewCountOnPeriod > 0);
        return privateReviewCountOnPeriod > 0;
    });

    constructor() {
        effect(
            () => {
                const data = this.data();
                if (data) {
                    this._store.dispatch(StatisticsActions.editPrivateReviewData({ data: data.privateReviewsDto }));
                }
            },
            { allowSignalWrites: true }
        );
    }

    readonly computedData = computed(() => {
        const data = this.data();
        if (!data) {
            return {
                privateReviewCountData: [],
                privateReviewCountOnPeriod: null,
                privateReviewCountDifferenceWithPreviousPeriod: null,
            };
        }
        const { nfcs, scans, privateReviewsDto, previousPrivateReviewsDto } = data;
        const privateReviewCountData = Object.entries(this.RATING_TRANSLATION_BY_VALUE).map(([rating, translation]) => {
            const privateReviewsFiltered = privateReviewsDto.filter((privateReview) => privateReview.rating === toInteger(rating));
            const privateReviewsByNfcId = groupBy(privateReviewsFiltered, (value) => {
                const scan = scans.find((s) => s.id === value.scanId);
                return scan?.nfcId;
            });
            const privateReviewsCountByNfcName = Object.entries(privateReviewsByNfcId).reduce((acc, [nfcId, privateReviews]) => {
                const nfc = nfcs.find((n) => n.id === nfcId);
                const key = nfc?.name ?? nfc?.chipName;
                if (key) {
                    acc[key] = privateReviews.length;
                }
                return acc;
            }, {});
            return {
                translation,
                rating: toInteger(rating),
                value: privateReviewsFiltered.length,
                details: privateReviewsCountByNfcName,
            };
        });
        const privateReviewCountOnPeriod = privateReviewsDto.length;
        const privateReviewCountDifferenceWithPreviousPeriod = privateReviewCountOnPeriod - previousPrivateReviewsDto.length;
        return {
            privateReviewCountData,
            privateReviewCountOnPeriod,
            privateReviewCountDifferenceWithPreviousPeriod,
        };
    });
}
