import { ChangeDetectionStrategy, Component, computed, input, OnInit, Signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartDataset, ChartOptions, ChartType, Plugin, TooltipItem } from 'chart.js';
import { EmptyObject } from 'chart.js/dist/types/basic';
import { NgChartsModule } from 'ng2-charts';

import { ReviewAnalysisChartDataSentiment, ReviewAnalysisChartDataTag } from '@malou-io/package-utils';

import { ReviewAnalysesChartDataByRestaurantId } from ':shared/components/review-analyses-v2/review-analyses-chart-data-by-restaurant-id/review-analyses-chart-data-by-restaurant-id';
import { ChartDataArray, malouChartColorGreen, malouChartColorRed, malouChartColorText1, malouChartColorText2 } from ':shared/helpers';
import { SegmentAnalyses } from ':shared/models';

type DoughnutChartType = Extract<ChartType, 'doughnut'>;

const colors = [malouChartColorGreen, malouChartColorRed];

enum Quarter {
    TOP_RIGHT,
    TOP_LEFT,
    BOTTOM_RIGHT,
    BOTTOM_LEFT,
}

enum ChartDataIndex {
    POSITIVE,
    NEGATIVE,
}

interface CustomChartLabel {
    value: number;
    percentageValue: number;
    subText: string[];
}

@Component({
    selector: 'app-tags-doughnut-chart',
    templateUrl: './tags-doughnut-chart.component.html',
    styleUrls: ['./tags-doughnut-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsDoughnutChartComponent implements OnInit {
    chartData = input.required<ReviewAnalysesChartDataByRestaurantId>();

    readonly CHART_TYPE: DoughnutChartType = 'doughnut';
    readonly CENTER_TEXT_PLUGIN: Plugin = this._getCenterTextPlugin();
    readonly DOUGHNUT_LABEL_LINE: Plugin = this._getDoughnutLabelLinePlugin();

    readonly chartDataSets: Signal<ChartDataset<DoughnutChartType, ChartDataArray>[]> = computed(() => {
        const chartData = this.chartData();
        return this._computeChartData(chartData);
    });
    readonly chartLabels: Signal<CustomChartLabel[]> = computed(() => {
        const chartData = this.chartData();
        return this._computeChartLabels(chartData);
    });
    chartOption: ChartOptions<DoughnutChartType>;

    allSegmentAnalyses: SegmentAnalyses[];

    constructor(private readonly _translate: TranslateService) {}

    ngOnInit(): void {
        this.chartOption = this._computeChartOptions();
    }

    private _computeChartData(chartData: ReviewAnalysesChartDataByRestaurantId): ChartDataset<DoughnutChartType, ChartDataArray>[] {
        const { positiveSentimentsPercentage, negativeSentimentsPercentage } = chartData.getSentimentPercentage(
            ReviewAnalysisChartDataTag.TOTAL
        );

        return [
            {
                backgroundColor: colors,
                borderColor: colors,
                data: [positiveSentimentsPercentage, negativeSentimentsPercentage],
                borderWidth: 0,
            },
        ];
    }

    private _computeChartLabels(chartData: ReviewAnalysesChartDataByRestaurantId): CustomChartLabel[] {
        const positiveSentimentsCount = chartData.getCount(ReviewAnalysisChartDataTag.TOTAL, ReviewAnalysisChartDataSentiment.POSITIVE);
        const negativeSentimentsCount = chartData.getCount(ReviewAnalysisChartDataTag.TOTAL, ReviewAnalysisChartDataSentiment.NEGATIVE);
        const { positiveSentimentsPercentage, negativeSentimentsPercentage } = chartData.getSentimentPercentage(
            ReviewAnalysisChartDataTag.TOTAL
        );

        return [
            {
                value: positiveSentimentsCount,
                percentageValue: positiveSentimentsPercentage,
                subText: [
                    this._translate.instant('aggregated_statistics.e_reputation.reviews_analysis.feelings'),
                    this._translate.instant('aggregated_statistics.e_reputation.reviews_analysis.positive'),
                ],
            },
            {
                value: negativeSentimentsCount,
                percentageValue: negativeSentimentsPercentage,
                subText: [
                    this._translate.instant('aggregated_statistics.e_reputation.reviews_analysis.feelings'),
                    this._translate.instant('aggregated_statistics.e_reputation.reviews_analysis.negative'),
                ],
            },
        ];
    }

    private _computeChartOptions(): ChartOptions<DoughnutChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>): string => {
                            const labels = tooltipItem.chart.data.labels as CustomChartLabel[];
                            const { value } = labels[tooltipItem.dataIndex];
                            if (tooltipItem.dataIndex === ChartDataIndex.POSITIVE) {
                                return ` ${this._translate.instant(
                                    'statistics.e_reputation.reviews_analysis.positive_sentiments'
                                )}: ${value}`;
                            }
                            return ` ${this._translate.instant('statistics.e_reputation.reviews_analysis.negative_sentiments')}: ${value}`;
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            cutout: '80%',
            layout: {
                padding: {
                    top: 40,
                    bottom: 50,
                    left: 150,
                    right: 150,
                },
            },
        };
    }

    private _getCenterTextPlugin(): Plugin {
        return {
            id: 'centerText',
            afterDraw: (chart: Chart, _args: EmptyObject): void => {
                const { ctx } = chart;
                ctx.save();
                const x = chart.getDatasetMeta(0).data[0].x;
                const y = chart.getDatasetMeta(0).data[0].y;

                ctx.font = '600 16px Poppins';
                ctx.fillStyle = malouChartColorText2;
                const text = `${this.chartData().getCount(ReviewAnalysisChartDataTag.TOTAL, ReviewAnalysisChartDataSentiment.TOTAL)} ${this._translate.instant('aggregated_statistics.e_reputation.reviews_analysis.feelings')}`;
                const textWidth = ctx.measureText(text);
                ctx.fillText(text, x - textWidth.width / 2, y + 10);
                ctx.restore();
            },
        };
    }

    private _getDoughnutLabelLinePlugin(): Plugin {
        return {
            id: 'doughnutLabelLine',
            afterDraw: (chart: Chart, _args: EmptyObject): void => {
                const { ctx } = chart;
                const centerX = chart.getDatasetMeta(0).data[0].x;
                const centerY = chart.getDatasetMeta(0).data[0].y;
                chart.data.datasets.forEach((dataset, i) => {
                    chart.getDatasetMeta(i).data.forEach((dataPoint, index) => {
                        ctx.save();
                        const { x, y } = dataPoint.tooltipPosition(true);
                        if (dataset.borderColor) {
                            ctx.strokeStyle = dataset.borderColor[index];
                        }

                        const quarter = this._getQuarter(centerX, centerY, x, y);
                        ctx.beginPath();
                        ctx.moveTo(x, y);

                        const gap = 30;
                        const gap_2x = 50;
                        let currentXPos;
                        let currentYPos;

                        switch (quarter) {
                            case Quarter.TOP_RIGHT:
                                currentXPos = x + gap_2x;
                                currentYPos = y + gap;
                                ctx.lineTo(x + gap, y + gap);
                                ctx.lineTo(x + gap_2x, y + gap);
                                break;
                            case Quarter.BOTTOM_RIGHT:
                                currentXPos = x + gap_2x;
                                currentYPos = y - gap;
                                ctx.lineTo(x + gap, y - gap);
                                ctx.lineTo(x + gap_2x, y - gap);
                                break;
                            case Quarter.BOTTOM_LEFT:
                                currentXPos = x - gap_2x;
                                currentYPos = y - gap;
                                ctx.lineTo(x - gap, y - gap);
                                ctx.lineTo(x - gap_2x, y - gap);
                                ctx.textAlign = 'end';
                                break;
                            case Quarter.TOP_LEFT:
                                currentXPos = x - gap_2x;
                                currentYPos = y + gap;
                                ctx.lineTo(x - gap, y + gap);
                                ctx.lineTo(x - gap_2x, y + gap);
                                ctx.textAlign = 'end';
                                break;
                            default:
                                break;
                        }
                        ctx.stroke();
                        const labels = chart.data.labels as CustomChartLabel[];
                        const label = labels[index];

                        ctx.textBaseline = 'middle';

                        ctx.font = '600 14px Poppins';
                        ctx.fillStyle = malouChartColorText1;
                        ctx.fillText(`${Math.round(label.percentageValue)} %`, currentXPos, currentYPos - 8);

                        ctx.font = 'italic 400 12px Poppins';
                        ctx.fillStyle = malouChartColorText2;
                        ctx.fillText(`${label.subText[0]}`, currentXPos, currentYPos + 8);
                        ctx.fillText(`${label.subText[1]}`, currentXPos, currentYPos + 20);

                        ctx.restore();
                    });
                });
            },
        };
    }

    private _getQuarter(centerX: number, centerY: number, x: number, y: number): Quarter {
        if (x > centerX) {
            if (y > centerY) {
                return Quarter.TOP_RIGHT;
            } else {
                return Quarter.BOTTOM_RIGHT;
            }
        } else {
            if (y > centerY) {
                return Quarter.TOP_LEFT;
            } else {
                return Quarter.BOTTOM_LEFT;
            }
        }
    }
}
