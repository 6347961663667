export enum UploadErrorCode {
    INVALID_FILE = 'INVALID_FILE',
}

export type UploadMediaV2ResponseDto =
    | {
          success: true;
          mediaId: string;
      }
    | {
          success: false;
          errorCode: UploadErrorCode.INVALID_FILE;
      };
