import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    model,
    ModelSignal,
    OnInit,
    Signal,
    signal,
    ViewChild,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { filter, map, Subject } from 'rxjs';

import { ApplicationLanguage, HeapEventName, isNotNil, RestaurantRankingFormat } from '@malou-io/package-utils';

import { HeapService } from ':core/services/heap.service';
import { KeywordsService } from ':core/services/keywords.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { RankingsCompetitorsListComponent } from ':modules/keywords/rankings-competitors-list/rankings-competitors-list.component';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { KeywordsPopularityComponent } from ':shared/components/keywords-popularity/keywords-popularity.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { SelectionModel } from ':shared/helpers/selection-model';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Keyword } from ':shared/models/keyword';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FlagPathResolverPipe } from ':shared/pipes/flag-path-resolver.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { UpdateKeywordModalComponent } from '../../update-keyword-modal/update-keyword-modal.component';
import { KeywordsWithCompetitorsTableDataRow, SortBy, SortOption } from '../keywords-list.component';
import { RankingTableDataRow } from '../keywords-list.component.interface';

@Component({
    selector: 'app-selected-keywords-list',
    templateUrl: './selected-keywords-list.component.html',
    styleUrls: ['./selected-keywords-list.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatSortModule,
        MatTooltipModule,
        MatTableModule,
        TranslateModule,
        ApplyPurePipe,
        FlagPathResolverPipe,
        KeywordsPopularityComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedKeywordsListComponent implements OnInit {
    readonly dataSource: ModelSignal<MatTableDataSource<KeywordsWithCompetitorsTableDataRow>> = model.required();
    readonly selection: InputSignal<SelectionModel<KeywordsWithCompetitorsTableDataRow>> = input.required();
    readonly restaurantId: InputSignal<string> = input.required();
    readonly keywords: ModelSignal<Keyword[]> = model.required();

    readonly SvgIcon = SvgIcon;
    readonly selectionCount$: Subject<number> = new Subject<number>();
    readonly selectionCount: Signal<number> = toSignal(this.selectionCount$, { initialValue: 0 });
    readonly isAllSelected = computed(() => this.dataSource().data.length === this.selectionCount());

    readonly updatingKeywordLangs: WritableSignal<string[]> = signal([]);
    readonly isKeywordLangUpdating = computed(
        () =>
            (keyword: KeywordsWithCompetitorsTableDataRow): boolean =>
                this.updatingKeywordLangs().includes(keyword.restaurantKeywordId)
    );

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    readonly isAdmin$ = this._store.select(selectUserInfos).pipe(
        filter(isNotNil),
        map((infos) => infos.role === 'admin')
    );
    readonly isAdmin = toSignal(this.isAdmin$, { initialValue: false });

    readonly sortOptions: Signal<SortOption[]> = computed(() => [
        { value: SortBy.Keywords, text: this._translateService.instant('keywords.keywords') },
        { value: SortBy.Language, text: this._translateService.instant('keywords.validation.lang') },
        {
            value: SortBy.SearchVolume,
            text: this._translateService.instant('keywords.popularity'),
        },
    ]);
    readonly selectedSortOption: WritableSignal<SortOption> = signal(this.sortOptions()[0]);
    readonly sortDirection: WritableSignal<1 | -1> = signal(-1);

    readonly DISPLAYED_COLUMNS: string[] = ['select', 'keyword', 'language', 'volume', 'competitors'];
    readonly APPLICATION_LANGUAGES: ApplicationLanguage[] = Object.values(ApplicationLanguage);

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    constructor(
        private readonly _customDialogService: CustomDialogService,
        private readonly _keywordsService: KeywordsService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService,
        private readonly _heapService: HeapService,
        private readonly _store: Store,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource().sort = sort;
    }

    ngOnInit(): void {
        this.selection()
            .getCount$()
            .subscribe((count) => this.selectionCount$.next(count));
    }

    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection().unselectAll();
            return;
        }

        this.selection().select(this.dataSource().data);
    }

    getPrettyLang = (lang: string): string => this._translateService.instant(`header.langs.${lang}`);

    changeSortOrder(): void {
        this.sortDirection.update((currentSortDirection) => (currentSortDirection === 1 ? -1 : 1));
        this.sortKeywords();
    }

    sortKeywords(sortOption: SortOption = this.selectedSortOption()): void {
        this.selectedSortOption.set(sortOption);

        this.dataSource.update((currentDataSource) => {
            currentDataSource.data.sort((a, b) => {
                const sortDirectionCoef = this.sortDirection() === 1 ? 1 : -1;
                return this._sortActiveColumn(sortOption.value, a, b, sortDirectionCoef);
            });

            return cloneDeep(currentDataSource);
        });
    }

    openCustomVolumeModal(keyword: RankingTableDataRow): void {
        this._customDialogService
            .open(UpdateKeywordModalComponent, {
                data: {
                    keywordToUpdate: keyword,
                    keywords: this.keywords(),
                    restaurantId: this.restaurantId(),
                },
                height: '275px',
            })
            .afterClosed()
            .subscribe({
                next: (newValue) => {
                    if (newValue) {
                        this.dataSource.update((currentDataSource) => {
                            const index = currentDataSource.data.findIndex((kw) => kw.keywordId === keyword.keywordId);
                            currentDataSource.data[index] = newValue;
                            return cloneDeep(currentDataSource);
                        });
                    }
                },
            });
    }

    updateKeywordLanguage(keyword: KeywordsWithCompetitorsTableDataRow, lang: ApplicationLanguage): void {
        if (lang === keyword.language || this.isKeywordLangUpdating()(keyword)) {
            return;
        }

        this.updatingKeywordLangs.update((currentUpdatingKeywordLangs) => {
            currentUpdatingKeywordLangs.push(keyword.restaurantKeywordId);
            return currentUpdatingKeywordLangs;
        });

        this._trackUpdateKeywordLanguageEvent(lang);

        const oldLanguage = keyword.language;
        keyword.language = lang; // optimistic update

        this._keywordsService.updateKeywordLanguage(keyword.keywordId, this.restaurantId(), lang).subscribe({
            next: (updatedKeyword) => {
                if (updatedKeyword) {
                    this._updateDataSourceAndKeyword(keyword, updatedKeyword);
                    this._toastService.openSuccessToast(this._translateService.instant('keywords.update_success'));
                }
                this.updatingKeywordLangs.update((currentUpdatingKeywordLangs) =>
                    currentUpdatingKeywordLangs.filter((id) => id !== keyword.restaurantKeywordId)
                );
            },
            error: (err) => {
                console.warn(err);
                keyword.language = oldLanguage; // revert optimistic update
                this._toastService.openErrorToast(
                    this._translateService.instant('common.unknown_error') + this._httpErrorPipe.transform(err)
                );
            },
        });
    }

    openCompetitorsList(keyword: string, ranking: RestaurantRankingFormat[], indexPosition?: number): void {
        if (!indexPosition) {
            return;
        }
        this._customDialogService
            .open(RankingsCompetitorsListComponent, {
                height: 'unset',
                data: {
                    keyword,
                    ranking,
                    indexPosition,
                },
            })
            .afterClosed();
    }

    private _updateDataSourceAndKeyword(keyword: KeywordsWithCompetitorsTableDataRow, updatedKeyword: Keyword): void {
        this.keywords.update((currentKeywords) => {
            const index = currentKeywords.findIndex((kw) => kw.keywordId === keyword.keywordId);
            currentKeywords[index] = updatedKeyword;
            return cloneDeep(currentKeywords);
        });

        this.dataSource.update((currentDataSource) => {
            const index = currentDataSource.data.findIndex((kw) => kw.keywordId === keyword.keywordId);
            currentDataSource.data[index] = {
                ...keyword,
                ...updatedKeyword,
            };
            return cloneDeep(currentDataSource);
        });
    }

    private _sortActiveColumn(value: SortBy, a: RankingTableDataRow, b: RankingTableDataRow, sortDirectionCoef: number): number {
        switch (value) {
            case SortBy.Keywords:
                return a.keyword.toLowerCase().localeCompare(b.keyword.toLowerCase()) * sortDirectionCoef;
            case SortBy.Language:
                return a.language.toLowerCase().localeCompare(b.language.toLowerCase()) * sortDirectionCoef;
            case SortBy.SearchVolume:
                return ((a.volume ?? 0) - (b.volume ?? 0)) * sortDirectionCoef;
            default:
                return 0;
        }
    }

    private _trackUpdateKeywordLanguageEvent(lang: ApplicationLanguage): void {
        switch (lang) {
            case ApplicationLanguage.FR:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_FR);
                break;
            case ApplicationLanguage.EN:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_EN);
                break;
            case ApplicationLanguage.ES:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_ES);
                break;
            case ApplicationLanguage.IT:
                this._heapService.track(HeapEventName.UPDATE_KEYWORD_LANGUAGE_IT);
                break;
            default:
                break;
        }
    }
}
