import { SocialPostItem } from ':modules/posts-v2/social-posts/models/social-post-item';

export interface UpsertSocialPostModalProps {
    postId?: string;
    shouldOpenFeedbacks?: boolean;
}

export interface UpsertSocialPostModalResult {
    post: SocialPostItem | null;
}

// Special case, we whitelist the "Very French Beans" page because it does not contain location
const VERYFRENCHBEANS_PAGE_ID = '100270695184218';
export const WHITELISTED_PAGE_IDS = [VERYFRENCHBEANS_PAGE_ID];

export enum SubmitPublicationStatus {
    NOW = 'now',
    SCHEDULE = 'schedule',
    DRAFT = 'draft',
}
