import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeapEventName, PlatformKey } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { PostErrorNotification } from ':core/components/notification-center/models/post-error-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-post-error-notification-item',
    templateUrl: './post-error-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, DatePipe, MatButtonModule, IllustrationPathResolverPipe],
})
export class PostErrorNotificationItemComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);
    readonly notification = computed<PostErrorNotification>(() => this.initialNotification());
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.restaurantIds?.[0])
    );
    readonly Illustration = Illustration;

    onNotificationClick(): void {
        this._notificationsCenterContext.trackNotification({
            heapEventName: this.notification().data.post.isStory
                ? HeapEventName.NOTIFICATION_STORY_ERROR_TRACKING_WEB_REDIRECT
                : HeapEventName.NOTIFICATION_POST_ERROR_TRACKING_WEB_REDIRECT,
            notificationId: this.notification()?.id,
            properties: {
                restaurantId: this.notification().data.restaurantIds[0],
                notificationType: this.notification()?.type,
            },
        });
        this.markAsRead();
        this._navigateToPost();
        this.notificationCenterContext.close();
    }

    private _navigateToPost(): void {
        if (this.notification().data.post.platform?.key === PlatformKey.GMB) {
            this._router.navigate(['restaurants', this.notification().data.restaurantIds[0], 'seo', 'posts', 'list'], {
                queryParams: {
                    postId: this.notification().data.post.id,
                },
            });
        } else {
            this._router.navigate(
                [
                    'restaurants',
                    this.notification().data.restaurantIds[0],
                    'social',
                    this.notification().data.post.isStory ? 'stories' : 'socialposts',
                ],
                {
                    queryParams: {
                        postId: this.notification().data.post.id,
                    },
                }
            );
        }
    }
}
