import { NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    Injector,
    OnInit,
    runInInjectionContext,
    Signal,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, filter, forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { isNotNil, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import {
    AuthorizedSimpleAccountManagedPlatformKeys,
    SimpleAccountManagedConnectionModalResult,
} from ':modules/platforms/platforms-connection-modals/platforms-connection-simple-account-managed-modal/simple-account-managed-connection-modal.service';
import {
    ComputedValue,
    PlatformsConnectionBusinessSelectorComponent,
} from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-business-selector/platforms-connection-business-selector.component';
import { BaseStepComponent } from ':modules/platforms/platforms-connection-modals/shared/platforms-connection-parent-stepper/base-step.component';
import { SearchPlatformRestaurantsService } from ':modules/platforms/platforms-connection-modals/shared/services/search-platform-restaurants.service';
import { UpdateAccountManagementPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/update-account-managed-platform.service';
import { UpdateCredentialsBasedPlatformService } from ':modules/platforms/platforms-connection-modals/shared/services/update-credentials-based-platform.service';
import { selectCurrentPlatform } from ':modules/platforms/store/platforms.reducer';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ButtonStyle, ModalStructureComponent } from ':shared/components/modal-structure/modal-structure.component';
import { Platform, PlatformSearch } from ':shared/models';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { CreatePlatformAccessService } from './create-platform-access.service';

const URL_PLACEHOLDER: Record<AuthorizedSimpleAccountManagedPlatformKeys, string> = {
    [PlatformKey.UBEREATS]: 'https://merchants.ubereats.com/manager/...',
    [PlatformKey.OPENTABLE]: 'https://guestcenter.opentable.com/restaurant/...',
};

@Component({
    selector: 'app-simple-account-managed-connection-step-1',
    templateUrl: './simple-account-managed-connection-step-1.component.html',
    styleUrls: ['./simple-account-managed-connection-step-1.component.scss'],
    standalone: true,
    imports: [
        ModalStructureComponent,
        TranslateModule,
        MalouSpinnerComponent,
        ImagePathResolverPipe,
        InputTextComponent,
        ReactiveFormsModule,
        MatRadioModule,
        NgTemplateOutlet,
        IllustrationPathResolverPipe,
        PlatformsConnectionBusinessSelectorComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleAccountManagedConnectionStep1Component
    extends BaseStepComponent<{ platformKey: AuthorizedSimpleAccountManagedPlatformKeys }, SimpleAccountManagedConnectionModalResult>
    implements OnInit
{
    private readonly _searchPlatformRestaurantsService = inject(SearchPlatformRestaurantsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _updateCredentialsBasedPlatformService = inject(UpdateCredentialsBasedPlatformService);
    private readonly _updateAccountManagementPlatformService = inject(UpdateAccountManagementPlatformService);
    private readonly _createPlatformAccessService = inject(CreatePlatformAccessService);
    private readonly _translateService = inject(TranslateService);
    private readonly _toastService = inject(ToastService);
    private readonly _store = inject(Store);
    private readonly _injector = inject(Injector);

    readonly ButtonStyle = ButtonStyle;
    readonly restaurant = this._restaurantsService.currentRestaurant;

    readonly urlControl = new FormControl<string>('', Validators.required);
    platformSearchResults: Signal<PlatformSearch[]>;
    selectedPlatformSearch = signal<PlatformSearch | null>(null);
    isLoading = signal(false);
    readonly isUrlInError$ = this.urlControl.statusChanges.pipe(map(() => this.urlControl.errors?.email ?? false));
    readonly isUrlInError = toSignal<boolean, boolean>(this.isUrlInError$, {
        initialValue: false,
    });
    isFirstSearchDone = signal(false);

    currentPlatform: Signal<Platform | undefined>;
    socialId: string | undefined;

    readonly platformKey = computed(() => this.sharedData().platformKey);
    readonly urlPlaceholder = computed(() => URL_PLACEHOLDER[this.platformKey()]);
    readonly translations = computed(() => {
        const base = 'platforms.connection_new.simple_account_managed';
        const platformKey = this.platformKey();
        return {
            title: `${base}.${platformKey}.step_1.step_name`,
            description: `${base}.${platformKey}.step_1.description`,
            noResultsDescription: `${base}.${platformKey}.step_1.no_results_description`,
            connectionSuccess: `${base}.${platformKey}.step_1.connection_success`,
        };
    });

    readonly isUpdatingPlatform = signal<boolean>(false);

    ngOnInit(): void {
        runInInjectionContext(this._injector, () => {
            this._initCurrentPlatform();
            this._initPlatformSearch();
            this._initSelectedPlatformSearch();
            this._initCurrentUrl();
        });
    }

    platformSearchDisplayWith(value: PlatformSearch): ComputedValue {
        return {
            title: value.name,
            subtitle: value.formattedAddress,
            link: value.socialUrl,
        };
    }

    platformSearchCompareWith(value: PlatformSearch): string {
        return value.socialId;
    }

    onBusinessChange(value: PlatformSearch): void {
        this.selectedPlatformSearch.set(value);
    }

    onPrimaryClick(): void {
        const selectedPlatformSearch = this.selectedPlatformSearch();
        if (!selectedPlatformSearch) {
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
            return;
        }
        const platformKey = this.platformKey();
        const socialId = this.socialId;
        if (!socialId) {
            return;
        }
        this.isUpdatingPlatform.set(true);
        const platformServiceResult$: Observable<any> =
            platformKey === PlatformKey.OPENTABLE
                ? forkJoin([
                      this._updateAccountManagementPlatformService.execute(platformKey, socialId),
                      this._createPlatformAccessService.execute(platformKey),
                  ])
                : this._updateCredentialsBasedPlatformService.execute({
                      platformKey,
                      platformSearch: selectedPlatformSearch,
                      credentialId: undefined,
                  });
        platformServiceResult$.subscribe({
            next: () => {
                this.isUpdatingPlatform.set(false);
                this._toastService.openSuccessToast(this._translateService.instant(this.translations().connectionSuccess));
                this.close.emit({ hasDataChanged: true });
            },
            error: (err) => {
                console.error(err);
                this.isUpdatingPlatform.set(false);
                this._toastService.openErrorToast(this._translateService.instant('common.error'));
            },
        });
    }

    private _initCurrentPlatform(): void {
        const currentPlatform$ = this._store.select(selectCurrentPlatform({ platformKey: this.platformKey() })).pipe(filter(isNotNil));
        this.currentPlatform = toSignal(currentPlatform$);
    }

    private _initPlatformSearch(): void {
        const platformSearchResults$ = this.urlControl.valueChanges.pipe(
            filter(Boolean),
            debounceTime(500),
            tap(() => this.isLoading.set(true)),
            switchMap((url) => {
                const socialId = this._extractSocialIdFromUrl(this.platformKey(), url);
                this.socialId = socialId;
                return this._searchPlatformRestaurantsService.execute(this.platformKey(), undefined, socialId).pipe(
                    catchError((err) => {
                        console.error(err);
                        return of([]);
                    })
                );
            }),
            tap(() => {
                this.isLoading.set(false);
                this.isFirstSearchDone.set(true);
            })
        );

        this.platformSearchResults = toSignal(platformSearchResults$, { initialValue: [] });
    }

    private _initSelectedPlatformSearch(): void {
        effect(
            () => {
                const platformSearchResults = this.platformSearchResults();
                const currentSocialId = this.currentPlatform()?.socialId;
                if (currentSocialId && this.selectedPlatformSearch() === null) {
                    const foundPlatformSearch = platformSearchResults.find((platformSearch) => platformSearch.socialId === currentSocialId);
                    this.selectedPlatformSearch.set(foundPlatformSearch ?? null);
                }
            },
            { allowSignalWrites: true }
        );
    }

    private _initCurrentUrl(): void {
        effect(() => {
            const currentPlatform = this.currentPlatform();
            if (currentPlatform) {
                const url = this._getUrl(this.platformKey(), currentPlatform.socialId);
                if (url) {
                    this.urlControl.setValue(url);
                }
            }
        });
    }

    private _getUrl(platformKey: PlatformKey, socialId: string): string | null {
        switch (platformKey) {
            case PlatformKey.OPENTABLE:
                return `https://guestcenter.opentable.com/restaurant/${socialId}/home`;
            case PlatformKey.UBEREATS:
                return `https://merchants.ubereats.com/manager/home/${socialId}`;
        }
        return null;
    }

    private _extractSocialIdFromUrl(platformKey: PlatformKey, link: string): string | undefined {
        switch (platformKey) {
            case PlatformKey.OPENTABLE:
                return this._extractOpentableSocialIdFromUrl(link);
            case PlatformKey.UBEREATS:
                return this._extractUberEatsSocialIdFromUrl(link);
            default:
                return undefined;
        }
    }

    private _extractOpentableSocialIdFromUrl(link: string): string | undefined {
        const socialIdRegex = RegExp(/(?<=guestcenter.opentable.com\/restaurant\/)(\d|-|\w)*/);
        return socialIdRegex.exec(link)?.[0];
    }

    private _extractUberEatsSocialIdFromUrl(link: string): string | undefined {
        const socialIdRegex = RegExp(/(?<=merchants.ubereats.com\/manager\/home\/)(\d|-|\w)*/);
        return socialIdRegex.exec(link)?.[0];
    }
}
