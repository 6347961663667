import { z } from 'zod';

export const userSettingsValidator = z.object({
    receiveFeedbacks: z.boolean().optional(),
    notifications: z
        .object({
            email: z
                .object({
                    reviewReplyReminder: z.object({
                        active: z.boolean(),
                    }),
                    specialHourReminder: z.object({
                        active: z.boolean(),
                    }),
                    postSuggestion: z.object({
                        active: z.boolean(),
                    }),
                    roiActivated: z.object({
                        active: z.boolean(),
                    }),
                    postError: z.object({
                        active: z.boolean(),
                    }),
                    summary: z.object({
                        active: z.boolean(),
                    }),
                    platformDisconnected: z
                        .object({
                            active: z.boolean(),
                        })
                        .optional(),
                })
                .optional(),

            web: z
                .object({
                    showFloatingToast: z.boolean(),
                    reviewReplyReminder: z.object({
                        active: z.boolean(),
                    }),
                    specialHourReminder: z.object({
                        active: z.boolean(),
                    }),
                    newReviews: z.object({
                        active: z.boolean(),
                    }),
                    postSuggestion: z.object({
                        active: z.boolean(),
                    }),
                    newMessage: z.object({
                        active: z.boolean(),
                    }),
                    roiActivated: z.object({
                        active: z.boolean(),
                    }),
                    postError: z.object({
                        active: z.boolean(),
                    }),
                    filters: z.object({
                        restaurantIds: z.array(z.string()),
                    }),
                    platformDisconnected: z
                        .object({
                            active: z.boolean(),
                        })
                        .optional(),
                })
                .optional(),
            mobile: z
                .object({
                    userDevicesTokens: z.array(z.string()),
                    active: z.boolean(),
                    newMessage: z
                        .object({
                            active: z.boolean(),
                            realtime: z.boolean(),
                            receivingWeekDays: z.array(z.number()),
                        })
                        .optional(),
                    newReviews: z
                        .object({
                            active: z.boolean(),
                            realtime: z.boolean(),
                            receivingWeekDays: z.array(z.number()),
                            concernedRatings: z.array(z.number()),
                            includeAutoRepliedReviews: z.boolean(),
                        })
                        .optional(),
                    noMoreScheduledPosts: z
                        .object({
                            active: z.boolean(),
                        })
                        .optional(),
                })
                .optional(),
        })
        .optional(),
    receiveMessagesNotifications: z
        .object({
            active: z.boolean(),
            restaurantsIds: z.array(z.string()),
        })
        .optional(),
    notificationSettings: z
        .object({
            userDevicesTokens: z.array(z.string()),
            active: z.boolean(),
            reviews: z.object({
                active: z.boolean(),
                realtime: z.boolean(),
                receivingWeekDays: z.array(z.number()),
                concernedRatings: z.array(z.number()),
                includeAutoRepliedReviews: z.boolean(),
            }),
            messages: z.object({
                active: z.boolean(),
                realtime: z.boolean(),
                receivingWeekDays: z.array(z.number()),
            }),
            posts: z.object({
                noMoreScheduledPosts: z.object({
                    active: z.boolean(),
                }),
                publishError: z.object({
                    active: z.boolean(),
                }),
            }),
        })
        .optional(),
});
