import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { MenuButtonSize } from ':shared/components/menu-button-v2/menu-button-v2.interface';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss'],
    standalone: true,
    imports: [NgClass, MatIconModule, MatMenuModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownButtonComponent<T> {
    readonly id = input<string>('');
    readonly options = input<T[]>([]);
    readonly selectedOption = model.required<T | null>();
    readonly displayOption = input.required<(option: T) => string>();
    readonly loading = input<boolean>(false);
    readonly disabled = input<boolean>(false);
    readonly buttonClasses = input<string>('');
    readonly tooltip = input<string>('');
    readonly testId = input<string>('');
    readonly size = input<MenuButtonSize>(MenuButtonSize.MEDIUM);

    readonly buttonClick = output<T | null>();

    readonly customButtonClasses = computed(
        () => `!shadow-none !py-0 !rounded-[5px] ${this.size() === MenuButtonSize.LARGE ? 'min-h-[50px]' : ''} ${this.buttonClasses()}`
    );

    readonly selectedOptionText = computed(() => {
        const option = this.selectedOption();
        return option !== null ? this.displayOption()(option) : '';
    });

    readonly MenuButtonSize = MenuButtonSize;
    readonly SvgIcon = SvgIcon;

    onMenuItemButtonClick(option: T): void {
        this.selectedOption.set(option);
    }

    onButtonClick(): void {
        this.buttonClick.emit(this.selectedOption());
    }
}
