<div class="flex flex-col rounded-[10px] bg-malou-color-background-dark">
    <div class="flex items-center justify-between">
        <span class="malou-text-13--bold m-4 text-malou-color-text-1">{{ 'posts.duplicate_post_modal.original_post' | translate }}</span>
        @if (postSource() === PostSource.SOCIAL) {
            <div class="mr-4 flex gap-1">
                @for (platformKey of post().keys; track platformKey; let index = $index; let first = $first) {
                    <app-platform-logo imgClasses="h-5 w-5 max-w-fit" [logo]="platformKey"></app-platform-logo>
                }
            </div>
        }
    </div>

    <div class="flex gap-4 border-y-[1px] border-white px-4 pb-5 pt-4">
        @if (postSource() === PostSource.SEO) {
            <img
                class="h-20 w-20 rounded-lg object-cover"
                alt="Image du post"
                [defaultImage]="'default-picture-grey' | imagePathResolver"
                [lazyLoad]="(post() | applySelfPure: 'getSocialMediaUrl') || (post() | applySelfPure: 'getMalouMediaUrl')" />
            <div class="flex w-[calc(100%-5.25rem)] flex-col">
                <span class="malou-text-10--regular italic text-malou-color-text-2" [innerHTML]="originalPostCaptionHighlighted()"></span>
                @if (hasCallToAction()) {
                    <div class="mt-2 flex">
                        <span class="malou-text-10--semibold pr-1">{{ 'common.button' | translate }} </span>
                        <span class="malou-text-10 pr-1"
                            >"{{ post().callToAction.actionType | enumTranslate: 'call_to_action_type' }}"</span
                        >
                        @if (post().callToAction.url) {
                            <span class="malou-text-10 max-w-[70%] truncate pr-1 italic">{{ '(' }} {{ post().callToAction.url }}</span>
                            <span class="malou-text-10 italic">{{ ')' }}</span>
                        }
                    </div>
                }
            </div>
        } @else {
            <div class="flex w-full flex-col gap-3">
                <app-media-thumbnail-list class="w-full" [medias]="medias()" [noEditAndDragAndDrop]="true"></app-media-thumbnail-list>
                <span class="malou-text-10--regular italic text-malou-color-text-2"> {{ this.post().text }}</span>
            </div>
        }
    </div>

    @if (postSource() === PostSource.SEO) {
        <app-keywords-score-gauge
            class="px-5 py-5"
            [text$]="originalPostCaption$!"
            [restaurant$]="restaurant$!"
            [textType$]="textType$!"
            [keywords$]="keywords$()!"
            [lang$]="postLang$"
            [shouldDisplayKeywords]="true"
            [shouldDisplayKeywordsInTooltip]="true"
            [shouldOnlyDisplayScore]="false"
            [withLargeDetails]="false"
            [shouldDisplayTips]="true"
            [reverseTipsAndKeywords]="true"
            #keywordsScoreGauge></app-keywords-score-gauge>
    }
</div>
