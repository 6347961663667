import { Injectable } from '@angular/core';
import { Ability } from '@casl/ability';

import { AppCaslRule } from '@malou-io/package-utils';

@Injectable({
    providedIn: 'root',
})
export class AbilitiesContext {
    readonly userAbilities = new Ability();
    readonly userRestaurantAbilities = new Ability();

    updateUserAbilities(rules: AppCaslRule[]): void {
        this.userAbilities.update(rules);
    }

    updateUserRestaurantAbilities(rules: AppCaslRule[]): void {
        this.userRestaurantAbilities.update(rules);
    }
}
