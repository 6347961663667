import { ChangeDetectionStrategy, Component, computed, inject, Input, Signal, WritableSignal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, Tick, TooltipItem } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey, WHEEL_OF_FORTUNE_PLATFORM_KEY } from '@malou-io/package-utils';

import {
    ChartDataArray,
    malouChartColorBluePurple,
    malouChartColorLighterPink,
    malouChartColorLightPink,
    malouChartColorLightPurple,
    malouChartColorPink,
    malouChartColorPinkBarbie,
    malouChartColorPinkMedium,
    malouChartColorPurple,
    malouChartColorPurpleDark,
    malouChartColorPurpleMedium,
    malouChartColorPurplePink,
} from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { Restaurant } from ':shared/models';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

const RESTAURANT_NAME_MAX_LENGTH = 20;

type BarChartType = Extract<ChartType, 'bar'>;

export type AggregatedScansData = Partial<Record<PlatformKey | typeof WHEEL_OF_FORTUNE_PLATFORM_KEY | 'total', number>>;
export interface AggregatedBoosterData {
    name: string;
    scans: (AggregatedScansData | null)[];
}
export type AggregatedTotemsData = AggregatedBoosterData[];

@Component({
    selector: 'app-aggregated-boosters-scan-count-chart',
    templateUrl: './aggregated-boosters-scan-count-chart.component.html',
    styleUrls: ['./aggregated-boosters-scan-count-chart.component.scss'],
    standalone: true,
    imports: [NgChartsModule, TranslateModule],
    providers: [ShortTextPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatedBoostersScanCountChartComponent {
    @Input() boostersData: WritableSignal<AggregatedTotemsData>;
    @Input() restaurants: WritableSignal<Restaurant[]>;

    private readonly _translateService = inject(TranslateService);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);
    private readonly _shortTextPipe = inject(ShortTextPipe);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly wheelOfFortuneDisplayedText = this._translateService.instant('aggregated_statistics.boosters.scans.wheel_of_fortune');
    readonly PLATFORM_KEYS_TRAD = {
        ...this._translateService.instant('enums.platform_key'),
        [WHEEL_OF_FORTUNE_PLATFORM_KEY]: this.wheelOfFortuneDisplayedText,
    };

    readonly chartDataSets: Signal<(ChartDataset<BarChartType, ChartDataArray> & { metadata: AggregatedBoosterData })[]> = computed(() =>
        this._computeChartData(this.boostersData())
    );
    readonly chartLabels: Signal<string[]> = computed(() => this._computeChartLabels(this.restaurants()));
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions(this.chartLabels()));

    private _computeChartData(
        boostersData: AggregatedTotemsData
    ): (ChartDataset<BarChartType, ChartDataArray> & { metadata: AggregatedBoosterData })[] {
        return boostersData.map((totemData, index) => ({
            label: totemData.name,
            borderColor: this._getChartColor(index, totemData.name),
            backgroundColor: this._getChartColor(index, totemData.name),
            xAxisID: 'xAxis',
            yAxisID: 'yAxis',
            barThickness: 7,
            data: totemData.scans.map((scan) => scan?.total ?? null),
            metadata: totemData,
        }));
    }

    private _getChartColor(index: number, key: string): string {
        const colors = [
            malouChartColorPink,
            malouChartColorBluePurple,
            malouChartColorLightPink,
            malouChartColorLightPurple,
            malouChartColorLighterPink,
            malouChartColorPurpleDark,
            malouChartColorPurpleMedium,
            malouChartColorPurplePink,
            malouChartColorPinkBarbie,
            malouChartColorPinkMedium,
        ];
        return key === this.wheelOfFortuneDisplayedText ? malouChartColorPurple : colors[index % colors.length];
    }

    private _computeChartLabels(restaurants: Restaurant[]): string[] {
        return restaurants.map((e) => e.internalName ?? e.name);
    }

    private _computeChartOptions(labels: string[]): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    filter: (tooltipItem: TooltipItem<any>): boolean => tooltipItem.formattedValue !== '0',
                    itemSort: (a: TooltipItem<any>, b: TooltipItem<any>): number => b.datasetIndex - a.datasetIndex,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<any>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                xAxis: {
                    stacked: true,
                    axis: 'x',
                    type: 'category',
                    ticks: {
                        callback: (_, _index: number, _ticks: Tick[]): string => {
                            const label = labels[_index];
                            return this._shortTextPipe.transform(label, RESTAURANT_NAME_MAX_LENGTH);
                        },
                    },
                },
                yAxis: {
                    stacked: true,
                    axis: 'y',
                    type: 'linear',
                    offset: false,
                    ticks: {
                        stepSize: 1,
                    },
                },
            },
        };
    }

    private _computeTooltipLabel(item: TooltipItem<any>): string {
        const defaultName = `${SMALL_TOOLTIP_TAB}${item.dataset.label}`;
        const totem: AggregatedBoosterData = item.dataset.metadata;
        const currentRestaurantIndex = this.chartLabels().findIndex((label) => label === item.label);
        const currentRestaurantScans = totem.scans[currentRestaurantIndex];
        if (!currentRestaurantScans) {
            return defaultName;
        }
        const nameWithScanCount = `${SMALL_TOOLTIP_TAB}${item.dataset.label} (${
            currentRestaurantScans.total
        } ${this._pluralTranslatePipe.transform('aggregated_statistics.boosters.scans.scans', currentRestaurantScans.total ?? 0)})`;
        if (this._isWheelOfFortuneTotem(totem)) {
            return currentRestaurantScans.total ? nameWithScanCount : defaultName;
        }
        const hasMultipleRedirectionPlatforms =
            Object.keys(currentRestaurantScans).filter((key) =>
                [...Object.values(PlatformKey), WHEEL_OF_FORTUNE_PLATFORM_KEY].includes(key)
            ).length > 1;
        return hasMultipleRedirectionPlatforms && currentRestaurantScans.total ? nameWithScanCount : defaultName;
    }

    private _computeTooltipAfterLabel(item: TooltipItem<any>): string[] {
        const totem: AggregatedBoosterData = item.dataset.metadata;
        if (this._isWheelOfFortuneTotem(totem)) {
            return [];
        }
        const currentRestaurantIndex = this.chartLabels().findIndex((label) => label === item.label);
        const currentRestaurantScans = totem.scans[currentRestaurantIndex];
        if (!currentRestaurantScans) {
            return [];
        }
        return Object.entries(currentRestaurantScans)
            .filter(([key, _count]) => [...Object.values(PlatformKey), WHEEL_OF_FORTUNE_PLATFORM_KEY].includes(key))
            .map(
                ([platformKey, count]: [PlatformKey, number]) =>
                    `${LARGE_TOOLTIP_TAB}${this.PLATFORM_KEYS_TRAD[platformKey]} (${count} ${this._pluralTranslatePipe.transform(
                        'aggregated_statistics.boosters.scans.scans',
                        count
                    )})`
            );
    }

    private _isWheelOfFortuneTotem(totem: AggregatedBoosterData): boolean {
        return totem.name === this.wheelOfFortuneDisplayedText;
    }
}
