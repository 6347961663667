// doc: https://betterprogramming.pub/sync-your-state-in-local-storage-with-ngrx-9d6ceba93fc0
import { Action, ActionReducer } from '@ngrx/store';

import { initialState as initialAggregatedStatisticsState } from ':modules/aggregated-statistics/store/aggregated-statistics.reducer';
import { initialState as initialReviewsState } from ':modules/reviews/store/reviews.reducer';
import { initialState as initialUserState } from ':modules/user/store/user.reducer';

type AppState = Record<string, any>;

export const INITIAL_STORE_STATE = {
    user: initialUserState,
    aggregatedStatistics: initialAggregatedStatisticsState,
    reviews: initialReviewsState,
};

export const LOGOUT_ACTION = 'LOGOUT_ACTION';

export function logout<S extends AppState, A extends Action = Action>(reducer: ActionReducer<S, A>): any {
    return function (state, action) {
        return reducer(action.type === LOGOUT_ACTION ? { ...state, ...INITIAL_STORE_STATE } : state, action);
    };
}
