<div>
    @if (!isLoading()) {
        @if (areAllPlatformsInError()) {
            <ng-container
                [ngTemplateOutlet]="errorTemplate"
                [ngTemplateOutletContext]="{
                    error: 'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip() },
                }"></ng-container>
        } @else {
            @if (!httpError()) {
                @if (!insightsError()) {
                    <div class="malou-simple-card flex flex-col gap-3 px-6 py-3 md:px-2">
                        <div>
                            <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
                        </div>
                        <div>
                            <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
                        </div>
                        <div>
                            <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
                        </div>
                    </div>
                } @else {
                    <ng-container
                        [ngTemplateOutlet]="errorTemplate"
                        [ngTemplateOutletContext]="{
                            error: insightsError(),
                        }"></ng-container>
                }
            } @else {
                <ng-container
                    [ngTemplateOutlet]="errorTemplate"
                    [ngTemplateOutletContext]="{
                        error: httpError() | statisticsHttpError,
                    }"></ng-container>
            }
        }
    } @else {
        <app-skeleton skeletonClass="!h-[542px] secondary-bg"></app-skeleton>
    }
</div>

<ng-template #filterTemplate>
    <div class="flex flex-wrap items-center justify-between gap-2">
        <div class="flex items-center gap-2">
            <span class="malou-text-section-title malou-color-text-1">
                {{ 'statistics.social_networks.community.community' | translate }}
            </span>
            @if (platformsErrorTooltip()) {
                <span
                    class="malou-status--waiting pdf-hidden"
                    [matTooltip]="'statistics.errors.platforms_error' | translate: { platforms: platformsErrorTooltip() }">
                    <mat-icon [svgIcon]="SvgIcon.EXCLAMATION_MARK"></mat-icon>
                </span>
            }
        </div>
        @if (!showViewByTextInsteadOfSelector()) {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--medium md:hidden">{{ 'statistics.social_networks.community.view_by' | translate }}</span>
                <app-select
                    [values]="VIEW_BY_FILTER_VALUES"
                    [displayWith]="viewByDisplayWith"
                    [formControl]="viewByControl"
                    (selectChange)="viewByFilterSubject$.next($event)">
                </app-select>
            </div>
        } @else {
            <div class="flex items-center gap-2">
                <span class="malou-text-14--regular italic">
                    {{ 'common.view_by' | translate }}
                    {{ viewByDisplayWith | applyPure: (viewByFilterSubject$ | async) ?? ViewBy.DAY | lowercase }}
                </span>
            </div>
        }
    </div>
</ng-template>

<ng-template #chartTemplate>
    <app-community-chart-v2
        [hiddenDatasetIndexes]="hiddenDatasetIndexes()"
        [communityData]="communityData()"
        [labels]="dateLabels()"
        [previousPeriodDates]="previousPeriodDates()"
        [viewBy]="(viewByFilterSubject$ | async) ?? ViewBy.DAY"
        (hiddenDatasetIndexesChange)="hiddenDatasetIndexesChange.emit($event)"></app-community-chart-v2>
</ng-template>

<ng-template #infoTemplate>
    <app-community-main-infos-v2 [communityData]="communityData()"></app-community-main-infos-v2>
</ng-template>

<ng-template let-error="error" #errorTemplate>
    <div class="flex !h-[542px] flex-col items-center justify-center py-6">
        <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="Illustration.Taster | illustrationPathResolver" />
        <span class="malou-text-14--bold mb-2">{{ 'statistics.errors.server_is_not_responding' | translate }}</span>
        <span class="malou-text-10--regular">{{ error }}</span>
    </div>
</ng-template>
