import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import {
    AiPostGenerationEmojiStatus,
    AiPostSettingsLength,
    AiPostSettingsTone,
    FrenchTutoiementVouvoiement,
    PostSource,
} from '@malou-io/package-utils';

import { AiService } from ':core/services/ai.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { AiSettingsContext } from ':modules/ai-settings/ai-settings.context';
import { ButtonComponent } from ':shared/components/button/button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

import { AiPostSettingsProposalComponent } from './ai-post-settings-proposal/ai-post-settings-proposal.component';

type AiPostSettingsForm = FormGroup<{
    denomination: FormControl<FrenchTutoiementVouvoiement>;
    tone: FormControl<AiPostSettingsTone[]>;
    length: FormControl<AiPostSettingsLength>;
    emoji: FormControl<AiPostGenerationEmojiStatus>;
    prompt: FormControl<string | null>;
}>;

@Component({
    selector: 'app-ai-post-settings-preview',
    standalone: true,
    imports: [
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        AiPostSettingsProposalComponent,
        ButtonComponent,
        CdkTextareaAutosize,
        ReactiveFormsModule,
        AsyncPipe,
    ],
    templateUrl: './ai-post-settings-preview.component.html',
    styleUrl: './ai-post-settings-preview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPostSettingsPreviewComponent implements OnInit {
    readonly postSource = input.required<PostSource>();
    readonly aiSeoPostSettingsForm = input.required<AiPostSettingsForm>();
    readonly aiSocialPostSettingsForm = input.required<AiPostSettingsForm>();
    readonly close = output<void>();

    readonly SvgIcon = SvgIcon;
    readonly aiSettingsContext = inject(AiSettingsContext);
    readonly _toastService = inject(ToastService);

    readonly postTextPreview = signal<string>('');
    readonly isLoading = signal<boolean>(false);

    readonly generateLegendControl = new FormControl('', { validators: [Validators.required] });
    readonly isControlValid$: Observable<boolean> = this.generateLegendControl.valueChanges.pipe(
        map(() => this.generateLegendControl.valid)
    );

    readonly _aiService = inject(AiService);
    readonly _restaurantsService = inject(RestaurantsService);
    readonly _translateService = inject(TranslateService);

    ngOnInit(): void {
        this.generateLegendControl.patchValue(this._translateService.instant('restaurant_ai_settings.modals.upsert.default_legend'));
    }

    async generatePreview(): Promise<void> {
        this.isLoading.set(true);
        const formData = this.postSource() === PostSource.SEO ? this.aiSeoPostSettingsForm().value : this.aiSocialPostSettingsForm().value;
        try {
            const preview = await this._aiService.generatePostTextPreview({
                description: this.generateLegendControl.value ?? '',
                lang: this.aiSettingsContext.restaurantAiSettings()!.defaultLanguageResponse.toString(),
                restaurantId: this._restaurantsService.restaurantSelected$.value!.id,
                postSource: this.postSource(),
                postSettings: {
                    denomination: formData.denomination!,
                    emoji: formData.emoji!,
                    length: formData.length!,
                    prompt: formData.prompt,
                    tone: formData.tone!,
                },
            });
            this.postTextPreview.set(preview?.[0] ?? '');
        } catch (error) {
            console.error(error);
            this.postTextPreview.set('');
            this._toastService.openErrorToast(this._translateService.instant('common.error'));
        }

        this.isLoading.set(false);
    }
}
