<div class="h-full overflow-y-auto py-4">
    <ng-container [ngTemplateOutlet]="malouPlatformsTemplate"></ng-container>
    @if (isYextEnabled && filteredConnectedPublishers().length) {
        <ng-container [ngTemplateOutlet]="yextPlatformsTemplate"></ng-container>
    }
</div>

<ng-template #malouPlatformsTemplate>
    @if (mappedPlatforms$ | async; as platformCategoryOrGroup) {
        <div class="flex flex-col gap-6 px-7.5">
            <app-platforms-filters
                [platforms]="platformCategoryOrGroup"
                (searchChange)="onSearchChange($event)"
                (reconnectFilterChange)="onReconnectFilterChange($event)"></app-platforms-filters>
            @for (categoryOrGroup of sortedPlatforms; track $index) {
                <div class="flex flex-col">
                    <ng-container
                        [ngTemplateOutlet]="
                            categoryOrGroup === PlatformGroup.MUST_HAVE ? mustHavePlatformCategoryTemplate : platformsCategoryTemplateV2
                        "
                        [ngTemplateOutletContext]="{ categoryOrGroup, platformCategoryOrGroup }">
                    </ng-container>
                </div>
            }
        </div>
    }
</ng-template>

<ng-template let-categoryOrGroup="categoryOrGroup" let-platformCategoryOrGroup="platformCategoryOrGroup" #mustHavePlatformCategoryTemplate>
    @if (platformCategoryOrGroup[categoryOrGroup]?.length > 0) {
        <div class="rounded-[10px] border border-malou-color-border-primary bg-white p-6">
            <span class="malou-text-16--bold malou-color-text-1">
                {{ categoryOrGroup | enumTranslate: 'platforms.connection' : 'main_text' }}
            </span>
            <div class="must-have-platforms-grid mt-4 grid gap-4">
                @for (platform of platformCategoryOrGroup[categoryOrGroup]; track trackByKeyFn($index, platform)) {
                    <div class="flex">
                        <ng-container [ngTemplateOutlet]="platformCardV2" [ngTemplateOutletContext]="{ platform: platform }"></ng-container>
                    </div>
                }
            </div>
        </div>
    }
</ng-template>

<ng-template let-categoryOrGroup="categoryOrGroup" let-platformCategoryOrGroup="platformCategoryOrGroup" #platformsCategoryTemplateV2>
    <div class="py-6">
        <span class="malou-text-16--bold malou-color-text-1">
            {{ categoryOrGroup | enumTranslate: 'platforms.connection' : 'main_text' }}
            <div class="malou-text-12--regular malou-color-text-2 italic">
                {{ categoryOrGroup | enumTranslate: 'platforms.connection' : 'sub_text' }}
            </div>
        </span>
    </div>
    @if (platformCategoryOrGroup[categoryOrGroup]; as mappedPlatforms) {
        <div class="platforms-grid-2 grid gap-4">
            @for (platform of mappedPlatforms; track trackByKeyFn($index, platform)) {
                <div class="flex">
                    <ng-container [ngTemplateOutlet]="platformCardV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
        </div>
    }
</ng-template>

<ng-template let-platform="platform" #platformCardV2>
    <div
        class="malou-border-color-dark flex w-full flex-col overflow-hidden rounded-[10px] border"
        [attr.data-testid]="'card-' + platform.key">
        <div class="malou-background-light flex justify-between px-3 py-2.5">
            <div class="flex items-center">
                <app-platform-logo imgClasses="h-7 w-7" [logo]="platform.key" [withLazyLoading]="true"></app-platform-logo>
                <span class="malou-text-12--regular malou-color-text-2 ml-2.5">{{ platform.fullName }}</span>
            </div>

            <ng-container
                [ngTemplateOutlet]="platform.state === PlatformState.CONNECTED ? connectedBtnActionV2 : notConnectedBtnActionV2"
                [ngTemplateOutletContext]="{ platform: platform }"></ng-container>
        </div>

        <div class="flex flex-col">
            <div class="flex flex-col justify-between">
                <ng-container
                    [ngTemplateOutlet]="
                        ([PlatformState.CONNECTED, PlatformState.FOUND] | includes: platform.state)
                            ? connectedPlatformV2
                            : notConnectedPlatformV2
                    "
                    [ngTemplateOutletContext]="{ platform: platform }"></ng-container>
            </div>

            <ng-template let-platform="platform" #connectedPlatformV2>
                <mat-divider class="malou-border-color-dark"></mat-divider>
                <div class="flex !h-12 !rounded-t-none bg-white p-3">
                    <ng-container
                        [ngTemplateOutlet]="platform.key === PlatformKey.MAPSTR ? mapstrPlatformStatusV2 : platformStatusV2"
                        [ngTemplateOutletContext]="{ platform }">
                    </ng-container>
                </div>
            </ng-template>

            <ng-template let-platform="platform" #notConnectedPlatformV2>
                <mat-divider class="malou-border-color-dark"></mat-divider>
                <div class="flex">
                    <button
                        class="malou-btn-flat malou-text-10--semibold !h-12 grow !rounded-t-none !bg-white"
                        mat-button
                        [attr.data-testid]="'connect-btn-' + platform.key"
                        (click)="
                            platform.state === PlatformState.FOUND
                                ? openConnectPlatform({ platformKey: platform.key, step: 1 })
                                : openConnectPlatform({ platformKey: platform.key, step: 0 })
                        ">
                        {{ 'platforms.connection.connect' | translate }} {{ platform.fullName }}
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>

<ng-template let-platform="platform" #platformStatusV2>
    <div>
        @switch (platform.accessStatus) {
            @case (PlatformAccessStatus.VERIFIED) {
                <div class="flex items-center" [attr.data-testid]="'verified-' + platform.key">
                    <ng-container [ngTemplateOutlet]="verifiedTemplateV2"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.NEED_REVIEW) {
                <div class="flex items-center" [attr.data-testid]="'need-review-' + platform.key">
                    <ng-container [ngTemplateOutlet]="needReviewTemplateV2"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.MISSING_PERMISSIONS) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="missingPermissionsTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.BAD_ACCESS) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="badAccessTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.UNCLAIMED_PAGE) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="unclaimedPageTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.NOT_FOUND) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="notFoundTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.INVALID_PAGE) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="invalidPageTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.UNVERIFIED) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="unverifiedTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @case (PlatformAccessStatus.FAILED) {
                <div class="flex items-center">
                    <ng-container [ngTemplateOutlet]="failedTemplateV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
                </div>
            }
            @default {
                <div class="flex items-center">
                    <app-malou-spinner size="small"></app-malou-spinner>
                </div>
            }
        }
    </div>
</ng-template>

<ng-template let-platform="platform" #mapstrPlatformStatusV2>
    <div class="flex items-center">
        @if (platform.platformData?.credentials?.length > 0) {
            <ng-container [ngTemplateOutlet]="premiumMapstrPlatformStatusV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
        } @else {
            <ng-container [ngTemplateOutlet]="basicMapstrPlatformStatusV2" [ngTemplateOutletContext]="{ platform }"></ng-container>
        }
    </div>
</ng-template>

<ng-template let-platform="platform" #basicMapstrPlatformStatusV2>
    <mat-icon
        class="!h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
        [svgIcon]="SvgIcon.CHECK"></mat-icon>
    <span class="malou-text-10--medium malou-color-text-1 ml-2.5 cursor-auto">
        {{ 'platforms.connection.mapstr_basic_connected' | translate }}
    </span>
    <button
        class="malou-btn-flat !malou-text-10--semibold grow !rounded-t-none !bg-white"
        mat-button
        (click)="
            platform.state === PlatformState.FOUND
                ? openConnectPlatform({ platformKey: platform.key, step: 1 })
                : openConnectPlatform({ platformKey: platform.key, step: 0 })
        ">
        {{ 'platforms.connection.connect_mapstr_premium' | translate }}
    </button>
</ng-template>

<ng-template let-platform="platform" #premiumMapstrPlatformStatusV2>
    <mat-icon
        class="!h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
        [svgIcon]="SvgIcon.CHECK"></mat-icon>
    <span class="malou-text-10--medium malou-color-text-1 ml-2.5 cursor-auto">
        {{ 'platforms.connection.mapstr_premium_connected' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #connectedBtnActionV2>
    <div class="flex items-center">
        <mat-icon
            class="!h-4 !w-4 cursor-pointer"
            color="primary"
            [svgIcon]="SvgIcon.ELLIPSIS"
            [matMenuTriggerFor]="actionsMenu"
            (click)="$event.stopPropagation()"
            (pointerdown)="$event.stopPropagation()"></mat-icon>

        <mat-menu class="malou-mat-menu custom-mat-menu malou-box-shadow !rounded-[10px]" #actionsMenu="matMenu">
            <button class="malou-menu-item !min-h-[40px]" mat-menu-item (click)="openInformationModal(platform.key, platform.state)">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.INFO"></mat-icon>
                <span class="malou-color-text-2 malou-text-14--regular">
                    {{ 'platforms.connection.informations' | translate }}
                </span>
            </button>
            @if (hasSocialLink | applyPure: platform.key) {
                <button class="malou-menu-item !min-h-[40px]" mat-menu-item (click)="openPlatformInNewPage(platform.key)">
                    <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EYE"></mat-icon>
                    <span class="malou-color-text-2 malou-text-14--regular">
                        {{ 'platforms.connection.access_to_listing' | translate }}
                    </span>
                </button>
            }
            <button
                class="malou-menu-item !min-h-[40px]"
                mat-menu-item
                (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                <span class="malou-color-text-2 malou-text-14--regular">{{ 'platforms.connection.modify_params' | translate }}</span>
            </button>
            @if (showDeletePlatformButton | applyPure: platform) {
                <button class="malou-menu-item !min-h-[40px]" mat-menu-item (click)="onDeletePlatform(platform)">
                    <mat-icon class="!h-4 !w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    <span class="malou-color-text-2 malou-text-14--regular">
                        {{ getDeletePlatformTranslationKey | applyPure: platform | translate }}
                    </span>
                </button>
            }
        </mat-menu>
    </div>
</ng-template>

<ng-template let-platform="platform" #notConnectedBtnActionV2>
    <div class="flex items-center">
        <mat-icon
            class="!h-4 !w-4 cursor-pointer"
            color="primary"
            [svgIcon]="SvgIcon.ELLIPSIS"
            [matMenuTriggerFor]="actionsMenu"
            (click)="$event.stopPropagation()"
            (pointerdown)="$event.stopPropagation()"></mat-icon>

        <mat-menu class="malou-mat-menu custom-mat-menu malou-box-shadow" #actionsMenu="matMenu">
            <button class="malou-menu-item !min-h-[40px]" mat-menu-item (click)="openHelpModal(platform.key)">
                <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.INFO"></mat-icon>
                <span class="malou-color-text-2 malou-text-14--regular">
                    {{ 'platforms.connection.presentation' | translate: { platform: platform.fullName } }}
                </span>
            </button>
            @if (platform.state === PlatformState.FOUND) {
                <button class="malou-menu-item !min-h-[40px]" mat-menu-item (click)="onDeletePlatform(platform)">
                    <mat-icon class="!h-4 !w-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                    <span class="malou-color-text-2 malou-text-14--regular">
                        {{ 'platforms.connection.delete_connection' | translate }} {{ platform.fullName }}
                    </span>
                </button>
            }
        </mat-menu>
    </div>
</ng-template>

<ng-template #verifiedTemplateV2>
    <mat-icon
        class="!h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
        [svgIcon]="SvgIcon.CHECK"></mat-icon>
    <span class="malou-text-10--regular malou-color-text-1 ml-2.5 cursor-auto">
        {{ 'platforms.connection.connected_platform' | translate }}
    </span>
</ng-template>

<ng-template #needReviewTemplateV2>
    <mat-icon
        class="!h-6 !w-6 rounded bg-malou-color-state-warn bg-opacity-20 p-1 text-malou-color-state-warn"
        [svgIcon]="SvgIcon.CLOCK"></mat-icon>
    <span class="malou-text-10--medium malou-color-text-2 ml-2.5 cursor-auto">
        {{ 'platforms.connection.malou_validation_in_progress' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #missingPermissionsTemplateV2>
    <div
        class="mr-2 flex items-center"
        [matTooltip]="getMissingPermissionsTooltip | applyPure: (platformsPermissions$ | async) : platform.key : platform.fullName">
        <span
            class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
            {{ getMissingPermissionsButtonText | applyPure: (platformsPermissions$ | async) : platform.key }}
        </span>
    </div>
    <span
        class="malou-text-10--semibold malou-color-primary cursor-pointer"
        (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
        {{ 'platforms.connection.connect' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #badAccessTemplateV2>
    <div class="mr-2 flex !h-6 items-center bg-white">
        @if ([PlatformKey.PAGESJAUNES, PlatformKey.DELIVEROO, PlatformKey.LAFOURCHETTE] | includes: platform.key) {
            <span
                class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
                {{ 'platforms.connection.bad_access_credentials' | translate }}
            </span>
        } @else if ([PlatformKey.TRIPADVISOR, PlatformKey.YELP, PlatformKey.FOURSQUARE, PlatformKey.RESY] | includes: platform.key) {
            <span
                class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
                {{ 'platforms.connection.bad_access_admin' | translate }}
            </span>
        } @else {
            <span
                class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
                {{ 'platforms.connection.bad_access' | translate }}
            </span>
        }
    </div>
    <span
        class="malou-text-10--semibold malou-color-primary cursor-pointer"
        (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
        {{ 'platforms.connection.retry' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #unclaimedPageTemplateV2>
    <div
        class="mr-2 flex !h-6 items-center bg-white"
        matTooltip="{{ 'platforms.connection.unclaimed_page_reconnect_platform' | translate: { platform: platform.fullName } }}">
        <span
            class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
            {{ 'platforms.connection.unclaimed_page' | translate }}
        </span>
    </div>
    <span
        class="malou-text-10--semibold malou-color-primary ml-1 cursor-pointer"
        (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
        {{ 'platforms.connection.claim_my_page' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #notFoundTemplateV2>
    <span
        class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
        {{ 'platforms.connection.not_found_page' | translate }}
    </span>
    <span
        class="malou-text-10--semibold malou-color-primary ml-1 cursor-pointer"
        (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
        {{ 'platforms.connection.connect' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #invalidPageTemplateV2>
    <span
        class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
        {{ 'platforms.connection.invalid_page_v2' | translate: { platform: platform.fullName } }}
    </span>
</ng-template>

<ng-template let-platform="platform" #unverifiedTemplateV2>
    <span
        class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
        {{ 'platforms.connection.disconnected_platform' | translate }}
    </span>
    <span
        class="malou-text-10--semibold malou-color-primary ml-3 cursor-pointer"
        (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
        {{ 'platforms.connection.reconnect' | translate }}
    </span>
</ng-template>

<ng-template let-platform="platform" #failedTemplateV2>
    <span
        class="malou-text-10--medium malou-color-state-error ml-2.5 cursor-auto rounded-md bg-malou-color-state-error bg-opacity-10 px-1.5 py-0.5">
        {{ 'platforms.connection.failed' | translate }}
    </span>
    <span
        class="malou-text-10--semibold malou-color-primary ml-3 cursor-pointer"
        (click)="openConnectPlatform({ platformKey: platform.key, step: 1 })">
        {{ 'platforms.connection.retry' | translate }}
    </span>
</ng-template>

<ng-template #yextPlatformsTemplate>
    <div class="px-7.5 pb-6">
        <div class="malou-text-18--bold malou-color-text-1 py-6">
            {{ 'platforms.connection.automatically_connected_platforms' | translate }}
        </div>
        <div class="malou-border-color-dark flex items-center justify-between rounded-[10px] border bg-white px-5 py-4">
            <div class="flex items-center gap-x-4">
                @for (
                    connectedPublisher of connectedPublishers() | slice: { end: MAX_DISPLAYED_AUTOMATICALLY_PLATFORMS };
                    track trackByIdFn($index, connectedPublisher)
                ) {
                    <div>
                        <div
                            class="flex h-7 w-7 cursor-pointer items-center"
                            [ngClass]="{
                                'rounded-md':
                                    yextPublishers[connectedPublisher.id].doesLogoNeedBorders ||
                                    yextPublishers[connectedPublisher.id].logoBackgroundColor,
                                'malou-border-color-dark border': yextPublishers[connectedPublisher.id].doesLogoNeedBorders,
                            }"
                            [ngStyle]="{ background: yextPublishers[connectedPublisher.id].logoBackgroundColor }"
                            (click)="openAllAutomaticallyConnectedPlatformsModal(connectedPublishers())">
                            <app-yext-platform-logo
                                imgClasses="max-h-7 w-7 object-contain"
                                [matTooltip]="yextPublishers[connectedPublisher.id].name"
                                [yextPublisherId]="connectedPublisher.id"></app-yext-platform-logo>
                        </div>
                    </div>
                }
                <span
                    class="malou-text-13--semibold malou-color-primary cursor-pointer"
                    (click)="openAllAutomaticallyConnectedPlatformsModal(connectedPublishers())">
                    {{
                        connectedPublishers().length > MAX_DISPLAYED_AUTOMATICALLY_PLATFORMS
                            ? '+' + (connectedPublishers().length - MAX_DISPLAYED_AUTOMATICALLY_PLATFORMS)
                            : ('platforms.connection.see_details' | translate)
                    }}
                </span>
            </div>
            <div class="flex items-center gap-x-4">
                <mat-icon
                    class="!h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
                    [svgIcon]="SvgIcon.CHECK"></mat-icon>
                <div class="malou-text-13--regular text-malou-color-text-2">
                    {{ 'platforms.connection.successfully_connected_platforms' | pluralTranslate: connectedPublishers().length }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
