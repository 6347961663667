import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { InsightsChart } from '@malou-io/package-utils';

import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { GmbActionsComponent } from ':modules/statistics/seo/gmb-actions/gmb-actions.component';
import { GmbImpressionsComponent } from ':modules/statistics/seo/gmb-impressions/gmb-impressions.component';
import { GmbInsightsComponent } from ':modules/statistics/seo/gmb-insights/gmb-insights.component';
import { StatisticsSeoKeywordsV3Component } from ':modules/statistics/seo/statistics-seo-keywords/statistics-seo-keywords-v3.component';
import { ChartOptions } from ':shared/components/download-insights-modal/download-insights.interface';
import { parseInsightsRouteParams } from ':shared/helpers/extract-statistics-route-data';
import { FromToDateFormatterPipe } from ':shared/pipes/from-to-date-formatter.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { StatisticsPdfRestaurantsFormatterPipe } from ':shared/pipes/statistics-pdf-restaurants-formatter.pipe';

@Component({
    selector: 'app-statistics-seo-pdf',
    templateUrl: './seo-pdf.component.html',
    styleUrls: ['./seo-pdf.component.scss'],
    standalone: true,
    imports: [
        StatisticsSeoKeywordsV3Component,
        GmbImpressionsComponent,
        GmbActionsComponent,
        TranslateModule,
        NgTemplateOutlet,
        IncludesPipe,
        FromToDateFormatterPipe,
        AsyncPipe,
        StatisticsPdfRestaurantsFormatterPipe,
        GmbInsightsComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoPdfComponent {
    startDate: Date;
    endDate: Date;
    displayedCharts: InsightsChart[] = [];

    chartOptions: ChartOptions;
    readonly InsightsChart = InsightsChart;

    seoKeywordsHasData = true;
    gmbImpressionsHasData = true;
    gmbActionsHasData = true;

    readonly selectedRestaurantTitle$: Observable<string>;

    readonly isGmbInsightsChartsV2Enabled: Signal<boolean> = toSignal(
        this._experimentationService.isFeatureEnabled$('release-gmb-insights-charts-v2'),
        {
            initialValue: false,
        }
    );

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _experimentationService: ExperimentationService,
        public readonly translateService: TranslateService
    ) {
        const parsedQueryParams = parseInsightsRouteParams();
        const { startDate, endDate, displayedCharts, chartOptions } = parsedQueryParams;

        this.chartOptions = chartOptions ?? {};
        this.startDate = startDate;
        this.endDate = endDate;
        this.displayedCharts = displayedCharts;

        this.selectedRestaurantTitle$ = this._restaurantsService.restaurantSelected$.pipe(
            map((restaurant) => restaurant?.internalName ?? restaurant?.name ?? '')
        );
    }
}
