<div class="flex w-full items-center justify-between sm:flex-wrap">
    @if (displayAutomaticReplyFilter() && !isAggregatedView()) {
        <div class="flex items-center pr-[15px] md:hidden">
            <app-reviews-header-automatic-reply [showTitle]="false"></app-reviews-header-automatic-reply>
        </div>
    }
    <div class="min-w-[15vw] grow pr-[15px] sm:basis-full sm:pb-2 sm:pr-0 md:!hidden">
        <app-reviews-header-search data-testid="main-reviews-header-search" [id]="searchButtonId()"></app-reviews-header-search>
    </div>
    <div class="flex items-center justify-between gap-x-4 md:w-full">
        @if (isAggregatedView() && displayRestaurantsFilter()) {
            <div class="!w-[45vw] min-w-[350px] flex-3">
                <app-reviews-header-restaurants></app-reviews-header-restaurants>
            </div>
        }

        <div>
            <app-reviews-header-filters [id]="filtersButtonId()" [isAggregatedView]="isAggregatedView()"></app-reviews-header-filters>
        </div>

        <div>
            <app-reviews-header-synchronize
                [id]="filtersButtonId()"
                [isAggregatedView]="isAggregatedView()"></app-reviews-header-synchronize>
        </div>
    </div>
</div>

<div class="mb-3 w-full min-md:!hidden" [class.show-advanced-filters]="showAdvancedFilters()">
    <button class="malou-btn-flat !px-0" mat-button (click)="toggleAdvancedFilters()">
        <span>{{ 'common.advanced_options' | translate }}</span>
        <mat-icon class="chevron-icon !h-[16px] !w-[16px]" iconPositionEnd [svgIcon]="SvgIcon.CHEVRON_DOWN"></mat-icon>
    </button>
    <div class="search mt-2 flex h-0 grow flex-col gap-y-2 opacity-0">
        <div class="grow pr-[15px] sm:basis-full sm:pb-2 sm:pr-0">
            <app-reviews-header-search [id]="searchButtonId()"></app-reviews-header-search>
        </div>
        @if (displayAutomaticReplyFilter() && !isAggregatedView()) {
            <div>
                <app-reviews-header-automatic-reply [showTitle]="true"></app-reviews-header-automatic-reply>
            </div>
        }
    </div>
</div>
