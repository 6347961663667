import { ChangeDetectionStrategy, Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { APP_DEFAULT_LANGUAGE, MALOU_PRIVACY_POLICY_EN, MALOU_PRIVACY_POLICY_FR } from '@malou-io/package-utils';

import { LangService } from ':core/services/lang.service';
import { LocalStorage } from ':core/storage/local-storage';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

@Component({
    selector: 'app-wheel-of-fortune-rules',
    templateUrl: './wheel-of-fortune-rules.component.html',
    styleUrls: ['./wheel-of-fortune-rules.component.scss'],
    standalone: true,
    imports: [TranslateModule, ApplyPurePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneRulesComponent {
    readonly restaurantName: WritableSignal<string> = signal('');
    readonly restaurantAddress: WritableSignal<string> = signal('');

    readonly articleIndexes: number[] = Array.from(
        { length: Object.keys(this._translateService.instant('play_wheel_of_fortune.rules.sections.title')).length },
        (_, index) => index + 1
    );

    readonly getArticleDetails: Signal<(index: number) => string> = computed(
        () =>
            (index: number): string =>
                this._translateService.instant(`play_wheel_of_fortune.rules.sections.content.n${index}`, {
                    name: this.restaurantName(),
                    address: this.restaurantAddress(),
                    url: this.lang() === 'fr' ? MALOU_PRIVACY_POLICY_FR : MALOU_PRIVACY_POLICY_EN,
                })
    );

    readonly lang = signal('en');

    constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _translateService: TranslateService,
        private readonly _langService: LangService
    ) {
        this.restaurantName.set(this._activatedRoute.snapshot.queryParams.name ?? '');
        this.restaurantAddress.set(this._activatedRoute.snapshot.queryParams.address ?? '');

        const navigatorLanguage = this._langService.getLangFromNavigator();
        const lang = LocalStorage.getLang(navigatorLanguage ?? APP_DEFAULT_LANGUAGE);
        const slicedLang = lang?.slice(0, 2);
        this.lang.set(slicedLang);
    }

    getArticleTitle = (index: number): string => this._translateService.instant(`play_wheel_of_fortune.rules.sections.title.n${index}`);
}
