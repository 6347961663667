<div class="relative h-full w-full" #containerDiv>
    <div class="relative h-full w-full" [class.invisible]="isLoading()">
        <img class="object-cover" fill [ngSrc]="url()" #image />
    </div>

    @if (isLoading()) {
        <div class="absolute left-0 top-0 h-full w-full">
            <app-skeleton skeletonClass="h-full" [useContainer]="false"></app-skeleton>
        </div>
    }
</div>
