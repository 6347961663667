export enum AspectRatio {
    SQUARE = 'SQUARE',
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE',
    ORIGINAL = 'ORIGINAL',
}

export const aspectRatioToNumberMap: Record<AspectRatio, number | null> = {
    [AspectRatio.SQUARE]: 1,
    [AspectRatio.PORTRAIT]: 4 / 5,
    [AspectRatio.LANDSCAPE]: 16 / 9,
    [AspectRatio.ORIGINAL]: null,
};
