import { ChangeDetectionStrategy, Component, computed, inject, input, output, Signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions, ChartType, TimeUnit } from 'chart.js';
import { TooltipItem } from 'chart.js/dist/types';
import { isNumber } from 'lodash';
import { NgChartsModule } from 'ng2-charts';

import { PlatformKey, WHEEL_OF_FORTUNE_PLATFORM_KEY } from '@malou-io/package-utils';

import { ViewBy } from ':shared/enums/view-by.enum';
import {
    malouChartColorBluePurple,
    malouChartColorLighterPink,
    malouChartColorLightPink,
    malouChartColorLightPurple,
    malouChartColorPink,
    malouChartColorPinkBarbie,
    malouChartColorPinkMedium,
    malouChartColorPurple,
    malouChartColorPurpleDark,
    malouChartColorPurpleMedium,
    malouChartColorPurplePink,
} from ':shared/helpers';
import { LARGE_TOOLTIP_TAB, SMALL_TOOLTIP_TAB } from ':shared/helpers/default-chart-js-configuration';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

const DD_MMM_YYYY = 'dd MMM yyyy';
const MMM_YYYY = 'MMM yyyy';

type BarChartType = Extract<ChartType, 'bar'>;

export type TotemData = (Record<PlatformKey | 'total', number> & { date: Date })[];
export type TotemsDataByNfcName = Record<string, TotemData>;

@Component({
    selector: 'app-statistics-boosters-scan-count-chart-v2',
    templateUrl: './scan-count-chart.component.html',
    styleUrls: ['./scan-count-chart.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgChartsModule, TranslateModule],
})
export class ScanCountChartV2Component {
    readonly totemsData = input<TotemsDataByNfcName>();
    readonly viewBy = input.required<ViewBy>();
    readonly hiddenDatasetIndexes = input<number[]>();
    readonly hiddenDatasetIndexesChange = output<number[]>();

    private readonly _translateService = inject(TranslateService);
    private readonly _pluralTranslatePipe = inject(PluralTranslatePipe);

    readonly CHART_TYPE: BarChartType = 'bar';
    readonly wheelOfFortuneDisplayedText = this._translateService.instant('statistics.totems.scan_count.wheel_of_fortune');
    readonly PLATFORM_KEYS_TRAD = {
        ...this._translateService.instant('enums.platform_key'),
        [WHEEL_OF_FORTUNE_PLATFORM_KEY]: this.wheelOfFortuneDisplayedText,
    };

    readonly chartDataSets: Signal<ChartDataset<BarChartType, TotemData>[]> = computed(() => {
        const totemsData = this.totemsData();
        let chartDataSets: ChartDataset<BarChartType, TotemData>[] = totemsData ? this._computeChartData(totemsData) : [];
        const hiddenDatasetIndexes = this.hiddenDatasetIndexes();
        if (hiddenDatasetIndexes && hiddenDatasetIndexes.length) {
            chartDataSets = chartDataSets.filter((_, index) => !hiddenDatasetIndexes.includes(index));
        }
        return chartDataSets;
    });
    readonly chartOptions: Signal<ChartOptions<BarChartType>> = computed(() => this._computeChartOptions(this.viewBy()));

    private _hiddenDatasetIndexes: number[] = [];

    private _computeChartData(data: TotemsDataByNfcName): ChartDataset<BarChartType, TotemData>[] {
        return Object.entries(data).map(([key, value], index) => ({
            label: key,
            borderColor: this._getChartColor(index, key),
            backgroundColor: this._getChartColor(index, key),
            xAxisID: 'xAxis',
            yAxisID: 'yAxis',
            data: value,
            parsing: {
                xAxisKey: 'date',
                yAxisKey: 'total',
            },
        }));
    }

    private _getChartColor(index: number, key: string): string {
        if (key === this.wheelOfFortuneDisplayedText) {
            return malouChartColorPurple;
        }
        const colors = [
            malouChartColorPink,
            malouChartColorBluePurple,
            malouChartColorLightPink,
            malouChartColorLightPurple,
            malouChartColorLighterPink,
            malouChartColorPurpleDark,
            malouChartColorPurpleMedium,
            malouChartColorPurplePink,
            malouChartColorPinkBarbie,
            malouChartColorPinkMedium,
        ];
        return colors[index % colors.length];
    }

    private _computeChartOptions(viewBy: ViewBy): ChartOptions<BarChartType> {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: TooltipItem<any>[]) => this._computeTooltipTitle(tooltipItems),
                        label: (tooltipItem: TooltipItem<any>) => this._computeTooltipLabel(tooltipItem),
                        afterLabel: (tooltipItem: TooltipItem<any>) => this._computeTooltipAfterLabel(tooltipItem),
                    },
                },
                legend: {
                    align: 'end',
                    onClick: (_, legendItem, legend): void => {
                        const index = legendItem.datasetIndex;
                        if (!isNumber(index)) {
                            return;
                        }
                        const chart = legend.chart;
                        if (chart.isDatasetVisible(index)) {
                            this._hiddenDatasetIndexes.push(index);
                            chart.hide(index);
                            legendItem.hidden = true;
                        } else {
                            this._hiddenDatasetIndexes = this._hiddenDatasetIndexes.filter((i) => i !== index);
                            chart.show(index);
                            legendItem.hidden = false;
                        }
                        this.hiddenDatasetIndexesChange.emit(this._hiddenDatasetIndexes);
                    },
                },
            },
            scales: {
                xAxis: {
                    axis: 'x',
                    type: 'time',
                    time: {
                        tooltipFormat: this._computeTooltipFormatFromViewBy(viewBy),
                        isoWeekday: true,
                        unit: this._computeTimeUnitFromViewBy(viewBy),
                        displayFormats: {
                            day: DD_MMM_YYYY,
                            week: DD_MMM_YYYY,
                            month: MMM_YYYY,
                        },
                    },
                },
                yAxis: {
                    axis: 'y',
                    type: 'linear',
                    offset: false,
                    ticks: {
                        stepSize: 1,
                    },
                },
            },
        };
    }

    private _computeTooltipTitle(item: TooltipItem<BarChartType>[]): string {
        const title: string = item[0].label;
        if (this.viewBy() === ViewBy.WEEK) {
            const weekOf = this._translateService.instant('statistics.totems.scan_count.week_of');
            return `${weekOf} ${title}`;
        }
        return title;
    }

    private _computeTooltipLabel(item: TooltipItem<BarChartType>): string {
        const totemDataElement: TotemData[number] = item.raw as TotemData[number];
        const label = this._getLabelWithoutTypeDetail(item.dataset.label);
        const nameWithScanCount = `${SMALL_TOOLTIP_TAB}${label} (${
            totemDataElement.total
        } ${this._pluralTranslatePipe.transform('statistics.totems.scan_count.scans', totemDataElement.total)})`;
        if (this._isTooltipForWheelOfFortune(item)) {
            return nameWithScanCount;
        }
        const hasMultipleRedirectionPlatforms =
            Object.keys(totemDataElement).filter((key) => [...Object.values(PlatformKey), WHEEL_OF_FORTUNE_PLATFORM_KEY].includes(key))
                .length > 1;
        return hasMultipleRedirectionPlatforms ? nameWithScanCount : `${SMALL_TOOLTIP_TAB}${label}`;
    }

    private _computeTooltipAfterLabel(item: TooltipItem<BarChartType>): string[] {
        const totemDataElement: TotemData[number] = item.raw as TotemData[number];
        if (this._isTooltipForWheelOfFortune(item)) {
            return [];
        }
        return Object.entries(totemDataElement)
            .filter(([key, _count]) => [...Object.values(PlatformKey), WHEEL_OF_FORTUNE_PLATFORM_KEY].includes(key))
            .map(
                ([platformKey, count]: [PlatformKey, number]) =>
                    `${LARGE_TOOLTIP_TAB}${this.PLATFORM_KEYS_TRAD[platformKey]} (${count} ${this._pluralTranslatePipe.transform(
                        'statistics.totems.scan_count.scans',
                        count
                    )})`
            );
    }

    private _isTooltipForWheelOfFortune(item: TooltipItem<BarChartType>): boolean {
        return !!this._getLabelWithoutTypeDetail(item.dataset.label)?.match(this.wheelOfFortuneDisplayedText);
    }

    private _getLabelWithoutTypeDetail(label?: string): string | undefined {
        return label?.split('(')?.[0]?.trim();
    }

    private _computeTimeUnitFromViewBy(viewBy: ViewBy): TimeUnit | undefined {
        if (![ViewBy.DAY, ViewBy.WEEK, ViewBy.MONTH].includes(viewBy)) {
            return;
        }
        return viewBy.toLowerCase() as TimeUnit;
    }

    private _computeTooltipFormatFromViewBy(viewBy: ViewBy): string {
        return viewBy === ViewBy.MONTH ? MMM_YYYY : DD_MMM_YYYY;
    }
}
